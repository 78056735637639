import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptyApiPage } from "../../models/api-page.model";

export enum ErrorTypes {
  UNKNOWN,
  INTERNET_DISCONNECTED,
  NO_CONTENT,
  SERVICE_RETURNED_NULL,
}

interface InitialStateType {
  error: boolean;
  errorMsg: string;
  errorType: ErrorTypes | null;
}

const initialState: InitialStateType = {
  error: false,
  errorMsg: "",
  errorType: null,
};

export const ErrorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    handleError: (state, action: PayloadAction<Record<string, unknown> | unknown>) => {
      if (!window.navigator.onLine) {
        state.error = true;
        state.errorType = ErrorTypes.INTERNET_DISCONNECTED;
        return;
      }
      switch (action.payload) {
        case emptyApiPage():
          state.error = true;
          state.errorType = ErrorTypes.NO_CONTENT;
          break;

        case null:
          state.error = true;
          state.errorType = ErrorTypes.SERVICE_RETURNED_NULL;
          break;

        default:
          state.error = true;
          state.errorType = ErrorTypes.UNKNOWN;
          break;
      }
    },
    resetErrors: state => {
      state.error = false;
      state.errorMsg = "";
      state.errorType = null;
    },
  },
});

export const { handleError, resetErrors } = ErrorSlice.actions;

export default ErrorSlice.reducer;
