import { ChangeEvent, useContext, useState, useEffect } from "react";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { TextInput, Button, Checkbox, DropArea, Select } from "../..";
import { Service, ServiceBilledTo, ServiceBilledToName } from "../../../models/service.model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { addOfficeService, addService } from "../../../redux/slices/ServiceSlice";
import { useParams } from "react-router-dom";
import { FileTypes } from "../../../models";
import { DiscountValueValidation, colors } from "../../../utils/constants";
import { clearUploadedFiles } from "../../../redux/slices/FileSlice";
import { formatCurrency, onFileError, validateCurrency } from "../../../utils";
import { StarBorderOutlined } from "@mui/icons-material";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { getOfficeUninsuredServices } from "../../../redux/slices/OfficeSlice";

export interface AddServiceDataType {
  id: string;
  placeholder: string;
  required: boolean;
  errorMesage: string;
  errorMesage1?: string;
  label: string;
  value: string;
  isValid: boolean;
}
const addServiceData: AddServiceDataType[] = [
  {
    id: "serviceName",
    label: "Service Name",
    placeholder: "Name of service",
    required: true,
    errorMesage: "Please provide a service name",
    value: "",
    isValid: false,
  },
  {
    id: "description",
    label: "Description",
    placeholder: "Description of service (optional)",
    required: false,
    errorMesage: "Maximum 250 characters",
    value: "",
    isValid: true,
  },
  {
    id: "file",
    label: "",
    placeholder: "",
    required: true,
    errorMesage: "",
    value: "",
    isValid: true,
  },
  {
    id: "servicePrice",
    label: "Service Price",
    placeholder: "$ Enter service price",
    required: true,
    errorMesage: "Please provide a service price",
    value: "",
    isValid: false,
  },
  {
    id: "serviceBilled",
    label: "Service Billed To:",
    isValid: true,
    required: false,
    value: "",
    placeholder: "Select billed to",
    errorMesage: "Please select an option",
  },
  // {
  //   id: "discount",
  //   label: "Discount",
  //   isValid: true,
  //   required: false,
  //   value: "",
  //   placeholder: "",
  //   errorMesage: "Please enter discount",
  // },
];
const billedToOptions = [
  { title: ServiceBilledToName[ServiceBilledTo.Patient], value: ServiceBilledTo.Patient },
  { title: ServiceBilledToName[ServiceBilledTo.OHIP], value: ServiceBilledTo.OHIP },
  { title: ServiceBilledToName[ServiceBilledTo.WSIB], value: ServiceBilledTo.WSIB },
  { title: ServiceBilledToName[ServiceBilledTo.ThirdParty], value: ServiceBilledTo.ThirdParty },
  { title: ServiceBilledToName[ServiceBilledTo.ServiceCanada], value: ServiceBilledTo.ServiceCanada },
  { title: ServiceBilledToName[ServiceBilledTo.DocnoteDebit], value: ServiceBilledTo.DocnoteDebit },
];

const AddService = ({
  onCancelClick,
  isAdminPage,
  setIsLoading,
  isLoading,
}: {
  onCancelClick: (val?: boolean) => void;
  isAdminPage: boolean;
  setIsLoading: (val: boolean) => void;
  isLoading: boolean;
}) => {
  const [serviceFormData, setServiceFormData] = useState<AddServiceDataType[]>(addServiceData);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [dropAreaError, setDropAreaError] = useState<string>("");
  const [hstCheckBox, setHstCheckBox] = useState(true);
  const [enableServiceCheckBox, setEnableServiceCheckBox] = useState(true);
  const [uninsured, setUninsured] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [addServiceError, setAddServiceError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [serviceNameError, setServiceNameError] = useState(false);
  const [serviceNameErrMsg, setServiceNameErMsg] = useState("");
  //const [includeWithBlockFee, setIncludeWithBlockFee] = useState(false);
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const { loading } = useSelector((state: RootState) => state.file);
  const { uninsuredServices } = useSelector((state: RootState) => state.office);
  const toast = useContext(ToastContext);
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearUploadedFiles());
    };
  }, []);

  const validateField = (name: string, value: string) => {
    let valid = false;
    switch (name) {
      case "servicePrice":
        valid = validateCurrency(value);
        break;
      case "serviceName":
        valid = !!value.trim();
        break;
      case "description":
        valid = true;
        break;
      // case "discount":
      //   if (includeWithBlockFee && value) {
      //     valid = true;
      //   }
      //   break;
      default:
        break;
    }
    return valid;
  };
  const handleFileChange = (file: string[]) => {
    const indexInData = serviceFormData.findIndex(v => v.id === "file");
    const temp = [...serviceFormData];
    temp[indexInData].value = file.toString();
    setServiceFormData(temp);
  };
  const handleFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    let value = e.currentTarget.value;
    let newServiceFormData = [...serviceFormData];
    switch (e.currentTarget.name) {
      case "servicePrice":
        value = formatCurrency(value, "");
        break;
      case "description":
        if ((value as string).length > 250) {
          setDescriptionError(true);
        } else {
          setDescriptionError(false);
        }
        break;
      case "serviceName":
        if ((value as string).length > 50) {
          setServiceNameError(true);
          setServiceNameErMsg("Maximum 50 character");
        } else {
          setServiceNameError(false);
          setServiceNameErMsg("");
        }
        break;
      case "serviceBilled":
        value = billedToOptions.find(v => v.title === e.target.value)?.value || "";
        if (value === ServiceBilledTo.DocnoteDebit) {
          newServiceFormData = [
            ...newServiceFormData.slice(0, 5),
            {
              ...newServiceFormData[5],
              value: "",
              isValid: true,
            },
            ...newServiceFormData.slice(6),
          ];
        }
        break;
      case "discount":
        value = e.currentTarget.value;
        if (!e.currentTarget.value) {
          value = "";
        } else if (e.currentTarget.value && DiscountValueValidation.test(e.currentTarget.value)) {
          value = e.currentTarget.value;
        } else {
          return;
        }
        break;
      default:
        break;
    }
    const valid = validateField(e.currentTarget.name, value);
    setServiceFormData([
      ...newServiceFormData.slice(0, index),
      {
        ...newServiceFormData[index],
        value,
        isValid: newServiceFormData[index].id === "serviceBilled" ? true : valid,
      },
      ...newServiceFormData.slice(index + 1),
    ]);
  };
  const onClickCancel = () => {
    onCancelClick();
  };
  const getServiceData = () => {
    return serviceFormData.reduce((acc: any, el) => {
      acc[el.id] = el.value;
      return acc;
    }, {});
  };

  // const handleBlockFee = (val: boolean) => {
  //   setIncludeWithBlockFee(val);
  //   setServiceFormData([
  //     ...serviceFormData.slice(0, 5),
  //     {
  //       ...serviceFormData[5],
  //       value: "",
  //       required: val,
  //       isValid: !val,
  //     },
  //     ...serviceFormData.slice(6),
  //   ]);
  // };

  const onClickSave = async () => {
    setErrorInitiated(true);
    setIsLoading(true);
    const allValid = serviceFormData.every(el => el.isValid);
    if (allValid) {
      //dispatch add office
      const { serviceName, servicePrice, file, serviceBilled, description } = getServiceData();
      setErrorInitiated(false);
      const dataToSave: Service = {
        officeId: isAdminPage ? undefined : id !== undefined ? id : "",
        name: serviceName,
        description: !description?.trim() ? undefined : description.trim(),
        price: servicePrice.replaceAll(",", "").replaceAll("$", "") * 100,
        addTax: hstCheckBox,
        enabled: enableServiceCheckBox,
        sampleFileId: file === "" ? undefined : file,
        serviceBilledTo: serviceBilled === "" ? undefined : serviceBilled,
        //blockFeeDiscountPct: includeWithBlockFee && discount ? parseInt(discount) : undefined,
        uninsured,
        featured,
      };
      setAddServiceError(false);
      if (!isAdminPage) {
        if (currentOffice?.officeId) {
          const res = await dispatch(addOfficeService({ officeId: currentOffice.officeId, serviceData: dataToSave }));
          if (res.payload !== null) {
            onCancelClick(true);
            toast?.openToast("Successfully added service!");
            dispatch(getOfficeUninsuredServices({ officeId: currentOffice.officeId, page: 0, size: 3, sort: "featured,desc" }));
          } else {
            setIsLoading(false);
            setAddServiceError(true);
          }
        }
      } else {
        dispatch(addService(dataToSave))
          .then(action => {
            if (action.payload !== null) {
              onCancelClick(true);
              toast?.openToast("Successfully added service!");
            } else {
              setIsLoading(false);
              setAddServiceError(true);
            }
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
    }
  };
  const onBlurPrice = (e: { currentTarget: { value: string } }) => {
    const value = e.currentTarget.value;
    const newVal = formatCurrency(value, "blur");
    const index = serviceFormData.findIndex(el => el.id === "servicePrice");
    setServiceFormData([
      ...serviceFormData.slice(0, index),
      {
        ...serviceFormData[index],
        value: newVal,
      },
      ...serviceFormData.slice(index + 1),
    ]);
  };

  const handleBlur = (id: string, value: string) => {
    const index = serviceFormData.findIndex(item => item.id === id);
    if (index !== -1) {
      setServiceFormData([
        ...serviceFormData.slice(0, index),
        {
          ...serviceFormData[index],
          value: value,
        },
        ...serviceFormData.slice(index + 1),
      ]);
    }
  };

  const handleFeatured = async () => {
    if (id) {
      if (uninsuredServices.length === 3 && uninsuredServices.every(val => val.featured)) {
        toast?.openToast("Only three services can be featured");
        return;
      }
      setFeatured(true);
      setUninsured(true);
    }
  };

  //const isDocnotDebit = serviceFormData.filter(item => item.value === ServiceBilledTo.DocnoteDebit).length;

  return (
    <>
      <div className="inline-block h-scrollable-screen overflow-auto w-full">
        <p className="text-base px-8 py-5 font-medium text-docsigna-blue-dark">Please provide the details for this new service.</p>
        {addServiceError ? (
          <p className="text-center" style={{ color: colors.Red }}>
            Unable to add service, please try again.
          </p>
        ) : null}
        {serviceFormData.slice(0, 5).map((item: AddServiceDataType, index) => {
          switch (item.id) {
            case "description":
              return (
                <div key={item.id} className="px-8 py-2">
                  <TextInput
                    isError={(!item.isValid && errorInitiated) || descriptionError}
                    value={item.value}
                    name={item.id}
                    onChangeFunc={e => handleFieldChange(e, index)}
                    label={item.label}
                    errorMsg={item.errorMesage}
                    isRequired={item.required}
                    showCounter={true}
                    counterMessage={"250"}
                    placeholder={item.placeholder}
                    isTextArea={true}
                    onBlur={e => handleBlur(item.id, e.currentTarget.value.trim())}
                    isDarkBg={true}
                  />
                </div>
              );
            case "servicePrice":
              return (
                <div key={item.id} className="px-8 py-2">
                  <TextInput
                    isError={!item.isValid && errorInitiated}
                    value={item.value}
                    name={item.id}
                    onChangeFunc={e => handleFieldChange(e, index)}
                    label={item.label}
                    errorMsg={item.errorMesage}
                    isRequired={item.required}
                    placeholder={item.placeholder}
                    onBlur={e => onBlurPrice(e)}
                    isDarkBg={true}
                  />
                </div>
              );
            case "file":
              return (
                <div key={item.id} className="px-8 py-2">
                  <p className="text-sm font-medium text-docsigna-dark-blue block w-full mb-1">{`Upload form image for reference (optional)`}</p>
                  <DropArea
                    allowedFileTypes={[FileTypes.JPEG, FileTypes.PNG, FileTypes.PDF, FileTypes.GIF]}
                    maxFileSize={50 * 1024 * 1024}
                    maxNumOfFiles={1}
                    onError={err => setDropAreaError(onFileError(err))}
                    onFilesChange={handleFileChange}
                    isDarkBg={true}
                  />
                  <p style={{ color: colors.Red }}>{dropAreaError}</p>
                </div>
              );
            case "serviceBilled":
              return (
                <div key={item.id} className="px-8 py-2">
                  <Select
                    required={item.required}
                    placeHolder={item.placeholder}
                    title={item.label}
                    name={item.id}
                    onSelect={e => {
                      handleFieldChange(e, index);
                    }}
                    options={billedToOptions.map(v => v.title)}
                    isDarkBg={true}
                  />
                </div>
              );
            default:
              return (
                <div key={item.id} className="px-8 py-2">
                  <TextInput
                    isError={(!item.isValid && errorInitiated) || serviceNameError}
                    value={item.value}
                    name={item.id}
                    onChangeFunc={e => handleFieldChange(e, index)}
                    label={item.label}
                    errorMsg={serviceNameErrMsg.length ? serviceNameErrMsg : item.errorMesage}
                    isRequired={item.required}
                    placeholder={item.placeholder}
                    onBlur={e => handleBlur(item.id, e.currentTarget.value.trim())}
                    isDarkBg={true}
                  />
                </div>
              );
          }
        })}
        <div className="px-8 py-2">
          <Checkbox checked={hstCheckBox} onChange={e => setHstCheckBox(e.currentTarget.checked)} label="HST applicable" isDarkBg={true} />
        </div>
        {/* {!isDocnotDebit ? (
          <div className="px-8 py-2">
            <Checkbox
              checked={includeWithBlockFee}
              onChange={e => handleBlockFee(e.currentTarget.checked)}
              label="Include with block fee"
              isDarkBg={true}
            />
          </div>
        ) : null} */}
        {/* {!isDocnotDebit && includeWithBlockFee
          ? serviceFormData.slice(5).map((item: AddServiceDataType, index) => {
              return (
                <div key={item.id} className="px-8 mb-5 w-full relative flex items-center">
                  <div className="w-2/5 ml-10">
                    <TextInput
                      isError={errorInitiated && !item.isValid}
                      value={item.value}
                      name={item.id}
                      onChangeFunc={e => handleFieldChange(e, 5)}
                      label={item.label}
                      errorMsg={serviceNameErrMsg.length ? serviceNameErrMsg : item.errorMesage}
                      isRequired={item.required}
                      placeholder={item.placeholder}
                      onBlur={e => handleBlur(item.id, e.currentTarget.value.trim())}
                      isDarkBg={true}
                    />
                  </div>
                  <div className="w-1/5">
                    <span className={`block ml-2 ${!(errorInitiated && !item.isValid) ? "mt-5" : ""}`}>%</span>
                  </div>
                </div>
              );
            })
          : null} */}
        <hr className="my-2 mx-8 border-docsigna-blue-light" />
        <div className="px-8 py-2">
          <Checkbox
            checked={enableServiceCheckBox}
            onChange={e => {
              setEnableServiceCheckBox(e.currentTarget.checked);
              if (!e.currentTarget.checked) {
                setUninsured(false);
                setFeatured(false);
              }
            }}
            label="Enable service"
            isDarkBg={true}
          />
        </div>
        {!isAdminPage ? (
          <>
            <div className="px-8 py-2">
              <Checkbox
                checked={uninsured}
                onChange={e => {
                  setUninsured(e.currentTarget.checked);
                  if (!e.currentTarget.checked) {
                    setFeatured(false);
                  }
                }}
                label="Include in uninsured service list"
                isDarkBg={true}
              />
            </div>
            <div className="px-8 py-2 flex gap-1 cursor-pointer">
              {!featured ? (
                <button disabled={!enableServiceCheckBox} onClick={handleFeatured}>
                  <StarBorderOutlined className="text-docsigna-orange" />
                </button>
              ) : (
                <button disabled={!enableServiceCheckBox} onClick={() => setFeatured(false)}>
                  <StarOutlinedIcon className="text-docsigna-orange" />
                </button>
              )}
              <p>Feature service on patient dashboard</p>
            </div>
          </>
        ) : null}
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            disabled={isLoading || loading}
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button
              onClickFunc={() => {
                onClickSave();
              }}
              disabled={(serviceFormData.some(el => !el.isValid) && errorInitiated) || descriptionError || serviceNameError || loading || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddService;
