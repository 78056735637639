import { UserInfo } from "./user-info.model";

export enum OfficeState {
  Pending = 'pending',
  Draft = 'draft',
  Published = 'published',
  Disabled = 'disabled',
}

export type Office = {
  officeId?: string;
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  fax: string;
  contactName: string;
  contactEmail: string;
  numUsers?: number;
  primaryCareProviders?: UserInfo[];
  billingName?: string;
  billingStreet?: string;
  billingCity?: string;
  billingState?: string;
  billingZip?: string;
  billingCountry?: string;
  taxEnabled?: boolean;
  taxNumber?: string;
  wsibNumber?: string;
  bankAccount?: string;
  bankInstitution?: string;
  bankTransit?: string;
  acceptedTerms?: boolean;
  officeState: OfficeState;
  street2?: string;
  billingStreet2?: string;
  yearsInPractice?: string;
  emr?: string;
  geoArea?: string;
  staffSize?: string;
  familyPhysician?: boolean;
  inPhysicianGroups?: boolean;
  physicianGroups?: string;
  acceptingPatients?: boolean;
  patientRosterSize?: string;
  monOpen?: string;
  monClose?: string;
  monClosed?: boolean;
  tueOpen?: string;
  tueClose?: string;
  tueClosed?: boolean;
  wedOpen?: string;
  wedClose?: string;
  wedClosed?: boolean;
  thuOpen?: string;
  thuClose?: string;
  thuClosed?: boolean;
  friOpen?: string;
  friClose?: string;
  friClosed?: boolean;
  satOpen?: string;
  satClose?: string;
  satClosed?: boolean;
  sunOpen?: string;
  sunClose?: string;
  sunClosed?: boolean;
  tempClosed?: boolean;
  tempClosedReason?: string;
};
