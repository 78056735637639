import { useContext, useState } from "react";
import { Button, TextArea, TextInput } from "../..";
import { validateEmail } from "../../../utils";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { invitePatient } from "../../../redux/slices/OfficeSlice";
import { colors } from "../../../utils/constants";
import { CircularProgress } from "@mui/material";
import { Check } from "@mui/icons-material";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../../../models";
interface InviteItem {
  email: string;
  valid: boolean;
  isDuplicate: boolean;
}
const newItem: InviteItem = {
  email: "",
  valid: false,
  isDuplicate: false,
};
const InvitePatient = ({ officeId, cancelInvite }: { officeId: string; cancelInvite: (val?: boolean) => void }) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const [emails, setEmail] = useState<InviteItem[]>([newItem]);
  const [completed, setCompleted] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [note, setNote] = useState("");
  const [errorInitiated, setErrorInitiated] = useState(false);

  const handleChange = (value: string, ind: number) => {
    const item = emails.find((itm, i) => i === ind);
    if (item) {
      const isValid = validateEmail(value);
      let isDuplicate = false;
      if (isValid) {
        const index = emails.findIndex(item => item.email === value);
        isDuplicate = index !== -1 && index !== ind;
      }
      setEmail([...emails.slice(0, ind), { email: value, valid: !isValid, isDuplicate }, ...emails.slice(ind + 1)]);
    }
  };

  const inviteUserFn = (item: InviteItem) => {
    return new Promise(async (resolve, reject) => {
      const res = await dispatch(
        invitePatient({
          officeId,
          email: item.email,
          note,
        })
      );
      if (res?.payload === "success") {
        const oldCompleted = [...completed, item.email];
        setCompleted([...oldCompleted]);
        resolve(true);
      } else {
        reject(res?.payload === "duplicate" ? item.email : null);
      }
    });
  };

  const invite = async () => {
    try {
      setError("");
      setLoading(true);
      setErrorInitiated(true);
      await emails
        .filter(item => !completed.includes(item.email))
        .reduce((accumulatorPromise: any, item) => {
          return accumulatorPromise.then(() => {
            return inviteUserFn(item);
          });
        }, Promise.resolve());
      setLoading(false);
      cancelInvite(true);
      toast?.openToast("Invitation sent successfully", 2000, ToastVariants.Success);
    } catch (err) {
      if (err) {
        setError(`${err}`);
      } else {
        setError(`Something went wrong. Try again!`);
      }
      setLoading(false);
    }
  };

  const removeEmail = (i: number) => {
    setEmail([...emails.slice(0, i), ...emails.slice(i + 1)]);
  };

  const disabled = emails.filter(item => !item.email.trim() || item.valid || item.isDuplicate).length > 0;

  return (
    <>
      <div className="inline-block h-scrollable-screen overflow-auto w-full">
        <p className="text-base px-8 pb-5">
          Enter email address(s) below to invite your patient(s) to Docnote. Your patient will receive a link to create their account and enter the
          remaining account details required.
        </p>
        {error ? (
          <p className="text-center" style={{ color: colors.Red }}>
            Patient invitation failed
          </p>
        ) : null}
        <div className="px-8">
          {emails?.map((item, i) => {
            const isCompleted = completed.includes(item.email);
            const isUserExist = error && item.email === error;
            return (
              <div className="py-2 flex items-center" key={i}>
                <div className={`w-4/5`}>
                  <TextInput
                    readonly={loading || isCompleted}
                    onChangeFunc={e => handleChange(e.target.value, i)}
                    value={item.email}
                    placeholder={"Email Address"}
                    errorMsg={
                      isUserExist ? "Patient already exist with this email" : item.isDuplicate ? "Email already added" : "Please enter valid email"
                    }
                    isRequired={true}
                    isError={isUserExist || (item.valid && errorInitiated) || item.isDuplicate}
                    label={`Email ${i + 1}`}
                    onBlur={e => null}
                    isDarkBg={true}
                  />
                </div>
                {isCompleted ? (
                  <div className="w-1/5 flex justify-center mt-5">
                    <Check className="green-color-icon" />
                  </div>
                ) : (
                  <>
                    {loading ? (
                      <div className="w-1/5 flex justify-center mt-5">
                        <CircularProgress size={25} color={"info"} />
                      </div>
                    ) : null}
                    {emails?.length > 1 && !loading ? (
                      <div className="w-1/5 flex justify-center mt-5">
                        <button
                          onClick={() => {
                            removeEmail(i);
                          }}
                          className="pointer rounded-full w-8 h-8 bg-docsigna-orange text-white">
                          <span className="block font-bold leading-none">X</span>
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            );
          })}
          {emails.length < 10 ? (
            <div className="w-full mt-2 flex justify-center">
              <button
                disabled={loading}
                onClick={() => {
                  setEmail([...emails, newItem]);
                }}
                className="pointer rounded-full w-8 h-8 bg-docsigna-orange text-white shadow-none outline-none">
                <span className="block font-bold leading-none mb-0.5">+</span>
              </button>
            </div>
          ) : null}
        </div>
        <div className="px-8 mt-4">
          <label className="text-sm font-medium block w-full max-w-full">Add an optional note to the patient(s) below:</label>
          <TextArea onChange={e => setNote(e.target.value)} label="" value={note} extraTextAreaClass="resize-none h-48 bg-docsigna-pink-light" />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            disabled={loading}
            onClickFunc={cancelInvite}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button disabled={disabled || loading} onClickFunc={invite} AdditionalClassNames="pointer px-5" text="Send Invitation(s)" width="fit" />
          </div>
        </div>
      </div>
    </>
  );
};
export default InvitePatient;
