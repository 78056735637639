import React from "react";
import { colors } from "../../utils/constants";

interface RadioInputPropType {
  onChangeFunc: (val: string) => void;
  value: string;
  placeholder?: string;
  isRequired?: boolean;
  errorMsg?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  readonly?: boolean;
  isDarkBg?: boolean;
  options: string[];
}
const RadioInput = ({
  onChangeFunc,
  value = "",
  label = "",
  isRequired = false,
  errorMsg = "",
  disabled = false,
  readonly,
  isDarkBg = false,
  options,
}: RadioInputPropType) => {
  return (
    <>
      {label ? (
        <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">
          {label}
          {isRequired ? <span className="text-red-500">*</span> : null}
        </label>
      ) : null}
      <div className="flex flex-wrap gap-5 w-full min-h-[44px] items-center">
        {options.map((option, index) => {
          return (
            <label key={index} className="relative">
              <input
                type={"radio"}
                readOnly={readonly}
                checked={value === option}
                disabled={disabled}
                style={disabled ? { backgroundColor: "rgb(156 163 175)", cursor: "not-allowed" } : {}}
                onChange={() => onChangeFunc(option)}
                className="general-radio bg-transparent"
              />
              <span className="text-base ml-2">{option}</span>
            </label>
          );
        })}
      </div>
      {errorMsg ? <p style={{ color: colors.Red }}>{errorMsg}</p> : <p></p>}
    </>
  );
};

export default RadioInput;
