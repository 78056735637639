import React, { useEffect, useState } from "react";
import moment from "moment";
import { getFileSize, onFileError } from "../../../utils";
import { DropArea, Tooltip } from "../../../components";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { addOfficeRequestFiles, clearRequestFiles, getOfficeRequestFiles, toggleToolip } from "../../../redux/slices/OfficeRequestSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, LinearProgress } from "@mui/material";
import close from "../../../assets/images/close.svg";
import { DsFile } from "../../../models/ds-file.model";
import { saveAs } from "file-saver";
import { FileTypes } from "../../../models";
import { colors } from "../../../utils/constants";
import { clearUploadedFiles } from "../../../redux/slices/FileSlice";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const FileDetails = ({ isCompleted }: { isCompleted: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { patientRequestId } = useParams();
  const { patientRequestData } = useSelector((state: RootState) => state.user);
  const { requestFiles: files } = useSelector((state: RootState) => state.officeRequest);
  const [fileUrl, setFileUrl] = useState("");
  const fileToolTipOptions = ["View File"];
  const [modalopen, setModalopen] = useState(false);
  const allowedFiles = [FileTypes.JPEG, FileTypes.PNG, FileTypes.PDF];
  const [uploadProgress, setUploadProgress] = useState(100);
  const addedFiles: string[] = [];

  const [dropAreaError, setDropAreaError] = useState<string>("");

  const handleDownloadFile = async (url: string, fileName: string) => {
    const blob = await fetch(url).then(r => r.blob());
    saveAs(blob, fileName);
  };
  const openFile = (fileId: string, url?: string) => {
    if (url) {
      setFileUrl(url);
      setModalopen(true);
      return;
    }
    if (fileId) {
      const fileToOpen = files.find(f => f.fileId === fileId);
      setFileUrl(fileToOpen?.url || "");
      setModalopen(true);
    }
  };

  const handleTooltip = (open: boolean, id?: string) => {
    dispatch(toggleToolip({ visible: open, fileId: id }));
  };

  const handleToolTipOption = (type: string, file: DsFile) => {
    if (type === "View File") {
      openFile(file.fileId, file.url);
    }
    if (type === "Download File") {
      if (file.url) handleDownloadFile(file.url, file.originalFilename);
    }
  };

  const handleDropArea = (requestFileIds: string[]) => {
    const filesToAdd: string[] = [];
    requestFileIds.forEach(id => {
      if (!addedFiles.includes(id)) {
        filesToAdd.push(id);
        addedFiles.push(id);
        if (patientRequestData && patientRequestId) {
          dispatch(addOfficeRequestFiles({ fileIds: [id], officeId: patientRequestData.officeId, requestId: patientRequestId })).then(() => {
            dispatch(clearUploadedFiles());
            dispatch(getOfficeRequestFiles({ officeId: patientRequestData.officeId, requestId: patientRequestId }));
          });
        }
      }
    });
  };
  useEffect(() => {
    dispatch(clearRequestFiles());
    return () => {
      dispatch(clearUploadedFiles());
    };
  }, []);

  useEffect(() => {
    if (patientRequestData?.officeId && patientRequestId) {
      dispatch(getOfficeRequestFiles({ officeId: patientRequestData?.officeId, requestId: patientRequestId }));
    }
  }, [patientRequestData?.officeId, patientRequestId]);

  return (
    <>
      {isCompleted ? null : (
        <>
          <div className="text-center bg-slate-100 mb-2 relative">
            <p style={{ color: colors.Red }}>{dropAreaError}</p>
            <DropArea
              allowedFileTypes={allowedFiles}
              maxFileSize={50 * 1024 * 1024}
              maxNumOfFiles={10}
              onError={err => setDropAreaError(onFileError(err))}
              onFilesChange={handleDropArea}
              onProgress={setUploadProgress}
              hideImageInPreview
              hidePreviewAfterUpload
              filePreviewVisible={false}>
              {
                (
                  <>
                    <input
                      type="file"
                      className="text-base rounded-md  w-full h-full py-6 px-4 opacity-0 relative z-10"
                      // onChange={() => toast?.openToast()}
                    />
                    {isCompleted ? null : (
                      <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center rounded bg-docsigna-pink-light">
                        <div className="flex">
                          <AttachFileIcon htmlColor={colors.DarkBlue} className="rotate-[45deg]" />
                          <p className="ml-1 font-medium">Upload attachment here</p>
                        </div>
                      </div>
                    )}
                  </>
                ) as unknown as Element
              }
            </DropArea>
            {uploadProgress !== 100 ? (
              <div className="flex justify-between items-center border-b w-full">
                <div className="w-full">
                  <LinearProgress value={uploadProgress} variant="determinate" />
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      {files.length > 0
        ? files.map(file => {
            return (
              <div key={file.requestFileId} className="block lg:flex justify-between items-center border-b w-full">
                {
                  <div className="w-full lg:w-1/2 p-3 pl-0 pr-0 lg:pr-3 pb-0 lg:pb-3">
                    <p className="break-all text-base text-docsigna-blue-light cursor-pointer" onClick={() => openFile(file.fileId, file.url)}>
                      {file.originalFilename}
                    </p>
                  </div>
                }
                <div className="flex justify-between items-center w-full lg:w-1/2">
                  <div className="inline-block w-full p-3 pl-0 lg:pl-3 text-gray-500 truncate">{moment(file.createdDate).format("ll")}</div>
                  <div className="inline-block w-full p-3 pl-0 lg:pl-3 text-gray-500 truncate">{getFileSize(file.size)}</div>
                  <div className="inline-block w-auto p-3 pl-0 lg:pl-3 relative">
                    <p className="text-base text-docsigna-blue-light inline-block w-5">
                      <Tooltip
                        open={file.tooltipVisible}
                        onSelect={type => handleToolTipOption(type, file)}
                        options={fileToolTipOptions}
                        handleOpenTooltip={() => {
                          handleTooltip(true, file.requestFileId);
                        }}
                        handleCloseTooltip={() => {
                          handleTooltip(false);
                        }}
                        additionalComponents={[
                          <div className="block" key={"extra-tooltip"} style={{ cursor: "pointer" }}>
                            <p
                              className="block text-base px-4 py-3 border-b border-gray-200 duration-100 hover:bg-gray-200"
                              onClick={() => {
                                handleToolTipOption("Download File", file);
                                handleTooltip(false);
                              }}>
                              Download File
                            </p>
                          </div>,
                        ]}
                      />
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        : isCompleted
        ? "-"
        : null}
      <Modal open={modalopen} onClose={() => setModalopen(false)}>
        <div className="modal relative z-10">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl">
                  <div className="bg-white p-4 xl:p-6">
                    <span className="modal-close absolute top-2 right-2 w-7 h-7 bg-white rounded-full">
                      <img src={close} alt="" className="w-full h-full cursor-pointer" onClick={() => setModalopen(false)} />
                    </span>

                    <embed src={fileUrl} className="w-full" style={{ height: "80vh" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FileDetails;
