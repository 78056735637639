import React from "react";
import loadingGif from "../../assets/images/loading.gif";

const FullScreenLoader = () => {
  return (
    <div className="flex items-center justify-center loader z-50 bg-white fixed top-0">
      <div className="w-10">
        <img src={loadingGif} alt="" />
      </div>
    </div>
  );
};

export default FullScreenLoader;
