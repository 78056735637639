import { useDispatch } from "react-redux";
import { BreadCrumbs, Button, Pagination, ProtectedComponent, RenderName, TableHeader, TableRow, UserSideBar } from "../../components";
import useBreadCrumbs from "../../hooks/useBreadCrumbs";
import { headers } from "./ViewPatientData";
import { AppDispatch, RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import { getUserRequests } from "../../redux/slices/UserSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { billedToOptions, formatOhipNumberNew, formatPhoneNumber, getSorting, renderState } from "../../utils";
import moment from "moment";
import { User } from "../../models/user.model";
import { Request } from "../../models/request.model";
import { sortObj } from "../../redux/slices/OfficeSlice";
import EditPatientProfile from "../../components/forms/EditPatientProfile/EditPatientProfile";
import { OfficeService } from "../../services/office.service";
import { AuthPermission } from "../../services/auth.service";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NoRequestFound from "../../assets/images/no-result.png";

const ViewPatient = () => {
  const navigate = useNavigate();
  const { patientId, id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { userRequest } = useSelector((state: RootState) => state.user);

  const [user, setUser] = useState<User | null>(null);
  const [showEditPatientProfile, setShowEditPatientProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [sortMethods, setSortMethods] = useState<boolean[]>(headers.map(() => false));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(-1);
  const [tableState, setTableState] = useState({
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "createdDate,desc",
  });

  let breadCrumbs = useBreadCrumbs();
  breadCrumbs = [...breadCrumbs.slice(0, breadCrumbs.length - 1), ...breadCrumbs.slice(breadCrumbs.length)];
  breadCrumbs = [
    ...breadCrumbs,
    {
      key: "as",
      title: `${user?.firstName ?? ""} ${user?.lastName ?? ""}`,
    },
  ];

  const handleRowClick = (requestId: string, officeId: string) => {
    navigate(`/offices/${officeId}/requests/${requestId}`);
  };

  const updateTable = (sortObj?: sortObj) => {
    const pageNumToBeCalled = sortObj?.pageNumber ?? tableState.pageNumber;
    const sizeToBeCalled = sortObj?.rowsPerPage ?? tableState.rowsPerPage;
    const sortBy = sortObj?.sortedBy ?? tableState.sortedBy;
    if (patientId) {
      dispatch(
        getUserRequests({
          page: pageNumToBeCalled,
          size: sizeToBeCalled,
          sort: sortBy,
          userId: patientId,
        })
      ).then(() => {
        setTableState({ ...tableState, pageNumber: pageNumToBeCalled, rowsPerPage: sizeToBeCalled, sortedBy: sortBy });
      });
    }
  };

  const getUser = async (officeId: string, patientId: string) => {
    const currentUser = await OfficeService.getOfficeUser(officeId, patientId);
    setUser(currentUser);
  };

  const load = async (patientId: string) => {
    dispatch(getUserRequests({ userId: patientId, page: 0, size: 10, sort: "createdDate,desc" }));
    // FIXME: Need office ID
    if (id) getUser(id, patientId);
  };

  const handleSort = (index: number) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    setActiveSortIndex(index);
    updateTable({ sortedBy: sortMethodString, pageNumber: 0, rowsPerPage: tableState.rowsPerPage });
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateTable({ sortedBy: tableState.sortedBy, pageNumber: newPage, rowsPerPage: tableState.rowsPerPage });
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateTable({ sortedBy: tableState.sortedBy, pageNumber: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  useEffect(() => {
    if (patientId) {
      load(patientId);
    }
  }, [patientId]);

  const moreOptions = ["View Details"];
  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));
  return (
    <>
      <main>
        <div className="container mx-auto p-8">
          <BreadCrumbs items={breadCrumbs} />
          <div className="block lg:flex justify-between items-center mb-6">
            <div className="w-full mb-5 lg:mb-0">
              <h2 className="heading mb-0 uppercase">
                {user?.firstName ?? "-"} {user?.lastName ?? ""}
              </h2>
              <p className="font-medium">Plan: {user?.blockFee?.name ?? "No active plan"}</p>
            </div>
          </div>
          {/* Patient details section */}
          <ProtectedComponent requiredPermission={[AuthPermission.User]}>
            <div className="flex justify-end">
              <Button text="Edit Profile" onClickFunc={() => setShowEditPatientProfile(true)} width="fit" />
            </div>
          </ProtectedComponent>
          <div className="bg-docsigna-pink-light p-5 rounded mt-5">
            <h2 className="text-xl font-bold text-docsigna-blue-extra-light mb-5">Patient Details</h2>
            <div className="lg:flex justify-between gap-[34px]">
              <div className="py-3">
                <h5 className="font-bold text-xl break-all">{user?.ohipNumber ? formatOhipNumberNew(user?.ohipNumber) : "-"}</h5>
                <h3 className="text-sm font-medium">OHIP Number</h3>
              </div>
              <div className="bg-white w-[1px]" />
              <div className="py-3">
                <h5 className="font-bold text-xl break-all">{user?.dateOfBirth ? moment(user?.dateOfBirth).format("ll") : "-"}</h5>
                <h3 className="text-sm font-medium">Date of Birth</h3>
              </div>
              <div className="bg-white w-[1px]" />
              <div className="py-3">
                <h5 className="font-bold text-xl break-all">{user?.email ? user?.email : "-"}</h5>
                <h3 className="text-sm font-medium">Email Address</h3>
              </div>
              <div className="bg-white w-[1px]" />
              <div className="py-3">
                <h5 className="font-bold text-xl break-all">{user?.phone ? formatPhoneNumber(user?.phone) : "-"}</h5>
                <h3 className="text-sm font-medium">Phone Number</h3>
              </div>
            </div>
            <div className="lg:flex justify-between gap-[34px]">
              <div className="py-3">
                <h5 className="font-bold text-xl break-all">Notes</h5>
                <h3 className="text-sm font-medium break-all">{user?.note ?? "-"}</h3>
              </div>
            </div>
          </div>
          <div className="block">
            {/* Patient requests table */}
            <div className="flex justify-between items-center border-b border-docsigna-blue-light mt-10">
              {headers.map((head, index) => (
                <TableHeader
                  title={head.title}
                  index={index}
                  key={index}
                  sortIcon={sortIcons[index]}
                  handleSort={handleSort}
                  extraClassesHeader={head.extraClasses}
                  isClickable={head.isClickable}
                />
              ))}
              <div className="w-auto p-5 py-3">
                <span className="text-base text-docsigna-purple-dark inline-block w-5"></span>
              </div>
            </div>
            {userRequest.data !== undefined && userRequest.data !== null && userRequest?.data?.content?.length > 0 ? (
              <>
                {userRequest.data.content.map((rowData: Request, index: number) => {
                  const textColor = "";
                  const sortedData = {
                    request: rowData.requestNumber ? <span className={textColor}>{rowData.requestNumber}</span> : "-",
                    service: (
                      <RenderName
                        extraClasses={"text-sm " + textColor}
                        title={rowData.service ? rowData.service.name : "--"}
                        subTitle={`PCP: ${
                          rowData.primaryCareProvider
                            ? `${rowData.primaryCareProvider.firstName} ${rowData.primaryCareProvider.lastName}`
                            : "Unassigned"
                        }`}
                      />
                    ),
                    billedto: (
                      <span className={textColor}>
                        {rowData?.serviceBilledToOverride
                          ? billedToOptions[rowData?.serviceBilledToOverride]
                          : rowData?.service?.serviceBilledTo
                          ? billedToOptions[rowData?.service?.serviceBilledTo]
                          : "-"}
                      </span>
                    ),
                    status: <span className={textColor}>{renderState[rowData.state]}</span>,

                    updated: <span className={textColor}>{moment(rowData.updatedDate).format("ll")}</span>,
                    comments: (
                      <>
                        <span className={textColor}>{rowData.numMessages ? rowData.numMessages : 0}</span>
                        {rowData.numUnreadMessages ? (
                          <span className="text-xs ml-1 text-green-700 font-medium py-1 px-2 bg-green-100 rounded-full">
                            {rowData.numUnreadMessages} New
                          </span>
                        ) : null}
                      </>
                    ),
                  };
                  return (
                    <TableRow
                      handleCloseTooltip={() => {}}
                      handleOpenTooltip={() => {}}
                      tooltipVisible={true}
                      tooltipOptions={moreOptions}
                      data={Object.values(sortedData)}
                      key={index}
                      id={index.toString()}
                      extraClassesRow={headers.map(header => header.extraClasses)}
                      handleTooltipClick={() => handleRowClick(rowData.requestId, rowData.officeId)}
                      onRowClick={() => handleRowClick(rowData.requestId, rowData.officeId)}
                    />
                  );
                })}
                <Pagination
                  colSpan={10}
                  count={userRequest.data ? userRequest.data.totalElements : 0}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  page={tableState.pageNumber}
                  rowsPerPage={tableState.rowsPerPage}
                />
              </>
            ) : (
              <DataNotFound image={NoRequestFound} text="No requests found" />
            )}
          </div>
        </div>
        {user && showEditPatientProfile ? (
          <UserSideBar
            onClickOutside={() => {
              !isLoading ? setShowEditPatientProfile(false) : null;
            }}
            title={`${user?.firstName} ${user?.lastName ?? ""}`}
            visible={true}>
            <EditPatientProfile
              userData={user}
              isEditable={true}
              onClickCancel={val => {
                setShowEditPatientProfile(false);
                if (val && patientId && id) getUser(id, patientId);
              }}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          </UserSideBar>
        ) : null}
      </main>
    </>
  );
};

export default ViewPatient;
