import { headersType } from "../../models";

export const headers: headersType[] = [
  {
    title: "Request #",
    extraClasses: "w-full lg:w-240 pl-0",
    sortKey: "requestNumber",
    isClickable: true,
  },
  {
    title: "Service",
    extraClasses: "w-160 hidden pl-2 lg:block break-all",
    sortKey: "service.name",
    isClickable: true,
  },
  {
    title: "Provider",
    extraClasses: "w-160 hidden lg:block  ",
    sortKey: "",
    isClickable: false,
  },
  {
    title: "Status",
    extraClasses: "w-160 hidden lg:block  ",
    sortKey: "",
    isClickable: false,
  },
  {
    title: "Created",
    extraClasses: "w-160 hidden lg:block  ",
    sortKey: "createdDate",
    isClickable: true,
  },
  {
    title: "Comments",
    extraClasses: "w-160 hidden lg:block  ",
    sortKey: "",
    isClickable: false,
  },
];
