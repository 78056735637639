import React, { Component, createRef, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment, { Moment } from "moment";
import { SingleDatePicker, SingleDatePickerShape, isInclusivelyAfterDay } from "react-dates";
import TextInput from "../TextInput/TextInput";
import { formatDateForDatePicker } from "../../utils";
import { useEffect } from "react";
import { ClickAwayListener } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";

interface DatePickerType {
  label: string;
  isRequired: boolean;
  name: string;
  isError: boolean;
  placeholder: string;
  onChange: (date: string) => void;
  onError?: (err: boolean) => void;
  value: string;
  errorMsg?: string;
  maxDate?: string;
  showError?: boolean;
  disabled?: boolean;
  isDob?: boolean;
  isDarkBg?: boolean;
}

const DatePicker = ({
  label,
  isRequired = false,
  name = "",
  placeholder = "",
  onChange = () => {},
  value = "",
  isError = false,
  onError = () => {},
  errorMsg = "",
  showError = true,
  disabled = false,
  isDob = true,
  isDarkBg = false,
}: DatePickerType) => {
  const [focused, setFocused] = useState(false);
  const [month, setMonth] = useState<Moment | null>(value !== "" ? (moment(value).isValid() ? moment(value) : null) : null);
  const [inputVal, setInputVal] = useState<string>(value !== "" ? moment(value).format("MM/DD/YYYY") : "");
  const [futureError, setFutureError] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const ref = createRef<Component<SingleDatePickerShape, any, any>>();

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }: any) => (
    <div style={{ display: "flex", justifyContent: "center" }} onClick={() => setFocused(true)}>
      <div style={{ marginRight: "0.5rem" }}>
        <select
          value={month.month()}
          onChange={e => {
            onMonthSelect(month, parseInt(e.target.value));
          }}>
          {moment.months().map((label, value) => (
            <option key={label} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          value={month.year()}
          onChange={e => {
            onYearSelect(month, parseInt(e.target.value));
          }}>
          {new Array(100).fill(0).map((_, i) => {
            return (
              <option key={i} value={moment().year() - i}>
                {moment().year() - i}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );

  const handleChange = (value: Moment | null) => {
    if (value === null) return;
    setMonth(value);
    setInputVal(`${("0" + (value.month() + 1)).slice(-2)}/${("0" + value.date()).slice(-2)}/${value.year()}`);
    onChange(`${value.year()}-${("0" + (value.month() + 1)).slice(-2)}-${("0" + value.date()).slice(-2)}`);
    setFocused(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatDateForDatePicker(e.currentTarget.value);

    if (formatted.length === 10) {
      setFocused(false);
      const tempMoment = moment(formatted);
      if (tempMoment.isValid()) {
        setMonth(moment(formatted));
        onChange(formatted);
      } else {
        onChange("");
      }
    } else {
      onChange("");
    }
    if (formatted.length < 10) {
      setFocused(true);
      onChange("");
      onError(true);
    }
    setInputVal(formatted);
  };

  const handleBlur = () => {
    if (inputVal.length === 10) {
      // setFocused(false);
    }
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else {
      if (month !== null) {
        if (month.unix() > moment().unix()) {
          setFutureError(true);
          onChange("");
          onError(true);
        } else {
          onChange(`${month.year()}-${("0" + (month.month() + 1)).slice(-2)}-${("0" + month.date()).slice(-2)}`);
          setInputVal(`${("0" + (month.month() + 1)).slice(-2)}/${("0" + month.date()).slice(-2)}/${month.year()}`);
          setFutureError(false);
          onError(false);
        }
      }
    }
  }, [month]);

  useEffect(() => {
    setMonth(value !== "" ? (moment(value).isValid() ? moment(value) : null) : null);
    setInputVal(value !== "" ? moment(value).format("MM/DD/YYYY") : "");
  }, [value]);

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <div className="date relative">
        <TextInput
          onChangeFunc={handleInputChange}
          value={inputVal}
          onFocus={() => setFocused(true)}
          onBlur={() => handleBlur()}
          label={label}
          isRequired={isRequired}
          placeholder={placeholder}
          errorMsg={errorMsg}
          disabled={disabled}
          isError={(isError || futureError) && showError}
          onClick={() => setFocused(true)}
          isDarkBg={isDarkBg}
        />
        {disabled ? null : (
          <div style={{ position: "absolute", top: label ? 28 : 7, right: 5 }} onClick={() => setFocused(!focused)} className="cursor-pointer">
            <DateRangeIcon sx={{ color: "gray" }} />
          </div>
        )}
        <SingleDatePicker
          hideKeyboardShortcutsPanel
          ref={ref}
          initialVisibleMonth={isDob ? () => moment().subtract(30, "years") : null}
          date={isDob ? month : month ? month : moment()}
          onDateChange={date => handleChange(date)}
          focused={focused}
          onFocusChange={() => {}}
          id={name}
          numberOfMonths={1}
          renderMonthElement={renderMonthElement}
          onClose={() => {}}
          isOutsideRange={e => {
            return isInclusivelyAfterDay(e, moment().add(1, "day"));
          }}
          placeholder={placeholder}
        />
      </div>
    </ClickAwayListener>
  );
};

export default DatePicker;
