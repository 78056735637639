import React, { useState, useEffect, useContext } from "react";
import { TextInput, GooglePlaceSearchComponent, Button, Checkbox, ConfirmationDialog } from "../../../components";
import { formatPhoneNumber, numberOnly, validateEmail } from "../../../utils";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { AuthPermission } from "../../../services/auth.service";
import { UpdateOffice } from "../../../models/update-office.model";
import { getOffice, updateOffice } from "../../../redux/slices/OfficeSlice";
import { FormFieldType, formDataInitialState, formDataType, hoursList, officeWeekDayTimings } from "./OfficeProfileData";
import { Phone_Fax_Regex, colors } from "../../../utils/constants";
import RadioInput from "../../../components/RadioInput/RadioInput";
import moment from "moment";
import SelectNew from "../../../components/Select/SelectNew";
import { OfficeService } from "../../../services/office.service";

const STREET_KEY = "route";
const CITY_KEY = ["locality", "sublocality_level_1"];
const STATE_KEY = "administrative_area_level_1";
const ZIP_KEY = ["zip_code", "postal_code"];
const COUNTRY_KEY = "country";
const STREET_NUMBER_KEY = "street_number";
const UNIT = "subpremise";

interface GoogleAddressCompType {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GoogleAPIResponseType {
  address_components: GoogleAddressCompType[];
}

const getKeyValueOfAddress = (data: GoogleAPIResponseType | null, key: string | string[], longValue?: boolean) => {
  if (data === null) return "";

  if (Array.isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      const k = key[i];
      const addressComp: GoogleAddressCompType[] = data["address_components"];
      const keyArr = addressComp?.find(addrCom => addrCom.types.includes(k));
      if (keyArr !== undefined) {
        return keyArr[longValue ? "long_name" : "short_name"];
      }
    }
  } else {
    const addressComp: GoogleAddressCompType[] = data["address_components"];
    const keyArr = addressComp?.find(addrCom => addrCom.types.includes(key));
    return keyArr ? keyArr[longValue ? "long_name" : "short_name"] : "";
  }

  return "";
};

const validateAddress = (value: GoogleAddressCompType[]) => {
  const city = getKeyValueOfAddress({ address_components: value }, CITY_KEY);
  let streetNumber = getKeyValueOfAddress({ address_components: value }, STREET_NUMBER_KEY);
  if (typeof streetNumber !== "string") {
    streetNumber = "";
  }
  const street = getKeyValueOfAddress({ address_components: value }, STREET_KEY, true);
  const unit = getKeyValueOfAddress({ address_components: value }, UNIT);
  const state = getKeyValueOfAddress({ address_components: value }, STATE_KEY);
  const zip = getKeyValueOfAddress({ address_components: value }, ZIP_KEY[0])
    ? getKeyValueOfAddress({ address_components: value }, ZIP_KEY[0])
    : getKeyValueOfAddress({ address_components: value }, ZIP_KEY[1]);
  const country = getKeyValueOfAddress({ address_components: value }, COUNTRY_KEY);
  const addressObj = { city, state, street, zip, country, streetNumber, unit };
  if (!city || !street || !state || !zip || !country || !streetNumber) {
    return null;
  }

  return addressObj;
};

const OfficeProfileTab = () => {
  const dispatch: AppDispatch = useDispatch();
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [formData, setFormData] = useState([...formDataInitialState]);
  const [weekDayTimings, setWeekDayTimings] = useState([...officeWeekDayTimings]);
  const toast = useContext(ToastContext);
  const { currentOffice, loading } = useSelector((state: RootState) => state.office);
  const [readOnly] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const initialAddressData = [
    { short_name: `${currentOffice?.street}`, long_name: `${currentOffice?.street}`, types: ["street_number"] },
    { short_name: `${currentOffice?.street}`, long_name: `${currentOffice?.street}`, types: ["route"] },
    { short_name: `${currentOffice?.city}`, long_name: `${currentOffice?.city}`, types: ["sublocality_level_1", "sublocality", "political"] },
    { short_name: `${currentOffice?.city}`, long_name: `${currentOffice?.city}`, types: ["locality", "political"] },
    { short_name: `${currentOffice?.state}`, long_name: `${currentOffice?.state}`, types: ["administrative_area_level_2", "political"] },
    { short_name: `${currentOffice?.state}`, long_name: `${currentOffice?.state}`, types: ["administrative_area_level_1", "political"] },
    { short_name: `${currentOffice?.country}`, long_name: `${currentOffice?.country}`, types: ["country", "political"] },
    { short_name: `${currentOffice?.zip}`, long_name: `${currentOffice?.zip}`, types: ["postal_code"] },
  ];
  const [addressData, setAddressData] = useState({ address_components: initialAddressData[0].short_name.length ? initialAddressData : [] });
  const [addressValidate, setAddressValidate] = useState(false);
  const [showTemporarilyCloseDialog, setShowTemporarilyCloseDialog] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [reasonText, setReasonText] = useState("");

  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const permissions = currentUser?.permissions;

  const isAuthorizedToEdit = Array.isArray(permissions) && permissions.includes(AuthPermission.Office);

  const validTimings = weekDayTimings.every(item => item.valid);

  const handleChange = (id: string, val: string | boolean) => {
    let tempFormData = [...formData];
    const index = tempFormData.findIndex(item => item.id === id);
    const item = tempFormData.find(item => item.id === id);
    if (index !== -1 && item) {
      let value = val;
      let valid = item.valid;
      switch (id) {
        case "officePhone":
        case "officeFax":
          value = formatPhoneNumber(value as string);
          valid = Phone_Fax_Regex.test(formatPhoneNumber(value as string));
          break;
        case "primaryContactEmail":
          valid = validateEmail(value as string);
          break;
        case "familyPhysician":
          if (value === "Yes") {
            tempFormData = tempFormData.map(item => {
              let newItem = { ...item };
              if (item.id === "specialty") {
                newItem = { ...newItem, value: "", visible: false, required: false, valid: true };
              }
              if (["inPhysicianGroups", "patientRosterSize", "acceptingPatients"].includes(item.id)) {
                newItem = { ...newItem, value: "", visible: true, required: true, valid: false };
              }
              return newItem;
            });
          } else {
            tempFormData = tempFormData.map(item => {
              let newItem = { ...item };
              if (item.id === "specialty") {
                newItem = { ...newItem, value: "", visible: true, required: true, valid: false };
              }
              if (["inPhysicianGroups", "physicianGroups", "patientRosterSize", "acceptingPatients"].includes(item.id)) {
                newItem = { ...newItem, value: "", visible: false, required: false, valid: true };
              }
              return newItem;
            });
          }
          valid = true;
          break;
        case "inPhysicianGroups":
          if (value === "Yes") {
            tempFormData = tempFormData.map(item => {
              let newItem = { ...item };
              if (item.id === "physicianGroups") {
                newItem = { ...newItem, value: "", visible: true, required: true, valid: false };
              }
              return newItem;
            });
          } else {
            tempFormData = tempFormData.map(item => {
              let newItem = { ...item };
              if (item.id === "physicianGroups") {
                newItem = { ...newItem, value: "", visible: false, required: false, valid: true };
              }
              return newItem;
            });
          }
          valid = true;
          break;
        case "yearsInPractice":
          value = numberOnly(value as string);
          valid = value?.trim().length > 0;
          break;
        case "street2":
          value = numberOnly(value as string);
          valid = true;
          break;
        default:
          valid = item.required ? (typeof value === "string" ? value.length > 0 : value === true) : true;
          break;
      }
      tempFormData = [...tempFormData.slice(0, index), { ...item, value, valid }, ...tempFormData.slice(index + 1)];
      setFormData([...tempFormData]);
    }
  };

  const handleChangeTime = (item: any, index: number, key: string, val: string | boolean) => {
    let tempFormData = [...weekDayTimings];
    const inputTime = moment(val as string, "HH:mm:ss");
    if (index !== -1 && item) {
      if (
        (key === "open" && item.close && !inputTime.isBefore(moment(item.close, "HH:mm:ss"))) ||
        (key === "close" && item.open && !inputTime.isAfter(moment(item.open, "HH:mm:ss")))
      ) {
        toast?.openToast(key === "open" ? "Open time can't be after or same as close time." : "Close time can't be before or same as open time.");
        return;
      }
      const newItem = { ...item, [key]: val };
      switch (key) {
        case "open":
        case "close":
          tempFormData = [
            ...tempFormData.slice(0, index),
            {
              ...newItem,
              valid: newItem.open && newItem.close,
            },
            ...tempFormData.slice(index + 1),
          ];
          break;
        case "closed":
          tempFormData = [
            ...tempFormData.slice(0, index),
            {
              ...newItem,
              valid: newItem.closed,
              open: "",
              close: "",
            },
            ...tempFormData.slice(index + 1),
          ];
          break;
        default:
          break;
      }
      setWeekDayTimings([...tempFormData]);
    }
  };

  const validateForm = () => {
    return formData.every(val => val.valid) && (!currentOffice?.tempClosed ? validTimings : true);
  };

  const onPlaceSelect = (places: any) => {
    let addressValue = "";
    places?.address_components?.forEach((data: any) => {
      addressValue += `${data.long_name},`;
    });
    const addressValidation = !!validateAddress(places?.address_components);
    const tempFormData = [...formData];
    const index = tempFormData.findIndex(item => item.id === "officeAddress");
    const item = tempFormData.find(item => item.id === "officeAddress");
    if (item && index !== -1) {
      setFormData([
        ...tempFormData.slice(0, index),
        {
          ...item,
          value: addressValue,
          valid: addressValidation,
        },
        ...tempFormData.slice(index + 1),
      ]);
    }
    setAddressData({ address_components: places?.address_components });
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value?.trim() === "" || e.target.value === null) {
      const tempFormData = [...formData];
      const index = tempFormData.findIndex(item => item.id === "officeAddress");
      const item = tempFormData.find(item => item.id === "officeAddress");
      if (item && index !== -1) {
        setFormData([
          ...tempFormData.slice(0, index),
          {
            ...item,
            value: "",
            valid: false,
          },
          ...tempFormData.slice(index + 1),
        ]);
      }
    }
  };

  const getAddOfficeData = () => {
    return formDataInitialState
      .map(item => item.id)
      .map(key => {
        const foundVal = formData?.find(el => el.id === key)?.value as string;
        return {
          [key]: foundVal ? foundVal : "",
        };
      })
      .reduce((acc, el) => {
        return { ...acc, ...el };
      }, {});
  };

  const getTimings = () => {
    const officeTimingsObject = weekDayTimings.reduce((acc: any, day: any) => {
      if (day.closed) {
        acc[day.key3] = day.closed;
      }
      if (day.open) {
        acc[day.key1] = moment(day.open, "hh:mm A").format("HH:mm:ss");
      }
      if (day.close) {
        acc[day.key2] = moment(day.close, "hh:mm A").format("HH:mm:ss");
      }
      return acc;
    }, {});

    return officeTimingsObject;
  };

  const saveOfficeProfile = () => {
    if (currentOffice?.officeId) {
      const { officeName, officePhone, officeFax, primaryContactEmail, primaryContact, inPhysicianGroups, familyPhysician, acceptingPatients } =
        getAddOfficeData();
      const timings = getTimings();
      const addressValidation = validateAddress(addressData.address_components);
      if (addressValidation === null) {
        setAddressValidate(true);
        return;
      }
      const streetName = `${(addressValidation.streetNumber + " " + addressValidation.street)
        .replace(` ${currentOffice?.street}`, "")
        .substring(0, 98)}${addressValidation?.unit ? ` #${addressValidation?.unit}` : ""}`;
      const dataToSend: UpdateOffice = {
        ...getAddOfficeData(),
        officeId: currentOffice?.officeId,
        name: officeName,
        city: addressValidation.city,
        street: streetName,
        state: addressValidation.state,
        zip: addressValidation.zip,
        country: addressValidation.country,
        phone: officePhone,
        fax: officeFax,
        contactName: primaryContact,
        contactEmail: primaryContactEmail,
        inPhysicianGroups: inPhysicianGroups === "Yes" ? true : false,
        familyPhysician: familyPhysician === "Yes" ? true : false,
        acceptingPatients: acceptingPatients === "Yes" ? true : false,
        ...timings,
      };
      setErrorInitiated(false);
      dispatch(updateOffice(dataToSend)).then(async act => {
        if (act.payload !== null && currentOffice.officeId) {
          await dispatch(getOffice({ officeId: currentOffice.officeId }));
          toast?.openToast("Updated Successfully!");
          setCanEdit(false);
        }
      });
    }
  };

  const renderInput = (inp: formDataType, isReadOnly: boolean, index: number) => {
    if (typeof inp.visible === "undefined" || inp.visible) {
      switch (inp.type) {
        case FormFieldType.SectionHeader:
          return (
            <>
              <div className="flex border-b border-docsigna-purple border-opacity-40 py-1 my-2 col-span-2">
                <h2 className="uppercase text-lg font-bold">{inp.title}</h2>
                {inp.required && canEdit ? <span className="text-red-500">*</span> : null}
              </div>
            </>
          );
        case FormFieldType.TextInput:
          return (
            <>
              <div className="col-span-2 md:col-span-1">
                {isReadOnly ? (
                  <div className="w-full">
                    <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">{inp.title}</label>
                    <h2 className="text-lg font-medium text-docsigna-purple">{inp.value}</h2>
                  </div>
                ) : (
                  <TextInput
                    key={inp.id}
                    value={inp.value as string}
                    onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
                    placeholder={inp.placeholder}
                    errorMsg={inp.errorMessage}
                    isError={!inp.valid && errorInitiated}
                    label={inp.title}
                    isRequired={inp.required}
                    disabled={isReadOnly}
                    onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
                  />
                )}
              </div>
              {inp.id === "officeName" ? <div className="col-span-2 md:col-span-1"></div> : null}
            </>
          );
        case FormFieldType.SelectNew:
          return (
            <>
              {["patientRosterSize"].includes(inp.id) ? <div className="col-span-1" /> : null}
              <div className="col-span-2 md:col-span-1">
                <SelectNew
                  key={inp.id}
                  value={inp.value ? (inp.value as string) : undefined}
                  onSelect={e => handleChange(inp.id, e.currentTarget.value)}
                  placeHolder={inp.placeholder}
                  errorMsg={!inp.valid && errorInitiated ? inp.errorMessage : ""}
                  title={inp.title}
                  required={inp.required}
                  disabled={isReadOnly}
                  options={inp?.newOptions ?? []}
                  readOnly={isReadOnly}
                />
              </div>
            </>
          );
        case FormFieldType.Radio:
          return (
            <>
              {["physicianGroups", "acceptingPatients"].includes(inp.id) ? <div className="col-span-1" /> : null}
              {isReadOnly ? (
                <div className="">
                  <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">{inp.title}</label>
                  <h2 className="text-lg font-medium text-docsigna-purple">{inp.value}</h2>
                </div>
              ) : (
                <div className="col-span-2 md:col-span-1">
                  <RadioInput
                    key={inp.id}
                    value={inp.value as string}
                    onChangeFunc={e => handleChange(inp.id, e)}
                    errorMsg={!inp.valid && errorInitiated ? inp.errorMessage : ""}
                    label={inp.title}
                    isRequired={inp.required}
                    disabled={isReadOnly}
                    options={inp?.options ?? []}
                  />
                </div>
              )}
            </>
          );
        case FormFieldType.Places:
          return isReadOnly ? (
            <div className="col-span-2 md:col-span-1">
              <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">{inp.title}</label>
              <h2 className="text-lg font-medium text-docsigna-purple">{inp.value}</h2>
            </div>
          ) : readOnly ? (
            <div className="col-span-2 md:col-span-1">
              <GooglePlaceSearchComponent
                onPlaceSelected={addr => {
                  onPlaceSelect(addr);
                }}
                onChange={p => {
                  !isReadOnly && onAddressChange(p);
                }}
                defaultValue={inp.value as string}
                isRequired={inp.required}
                errorMsg={inp.errorMessage}
                isError={(!inp.valid && errorInitiated) || addressValidate}
              />
            </div>
          ) : (
            <div className="col-span-2 md:col-span-1">
              <TextInput
                key={inp.id}
                value={inp.value as string}
                onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
                placeholder={inp.placeholder}
                errorMsg={inp.errorMessage}
                isError={!inp.valid && errorInitiated}
                label={inp.title}
                isRequired={inp.required}
                disabled={isReadOnly}
                onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
              />
            </div>
          );

        default:
          return null;
      }
    }
  };

  const populateFormData = (officeData: any) => {
    const data = [...formDataInitialState];
    let newData = data.map(item => {
      const newItem = { ...item };
      switch (item.id) {
        case "officeName":
          newItem.value = officeData.name;
          newItem.valid = true;
          break;
        case "officeAddress":
          const add = `${officeData.street}, ${officeData.city}, ${officeData.state}, ${officeData.zip}, ${currentOffice?.country}`;
          newItem.value = add;
          newItem.valid = true;
          break;
        case "officePhone":
          newItem.value = formatPhoneNumber(officeData.phone as string);
          newItem.valid = Phone_Fax_Regex.test(formatPhoneNumber(officeData.phone as string));
          break;
        case "officeFax":
          newItem.value = formatPhoneNumber(officeData.fax as string);
          newItem.valid = Phone_Fax_Regex.test(formatPhoneNumber(officeData.fax as string));
          break;
        case "primaryContact":
          newItem.value = officeData.contactName;
          newItem.valid = true;
          break;
        case "primaryContactEmail":
          newItem.value = officeData.contactEmail;
          newItem.valid = validateEmail(officeData.contactEmail);
          break;
        case "familyPhysician":
          if (typeof officeData["familyPhysician"] !== "undefined") {
            newItem.value = officeData["familyPhysician"] ? "Yes" : "No";
            newItem.valid = true;
          }
          break;
        case "inPhysicianGroups":
        case "acceptingPatients":
          if (typeof officeData[newItem.id as string] !== "undefined") {
            newItem.value = officeData[newItem.id as string] ? "Yes" : "No";
            newItem.valid = true;
          }
          break;
        case "specialty":
          if (typeof officeData["specialty"] !== "undefined" && officeData["specialty"] !== "") {
            newItem.value = officeData["specialty"];
            newItem.valid = true;
          }
          break;
        default:
          if (typeof officeData[newItem.id as string] !== "undefined") {
            newItem.value = officeData[newItem.id as string];
            newItem.valid = true;
          }
          break;
      }
      return newItem;
    });
    const item = newData.find(item => item.id === "familyPhysician");
    if (item) {
      newData = newData.map(itm => {
        const newItem = { ...itm };
        if (item.value === "Yes") {
          if (typeof officeData[newItem.id as string] !== "undefined") {
            newItem.visible = true;
            switch (newItem.id) {
              case "inPhysicianGroups":
              case "acceptingPatients":
              case "familyPhysician":
                newItem.value = officeData[newItem.id as string] ? "Yes" : "No";
                newItem.valid = true;
                break;
              case "specialty":
                newItem.value = "";
                newItem.valid = true;
                newItem.visible = false;
                break;
              default:
                newItem.value = officeData[newItem.id as string];
                newItem.valid = true;
                break;
            }
          }
        } else {
          if (newItem.id === "specialty" && typeof officeData["specialty"] !== "undefined") {
            newItem.visible = true;
            newItem.value = officeData["specialty"];
            newItem.valid = !!officeData["specialty"];
          }
        }
        return newItem;
      });
    }
    return newData;
  };

  const populateTimingData = (officeData: any) => {
    const data = [...officeWeekDayTimings];
    const newData = data.map(item => {
      const newItem = { ...item };
      if (typeof officeData[newItem.key3] !== "undefined" && officeData[newItem.key3]) {
        newItem.closed = true;
        newItem.valid = true;
      } else {
        if (typeof officeData[newItem.key1] !== "undefined" && !!officeData[newItem.key1]) {
          newItem.open = officeData[newItem.key1];
        }
        if (typeof officeData[newItem.key2] !== "undefined" && !!officeData[newItem.key2]) {
          newItem.close = officeData[newItem.key2];
        }
        newItem.valid = !!newItem.open && !!newItem.close;
      }
      return newItem;
    });
    return newData;
  };

  const setTemporarilyClosed = async (closed: boolean, reason?: string) => {
    setLoading(true);
    if (currentOffice?.officeId) {
      const res = await OfficeService.setOfficeTemporarilyClosed(currentOffice?.officeId, closed, reason);
      if (res) {
        await dispatch(getOffice({ officeId: currentOffice?.officeId }));
      }
    }
    setLoading(false);
  };

  const handleTemporarilyClosed = async () => {
    await setTemporarilyClosed(true, reasonText);
    setReasonText("");
    setShowTemporarilyCloseDialog(false);
  };

  const renderTemporarilyClose = () => {
    return !showPreview ? (
      <div className="">
        <h2 className="font-bold mb-2">
          Please confirm that you wish to close the office.
          <br />
          Provide patients additional details below.
        </h2>
        <TextInput
          maxLenght={150}
          value={reasonText}
          placeholder="Enter reason"
          onChangeFunc={e => setReasonText(e.target.value)}
          isTextArea
          extraInputClass="resize-none"
        />
        <div className="flex justify-end mt-2">
          <span className={`text-sm text-docsigna-orange font-medium cursor-pointer`} onClick={() => setShowPreview(true)}>
            Preview
          </span>
        </div>
        <div className="flex justify-end gap-5 mt-3">
          <Button
            text="Confirm"
            varient={isLoading ? "loading" : "Primary"}
            disabled={!reasonText?.trim() || isLoading}
            onClickFunc={handleTemporarilyClosed}
          />
          <Button text="Cancel" disabled={isLoading} varient="Outlined" onClickFunc={() => setShowTemporarilyCloseDialog(false)} />
        </div>
      </div>
    ) : (
      <>
        <div className="flex flex-col gap-2">
          <h2 className="font-bold">{currentOffice?.name ?? ""} is currently closed.</h2>
          <p>{reasonText}</p>
          <p>Your request will not be reviewed until the office has reopened.</p>
        </div>
        <div className="flex justify-end mt-3">
          <span className={`text-sm text-docsigna-orange font-medium cursor-pointer`} onClick={() => setShowPreview(false)}>
            Close Preview
          </span>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (currentOffice !== null) {
      setCanEdit(!currentOffice?.yearsInPractice ?? false);
      const newFormData = populateFormData(currentOffice);
      const newTimingData = populateTimingData(currentOffice);
      setFormData([...newFormData]);
      setWeekDayTimings(newTimingData);
    }
  }, [currentOffice]);

  useEffect(() => {
    if (currentOffice?.street) {
      setAddressData({ address_components: initialAddressData[0].short_name.length ? initialAddressData : [] });
    }
  }, [currentOffice]);

  return (
    <div className="relative md:flex gap-5">
      <div className="w-full">
        <div className="flex justify-between gap-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
            {formData.map((input, index) => {
              return renderInput(input, !canEdit, index);
            })}
            <div className="flex gap-5 col-span-2">
              <div className="flex border-b border-docsigna-purple border-opacity-40 py-1 w-2/3">
                <h2 className="uppercase text-lg font-bold">OFFICE HOURS</h2>
                {canEdit ? <span className="text-red-500">*</span> : null}
                {errorInitiated && !currentOffice?.tempClosed && !validTimings ? (
                  <p className="ml-5" style={{ color: colors.Red }}>
                    Please provide office hours
                  </p>
                ) : null}
              </div>
              <div className="flex justify-end">
                {!currentOffice?.tempClosed ? (
                  <Button width="fit" onClickFunc={() => setShowTemporarilyCloseDialog(true)} text="Temporarily Close Office" />
                ) : (
                  <Button
                    width="fit"
                    varient={isLoading ? "loading" : "Primary"}
                    onClickFunc={() => setTemporarilyClosed(false)}
                    text="Open Office"
                  />
                )}
              </div>
            </div>
            <div className="relative col-span-2">
              {currentOffice?.tempClosed ? (
                <div className="absolute w-full h-full bg-white/85 backdrop-blur-[2px] text-center flex justify-start items-center">
                  <h2 className="font-bold text-4xl text-docsigna-orange uppercase">
                    Office
                    <br />
                    TEMPORARILY
                    <br />
                    Closed
                  </h2>
                </div>
              ) : null}
              {weekDayTimings.map((item, index) => {
                return (
                  <div key={`${item.id}-${index}-${item.title}`} className="col-span-2 md:flex w-full items-center mb-3">
                    <h2 className="w-4/12">{item.title}</h2>
                    {!canEdit ? (
                      <div className="flex items-center">
                        <h2 className="w-24 text-lg font-medium text-docsigna-purple">
                          {item.closed ? "Closed" : item.open ? moment(item.open, "HH:mm:ss").format("hh:mm A") : ""}
                        </h2>
                        <span className="ml-6 mr-10">-</span>
                        <h2 className="w-24 text-lg font-medium text-docsigna-purple">
                          {item.closed ? "Closed" : item.close ? moment(item.close, "HH:mm:ss").format("hh:mm A") : ""}
                        </h2>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2 md:gap-10">
                        <SelectNew
                          title=""
                          required
                          onSelect={e => handleChangeTime(item, index, "open", e.currentTarget.value)}
                          value={item.open && !item.closed ? item.open : undefined}
                          disabled={item.closed}
                          placeHolder={item.closed ? "-" : "Open"}
                          options={hoursList}
                        />
                        <span>-</span>
                        <SelectNew
                          title=""
                          required
                          onSelect={e => handleChangeTime(item, index, "close", e.currentTarget.value)}
                          value={item.close && !item.closed ? item.close : undefined}
                          disabled={item.closed}
                          placeHolder={item.closed ? "-" : "Close"}
                          options={hoursList}
                        />
                        <Checkbox
                          label="Closed"
                          checked={item.closed}
                          onChange={val => handleChangeTime(item, index, "closed", val.target.checked)}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="py-2">
        {isAuthorizedToEdit ? (
          canEdit ? (
            <Button
              AdditionalClassNames="profile-btn"
              width="fit"
              onClickFunc={() => {
                setErrorInitiated(true);
                if (validateForm()) saveOfficeProfile();
              }}
              disabled={(!validateForm() && errorInitiated) || loading}
              text="Save"
            />
          ) : (
            <Button
              AdditionalClassNames="profile-btn"
              width="fit"
              onClickFunc={() => {
                setCanEdit(true);
              }}
              text="Edit"
            />
          )
        ) : null}
      </div>
      <ConfirmationDialog
        open={showTemporarilyCloseDialog}
        title=""
        description={renderTemporarilyClose()}
        handleFailure={() => undefined}
        handleSuccess={() => undefined}
        successButtonText=""
        failureButtonText=""
        hideActionButtons={false}
      />
    </div>
  );
};

export default OfficeProfileTab;
