import { Provider as ReduxProvider } from "react-redux";
import AuthContextProvider from "./contexts/AuthContext/AuthContext";
import RootNavigator, { patientPaths, systemAdminPaths } from "./navigation";
import { store } from "./redux/store";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";
import "./styles.css";
import ToastContextProvider from "./contexts/ToastContext/ToastContext";
import { Toast } from "./components";
//TODO :: css override for react components
import "./assets/css/extras.css";
import { BrowserRouter } from "react-router-dom";

import { matchRoutes, useLocation } from "react-router-dom";
import { adminPaths, userPaths } from "./navigation";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_ID ?? "G-P0GFTB7E84", {
  titleCase: false,
});

Sentry.init({
  enabled: process.env.NODE_ENV !== "development",
  environment: process.env.REACT_APP_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

export const usePathPattern = () => {
  const location = useLocation();
  const userMatch = matchRoutes(userPaths, location);
  const adminMatch = matchRoutes(adminPaths, location);
  const patientMatch = matchRoutes(patientPaths, location);
  const systemAdminMatch = matchRoutes(systemAdminPaths, location);
  let path: undefined | string = undefined;
  if (userMatch !== null) {
    path = userMatch[0].route.path;
  } else if (adminMatch !== null) {
    path = adminMatch[0].route.path;
  } else if (patientMatch !== null) {
    path = patientMatch[0].route.path;
  } else if (systemAdminMatch !== null) {
    path = systemAdminMatch[0].route.path;
  }
  return { path };
};

function App() {
  return (
    <ReduxProvider store={store}>
      <AuthContextProvider>
        <ToastContextProvider>
          <Toast />
          <BrowserRouter>
            <RootNavigator />
          </BrowserRouter>
        </ToastContextProvider>
      </AuthContextProvider>
    </ReduxProvider>
  );
}

export default App;
