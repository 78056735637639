import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BreadCrumbsType } from "../components/BreadCrumbs/BreadCrumbs";
import { getOffice } from "../redux/slices/OfficeSlice";
import { AppDispatch, RootState } from "../redux/store";
import { usePathPattern } from "../App";

const useBreadCrumbs = () => {
  const { id } = useParams();

  /*
   * if first match is offices and we have an id
   * get the office name
   */
  const dispatch: AppDispatch = useDispatch();
  const { currentOffice, loadingOffice } = useSelector((state: RootState) => state.office);

  useEffect(() => {
    if (id !== undefined && currentOffice === null && !loadingOffice) dispatch(getOffice({ officeId: id }));
  }, [id]);
  const match = usePathPattern();
  const paths = match?.path?.split("/").filter(item => item !== "");
  const breadCrumbs: BreadCrumbsType[] = [];
  paths?.forEach(p =>
    breadCrumbs.push({ title: p === ":id" ? currentOffice?.name : p.replaceAll("-", " "), key: p === ":id" ? `/offices/${id}/requests` : `/${p}` })
  );
  return breadCrumbs;
};

export default useBreadCrumbs;
