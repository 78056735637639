import { CircularProgress } from "@mui/material";
import React from "react";
import { colors } from "../../utils/constants";

interface ButtonPropType {
  text: string;
  onClickFunc: () => void;
  AdditionalClassNames?: string;
  disabled?: boolean;
  width?: "fit" | "full";
  varient?: "Primary" | "Secondary" | "Outlined" | "loading";
  name?: string;
  noBackgroundColor?: boolean;
  hover?: boolean;
  verified?: boolean;
  icon?: React.ReactNode;
}

const Button = ({
  text = "Button",
  onClickFunc = () => {},
  AdditionalClassNames = "",
  disabled = false,
  width = "full",
  varient = "Primary",
  name = "",
  noBackgroundColor = false,
  verified = false,
  icon,
}: ButtonPropType) => {
  let buttonColor = verified
    ? "bg-docnote-lilac hover:bg-docnote-lilac-dark hover:text-white border-docnote-lilac"
    : "bg-docnote-orange hover:bg-white hover:text-docnote-orange border-docnote-orange";

  switch (varient) {
    case "Primary":
    case "loading":
      buttonColor = noBackgroundColor ? "text-white" : `text-white ${buttonColor}`;
      break;
    case "Secondary":
      buttonColor = "border text-white border-docnote-purple-grey bg-docnote-purple-grey hover:bg-white hover:text-docnote-purple-grey";
      break;
    case "Outlined":
      buttonColor = "border text-docnote-orange border-docnote-orange bg-transparent";
      break;
    default:
      break;
  }

  const buttonStyles = `${AdditionalClassNames} ${
    width === "full" ? "w-full" : ""
  } text-sm text-center px-5 py-2.5 font-semibold leading-none rounded-full border shadow-none outline-none transition duration-500 ${buttonColor}`;

  return (
    <div style={{ position: "relative" }}>
      {varient === "loading" ? (
        <CircularProgress size="1.5rem" sx={{ color: colors.Orange, right: 20, top: "0.5rem", position: "absolute", zIndex: 10 }} />
      ) : null}
      <button
        name={name}
        onClick={!disabled && varient !== "loading" ? onClickFunc : () => {}}
        className={buttonStyles}
        style={disabled || varient === "loading" ? { opacity: "37%" } : {}}>
        {icon ?? null}
        {text}
      </button>
    </div>
  );
};

export default Button;
