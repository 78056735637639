import { Link, useLocation } from "react-router-dom";
import { AuthPermission } from "../../services/auth.service";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { UserType } from "../../services/user.service";
import ProtectedComponent from "../ProtectedComponent/ProtectedComponent";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";

const hideNavPaths = ["onboarding", "invitation"];
const adminPathsList = ["users", "offices", "services", "fees", "finance", "search", "forms"];

export interface NavListType {
  id: string;
  title: string;
  key: string;
  permissions: AuthPermission[];
  type?: "item" | "label";
}

const LeftMenu = ({ screenWidth }: { screenWidth: number }) => {
  const toast = useContext(ToastContext);
  const { pathname } = useLocation();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.currentUser.currentUser);
  let tempPath = pathname;
  if (pathname.endsWith("/")) {
    tempPath = pathname.slice(0, pathname.length - 1);
  }
  let currentOfficeId = pathname.split("/")[2] || "temp";

  const adminNavList: NavListType[] = [
    {
      id: "offices",
      title: "Offices",
      key: "offices",
      permissions: [],
    },
    {
      id: "users",
      title: "Users",
      key: "users",
      permissions: [],
    },
    {
      id: "services",
      title: "Services",
      key: "services",
      permissions: [],
    },
    {
      id: "fees",
      title: "Fees",
      key: "fees",
      permissions: [],
    },
    {
      id: "finance",
      title: "Finance",
      key: "finance",
      permissions: [],
    },
    {
      id: "search",
      title: "Search",
      key: "search",
      permissions: [],
    },
    {
      id: "forms",
      title: "Forms",
      key: "forms",
      permissions: [],
    },
    {
      id: "@#@#",
      title: "",
      key: `#`,
      permissions: [],
    },
    {
      id: "#@#@#",
      title: "",
      key: `#`,
      permissions: [],
    },
  ];

  const userNavList: NavListType[] = [
    {
      id: "dashboard",
      title: "Dashboard",
      key: `dashboard`,
      permissions: [],
      type: "label",
    },
    {
      id: "request",
      title: "Requests",
      key: `offices/${currentOfficeId}/requests`,
      permissions: [],
    },
    {
      id: "patients",
      title: "Patients",
      key: `offices/${currentOfficeId}/patients`,
      permissions: [],
    },
    {
      id: "officeManagement",
      title: "Office Management",
      key: `officeManagement`,
      permissions: [],
      type: "label",
    },
    {
      id: "officeInfo",
      title: "Office Info",
      key: `offices/${currentOfficeId}/office-info`,
      permissions: [],
    },
    {
      id: "team",
      title: "Team",
      key: `offices/${currentOfficeId}/team`,
      permissions: [],
    },
    {
      id: "contacts",
      title: "Contacts",
      key: `offices/${currentOfficeId}/contacts`,
      permissions: [],
    },
    {
      id: "forms",
      title: "Forms",
      key: `offices/${currentOfficeId}/forms`,
      permissions: [],
    },
    {
      id: "finance",
      title: "Finance",
      key: `offices/${currentOfficeId}/finance`,
      permissions: [],
    },
    {
      id: "serviceManagement",
      title: "Service Management",
      key: `serviceManagement`,
      permissions: [],
      type: "label",
    },
    {
      id: "services",
      title: "Services",
      key: `offices/${currentOfficeId}/services`,
      permissions: [],
    },
    {
      id: "fees",
      title: "Fees",
      key: `offices/${currentOfficeId}/fees`,
      permissions: [],
    },
    {
      id: "plans",
      title: "Plans",
      key: `offices/${currentOfficeId}/plans`,
      permissions: [],
    },
    {
      id: "@#@#",
      title: "",
      key: `#`,
      permissions: [],
    },
    {
      id: "#@#@#",
      title: "",
      key: `#`,
      permissions: [],
    },
  ];

  const patinetNavList: NavListType[] = [
    {
      id: "dashboard",
      title: "Dashboard",
      key: `patients/${user?.userId}/dashboard`,
      permissions: [],
    },
    {
      id: "request",
      title: "Requests",
      key: `patients/${user?.userId}/requests`,
      permissions: [],
    },
    {
      id: "documents",
      title: "Documents",
      key: `patients/${user?.userId}/documents`,
      permissions: [],
    },
    {
      id: "plans",
      title: "Annual Plan",
      key: `patients/${user?.userId}/plans`,
      permissions: [],
    },
    {
      id: "receipts",
      title: "Receipts",
      key: `patients/${user?.userId}/receipts`,
      permissions: [],
    },
    {
      id: "@#@#",
      title: "",
      key: `#`,
      permissions: [],
    },
    {
      id: "#@#@#",
      title: "",
      key: `#`,
      permissions: [],
    },
    // {
    //   id: "resources",
    //   title: "Resources",
    //   key: `patients/${user?.userId}/resources`,
    //   permissions: [],
    // },
  ];
  let navList = [];
  const pathIsOnTop = adminPathsList.includes(tempPath.split("/").pop() || "") && tempPath.split("/").length <= 2;
  if (user?.userType === UserType.Patient) {
    navList = patinetNavList.filter(item => (process.env.REACT_APP_ENV === "production" ? item.id !== "plans" : true));
  } else {
    navList = pathIsOnTop ? adminNavList : userNavList;
    if (!pathIsOnTop && process.env.REACT_APP_ENV === "production") {
      navList = userNavList.filter(item => item.id !== "plans");
    }
  }

  useEffect(() => {
    if (user !== null) {
      if (user?.offices !== undefined && user.offices.length > 0 && user?.userType !== UserType.Patient) {
        currentOfficeId = user.offices[0].officeId ? user.offices[0].officeId : "";
      }
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.outerWidth > 1023) {
        document.getElementById("mobile-nav")?.classList.add("hidden");
      }
    });
  }, [location]);

  const showOfficeLink = navList.length && screenWidth < 1024 && !pathIsOnTop && user?.userType === UserType.SystemAdmin;

  return (
    <div className="">
      <ul className={`sidebar-menu ${showOfficeLink ? "pt-0" : ""}`}>
        {showOfficeLink ? (
          <li>
            <Link to={"offices"} className={`"text-slate-700" hover:text-docsigna-purple-dark font-medium`}>
              Offices
            </Link>
          </li>
        ) : null}
        {navList.map((item: NavListType, index: number) => {
          const current = pathname.includes(item.key);
          const excludeNav = hideNavPaths.some(v => pathname.includes(v)) || pathname === "/";
          if (excludeNav) return null;
          if (user === undefined || user === null) return null;
          return item?.type === "label" ? (
            <li
              key={`${item.key}-${index}`}
              className="bg-docnote-green text-docsigna-purple p-5 text-xl font-bold uppercase rounded-tl-[10px] rounded-bl-[10px] my-5">
              {item.title}
            </li>
          ) : (
            <li key={`${item.key}-${index}`} className={`${current ? "active" : ""} ml-4`}>
              <ProtectedComponent requiredPermission={item.permissions} key={item.id}>
                {item.key === "#" ? (
                  <button
                    onClick={() => toast?.openToast()}
                    className={`${current ? "text-docsigna-purple-dark" : "text-slate-700"} hover:text-docsigna-purple-dark font-medium`}>
                    {item.title}
                  </button>
                ) : (
                  <Link
                    to={item.key}
                    className={`${current ? "text-docsigna-purple-dark" : "text-slate-700"} hover:text-docsigna-purple-dark font-medium`}>
                    {item.title}
                  </Link>
                )}
              </ProtectedComponent>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LeftMenu;
