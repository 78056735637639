import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logger } from "../../utils/logger";
import { Form } from "../../models/form.model";
import { sortObj } from "./OfficeSlice";
import { FormService } from "../../services/form.service";
import { ApiPage } from "../../models/api-page.model";

const log = logger.getLogger('FormErrors');
interface InitialStateType {
  loading: boolean;
  error: boolean;
  forms: Form[];
  sort: sortObj;
}

const initialState: InitialStateType = {
  loading: false,
  error: false,
  forms: [],
  sort: {
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    searchTerm: '',
    sortedBy: "name,asc",
  }
};

export const getForms = createAsyncThunk(
  "formApi/getForms",
  async ({ searchTerm='', page=0, size=10 }: {searchTerm?:string; page?:number; size?:number; }, { rejectWithValue }) => {
    try {
      const res = await FormService.searchForms(searchTerm, page, size);
      return { data: res, searchTerm };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const createForms = createAsyncThunk(
    "formApi/createForms",
    async ({ fileId, name }: {fileId:string; name:string; }, { rejectWithValue }) => {
      try {
        await FormService.createForm({fileId, name});
        return true;
      } catch (e) {
        log.warn(JSON.stringify(e));
        return false;
      }
    }
  );

  export const deleteForm = createAsyncThunk(
    "formApi/deleteForm",
    async ({ formId }: {formId:string;}, { rejectWithValue }) => {
      try {
        await FormService.deleteForm(formId);
        return true;
      } catch (e) {
        log.warn(JSON.stringify(e));
        return false;
      }
    }
  );

  export const editFormName = createAsyncThunk(
    "formApi/editFormName",
    async ({ formId, name }: {formId:string; name:string}, { rejectWithValue }) => {
      try {
        await FormService.updateFormFileName(formId, name);
        return true;
      } catch (e) {
        log.warn(JSON.stringify(e));
        return false;
      }
    }
  );

  export const getFormUrl = createAsyncThunk(
    "formApi/getFormUrl",
    async ({ formId }: {formId:string;}, { rejectWithValue }) => {
      try {
        const res = await FormService.getForm(formId);
        return res?.fileUrl ?? '';
      } catch (e) {
        log.warn(JSON.stringify(e));
        return '';
      }
    }
  );

export const FormSlice = createSlice({
  name: "formApi",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getForms.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getForms.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getForms.fulfilled, (state, action: PayloadAction<{data:ApiPage<Form>, searchTerm:string}>) => {
        state.loading = false;
        if (action.payload !== null) {
            const {content, totalElements, size, number} = action.payload.data;
            state.forms = content;
            state.sort = {
                ...state.sort,
                pageNumber: number,
                rowsPerPage: size,
                totalElements: totalElements,
                searchTerm: action.payload.searchTerm
            }
        }
      });
  },
});

export default FormSlice.reducer;
