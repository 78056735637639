import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiError, ApiErrorCode } from "../../models/api-error.model";
import { ApiPage, emptyApiPage } from "../../models/api-page.model";
import { CreateUser, JobTitle, User } from "../../models/user.model";
import { AuthPermission, authService } from "../../services/auth.service";
import { UserService, UserType } from "../../services/user.service";
import { handleError } from "./ErrorSlice";
import { OfficeService } from "../../services/office.service";
import { UserUpdate } from "../../models/user-update.model";
import { Request } from "../../models/request.model";
import { setPreviousSelectedOfficeId } from "./OfficeSlice";
import { Timeline } from "../../models/timeline.model";
import { GuestService, GuestToken } from "../../services/guest.service";
import { logger } from "../../utils/logger";

const log = logger.getLogger('UserErrors');

export interface UserDataForRender extends User {
  tooltipVisible: boolean;
}

type UsersList = {
  sortedBy: string;
  data: ApiPage<UserDataForRender> | null;
};
interface ListPatientType {
  userId: string;
  page: number;
  size: number;
  sort: string;
}
type TimeLineList = {
  data: ApiPage<Timeline> | null;
};

export interface UserState {
  error: boolean;
  errorMessage: string;
  errorType: ApiErrorCode;
  loading: boolean;
  apiSucess: boolean;
  invitationTokenDetails: User | null | undefined;
  isUserAccepted: boolean | undefined;
  userRequest: any;
  usersList: UsersList;
  toastVisible: boolean;
  currentUser: User | null;
  pageLoading: boolean;
  patientRequestData: Request | null;
  timeline: TimeLineList;
  jobTitles: JobTitle[];
}

const initialState: UserState = {
  loading: false,
  errorMessage: "",
  error: false,
  errorType: ApiErrorCode.UNKNOWN,
  apiSucess: false,
  invitationTokenDetails: undefined,
  isUserAccepted: undefined,
  userRequest: { data: null, sort: "" },
  usersList: { data: null, sortedBy: "" },
  toastVisible: false,
  currentUser: null,
  pageLoading: false,
  patientRequestData: null,
  timeline: { data: null },
  jobTitles: [],
};

export interface InviteUserType {
  firstName: string;
  lastName: string;
  email: string;
  selectedPermissions: AuthPermission[];
  jobTitleId: string;
  officeId?: string;
  primaryCareProvider: boolean;
  userType?: UserType;
  phone?: string;
}
interface ListUserType {
  page: number;
  size: number;
  sortType: string;
  userState?:string;
}
interface ListOfficeUserType {
  officeId: string;
  page: number;
  size: number;
  sortType: string;
}

interface SearchUserType {
  page: number;
  size: number;
  sortType: string;
  term: string;
  userState?:string;
}
interface SearchOfficeUserType {
  officeId: string;
  page: number;
  size: number;
  sortType: string;
  term: string;
  userType?:UserType;
  userState?:string;
}

type listUsersResponse = { data: ApiPage<User>; sortType: string };

export const createUser = createAsyncThunk(
  "userApi/createUser",
  async (user: CreateUser, _) => {
    try {
      const data = await UserService.createUser(user);
      return data;
    } catch (e:any) {
      log.warn(JSON.stringify(e));
      return e?.message ?? e?.code
    }
  }
);

export const inviteUser = createAsyncThunk(
  "userApi/invite",
  async ({ firstName, lastName, email, selectedPermissions, primaryCareProvider, officeId, userType, jobTitleId }: InviteUserType, { rejectWithValue }) => {
    try {
      //TODO :: need to provide Usertype & primaryCareProvider dynamically
      // IMPORTANT: IN CASE OF MERGE CONFLICT, PLEASE OVERRIDE THIS FILE WITH DOC-73 UPDATED
      await UserService.inviteUser(firstName, lastName, email, jobTitleId, userType, selectedPermissions, primaryCareProvider, officeId);
      return true;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const fetchInvitationTokenDetails = createAsyncThunk(
  "userApi/invitationTokenDetails",
  async ({ invitationToken }: { invitationToken: string }, { rejectWithValue }) => {
    try {
      const response = await UserService.getUserByInviteToken(invitationToken);
      return response ? { ...response, token: invitationToken } : response;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const getUserRequests = createAsyncThunk(
  "userApi/getUserRequests",
  async ({ userId, page, size, sort }: ListPatientType, { rejectWithValue }) => {
    try {
      const response = await UserService.getUserRequests(userId, page, size, sort.length > 0 ? sort : undefined);
      return { data: response, sortType: sort };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);
export const getUserRequest = createAsyncThunk(
  "userApi/getUserRequest",
  async ({ reqeustId, userId }: { userId: string; reqeustId: string }, { rejectWithValue }) => {
    try {
      const res = await UserService.getUserRequest(userId, reqeustId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const acceptInvitation = createAsyncThunk(
  "userApi/acceptInvitation",
  async ({ invitationToken, password }: { invitationToken: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await UserService.acceptUserInvitation(invitationToken, password);
      return response;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const resendInvitation = createAsyncThunk("userApi/resendInvitation", async ({ userId }: { userId: string }, { rejectWithValue }) => {
  try {
    const response = await UserService.resendUserInvitation(userId);
    return response;
  } catch (e) {
    if ((e as ApiError).code === ApiErrorCode.EXISTS) {
      return rejectWithValue(ApiErrorCode.EXISTS);
    } else {
      log.warn(JSON.stringify(e));
      return rejectWithValue(ApiErrorCode.UNKNOWN);
    }
  }
});

export const cancelInvitation = createAsyncThunk("userApi/cancelInvitation", async ({ userId }: { userId: string }, { rejectWithValue }) => {
  try {
    const response = await UserService.cancelUserInvitation(userId);
    return response;
  } catch (e) {
    if ((e as ApiError).code === ApiErrorCode.EXISTS) {
      return rejectWithValue(ApiErrorCode.EXISTS);
    } else {
      log.warn(JSON.stringify(e));
      return rejectWithValue(ApiErrorCode.UNKNOWN);
    }
  }
});

export const listUsers = createAsyncThunk("userApi/list", async ({ page, sortType, size, userState }: ListUserType, { rejectWithValue }) => {
  try {
    const res = await UserService.getAllUsers(page, size, sortType.length > 0 ? sortType : undefined);
    return { data: res, sortType: sortType };
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});

export const listOfficeUsers = createAsyncThunk(
  "userApi/listOfficeUsers",
  async ({ officeId, page, sortType, size }: ListOfficeUserType, { rejectWithValue, dispatch }) => {
    try {
      if (officeId) {
        dispatch(setPreviousSelectedOfficeId(officeId));
        const res = await OfficeService.getOfficeUsers(officeId, page, size, sortType.length > 0 ? sortType : undefined);
        return { data: res, sortType: sortType };
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchUser = createAsyncThunk(
  "userApi/search",
  async ({ term, page, sortType, size, userState }: SearchUserType, { rejectWithValue, dispatch }) => {
    try {
      const res = await UserService.searchUsers(term, page, size, sortType.length > 0 ? sortType : undefined, userState);
      if (res === emptyApiPage()) {
        dispatch(handleError(res));
      }
      return { data: res, sortType: sortType };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchOfficeUser = createAsyncThunk(
  "userApi/searchOfficeUser",
  async ({ officeId, term, page, sortType, size, userState, userType }: SearchOfficeUserType, { rejectWithValue, dispatch }) => {
    try {
      const res = await OfficeService.searchOfficeUsers(officeId, term, page, size, sortType.length > 0 ? sortType : undefined, userState, userType);
      if (res === emptyApiPage()) {
        dispatch(handleError(res));
      }
      return { data: res, sortType: sortType };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const updateUser = createAsyncThunk("userApi/updateUser", async ({ user }: { user: UserUpdate }, { rejectWithValue }) => {
  try {
    const res = await UserService.updateUser(user);
    return res;
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});

export const updateOfficeUser = createAsyncThunk(
  "userApi/updateOfficeUser",
  async ({ officeId, user }: { officeId: string; user: UserUpdate }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.updateOfficeUser(officeId, user);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);
export const getOfficeRequestTimeline = createAsyncThunk(
  "userApi/getOfficeRequestTimeline",
  async ({ officeId, requestId, page, size }: { officeId: string; requestId: string; page: number; size: number }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeRequestTimeline(officeId, requestId, page, size);
      return { data: res };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const addOfficeRequestTimelineEvent = createAsyncThunk(
  "userApi/addOfficeRequestTimelineEvent",
  async ({ event, officeId, requestId }: { event: string; officeId: string; requestId: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.addOfficeRequestTimelineEvent(event, officeId, requestId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const addGuestRequestTimelineEvent = createAsyncThunk(
  "userApi/addGuestRequestTimelineEvent",
  async ({ event, requestId, guestToken}: {event: string, requestId: string, guestToken: GuestToken }, { rejectWithValue }) => {
    try {
      const response = await GuestService.addRequestTimelineEvent(event, requestId, guestToken);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const sendOfficeRequestTextMessage = createAsyncThunk(
  "userApi/sendOfficeRequestTextMessage",
  async ({ officeId, requestId, message }: { officeId: string; requestId: string; message: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.sendOfficeRequestTextMessage(officeId, requestId, message);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const requestRefund = createAsyncThunk(
  "userApi/requestRefund",
  async ({ officeId, requestId, reason, reasonOther }: {officeId:string; requestId: string; reason: string; reasonOther?: string}, { rejectWithValue }) => {
    try {
      const res = OfficeService.refundRequest(officeId, requestId, reason, reasonOther);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getJobTitles = createAsyncThunk(
  "userApi/getJobTitles",
  async (_, { rejectWithValue }) => {
    try {
      const res = UserService.getJobTitles();
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getUserLastAccessDate = createAsyncThunk(
  "userApi/getUserLastAccessDate",
  async ({userId}:{userId: string}, { rejectWithValue }) => {
    try {
      const res = authService.getUserLastAccessDate(userId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return undefined;
    }
  }
);


export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserList: state => {
      state.usersList = { data: null, sortedBy: "" };
    },
    clearPatientRequestData: state => {
      state.patientRequestData = null;
    },
    resetState: state => {
      state.error = false;
      state.errorType = ApiErrorCode.UNKNOWN;
      state.loading = false;
      state.apiSucess = false;
    },
    open: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.toastVisible = true;
      setTimeout(() => {
        state.toastVisible = false;
      }, 2000);
    },
    close: state => {
      state.toastVisible = false;
      state.errorMessage = "";
    },
    openTooltipUser: (state, action: PayloadAction<number>) => {
      if (state.usersList.data)
        state.usersList.data.content = state.usersList.data?.content.map((d, i) => ({ ...d, tooltipVisible: i === action.payload }));
    },
    closeTooltipUser: state => {
      if (state.usersList.data) state.usersList.data.content = state.usersList.data?.content.map(d => ({ ...d, tooltipVisible: false }));
    },
    closeToast: state => {
      state.toastVisible = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(inviteUser.pending, state => {
        //state.loading = true;
        state.error = false;
        state.errorMessage = "";
      })
      .addCase(inviteUser.fulfilled, state => {
        state.loading = false;
        state.error = false;
        state.apiSucess = true;
        state.errorMessage = "User invitation sent";
      })
      .addCase(inviteUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.errorType = action.payload;
        state.loading = false;
      })

      .addCase(getUserRequests.pending, state => {
        state.loading = true;

        state.error = false;
      })
      .addCase(getUserRequests.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = false;
        state.userRequest.data = {
          ...action.payload.data,
          content: action.payload.data.content.map((data: any) => ({ ...data, tooltipVisible: false })),
        };
        const { sort } = action.payload;
        state.userRequest.sortedBy = sort;
      })
      .addCase(getUserRequests.rejected, state => {
        state.error = true;
        state.loading = false;
      })

      .addCase(fetchInvitationTokenDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.invitationTokenDetails = action.payload;
      })
      .addCase(fetchInvitationTokenDetails.rejected, () => {
        //handleError to be called
      })
      .addCase(acceptInvitation.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(acceptInvitation.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isUserAccepted = action.payload;
        state.error = false;
      })
      .addCase(acceptInvitation.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(listUsers.pending, state => {
        if (state.usersList.data === null) {
          state.loading = true;
          state.pageLoading = true;
        }
        state.error = false;
      })
      .addCase(listUsers.fulfilled, (state, action: PayloadAction<listUsersResponse>) => {
        state.loading = false;
        state.error = false;
        state.usersList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        state.usersList.sortedBy = action.payload.sortType;
        state.pageLoading = false;
      })
      .addCase(listUsers.rejected, state => {
        state.error = true;
        state.loading = false;
        state.pageLoading = false;
      })

      .addCase(resendInvitation.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.error = !action.payload;
        if (action.payload === false) {
          state.errorMessage = "Unable to resend invitation";
        } else {
          state.errorMessage = "User invitation sent";
        }
      })
      .addCase(resendInvitation.rejected, () => {
        //handleError
      })
      .addCase(resendInvitation.pending, state => {
        state.error = false;
      })
      .addCase(cancelInvitation.pending, state => {
        state.error = false;
      })
      .addCase(cancelInvitation.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.error = !action.payload;
        if (action.payload === false) {
          state.toastVisible = true;
          state.errorMessage = "Unable to cancel invitation";
        } else {
          state.toastVisible = true;
          state.errorMessage = "User invitation cancelled";
        }
      })
      .addCase(cancelInvitation.rejected, state => {
        //handleErrors
        state.error = true;
      })
      .addCase(searchUser.pending, state => {
        state.error = false;
      })
      .addCase(searchUser.fulfilled, (state, action: PayloadAction<listUsersResponse>) => {
        state.error = false;
        state.usersList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        state.usersList.sortedBy = action.payload.sortType;
      })
      .addCase(searchUser.rejected, state => {
        state.error = true;
      })
      .addCase(listOfficeUsers.pending, state => {
        if (state.usersList.data === null) {
          state.loading = true;
          state.pageLoading = true;
        }
        state.error = false;
      })
      .addCase(listOfficeUsers.fulfilled, (state, action: PayloadAction<listUsersResponse>) => {
        state.loading = false;
        state.error = false;
        state.usersList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        state.usersList.sortedBy = action.payload.sortType;
        state.pageLoading = false;
      })
      .addCase(listOfficeUsers.rejected, state => {
        state.error = true;
        state.loading = false;
        state.pageLoading = false;
      })
      .addCase(searchOfficeUser.pending, state => {
        state.error = false;
      })
      .addCase(searchOfficeUser.fulfilled, (state, action: PayloadAction<listUsersResponse>) => {
        state.error = false;
        state.usersList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        state.usersList.sortedBy = action.payload.sortType;
      })
      .addCase(searchOfficeUser.rejected, state => {
        state.error = true;
      })

      .addCase(getOfficeRequestTimeline.pending, state => {
        state.error = false;
      })
      .addCase(getOfficeRequestTimeline.fulfilled, (state, action: PayloadAction<TimeLineList>) => {
        state.error = false;
        state.timeline = action.payload;
      })
      .addCase(getOfficeRequestTimeline.rejected, state => {
        state.error = true;
      })

      .addCase(addOfficeRequestTimelineEvent.pending, state => {
        state.error = false;
      })
      .addCase(addOfficeRequestTimelineEvent.fulfilled, (state, action: PayloadAction<Timeline | null>) => {
        if (action.payload !== null) {
          state.error = false;
        }
      })
      .addCase(addOfficeRequestTimelineEvent.rejected, state => {
        state.error = true;
      })
      .addCase(sendOfficeRequestTextMessage.pending, state => {
        state.loading = true;
      })
      .addCase(sendOfficeRequestTextMessage.fulfilled, state => {
        state.loading = false;
      })
      .addCase(sendOfficeRequestTextMessage.rejected, state => {
        state.loading = false;
      })
      .addCase(updateUser.pending, state => {
        state.error = false;
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<User | null>) => {
        if (action.payload === null) {
          state.error = true;
        } else {
        }
      })
      .addCase(updateUser.rejected, state => {
        state.error = true;
      })
      .addCase(getUserRequest.pending, state => {
        state.error = false;
        state.loading = true;
        state.pageLoading = true;
      })
      .addCase(getUserRequest.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload === null) {
          state.error = true;
          state.loading = false;
          state.pageLoading = false;
        } else {
          state.patientRequestData = action.payload;
          state.pageLoading = false;
        }
      })
      .addCase(getUserRequest.rejected, state => {
        state.error = true;
        state.loading = false;
        state.pageLoading = false;
      })
      .addCase(requestRefund.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) {
          state.patientRequestData = action.payload;
        }
      })
      .addCase(getJobTitles.fulfilled, (state, action) => {
        state.jobTitles = action.payload?.content ?? [];
      });
  },
});

// Action creators are generated for each case reducer function
export const { resetState, open, close, closeTooltipUser, openTooltipUser, closeToast, clearUserList, clearPatientRequestData } = UserSlice.actions;

export default UserSlice.reducer;
