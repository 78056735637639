import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Logo from "../../assets/images/logo.png";
import moment from "moment";

export const ReportTopHeader = () => {
  return (
    <div className="flex justify-between items-center px-10 pt-2 mb-5">
      <span>
        <img src={Logo} alt="" className="w-40 mt-2" />
      </span>
      <h2 className="font-15 mb-1 font-roboto-regular">
        <span>Date:</span> <span>{moment().format("ll")}</span>
      </h2>
    </div>
  );
};

const ReportHeader = ({ title, selectedMonth }: { title: string; selectedMonth: string }) => {
  const { selectedOffice } = useSelector((state: RootState) => state.office);
  return (
    <>
      <ReportTopHeader />
      <div className="px-10 mb-10">
        <h2 className="font-15 mb-4 font-roboto-regular">Financial Report - {selectedMonth}</h2>
        {selectedOffice ? (
          <>
            <h2 className="font-15 mb-1 font-roboto-regular">
              {selectedOffice?.name}
              <br />
              {selectedOffice?.street}
              <br />
              {selectedOffice.city + ", " + selectedOffice.state + " " + selectedOffice.zip}
            </h2>
            {selectedOffice?.taxNumber ? <h2 className="text-base mb-1">HST # {selectedOffice?.taxNumber}</h2> : null}
          </>
        ) : null}
      </div>
      <div className="w-full h-10 bg-docsigna-purple-dark text-center">
        <h3 className="text-lg font-semibold text-white leading-tight font-roboto-regular">{title}</h3>
      </div>
    </>
  );
};

export default ReportHeader;
