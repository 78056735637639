import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { FormType } from "../../models";
import { UpdateRequest } from "../../models/update-request.model";
import { Office } from "../../models/office.model";
import { Request, RequestPaymentMethod, RequestState, RequestType } from "../../models/request.model";
import { RequestService } from "../../services/request.service";
import {
  SESSION_STORAGE_OFFICE_DATA,
  SESSION_STORAGE_ONBOARDING_EMAIL_VERIFIED,
  SESSION_STORAGE_ONBOARDING_PHONE_VERIFIED,
  SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED,
  SESSION_STORAGE_REQUEST_DATA,
} from "../../utils/constants";
import { RootState } from "../store";
import { GuestService } from "../../services/guest.service";
import { RequestFile } from "../../models/request-file.model";
import { RequestMessage } from "../../models/request-message.model";
import { logger } from "../../utils/logger";
import { OfficeService } from "../../services/office.service";
import { PriceDetails } from "../../models/price-details.model";

const log = logger.getLogger("OnBoardingErrors");
export interface RequestFormInitDataType {
  id: string;
  placeholder: string;
  required: boolean;
  errorMesage: string;
  label: string;
  value: string;
  isValid: boolean;
  cursorPosition: number;
}
export interface GuestToken {
  requestId: string;
  guestToken: string;
}

export const requestFormInitData: RequestFormInitDataType[] = [
  {
    id: "firstName",
    label: "First Name",
    placeholder: "Enter first name",
    required: true,
    errorMesage: "Please provide a first name",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "lastName",
    label: "Last Name",
    placeholder: "Enter last name",
    required: true,
    errorMesage: "Please provide a last name",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "email",
    label: "Email",
    placeholder: "Enter email address",
    required: true,
    errorMesage: "Please enter a valid email address",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "birthday",
    label: "Date of Birth",
    placeholder: "MM/DD/YYYY",
    required: true,
    errorMesage: "Valid date required",
    value: "",
    cursorPosition: 0,
    isValid: true,
  },
  {
    id: "phone",
    label: "Mobile Phone Number",
    placeholder: "Enter mobile number",
    required: true,
    errorMesage: "Please provide a mobile number",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "ohip",
    label: "OHIP Number (Optional)",
    placeholder: "Enter OHIP Number",
    required: false,
    errorMesage: "Valid OHIP number required",
    value: "",
    cursorPosition: 0,
    isValid: true,
  },
  {
    id: "password",
    label: "Password",
    placeholder: "Enter Password",
    required: true,
    errorMesage: "",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "confirmPassword",
    label: "Confirm Password",
    placeholder: "Confirm password",
    required: true,
    errorMesage: "",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "details",
    label: "details",
    placeholder: "details",
    required: true,
    errorMesage: "",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "recipientName",
    label: "recipientName",
    placeholder: "recipientName",
    required: true,
    errorMesage: "",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "recipientContact",
    label: "recipientContact",
    placeholder: "recipientContact",
    required: true,
    errorMesage: "",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "recipientConsent",
    label: "recipientConsent",
    placeholder: "recipientConsent",
    required: true,
    errorMesage: "",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "fileIds",
    label: "fileIds",
    placeholder: "fileIds",
    required: true,
    errorMesage: "",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "requestFormTerms",
    label: "requestFormTerms",
    placeholder: "requestFormTerms",
    required: true,
    errorMesage: "",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
];

export interface OnBoardingState {
  pending: boolean;
  loading: boolean;
  responseData: Request | null;
  user: FormType | null;
  selectedOffice: Office | null;
  invoiceObjectOnboarding: PriceDetails | null;
  requestFormData: RequestFormInitDataType[];
  emailVerified: boolean;
  phoneVerified: boolean;

  GuestToken: GuestToken | null;
  RequestData: Request | null;
  RequestFile: RequestFile[];
  RequestMessage: RequestMessage[];
}

const initialState: OnBoardingState = {
  pending: false,
  loading: false,
  responseData: null,
  user: FormType.ContinueGuest,
  invoiceObjectOnboarding: null,
  selectedOffice:
    sessionStorage.getItem(SESSION_STORAGE_OFFICE_DATA) !== null ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_OFFICE_DATA) || "") : null,
  requestFormData:
    sessionStorage.getItem(SESSION_STORAGE_REQUEST_DATA) !== null
      ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_REQUEST_DATA) || "")
      : requestFormInitData,
  emailVerified:
    sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_EMAIL_VERIFIED) !== null
      ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_EMAIL_VERIFIED) || "").emailVerified
      : false,
  phoneVerified:
    sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_PHONE_VERIFIED) !== null
      ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_PHONE_VERIFIED) || "").phoneVerified
      : false,

  GuestToken: null,
  RequestData: null,
  RequestFile: [],
  RequestMessage: [],
};

export const OnBoardingSlice = createSlice({
  name: "onBoarding",
  initialState,
  reducers: {
    changeUserType: (state, action: PayloadAction<FormType>) => {
      state.user = action.payload;
    },
    clearOnBoardingRequestData: state => {
      state.RequestData = null;
    },
    clearOnboardingInvoiceObject: state => {
      state.invoiceObjectOnboarding = null;
    },
    resetStateOnBoardingSlice: state => {
      state.pending = false;
      state.responseData = null;
      state.user = initialState.user;
      state.requestFormData = requestFormInitData;
      state.selectedOffice = null;
    },
    updateRequestFormData: (state, action: PayloadAction<RequestFormInitDataType[]>) => {
      sessionStorage.setItem(SESSION_STORAGE_REQUEST_DATA, JSON.stringify(action.payload));
      const requestFormFields = [...current(state.requestFormData)].slice(0, state.requestFormData.findIndex(v => v.id === "confirmPassword") + 1);
      const submitRequestFormFields = [...current(state.requestFormData)].slice(
        state.requestFormData.findIndex(v => v.id === "details"),
        state.requestFormData.findIndex(v => v.id === "requestFormTerms") + 1
      );
      if (requestFormFields.some((f, i) => f.value !== action.payload[i].value)) {
        state.emailVerified = false;
        sessionStorage.setItem(SESSION_STORAGE_ONBOARDING_EMAIL_VERIFIED, JSON.stringify({ emailVerified: false }));
      }
      if (submitRequestFormFields.some((f, i) => f.value !== action.payload[i].value)) {
        state.phoneVerified = false;
        sessionStorage.setItem(SESSION_STORAGE_ONBOARDING_PHONE_VERIFIED, JSON.stringify({ phoneVerified: false }));
      }
      state.requestFormData = action.payload;
    },
    getSessionStorage: state => {
      const data = sessionStorage.getItem(SESSION_STORAGE_REQUEST_DATA);
      if (data !== null) {
        state.requestFormData = JSON.parse(data);
      } else {
        state.requestFormData = initialState.requestFormData;
      }
    },
    clearSessionStorage: () => {
      sessionStorage.removeItem(SESSION_STORAGE_REQUEST_DATA);
      sessionStorage.removeItem(SESSION_STORAGE_OFFICE_DATA);
      sessionStorage.removeItem(SESSION_STORAGE_ONBOARDING_EMAIL_VERIFIED);
      sessionStorage.removeItem(SESSION_STORAGE_ONBOARDING_PHONE_VERIFIED);
      sessionStorage.removeItem(SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED);
    },
    updateSelectedOffice: (state, action: PayloadAction<Office>) => {
      state.selectedOffice = action.payload;
      if (action.payload.officeId) {
        sessionStorage.setItem(SESSION_STORAGE_OFFICE_DATA, JSON.stringify(action.payload));
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(submitRequest.pending, state => {
        state.pending = true;
      })
      .addCase(submitRequest.fulfilled, (state, action: PayloadAction<Request | null>) => {
        state.pending = false;
        if (action.payload !== null) {
          state.responseData = action.payload;
        }
      })
      .addCase(submitRequest.rejected, state => {
        state.pending = false;
      })
      .addCase(addRequest.pending, state => {
        state.pending = true;
      })
      .addCase(addRequest.fulfilled, (state, action: PayloadAction<Request | null>) => {
        state.pending = false;
        if (action.payload !== null) {
          state.responseData = action.payload;
        }
      })
      .addCase(addRequest.rejected, state => {
        state.pending = false;
      })

      .addCase(setRequestPaid.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.RequestData = action.payload;
        state.pending = false;
      })
      .addCase(setRequestPaid.pending, state => {
        state.pending = true;
      })
      .addCase(setRequestPaid.rejected, state => {
        state.pending = false;
      })
      .addCase(sendEmailVerificationCode.pending, state => {
        state.pending = true;
      })
      .addCase(sendEmailVerificationCode.fulfilled, state => {
        state.pending = false;
      })
      .addCase(sendEmailVerificationCode.rejected, state => {
        state.pending = false;
      })
      .addCase(verifyEmailCode.pending, state => {
        state.pending = true;
      })
      .addCase(verifyEmailCode.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.pending = false;
        state.emailVerified = action.payload;
        sessionStorage.setItem(SESSION_STORAGE_ONBOARDING_EMAIL_VERIFIED, JSON.stringify({ emailVerified: action.payload }));
      })
      .addCase(verifyEmailCode.rejected, state => {
        state.pending = false;
      })
      .addCase(guestTokenInitiate.pending, state => {
        state.pending = true;
      })
      .addCase(guestTokenInitiate.fulfilled, state => {
        state.pending = false;
      })
      .addCase(guestTokenInitiate.rejected, state => {
        state.pending = false;
      })
      .addCase(getRequest.pending, state => {
        state.loading = true;
        state.pending = true;
      })
      .addCase(getRequest.fulfilled, (state, action: PayloadAction<Request | null>) => {
        state.RequestData = action.payload;
        state.pending = false;
        state.loading = false;
      })
      .addCase(getRequest.rejected, state => {
        state.pending = false;
        state.loading = false;
      })

      .addCase(setRequestState.pending, state => {
        state.loading = true;
        state.pending = true;
      })
      .addCase(setRequestState.fulfilled, (state, action: PayloadAction<Request | null>) => {
        state.RequestData = action.payload;
        state.pending = false;
        state.loading = false;
      })
      .addCase(setRequestState.rejected, state => {
        state.pending = false;
        state.loading = false;
      })
      .addCase(setRequestAcceptedTerms.pending, state => {
        state.loading = true;
        state.pending = true;
      })
      .addCase(setRequestAcceptedTerms.fulfilled, (state, action: PayloadAction<Request | null>) => {
        state.RequestData = action.payload;
        state.pending = false;
        state.loading = false;
      })
      .addCase(setRequestAcceptedTerms.rejected, state => {
        state.pending = false;
        state.loading = false;
      })

      .addCase(getRequestFiles.pending, state => {
        state.pending = true;
      })
      .addCase(getRequestFiles.fulfilled, (state, action: PayloadAction<RequestFile[]>) => {
        state.RequestFile = action.payload;
        state.pending = false;
      })
      .addCase(getRequestFiles.rejected, state => {
        state.pending = false;
      })
      .addCase(getRequestMessages.pending, state => {
        state.pending = true;
      })
      .addCase(getRequestMessages.fulfilled, (state, action: PayloadAction<RequestMessage[]>) => {
        state.RequestMessage = action.payload;
        state.pending = false;
      })
      .addCase(getRequestMessages.rejected, state => {
        state.pending = false;
      })
      .addCase(addRequestMessage.pending, state => {
        state.pending = true;
      })
      .addCase(addRequestMessage.fulfilled, state => {
        state.pending = false;
      })
      .addCase(addRequestMessage.rejected, state => {
        state.pending = false;
      })
      .addCase(addRequestFiles.pending, state => {
        state.pending = true;
      })
      .addCase(addRequestFiles.fulfilled, state => {
        state.pending = false;
      })
      .addCase(addRequestFiles.rejected, state => {
        state.pending = false;
      })
      .addCase(getRequestOffice.pending, state => {
        state.pending = true;
      })
      .addCase(getRequestOffice.fulfilled, (state, action: PayloadAction<Office | null>) => {
        state.pending = false;
        state.selectedOffice = action.payload;
      })
      .addCase(getRequestOffice.rejected, state => {
        state.pending = false;
      })

      .addCase(getRequestInvoice.pending, state => {
        state.pending = true;
      })
      .addCase(getRequestInvoice.fulfilled, (state, action: PayloadAction<PriceDetails | null>) => {
        state.pending = false;
        state.invoiceObjectOnboarding = action.payload;
      })
      .addCase(getRequestInvoice.rejected, state => {
        state.pending = false;
      })
      .addCase(guestTokenVerify.pending, state => {
        state.pending = true;
      })
      .addCase(guestTokenVerify.fulfilled, (state, action: PayloadAction<GuestToken | null>) => {
        state.pending = false;
        state.GuestToken = action.payload;
        sessionStorage.setItem(SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED, JSON.stringify({ GuestToken: action.payload }));
      })
      .addCase(guestTokenVerify.rejected, state => {
        state.pending = false;
      })

      .addCase(sendPhoneVerificationCode.pending, state => {
        state.pending = true;
      })
      .addCase(sendPhoneVerificationCode.fulfilled, state => {
        state.pending = false;
      })
      .addCase(sendPhoneVerificationCode.rejected, state => {
        state.pending = false;
      })
      .addCase(verifyPhoneCode.pending, state => {
        state.pending = true;
      })
      .addCase(verifyPhoneCode.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.pending = false;
        state.phoneVerified = action.payload;
        sessionStorage.setItem(SESSION_STORAGE_ONBOARDING_PHONE_VERIFIED, JSON.stringify({ phoneVerified: action.payload }));
      })
      .addCase(verifyPhoneCode.rejected, state => {
        state.pending = false;
      });
  },
});

export const submitRequest = createAsyncThunk("OnBoarding/submit", async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState() as RootState;
    const requiredData = state.onBoarding.requestFormData;
    const data: UpdateRequest = {
      requestType: RequestType.Standard,
      dateOfBirth: requiredData[requiredData.findIndex(v => v.id === "birthday")].value,
      details: requiredData[requiredData.findIndex(v => v.id === "details")].value,
      email: requiredData[requiredData.findIndex(v => v.id === "email")].value,
      firstName: requiredData[requiredData.findIndex(v => v.id === "firstName")].value,
      lastName: requiredData[requiredData.findIndex(v => v.id === "lastName")].value,
      officeId: state.onBoarding.selectedOffice && state.onBoarding.selectedOffice.officeId ? state.onBoarding.selectedOffice.officeId : "",
      ohipNumber: requiredData[requiredData.findIndex(v => v.id === "ohip")].value,
      phone: requiredData[requiredData.findIndex(v => v.id === "phone")].value,
      fileIds: requiredData[requiredData.findIndex(v => v.id === "fileIds")].value.split(","),
      recipientConsent: requiredData[requiredData.findIndex(v => v.id === "recipientConsent")].value === "true",
      recipientContact:
        requiredData[requiredData.findIndex(v => v.id === "recipientContact")].value === ""
          ? undefined
          : requiredData[requiredData.findIndex(v => v.id === "recipientContact")].value,
      recipientName:
        requiredData[requiredData.findIndex(v => v.id === "recipientName")].value === ""
          ? undefined
          : requiredData[requiredData.findIndex(v => v.id === "recipientName")].value,
      acceptedTerms: requiredData[requiredData.findIndex(v => v.id === "requestFormTerms")].value === "true",
    };

    if (data.fileIds === undefined || (data.fileIds?.length === 1 && data.fileIds[0] === "")) {
      delete data.fileIds;
    }

    const res = await RequestService.addRequest(data);
    if (res === null) {
      rejectWithValue(null);
      return null;
    } else {
      localStorage.setItem("userData", JSON.stringify({ email: res.email, requestNumber: res.requestNumber }));
      dispatch(clearSessionStorage());
      dispatch(resetStateOnBoardingSlice());
      return res;
    }
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});

export const addRequest = createAsyncThunk("OnBoarding/addRequest", async ({ request }: { request: UpdateRequest }, { rejectWithValue }) => {
  try {
    const response = await RequestService.addRequest(request);
    return response;
  } catch (error) {
    log.warn(JSON.stringify(error));
    return rejectWithValue(error);
  }
});

export const updateRequest = createAsyncThunk(
  "OnBoarding/updateRequest",
  async ({ request, requestId, officeId }: { request: UpdateRequest; requestId: string; officeId: string }, { rejectWithValue }) => {
    try {
      const response = await OfficeService.updateRequest(request, requestId, officeId);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const sendEmailVerificationCode = createAsyncThunk(
  "OnBoarding/send-email-verification-code",
  async ({ email, firstName, lastName }: { email: string; firstName?: string; lastName?: string }, { rejectWithValue }) => {
    try {
      const response = await GuestService.sendEmailVerificationCode(email, firstName, lastName);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);
export const verifyEmailCode = createAsyncThunk(
  "OnBoarding/verify-email-code",
  async ({ email, code }: { email: string; code: string }, { rejectWithValue }) => {
    try {
      const response = await GuestService.verifyEmailCode(email, code);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);
export const guestTokenVerify = createAsyncThunk(
  "OnBoarding/guestTokenVerify",
  async ({ email, requestNumber, code }: { email: string; requestNumber: string; code: string }, { rejectWithValue }) => {
    try {
      const response = await GuestService.guestTokenVerify(email, requestNumber, code);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const guestTokenInitiate = createAsyncThunk(
  "OnBoarding/guestTokenInitiate",
  async ({ email, requestNumber }: { email: string; requestNumber: string }, { rejectWithValue }) => {
    try {
      const response = await GuestService.guestTokenInitiate(email, requestNumber);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const getRequest = createAsyncThunk("onBoarding/getRequest", async ({ guestToken }: { guestToken: GuestToken }, { rejectWithValue }) => {
  try {
    const response = await GuestService.getRequest(guestToken);
    return response;
  } catch (error) {
    log.warn(JSON.stringify(error));
    return rejectWithValue(error);
  }
});

export const getRequestFiles = createAsyncThunk(
  "onBoarding/getRequestFiles",
  async ({ guestToken }: { guestToken: GuestToken }, { rejectWithValue }) => {
    try {
      const response = await GuestService.getRequestFiles(guestToken);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const getRequestMessages = createAsyncThunk(
  "onBoarding/getRequestMessages",
  async ({ guestToken }: { guestToken: GuestToken }, { rejectWithValue }) => {
    try {
      const response = await GuestService.getRequestMessages(guestToken);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const setRequestMessageRead = createAsyncThunk(
  "onBoarding/setRequestMessageRead",
  async ({ guestToken, messageId, read }: { guestToken: GuestToken; messageId: string; read: boolean }, { rejectWithValue }) => {
    try {
      const response = await GuestService.setRequestMessageRead(guestToken, messageId, read);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const addRequestMessage = createAsyncThunk(
  "onBoarding/addRequestMessage",
  async ({ guestToken, senderName, message }: { guestToken: GuestToken; senderName: string; message: string }, { rejectWithValue }) => {
    try {
      const response = await GuestService.addRequestMessage(guestToken, senderName, message);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const addRequestFiles = createAsyncThunk(
  "onBoarding/addRequestFiles",
  async ({ guestToken, fileIds }: { guestToken: GuestToken; fileIds: string[] }, { rejectWithValue }) => {
    try {
      const response = await GuestService.addRequestFiles(guestToken, fileIds);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const getRequestOffice = createAsyncThunk(
  "onBoarding/getRequestOffice",
  async ({ guestToken }: { guestToken: GuestToken }, { rejectWithValue }) => {
    try {
      const response = await GuestService.getRequestOffice(guestToken);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);
export const getRequestInvoice = createAsyncThunk(
  "onBoarding/getRequestInvoice",
  async ({ guestToken }: { guestToken: GuestToken }, { rejectWithValue }) => {
    try {
      const response = await GuestService.getPriceDetails(guestToken);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const setRequestPaid = createAsyncThunk(
  "onBoarding/setRequestPaid",
  async ({ guestToken, paymentMethod }: { guestToken: GuestToken; paymentMethod: RequestPaymentMethod }, { rejectWithValue }) => {
    try {
      const response = await GuestService.setRequestPaid(guestToken, paymentMethod);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);
export const setRequestState = createAsyncThunk(
  "onBoarding/setRequestState",
  async ({ guestToken, state }: { guestToken: GuestToken; state: RequestState }, { rejectWithValue }) => {
    try {
      const response = await GuestService.setRequestState(guestToken, state);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);
export const setRequestAcceptedTerms = createAsyncThunk(
  "onBoarding/setRequestAcceptedTerms",
  async ({ guestToken, acceptedTerms }: { guestToken: GuestToken; acceptedTerms: boolean }, { rejectWithValue }) => {
    try {
      const response = await GuestService.setRequestAcceptedTerms(guestToken, acceptedTerms);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const sendPhoneVerificationCode = createAsyncThunk(
  "OnBoarding/send-phone-verification-code",
  async ({ phone }: { phone: string }, { rejectWithValue }) => {
    try {
      const response = await GuestService.sendPhoneVerificationCode(phone);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);
export const verifyPhoneCode = createAsyncThunk(
  "OnBoarding/verify-phone-code",
  async ({ phone, code }: { phone: string; code: string }, { rejectWithValue }) => {
    try {
      const response = await GuestService.verifyPhoneCode(phone, code);
      return response;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const submitWebsiteContactForm = createAsyncThunk(
  "OnBoarding/submitWebsiteContactForm",
  async (
    { name, email, phone, office, message }: { name: string; email: string; phone: string; office: string; message: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await GuestService.submitWebsiteOfficeContactForm(name, email, phone,office, message);
      return resp;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const submitWebsitePublicContactForm = createAsyncThunk(
  "OnBoarding/submitWebsitePublicContactForm",
  async (
    { name, email, phone, office, message, agreeUpdates }: { name: string; email: string; phone: string; office: string; message: string; agreeUpdates:boolean },
    { rejectWithValue }
  ) => {
    try {
      const resp = await GuestService.submitWebsitePublicContactForm(name, email, phone,office, message, agreeUpdates);
      return resp;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const {
  changeUserType,
  resetStateOnBoardingSlice,
  updateRequestFormData,
  getSessionStorage,
  clearSessionStorage,
  updateSelectedOffice,
  clearOnboardingInvoiceObject,
  clearOnBoardingRequestData,
} = OnBoardingSlice.actions;

export default OnBoardingSlice.reducer;
