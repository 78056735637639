import { useState } from "react";
import { headersType } from "../../models";
import { TableHeader, TableRow } from "../TableComponents/TableComponents";
import LoadMore from "../../assets/images/load-more-arrow.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import moment from "moment";
import { formatCurrency, getSorting, sortArrayByKey } from "../../utils";
import { ServiceBilledTo, ServiceBilledToName } from "../../models/service.model";
import { useNavigate } from "react-router-dom";
import { TopOverdueAccounts } from "../../models/finance.model";

export const reportHeaders: headersType[] = [
  {
    title: "Request #",
    extraClasses: "w-full pl-0 text-sm font-medium text-docsigna-purple",
    sortKey: "requestNumber",
    isClickable: true,
    titleColor: "text-docsigna-purple",
  },
  {
    title: "Date",
    extraClasses: "w-full pl-0 text-sm font-medium text-docsigna-purple",
    sortKey: "date",
    isClickable: true,
    titleColor: "text-docsigna-purple",
  },
  {
    title: "Days Overdue",
    extraClasses: "w-full pl-0 text-sm font-medium text-docsigna-purple",
    sortKey: "daysOverdue",
    isClickable: true,
    titleColor: "text-docsigna-purple",
  },
  {
    title: "Patient name",
    extraClasses: "w-240 pl-0 text-sm font-medium text-docsigna-purple truncate break-all",
    sortKey: "patientName",
    isClickable: true,
    titleColor: "text-docsigna-purple",
  },
  {
    title: "Payee",
    extraClasses: "w-full pl-0 text-sm font-medium text-docsigna-purple",
    sortKey: "payee",
    isClickable: true,
    titleColor: "text-docsigna-purple",
  },
  {
    title: "Total",
    extraClasses: "w-full pl-0 text-sm font-medium text-docsigna-purple",
    sortKey: "total",
    isClickable: true,
    titleColor: "text-docsigna-purple",
  },
];

const filterData = [
  {
    label: "30+ Days",
    value: 30,
    max: 59,
    selected: false,
  },
  {
    label: "60+ Days",
    value: 60,
    max: 89,
    selected: false,
  },
  {
    label: "90+ Days",
    value: 90,
    max: 119,
    selected: false,
  },
  {
    label: "120+ Days",
    value: 120,
    max: Infinity,
    selected: false,
  },
];

const OfficeFinanceAccountReceivables = ({ loadMoreData }: { loadMoreData: (isPagination: boolean, sortBy?: string) => void }) => {
  const navigate = useNavigate();
  const { accountReceivables, arSort } = useSelector((state: RootState) => state.finance);
  const { currentOffice } = useSelector((state: RootState) => state.office);
  const [extraClasses] = useState<string[]>(reportHeaders.map(head => `${head.extraClasses} text-[15px]`));
  const [filters, setFilters] = useState(filterData);
  const [orderBy, setOrderBy] = useState("requestNumber,desc");
  const [sortMethods, setSortMethods] = useState<boolean[]>(reportHeaders.map(() => false));
  const [sortKeys] = useState(reportHeaders.map(head => head.sortKey));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(reportHeaders.findIndex(item => item.sortKey === orderBy));

  const handleFilter = (item: any) => {
    const index = filters.findIndex(itm => itm.value === item.value);
    if (index !== -1 && item) {
      setFilters([...filters.slice(0, index), { ...item, selected: !item.selected }, ...filters.slice(index + 1)]);
    }
  };

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));

  const handleSort = (index: number) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    setActiveSortIndex(index);
    setOrderBy(sortMethodString);
  };

  const selectedFilters = filters.filter(filter => filter.selected);
  // Filter out the requests based on all selected filter values
  let filteredRequests = accountReceivables;
  // If no filters are selected, return all data
  if (selectedFilters.length > 0) {
    filteredRequests = accountReceivables.filter(item => {
      return selectedFilters.some(filter => item.daysOverdue >= filter.value && item.daysOverdue <= filter.max);
    });
  }

  const orderByOptions = orderBy.split(",");
  filteredRequests = sortArrayByKey(filteredRequests as TopOverdueAccounts[], orderByOptions[0] as keyof TopOverdueAccounts, orderByOptions[1]);
  return (
    <div className="mt-10 mb-5">
      <div className="p-5 pb-10 rounded bg-docsigna-pink-light">
        <div className="flex gap-10 items-center mb-3">
          <h2 className="text-lg font-semibold text-docnote-purple heading uppercase">ACCOUNTS RECEIVABLE</h2>
          <div className="flex gap-6">
            {filters.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleFilter(item)}
                  className={`rounded-[10px] py-2 px-5 border font-bold ${
                    item.selected ? "text-white bg-docnote-orange hover:text-white hover:bg-docnote-orange" : "text-docnote-orange"
                  } border-docnote-orange`}>
                  {item.label}
                </button>
              );
            })}
          </div>
        </div>
        <div className="flex justify-between items-center relative">
          {reportHeaders.map((head, index) => (
            <TableHeader
              title={head.title}
              index={index}
              key={index}
              sortIcon={sortIcons[index]}
              handleSort={handleSort}
              extraClassesHeader={head.extraClasses}
              isClickable={head.isClickable}
              titleColor={head.titleColor}
            />
          ))}
        </div>
        {filteredRequests.length > 0 ? (
          filteredRequests.map((item, index: number) => {
            const sortedData = {
              requestNo: item.requestNumber,
              date: moment(item.date).format("ll"),
              overdueDays: item.daysOverdue,
              patientName: item.patientName,
              payee: ServiceBilledToName[item.payee as ServiceBilledTo],
              total: formatCurrency((item.total / 100)?.toString(), "blur"),
            };
            return (
              <TableRow
                data={Object.values(sortedData)}
                key={index}
                onRowClick={() => navigate(`/offices/${currentOffice?.officeId}/requests/${item.requestId}`)}
                tooltipVisible={false}
                handleOpenTooltip={() => null}
                handleCloseTooltip={() => null}
                id={`${index}`}
                extraClassesRow={extraClasses}
                parentExtraClasses="border-docsigna-purple border-dotted"
              />
            );
          })
        ) : (
          <div className="flex justify-center items-center font-bold">
            <p>No results found</p>{" "}
          </div>
        )}
        {!arSort.last ? (
          <div className="absolute flex justify-center w-full left-0 -bottom-8">
            <img src={LoadMore} alt="" className="cursor-pointer" onClick={() => loadMoreData(true)} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OfficeFinanceAccountReceivables;
