import { Button, ConfirmationDialog, Pagination, ProtectedComponent, TableHeader, TableRow, TextInput, UserSideBar } from "../../components";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { AuthPermission } from "../../services/auth.service";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { headers } from "./ContactsData";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddEditContact from "../../components/forms/AddEditContact/AddEditContact";
import { Contact } from "../../models/contact-model";
import { ContactService } from "../../services/contact.service";
import { useDebouncedCallback } from "use-debounce";
import { formatAddress, getSorting } from "../../utils";
import { ToastVariants } from "../../models";
import { useParams } from "react-router-dom";

const ContactsScreen = () => {
  const toast = useContext(ToastContext);
  const { id: officeId } = useParams();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showUploadBox, setShowUploadBox] = useState(false);
  const [showCreateContact, setShowCreateContact] = useState(false);
  const [openDeleteContactDialog, setOpenDeleteContactDialog] = useState(false);
  const [tooltipStates, setTooltipStates] = useState<boolean[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>(undefined);
  const [activeColumn, setActiveColumn] = useState<number>(-1);
  const [sortMethods, setSortMethods] = useState([true, false, false, false, false]);
  const [sort, setSort] = useState({
    totalElements: 0,
    page: 0,
    size: 10,
    sortBy: "firstName,asc",
  });

  const sortKeys = headers.map(head => head.sortKey);
  const extraClasses = headers.map(head => head.extraClasses);
  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeColumn === index ? (item ? headerIconAsc : headerIconDesc) : null));

  const debounced = useDebouncedCallback(value => {
    load(sort.page, sort.size, sort.sortBy, value);
  }, 1000);

  const handleSearchInput = (text: string) => {
    const input = text;
    const oldSearchTerm = searchTerm;
    setSearchTerm(input);
    if (input && input.trim().length >= 2) {
      debounced(input);
    } else if (oldSearchTerm !== "" && input.length <= 1) {
      load(sort.page, sort.size, sort.sortBy);
    }
  };

  const UploadBox = () => {
    return (
      <div>
        <p>Upload address book CSV</p>
        <div className="">
          <div className="text-center bg-docnote-pink-dark rounded my-5 relative">
            <input type="file" className="text-base rounded-md w-full h-full py-6 px-4 opacity-0 relative z-10" onChange={() => toast?.openToast()} />
            <div className="w-full h-full py-6 px-4 absolute top-0 flex items-center justify-center">
              <AttachFileIcon className="rotate-[45deg]" />
              <p className="ml-1 mt-1">Upload attachment here</p>
            </div>
          </div>
        </div>
        <div className="flex gap-5 justify-end mt-5">
          <Button width="fit" varient="Secondary" text="Cancel" onClickFunc={() => setShowUploadBox(false)} />
          <Button width="fit" varient="Primary" text="Confirm" onClickFunc={() => toast?.openToast()} />
        </div>
      </div>
    );
  };

  const load = async (page = 0, size = 10, sort = "firstName,asc", term = "") => {
    const resp = await ContactService.searchContacts(officeId ?? "", page, size, sort, term);
    setContacts(resp.content);
    setSort({
      totalElements: resp.totalElements,
      size: resp.size,
      page: resp.number,
      sortBy: sort,
    });
    setTooltipStates(new Array(resp?.content ? resp.content.length : 0).fill(false));
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    load(newPage, sort.size, sort.sortBy, searchTerm);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    load(sort.page, parseInt(event.target.value, 10), sort.sortBy, searchTerm);
  };

  const onHeaderClick = (index: number) => {
    if (sortKeys[index] === "") return;
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setActiveColumn(index);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    load(sort.page, sort.size, sortMethodString, searchTerm);
  };

  const handleToolTipOpen = (index: number) => {
    setTooltipStates([...tooltipStates.slice(0, index).fill(false), true, ...tooltipStates.slice(index + 1).fill(false)]);
  };

  const handleToolTipClose = () => {
    setTooltipStates([...tooltipStates].fill(false));
  };

  const handleTooltipClick = (option: string, id: string) => {
    const contact = contacts.find(item => item.contactId === id);
    if (contact) setSelectedContact(contact);
    switch (option) {
      case "Edit Contact":
        setShowCreateContact(true);
        break;
      case "Remove Contact":
        setOpenDeleteContactDialog(true);
        break;
      default:
        toast?.openToast();
        break;
    }
    setTooltipStates([...tooltipStates].fill(false));
  };

  const handleDeleteContact = async () => {
    if (selectedContact && officeId) {
      const resp = await ContactService.deleteContact(officeId, selectedContact?.contactId);
      if (resp) {
        toast?.openToast("Contact deleted successfully!", 2000, ToastVariants.Success);
        load(sort.page, sort.size, sort.sortBy, searchTerm);
      } else {
        toast?.openToast("Something went wrong!", 2000, ToastVariants.Error);
      }
    }
    setSelectedContact(undefined);
    setOpenDeleteContactDialog(false);
  };

  const handleCloseSidebar = () => {
    setSelectedContact(undefined);
    setShowCreateContact(false);
  };

  useEffect(() => {
    load(sort.page, sort.size, sort.sortBy);
  }, []);

  return (
    <main>
      <div className="container mx-auto p-8">
        <OfficeSectionsHeader showMeta={true} />
        <div className="block lg:flex justify-between items-center mb-6">
          <div className="w-full lg:w-1/3 mb-5 lg:mb-0">
            <h2 className="heading mb-0 lg:mb-5 uppercase">Contacts</h2>
            <p className="text-base font-medium">Manage your 3rd party contacts here.</p>
          </div>
          <div className="block md:flex lg:justify-end w-full lg:w-2/3">
            <div className="flex items-center">
              <div className="relative w-full">
                <TextInput
                  onChangeFunc={e => {
                    handleSearchInput(e.currentTarget.value);
                  }}
                  value={searchTerm}
                  placeholder="Search contact"
                  extraInputClass="w-full"
                  isSearchInput={true}
                />
              </div>
            </div>
            <ProtectedComponent requiredPermission={[AuthPermission.Service]}>
              <div className="block md:flex md:justify-end items-center ml-0 md:ml-5">
                <span className="md:mr-5 font-medium text-center hidden md:inline-block">or</span>
                <Button
                  text="Add Contact"
                  onClickFunc={() => setShowCreateContact(true)}
                  AdditionalClassNames="whitespace-nowrap w-auto mt-3 md:mt-0"
                />
              </div>
            </ProtectedComponent>
          </div>
        </div>
        <div className="block">
          <div className="flex justify-between items-center border-b border-docsigna-blue-light">
            {headers.map((head, index: number) => (
              <TableHeader
                title={head.title}
                index={index}
                key={index}
                sortIcon={sortIcons[index]}
                isClickable={head.isClickable}
                handleSort={onHeaderClick}
                extraClassesHeader={extraClasses[index]}
                isCheckbox={head?.isCheckbox}
                selectAll={() => toast?.openToast()}
              />
            ))}
          </div>
          {contacts.map((item: Contact, index) => {
            const { street1, street2, city, province, postalCode } = item;
            const sortedData = {
              company: `${item.company ?? "-"}`,
              name: `${item.firstName ?? "-"} ${item.lastName ? item.lastName : ""}`,
              address: formatAddress({ street1, street2, city, province, postalCode }),
              email: `${item.email ?? "-"}`,
              fax: `${item.fax ?? "-"}`,
            };
            return (
              <TableRow
                data={Object.values(sortedData)}
                key={index}
                id={item.contactId}
                extraClassesRow={extraClasses}
                tooltipOptions={["Edit Contact", "Remove Contact"]}
                handleTooltipClick={(option, id) => handleTooltipClick(option, id)}
                handleCloseTooltip={handleToolTipClose}
                handleOpenTooltip={() => handleToolTipOpen(index)}
                tooltipVisible={tooltipStates[index]}
                onRowClick={id => handleTooltipClick("Edit Contact", id ?? "")}
              />
            );
          })}
          <Pagination
            colSpan={10}
            count={sort.totalElements}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            page={sort.page}
            rowsPerPage={sort.size}
          />
        </div>
      </div>
      <UserSideBar
        visible={showCreateContact}
        LeftbuttonText="Cancel"
        RightbuttonText="Add"
        onCancelClick={handleCloseSidebar}
        onClickOutside={handleCloseSidebar}
        title={`${selectedContact ? "Edit Contact" : "Create Contact"}`}>
        <AddEditContact
          isEditable={true}
          onClickCancel={val => {
            if (val) {
              load(sort.page, sort.size, sort.sortBy, searchTerm);
            }
            handleCloseSidebar();
          }}
          officeId={officeId}
          selectedContact={selectedContact}
        />
      </UserSideBar>
      <ConfirmationDialog
        maxWidth="lg"
        successButtonText=""
        failureButtonText=""
        open={showUploadBox}
        description={<UploadBox />}
        handleSuccess={() => null}
        handleFailure={() => null}
        showCrossIcon={false}
      />
      <ConfirmationDialog
        maxWidth="lg"
        successButtonText="Delete"
        failureButtonText="Cancel"
        open={openDeleteContactDialog}
        title="This will permanently delete this contact, proceed?"
        description={""}
        handleSuccess={handleDeleteContact}
        handleFailure={() => setOpenDeleteContactDialog(false)}
        showCrossIcon={false}
      />
    </main>
  );
};

export default ContactsScreen;
