import { useEffect, useState } from "react";
import { Button, ProtectedComponent, Tabs, UserSideBar } from "../../components";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { AuthPermission } from "../../services/auth.service";
import Plans from "./Plans";
import Subscribers from "./Subscribers";
import Services from "./Services";
import AddPlan from "../../components/forms/AddPlan/AddPlan";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getSubscriptionTypes } from "../../redux/slices/BlockFeeSlice";
import { BlockFee } from "../../models/block-fee.model";
import { useParams } from "react-router-dom";
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import { useSelector } from "react-redux";
import { searchBlockFeeServices } from "../../redux/slices/ServiceSlice";
import { searchBlockFeeSubscribers } from "../../redux/slices/OfficeSlice";

const OfficePlan = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { blockFeeServicesSortObj } = useSelector((state: RootState) => state.service);
  const { subscribersSortObj } = useSelector((state: RootState) => state.office);
  const [activeIndex, setActiveIndex] = useState(0);
  const [createPlan, setCreatePlan] = useState(false);
  const [editPlan, setEditPlan] = useState<BlockFee | null>(null);
  const [loading, setLoading] = useState(false);

  const changeServiceTab = (index: number) => {
    setActiveIndex(index);
  };

  const handleFinalSearch = (val: string) => {
    if (id)
      switch (activeIndex) {
        case 0:
          break;
        case 1:
          dispatch(
            searchBlockFeeSubscribers({
              page: 0,
              size: subscribersSortObj.rowsPerPage,
              sort: subscribersSortObj.sortedBy,
              term: val,
              officeId: id,
            })
          );
          break;
        case 2:
          dispatch(
            searchBlockFeeServices({
              page: 0,
              size: blockFeeServicesSortObj.rowsPerPage,
              sort: blockFeeServicesSortObj.sortedBy,
              term: val,
              officeId: id,
            })
          );
          break;
        default:
          break;
      }
  };

  useEffect(() => {
    dispatch(getSubscriptionTypes());
  }, [dispatch]);

  const handleClose = () => {
    setLoading(false);
    setCreatePlan(false);
    setEditPlan(null);
  };

  let searchPlaceHolder = "Search Plans";
  let searchValue = "";
  switch (activeIndex) {
    case 1:
      searchPlaceHolder = "Search Subscribers";
      searchValue = subscribersSortObj?.searchTerm ?? "";
      break;
    case 2:
      searchPlaceHolder = "Search Services";
      searchValue = blockFeeServicesSortObj.searchTerm ?? "";
      break;
    default:
      break;
  }

  return (
    <div className="container mx-auto p-8">
      <OfficeSectionsHeader showMeta={true} />
      <div className="block lg:flex justify-between items-center mb-6">
        <div className="w-full lg:w-1/3 mb-5 lg:mb-0">
          <h2 className="heading mb-0 lg:mb-5 uppercase">Block Fee</h2>
          <p className="text-base font-medium">Manage your block fee plan options here.</p>
        </div>
        <div className="block md:flex lg:justify-end w-full lg:w-2/3">
          {activeIndex !== 0 ? (
            <div className="flex items-center">
              <div className="relative w-full">
                <SearchComponent placeholder={searchPlaceHolder} value={searchValue} handleFinalSearch={handleFinalSearch} />
              </div>
            </div>
          ) : null}
          {activeIndex === 0 ? (
            <>
              <ProtectedComponent requiredPermission={[AuthPermission.Office]}>
                <div className="block md:flex md:justify-end items-center ml-0 md:ml-5">
                  <Button text="Create Plan" onClickFunc={() => setCreatePlan(true)} AdditionalClassNames="whitespace-nowrap w-auto mt-3 md:mt-0" />
                </div>
              </ProtectedComponent>
            </>
          ) : null}
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 gap-4 mb-5">
          <Tabs
            components={[
              <Plans key={"plans"} setEditPlan={setEditPlan} />,
              <Subscribers key={"subscribers"} />,
              <Services key={"servces"} officeId={id ?? ""} />,
            ]}
            options={["Plans", "Subscribers", "Services"]}
            activeTab={activeIndex}
            onClickTab={changeServiceTab}
            fromOffice={false}
            showBottomBorder={false}
          />
        </div>
      </div>
      {createPlan || editPlan ? (
        <UserSideBar
          title={!editPlan ? "Create Plan Option" : "Edit plan"}
          visible={true}
          onClickOutside={() => (!loading ? handleClose() : null)}
          onCancelClick={handleClose}
          onInviteClick={handleClose}>
          <AddPlan handleClose={handleClose} loading={loading} setLoading={setLoading} isEdit={!!editPlan} plan={editPlan} />
        </UserSideBar>
      ) : null}
    </div>
  );
};

export default OfficePlan;
