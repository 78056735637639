import { useState } from "react";
import { Finance, FinanceBilledTo } from "../../models/finance.model";
import { formatCurrency } from "../../utils";
import { colors } from "../../utils/constants";
import DountGraph from "../DonutGraph/DonutGraph";
import Tabs from "../Tabs/Tabs";

export const serviceBilledToObj: any = {
  patient: {
    name: "Patient Pays",
    color: colors.PatientPays,
  },
  ohip: {
    name: "OHIP",
    color: colors.OHIP,
  },
  wsib: {
    name: "WSIB",
    color: colors.WSIB,
  },
  "third-party": {
    name: "3rd Party",
    color: colors.ThirdParty,
  },
  "service-canada": {
    name: "Service Canada",
    color: colors.ServiceCanada,
  },
  "docnote-debit": {
    name: "Docnote Debit",
    color: colors.DarkBlue,
  },
};

const DashboardTabGraphSection = ({ financeReport }: { financeReport: Finance | null }) => {
  const [activeTab, setActiveTab] = useState(0);
  const Tab1 = () => {
    return (
      <div className="mt-10">
        {financeReport?.billedTo?.length ? (
          <DountGraph
            billedToList={financeReport?.billedTo}
            graphLabel={"Total Requests"}
            totalCount={financeReport?.numRequestsInvoiced.toString()}
          />
        ) : null}
      </div>
    );
  };
  const Tab2 = () => {
    return (
      <div className="mt-10 relative">
        {financeReport?.billedTo?.length ? (
          <DountGraph
            billedToList={financeReport?.billedTo}
            graphLabel={"Total Revenue"}
            graphVal={false}
            totalCount={financeReport?.totalRevenue ? formatCurrency((financeReport?.totalRevenue / 100)?.toString(), "blur") : "$0.00"}
          />
        ) : null}
      </div>
    );
  };
  return (
    <div className="finance-graph mt-10">
      <Tabs
        components={[]}
        options={[`Total Number of Requests`, "Revenue By Billed To Type"]}
        activeTab={activeTab}
        onClickTab={setActiveTab}
        fromOffice={true}
      />
      <div className="w-full block lg:flex">
        {financeReport?.billedTo?.length ? (
          <div className="w-full lg:w-1/2 flex items-center justify-center">
            <div className="flex flex-col w-full mt-5 lg:mt-0">
              {financeReport.billedTo.map((item: FinanceBilledTo) => {
                return (
                  <div key={item?.billedTo} className="flex w-full lg:w-2/3 text-docsigna-blue-ldark border-b border-docsigna-blue-dark py-3">
                    <div className="flex w-2/3">
                      <div
                        className="w-5 h-5 rounded-full mr-3"
                        style={{ backgroundColor: serviceBilledToObj[item?.billedTo]?.color ?? "transparent" }}
                      />
                      <p className="text-sm font-semibold">{serviceBilledToObj[item?.billedTo]?.name ?? ""}</p>
                    </div>
                    <div className="text-sm font-semibold text-right mr-5 w-1/3">
                      {activeTab === 0 ? item.count : formatCurrency((item?.amount / 100)?.toString(), "blur")}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        <div className="w-full lg:w-1/2">{activeTab === 0 ? <Tab1 /> : <Tab2 />}</div>
      </div>
    </div>
  );
};

export default DashboardTabGraphSection;
