import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { RootState, AppDispatch } from "../../redux/store";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  getOfficeFinanceRequests,
  getOfficeFinanceReport,
  setActiveOfficeFinanceTab,
  getOfficeAccountReceivablesPayee,
  getOfficeAccountReceivablesTopOverdueAccounts,
  getOfficeAccountReceivablesTotals,
  getOfficeAccountReceivables,
} from "../../redux/slices/FinanceSlice";
//import DataNotFound from "../../components/DataNotFound/DataNotFound";
//import NotFound from "../../assets/images/no-financial.png";
import ButtonTabs from "../../components/Tabs/ButtonTabs";
import FinanceDashboard from "./FinanceDashboard";
import AccountReceivable from "./AccountReceivable";
import AnnualPlan from "./AnnualPlan";

const FinanceScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const { officeFinanceReport, loading, activeOfficeTab: activeTab, officeSort } = useSelector((state: RootState) => state.finance);
  const date = new Date();
  const monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [monthOptions, setMonthOptions] = useState<string[]>(["january"]);
  const [selectedMonth, setSelectedMonth] = useState<string>(`${monthName[date.getMonth()] + " " + date.getFullYear()}`);
  const [triger, setTriger] = useState(false);
  date.setDate(1);
  const monthArray: string[] = [];
  for (let i = 0; i <= 11; i++) {
    monthArray.push(monthName[date.getMonth()] + " " + date.getFullYear());
    date.setMonth(date.getMonth() - 1);
  }

  const fetchData = (startDate: string, endDate: string, page = 0, size = 10) => {
    if (currentOffice?.officeId) {
      dispatch(getOfficeFinanceReport({ officeId: currentOffice?.officeId, startDate: new Date(startDate), endDate: new Date(endDate) }));
      dispatch(
        getOfficeFinanceRequests({
          officeId: currentOffice?.officeId,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          page: page,
          size: size,
          sort: officeSort.sortedBy,
        })
      );
    }
  };

  const handleMonthChange = (e: string) => {
    setSelectedMonth(e);
    const selectedMonth = e.split(" ");
    const month = monthName.indexOf(selectedMonth[0]);
    const year = selectedMonth[1];
    const firstDay = moment([year, month]).startOf("month").format("YYYY-MM-DD");
    const lastDay = moment([year, month]).endOf("month").add(1, "day").format("YYYY-MM-DD");
    fetchData(firstDay, lastDay);
  };

  const handleTabChange = (index: number) => {
    dispatch(setActiveOfficeFinanceTab({ index }));
  };

  useEffect(() => {
    if (monthArray.length === 12 && triger === false) {
      setMonthOptions(monthArray);
      setTriger(true);
    }
  }, [monthArray]);

  useEffect(() => {
    if (currentOffice?.officeId) {
      const firstDay = moment().startOf("month").format("YYYY-MM-DD");
      const lastDay = moment().add(1, "M").startOf("month").format("YYYY-MM-DD");
      fetchData(firstDay, lastDay);
      dispatch(getOfficeAccountReceivablesTotals({ officeId: currentOffice?.officeId }));
      dispatch(getOfficeAccountReceivablesPayee({ officeId: currentOffice?.officeId }));
      dispatch(getOfficeAccountReceivablesTopOverdueAccounts({ officeId: currentOffice?.officeId }));
      dispatch(getOfficeAccountReceivables({ officeId: currentOffice?.officeId, page: 0 }));
    }
  }, [currentOffice, dispatch]);

  const noRecordFound = !(
    officeFinanceReport?.totalRevenue ||
    officeFinanceReport?.discountsProvided ||
    officeFinanceReport?.feesOwedToDs ||
    officeFinanceReport?.netIncome ||
    officeFinanceReport?.dsCollected ||
    officeFinanceReport?.collectedByOffice ||
    officeFinanceReport?.outstandingPayments ||
    officeFinanceReport?.totalHstApplicableRevenue ||
    officeFinanceReport?.hstCollected
  );

  const buttonTabOptions =
    process.env.REACT_APP_ENV === "development"
      ? ["FINANCE DASHBOARD", "ANNUAL PLAN", "ACCOUNTS RECEIVABLE"]
      : ["FINANCE DASHBOARD", "ACCOUNTS RECEIVABLE"];

  const buttonTabComponents =
    process.env.REACT_APP_ENV === "development"
      ? [<FinanceDashboard key="dashboard-tab" noRecordFound={noRecordFound} />, <AnnualPlan key="annual-tab" />, <AccountReceivable key="acr-tab" />]
      : [<FinanceDashboard key="dashboard-tab" noRecordFound={noRecordFound} />, <AccountReceivable key="acr-tab" />];

  return (
    <>
      <main>
        <div
          className="container mx-auto p-8"
          style={{
            minHeight: "calc(100vh - 96px)",
          }}>
          <div className="block xl:flex justify-between items-end">
            <div className="">
              <OfficeSectionsHeader showMeta={true} />
            </div>
          </div>

          {loading ? (
            <CircularProgress size={40} color={"info"} style={{ marginLeft: "50%", marginTop: "10%" }} />
          ) : (
            // !noData?
            <>
              <div className="relative">
                <ButtonTabs
                  components={buttonTabComponents}
                  options={buttonTabOptions}
                  activeTab={activeTab}
                  onClickTab={handleTabChange}
                  fromOffice={true}
                />
                {activeTab !== 2 ? (
                  <div className="absolute top-0 right-0 z-20 block lg:flex items-center mb-6 space-x-0 lg:space-x-3 space-y-2 lg:space-y-0">
                    <div className="flex justify-between items-center space-x-3">
                      {/* <p className="text-orange-500 font-bold text-base">Month Being Viewed</p> */}
                      <select
                        onChange={e => {
                          handleMonthChange(e.target.value);
                        }}
                        value={selectedMonth}
                        className={`general-select leading-5 w-44`}>
                        {monthOptions.map((ele, index) => {
                          return <option key={index}>{ele}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>
              {/* {!officeFinanceReport?.billedTo?.length && !officeFinanceRequests?.length ? (
                <DataNotFound image={NotFound} text="No financial records found" />
              ) : null} */}
            </>
          )}
        </div>
      </main>
    </>
  );
};
export default FinanceScreen;
