import { Button, ConfirmationDialog, TextInput } from "../../components";
import Date from "../../assets/images/date.svg";
import { useContext, useState } from "react";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setShowCurrentSubscription } from "../../redux/slices/PatientSlice";

const PatientSubscriptionDetials = ({
  handleChangeSubscription,
  handleChangeCard,
}: {
  handleChangeSubscription: (val: boolean) => void;
  handleChangeCard: (val: boolean) => void;
}) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);
  const [showRemoveCard, setShowRemoveCard] = useState(false);

  return (
    <>
      <div className="px-8 border-t">
        <p className="font-bold py-5">You are currently subscribed to the following:</p>
        <p>Couple - $15/monthly</p>
        <div className="flex mt-5">
          <Button
            onClickFunc={() => setShowCancelSubscription(true)}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel Subscription"
            width="fit"
            varient="Secondary"
          />
          <Button onClickFunc={() => handleChangeSubscription(true)} AdditionalClassNames="pointer px-5" text="Change Subscription" width="fit" />
        </div>
        <p className="font-bold py-5">Next Payment Date:</p>
        <p className="flex gap-3 items-center">
          October 1st, 2023{" "}
          <div className="w-5 h-5 p-1 rounded-full bg-docsigna-orange flex justify-center items-center">
            <img className="" src={Date} alt="date-icon" />
          </div>
        </p>
        <p className="font-bold mt-5">Payment Information:</p>
        <div className="flex mt-3">
          <TextInput
            value="**** **** **** 1234"
            readonly
            onChangeFunc={() => null}
            extraInputClass="bg-docnote-pink-dark rounded-none payment-card-details-input"
          />
        </div>
        <div className="flex mt-5">
          <TextInput value="12/27" readonly onChangeFunc={() => null} extraInputClass="bg-docnote-pink-dark rounded-none" />
          <TextInput
            value="***"
            readonly
            onChangeFunc={() => null}
            extraInputClass="bg-docnote-pink-dark rounded-none border-l-0 cvv-card-details-input"
          />
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <p>Name On Card</p>
          <TextInput value="John Doe" readonly onChangeFunc={() => null} />
          <select className="general-select w-full" disabled>
            <option>Ontario</option>
          </select>
          <TextInput value="A1A 1A1" readonly onChangeFunc={() => null} />
        </div>
        <div className="flex mt-5 justify-end">
          <Button
            onClickFunc={() => setShowRemoveCard(true)}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Remove Card"
            width="fit"
            varient="Secondary"
          />
          <Button onClickFunc={() => handleChangeCard(true)} AdditionalClassNames="pointer px-5" text="Change Card" width="fit" />
        </div>
      </div>
      <ConfirmationDialog
        maxWidth="lg"
        successButtonText="Cancel Subscription"
        failureButtonText="Cancel"
        open={showCancelSubscription}
        title="Cancel Subscription?"
        description="Please confirm you wish to proceed with cancelling your subscription. Your current subscription will expire on October 1st, 2023 and you will not be charged further after this date."
        handleSuccess={() => {
          dispatch(setShowCurrentSubscription(false));
          toast?.openToast("Subscription cancelled!");
        }}
        handleFailure={() => setShowCancelSubscription(false)}
        showCrossIcon={false}
      />
      <ConfirmationDialog
        maxWidth="lg"
        successButtonText="Remove"
        failureButtonText="Cancel"
        open={showRemoveCard}
        title="Remove **** **** **** 1234"
        description="Are you sure you want to remove this card? This action can not be undone."
        handleFailure={() => setShowRemoveCard(false)}
        handleSuccess={() => {
          dispatch(setShowCurrentSubscription(false));
          toast?.openToast("Card removed successfully!");
        }}
        showCrossIcon={false}
      />
    </>
  );
};

export default PatientSubscriptionDetials;
