import React, { useContext } from "react";
import { TableDataType, TableHeaderType } from "../../models";
import Tooltip from "../Tooltip/Tooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import Checkbox from "../Checkbox/Checkbox";
import { colors } from "../../utils/constants";

const TableHeader = ({
  title,
  index,
  sortIcon,
  handleSort,
  extraClassesHeader,
  isClickable = true,
  isCheckbox = false,
  selectAll,
  isChecked = false,
  isCheckboxDisabled = false,
  titleColor = "text-docsigna-blue-light",
}: TableHeaderType) => {
  return (
    <>
      {title && (
        <>
          <div
            onClick={() => {
              isClickable && handleSort(index);
            }}
            className={`flex text-base font-semibold items-center text-docsigna-blue-light ${
              isClickable ? "cursor-pointer" : ""
            }  p-5 py-3 ${extraClassesHeader}`}>
            {isCheckbox ? (
              <div className="" onClick={e => e.stopPropagation()}>
                <Checkbox
                  label=""
                  disabled={isCheckboxDisabled}
                  checked={isChecked}
                  onChange={e => (selectAll ? selectAll(e.target.checked) : null)}
                />
              </div>
            ) : null}
            <span className={`flex flex-row capitalize ${titleColor}`}>
              {title}
              {isClickable ? sortIcon : null}
            </span>
          </div>
        </>
      )}
      {!title && (
        <div
          onClick={() => {
            isClickable && handleSort(index);
          }}
          className={`text-xs text-slate-500 uppercase ${isClickable ? "cursor-pointer" : ""}  p-5 py-3 ${extraClassesHeader}`}>
          <span className="text-base text-docsigna-blue-light inline-block dot bg-transparent"></span>
        </div>
      )}
    </>
  );
};

const TableRow = ({
  data,
  childElements,
  extraClassesRow = [],
  tooltipOptions = [],
  tooltipVisible = false,
  handleTooltipClick = () => {},
  id = "",
  onRowClick,
  handleOpenTooltip,
  handleCloseTooltip,
  cursorType,
  disabledOptions,
  extraClassesTooltip = "",
  allowHover = false,
  parentExtraClasses = "",
}: TableDataType) => {
  const toast = useContext(ToastContext);
  const handleRowClick = (id?: string) => {
    if (typeof onRowClick === "function") {
      onRowClick(id);
    } else {
      toast?.openToast();
    }
  };
  return (
    <div
      className={`flex justify-between items-center py-0 md:py-3 border-b-[0.5px] border-docsigna-pink relative group ${
        cursorType ? cursorType : "cursor-pointer"
      } ${allowHover ? "hover:bg-base hover:text-white hover:shadow-sm border-secondary-400" : ""} ${parentExtraClasses}`}
      onClick={() => handleRowClick(id)}>
      <div className="hidden group-hover:block absolute left-1/2 md:left-0 -translate-x-2/4 md:-translate-x-0 top-0 w-screen md:w-full h-full bg-gray-100 -z-10"></div>
      {data
        ? data.map((item, index) => {
            return (
              <div key={index} className={`p-5 py-3 font-medium ${extraClassesRow[index]}`}>
                {item}
              </div>
            );
          })
        : null}
      {childElements ? childElements.map(elem => elem) : null}
      {tooltipOptions.length ? (
        <Tooltip
          handleCloseTooltip={handleCloseTooltip}
          handleOpenTooltip={handleOpenTooltip}
          open={tooltipVisible}
          imageComponent={<MoreHorizIcon htmlColor={`${colors.Orange}`} />}
          additionalClasses={" lg:w-40 px-5 flex items-center justify-center cursor-pointer " + extraClassesTooltip}
          options={tooltipOptions}
          disabledOptions={disabledOptions}
          onSelect={(type, id) => handleTooltipClick(type, id)}
          id={id}
        />
      ) : null}
    </div>
  );
};

export { TableHeader, TableRow };
