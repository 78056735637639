import { ApiError } from "../models/api-error.model";
import { ApiPage, emptyApiPage } from "../models/api-page.model";
import { FormCreateRequest, Form } from "../models/form.model";
import { ApiPatchOp, apiService } from "./api.service";

export class FormService {
  private static BASE_PATH = "/api/v1/forms";

  static async searchForms(term: string, page = 0, size = 10): Promise<ApiPage<Form>> {
    const response = await apiService.post(`${this.BASE_PATH}/search?page=${page}&size=${size}`, {
      term: term ? term : undefined,
    });
    if (!response.success) return emptyApiPage<Form>();

    return response.data as ApiPage<Form>;
  }

  static async getForm(formId: string): Promise<Form | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${formId}`);
    if (!response.success) return null;

    return response.data as Form;
  }

  static async createForm(form: FormCreateRequest): Promise<FormCreateRequest | ApiError> {
    const response = await apiService.post(`${this.BASE_PATH}`, form);
    if (!response.success) throw new ApiError(response?.data?.message);

    return response.data as Form;
  }

  static async deleteForm(formId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/${formId}`);
    if (!response.success) return false;

    return true;
  }

  static async updateFormFileName(formId: string, name: string): Promise<Form | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${formId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/name",
        value: name,
      },
    ]);
    if (!response.success) return null;

    return response.data as Form;
  }
}
