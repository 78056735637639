import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { Button, OtpInput } from "../../components";
import { colors } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { guestTokenInitiate, guestTokenVerify } from "../../redux/slices/OnBoardingSlice";

const GuestVerifyPhone = () => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const navigate = useNavigate();
  const [error] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [guestTokenVerifyError, setGuestTokenVerifyError] = useState<boolean>(false);
  const finalUserData = localStorage.getItem("userData");
  const maskedPhoneNumber = localStorage.getItem("maskedPhoneNumber");
  const parsedMaskedPhoneNumber = maskedPhoneNumber ? JSON.parse(maskedPhoneNumber) : "";
  const parseFinalUserData = finalUserData ? JSON.parse(finalUserData) : "";
  const { pending } = useSelector((state: RootState) => state.onBoarding);

  const onSubmitClick = () => {
    dispatch(guestTokenVerify({ code: otpInput, ...parseFinalUserData })).then(action => {
      if (action.payload !== null) {
        navigate("/guest/view-request");
      } else {
        setGuestTokenVerifyError(true);
      }
    });
  };

  const handleTryAgain = () => {
    setGuestTokenVerifyError(false);
    setOtpInput("");
    dispatch(guestTokenInitiate(parseFinalUserData)).then(action => {
      if (action?.payload !== null) {
        toast?.openToast("Code has been sent again.");
      }
    });
  };
  useEffect(() => {
    if (otpInput.length === 6) {
      onSubmitClick();
    }
  }, [otpInput]);

  return (
    <>
      <div className="flex justify-between w-full">
        <div className="w-full max-w-2xl mx-auto p-6 md:p-8">
          <h3 className="text-3xl font-light mb-2">We need to validate your phone number.</h3>
          <p className="text-base mb-5 font-medium">We have sent a 6 digit code to your phone number: +1-{parsedMaskedPhoneNumber}.</p>
          <OtpInput length={6} onChange={e => setOtpInput(e)} />

          {error ? <p style={{ color: colors.Red }}>Invalid OTP.</p> : null}
          <div className="tab-content py-5 w-full max-w-[370px]">
            <Button
              text="Continue"
              width="full"
              disabled={otpInput.length !== 6}
              onClickFunc={onSubmitClick}
              varient={pending ? "loading" : "Primary"}
            />
          </div>
          {guestTokenVerifyError ? <p className="text-sm text-red-400">Invalid code, please try again.</p> : null}

          <br />
          <p className="text-base mb-5 font-medium">
            Didn{"'"}t receive a code?{" "}
            <span className="text-base text-docsigna-purple-dark cursor-pointer" onClick={() => handleTryAgain()}>
              Try again
            </span>{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default GuestVerifyPhone;
