import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { clearInvoiceObject, getOfficeRequestInvoice, setRequestSaved } from "../../../redux/slices/OfficeRequestSlice";
import { clearOnboardingInvoiceObject, getRequestInvoice } from "../../../redux/slices/OnBoardingSlice";
import { SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED } from "../../../utils/constants";
import { formatCurrency, priceReview } from "../../../utils";
import { setOpenPaymentSidebar } from "../../../redux/slices/RequestSlice";
import { Button } from "../../../components";

const PaymentDetailSummaryBox = ({
  officeId,
  requestId,
  isGuest = false,
  discountType,
  showPayButton = false,
  cancelled = false,
}: {
  officeId?: string;
  requestId?: string;
  isGuest?: boolean;
  discountType?: string;
  showPayButton?: boolean;
  cancelled?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { invoiceObject } = useSelector((state: RootState) => state.officeRequest);
  const { invoiceObjectOnboarding } = useSelector((state: RootState) => state.onBoarding);
  const { requestSaved } = useSelector((state: RootState) => state.officeRequest);
  const guestToken = sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED);
  const guestData = JSON.parse(guestToken || "{}");
  const paymentDetails = !isGuest ? invoiceObject : invoiceObjectOnboarding;
  const {
    servicePrice,
    dscDesc,
    dscValue,
    blockFeeDiscountDesc,
    blockFeeDiscountAmount,
    additionalFeeTotal,
    subTotal,
    dsFee,
    dsFeeDesc,
    hstValue,
    hstPercentage,
    hstDesc,
    total,
  } = priceReview(paymentDetails ? paymentDetails : undefined);

  const openPaymentSideBar = () => {
    dispatch(setOpenPaymentSidebar(true));
  };

  useEffect(() => {
    if (officeId && requestId && !isGuest) dispatch(getOfficeRequestInvoice({ officeId, requestId }));
  }, [officeId, requestId]);

  useEffect(() => {
    if (requestSaved && officeId && requestId && !isGuest) {
      dispatch(clearOnboardingInvoiceObject());
      dispatch(getOfficeRequestInvoice({ officeId, requestId }));
      dispatch(setRequestSaved(false));
    }
    if (isGuest && guestData) {
      dispatch(clearInvoiceObject());
      dispatch(getRequestInvoice({ guestToken: guestData.GuestToken }));
    }
  }, [requestSaved, officeId, requestId]);
  return (
    <>
      <div className="w-full md:max-w">
        <div className="w-full bg-docsigna-pink-light mb-3">
          <div className="w-full py-3 px-4">
            <div className="w-full flex justify-between items-center mb-2">
              <p className="text-md">
                <span className="font-semibold">Payment Details</span>
              </p>
              {showPayButton ? <Button AdditionalClassNames="font-semibold" text="Pay Now" onClickFunc={openPaymentSideBar} /> : null}
            </div>
            {!cancelled ? (
              <>
                <div>
                  <div className="text-md font-small col-span-2">
                    <div className="w-full flex justify-between mt-3">
                      <div>Service</div>

                      <div className="text-md font-small col-span-2">{formatCurrency(servicePrice.toString(), "blur")}</div>
                    </div>
                  </div>
                </div>
                <hr className="my-2 border-docsigna-blue-dark opacity-25" />
                {dscValue > 0 ? (
                  <>
                    <div className="w-full flex  justify-between">
                      <div className="text-md font-small col-span-2 font-bold">{dscDesc}</div>

                      <div className="text-md font-small col-span-2 font-bold">{formatCurrency(dscValue.toString(), "blur")}</div>
                    </div>
                    <hr className="my-2 border-docsigna-blue-dark opacity-25" />
                  </>
                ) : null}
                {blockFeeDiscountAmount > 0 ? (
                  <>
                    <div className="w-full flex  justify-between">
                      <div className="text-md font-small col-span-2 font-bold">Block Fee: {blockFeeDiscountDesc}</div>

                      <div className="text-md font-small col-span-2 font-bold">{formatCurrency(blockFeeDiscountAmount.toString(), "blur")}</div>
                    </div>
                    <hr className="my-2 border-docsigna-blue-dark opacity-25" />
                  </>
                ) : null}

                {additionalFeeTotal > 0 ? (
                  <>
                    <div className="w-full flex justify-between mt-3">
                      <div className="text-md font-small col-span-2">Additional Fees</div>
                      <div className="text-md font-small col-span-2">{formatCurrency(additionalFeeTotal.toString(), "blur")}</div>
                    </div>
                    <hr className="my-2 border-docsigna-blue-dark opacity-25" />
                  </>
                ) : null}

                {paymentDetails?.fees?.map((ele: any, index: number) => {
                  return (
                    <div key={index} className="w-full flex justify-between  py-2">
                      <div className="text-sm text-slate-400 font-small col-span-2">{ele.description}</div>
                      <div className="text-sm text-slate-400 font-small col-span-2">${(ele.amount / 100).toFixed(2)}</div>
                    </div>
                  );
                })}

                {paymentDetails?.fees?.length ? <hr className="my-2" /> : null}

                <div className="w-full flex justify-between">
                  <div className="text-md font-small col-span-2">Subtotal</div>
                  <div className="text-md font-small col-span-2">${subTotal.toFixed(2)}</div>
                </div>
                <hr className="my-2 border-docsigna-blue-dark opacity-25" />

                {dsFee > 0 ? (
                  <>
                    <div className="w-full flex  justify-between">
                      <div className="text-md font-small col-span-2">{dsFeeDesc}</div>

                      <div className="text-md font-small col-span-2">{formatCurrency(dsFee.toString(), "blur")}</div>
                    </div>
                    <hr className="my-2 border-docsigna-blue-dark opacity-25" />
                  </>
                ) : null}
                {hstValue > 0 ? (
                  <>
                    <div className="w-full flex justify-between">
                      <div className="text-md font-small col-span-2">
                        {hstDesc ? `${hstDesc} (${hstPercentage ? `${hstPercentage}%` : ""})` : "HST"}
                      </div>
                      <div className="text-md font-small col-span-2">{formatCurrency(hstValue.toString(), "blur")}</div>
                    </div>
                    <hr className="my-2 border-docsigna-blue-dark opacity-25" />
                  </>
                ) : null}
                <div className="w-full flex justify-between">
                  <div className="text-md font-small font-bold col-span-2">Total </div>
                  <div className="text-md font-small font-bold col-span-2">{formatCurrency(total.toString(), "blur")}</div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetailSummaryBox;
