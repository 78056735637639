import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import PatientDetails from "./PatientViewRequestComps/PatientDetails";
import RequestDetails from "./PatientViewRequestComps/RequestDetails";
import OfficeCommunication from "./PatientViewRequestComps/OfficeCommunication";
import PaymentSummaryBox from "./PatientViewRequestComps/PaymentSummaryBox";
import { Box, UserSideBar, PaymentForm, BreadCrumbs, FullScreenLoader, Button, ConfirmationDialog, TextInput } from "../../components";
import { getOfficeRequestPatientMessages, setOpenPaymentSidebar } from "../../redux/slices/RequestSlice";
import FileDetails from "./PatientViewRequestComps/FileDetails";
import { useParams } from "react-router-dom";
import { addOfficeRequestTimelineEvent, getUserRequest, requestRefund } from "../../redux/slices/UserSlice";
import { RefundRequestState, RequestState, RequestType } from "../../models/request.model";
import { ServiceBilledTo } from "../../models/service.model";
import { useNavigate } from "react-router-dom";
import { patientRenderState, renderState, showRefundState } from "../../utils";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { setOfficeRequestState, setPaymentSucceded } from "../../redux/slices/OfficeRequestSlice";
import { ToastVariants } from "../../models";
import { clearOnBoardingRequestData } from "../../redux/slices/OnBoardingSlice";
import { SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED } from "../../utils/constants";
import { UserType } from "../../services/user.service";
import { getOffice } from "../../redux/slices/OfficeSlice";
import InvoicePatientPdf from "../../components/forms/InvoiceForm/PatientInvoicePdf";

export enum RefundReasonOptions {
  Reason1 = "Request is taking longer than expected",
  Reason2 = "Request is no longer required",
  Reason3 = "Incorrect Information submitted",
  Reason4 = "Billed in error",
  Other = "Other",
}

const refundOptions = [
  RefundReasonOptions.Reason1,
  RefundReasonOptions.Reason2,
  RefundReasonOptions.Reason3,
  RefundReasonOptions.Reason4,
  RefundReasonOptions.Other,
];

const PatientViewRequest = () => {
  const navigate = useNavigate();
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const { patientRequestId, patientId } = useParams();
  const user = useSelector((state: RootState) => state.currentUser.currentUser);
  const { openPaymentSidebar } = useSelector((state: RootState) => state.request);
  const { patientRequestData, loading, pageLoading } = useSelector((state: RootState) => state.user);
  const { paymentSucceded } = useSelector((state: RootState) => state.officeRequest);
  const billedTo = patientRequestData?.serviceBilledToOverride
    ? patientRequestData?.serviceBilledToOverride
    : patientRequestData?.service?.serviceBilledTo;
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const [getPatientData, setGetPatientData] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(false);
  const [messageReached, setMessageReached] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showRefundOption, setShowRefundOption] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [refundReasonDesc, setRefundReasonDesc] = useState("");
  const [showRefundSuccess, setShowRefundSuccess] = useState(false);
  const isArchived = patientRequestData?.archived;
  const paidOrSubmitted = patientRequestData?.paymentComplete || patientRequestData?.submitted;
  const serviceBilledTo =
    (patientRequestData?.serviceBilledToOverride
      ? patientRequestData?.serviceBilledToOverride === ServiceBilledTo.Patient
      : patientRequestData?.service?.serviceBilledTo === ServiceBilledTo.Patient) || patientRequestData?.requestType === RequestType.DocnoteDebit;

  const isCancelable = (serviceBilledTo && !patientRequestData?.paymentComplete) || (!serviceBilledTo && !patientRequestData?.submitted);
  const closeSideBar = () => {
    dispatch(setOpenPaymentSidebar(!openPaymentSidebar));
  };
  const handleScroll = () => {
    const position = window.pageYOffset;

    if (position > 500) {
      setMessageReached(true);
      setScrollHeight(true);
    }
  };

  const handleConfirmationBox = (valueSelected: "yes" | "no") => {
    if (valueSelected === "no") {
      setConfirmationOpen(false);
    } else {
      if (currentOffice?.officeId && patientRequestId && patientId) {
        dispatch(setOfficeRequestState({ officeId: currentOffice?.officeId, requestId: patientRequestId, state: RequestState.Cancelled })).then(
          act => {
            if (act.payload !== null) {
              if (currentOffice?.officeId) {
                dispatch(
                  addOfficeRequestTimelineEvent({
                    event: "Patient updated status to cancelled",
                    officeId: currentOffice?.officeId,
                    requestId: patientRequestId,
                  })
                );
              }
              dispatch(getUserRequest({ reqeustId: patientRequestId, userId: patientId }));
              toast?.openToast("Request Cancelled!");
            } else {
              toast?.openToast("Something went wrong!", 2000, ToastVariants.Error);
            }
          }
        );
        setConfirmationOpen(false);
      }
    }
  };

  const refundReasons = () => {
    return (
      <div>
        <h2 className="font-bold mb-3">Please select reason for refund</h2>
        <ul>
          {refundOptions.map(item => {
            return (
              <li key={item}>
                <label className="block w-full mb-1 relative">
                  <input
                    type={"radio"}
                    checked={selectedReason === item}
                    onChange={e => setSelectedReason(e.target.value)}
                    value={item}
                    className="general-radio"
                  />
                  <span className="text-base ml-2">{item}</span>
                </label>
              </li>
            );
          })}
          {selectedReason === RefundReasonOptions.Other ? (
            <li>
              <TextInput
                value={refundReasonDesc}
                placeholder="Please enter the reason for your refund request"
                onChangeFunc={e => setRefundReasonDesc(e.target.value)}
              />
            </li>
          ) : null}
        </ul>
      </div>
    );
  };

  const handleRefund = async () => {
    if (!selectedReason) {
      toast?.openToast("Please select refund reason.");
      return;
    } else if (selectedReason === RefundReasonOptions.Other && !refundReasonDesc?.trim().length) {
      toast?.openToast("Please provide refund reason description.");
      return;
    }
    if (patientRequestData?.officeId && patientRequestData?.requestId) {
      setIsLoading(true);
      const res = await dispatch(
        requestRefund({
          officeId: patientRequestData?.officeId,
          requestId: patientRequestData?.requestId,
          reason: selectedReason,
          reasonOther: selectedReason === RefundReasonOptions.Other ? refundReasonDesc : undefined,
        })
      );
      setIsLoading(false);
      if (res.payload) {
        setShowRefundSuccess(true);
        setShowRefundOption(false);
        setSelectedReason("");
        setRefundReasonDesc("");
        toast?.openToast("Requested refund successfully.");
      } else {
        toast?.openToast("Refund request failed!");
      }
    }
  };

  useEffect(() => {
    dispatch(clearOnBoardingRequestData());
    localStorage.removeItem("redirectPath");
  }, []);

  useEffect(() => {
    if (user?.userType === UserType.Patient) {
      sessionStorage.removeItem(SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED);
    }
  }, [user]);

  useEffect(() => {
    if (patientRequestId && patientId)
      dispatch(getUserRequest({ reqeustId: patientRequestId, userId: patientId })).then(() => {
        setGetPatientData(true);
      });
  }, [patientRequestId, patientId]);

  useEffect(() => {
    if (patientRequestId && patientId && paymentSucceded) {
      dispatch(getUserRequest({ reqeustId: patientRequestId, userId: patientId }));
      dispatch(setPaymentSucceded(false));
    }
  }, [patientRequestId, patientId, paymentSucceded]);

  useEffect(() => {
    patientRequestData?.officeId && patientRequestId
      ? dispatch(getOfficeRequestPatientMessages({ officeId: patientRequestData?.officeId, requestId: patientRequestId }))
      : null;
  }, [patientRequestData?.officeId, patientRequestId]);

  useEffect(() => {
    if (!loading && patientRequestData?.requestNumber === undefined && getPatientData) {
      navigate(`/patients`);
    }
  }, [patientRequestData, loading, getPatientData]);

  useEffect(() => {
    if (patientRequestData?.officeId) {
      dispatch(getOffice({ officeId: patientRequestData?.officeId }));
    }
  }, [patientRequestData?.officeId]);

  useEffect(() => {
    if (patientRequestId && messageReached === false) {
      window.addEventListener("scroll", handleScroll, { passive: true });

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [patientRequestId, messageReached]);

  const breadCrumbItems = [
    {
      title: "Requests",
      key: `/patients/${patientId}/requests`,
    },
    { key: `#`, title: `${patientRequestData?.requestNumber}` },
  ];
  return (
    <main>
      <UserSideBar
        visible={openPaymentSidebar}
        LeftbuttonText="Cancel"
        RightbuttonText="Add"
        onClickOutside={() => (!isLoading ? closeSideBar() : null)}
        title={"Payment Details"}>
        <PaymentForm isPatient setIsLoading={setIsLoading} />
      </UserSideBar>
      {(loading && patientRequestData === null) || pageLoading ? <FullScreenLoader /> : null}
      <div className="container mx-auto p-4 md:p-8">
        <div className="mb-6 w-full text-sm font-light text-gray-400">
          <BreadCrumbs items={breadCrumbItems} />
        </div>
        <div className="flex justify-between items-start mb-6">
          <div className="flex justify-between items-start flex-wrap w-full pr-0 md:pr-12 lg:pr-24">
            <div className="text-left w-full md:w-auto mb-2 md:mb-0">
              <h2 className="heading">{`${user?.firstName} ${user?.lastName}`}</h2>
              <p className="text-md font-medium">Request # {patientRequestData?.requestNumber}</p>
            </div>
            {patientRequestData?.state !== RequestState.Cancelled &&
            patientRequestData?.refundRequest?.refundRequestId &&
            patientRequestData?.refundRequest?.refundRequestState !== RefundRequestState.Rejected ? (
              <span
                className={`inline-block text-sm text-white font-medium md:ml-2 bg-red-600 rounded-full py-2 px-5 ${
                  patientRequestData?.refundRequest?.refundRequestState === RefundRequestState.Approved ? "opacity-65" : ""
                }`}>
                {showRefundState(patientRequestData?.refundRequest?.refundRequestState)}
              </span>
            ) : (
              <span className="inline-block text-sm text-white font-medium md:ml-2 bg-docsigna-purple-light rounded-full py-2 px-5">
                {billedTo === ServiceBilledTo.Patient || billedTo === ServiceBilledTo.DocnoteDebit
                  ? patientRequestData?.state
                    ? renderState[patientRequestData.state]
                    : null
                  : patientRequestData?.state
                  ? patientRenderState(patientRequestData.state)
                  : null}
              </span>
            )}
          </div>
          <div className="hidden md:block w-full md:max-w-xs"></div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap flex-col-reverse md:flex-row">
          <div className="w-full mt-3 md:mt-0">
            <Box title="Patient Details 12" extraClasses="pt-2">
              <PatientDetails />
            </Box>
            <div className="flex justify-between items-center flex-wrap mb-1 mr-24">
              <p className="font-semibold">Request Details</p>
              {!isArchived &&
              patientRequestData?.state &&
              [RequestState.PendingPayment, RequestState.PendingVerification].includes(patientRequestData?.state) &&
              patientRequestData?.requestType === RequestType.Standard ? (
                <Button
                  AdditionalClassNames={paidOrSubmitted ? "opacity-30" : ""}
                  text="Cancel Request"
                  onClickFunc={() => setConfirmationOpen(true)}
                />
              ) : null}
            </div>
            <Box title="">
              <RequestDetails />
            </Box>
            {patientRequestData?.requestType !== RequestType.DocnoteDebit ? (
              <Box title="Files">
                <FileDetails
                  isCompleted={
                    isArchived || patientRequestData?.state === RequestState.Complete || patientRequestData?.state === RequestState.Cancelled
                  }
                />
              </Box>
            ) : null}
            <Box title="Office Communication">
              <OfficeCommunication scrollHeight={scrollHeight} isCompleted={isArchived || patientRequestData?.state === RequestState.Cancelled} />
            </Box>
          </div>
          <PaymentSummaryBox
            showPaymentDetails={
              ((patientRequestData && patientRequestData?.state === RequestState.InProgress
                ? patientRequestData?.state === RequestState.InProgress
                : patientRequestData?.state === RequestState.Complete
                ? patientRequestData?.state === RequestState.Complete
                : patientRequestData?.state === RequestState.PendingPayment) ||
                (patientRequestData?.state === RequestState.Cancelled && patientRequestData?.refunded)) &&
              serviceBilledTo
            }
            isPatient
            setShowRefundOption={setShowRefundOption}
          />
        </div>
        <div className=" absolute -top-[100%]">
          <div id="invoice-patient-container-pdf" className="hidden">
            <InvoicePatientPdf />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmationOpen}
        description={
          isCancelable
            ? "Are you sure you want to cancel this request? If you change your mind you will need to begin a new request."
            : "Please contact your doctor's office to cancel this request."
        }
        title={""}
        successButtonText={isCancelable ? "Yes" : ""}
        handleSuccess={() => {
          isCancelable ? handleConfirmationBox("yes") : {};
        }}
        failureButtonText={isCancelable ? "No" : "Close"}
        handleFailure={() => {
          handleConfirmationBox("no");
        }}
      />
      <ConfirmationDialog
        open={showRefundOption}
        description={refundReasons()}
        title={""}
        loading={isLoading}
        successButtonText={"Submit"}
        handleSuccess={handleRefund}
        failureButtonText={"Cancel"}
        handleFailure={() => setShowRefundOption(false)}
      />
      <ConfirmationDialog
        open={showRefundSuccess}
        description={`Your refund request has been submitted to ${patientRequestData?.officeName}. Once reviewed you will receive an email with the status of your request. The office may contact you if more information is required.`}
        title={""}
        loading={isLoading}
        successButtonText={"CLOSE"}
        handleSuccess={() => setShowRefundSuccess(false)}
        failureButtonText={""}
        handleFailure={() => undefined}
      />
    </main>
  );
};

export default PatientViewRequest;
