import { ServiceBilledTo, ServiceBilledToName } from "../../models/service.model";

export interface InputsTypes {
  id: string;
  type:
    | "textInput"
    | "textArea"
    | "SectionTitle"
    | "DropArea"
    | "checkbox"
    | "dateOfBirth"
    | "ohip"
    | "select"
    | "serviceNotes"
    | "typeOfService"
    | "billedto";
  title: string;
  placeholder?: string;
  errorMesage?: string;
  required: boolean;
  value: string | boolean;
  isValid: boolean;
  child?: InputsTypes[];
  width?: "full" | "half";
  subTitle?: string;
  visible: boolean;
}

export const inputsInitialState: InputsTypes[] = [
  {
    id: "firstName",
    type: "textInput",
    isValid: false,
    required: true,
    visible: true,
    value: "",
    placeholder: "Enter First Name",
    title: "First Name",
    errorMesage: "Please provide a first name",
    child: [
      {
        id: "lastName",
        type: "textInput",
        isValid: false,
        required: true,
        visible: true,
        value: "",
        placeholder: "Enter last Name",
        title: "Last Name",
        errorMesage: "Please provide a last name",
      },
    ],
  },
  {
    id: "dateOfBirth",
    type: "dateOfBirth",
    isValid: false,
    required: true,
    visible: false,
    value: "",
    placeholder: "MM/DD/YYYY",
    title: "Date of Birth",
    errorMesage: "Please provide a valid date",
    child: [
      {
        id: "ohipNumber",
        isValid: true,
        required: false,
        visible: false,
        type: "ohip",
        value: "",
        placeholder: "Enter OHIP Number",
        errorMesage: "Valid OHIP number required",
        title: "OHIP Number (Optional)",
      },
    ],
  },

  {
    id: "email",
    type: "textInput",
    isValid: false,
    required: true,
    visible: true,
    value: "",
    placeholder: "Enter email address",
    title: "Email",
    errorMesage: "Please provide an email address",
    child: [
      {
        id: "phone",
        type: "textInput",
        isValid: false,
        required: true,
        visible: true,
        value: "",
        placeholder: "Enter mobile number",
        title: "Mobile Number",
        errorMesage: "Please provide a mobile number",
      },
    ],
  },
  {
    type: "checkbox",
    id: "addtionalCopyCheck",
    isValid: false,
    required: false,
    visible: true,
    title: "The requested form will be sent to the below recipient.",
    value: false,
    errorMesage: "",
  },
  {
    id: "recipientName",
    type: "textInput",
    isValid: false,
    required: true,
    visible: false,
    value: "",
    placeholder: "Enter Name",
    title: "Recipient Name",
    errorMesage: "Please provide a name",
    child: [
      {
        id: "emailOrFax",
        isValid: false,
        required: true,
        visible: false,
        type: "textInput",
        value: "",
        placeholder: "Enter email address or fax number",
        errorMesage: "Please provide valid email address or fax number with format 111-222-3333",
        title: "Email or Fax Number",
      },
    ],
  },
  {
    id: "recipientSection",
    type: "SectionTitle",
    isValid: false,
    required: false,
    visible: false,
    value: "",
    title: "Recipient",
    subTitle: "The requested form will be sent to the below recipient.",
  },
  {
    id: "recipientNameNew",
    type: "textInput",
    isValid: false,
    required: true,
    visible: false,
    value: "",
    placeholder: "Enter Name",
    title: "Recipient Name",
    errorMesage: "Please provide a name",
    child: [
      {
        id: "emailOrFaxNew",
        isValid: false,
        required: true,
        visible: false,
        type: "textInput",
        value: "",
        placeholder: "Enter email address or fax number",
        errorMesage: "Please provide valid email address or fax number with format 111-222-3333",
        title: "Email or Fax Number",
      },
    ],
  },
  {
    id: "pharmacySection",
    type: "SectionTitle",
    isValid: false,
    required: false,
    visible: false,
    value: "",
    title: "Pharmacy",
    subTitle: "",
  },
  {
    id: "pharmacyName",
    type: "textInput",
    isValid: false,
    required: true,
    visible: false,
    value: "",
    placeholder: "Enter Name",
    title: "Pharmacy Name",
    errorMesage: "Please provide a name",
    child: [
      {
        id: "pharmacyEmailOrFax",
        isValid: false,
        required: true,
        visible: false,
        type: "textInput",
        value: "",
        placeholder: "Enter email address or fax number",
        errorMesage: "Please provide valid email address or fax number with format 111-222-3333",
        title: "Email or Fax Number",
      },
    ],
  },
  {
    id: "detailsSection",
    type: "SectionTitle",
    isValid: false,
    required: false,
    visible: true,
    value: "",
    title: "Notes",
  },
  {
    id: "details",
    type: "textArea",
    placeholder: "Enter notes here",
    value: "",
    isValid: true,
    required: false,
    visible: true,
    errorMesage: "Please provide details about this request",
    title: "Please provide any information relevant to this request",
  },
  {
    type: "DropArea",
    id: "files",
    isValid: false,
    required: false,
    visible: true,
    errorMesage: "File is required",
    value: "",
    title: "You can attach up to 10 files",
  },
  {
    id: "serviceSection",
    type: "SectionTitle",
    isValid: false,
    required: false,
    visible: true,
    title: "Service Details",
    value: "",
  },
  {
    id: "typeOfService",
    isValid: false,
    required: true,
    visible: true,
    title: "Name",
    type: "typeOfService",
    value: "",
    width: "half",
    placeholder: "Select Service",
    errorMesage: "Please select an option",
    child: [
      {
        id: "servicePrice",
        isValid: false,
        required: false,
        visible: true,
        title: "Cost",
        type: "textInput",
        value: "",
        width: "half",
        errorMesage: "Please provide a service price",
        placeholder: "$ 0.00",
      },
    ],
  },
  {
    id: "note",
    isValid: false,
    required: false,
    visible: true,
    title: "Notes",
    type: "serviceNotes",
    placeholder: "Service Notes",
    value: "",
    errorMesage: "Maximum 50 characters",
  },
  {
    id: "billedto",
    isValid: false,
    required: true,
    visible: true,
    title: "Billed To",
    type: "billedto",
    value: "",
    width: "half",
    placeholder: "Select billed to",
    errorMesage: "Please select an option",
  },
];

export const serviceBilled = [
  { id: ServiceBilledTo.Patient, name: "Patient" },
  { id: ServiceBilledTo.OHIP, name: ServiceBilledToName[ServiceBilledTo.OHIP] },
  { id: ServiceBilledTo.WSIB, name: ServiceBilledToName[ServiceBilledTo.WSIB] },
  { id: ServiceBilledTo.ThirdParty, name: ServiceBilledToName[ServiceBilledTo.ThirdParty] },
  { id: ServiceBilledTo.ServiceCanada, name: ServiceBilledToName[ServiceBilledTo.ServiceCanada] },
];
