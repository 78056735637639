import React from "react";
import { colors } from "../../utils/constants";

export type SelectOption = {
  label: string;
  value: string;
};

interface SelectProps {
  options: SelectOption[];
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  required: boolean;
  title: string;
  placeHolder: string;
  value?: string;
  disabled?: boolean;
  errorMsg?: string;
  name?: string;
  isDarkBg?: boolean;
  readOnly?: boolean;
}

const SelectNew = (props: SelectProps) => {
  const { options, onSelect, required, title, placeHolder, value, disabled = false, readOnly, isDarkBg = false, errorMsg } = props;

  return (
    <>
      {title ? (
        <>
          <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-1">
            {title}
            {required && !readOnly ? <span className="text-red-500">*</span> : null}
          </label>{" "}
        </>
      ) : null}
      <select
        onChange={onSelect}
        name={props?.name ?? ""}
        disabled={disabled || readOnly}
        style={disabled ? { backgroundColor: "#D0D5DD" } : {}}
        value={value ? value : placeHolder}
        className={`block w-full ${title ? "mt-1" : ""} rounded-md shadow-none border border-docsigna-blue-dark ${
          isDarkBg ? "bg-docsigna-pink-light" : ""
        } ${readOnly ? "custom-select bg-transparent border-none p-0 text-lg font-medium text-docsigna-purple opacity-100" : ""}`}>
        <option value={placeHolder} disabled>
          {placeHolder}
        </option>
        {options.map((val, i) => {
          return (
            <option key={i} value={val.value} selected={value === val.value}>
              {val.label}
            </option>
          );
        })}
      </select>
      {errorMsg ? <p style={{ color: colors.Red }}>{errorMsg}</p> : null}
    </>
  );
};

export default SelectNew;
