import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import { ConfirmationDialogType } from "../../models";
import Button from "../Button/Button";
import { colors } from "../../utils/constants";
import { DialogContent, DialogTitle } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ConfirmationDialog({
  open,
  title,
  description,
  successButtonText,
  handleSuccess,
  failureButtonText,
  handleFailure,
  hideActionButtons = true,
  maxWidth = "sm",
  showCrossIcon = false,
  titleBold = false,
  actionButtonPosition = "Right",
  canClickOutSide = true,
  loading = false,
}: ConfirmationDialogType) {
  return (
    <div className="relative">
      <Dialog
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => (canClickOutSide ? handleFailure() : undefined)}
        aria-describedby="alert-dialog-slide-description">
        {showCrossIcon ? (
          <DialogActions onClick={handleFailure} className="text-sm font-black cursor-pointer absolute top-3 right-4 text-docsigna-purple-dark">
            <CloseIcon htmlColor={colors.Orange} style={{ fontSize: "28px" }} />
          </DialogActions>
        ) : null}
        <div>
          <DialogTitle className="p-[0!important]">
            <div className={`text-docsigna-blue-dark font-medium text-lg ${titleBold ? "heading" : ""}`}>{title}</div>
          </DialogTitle>
          <DialogContent className={`px-[0!important] ${!hideActionButtons ? "pb-[0!important]" : ""}`}>
            {description ? <div className="text-docsigna-blue-dark font-medium text-base py-2">{description}</div> : null}
          </DialogContent>
          {hideActionButtons ? (
            <DialogActions>
              <div
                className={`w-full flex gap-5 ${
                  actionButtonPosition === "Center"
                    ? "justify-center"
                    : actionButtonPosition === "Left"
                    ? "justify-start"
                    : actionButtonPosition === "Split"
                    ? "justify-between"
                    : "justify-end"
                }`}>
                {failureButtonText.length ? (
                  <Button disabled={loading} onClickFunc={handleFailure} text={failureButtonText} varient="Outlined" />
                ) : null}
                {successButtonText.length ? (
                  <Button disabled={loading} varient={loading ? "loading" : "Primary"} onClickFunc={handleSuccess} text={successButtonText} />
                ) : null}
              </div>
            </DialogActions>
          ) : null}
        </div>
      </Dialog>
    </div>
  );
}
