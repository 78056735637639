import React from "react";
import P from "../Typography/P";
interface OfficeCardProps {
  title: string;
  address: string;
  fax: string;
  phone: string;
  primaryContact: string;
  hidePrimaryContact?: boolean;
  hideFooter?: boolean;
}

const OfficeCard = ({ title, address, fax, phone, primaryContact, hidePrimaryContact = false, hideFooter = false }: OfficeCardProps) => {
  return (
    <div className="rounded-md shadow-sm bg-docsigna-pink-light p-7">
      <h2 className="heading text-lg">{title}</h2>
      <div className="w-full py-6">
        <div className="grid grid-cols-2 justify-between border-b border-docsigna-blue-dark py-1">
          <P>Address</P>
          <P>{address}</P>
        </div>
        <div className="grid grid-cols-2 justify-between border-b border-docsigna-blue-dark py-1 pt-5">
          <P>Phone</P>
          <P>{phone}</P>
        </div>
        <div className="grid grid-cols-2 justify-between border-b border-docsigna-blue-dark py-1 pt-5">
          <P>Fax</P>
          <P>{fax}</P>
        </div>
        {!hidePrimaryContact ? <P>Primary Contact: {primaryContact}</P> : null}
      </div>
    </div>
  );
};

export default OfficeCard;
