import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { AppDispatch, RootState } from "../../../redux/store";
import { passwordValidationList, validateEmail, validatePassword } from "../../../utils";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import { formatPhoneNumber } from "../../../utils";
import { Phone_Fax_Regex } from "../../../utils/constants";
import ConfirmationDialog from "../../Dialogs/ConfirmationDialog";
import { emailExists, updatePatientUserProfile } from "../../../redux/slices/CurrentUserSlice";
import { UserUpdate } from "../../../models/user-update.model";
import { useDispatch } from "react-redux";
import { ToastVariants } from "../../../models";
import { PasswordErrorList } from "../..";
import { authService } from "../../../services/auth.service";

const EditAccountSetting = ({ onCancelClick }: { onCancelClick: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.currentUser.currentUser);
  const officeId = user && user?.offices && user?.offices?.length ? user.offices[0].officeId : "";
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [email, setEmail] = useState(user?.email ?? "");
  const [phone, setPhone] = useState(user?.phone ?? "");
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pwdValidations, setPwdValidations] = useState(passwordValidationList);
  const [loading, setLoading] = useState(false);
  const [confirmAlert, setShowConfirmAlert] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showPasswordChangedSuccess, setShowPasswordChangedSuccess] = useState(false);

  const toast = useContext(ToastContext);

  const validEmail = validateEmail(email);
  const validPhone = Phone_Fax_Regex.test(phone);
  const isEdited = JSON.stringify({ email, phone }) !== JSON.stringify({ email: user?.email, phone: user?.phone });

  const validateForm = () => {
    return validEmail && validPhone;
  };
  const handleUpdateData = async () => {
    setLoading(true);
    if (officeId && user?.userId) {
      if (confirmAlert) {
        const res = await dispatch(emailExists({ email }));
        if (res.payload) {
          toast?.openToast("An account already exists with this email address.", 2000, ToastVariants.Error);
          setLoading(false);
          return;
        }
      }
      const body: UserUpdate = {
        officeId,
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: email,
        phone: phone,
      };
      const res = await dispatch(updatePatientUserProfile({ body, officeId, userId: user.userId }));
      if (res.payload) {
        toast?.openToast("Settings updated successfully!", 2000, ToastVariants.Success);
        setShowConfirmAlert(false);
        onCancelClick();
      } else {
        toast?.openToast("Email updated failed", 2000, ToastVariants.Error);
      }
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    if (email !== user?.email) {
      setShowConfirmAlert(true);
      return;
    }
    if (phone !== user?.phone) {
      handleUpdateData();
    }
  };

  const handlePasswordChange = (e: string, id: string) => {
    let value = "";
    value = e;
    switch (id) {
      case "currentPassword":
        setCurrentPassword(value);
        break;
      case "confirmPassword":
        const isValid = password === value;
        const validationErrors = [...pwdValidations];
        validationErrors[5] = { ...validationErrors[5], isValid };
        setPwdValidations(validationErrors);
        setConfirmPassword(value);
        break;
      case "password":
        const newValidations = pwdValidations.map(item => {
          const isValid = validatePassword(item.id, value);
          return { ...item, isValid };
        });
        setPwdValidations(newValidations);
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const cancelChangePassword = (passwordChangeSuccess?: boolean) => {
    setShowPasswordChangedSuccess(passwordChangeSuccess ?? false);
    setShowChangePassword(false);
    setLoading(false);
    setPassword("");
    setCurrentPassword("");
    setConfirmPassword("");
  };

  const handleUpdatePassword = async () => {
    setLoading(true);
    if (user?.email) {
      const res = await authService.changePassword(currentPassword, password);
      if (!res) {
        toast?.openToast("The current password you entered is incorrect. Please try again.", 2000, ToastVariants.Error);
        setLoading(false);
        return;
      }
      cancelChangePassword(true);
    }
  };

  const renderConfirmMessage = () => {
    return (
      <div className="flex flex-col gap-4">
        <h2 className="font-bold">Your email is being updated to {email}.</h2>
        <p>Once confirmed, you will use your updated email to login to Docnote. Please confirm if you wish to proceed.</p>
      </div>
    );
  };

  const renderChangePassword = () => {
    return (
      <div className="flex flex-col gap-4">
        <div className="w-full">
          <TextInput
            label={"Current password"}
            placeholder={"Enter current password"}
            value={currentPassword}
            isPassword
            onChangeFunc={e => handlePasswordChange(e.target.value, "currentPassword")}
          />
        </div>
        <div className="w-full">
          <TextInput
            label={"New password"}
            placeholder={"Enter new password"}
            isPassword
            value={password}
            onChangeFunc={e => handlePasswordChange(e.target.value, "password")}
          />
        </div>
        <div className="w-full">
          <TextInput
            label={"Confirm password"}
            placeholder={"Enter confirm password"}
            isPassword
            value={confirmPassword}
            onChangeFunc={e => handlePasswordChange(e.target.value, "confirmPassword")}
          />
        </div>
        {password?.trim().length ? (
          <div className="flex gap-5">
            <div className="w-full">
              {pwdValidations.map(item => {
                return (
                  <div key={item.id} className="my-1 ml-1">
                    <PasswordErrorList isValid={item.isValid} message={item.msg} />
                  </div>
                );
              })}
            </div>
            <div className="md:w-1/2 w-full"></div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className="px-8">
        <div className="py-2">
          <TextInput
            isError={errorInitiated && !validEmail}
            value={email}
            name={"email"}
            onChangeFunc={e => setEmail(e.currentTarget.value)}
            label={"Email"}
            errorMsg={"Please provide valid email address"}
            isRequired={true}
            placeholder={"Enter email address"}
            isDarkBg={true}
          />
        </div>
        <div className="py-2">
          <TextInput
            isError={errorInitiated && !validPhone}
            value={phone}
            name={"phone"}
            onChangeFunc={e => setPhone(formatPhoneNumber(e.currentTarget.value))}
            label={"Phone"}
            errorMsg={"Please provide valid phone number"}
            isRequired={true}
            placeholder={"Enter phone number"}
            isDarkBg={true}
          />
        </div>
        <div className="py-2">
          <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">Password</label>
          <Button width="fit" text="Change password" onClickFunc={() => setShowChangePassword(true)} AdditionalClassNames="uppercase !font-bold" />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => {
              onCancelClick();
            }}
            AdditionalClassNames="pointer px-5 mr-3"
            disabled={loading}
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div onClick={() => setErrorInitiated(true)}>
            <Button
              onClickFunc={() => {
                if (validateForm()) handleUpdate();
              }}
              AdditionalClassNames="pointer px-5"
              disabled={!isEdited || (!validateForm() && errorInitiated)}
              text="Save"
              width="fit"
              varient={loading ? "loading" : "Primary"}
            />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmAlert}
        canClickOutSide={false}
        description={renderConfirmMessage()}
        handleFailure={() => setShowConfirmAlert(false)}
        handleSuccess={() => {
          handleUpdateData();
        }}
        successButtonText="Confirm"
        failureButtonText="Cancel"
        loading={loading}
        actionButtonPosition="Left"
      />
      <ConfirmationDialog
        open={showChangePassword}
        canClickOutSide={false}
        description={renderChangePassword()}
        handleFailure={() => cancelChangePassword()}
        handleSuccess={() => handleUpdatePassword()}
        successButtonText="Confirm"
        failureButtonText="Cancel"
        loading={loading}
        actionButtonPosition="Left"
      />
      <ConfirmationDialog
        open={showPasswordChangedSuccess}
        canClickOutSide={false}
        description={"Your password has been successfully updated."}
        handleFailure={() => undefined}
        handleSuccess={() => {
          setShowPasswordChangedSuccess(false);
          cancelChangePassword();
          onCancelClick();
        }}
        successButtonText="Ok"
        failureButtonText=""
        loading={loading}
        actionButtonPosition="Center"
      />
    </>
  );
};

export default EditAccountSetting;
