import React, { useState, useEffect, useContext } from "react";
import { AuthPermission } from "../../services/auth.service";
import ProtectedComponent from "../../components/ProtectedComponent/ProtectedComponent";
import UserSideBar from "../../components/UserSideBar/UserSideBar";
import AddService from "../../components/forms/AddService/AddService";
import { BreadCrumbs, Button, Checkbox, ConfirmationDialog, Pagination, TableHeader, TableRow, Tabs, TextInput } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import CheckIcon from "@mui/icons-material/Check";
import {
  searchServiceData,
  serviceServiceSearchServices,
  resetServiceTableSort,
  deleteService,
  copyServiceToOffice,
  getService,
} from "../../redux/slices/ServiceSlice";
import { Service, ServiceOwner } from "../../models/service.model";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { FileTypes } from "../../models";
import close from "../../assets/images/close.svg";
import { Modal } from "@mui/material";
import { getOfficeServiceSampleFile } from "../../redux/slices/FileSlice";
import { formatNumToDec, getSorting } from "../../utils";
import EditService from "../../components/forms/EditService/EditService";
import ViewService from "../../components/ViewService/ViewService";
import { headersDocTemplates, headersInitialState } from "./ServiceScreenDaa";
import { CircularProgress } from "@mui/material";
import { enableOfficeService, getOfficeUninsuredServices, sortObj } from "../../redux/slices/OfficeSlice";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NotFound from "../../assets/images/no-services-found.png";
import { ToastVariants } from "../../models";
import { isEmpty } from "lodash";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { colors } from "../../utils/constants";
import { useDebouncedCallback } from "use-debounce";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

interface CopiedServiceItem {
  serviceId: string;
  completed: boolean;
}
let previousCompletedItems: string[] = [];
const toastMessage = "Services created at the Docnote level cannot be edited or deleted within an office.";

const ServiceScreen = ({ isAdminPage }: { isAdminPage: boolean }) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const { serviceList, loading: serviceLoading, sort } = useSelector((state: RootState) => state.service);
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const { loading, getFileDetails } = useSelector((state: RootState) => state.file);
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const [showAddServiceSideBar, setShowAddServiceSideBar] = useState(false);
  const serviceSideBar = (val: boolean) => setShowAddServiceSideBar(val);
  const [headers, setHeaders] = useState(headersInitialState);
  const [sortMethods, setSortMethods] = useState([true, false, false, false, false]);
  const [activeColumn, setActiveColumn] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const [officeId, setOfficeId] = useState(currentOffice?.officeId || "");
  const [modalopen, setModalopen] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [openEditService, setOpenEditService] = useState(false);
  const [openViewService, setOpenViewService] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeOwner, setActiveOwner] = useState(isAdminPage ? ServiceOwner.System : ServiceOwner.Office);
  const [tooltipStates, setTooltipStates] = useState<boolean[]>(new Array(serviceList.data ? serviceList.data.content.length : 0).fill(false));
  const [serviceToBeUpdated, setServiceToBeUpdated] = useState<Service | null>(null);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedServiceIdsForCopy, setSelectedServiceIdsForCopy] = useState<CopiedServiceItem[]>([]);
  const [completedService, setCompletedCopiedServices] = useState<string[]>([]);

  const officId =
    isAdminPage && currentUser?.offices?.length && currentUser?.offices[0]?.officeId ? currentUser?.offices[0]?.officeId : currentOffice?.officeId;

  const sortKeys = headers.map(head => head.sortKey);
  const extraClasses = headers.map(head => head.extraClasses);

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeColumn === index ? (item ? headerIconAsc : headerIconDesc) : null));
  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateTable(activeOwner, { sortedBy: sort.sortedBy, pageNumber: newPage, rowsPerPage: sort.rowsPerPage }, searchTerm);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateTable(activeOwner, { sortedBy: sort.sortedBy, pageNumber: 0, rowsPerPage: parseInt(event.target.value, 10) }, searchTerm);
  };

  const updateTable = async (owner: ServiceOwner, sortObj?: sortObj, searchText?: string) => {
    const pageNumToBeCalled = sortObj?.pageNumber ?? sort.pageNumber;
    const sizeToBeCalled = sortObj?.rowsPerPage ?? sort.rowsPerPage;
    const sortBy = sortObj?.sortedBy ?? sort.sortedBy;
    const searchT = searchText ?? "";
    const commonParams = {
      page: pageNumToBeCalled,
      size: sizeToBeCalled,
      sort: sortBy,
    };

    const action = isAdminPage ? serviceServiceSearchServices : searchServiceData;
    if (searchT.length >= 2) {
      await dispatch(
        action({
          ...commonParams,
          term: searchT,
          ...(isAdminPage ? {} : { officeId: officeId, serviceOwner: owner }),
        })
      );
    } else {
      if (!isAdminPage) {
        dispatch(getOfficeUninsuredServices({ officeId: officeId, page: 0, size: 3, sort: "featured,desc" }));
      }
      await dispatch(
        action({
          ...commonParams,
          ...(isAdminPage ? {} : { officeId: officeId, serviceOwner: owner }),
        })
      );
    }

    return true;
  };

  const onHeaderClick = (index: number) => {
    if (sortKeys[index] === "" || isLoading) return;
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setActiveColumn(index);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    updateTable(activeOwner, { sortedBy: sortMethodString, pageNumber: 0, rowsPerPage: sort.rowsPerPage }, searchTerm);
  };

  const toolTipOptions = currentUser?.permissions.includes(AuthPermission.Service)
    ? isAdminPage
      ? ["Edit Service", "Delete Service"]
      : ["Edit Service", "Copy Service"]
    : // [ "Disable Service", "Delete Service"]
      ["View Service"];

  const toolTipOptionsOffice = ["View Service"];

  const openEditServiceSideBar = (id: string) => {
    setSelectedServiceId(id);
    if (currentUser?.permissions.includes(AuthPermission.Service)) {
      if (!serviceList.data?.content.find(f => f.serviceId === id)?.officeId && !isAdminPage) {
        toast?.openToast(toastMessage, 4000);
      } else {
        const editService = serviceList.data?.content.find(f => f.serviceId === id);
        setOpenEditService(true);
        if (editService) setServiceToBeUpdated(editService);
      }
    } else {
      setOpenViewService(true);
    }
  };

  const handleRowClick = (id?: string) => {
    if (id) {
      setSelectedServiceId(id);
      if (currentUser?.permissions.includes(AuthPermission.Service)) {
        if (!serviceList.data?.content.find(f => f.serviceId === id)?.officeId && !isAdminPage) {
          setOpenViewService(true);
        } else {
          const editService = serviceList.data?.content.find(f => f.serviceId === id);
          setOpenEditService(true);
          if (editService) setServiceToBeUpdated(editService);
        }
      } else {
        setOpenViewService(true);
      }
    }
  };

  const handleTooltipClick = (type: string, id: string | null) => {
    if (type === "Edit Service") {
      if (id) openEditServiceSideBar(id);
    } else if (type === toolTipOptions[0]) {
      setSelectedServiceId(id);
      setOpenViewService(true);
    } else if (type === "Delete Service") {
      if (!serviceList.data?.content.find(f => f.serviceId === id)?.officeId && !isAdminPage) {
        toast?.openToast(toastMessage, 4000);
      } else {
        setSelectedServiceId(id);
        setConfirmationOpen(true);
      }
    } else if (type === "Disable Service") {
      if (!serviceList.data?.content.find(f => f.serviceId === id)?.officeId && !isAdminPage) {
        toast?.openToast(toastMessage, 4000);
      } else {
        toast?.openToast();
      }
    } else if (type === "View Service") {
      const editService = serviceList.data?.content.find(f => f.serviceId === id);
      if (editService) {
        setServiceToBeUpdated(editService);
        setOpenViewService(true);
      }
    } else if (type === "Copy Service") {
      const service = serviceList.data?.content.find(f => f.serviceId === id);
      if (service) copyServiceToOfficeFunc(service);
    } else {
      toast?.openToast();
    }
  };

  const debounced = useDebouncedCallback(value => {
    if (value && value.trim().length >= 2) {
      updateTable(activeOwner, { ...sort, pageNumber: 0 }, value.trim());
    }
  }, 1000);

  const handleSearchInput = (text: string) => {
    const input = text;
    const oldSearchTerm = searchTerm;
    setSearchTerm(input);
    setSelectAll(false);
    setSelectedServiceIdsForCopy([]);
    debounced(input);
    if (oldSearchTerm !== "" && input.length <= 1) {
      updateTable(activeOwner, sort);
    }
  };

  const handleToolTipOpen = (index: number) => {
    setTooltipStates([...tooltipStates.slice(0, index).fill(false), true, ...tooltipStates.slice(index + 1).fill(false)]);
  };

  const handleToolTipClose = () => {
    setTooltipStates([...tooltipStates].fill(false));
  };

  const openImageInModal = (serviceId: string) => {
    dispatch(getOfficeServiceSampleFile({ officeId, serviceId }));
    setModalopen(true);
  };

  const handleConfirmationBox = (valueSelected: "yes" | "no") => {
    switch (valueSelected) {
      case "no":
        setConfirmationOpen(false);
        break;
      case "yes":
        if (selectedServiceId)
          dispatch(deleteService({ serviceId: selectedServiceId })).then(res => {
            if (res !== null) {
              updateTable(activeOwner);
            }
          });
        setConfirmationOpen(false);
        break;
      default:
        break;
    }
  };

  const changeServiceTab = (index: number) => {
    setActiveIndex(index);
    if (index === 1) {
      setHeaders(headersDocTemplates);
    } else {
      setHeaders(headersInitialState);
    }
    let owner = ServiceOwner.Office;
    if (index === 1) {
      owner = ServiceOwner.System;
    }
    setActiveOwner(owner);
    updateTable(owner, { ...sort, pageNumber: 0 }, searchTerm);
  };

  const copyServiceToOfficeFunc = async (service: Service) => {
    if (service?.serviceId) {
      try {
        setIsLoading(true);
        const res: any = await dispatch(
          copyServiceToOffice({
            officeId,
            serviceId: service.serviceId,
          })
        );
        if (res?.payload && !isEmpty(res?.payload)) {
          setOpenEditService(true);
          toast?.openToast("Service copied successfully.");
          setActiveOwner(ServiceOwner.Office);
          setActiveIndex(0);
          await updateTable(ServiceOwner.Office, { sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, searchTerm);
          if (res?.payload?.serviceId) {
            setSelectedServiceId(res?.payload?.serviceId);
            const service: any = await dispatch(getService({ serviceId: res?.payload?.serviceId }));
            if (service?.payload) {
              setServiceToBeUpdated(service.payload);
            }
          }
        }
        setIsLoading(false);
      } catch (_) {
        setIsLoading(false);
      }
    }
  };

  const onSelectServiceForCopy = (serviceId: string) => {
    if (serviceId) {
      const index = selectedServiceIdsForCopy.findIndex(item => item.serviceId === serviceId);
      if (index === -1) {
        setSelectedServiceIdsForCopy([...selectedServiceIdsForCopy, { serviceId: serviceId, completed: false }]);
      } else {
        setSelectedServiceIdsForCopy([...selectedServiceIdsForCopy.slice(0, index), ...selectedServiceIdsForCopy.slice(index + 1)]);
      }
    }
  };

  const selectAllServiceIds = () => {
    const services = serviceList?.data?.content?.length ? serviceList?.data?.content : [];
    let newIds = [...selectedServiceIdsForCopy];
    services.map((item: Service) => {
      if (item?.serviceId) {
        const index = newIds.findIndex(itm => itm.serviceId === item?.serviceId);
        if (index === -1) {
          newIds = [...newIds, { serviceId: item?.serviceId, completed: false }];
        }
      }
      return true;
    });
    setSelectedServiceIdsForCopy(newIds);
  };

  const onSelectAll = (val: boolean) => {
    setSelectAll(val);
    if (val) {
      selectAllServiceIds();
    } else {
      setSelectedServiceIdsForCopy([]);
    }
  };

  const copyServiceFn = (serviceId: string) => {
    return new Promise(async (resolve, reject) => {
      const res: any = await dispatch(
        copyServiceToOffice({
          officeId,
          serviceId: serviceId,
        })
      );
      if (res?.payload && !isEmpty(res?.payload)) {
        previousCompletedItems = [...previousCompletedItems, serviceId];
        setCompletedCopiedServices(previousCompletedItems);
        resolve(true);
      } else {
        reject(false);
      }
    });
  };

  const copySelectedServicesToOffice = async () => {
    try {
      setIsLoading(true);
      previousCompletedItems = [...completedService];
      await selectedServiceIdsForCopy
        .filter(item => !item.completed)
        .reduce((accumulatorPromise: any, item) => {
          return accumulatorPromise.then(() => {
            return copyServiceFn(item.serviceId);
          });
        }, Promise.resolve());
      toast?.openToast("Services Successfully Copied To Office", 2000, ToastVariants.Success);
      setSelectedServiceIdsForCopy([]);
      setCompletedCopiedServices([]);
      previousCompletedItems = [];
      setSelectAll(false);
      setIsLoading(false);
      changeServiceTab(0);
    } catch (err) {
      toast?.openToast("Services Copied To Office Failed!", 2000, ToastVariants.Error);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpenViewService(false);
    setOpenEditService(false);
    setServiceToBeUpdated(null);
    setSelectedServiceId(null);
  };

  useEffect(() => {
    setTooltipStates(new Array(serviceList.data ? serviceList.data.content.length : 0).fill(false));
  }, [serviceList]);

  useEffect(() => {
    if (selectAll && activeIndex === 1) {
      selectAllServiceIds();
    }
  }, [serviceList?.data?.content, activeIndex, selectAll]);

  useEffect(() => {
    if (!firstRender) {
      updateTable(activeOwner);
    }
  }, [officeId, firstRender]);

  useEffect(() => {
    if (officId) setOfficeId(officId);
  }, [officId]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      dispatch(resetServiceTableSort());
    }
  }, []);
  const canEditService = currentUser?.permissions?.includes(AuthPermission.Service);
  return (
    <main>
      <ConfirmationDialog
        open={confirmationOpen}
        description={`Are you sure you want to delete this service?  This action cannot be undone.`}
        title={""}
        successButtonText={"Yes"}
        handleSuccess={() => {
          handleConfirmationBox("yes");
        }}
        failureButtonText={"No"}
        handleFailure={() => {
          handleConfirmationBox("no");
        }}
      />
      <Modal open={modalopen} onClose={() => setModalopen(false)}>
        <div className="modal relative z-10">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl">
                  <div className="bg-white p-4 xl:p-6">
                    <span className="modal-close absolute top-2 right-2 w-7 h-7 bg-white rounded-full">
                      <img src={close} alt="" className="w-full h-full cursor-pointer" onClick={() => setModalopen(false)} />
                    </span>
                    {loading ? (
                      "Loading ..."
                    ) : getFileDetails?.contentType === FileTypes.PDF ? (
                      <embed src={getFileDetails.url} className="w-full" style={{ height: "80vh" }} />
                    ) : (
                      <img src={getFileDetails?.url} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container mx-auto p-8">
        {!isAdminPage ? <OfficeSectionsHeader showMeta={true} /> : <BreadCrumbs items={[{ key: "#", title: "Services" }]} />}
        <div className="block lg:flex justify-between items-center flex-wrap mb-6">
          <div className="w-full lg:w-1/2 mb-5 lg:mb-0">
            <h2 className="heading uppercase">Services</h2>
          </div>
          <div className="block md:flex lg:justify-end w-full lg:w-1/2">
            <div className="flex items-center">
              <div className="relative w-full">
                <TextInput
                  onChangeFunc={e => {
                    handleSearchInput(e.currentTarget.value);
                  }}
                  disabled={isLoading}
                  value={searchTerm}
                  placeholder={activeIndex === 1 ? "Search templates" : "Search services"}
                  extraInputClass="w-full"
                  isSearchInput={true}
                />
              </div>
            </div>
            {activeIndex === 0 ? (
              <ProtectedComponent requiredPermission={[AuthPermission.Service]}>
                <div className="block md:flex md:justify-end items-center ml-0 md:ml-5">
                  <span className="md:mr-5 font-medium text-center hidden md:inline-block">or</span>
                  <Button
                    text="Create Service"
                    onClickFunc={() => {
                      if (activeOwner === ServiceOwner.System && !isAdminPage) {
                        toast?.openToast("You can't create service inside Docnote section.", 2000, ToastVariants.Error);
                      } else {
                        serviceSideBar(true);
                      }
                    }}
                    AdditionalClassNames="whitespace-nowrap w-auto mt-3 md:mt-0"
                  />
                </div>
              </ProtectedComponent>
            ) : null}
            {activeIndex === 1 ? (
              <div className="relative w-auto ml-5 flex items-center">
                <Button
                  AdditionalClassNames="w-auto"
                  text="Copy Selected Services To Office"
                  disabled={!selectedServiceIdsForCopy.length || isLoading}
                  onClickFunc={copySelectedServicesToOffice}
                />
              </div>
            ) : null}
          </div>
          <p className="text-base font-medium mt-5">
            {!isAdminPage
              ? "Manage and customize your uninsured services. Use the Docnote template, based on OMA guidelines and best practices, to easily copy suggested services to your office."
              : "Manage services here."}
          </p>
        </div>
        <div>
          {!isAdminPage ? (
            <div className="mb-5">
              <Tabs
                components={[]}
                options={["Office Services", "Docnote Templates"]}
                activeTab={activeIndex}
                onClickTab={changeServiceTab}
                fromOffice={false}
                showBottomBorder={false}
              />
            </div>
          ) : null}
          {!serviceLoading ? (
            <div className="block">
              {serviceList?.data?.content && serviceList?.data?.content?.length > 0 ? (
                <>
                  <div className="flex justify-between items-center border-b border-docsigna-blue-light">
                    {headers.map((head, index: number) => (
                      <TableHeader
                        title={head.title}
                        index={index}
                        key={index}
                        sortIcon={sortIcons[index]}
                        isClickable={head.isClickable}
                        handleSort={onHeaderClick}
                        extraClassesHeader={extraClasses[index]}
                        isCheckbox={head?.isCheckbox}
                        isChecked={selectAll}
                        isCheckboxDisabled={!canEditService || isLoading}
                        selectAll={(val: boolean) => (!isLoading ? onSelectAll(val) : null)}
                      />
                    ))}
                  </div>

                  {serviceList?.data &&
                    serviceList?.data?.content.map((rowData: Service, index: number) => {
                      let sortedData = null;
                      if (activeIndex === 1) {
                        const copiedItem = selectedServiceIdsForCopy.find(item => item.serviceId === rowData?.serviceId);
                        sortedData = {
                          selectAll: (
                            <div className="ml-0" onClick={e => e.stopPropagation()}>
                              {copiedItem?.serviceId && completedService.includes(copiedItem?.serviceId) ? (
                                <CheckIcon fontSize="small" className="green-color-icon" htmlColor="#22C55E" />
                              ) : copiedItem?.serviceId && isLoading ? (
                                <CircularProgress size={16} />
                              ) : (
                                <Checkbox
                                  label=""
                                  disabled={!canEditService}
                                  checked={rowData?.serviceId ? !!copiedItem : false}
                                  onChange={e => {
                                    onSelectServiceForCopy(rowData?.serviceId ?? "");
                                  }}
                                />
                              )}
                            </div>
                          ),
                          service: rowData.name,
                          description: (
                            <div className="flex justify-between items-center">
                              {rowData.description ? rowData.description : ""}
                              {rowData.sampleFileId ? (
                                <div className="w-14 ml-auto">
                                  <span
                                    className="block"
                                    onClick={e => {
                                      e.stopPropagation();
                                      openImageInModal(rowData.serviceId || "");
                                    }}>
                                    <CameraAltIcon htmlColor={colors.CyanBlue} />
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          ),
                          price: "$" + formatNumToDec(rowData.price / 100, 2),
                        };
                      } else {
                        sortedData = {
                          service: rowData.name,
                          description: (
                            <div className="flex justify-between items-center">
                              {rowData.description ? rowData.description : ""}
                              {rowData.sampleFileId ? (
                                <div className="w-14 ml-auto">
                                  <span
                                    className="block"
                                    onClick={e => {
                                      e.stopPropagation();
                                      openImageInModal(rowData.serviceId || "");
                                    }}>
                                    <CameraAltIcon htmlColor={colors.CyanBlue} />
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          ),
                          price: "$" + formatNumToDec(rowData.price / 100, 2),
                          plan: rowData?.blockFeeDiscountPct ? <FiberManualRecordIcon htmlColor={colors.CyanBlue} fontSize="small" /> : "--",
                          status: !isAdminPage ? (
                            <div className="ml-3.5" onClick={e => e.stopPropagation()}>
                              <Checkbox
                                label=""
                                checked={rowData.enabled}
                                onChange={e => {
                                  if (officeId && rowData.serviceId) {
                                    dispatch(enableOfficeService({ state: !rowData.enabled, officeId, serviceId: rowData.serviceId }))
                                      .then(action => {
                                        if (action.payload !== null) {
                                          updateTable(activeOwner, sort, searchTerm);
                                        }
                                      })
                                      .catch(() => {});
                                  }
                                }}
                              />
                            </div>
                          ) : rowData.enabled ? (
                            "Enabled"
                          ) : (
                            "Disabled"
                          ),
                          featured: rowData?.featured ? <StarOutlinedIcon className="text-docsigna-orange" /> : "-",
                        };
                      }
                      return sortedData ? (
                        <TableRow
                          data={Object.values(sortedData)}
                          key={index}
                          id={rowData.serviceId}
                          extraClassesRow={extraClasses}
                          tooltipOptions={isAdminPage ? toolTipOptions : activeOwner === ServiceOwner.System ? toolTipOptionsOffice : toolTipOptions}
                          handleTooltipClick={type => handleTooltipClick(type, rowData.serviceId || null)}
                          handleCloseTooltip={handleToolTipClose}
                          handleOpenTooltip={() => handleToolTipOpen(index)}
                          tooltipVisible={tooltipStates[index]}
                          disabledOptions={!rowData.officeId && !isAdminPage ? [toolTipOptions[1], toolTipOptions[2]] : []}
                          onRowClick={() => (!isLoading ? handleRowClick(rowData.serviceId) : null)}
                        />
                      ) : null;
                    })}
                  <Pagination
                    colSpan={10}
                    count={serviceList.data ? serviceList.data.totalElements : 0}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    page={sort.pageNumber}
                    rowsPerPage={sort.rowsPerPage}
                  />
                </>
              ) : (
                <DataNotFound image={NotFound} text="No services found" />
              )}
            </div>
          ) : (
            <CircularProgress size={40} style={{ marginLeft: "50%", marginTop: "10%" }} />
          )}
          <UserSideBar
            visible={showAddServiceSideBar}
            LeftbuttonText="Cancel"
            RightbuttonText="Add"
            onCancelClick={() => serviceSideBar(false)}
            onInviteClick={() => serviceSideBar(false)}
            onClickOutside={() => (!isLoading && !loading ? serviceSideBar(false) : null)}
            title="Create Service">
            <AddService
              onCancelClick={val => {
                serviceSideBar(false);
                setIsLoading(false);
                if (val) {
                  updateTable(activeOwner, { sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, searchTerm);
                }
              }}
              isAdminPage={isAdminPage}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          </UserSideBar>
          {serviceToBeUpdated ? (
            <UserSideBar
              visible={openEditService && selectedServiceId !== null}
              LeftbuttonText="Cancel"
              RightbuttonText="Add"
              onCancelClick={() => setOpenEditService(false)}
              onInviteClick={() => setOpenEditService(false)}
              onClickOutside={() => (!isLoading && !loading ? setOpenEditService(false) : null)}
              title="Edit Service">
              <EditService
                serviceId={selectedServiceId || ""}
                serviceToBeUpdated={serviceToBeUpdated}
                isAdmin={isAdminPage}
                onCancelClick={(val?: boolean) => {
                  setOpenEditService(false);
                  setIsLoading(false);
                  if (val) {
                    updateTable(activeOwner, { sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, searchTerm);
                  }
                  setServiceToBeUpdated(null);
                }}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                isAdminPage={isAdminPage}
              />
            </UserSideBar>
          ) : null}
          <UserSideBar
            visible={openViewService && serviceToBeUpdated !== null}
            onClickOutside={() => (!isLoading ? handleClose() : null)}
            title="View Service">
            <ViewService
              onCancelClick={handleClose}
              service={serviceToBeUpdated}
              officeId={officeId}
              isLoading={isLoading}
              onCopyClick={service => copyServiceToOfficeFunc(service)}
            />
          </UserSideBar>
        </div>
      </div>
    </main>
  );
};

export default ServiceScreen;
