import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { BreadCrumbs, Pagination } from "../../components";
import { TableHeader, TableRow } from "../../components/TableComponents/TableComponents";
import { BreadCrumbsType } from "../../components/BreadCrumbs/BreadCrumbs";
import { headersType } from "../../models";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useDispatch } from "react-redux";
import { User } from "../../models/user.model";
import { getPatientReceipts } from "../../redux/slices/PatientSlice";
import { Link } from "react-router-dom";
import moment from "moment";
import { Receipt } from "../../models/receipt.model";

export const headers: headersType[] = [
  {
    title: "Service",
    extraClasses: "w-full hidden pl-2 lg:block truncate",
    sortKey: "service",
    isClickable: false,
  },
  {
    title: "Request #",
    extraClasses: "w-full lg:w-240 pl-0",
    sortKey: "requestNumber",
    isClickable: true,
  },
  {
    title: "Provider",
    extraClasses: "w-160 hidden lg:block  ",
    sortKey: "provider",
    isClickable: false,
  },
  {
    title: "Date Created",
    extraClasses: "w-160 hidden lg:block  ",
    sortKey: "dateCreated",
    isClickable: false,
  },
  {
    title: "",
    extraClasses: "w-56 hidden lg:block  ",
    sortKey: "",
    isClickable: false,
  },
];

const PatientReceipts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const { receipts, sortReceipts } = useSelector((state: RootState) => state.patient);
  //const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [extraClasses] = useState(headers.map(head => head.extraClasses));
  const [sortMethods] = useState<boolean[]>(headers.map(() => false));
  const [activeSortIndex] = useState<number>(-1);

  const breadcrumbs: BreadCrumbsType[] = [
    {
      title: "Receipts",
      key: "#",
    },
  ];

  const handleSort = (index: number) => {};

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));

  const download = (receipt: Receipt) => {
    if (receipt?.url) {
      fetch(receipt.url)
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `receipt-${receipt.requestNumber}`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(_ => {});
    }
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (currentUser) init(currentUser, newPage, sortReceipts.rowsPerPage);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (currentUser) init(currentUser, 0, parseInt(event.target.value, 10));
  };

  const init = async (currentUser: User, page = 0, size = 10) => {
    if (currentUser && currentUser?.offices && currentUser?.offices?.length && currentUser?.offices[0]?.officeId)
      await dispatch(
        getPatientReceipts({
          officeId: currentUser.offices[0].officeId,
          userId: currentUser.userId,
          page,
          size,
        })
      );
  };

  useEffect(() => {
    if (currentUser && !receipts.length) init(currentUser);
  }, [currentUser]);

  return (
    <>
      <main>
        <div className="container mx-auto p-4 md:p-8 min-h-[80vh]">
          <BreadCrumbs items={breadcrumbs} />
          <div className="flex justify-between items-center mb-6">
            <h2 className="heading uppercase">
              Hi {currentUser?.firstName ?? ""} {currentUser?.lastName ?? ""}
            </h2>
          </div>
          <p className="text-base mb-5 font-medium">You will find all your current and past receipts below.</p>
          <div className="block">
            <div className="flex justify-between items-center border-b border-docsigna-blue-light">
              {headers.map((head, index) => (
                <TableHeader
                  title={head.title}
                  index={index}
                  key={index}
                  sortIcon={sortIcons[index]}
                  handleSort={handleSort}
                  extraClassesHeader={extraClasses[index]}
                  isClickable={head.isClickable}
                />
              ))}
            </div>

            {receipts?.map((receipt, index) => {
              const sortedData = {
                service: receipt?.serviceName ?? "-",
                request: receipt?.requestNumber ?? "-",
                provider: `${receipt?.provider?.firstName ?? ""} ${receipt?.provider?.lastName ?? ""}`,
                date: moment(receipt?.createdDate ?? "").format("ll"),
                comment: receipt?.url ? (
                  <div className="flex gap-2">
                    <Link to={`${receipt.url}`} target="_blank">
                      <RemoveRedEyeOutlinedIcon className="text-docsigna-orange" />
                    </Link>
                    <div onClick={() => download(receipt)}>
                      <FileDownloadOutlinedIcon className="text-docsigna-orange" />
                    </div>
                  </div>
                ) : null,
              };
              return (
                <TableRow
                  data={Object.values(sortedData)}
                  key={`${index}-${receipt.requestNumber}`}
                  handleCloseTooltip={() => {}}
                  handleOpenTooltip={() => {}}
                  tooltipVisible={false}
                  extraClassesRow={extraClasses}
                  onRowClick={() => undefined}
                />
              );
            })}
            <Pagination
              colSpan={10}
              count={sortReceipts.totalElements ?? 0}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              page={sortReceipts.pageNumber}
              rowsPerPage={sortReceipts.rowsPerPage}
              // rowsPerPageOptions={[10, 25, 50, 100].filter(i => userRequest.data && i < userRequest.data?.totalElements)}
            />
          </div>

          <div></div>
        </div>
      </main>
    </>
  );
};

export default PatientReceipts;
