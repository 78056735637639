/* eslint-disable @typescript-eslint/no-useless-constructor */
import {
  LoggerFactoryOptions,
  LogGroupRule,
  LogLevel,
  LoggerFactory,
  LFService,
  AbstractLogger,
  LogMessage,
  LogFormat,
  LoggerType,
} from "typescript-logging";

import * as Sentry from "@sentry/react";
import { LogGroupRuntimeSettings } from "typescript-logging/dist/commonjs/log/standard/LogGroupRuntimeSettings";

// Sending everything through our custom logger
const ENVIRONMENT = process.env.REACT_APP_ENV;
const options = new LoggerFactoryOptions();
options.addLogGroupRule(
  new LogGroupRule(
    new RegExp(".+"),
    ENVIRONMENT === "production" ? LogLevel.Warn : LogLevel.Debug,
    new LogFormat(),
    LoggerType.Custom,
    (name: string, settings: LogGroupRuntimeSettings) => new Logger(name, settings)
  )
);

// Using DEFAULT logger so package logs are also handled
LFService.DEFAULT.configure(options);
export const logger: LoggerFactory = LFService.DEFAULT;

const sentry = {
  sendMessage: (message: string, level: Sentry.SeverityLevel = "debug") => {
    Sentry.captureMessage(message, level);
  },
};

class Logger extends AbstractLogger {
  constructor(name: string, settings: LogGroupRuntimeSettings) {
    super(name, settings);
  }

  protected doLog(msg: LogMessage): void {
    // Logging to console for tests and during development
    // TODO: This should not log to console in production, check REACT_APP_ENV
    // eslint-disable-next-line no-console
    console.log(this.createDefaultLogMessage(msg));

    if (msg.level >= LogLevel.Warn) {
      sentry.sendMessage(this.sentryMessage(msg), this.severityForLoggerLevel(msg.level));
    }
  }

  private sentryMessage(msg: LogMessage): string {
    let message = "[" + msg.loggerName + "]";

    let actualStringMsg = "";
    let dataString = "";
    if (typeof msg.message === "string") {
      actualStringMsg = msg.message;
    } else {
      const logData = msg.message;
      actualStringMsg = logData.msg;
      if (logData.data) {
        dataString = " " + (logData.ds ? logData.ds(logData.data) : JSON.stringify(logData.data));
      }
    }

    return (message += " " + actualStringMsg + "" + dataString);
  }

  private severityForLoggerLevel(level: LogLevel): Sentry.SeverityLevel {
    if (level === LogLevel.Info) {
      return "info";
    }

    if (level === LogLevel.Warn) {
      return "warning";
    }

    if (level === LogLevel.Error) {
      return "error";
    }

    return "debug";
  }
}
