/* eslint-disable no-useless-escape */
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { validateEmail, validatePass } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { guestTokenInitiate } from "../../redux/slices/OnBoardingSlice";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { useLocation } from "react-router-dom";
import bgImg from "../../assets/images/banner.jpg";
import { CheckRequestNoRegex } from "../../utils/constants";
import { TextInput } from "../../components";

const GuestLoginScreen = () => {
  const dispatch = useDispatch<AppDispatch>();

  const authContext = useContext(AuthContext);
  const params = useLocation().search;
  const email = new URLSearchParams(params).get("email")?.replace(/\s/g, "+");
  const requestNumber = new URLSearchParams(params).get("requestNumber");
  const navigate = useNavigate();
  const [reqemail, setEmail] = useState(email?.length ? email : "");
  const [reqNumber, setRequestNumber] = useState(requestNumber?.length ? requestNumber : "");
  const [emailError, setEmailError] = useState(false);
  const [requestNumberError, setRequestNumberError] = useState(false);
  const [initErrorCheck, setInitErrorCheck] = useState(false);
  const [guestTokenInitiateError, setGuestTokenInitiateError] = useState<boolean>(false);
  const { pending } = useSelector((state: RootState) => state.onBoarding);
  localStorage.setItem("userData", JSON.stringify({ email: reqemail, requestNumber: reqNumber }));
  const onEmailLinkClick = () => {
    setInitErrorCheck(true);
    setEmailError(!validateEmail(reqemail));
    setRequestNumberError(!validatePass(reqNumber));
    if (validateEmail(reqemail) && validatePass(reqNumber)) {
      const requestNum = reqNumber;
      dispatch(guestTokenInitiate({ email: reqemail, requestNumber: requestNum })).then(action => {
        if (action?.payload !== null) {
          authContext?.signout();
          localStorage.setItem("maskedPhoneNumber", JSON.stringify(action?.payload));
          navigate("/guest/verify-phone");
        } else {
          setGuestTokenInitiateError(true);
        }
      });
    }
  };
  const onEmailChange = (email: string) => {
    if (initErrorCheck) {
      setEmailError(!validateEmail(email));
    }
    setEmail(email);
  };
  const onPassChange = (pass: string) => {
    if (initErrorCheck) {
      setRequestNumberError(!validatePass(pass));
    }
    if (!pass.match(CheckRequestNoRegex)) {
      setRequestNumber(pass);
    }
  };
  return (
    <main className="">
      <div
        onKeyDown={e => {
          if (e.key === "Enter") {
            onEmailLinkClick();
          }
        }}>
        <section className="max-h-[21rem] overflow-hidden">
          <img src={bgImg} alt="bg" />
        </section>
        <section className="lg:flex m-auto mt-5 sm:mt-10 px-5 sm:px-10">
          <div className="w-full lg:w-[35rem] m-auto rounded-3xl p-10 bg-docsigna-pink-light mb-5 sm:mb-10">
            <ul>
              <li>
                <h2 className="heading uppercase font-semibold">Docnote debit</h2>
              </li>
              <li className="mt-5">
                <TextInput
                  label="Email"
                  errorMsg={"Please enter a valid email address"}
                  value={reqemail}
                  placeholder="Enter email address"
                  isError={emailError}
                  onChangeFunc={e => onEmailChange(e.target.value)}
                  isRequired={true}
                  isDarkBg={true}
                />
              </li>
              <li className="mt-5">
                <TextInput
                  label="Request Number"
                  errorMsg={"Please enter your request number"}
                  isError={requestNumberError}
                  value={reqNumber}
                  placeholder="Enter request number"
                  onChangeFunc={e => onPassChange(e.target.value)}
                  isRequired={true}
                  isDarkBg={true}
                />
              </li>
              <li className="mt-5 flex flex-wrap gap-4 justify-between">
                <Button width="fit" text="Access Request" onClickFunc={onEmailLinkClick} disabled={emailError || requestNumberError || pending} />
                <span className="cursor-pointer font-medium mt-2 block">
                  Have an account? Click here to{" "}
                  <span className="cursor-pointer text-docsigna-purple-dark" onClick={() => navigate("/login")}>
                    login
                  </span>
                  .
                </span>
              </li>
            </ul>
            {guestTokenInitiateError ? <p className="text-sm text-red-400">Unable to verify your request for access, please try again.</p> : null}
          </div>
        </section>
      </div>
    </main>
  );
};
export default GuestLoginScreen;
