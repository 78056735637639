import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "../Tooltip/Tooltip";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { DsNotification, DsNotificationType } from "../../models/notification.model";
import { useDispatch } from "react-redux";
import {
  deleteUserNotification,
  getUserNotifications,
  markAllUserNotificationsAsRead,
  setUserNotificationRead,
} from "../../redux/slices/NotificationSlice";
import { getTimeInStringFormat } from "../../utils";
import { CircularProgress, ClickAwayListener } from "@mui/material";
import { SESSION_STORAGE_NOTIFICATION_TAB } from "../../utils/constants";
import Triangle from "../../assets/images/border-top-triangle.svg";
import { UserType } from "../../services/user.service";

const Notifications = ({
  setShowNotifications,
  userOfficeId,
  selectedTab,
}: {
  setShowNotifications: (val: boolean) => void;
  userOfficeId: string;
  selectedTab: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const listInnerRef = useRef<any>();
  const { notifications, pageable, loading } = useSelector((state: RootState) => state.notifications);
  const user = useSelector((state: RootState) => state.currentUser.currentUser);
  const [showAll, setShowAll] = useState<boolean>(selectedTab);
  const [showNotificationTooltip, setShowNotificationTooltip] = useState(false);
  const [showNotificationItemTooltip, setShowNotificationItemTooltip] = useState(-1);

  const onScroll = () => {
    if (listInnerRef.current && user?.userId) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight && !loading) {
        dispatch(getUserNotifications({ userId: user?.userId, page: pageable.number + 1 }));
      }
    }
  };

  const handleTooltipClick = () => {
    if (user?.userId) {
      dispatch(markAllUserNotificationsAsRead({ userId: user?.userId }));
    }
  };

  const handleNotificationItemTooltipClick = (item: DsNotification, index: number, optionIndex?: number) => {
    if (user?.userId) {
      if (optionIndex === 0) {
        dispatch(
          setUserNotificationRead({
            userId: user?.userId,
            notificationId: item.notificationId,
            read: item.unread,
          })
        );
      } else {
        dispatch(
          deleteUserNotification({
            userId: user?.userId,
            notificationId: item.notificationId,
          })
        );
      }
    }
  };

  const openNotification = (notification: DsNotification) => {
    setShowNotifications(false);
    if (user?.userId && notification.unread) {
      dispatch(
        setUserNotificationRead({
          userId: user?.userId,
          notificationId: notification.notificationId,
          read: notification.unread,
        })
      );
    }
    switch (notification.notificationType) {
      case DsNotificationType.Request:
        if (user?.userType === UserType.Patient) {
          navigate(`patients/${user?.userId}/requests/${notification.entityId}`);
        } else {
          navigate(`offices/${userOfficeId}/requests/${notification.entityId}`);
        }
        break;
      default:
        break;
    }
  };

  const changeNotificationTab = () => {
    setShowAll(!showAll);
    if (!showAll) {
      sessionStorage.setItem(SESSION_STORAGE_NOTIFICATION_TAB, "yes");
    } else {
      sessionStorage.setItem(SESSION_STORAGE_NOTIFICATION_TAB, "no");
    }
  };

  const finalNotications = showAll ? notifications : notifications.filter(item => item.unread);
  const activeButtonClass = "cursor-pointer p-4 py-1 font-bold border-0 bg-docsigna-orange text-white font-medium text-base rounded-full";
  const inactiveButtonClass = "cursor-pointer p-4 py-1 px-0 font-bold border-0 text-base text-docsigna-blue-dark";
  return (
    <ClickAwayListener onClickAway={() => setShowNotifications(false)}>
      <div className="sm:w-96 w-80 max-h-screen absolute top-[25px] right-0 z-50" ref={listInnerRef} onScroll={() => onScroll()}>
        <div className="flex justify-end">
          <img src={Triangle} alt="traingle" className="mr-3" />
        </div>
        <div className="bg-docsigna-pink-light shadow rounded-[13px] p-5" style={{ maxHeight: "90vh", overflowY: "auto" }}>
          <div className="flex justify-between relative">
            <h2 className="heading text-xl uppercase">Notifications</h2>
            <Tooltip
              handleCloseTooltip={() => setShowNotificationTooltip(false)}
              handleOpenTooltip={() => setShowNotificationTooltip(true)}
              open={showNotificationTooltip}
              imageComponent={<MoreHorizIcon className="text-docsigna-orange cursor-pointer" />}
              additionalClasses={"w-40 relative flex items-center justify-center cursor-pointer z-50"}
              options={["Mark All As Read"]}
              onSelect={() => handleTooltipClick()}
              id={"id"}
            />
          </div>
          <div className="flex mt-2 gap-4">
            <span onClick={changeNotificationTab} className={`${showAll ? activeButtonClass : inactiveButtonClass}`}>
              All
            </span>
            <span onClick={changeNotificationTab} className={`${!showAll ? activeButtonClass : inactiveButtonClass}`}>
              Unread
            </span>
          </div>
          <ul className="mt-4">
            {finalNotications?.length ? (
              finalNotications.map((item: DsNotification, index: number) => {
                return (
                  <>
                    {index > 0 ? <hr className="my-3 border-docsigna-blue-dark opacity-25" /> : null}
                    <li key={item.notificationId} className="mb-3 relative flex justify-start items-center">
                      <div className={`w-3 h-3 mr-2 rounded-full ${item.unread ? "bg-docsigna-blue-light border" : ""}`}></div>
                      <div className="relative w-10/12 cursor-pointer" onClick={() => openNotification(item)}>
                        <h4 className={`text-base text-docsigna-blue-dark ${item.unread ? "font-bold" : "font-medium"} leading-tight`}>
                          {item.text}
                        </h4>
                        <h5 className={`text-xs font-bold text-docsigna-blue-light mt-2`}>{getTimeInStringFormat(item.createdDate)}</h5>
                      </div>
                      <Tooltip
                        handleCloseTooltip={() => setShowNotificationItemTooltip(-1)}
                        handleOpenTooltip={() => setShowNotificationItemTooltip(index)}
                        open={showNotificationItemTooltip === index}
                        imageComponent={<MoreHorizIcon className="text-docsigna-orange cursor-pointer" />}
                        additionalClasses={"w-40 relative flex items-center justify-center cursor-pointer z-50"}
                        options={!item.unread ? ["Mark As Unread", "Remove Notification"] : ["Mark As Read", "Remove Notification"]}
                        onSelect={(type, id, ind) => handleNotificationItemTooltipClick(item, index, ind)}
                        id={"id"}
                      />
                    </li>
                  </>
                );
              })
            ) : (
              <span className="flex justify-center font-medium">No Notifications available.</span>
            )}
          </ul>
          {loading ? (
            <div className="w-full text-center mb-3">
              <CircularProgress size={20} color={"info"} />
            </div>
          ) : null}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Notifications;
