import React from "react";
import { colors } from "../../utils/constants";

interface SelectProps {
  options: string[];
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  required: boolean;
  title: string;
  placeHolder: string;
  defaultValue?: string;
  disabled?: boolean;
  errorMsg?: string;
  name?: string;
  isDarkBg?: boolean;
}

const Select = (props: SelectProps) => {
  const { options, onSelect, required, title, placeHolder, defaultValue, disabled = false, isDarkBg = false, errorMsg } = props;

  return (
    <>
      {title ? (
        <>
          <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-1">
            {title}
            {required ? <span className="text-red-500">*</span> : null}
          </label>{" "}
        </>
      ) : null}
      <select
        onChange={onSelect}
        name={props?.name ?? ""}
        disabled={disabled}
        style={disabled ? { backgroundColor: "#D0D5DD" } : {}}
        defaultValue={defaultValue ?? placeHolder}
        className={`block w-full ${title ? "mt-1" : ""} rounded-md shadow-none border border-docsigna-blue-dark ${
          isDarkBg ? "bg-docsigna-pink-light" : ""
        }`}>
        <option value={""}>{placeHolder}</option>
        {options.map((val, i) => {
          return (
            <option key={i} value={val} selected={defaultValue === val}>
              {val}
            </option>
          );
        })}
      </select>
      {errorMsg ? <p style={{ color: colors.Red }}>{errorMsg}</p> : null}
    </>
  );
};

export default Select;
