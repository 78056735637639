import React from "react";
import DountGraph from "../../components/DonutGraph/DonutGraph";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { formatCurrency } from "../../utils";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NotFound from "../../assets/images/no-financial.png";
import { ServiceBilledTo, ServiceBilledToName } from "../../models/service.model";

const FinanceDashboard = ({ noRecordFound = false }: { noRecordFound: boolean }) => {
  const { officeFinanceReport } = useSelector((state: RootState) => state.finance);
  const graphData = officeFinanceReport?.billedToAll?.length
    ? officeFinanceReport.billedToAll.map(item => {
        return {
          billedTo: item.billedTo,
          count: item.count,
          amount: item.amount,
        };
      })
    : [];
  if (noRecordFound) return <DataNotFound image={NotFound} text="No financial records found" />;
  return (
    <React.Fragment>
      {/* Header Start */}
      <div className="px-6 py-5 bg-docsigna-pink-light mb-10 rounded-b-xl rounded-tr-xl">
        <h2 className="text-xl font-semibold text-docsigna-purple heading mb-5">REVENUE</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-2">
          <div>
            <div className="flex justify-between items-center border py-1 border-x-0 border-t-0 border-dotted border-docsigna-purple">
              <h3 className="text-lg font-medium text-docsigna-purple">Total Service Revenue</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {officeFinanceReport?.totalRevenue !== undefined
                  ? formatCurrency((officeFinanceReport?.totalRevenue / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
            <div className="flex justify-between items-center border py-1 border-x-0 border-t-0 border-dotted border-docsigna-purple">
              <h3 className="text-lg font-medium text-docsigna-purple">Total Annual Plan Revenue</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {process.env.REACT_APP_ENV === "production"
                  ? "$00.00"
                  : officeFinanceReport?.totalHstApplicableRevenue !== undefined
                  ? formatCurrency((officeFinanceReport?.totalHstApplicableRevenue / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
            <div className="flex justify-between items-center py-1">
              <h3 className="text-lg font-medium text-docsigna-purple">HST Collected</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {officeFinanceReport?.hstCollected !== undefined
                  ? formatCurrency((officeFinanceReport?.hstCollected / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
          </div>
          <div>
            <div className="flex justify-between items-center border py-1 border-x-0 border-t-0 border-dotted border-docsigna-purple">
              <h3 className="text-lg font-medium text-docsigna-purple">Collected in Docnote</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {officeFinanceReport?.dsCollected !== undefined
                  ? formatCurrency((officeFinanceReport?.dsCollected / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
            <div className="flex justify-between items-center border py-1 border-x-0 border-t-0 border-dotted border-docsigna-purple">
              <h3 className="text-lg font-medium text-docsigna-purple">Collected in Office</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {officeFinanceReport?.collectedByOffice !== undefined
                  ? formatCurrency((officeFinanceReport?.collectedByOffice / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
            <div className="flex justify-between items-center py-1">
              <h3 className="text-lg font-medium text-docsigna-purple">Refunds Provided</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {formatCurrency((officeFinanceReport?.refundProvided ?? 0 / 100)?.toString(), "blur")}
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* Header End */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-2">
        {/* Category Left Part Start */}
        <div className="flex flex-wrap items-center justify-between mt-3">
          <div className="text-center">
            <h2 className="text-lg font-semibold text-docsigna-purple heading mb-2">NUMBER OF ACCOUNTS</h2>
            <h3 className="text-[60px] font-normal text-docsigna-purple heading leading-none">{officeFinanceReport?.totalNumberOfAccount ?? 0}</h3>
            {/* <p className="flex justify-center items-center gap-3 text-[25px] font-medium text-green-500 heading leading-none">
              <img src={UpArrow} alt="up arrow" />
              +25
            </p> */}
          </div>
          <div className="text-center">
            <h2 className="text-lg font-semibold text-docsigna-purple heading mb-2 uppercase">Number of Requests Created</h2>
            <h3 className="text-[60px] font-normal text-docsigna-purple heading leading-none">
              {officeFinanceReport?.numRequestsInvoiced?.toString() ?? ""}
            </h3>
            {/* <p className="flex justify-center items-center gap-3 text-[25px] font-medium text-red-500 heading leading-none">
              <img src={DownArrow} alt="up arrow" />
              -14
            </p> */}
          </div>
        </div>
        {/* Category Left Part End */}

        {/* Category Right Part Start */}
        <div className="flex flex-col gap-3">
          <div className="px-20 h-[240px]">
            <DountGraph
              height={240}
              billedToList={graphData}
              graphLabel={"Total Requests"}
              totalCount={officeFinanceReport?.totalNumberOfRequest?.toString() ?? ""}
            />
          </div>
        </div>
        {/* Category Right Part End */}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-2 mt-3">
        <div>
          <div className="flex gap-2 items-center text-xl font-semibold text-docsigna-purple heading mb-3">
            <h2>REVENUE BY CATEGORY</h2>
            {/* <p className="text-sm">(compared to previous month)</p> */}
          </div>
          {officeFinanceReport?.billedTo?.map((item, index) => {
            let name = "";
            switch (item.billedTo.toLowerCase()) {
              case ServiceBilledTo.Patient:
                name = ServiceBilledToName[ServiceBilledTo.Patient];
                break;
              case ServiceBilledTo.OHIP:
                name = ServiceBilledToName[ServiceBilledTo.OHIP];
                break;
              case ServiceBilledTo.ThirdParty:
                name = ServiceBilledToName[ServiceBilledTo.ThirdParty];
                break;
              case ServiceBilledTo.WSIB:
                name = ServiceBilledToName[ServiceBilledTo.WSIB];
                break;
              case ServiceBilledTo.ServiceCanada:
                name = ServiceBilledToName[ServiceBilledTo.ServiceCanada];
                break;
              case ServiceBilledTo.PrescriptionRefill:
                name = ServiceBilledToName[ServiceBilledTo.PrescriptionRefill];
                break;
              case ServiceBilledTo.DocnoteDebit:
                name = ServiceBilledToName[ServiceBilledTo.DocnoteDebit];
                break;
              default:
                break;
            }
            return (
              <div key={index} className={`flex gap-3 px-5 py-4 items-center justify-between border-b border-docsigna-purple border-dashed`}>
                <div className="flex gap-2 items-center">
                  <h2 className="text-lg font-bold text-docsigna-purple uppercase">{name}</h2>
                </div>
                <p className="text-base font-bold text-docsigna-purple">
                  {item.amount ? formatCurrency((item.amount / 100)?.toString(), "blur") : "$0.00"}
                </p>
              </div>
            );
          })}
        </div>
        <div className="">
          <h2 className="text-xl font-semibold text-docsigna-purple heading mb-3">TOP BILLED SERVICES</h2>
          <div className="flex flex-col gap-1 p-7 bg-docsigna-pink-light rounded-2xl h-[93%]">
            <div className="flex flex-wrap text-lg font-bold text-docsigna-purple uppercase">
              <h3 className="w-7/12">Service</h3>
              <div className="w-5/12 flex flex-wrap gap-y-2 justify-between">
                <h3>QTY</h3>
                <h3>REVENUE</h3>
              </div>
            </div>
            {officeFinanceReport?.services?.map((service, index) => {
              return (
                <div key={index} className="flex flex-wrap text-base font-semibold text-docsigna-purple">
                  <h3 className="w-7/12 break-all">{service.name}</h3>
                  <div className="w-5/12 flex flex-wrap gap-y-2 justify-between">
                    <h3>{service.count}</h3>
                    <h3>{service.amount ? formatCurrency((service.amount / 100)?.toString(), "blur") : "$0.00"}</h3>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default FinanceDashboard;
