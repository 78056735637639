import React, { ChangeEvent, useContext, useState } from "react";
import { Button, TextArea } from "../../components";
import { FileTypes, ToastVariants } from "../../models";
import SupportImg from "../../assets/images/support.png";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { submitSupportForm } from "../../redux/slices/OfficeSlice";
import { DeleteOutline } from "@mui/icons-material";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { colors } from "../../utils/constants";
const allowedFiles = [FileTypes.JPEG, FileTypes.JPG, FileTypes.PNG, FileTypes.PDF];

const Support = ({ officeId }: { officeId: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const [message, setMessage] = useState("");
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].type && allowedFiles.includes(e.target.files[0].type as FileTypes)) {
        setFile(e.target.files[0]);
      } else {
        toast?.openToast("Invalid file format", 2000, ToastVariants.Warn);
      }
    }
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      const res = await dispatch(
        submitSupportForm({
          officeId: officeId,
          message,
          file: file ?? undefined,
        })
      );
      if (res.payload) {
        setRequestSubmitted(true);
      } else {
        toast?.openToast("Something went wrong. Try again!");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast?.openToast("Something went wrong. Try again!");
    }
  };

  return (
    <>
      <div className="mx-auto p-4 w-full min-h-96">
        {!requestSubmitted ? (
          <>
            <h2 className="text-2xl mb-1 text-center text-docsigna-blue-dark">Support</h2>
            <p className="text-base mb-3 text-docsigna-blue-dark">Please explain how we can be of assisstance and we will get back to you shortly!</p>
            <TextArea
              onChange={e => setMessage(e.target.value)}
              value={message}
              label=""
              extraTextAreaClass={"h-48 bg-transparent resize-none border-docsigna-blue-dark"}
            />
            <div className="text-center bg-transparent border border-docsigna-blue-dark rounded my-5 relative">
              <input
                type="file"
                className="text-base rounded-md border-gray-300 focus:border-docsigna-purple w-full h-full py-6 px-4 opacity-0 relative z-10"
                onChange={handleFileChange}
              />
              <div className="w-full h-full py-6 px-4 absolute top-0 flex items-center justify-center">
                <AttachFileIcon className="rotate-[45deg]" />
                <p className="ml-1 mt-1">Upload attachment here</p>
              </div>
            </div>
            {file ? (
              <div className="text-center my-5 relative flex items-center justify-between">
                <div className="flex items-center">
                  <p>{file.name}</p>
                </div>
                <div className="cursor-pointer" onClick={() => setFile(undefined)}>
                  <DeleteOutline htmlColor={colors.Orange} />
                </div>
              </div>
            ) : null}
            <div className="text-center">
              <Button
                disabled={!message.trim() || loading}
                width="fit"
                AdditionalClassNames="px-5"
                text="Submit Request"
                onClickFunc={() => submitForm()}
              />
            </div>
          </>
        ) : (
          <div>
            <h2 className="text-2xl mb-1 text-center">Thank you for reaching out!</h2>
            <p className="text-base mb-3 text-center">Our support team will contact you soon to help.</p>
            <img src={SupportImg} alt="Support" className="max-h-96" />
          </div>
        )}
      </div>
    </>
  );
};

export default Support;
