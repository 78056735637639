import { Finance } from "../../models/finance.model";
import { formatCurrency } from "../../utils";

const DashboardTabRevenueSection = ({ financeReport }: { financeReport: Finance | null }) => {
  return (
    <div className="p-5 bg-docsigna-pink-light">
      <div className="block lg:flex w-full pl-0">
        <div className="w-full lg:w-1/2 gap-[34px] flex flex-col bg-white text-docsigna-blue-dark p-5 rounded">
          <h2 className="text-xl font-bold text-docsigna-blue-extra-light">Revenue</h2>
          <div className="block md:flex justify-start lg:justify-between gap-[34px] lg:gap-0 overflow-hidden">
            <div className="flex gap-[34px] flex-col">
              <div className="">
                <h5 className="font-bold text-2xl">
                  {financeReport?.totalRevenue !== undefined ? formatCurrency((financeReport?.totalRevenue / 100)?.toString(), "blur") : "$0.00"}
                </h5>
                <h3 className="text-xs font-medium">Total Revenue</h3>
              </div>
              <div className="">
                <h5 className="font-bold text-2xl">
                  {financeReport?.netIncome !== undefined ? formatCurrency((financeReport?.netIncome / 100)?.toString(), "blur") : "$0.00"}
                </h5>
                <h3 className="text-xs font-medium">Net Income</h3>
              </div>
            </div>
            <div className="bg-docsigna-blue-dark h-max sm:h-full w-[1px]"></div>
            <div className="flex gap-[34px] flex-col mt-5 md:mt-0">
              <div className="">
                <h5 className="font-bold text-2xl">
                  {financeReport?.feesOwedToDs !== undefined ? formatCurrency((financeReport?.feesOwedToDs / 100)?.toString(), "blur") : "$0.00"}
                </h5>
                <h3 className="text-xs font-medium">Fees Owed to Docnote</h3>
              </div>
              <div className="">
                <h5 className="font-bold text-2xl">
                  {financeReport?.discountsProvided !== undefined
                    ? formatCurrency((financeReport?.discountsProvided / 100)?.toString(), "blur")
                    : "$0.00"}
                </h5>
                <h3 className="text-xs font-medium">Discounts Provided</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="block md:flex justify-start lg:justify-between mt-5 lg:mt-0 lg:ml-5">
          <div className="p-5 border border-white rounded">
            <h2 className="text-xl font-bold text-docsigna-blue-extra-light mb-5">Payments</h2>
            <div className="border-b border-docsigna-blue-dark mb-3">
              <h5 className="font-medium text-xl">
                {financeReport?.dsCollected !== undefined ? formatCurrency((financeReport?.dsCollected / 100)?.toString(), "blur") : "$0.00"}
              </h5>
              <h3 className="mb-2 text-xs font-medium">Collected by Docnote</h3>
            </div>
            <div className="border-b border-docsigna-blue-dark mb-3">
              <h5 className="font-medium text-xl">
                {financeReport?.collectedByOffice !== undefined
                  ? formatCurrency((financeReport?.collectedByOffice / 100)?.toString(), "blur")
                  : "$0.00"}
              </h5>
              <h3 className="mb-2 text-xs font-medium">Collected by office</h3>
            </div>
            <div className="">
              <h5 className="font-medium text-xl">
                {financeReport?.outstandingPayments !== undefined
                  ? formatCurrency((financeReport?.outstandingPayments / 100)?.toString(), "blur")
                  : "$0.00"}
              </h5>
              <h3 className="mb-2 text-xs font-medium">Outstanding Payments</h3>
            </div>
          </div>
          <div className="p-5 border border-white rounded ml-0 md:ml-5 mt-5 md:mt-0">
            <h2 className="text-xl font-bold text-docsigna-blue-extra-light mb-5">Taxes</h2>
            <div className="border-b border-docsigna-blue-dark mb-3">
              <h5 className="font-medium text-xl">
                {financeReport?.totalHstApplicableRevenue !== undefined
                  ? formatCurrency((financeReport?.totalHstApplicableRevenue / 100)?.toString(), "blur")
                  : "$0.00"}
              </h5>
              <h3 className="mb-2 text-xs font-medium">Total HST Applicable Revenue</h3>
            </div>
            <div className="">
              <h5 className="font-medium text-xl">
                {financeReport?.hstCollected !== undefined ? formatCurrency((financeReport?.hstCollected / 100)?.toString(), "blur") : "$0.00"}
              </h5>
              <h3 className="mb-2 text-xs font-medium">HST Collected</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTabRevenueSection;
