import { ApiPage, emptyApiPage } from "../models/api-page.model";
import { DsNotification, DsNotificationCounts } from "../models/notification.model";
import { ApiPatchOp, apiService } from "./api.service";

export class NotificationService {
  private static BASE_PATH = "/api/v1/users";

  static async getUserNotificationCounts(userId: string): Promise<DsNotificationCounts | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${userId}/notifications/count`);
    if (!response.success) return null;

    return response.data as DsNotificationCounts;
  }

  static async getUserNotifications(userId: string, page = 0, size = 10): Promise<ApiPage<DsNotification>> {
    const response = await apiService.get(`${this.BASE_PATH}/${userId}/notifications?page=${page}&size=${size}`);
    if (!response.success) return emptyApiPage<DsNotification>();

    return response.data as ApiPage<DsNotification>;
  }

  static async markAllUserNotificationsAsRead(userId: string): Promise<boolean> {
    const response = await apiService.put(`${this.BASE_PATH}/${userId}/notifications/read`);
    if (!response.success) return false;

    return true;
  }

  static async markAllUserNotificationsForRequestAsRead(userId: string, requestId: string): Promise<boolean> {
    const response = await apiService.put(`${this.BASE_PATH}/${userId}/requests/${requestId}/notifications/read`);
    if (!response.success) return false;

    return true;
  }

  static async setUserNotificationRead(userId: string, notificationId: string, read: boolean): Promise<DsNotification | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${userId}/notifications/${notificationId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/unread",
        value: !read,
      },
    ]);
    if (!response.success) return null;

    return response.data as DsNotification;
  }

  static async deleteUserNotification(userId: string, notificationId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/${userId}/notifications/${notificationId}`);
    if (!response.success) return false;

    return true;
  }
}
