/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Request, RequestDiscountType, RequestType } from "../../../models/request.model";
import { ServiceBilledTo, ServiceOwner } from "../../../models/service.model";
import { getOfficeRequest, setRequestSaved } from "../../../redux/slices/OfficeRequestSlice";
import { searchServiceData } from "../../../redux/slices/ServiceSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { billedToOptions, createUpdateRequestObject, formatCurrency, priceReview } from "../../../utils";
import { AmountReplaceRegex, DiscountValueRegex, colors } from "../../../utils/constants";
import Button from "../../Button/Button";
import SelectWithSearch from "../../Select/SelectWithSearch";
import TextInput from "../../TextInput/TextInput";
import UserSideBar from "../../UserSideBar/UserSideBar";
import AddEditFee from "../AddEditFee/AddEditFee";
import { InvoiceItem } from "../../../models/invoice.model";
import { OfficeService } from "../../../services/office.service";
import { UpdateRequest } from "../../../models/update-request.model";
import { UpdateFee } from "../../../models/update-fee.model";
import { updateRequest } from "../../../redux/slices/OnBoardingSlice";
import { PriceDetails } from "../../../models/price-details.model";
import { ToastVariants } from "../../../models";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { useDebouncedCallback } from "use-debounce";
import { serviceBilled } from "../../../screens/CreateRequestScreen/CreateRequestData";

interface formDataType {
  id: string;
  value: string | boolean;
  title: string;
  placeholder: string;
  required: boolean;
  errorMessage: string;
  valid: boolean;
  type: "textInput" | "select" | "servicename" | "servicefee" | "checkbox";
}

interface TempRequestDataType extends Request {
  additionalFees?: UpdateFee[];
}

const AssignService = ({
  onClickCancel,
  setIsLoading,
  isLoading,
}: {
  onClickCancel: () => void;
  setIsLoading: (val: boolean) => void;
  isLoading?: boolean;
}) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const services = useSelector((state: RootState) => state.service.serviceList.data?.content);
  const { requestData, invoiceObject } = useSelector((state: RootState) => state.officeRequest);
  const [serviceNotesValue, setServiceNotesValue] = useState(requestData?.serviceNotes || "");
  const [payeeError, setPayeeError] = useState(false);
  const [discountError, setDiscountError] = useState(false);
  const [priceDetails, setPriceDetails] = useState<PriceDetails | undefined>(undefined);
  const readOnly = requestData?.paymentComplete || requestData?.submitted;

  const isThirdParty =
    requestData?.requestType === RequestType.ThirdPartyOffice ||
    requestData?.requestType === RequestType.PrescriptionRefill ||
    requestData?.requestType === RequestType.DocnoteDebit;

  const newServices = services?.filter(f => {
    switch (requestData?.requestType) {
      case RequestType.ThirdPartyOffice:
        switch (requestData?.serviceBilledToOverride) {
          case ServiceBilledTo.WSIB:
            return f.serviceBilledTo === ServiceBilledTo.WSIB || f.serviceBilledTo === undefined;
          case ServiceBilledTo.ServiceCanada:
            return f.serviceBilledTo === ServiceBilledTo.ServiceCanada || f.serviceBilledTo === undefined;
          default:
            return f.serviceBilledTo === ServiceBilledTo.ThirdParty || f.serviceBilledTo === undefined;
        }
      case RequestType.QuickFormOffice:
      case RequestType.PrescriptionRefill:
        return f.serviceBilledTo === ServiceBilledTo.Patient || f.serviceBilledTo === undefined;
      case RequestType.DocnoteDebit:
        return f.serviceBilledTo === ServiceBilledTo.DocnoteDebit;
      default:
        return f.serviceBilledTo !== ServiceBilledTo.DocnoteDebit || f.serviceBilledTo === undefined;
    }
  });

  const formDataInitialState: formDataType[] = [
    {
      id: "serviceName",
      value: requestData?.service?.serviceId || "",
      title: "",
      placeholder: "Select Service",
      required: false,
      errorMessage: "Please select a service.",
      valid: requestData?.service?.serviceId ? true : false,
      type: "servicename",
    },
    {
      id: "price",
      value: `${formatCurrency(
        requestData?.servicePriceOverride === 0
          ? "0"
          : (requestData?.servicePriceOverride && (requestData?.servicePriceOverride / 100).toString()) ||
              (requestData?.service?.price && (requestData?.service?.price / 100).toString()) ||
              "",
        "blur"
      )}`,
      title: "Cost",
      placeholder: "$",
      required: false,
      errorMessage: "Please enter service cost.",
      valid:
        formatCurrency(
          (requestData?.servicePriceOverride && (requestData?.servicePriceOverride / 100).toString()) ||
            (requestData?.service?.price && (requestData?.service?.price / 100).toString()) ||
            "",
          "blur"
        ).length > 0,
      type: "servicefee",
    },
    {
      id: "hst",
      title: "+ HST",
      placeholder: "",
      type: "checkbox",
      value: "",
      errorMessage: "",
      required: true,
      valid: true,
    },
  ];

  const [formData, setFormData] = useState(formDataInitialState);
  const [tempRequestData, setTempRequestData] = useState<TempRequestDataType | undefined>(undefined);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [serviceNotesError, setServiceNotesError] = useState(false);
  const [serviceOption, setServiceOption] = useState(requestData?.serviceBilledToOverride || requestData?.service?.serviceBilledTo || "");
  const [addSideBarOpen, setAddSideBarOpen] = useState(false);
  const [selectsearch, setselectSearch] = useState("");
  const [discountValue, setDiscountValue] = useState("");

  const readOnlyServicePrice = formatCurrency(
    (requestData?.servicePriceOverride ? requestData?.servicePriceOverride / 100 : 0).toString() ||
      (requestData?.service?.price ? requestData?.service?.price / 100 : 0).toString() ||
      "",
    ""
  );

  const {
    servicePrice,
    dscDesc,
    dscValue,
    blockFeeDiscountDesc,
    blockFeeDiscountAmount,
    additionalFeeTotal,
    subTotal,
    dsFee,
    dsFeeDesc,
    hstValue,
    hstPercentage,
    hstDesc,
    total,
  } = priceReview(priceDetails);

  const handleServiceSelect = (e: string) => {
    if (tempRequestData && !isThirdParty)
      setTempRequestData({
        ...tempRequestData,
        serviceBilledToOverride: e as ServiceBilledTo,
      });
    setServiceOption(e);
  };

  const handleChange = (id: string, value: string | boolean) => {
    switch (id) {
      case "payee":
        if (value !== "none") setPayeeError(false);
        break;
      case "price":
        const tempPrice = parseFloat((value as string).replace("$", ""));
        tempRequestData ? setTempRequestData({ ...tempRequestData, servicePriceOverride: tempPrice * 100 }) : null;
        break;
      case "serviceNotes":
        if ((value as string).length > 150) {
          setServiceNotesError(true);
        } else {
          setServiceNotesError(false);
          setServiceNotesValue(value as string);
        }
        break;
      default:
        break;
    }

    const tempFormData = [...formData];

    tempFormData.forEach(input => {
      if (input.id === id) {
        if (id !== "serviceName") {
          input.value = formatCurrency(value as string, "");
          input.valid = formatCurrency(value as string, "") !== "";
        } else if (id === "serviceName") {
          const selectedService = newServices?.find(serv => serv.serviceId === value);
          if (tempRequestData) {
            setTempRequestData({
              ...tempRequestData,
              service: selectedService,
              servicePriceOverride: selectedService?.price,
              serviceBilledToOverride: !isThirdParty ? selectedService?.serviceBilledTo : tempRequestData?.serviceBilledToOverride,
            });
            if (tempRequestData?.discountAmount && tempRequestData?.discountType === RequestDiscountType.Amount) {
              handleDiscount("input", (tempRequestData?.discountAmount / 100).toString(), selectedService?.price);
            }
          }

          if (!isThirdParty) {
            if (selectedService?.serviceBilledTo) {
              setServiceOption(selectedService.serviceBilledTo);
              setPayeeError(false);
            } else {
              setServiceOption("none");
              setPayeeError(true);
            }
          }

          input.value = value;
          input.valid = value !== "";
          tempFormData[tempFormData.findIndex(v => v.id === "price")].value = formatCurrency(
            (newServices?.find(v => v.serviceId === value)?.price! / 100).toString() || "",
            "blur"
          );
          tempFormData[tempFormData.findIndex(v => v.id === "price")].valid =
            formatCurrency((newServices?.find(v => v.serviceId === value)?.price! / 100).toString() || "", "blur").length > 0;
        } else {
          input.value = value;
          input.valid = value !== "";
        }
      }
    });
    setFormData(tempFormData);
  };

  const handleBlur = (id: string) => {
    const tempData = [...formData];
    tempData.forEach(input => {
      if (input.id === id) {
        input.value = formatCurrency(input.value as string, "blur");
      }
    });
    setFormData(tempData);
  };

  const renderInput = (inp: formDataType) => {
    switch (inp.type) {
      case "servicename":
        return (
          <div key={inp.id} className="mt-3 flex items-center">
            <label className="text-sm font-medium text-docsigna-blue-dark block w-full max-w-full">
              Name{readOnly ? null : <span className="text-red-500">*</span>}
            </label>
            {!readOnly ? (
              <div className="block w-full max-w-full">
                <SelectWithSearch
                  value={inp.value as string}
                  onSearcSelect={newalue => {
                    setselectSearch(newalue);
                  }}
                  onSelect={newValue => {
                    handleChange(inp.id, newValue.serviceId);
                  }}
                  options={newServices?.filter(o => o.name.toLowerCase().includes(selectsearch.toLowerCase())) || []}
                  label={inp.title}
                  required={inp.required}
                  placeholder={"Select Service"}
                  isSearchBox={true}
                  searchValue={selectsearch}
                  renderOption={option => {
                    return option.name;
                  }}
                  renderValue={option => {
                    return newServices?.find(o => o.serviceId === option)?.name || requestData?.service?.name;
                  }}
                  onSearchChange={(e, newValue) => {
                    setselectSearch(newValue);
                  }}
                />
                {!inp.valid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMessage}</p> : null}
              </div>
            ) : (
              <div className="block w-full max-w-full">{requestData.service?.name}</div>
            )}
          </div>
        );

      case "servicefee":
        return (
          <div key={inp.id} className="mt-3 flex items-center">
            {readOnly ? (
              <>
                <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full">Cost</label>
                <div className="block w-full max-w-full">{readOnlyServicePrice}</div>
              </>
            ) : (
              <TextInput
                value={inp.value as string}
                disabled={inp?.id === "price"}
                onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
                placeholder={inp.placeholder}
                errorMsg={inp.errorMessage}
                isError={!inp.valid && errorInitiated}
                label={inp.title}
                isRequired={inp.required}
                onBlur={() => handleBlur(inp.id)}
                extraInputClass="text-md"
                isDarkBg={true}
              />
            )}
          </div>
        );

      case "select":
        return (
          <div className="py-2 " key={inp.id}>
            <select
              onChange={e => handleChange(inp.id, e.currentTarget.value)}
              value={inp.value as string}
              className="block w-full mt-1 text-md rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              {requestData?.service ? null : <option>Select Service</option>}
              {newServices?.map(ser => {
                return (
                  <option value={ser.serviceId} key={ser.serviceId}>
                    {ser.name}
                  </option>
                );
              })}
            </select>

            {!inp.valid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMessage}</p> : null}
          </div>
        );
      default:
        return null;
    }
  };

  const validateForm = () => {
    return formData.every(val => val.valid) && !serviceNotesError && !payeeError && serviceOption !== "none";
  };

  const saveService = async () => {
    setErrorInitiated(true);
    const serviceId = formData.find(f => f.id === "serviceName")?.value;
    if (id && requestData?.requestId && serviceId) {
      setIsLoading(true);
      const price = (formData.find(f => f.id === "price")?.value as string).replace(AmountReplaceRegex, "");
      let billedToSent: ServiceBilledTo;
      if (tempRequestData?.requestType === RequestType.QuickFormOffice || tempRequestData?.requestType === RequestType.PrescriptionRefill) {
        billedToSent = ServiceBilledTo.Patient;
      } else {
        billedToSent = serviceOption as ServiceBilledTo;
      }
      try {
        const oldRequestData = createUpdateRequestObject(requestData);
        const resp = await dispatch(
          updateRequest({
            request: {
              ...oldRequestData,
              discountAmount: tempRequestData?.discountAmount,
              discountType: tempRequestData?.discountType,
              serviceId: tempRequestData?.service?.serviceId,
              serviceBilledToOverride: billedToSent,
              servicePriceOverride: +price * 100,
              serviceNotes: serviceNotesValue && serviceNotesValue.length ? serviceNotesValue.trim() : requestData?.serviceNotes,
              fees: tempRequestData?.additionalFees,
            },
            requestId: requestData.requestId,
            officeId: requestData.officeId,
          })
        );
        setIsLoading(false);
        if (resp !== null) {
          dispatch(
            getOfficeRequest({
              requestId: requestData.requestId,
              officeId: requestData.officeId,
            })
          );
          await dispatch(setRequestSaved(true));
          onClickCancel();
          toast?.openToast("Service assigned successfully!", 2000, ToastVariants.Success);
        } else {
          toast?.openToast("Service assignment failed!");
        }
      } catch (_) {
        setIsLoading(false);
        toast?.openToast("Something went wrong please try again!", 2000, ToastVariants.Error);
      }
    }
  };

  const initialServiceFee =
    tempRequestData?.servicePriceOverride === 0
      ? 0
      : (tempRequestData?.servicePriceOverride && tempRequestData?.servicePriceOverride) ||
        (tempRequestData?.service?.price && tempRequestData?.service?.price) ||
        0;

  const debounced = useDebouncedCallback(value => {
    if (tempRequestData) {
      const discountType = tempRequestData?.discountType ? tempRequestData?.discountType : RequestDiscountType.Percentage;
      setTempRequestData({ ...tempRequestData, discountAmount: value, discountType });
    }
  }, 750);

  const handleDiscount = (type: "$" | "%" | "input", value: string | boolean, price?: number) => {
    if (!tempRequestData) return;
    const temp = { ...tempRequestData };
    if (temp?.discountType === undefined) {
      temp.discountType = RequestDiscountType.Percentage;
    }
    switch (type) {
      case "$":
      case "%":
        temp.discountType = type === "$" ? RequestDiscountType.Amount : RequestDiscountType.Percentage;
        delete temp.discountAmount;
        setDiscountValue("");
        setDiscountError(false);
        setTempRequestData(temp);
        break;
      case "input":
        switch (temp.discountType) {
          case RequestDiscountType.Amount:
            const amount = formatCurrency(value as string, "");
            setDiscountValue(amount);
            const amnt = +amount.replace(AmountReplaceRegex, "") * 100;
            if (amnt > initialServiceFee) {
              setDiscountError(true);
            } else {
              setDiscountError(false);
            }
            debounced(parseFloat(amnt.toFixed(2)));
            break;
          case RequestDiscountType.Percentage:
            if (temp.discountType === RequestDiscountType.Percentage) {
              const discount = +parseInt((value as string).replace(DiscountValueRegex, "")).toFixed(2) || 0;
              if (discount <= 100) {
                setDiscountValue(discount ? (value as string).replace(DiscountValueRegex, "") : "");
                debounced(discount);
              }
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleAddAdditionalSideBar = () => {
    setAddSideBarOpen(true);
  };

  const setAdditionalFees = (data?: UpdateFee[]) => {
    if (tempRequestData) setTempRequestData({ ...tempRequestData, additionalFees: data });
  };

  useEffect(() => {
    if (id)
      dispatch(
        searchServiceData({
          officeId: id,
          page: 0,
          sort: "name",
          size: 200,
          enabledOnly: true,
          serviceOwner: ServiceOwner.Office,
        })
      );
  }, []);

  useEffect(() => {
    if (requestData)
      setTempRequestData({
        ...requestData,
        additionalFees: requestData?.requestFees?.map(item => {
          return { feeId: item?.fee?.feeId ?? "", requestId: item.requestId, quantity: item.quantity };
        }),
      });
    const discountVal: number = requestData?.discountAmount ?? 0;
    let discount = "";
    if (discountVal) {
      discount =
        requestData?.discountType === RequestDiscountType.Amount ? formatCurrency((discountVal / 100).toString(), "blur") : discountVal.toString();
    }
    setDiscountValue(discount);
  }, [requestData]);

  useEffect(() => {
    if (invoiceObject) {
      setPriceDetails(invoiceObject);
    }
  }, [invoiceObject]);

  useEffect(() => {
    const getPrice = async () => {
      if (
        tempRequestData?.officeId &&
        tempRequestData?.service?.serviceId &&
        tempRequestData?.servicePriceOverride &&
        tempRequestData?.serviceBilledToOverride
      ) {
        const body: UpdateRequest = {
          requestType: tempRequestData.requestType,
          requestId: tempRequestData.requestId,
          officeId: tempRequestData.officeId,
          firstName: tempRequestData.firstName,
          lastName: tempRequestData.lastName,
          patientId: tempRequestData?.patient?.userId,
          serviceId: tempRequestData?.service?.serviceId,
          servicePriceOverride: tempRequestData?.servicePriceOverride,
          serviceBilledToOverride: tempRequestData?.serviceBilledToOverride,
          discountType: tempRequestData?.discountType ?? undefined,
          discountAmount: tempRequestData?.discountAmount ?? undefined,
          fees: tempRequestData?.additionalFees,
        };
        const resp = await OfficeService.getPriceReview(tempRequestData?.officeId, body);
        if (resp) {
          setPriceDetails(resp as any);
          if (resp?.totalAmount > 0 && resp?.totalAmount / 100 < 1) {
            toast?.openToast("Minimum transaction amount is $1", 2000, ToastVariants.Error);
          }
        } else setPriceDetails(undefined);
      }
    };
    getPrice();
  }, [
    tempRequestData?.officeId,
    tempRequestData?.service?.serviceId,
    tempRequestData?.servicePriceOverride,
    tempRequestData?.serviceBilledToOverride,
    tempRequestData?.discountAmount,
    tempRequestData?.discountType,
    tempRequestData?.additionalFees,
  ]);

  return (
    <>
      {readOnly ? null : <p className="text-sm px-8 pb-5 font-medium">Select the appropriate service and fees below.</p>}
      <div className="px-8 py-2  items-center">{formData.map(input => renderInput(input))}</div>
      <div className="px-8 py-2  items-center">
        <div className="flex items-center justify-between">
          <label className="text-sm font-medium text-docsigna-blue-dark block ">
            Billed To
            {readOnly ? null : <span className="text-red-500">*</span>}
          </label>
          {readOnly ? (
            <div className="block w-1/2">
              {(requestData?.serviceBilledToOverride ? billedToOptions[requestData?.serviceBilledToOverride] : "To be confirmed") ||
                (requestData?.service?.serviceBilledTo ? billedToOptions[requestData?.service?.serviceBilledTo] : "To be confirmed")}
            </div>
          ) : !isThirdParty ? (
            <div className="block w-1/2">
              <select
                disabled={readOnly}
                onChange={e => {
                  handleServiceSelect(e.currentTarget.value);
                  handleChange("payee", e.currentTarget.value);
                }}
                value={serviceOption}
                className="general-select bg-docsigna-pink-light w-full">
                <option value="none" hidden>
                  Select billed to
                </option>
                {serviceBilled?.map(ser => {
                  return (
                    <option value={ser.id} key={ser.id}>
                      {ser.name}
                    </option>
                  );
                })}
              </select>
              {payeeError && errorInitiated ? <p style={{ color: colors.Red }}>Select Payee</p> : null}
            </div>
          ) : (
            <div className="block w-1/2">
              {requestData?.requestType === RequestType.PrescriptionRefill
                ? "Patient"
                : requestData?.serviceBilledToOverride
                ? billedToOptions[requestData?.serviceBilledToOverride]
                : ""}
            </div>
          )}
        </div>
      </div>
      <div className="px-8 py-2">
        {readOnly ? (
          <div>
            {requestData?.serviceNotes?.length ? (
              <>
                <span className="text-sm text-gray-700 block w-full max-w-full">Notes</span>
                <div>{serviceNotesValue}</div>
              </>
            ) : null}
          </div>
        ) : (
          <TextInput
            value={serviceNotesValue}
            disabled={readOnly}
            onChangeFunc={e => handleChange("serviceNotes", e.currentTarget.value)}
            placeholder={"Service Notes"}
            errorMsg={"Maximum 150 characters"}
            isError={serviceNotesError && errorInitiated}
            label={"Service Notes"}
            isRequired={false}
            onBlur={e => {
              handleBlur("serviceNotes");
              handleChange("serviceNotes", e.currentTarget.value.trim());
            }}
            isTextArea={true}
            showCounter={true}
            counterMessage={"150"}
            extraInputClass="max-h-36"
            isDarkBg={true}
          />
        )}
      </div>
      {readOnly ? null : (
        <>
          {" "}
          <div className="px-8 py-2">
            <span className="text-sm font-medium text-docsigna-blue-dark block w-full max-w-full">Discount</span>
            <div className="flex flex-row mt-3">
              <div className="w-1/2">
                <TextInput
                  disabled={readOnly}
                  onChangeFunc={e => handleDiscount("input", e.currentTarget.value, tempRequestData?.service?.price)}
                  value={discountValue}
                  placeholder="Enter discount"
                  isDarkBg={true}
                />
              </div>
              <div className="w-1/2 flex flx-row px-8 items-center">
                <label className="block w-full mb-1 relative">
                  <input
                    type={"radio"}
                    checked={
                      tempRequestData?.discountType === undefined || tempRequestData?.discountType === RequestDiscountType.Percentage ? true : false
                    }
                    disabled={readOnly}
                    onChange={e => handleDiscount("%", e.currentTarget.checked)}
                    className="general-radio"
                  />
                  <span className="text-base ml-2">{"%"}</span>
                </label>
                <label className="block w-full mb-1 relative">
                  <input
                    type={"radio"}
                    checked={tempRequestData?.discountType === RequestDiscountType.Amount}
                    disabled={readOnly}
                    onChange={e => handleDiscount("$", e.currentTarget.checked)}
                    className="general-radio"
                  />
                  <span className="text-base ml-2">{"$"}</span>
                </label>
              </div>
            </div>
            {discountError ? <span className="text-red-500">Invalid discount amount</span> : null}
          </div>
        </>
      )}
      <hr className="my-2" />
      <div className="px-8 py-2">
        <span className="font-medium ">Fees</span>
      </div>
      {priceDetails?.fees?.length || !readOnly ? (
        <>
          {priceDetails?.fees?.map((ele: InvoiceItem) => {
            return (
              <div key={ele.description} className="w-full flex justify-between px-8 py-2">
                {<div className="text-sm  font-small col-span-2">{ele.description}</div>}
                <div className="text-sm  font-small col-span-2">{formatCurrency((ele.amount / 100).toString(), "blue")}</div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="px-8 py-2">No additional fees</div>
      )}

      {!readOnly ? (
        <div className="px-8 py-2">
          <button onClick={handleAddAdditionalSideBar} className="text-sm text-docsigna-blue-light">
            {requestData?.requestFees?.length ? "Add/Edit Fee" : `Add New Fee`}
          </button>
        </div>
      ) : null}
      <hr className="my-2" />

      <div className="px-8 py-2">
        <span className="font-medium ">Service Cost</span>
        <div className="w-full flex justify-between mt-3">
          <div className="text-md font-small col-span-2">Service</div>
          <div className="text-md font-medium col-span-2">{formatCurrency(servicePrice.toString(), "blur")}</div>
        </div>
        <hr className="my-2" />
        {dscValue > 0 ? (
          <>
            <div className="w-full flex justify-between mt-3">
              <div className="text-md font-bold col-span-2">{dscDesc}</div>
              <div className="text-md font-bold col-span-2">{formatCurrency(dscValue.toString(), "blur")}</div>
            </div>
            <hr className="my-2" />
          </>
        ) : null}
        {blockFeeDiscountAmount > 0 ? (
          <>
            <div className="w-full flex justify-between mt-3">
              <div className="text-md font-bold col-span-2">Block Fee: {blockFeeDiscountDesc}</div>
              <div className="text-md font-bold col-span-2">{formatCurrency(blockFeeDiscountAmount.toString(), "blur")}</div>
            </div>
            <hr className="my-2" />
          </>
        ) : null}
        {additionalFeeTotal > 0 ? (
          <>
            <div className="w-full flex justify-between mt-3">
              <div className="text-md font-small col-span-2">Additional Fees</div>
              <div className="text-md font-medium">{formatCurrency(additionalFeeTotal.toString(), "blur")}</div>
            </div>
            <hr className="my-2" />
          </>
        ) : null}
        <div className="w-full flex justify-between">
          <div className="text-md font-small col-span-2">Subtotal</div>
          <div className="text-md font-medium">{formatCurrency(subTotal.toString(), "blur")}</div>
        </div>
        <hr className="my-2" />
        {dsFee > 0 ? (
          <>
            <div className="w-full flex justify-between">
              <div className="text-md font-small col-span-2">{dsFeeDesc}</div>
              <div className="text-md font-small col-span-2">{formatCurrency(dsFee.toString(), "blur")}</div>
            </div>
            <hr className="my-2" />
          </>
        ) : null}
        {hstValue > 0 ? (
          <>
            <div className="w-full flex justify-between">
              <div className="text-md font-small col-span-2">{hstDesc ? `${hstDesc} (${hstPercentage ? `${hstPercentage}%` : ""})` : "HST"}</div>
              <div className="text-md font-small col-span-2">{formatCurrency(hstValue.toString(), "blur")}</div>
            </div>
            <hr className="my-2" />
          </>
        ) : null}
        <div className="w-full flex justify-between">
          <div className="text-md font-small font-bold col-span-2">Total </div>
          <div className="text-md font-small font-bold col-span-2">{formatCurrency(total.toString(), "blur")}</div>
        </div>
        <hr className="my-2" />
      </div>

      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 bg-docsigna-pink-light">
        <div className={`px-8 py-5 flex justify-end`}>
          <div className="flex">
            <Button
              onClickFunc={() => onClickCancel()}
              AdditionalClassNames="pointer px-5 mr-3"
              text="Cancel"
              width="fit"
              varient="Secondary"
              disabled={isLoading}
            />
            <div>
              {!readOnly ? (
                <Button
                  onClickFunc={() => {
                    if (validateForm() && !discountError) {
                      saveService();
                    }
                  }}
                  disabled={
                    ((!validateForm() || serviceNotesError || payeeError) && errorInitiated) || isLoading || discountError || (total > 0 && total < 1)
                  }
                  AdditionalClassNames="pointer px-5"
                  text="Save"
                  width="fit"
                />
              ) : null}
            </div>
          </div>
        </div>
        <UserSideBar
          onClickOutside={() => {
            setAddSideBarOpen(false);
          }}
          title="Add Fees"
          visible={addSideBarOpen}>
          <AddEditFee
            onCancelClick={(feeData?: UpdateFee[]) => {
              setAddSideBarOpen(false);
              setAdditionalFees(feeData);
            }}
            officeId={id ? id : null}
            requestId={requestData ? requestData.requestId : null}
            addedFee={tempRequestData?.additionalFees}
          />
        </UserSideBar>
      </div>
    </>
  );
};

export default AssignService;
