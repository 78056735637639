import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPaymentSucceded } from "../../../redux/slices/OfficeRequestSlice";
import { createPaymentIntent, resetPaymentSlice, setPaymentIntent } from "../../../redux/slices/PaymentSlice";
import { setOpenPaymentSidebar } from "../../../redux/slices/RequestSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { formatCurrency, priceReview } from "../../../utils";
import { colors, SESSION_STORAGE_PAYMENT_INTENT } from "../../../utils/constants";
import PaymentComponent from "../../PaymentComponent/PaymentComponent";
import CircularProgress from "@mui/material/CircularProgress";

const stripePromise = process.env.REACT_APP_STRAPI_PUBLISHABLE_KEY ? loadStripe(process.env.REACT_APP_STRAPI_PUBLISHABLE_KEY) : null;

const PaymentForm = ({
  isPatient = false,
  officeTaxEnabled,
  isOffice = false,
  setIsLoading,
}: {
  isPatient?: boolean;
  officeTaxEnabled?: boolean;
  isOffice?: boolean;
  setIsLoading?: (val: boolean) => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { RequestData } = useSelector((state: RootState) => state.onBoarding);
  const { requestData: officeRequestData } = useSelector((state: RootState) => state.officeRequest);
  const { patientRequestData } = useSelector((state: RootState) => state.user);
  const { invoiceObject } = useSelector((state: RootState) => state.officeRequest);
  const { invoiceObjectOnboarding } = useSelector((state: RootState) => state.onBoarding);
  const currentRequestData = isOffice ? officeRequestData : isPatient ? patientRequestData : RequestData;
  const paymentDetails = isPatient || isOffice ? invoiceObject : invoiceObjectOnboarding;
  const { paymentIntent, cancelled, getIntentError, error } = useSelector((state: RootState) => state.payment);
  const requestFee = currentRequestData?.requestFees ? currentRequestData?.requestFees : [];
  const {
    servicePrice,
    dscDesc,
    dscValue,
    blockFeeDiscountDesc,
    blockFeeDiscountAmount,
    additionalFeeTotal,
    subTotal,
    dsFee,
    dsFeeDesc,
    hstValue,
    hstPercentage,
    hstDesc,
    total,
  } = priceReview(paymentDetails ? paymentDetails : undefined);

  const createPayment = () => {
    if (isOffice && officeRequestData?.requestId) dispatch(createPaymentIntent({ requestId: officeRequestData?.requestId }));
    if (isPatient && patientRequestData?.requestId) dispatch(createPaymentIntent({ requestId: patientRequestData?.requestId }));
    if (RequestData?.requestId) dispatch(createPaymentIntent({ requestId: RequestData?.requestId }));
  };

  const handleCancel = () => {
    dispatch(setOpenPaymentSidebar(false));
  };

  useEffect(() => {
    dispatch(setPaymentSucceded(false));

    dispatch(resetPaymentSlice());
    const paymentIntentKey = sessionStorage.getItem(SESSION_STORAGE_PAYMENT_INTENT);
    if (paymentIntentKey !== null) {
      const savedPaymentIntent = JSON.parse(paymentIntentKey);
      if (savedPaymentIntent.amount === total) {
        dispatch(setPaymentIntent(savedPaymentIntent));
      } else {
        sessionStorage.removeItem(SESSION_STORAGE_PAYMENT_INTENT);
        createPayment();
      }
    } else {
      createPayment();
    }
  }, []);

  useEffect(() => {
    if (paymentIntent) sessionStorage.setItem(SESSION_STORAGE_PAYMENT_INTENT, JSON.stringify(paymentIntent));
  }, [paymentIntent]);

  useEffect(() => {
    if (getIntentError) {
      dispatch(resetPaymentSlice());
      createPayment();
    }
  }, [getIntentError]);
  return (
    <div className="inline-block w-full h-scrollable-screen overflow-auto">
      <div className="flex justify-between">
        <p className="text-md px-8 pt-5 mb-2 font-semibold">Service</p>
        <p className="text-md px-8 pt-5 mb-2 font-semibold">{formatCurrency(servicePrice.toString(), "blur")}</p>
      </div>
      {dscValue > 0 ? (
        <div className="flex justify-between">
          <p className="text-md px-8 mb-2 font-bold">{dscDesc}</p>
          <p className="text-md px-8 mb-2 font-bold">{formatCurrency(dscValue.toString(), "blur")}</p>
        </div>
      ) : null}
      {blockFeeDiscountAmount > 0 ? (
        <div className="flex justify-between">
          <p className="text-md px-8 mb-2 font-bold">Block Fee: {blockFeeDiscountDesc}</p>
          <p className="text-md px-8 mb-2 font-bold">{formatCurrency(blockFeeDiscountAmount.toString(), "blur")}</p>
        </div>
      ) : null}
      {additionalFeeTotal > 0 ? (
        <div className="flex justify-between">
          <p className="text-md px-8 mb-2 font-semibold">Additional Fees </p>
          <p className="text-md px-8 mb-2 font-semibold">{formatCurrency(additionalFeeTotal.toString(), "blur")}</p>
        </div>
      ) : null}
      {requestFee.length > 0 ? (
        <div className="px-8 mb-2">
          {requestFee.map((ele: any, index: number) => {
            return (
              <div key={index} className="w-full flex justify-between py-2 border-b">
                <div className="text-md">
                  {ele.fee.name} ({ele.quantity} units @ {(ele.fee.price / 100).toFixed(2)}/unit)
                </div>
                <div className="text-md">${((ele.fee.price / 100) * ele.quantity).toFixed(2)}</div>
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="flex justify-between">
        <p className="text-md px-8 mb-2 font-semibold">Subtotal</p>
        <p className="text-md px-8 mb-2 font-semibold">{formatCurrency(subTotal.toString(), "blur")}</p>
      </div>
      {dsFee > 0 ? (
        <div className="flex justify-between">
          <p className="text-md px-8 mb-2 font-semibold">{dsFeeDesc}</p>
          <p className="text-md px-8 mb-2 font-semibold">{formatCurrency(dsFee.toString(), "blur")}</p>
        </div>
      ) : null}
      {hstValue > 0 ? (
        <div className="flex justify-between">
          <p className="text-md px-8 mb-2 font-semibold">{hstDesc ? `${hstDesc} (${hstPercentage ? `${hstPercentage}%` : ""})` : "HST"}</p>
          <p className="text-md px-8 mb-2 font-semibold">{formatCurrency(hstValue.toString(), "blur")}</p>
        </div>
      ) : null}
      <div className="flex justify-between">
        <p className="text-md font-semibold px-8 mb-3">Total Fee</p>
        <p className="text-md font-semibold px-8 mb-3">{formatCurrency(total.toString(), "blur")}</p>
      </div>

      <div className="flex flex-col px-4 py-2 ">
        <div className="w-full ">{cancelled ? <p>Payment has been cancelled.</p> : null}</div>
        <div className="w-full px-4 ">{error ? <p style={{ color: colors.Red }}>An error occurred, please try again.</p> : null}</div>
        <div className="w-full px-4 ">
          {paymentIntent !== null && !cancelled ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: paymentIntent.clientSecret,
                appearance: {
                  theme: "stripe",
                  variables: {
                    spacingGridRow: "0",
                    spacingGridColumn: "0",
                    spacingUnit: "0",
                    borderRadius: "0",
                  },
                  rules: {
                    ".Grid": {
                      marginBottom: "0px",
                    },
                    ".Label": {
                      fontSize: "0px",
                      lineHeight: "0px",
                      marginBottom: "0px",
                    },
                    ".p-CardNumberInput-input": {
                      borderTop: "1px",
                      borderTopLeftRadius: "8px",
                    },
                    "#Field-numberInput": {
                      backgroundColor: "red",
                    },
                    ".Input": {
                      color: colors.DarkBlue,
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "10px 0 0 0",
                      backgroundColor: "transparent",
                      outline: "none",
                      boxShadow: "none",
                      borderColor: colors.DarkBlue,
                    },
                    ".Input:focus": {
                      outline: "none",
                      boxShadow: "none",
                    },
                    ".Error": {
                      color: "red",
                    },
                    "p-BillingAddressForm": {
                      marginTop: "20px",
                    },
                  },
                },
              }}>
              {currentRequestData ? (
                <PaymentComponent
                  paymentIntent={paymentIntent}
                  onCancelClick={handleCancel}
                  paymentRequestData={currentRequestData}
                  isOffice={isOffice}
                  setIsLoading={setIsLoading}
                />
              ) : null}
            </Elements>
          ) : (
            <div className="px-[50%] mt-20">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
