import { ApiPage, emptyApiPage } from "../models/api-page.model";
import { Finance, FinanceRequest } from "../models/finance.model";
import { apiService } from "./api.service";

export class FinanceService {
  private static BASE_PATH = "/api/v1/finance";

  static async getFinanceReport(startDate: Date, endDate?: Date, officeId?: string): Promise<Finance | null> {
    const response = await apiService.post(`${this.BASE_PATH}/search`, {
      startDate: startDate.toISOString(),
      endDate: endDate?.toISOString(),
      officeId: officeId ? officeId : undefined
    });
    if (!response.success) return null;

    return response.data as Finance;
  }

  static async getFinanceRequests(startDate: Date, endDate:Date, page = 0, size = 10, sort ='request.lastName', officeId?: string): Promise<ApiPage<FinanceRequest>> {
    const body = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      officeId: officeId ? officeId : undefined
    }
    const response = await apiService.post(`${this.BASE_PATH}/requests?page=${page}&size=${size}&sort=${sort}`, body);
    if (!response.success) return emptyApiPage<FinanceRequest>();

    return response.data as ApiPage<FinanceRequest>;
  }
}
