export type ApiPage<T> = {
  content: T[];
  totalElements: number;
  totalPages: number;
  number: number; // Current page index starting from 0
  size: number; // Number of items per page
  first: boolean;
  last: boolean;
  empty: boolean;
};

export const emptyApiPage = <T>(): ApiPage<T> => {
  return {
    content: [],
    totalElements: 0,
    totalPages: 0,
    number: 0,
    size: 0,
    first: true,
    last: true,
    empty: true,
  };
};
