import { ApiPatchOp, apiService } from "./api.service";
import { Request, RequestPaymentMethod, RequestState } from "../models/request.model";
import { RequestFile } from "../models/request-file.model";
import { RequestMessage } from "../models/request-message.model";
import { Timeline } from "../models/timeline.model";
import { Office } from "../models/office.model";
import { PriceDetails } from "../models/price-details.model";
import { RequestFileTypes } from "../models/ds-file.model";

export interface GuestToken {
  requestId: string;
  guestToken: string;
}
export class GuestService {
  private static BASE_PATH = "/api/v1/guest";
  private static FORM_PATH = "/api/v1/website-contact";

  static async sendEmailVerificationCode(email: string, firstName?: string, lastName?: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/verify-email`, {
      firstName,
      lastName,
      email,
    });
    if (!response.success) return false;

    return true;
  }

  static async verifyEmailCode(email: string, code: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/verify-email-confirm`, {
      email: email,
      code: code,
    });
    if (!response.success) return false;

    return true;
  }

  static async sendPhoneVerificationCode(phone: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/verify-phone`, {
      phone: phone,
    });
    if (!response.success) return false;

    return true;
  }

  static async verifyPhoneCode(phone: string, code: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/verify-phone-confirm`, {
      phone: phone,
      code: code,
    });
    if (!response.success) return false;

    return true;
  }

  static async guestTokenInitiate(email: string, requestNumber: string): Promise<string | null> {
    const response = await apiService.post(`${this.BASE_PATH}/token-request`, {
      email: email,
      requestNumber: requestNumber,
    });
    if (!response.success) return null;

    return response.data.phone;
  }

  static async guestTokenVerify(email: string, requestNumber: string, code: string): Promise<GuestToken | null> {
    const response = await apiService.post(`${this.BASE_PATH}/token-request-verify`, {
      email: email,
      requestNumber: requestNumber,
      code: code,
    });
    if (!response.success) return null;

    return response.data as GuestToken;
  }

  static async getRequest(guestToken: GuestToken): Promise<Request | null> {
    const response = await apiService.get(`${this.BASE_PATH}/requests/${guestToken.requestId}`, false, guestToken);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async addRequestFiles(guestToken: GuestToken, fileIds: string[]): Promise<Request | null> {
    const response = await apiService.post(
      `${this.BASE_PATH}/requests/${guestToken.requestId}/files`,
      {
        fileIds: fileIds,
        requestFileType: RequestFileTypes.Document
      },
      false,
      false,
      guestToken
    );
    if (!response.success) return null;

    return response.data as Request;
  }

  static async getRequestFiles(guestToken: GuestToken): Promise<RequestFile[]> {
    const response = await apiService.get(`${this.BASE_PATH}/requests/${guestToken.requestId}/files`, false, guestToken);
    if (!response.success) return [];

    return response.data as RequestFile[];
  }

  static async addRequestMessage(guestToken: GuestToken, senderName: string, message: string): Promise<RequestMessage | null> {
    const response = await apiService.post(
      `${this.BASE_PATH}/requests/${guestToken.requestId}/messages`,
      {
        requestId: guestToken.requestId,
        senderName: senderName,
        message: message,
        internal: false,
      },
      false,
      false,
      guestToken
    );
    if (!response.success) return null;

    return response.data as RequestMessage;
  }

  static async getRequestMessages(guestToken: GuestToken): Promise<RequestMessage[]> {
    const response = await apiService.get(`${this.BASE_PATH}/requests/${guestToken.requestId}/messages`, false, guestToken);
    if (!response.success) return [];

    return response.data as RequestMessage[];
  }

  static async setRequestMessageRead(guestToken: GuestToken, messageId: string, read: boolean): Promise<RequestMessage | null> {
    const response = await apiService.patch(
      `${this.BASE_PATH}/requests/${guestToken.requestId}/messages/${messageId}`,
      [
        {
          op: ApiPatchOp.Replace,
          path: "/unread",
          value: !read,
        },
      ],
      false,
      guestToken
    );
    if (!response.success) return null;

    return response.data as RequestMessage;
  }

  static async getRequestOffice(guestToken: GuestToken): Promise<Office | null> {
    const response = await apiService.get(`${this.BASE_PATH}/requests/${guestToken.requestId}/office`, false, guestToken);
    if (!response.success) return null;

    return response.data as Office;
  }

  static async setRequestPaid(guestToken: GuestToken, paymentMethod: RequestPaymentMethod, paymentDate = new Date()): Promise<Request | null> {
    const response = await apiService.patch(
      `${this.BASE_PATH}/requests/${guestToken.requestId}`,
      [
        {
          op: ApiPatchOp.Replace,
          path: "/paymentComplete",
          value: true,
        },
        {
          op: ApiPatchOp.Replace,
          path: "/paymentMethod",
          value: {
            name: paymentMethod,
          },
        },
        {
          op: ApiPatchOp.Replace,
          path: "/paymentDate",
          value: paymentDate.toISOString(),
        },
      ],
      false,
      guestToken
    );
    if (!response.success) return null;

    return response.data as Request;
  }

  static async setRequestState(guestToken: GuestToken, state: RequestState): Promise<Request | null> {
    const response = await apiService.patch(
      `${this.BASE_PATH}/requests/${guestToken.requestId}`,
      [
        {
          op: ApiPatchOp.Replace,
          path: "/requestState",
          value: {
            name: state,
          },
        },
      ],
      false,
      guestToken
    );
    if (!response.success) return null;

    return response.data as Request;
  }

  static async setRequestAcceptedTerms(guestToken: GuestToken, acceptedTerms: boolean): Promise<Request | null> {
    const response = await apiService.patch(
      `${this.BASE_PATH}/requests/${guestToken.requestId}`,
      [
        {
          op: ApiPatchOp.Replace,
          path: "/acceptedTerms",
          value: acceptedTerms,
        },
      ],
      false,
      guestToken
    );
    if (!response.success) return null;

    return response.data as Request;
  }

  static async getPriceDetails(guestToken: GuestToken): Promise<PriceDetails | null> {
    const response = await apiService.get(`${this.BASE_PATH}/requests/${guestToken.requestId}/price-details`, false, guestToken);
    if (!response.success) return null;

    return response.data as PriceDetails;
  }

  static async addRequestTimelineEvent(event: string, requestId: string, guestToken: GuestToken): Promise<Timeline | null> {
    const response = await apiService.post(
      `${this.BASE_PATH}/requests/${requestId}/timeline`,
      {
        event: event,
      },
      false,
      false,
      guestToken
    );
    if (!response.success) return null;

    return response.data as Timeline;
  }

  static async submitWebsitePublicContactForm(name:string,
    email:string,
    phone:string,
    office:string,
    message:string,
    agreeUpdates:boolean ): Promise<boolean> {
    const response = await apiService.post(
      `${this.FORM_PATH}/public`,
      {
        name,
        email,
        phone,
        office,
        message,
        agreeUpdates
      }
    );
    if (!response.success) return false;
    return true;
  }

  // FIXME: This should be in office service, maybe as submitContactForm()
  // Or we put these both in some new contact form service
  static async submitWebsiteOfficeContactForm(name:string,
    email:string,
    phone:string,
    office:string,
    message:string,
    ): Promise<boolean> {
    const response = await apiService.post(
      `${this.FORM_PATH}/office`,
      {
        name,
        email,
        phone,
        office,
        message,
      }
    );
    if (!response.success) return false;
    return true;
  }
}
