import { useState } from "react";
import { headersType } from "../../models";
import { FinanceService } from "../../models/finance.model";
import { TableHeader, TableRow } from "../TableComponents/TableComponents";
import { formatCurrency } from "../../utils";
import { orderBy } from "lodash";

export const reportHeaders: headersType[] = [
  {
    title: "Service Name",
    extraClasses: "w-full pl-0 break-all",
    sortKey: "",
    isClickable: false,
    titleColor: "text-docsigna-pink-dark",
  },
  {
    title: "Quantity",
    extraClasses: "w-full pl-0",
    sortKey: "",
    isClickable: false,
    titleColor: "text-docsigna-pink-dark",
  },
  {
    title: "Total Revenue for time period",
    extraClasses: "w-full pl-0",
    sortKey: "",
    isClickable: false,
    titleColor: "text-docsigna-pink-dark",
  },
];

const DashboardTabTopEarningSection = ({ financeReportServices = [] }: { financeReportServices: FinanceService[] }) => {
  const [extraClasses] = useState<string[]>(reportHeaders.map(head => `${head.extraClasses} text-[15px]`));
  return (
    <div className="mt-10 mb-5">
      <div className="p-5 rounded bg-docsigna-blue-dark">
        <div>
          <span className="font-bold text-white text-lg block mb-5">Top Earning Services</span>
        </div>
        <div className="flex justify-between items-center border-b border-docsigna-pink-dark">
          {reportHeaders.map((head, index) => (
            <TableHeader
              title={head.title}
              index={index}
              key={index}
              sortIcon={null}
              handleSort={() => null}
              extraClassesHeader={head.extraClasses}
              isClickable={head.isClickable}
              titleColor={head.titleColor}
            />
          ))}
        </div>
        {orderBy(financeReportServices, "amount", "desc")
          .slice(0, 10)
          .filter(item => item.amount > 0)
          .map((item: FinanceService, index: number) => {
            const sortedData = {
              serviceName: <p className="text-sm font-medium text-docsigna-pink-light">{item.name}</p>,
              quantity: <p className="text-sm font-medium text-docsigna-pink-light">{item.count}</p>,
              total: (
                <p className="text-sm font-medium text-docsigna-pink-light">
                  {formatCurrency(item.amount ? (item.amount / 100).toString() : "0", "blur")}
                </p>
              ),
            };
            return (
              <TableRow
                data={Object.values(sortedData)}
                key={index}
                onRowClick={() => null}
                tooltipVisible={false}
                handleOpenTooltip={() => null}
                handleCloseTooltip={() => null}
                id={`${item.name}+${index}`}
                extraClassesRow={extraClasses}
              />
            );
          })}
      </div>
    </div>
  );
};

export default DashboardTabTopEarningSection;
