import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, OtpInput } from "../../components";
import { AppDispatch, RootState } from "../../redux/store";
import { colors } from "../../utils/constants";
import { UserType } from "../../services/user.service";
import { createUser } from "../../redux/slices/UserSlice";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { clearSessionStorage, verifyEmailCode } from "../../redux/slices/OnBoardingSlice";
import { FormType } from "../../models";

const VerifyEmail = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const requestFormData = useSelector((state: RootState) => state.onBoarding.requestFormData);
  const email = requestFormData[2].value;
  const officeId = useSelector((state: RootState) => state.onBoarding.selectedOffice?.officeId);
  const [error, setError] = useState("");
  const [sendingOTP, setSendingOTP] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const onContinueClick = async () => {
    try {
      setError("");
      setSendingOTP(true);
      if (officeId) {
        const formType = localStorage.getItem("formType");
        if (formType === FormType.CreateAccount) {
          const user = {
            officeId: officeId,
            firstName: requestFormData[0].value,
            lastName: requestFormData[1].value,
            email: requestFormData[2].value,
            dateOfBirth: requestFormData[3].value,
            phone: requestFormData[4].value,
            password: requestFormData[6].value,
            userType: UserType.Patient,
            code: otpInput,
            ohipNumber: requestFormData[5].value,
            acceptedTerms: requestFormData[13].value === "true" ? true : false,
          };
          const res = await dispatch(createUser(user));
          if (res.payload) {
            if (typeof res.payload === "string") {
              if (res.payload === "Invalid code") {
                setError("Invalid code, please try again");
              } else {
                setError(res.payload);
              }
            } else {
              // login here
              const Login = await auth?.signin({
                email: user.email.trim(),
                password: user.password.trim(),
              });
              if (Login) {
                dispatch(clearSessionStorage());
                localStorage.removeItem("formType");
                navigate(`/patients/${res.payload.patientId}/dashboard`);
              }
            }
          } else {
            setError("Something went wrong. Try again!");
          }
          setSendingOTP(false);
        } else {
          dispatch(verifyEmailCode({ email: email, code: otpInput })).then(action => {
            setSendingOTP(false);
            if (action.payload) {
              navigate("/onboarding/submit-request");
            } else {
              setError("Invalid code, please try again");
            }
          });
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (otpInput.length === 6) {
      onContinueClick();
    } else {
      setError("");
    }
  }, [otpInput]);

  return (
    <>
      <div className="flex justify-between w-full">
        <div className="w-full max-w-2xl mx-auto py-2 my-12 px-10 md:px-0">
          <h3 className="text-3xl font-light mb-2">Email Verification</h3>
          <p className="text-base mb-5">You have received a 6-digit code to the email {email}. Enter it below to verify your account.</p>
          <OtpInput length={6} onChange={e => setOtpInput(e)} />
          {error ? <p style={{ color: colors.Red }}>{error}</p> : null}

          <div className="tab-content py-5 w-80">
            <Button
              text="Continue"
              width="full"
              disabled={otpInput.length !== 6}
              onClickFunc={() => onContinueClick()}
              varient={sendingOTP ? "loading" : "Primary"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
