import React from "react";
import ProgressBar from "../../components/ProgressBar";
import OfficeFinanceAccountReceivables from "../../components/Finance/OfficeFinanceAccountReceivables";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency, generateBarGraphYAxisValues } from "../../utils";
import { ServiceBilledTo, ServiceBilledToName } from "../../models/service.model";
import { getOfficeAccountReceivables } from "../../redux/slices/FinanceSlice";
import DountGraph from "../../components/DonutGraph/DonutGraph";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NotFound from "../../assets/images/no-financial.png";

const minRange = 250;
const maxRange = 1500;

const AccountReceivable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const { accountReceivablesTotals, accountReceivablesPayee, arSort } = useSelector((state: RootState) => state.finance);
  const loadMoreData = (isPagination: boolean, sortBy?: string) => {
    if (currentOffice?.officeId) {
      dispatch(
        getOfficeAccountReceivables({ officeId: currentOffice?.officeId, page: isPagination ? arSort.pageNumber + 1 : arSort.pageNumber, sortBy })
      );
    }
  };
  const values = accountReceivablesPayee.map(item => item.amount); // Sample values, adjust as needed
  const maxValue = values?.length ? Math.max(...values) : 0;
  const maxY = Math.ceil(maxValue / minRange) * minRange;
  const barGraphPointers = generateBarGraphYAxisValues(minRange, maxRange, 6);

  const scaleValue = (value: number) => {
    const normalizedValue = (value / maxY) * (maxRange - minRange) + minRange;
    return Math.round(Math.min(Math.max(normalizedValue, minRange), maxRange)); // Ensure within range
  };

  const graphData = accountReceivablesPayee?.length
    ? accountReceivablesPayee.map(item => {
        return {
          billedTo: item.name,
          count: item.count,
          amount: item.amount,
        };
      })
    : [];
  const noDataFound = !(
    accountReceivablesTotals?.outstanding30DaysPastDue ||
    accountReceivablesTotals?.outstanding60DaysPastDue ||
    accountReceivablesTotals?.outstanding90DaysPastDue ||
    accountReceivablesTotals?.outstanding120DaysPastDue ||
    accountReceivablesTotals?.outstandingTotalAccountsReceivable
  );

  if (noDataFound) return <DataNotFound image={NotFound} text="No financial records found" />;

  return (
    <React.Fragment>
      {/* Header Start */}
      <div className="px-6 py-5 bg-docsigna-pink-light mb-10 rounded-b-xl rounded-tr-xl">
        <h2 className="text-xl font-semibold text-docsigna-purple heading mb-5 uppercase">Accounts receivable</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-2">
          <div>
            <div className="flex justify-between items-center border py-1 border-x-0 border-t-0 border-dotted border-docsigna-purple">
              <h3 className="text-lg font-medium text-docsigna-purple">30+ Days</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {accountReceivablesTotals?.outstanding30DaysPastDue
                  ? formatCurrency((accountReceivablesTotals?.outstanding30DaysPastDue / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
            <div className="flex justify-between items-center border py-1 border-x-0 border-t-0 border-dotted border-docsigna-purple">
              <h3 className="text-lg font-medium text-docsigna-purple">60+ Days</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {accountReceivablesTotals?.outstanding60DaysPastDue
                  ? formatCurrency((accountReceivablesTotals?.outstanding60DaysPastDue / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
            <div className="flex justify-between items-center py-1">
              <h3 className="text-lg font-medium text-docsigna-purple">90+ Days</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {accountReceivablesTotals?.outstanding90DaysPastDue
                  ? formatCurrency((accountReceivablesTotals?.outstanding90DaysPastDue / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
          </div>
          <div>
            <div className="flex justify-between items-center border py-1 border-x-0 border-t-0 border-dotted border-docsigna-purple">
              <h3 className="text-lg font-medium text-docsigna-purple">120+ Days</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {accountReceivablesTotals?.outstanding120DaysPastDue
                  ? formatCurrency((accountReceivablesTotals?.outstanding120DaysPastDue / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
            <div className="flex justify-between items-center py-1">
              <h3 className="text-lg font-medium text-docsigna-purple">Total Accounts Receivable</h3>
              <h3 className="text-lg font-medium text-docsigna-purple">
                {accountReceivablesTotals?.outstandingTotalAccountsReceivable
                  ? formatCurrency((accountReceivablesTotals?.outstandingTotalAccountsReceivable / 100)?.toString(), "blur")
                  : "$0.00"}
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* Header End */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-2">
        {/* Category Left Part Start */}
        <div className="p-5 bg-docsigna-pink-light rounded-xl flex flex-col gap-6">
          <h2 className="text-lg font-semibold text-docsigna-purple heading mb-3 uppercase text-center">Accounts receivable by Payee</h2>
          {accountReceivablesPayee.map((item, index) => {
            const val = scaleValue(item.amount);
            return (
              <div key={index} className="flex gap-10 items-center">
                <h3 className="text-base font-medium text-docsigna-purple w-[200px]">{ServiceBilledToName[item.name as ServiceBilledTo]}</h3>
                <div className="w-full group relative cursor-pointer">
                  <ProgressBar progress={Math.round((item.amount / maxY) * 100)} />
                  <p className="absolute left-0 right-0 top-0 z-[10000] text-docnote-orange font-bold invisible group-hover:visible flex justify-center items-center h-full">
                    {val > 260 ? val : 0}
                  </p>
                </div>
              </div>
            );
          })}
          <div className="flex gap-10 items-center">
            <div className="w-[200px]" />
            <div className="w-full">
              <ul className="flex justify-between">
                {barGraphPointers.map(val => (
                  <li key={val}>
                    <span className="block rotate-90">{val}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* Category Left Part End */}
        {/* Category Right Part Start */}
        <div className="flex gap-3 items-center">
          <div className="w-full">
            <DountGraph
              billedToList={graphData}
              graphLabel={"Total Accounts Receivable"}
              totalCount={
                accountReceivablesTotals?.outstandingTotalAccountsReceivable
                  ? formatCurrency((accountReceivablesTotals?.outstandingTotalAccountsReceivable / 100)?.toString(), "blur")
                  : "$0.00"
              }
              showLegend={false}
              location="AccountTab"
            />
          </div>
        </div>
        {/* Category Right Part End */}
      </div>

      {/* ACCOUNTS RECEIVABLE Section */}
      <OfficeFinanceAccountReceivables loadMoreData={loadMoreData} />
    </React.Fragment>
  );
};
export default AccountReceivable;
