import { ChangeEvent, useState } from "react";
import { TextInput, Button, Checkbox } from "../..";
import { formatCurrency, validateCurrency } from "../../../utils";
import { updateOfficeFee, updateSystemFee } from "../../../redux/slices/FeeSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { AmountReplaceRegex, colors } from "../../../utils/constants";
export interface EditFeeDataType {
  id: string;
  placeholder: string;
  required: boolean;
  errorMesage: string;
  errorMesage1?: string;
  label: string;
  value: string;
  isValid: boolean;
}

const EditFee = ({
  onCancelClick,
  isAdminPage,
  feeId,
  disabled,
  setIsLoading,
  isLoading,
}: {
  onCancelClick: (val?: boolean) => void;
  isAdminPage: boolean;
  feeId: string;
  disabled: boolean;
  setIsLoading: (val: boolean) => void;
  isLoading: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const fees = useSelector((state: RootState) => state.fees.feeList?.content);
  const feeToBeUpdated = fees?.find(f => f.feeId === feeId);
  const EditFeeData: EditFeeDataType[] = [
    {
      id: "feeName",
      label: "Fee Name",
      placeholder: "Name of fee",
      required: true,
      errorMesage: "Please provide a fee name",
      value: feeToBeUpdated ? feeToBeUpdated.name : "",
      isValid: feeToBeUpdated?.name ? true : false,
    },
    {
      id: "description",
      label: "Description",
      placeholder: "Description of fee (optional)",
      required: false,
      errorMesage: "Maximum 100 characters",
      value: feeToBeUpdated && feeToBeUpdated.description ? feeToBeUpdated.description : "",
      isValid: true,
    },
    {
      id: "feePrice",
      label: "Price (per unit)",
      placeholder: "$",
      required: true,
      errorMesage: "Please provide a fee price",
      value: feeToBeUpdated ? "$" + feeToBeUpdated.price / 100 : "",
      isValid: feeToBeUpdated?.price ? true : false,
    },
  ];
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const [feeFormData, setFeeFormData] = useState<EditFeeDataType[]>(EditFeeData);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [hstCheckBox, setHstCheckBox] = useState(feeToBeUpdated?.addTax || false);
  const [enableFeeCheckBox, setEnableFeeCheckBox] = useState(feeToBeUpdated?.enabled || false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState(feeToBeUpdated && feeToBeUpdated.description ? feeToBeUpdated.description : "");
  const [feeNameError, setFeeNameError] = useState(false);
  const [feeNameErrMsg, setfeeNameErMsg] = useState("");
  const [feeNameValue, setfeeNameValue] = useState(feeToBeUpdated ? feeToBeUpdated.name : "");
  const [EditFeeError, setEditFeeError] = useState(false);

  const validateField = (name: string, value: string) => {
    let valid = false;

    switch (name) {
      case "feePrice":
        valid = validateCurrency(value);
        break;

      case "feeName":
        valid = !!value.trim();
        break;

      case "description":
        valid = true;
        break;

      default:
        break;
    }
    return valid;
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    let value = e.currentTarget.value;
    if (e.currentTarget.name === "feePrice") {
      value = formatCurrency(value, "");
    }
    if (e.currentTarget.name === "description") {
      if ((value as string).length > 100) {
        setDescriptionError(true);
      } else {
        setDescriptionError(false);
        setDescriptionValue(value as string);
        value as string;
      }
    }
    if (e.currentTarget.name === "feeName") {
      if ((value as string).length > 50) {
        setfeeNameErMsg("Maximum 50 character");
        setFeeNameError(true);
      } else {
        setfeeNameValue(value as string);
        setFeeNameError(false);
        setfeeNameErMsg("");
        value as string;
      }
    }

    const valid = validateField(e.currentTarget.name, value);
    setFeeFormData([
      ...feeFormData.slice(0, index),
      {
        ...feeFormData[index],
        value,
        isValid: feeFormData[index].id === "serviceBilled" ? true : valid,
      },
      ...feeFormData.slice(index + 1),
    ]);
  };

  const onClickCancel = () => {
    onCancelClick();
  };

  const onClickSave = () => {
    setErrorInitiated(true);
    setIsLoading(true);
    const allValid = feeFormData.every(el => el.isValid);
    if (allValid) {
      setEditFeeError(false);
      if (isAdminPage) {
        dispatch(
          updateSystemFee({
            name: feeFormData.filter(v => v.id === "feeName")[0].value,
            addTax: hstCheckBox,
            price: +feeFormData.filter(v => v.id === "feePrice")[0].value.replace(AmountReplaceRegex, "") * 100,
            enabled: enableFeeCheckBox,
            feeId: feeId,
            description:
              feeFormData.filter(v => v.id === "description")[0].value !== ""
                ? feeFormData.filter(v => v.id === "description")[0].value.trim()
                : undefined,
          })
        )
          .then(action => {
            if (action.payload !== null) {
              onCancelClick(true);
            } else {
              setEditFeeError(true);
            }
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else {
        if (currentOffice?.officeId) {
          dispatch(
            updateOfficeFee({
              fee: {
                name: feeFormData.filter(v => v.id === "feeName")[0].value,
                addTax: hstCheckBox,
                price: +feeFormData.filter(v => v.id === "feePrice")[0].value.replace(AmountReplaceRegex, "") * 100,
                enabled: enableFeeCheckBox,
                officeId: currentOffice?.officeId,
                feeId: feeId,
                description:
                  feeFormData.filter(v => v.id === "description")[0].value !== ""
                    ? feeFormData.filter(v => v.id === "description")[0].value.trim()
                    : undefined,
              },
              officeId: currentOffice?.officeId,
            })
          )
            .then(action => {
              if (action.payload !== null) {
                onCancelClick(true);
              } else {
                setEditFeeError(true);
              }
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  const onBlurPrice = (e: { currentTarget: { value: string } }) => {
    const value = e.currentTarget.value;
    const newVal = formatCurrency(value, "blur");
    const index = feeFormData.findIndex(el => el.id === "feePrice");
    setFeeFormData([
      ...feeFormData.slice(0, index),
      {
        ...feeFormData[index],
        value: newVal,
      },
      ...feeFormData.slice(index + 1),
    ]);
  };

  return (
    <>
      <div className="inline-block h-scrollable-screen overflow-auto w-full py-5">
        {EditFeeError ? (
          <p className="text-center" style={{ color: colors.Red }}>
            Unable to update fee, please try again.
          </p>
        ) : null}
        {feeFormData.map((item: EditFeeDataType, index) => {
          if (item.id === "description") {
            return (
              <div key={item.id} className="px-8 py-2">
                <TextInput
                  isError={(!item.isValid && errorInitiated) || descriptionError}
                  value={descriptionValue}
                  name={item.id}
                  onChangeFunc={e => handleFieldChange(e, index)}
                  label={item.label}
                  errorMsg={item.errorMesage}
                  isRequired={item.required}
                  placeholder={item.placeholder}
                  isTextArea={true}
                  disabled={disabled}
                  counterMessage={"100"}
                  showCounter={true}
                  isDarkBg={true}
                />
              </div>
            );
          }
          if (item.id === "feePrice") {
            return (
              <div key={item.id} className="px-8 py-2">
                <TextInput
                  isError={!item.isValid && errorInitiated}
                  value={item.value}
                  name={item.id}
                  onChangeFunc={e => handleFieldChange(e, index)}
                  label={item.label}
                  errorMsg={item.errorMesage}
                  isRequired={item.required}
                  placeholder={item.placeholder}
                  onBlur={e => onBlurPrice(e)}
                  disabled={disabled}
                  isDarkBg={true}
                />
              </div>
            );
          }

          return (
            <div key={item.id} className="px-8 py-2">
              <TextInput
                isError={(!item.isValid && errorInitiated) || feeNameError}
                value={feeNameValue}
                name={item.id}
                onChangeFunc={e => handleFieldChange(e, index)}
                label={item.label}
                errorMsg={feeNameErrMsg.length ? feeNameErrMsg : item.errorMesage}
                isRequired={item.required}
                placeholder={item.placeholder}
                disabled={disabled}
                isDarkBg={true}
              />
            </div>
          );
        })}
        <div className="px-8 py-2">
          <Checkbox
            id="add-fee-hst"
            checked={hstCheckBox}
            disabled={disabled}
            onChange={e => setHstCheckBox(e.currentTarget.checked)}
            label="HST applicable"
            isDarkBg={true}
          />
        </div>
        <div className="px-8 py-2">
          <Checkbox
            id="add-fee-enable"
            checked={enableFeeCheckBox}
            disabled={disabled}
            onChange={e => setEnableFeeCheckBox(e.currentTarget.checked)}
            label="Enable fee"
            isDarkBg={true}
          />
        </div>
      </div>

      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            disabled={isLoading}
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button
              onClickFunc={onClickSave}
              disabled={(feeFormData.some(el => !el.isValid) && errorInitiated) || descriptionError || feeNameError || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default EditFee;
