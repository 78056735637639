import { useContext, useEffect, useState } from "react";
import { BreadCrumbs, Button, ConfirmationDialog, Pagination, TableHeader, TableRow, TextInput } from "../../components";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { ToastVariants, headersType } from "../../models";
import Delete from "../../assets/images/delete-file.svg";
import File from "../../assets/images/file.svg";
import Edit from "../../assets/images/edit.svg";
import Download from "../../assets/images/download-new.svg";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import { deleteForm, editFormName, getFormUrl, getForms } from "../../redux/slices/FormSlice";
import { Form } from "../../models/form.model";
import { useDebouncedCallback } from "use-debounce";
import { Modal } from "@mui/material";
import close from "../../assets/images/close.svg";
import { PayloadAction } from "@reduxjs/toolkit";
import FormUploadLayout from "./FormUploadLayout";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NotFound from "../../assets/images/no-forms.png";

export const headersInitialState: headersType[] = [
  {
    title: "Form Name",
    extraClasses: "w-full pl-0 truncate",
    sortKey: "formName",
    isClickable: false,
  },
  {
    title: "",
    extraClasses: "w-40 pr-0",
    sortKey: "",
    isClickable: false,
  },
];

const EditFileName = ({ fileName, setFileName }: any) => {
  return (
    <div className="mx-auto p-4 w-full">
      <p className="text-base mb-3 text-docsigna-blue-dark text-center">Edit File Name</p>
      <div className="">
        <TextInput value={fileName} onChangeFunc={e => setFileName(e.target.value)} isDarkBg={true} />
      </div>
    </div>
  );
};

const FormsScreen = ({ isAdminPage }: { isAdminPage?: boolean }) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const [headers] = useState(headersInitialState);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [editFileName, setEditFileName] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFormFile, setShowFormFile] = useState("");
  const { forms, sort } = useSelector((state: RootState) => state.form);
  const extraClasses = headers.map(head => head.extraClasses);

  const handleCancel = (val?: boolean) => {
    setShowModal(false);
    if (val) {
      dispatch(getForms({ page: sort.pageNumber, size: sort.rowsPerPage, searchTerm: sort.searchTerm }));
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const res = await dispatch(deleteForm({ formId: showDeleteModal }));
    if (res.payload) {
      dispatch(getForms({ page: sort.pageNumber, size: sort.rowsPerPage, searchTerm: sort.searchTerm }));
      toast?.openToast("Form deleted successfully");
    } else {
      toast?.openToast("Form deletion failed");
    }
    setShowDeleteModal("");
    setLoading(false);
  };

  const handleEditFormTemplateName = async () => {
    if (fileName?.trim().length <= 0) {
      toast?.openToast("Please provide file name", 2000, ToastVariants.Error);
      return;
    }
    setLoading(true);
    const res = await dispatch(editFormName({ formId: editFileName, name: fileName }));
    if (res.payload) {
      dispatch(getForms({ page: sort.pageNumber, size: sort.rowsPerPage, searchTerm: sort.searchTerm }));
      toast?.openToast("Form name updated successfully");
    } else {
      toast?.openToast("Form name update failed");
    }
    setEditFileName("");
    setLoading(false);
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(getForms({ page: newPage, size: sort.rowsPerPage, searchTerm: sort.searchTerm }));
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(getForms({ page: 0, size: parseInt(event.target.value, 10), searchTerm: sort.searchTerm }));
  };

  const searchDebounced = useDebouncedCallback((val: string) => {
    dispatch(getForms({ searchTerm: val.trim().length > 2 ? val.trim() : undefined, page: 0, size: 10 }));
  }, 500);

  const handleSearch = (val: string) => {
    setSearchTerm(val);
    searchDebounced(val);
  };

  const getFormData = async (formId: string, download?: boolean) => {
    dispatch(getFormUrl({ formId }))
      .then((res: PayloadAction<any>) => {
        const fileUrl = res?.payload ?? "";
        if (fileUrl) {
          if (download) {
            fetch(fileUrl)
              .then(response => {
                if (!response.ok) {
                  toast?.openToast("Unable to download file.");
                }
                return response.blob();
              })
              .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = "contactForm.pdf";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              })
              .catch(_ => {
                toast?.openToast("Unable to download file.");
              });
          } else {
            setShowFormFile(fileUrl);
          }
        } else {
          toast?.openToast("No form file found.");
        }
      })
      .catch(_ => {
        toast?.openToast("No form file found.");
      });
  };

  useEffect(() => {
    dispatch(getForms({ page: 0, size: 10 }));
  }, [dispatch]);

  return (
    <div className="container mx-auto p-8">
      {!isAdminPage ? <OfficeSectionsHeader showMeta={false} /> : <BreadCrumbs items={[{ key: "#", title: "Forms" }]} />}
      <div className="block lg:flex justify-between items-center mb-6">
        <div className="w-full lg:w-2/3 mb-5 lg:mb-0">
          <h2 className="heading mb-0 lg:mb-5 uppercase">Forms</h2>
          <p className="text-base font-medium">{isAdminPage ? "Manage form templates here." : "View and download available form templates below."}</p>
        </div>
        <div className="block md:flex lg:justify-end w-full lg:w-2/3">
          <div className="flex items-center">
            <div className="relative w-full">
              <TextInput
                onChangeFunc={e => {
                  handleSearch(e.currentTarget.value);
                }}
                value={searchTerm}
                placeholder="Search Form Templates"
                extraInputClass="w-full"
                isSearchInput={true}
              />
            </div>
          </div>
          {isAdminPage ? (
            <div className="block md:flex md:justify-end items-center ml-0 md:ml-5">
              <span className="md:mr-5 font-medium text-center hidden md:inline-block">or</span>
              <Button
                text="Upload Form Template"
                onClickFunc={() => setShowModal(true)}
                AdditionalClassNames="whitespace-nowrap w-auto mt-3 md:mt-0"
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex justify-between items-center border-b border-docsigna-blue-light">
          {headers.map((head, index: number) => (
            <TableHeader
              title={head.title}
              index={index}
              key={index}
              sortIcon={null}
              isClickable={head.isClickable}
              handleSort={() => null}
              extraClassesHeader={extraClasses[index]}
              isCheckbox={head?.isCheckbox}
              selectAll={() => null}
            />
          ))}
        </div>
      {forms.length > 0 ? (
        <>
        {forms.map((rowData: Form, index) => {
          const sortedData = {
            name: rowData.name,
            status: (
              <div className="flex gap-3 justify-end">
                <img src={File} alt="file icon" onClick={() => getFormData(rowData.formId)} />
                {isAdminPage ? (
                  <>
                    <img
                      src={Edit}
                      alt="edit icon"
                      onClick={() => {
                        setFileName(rowData.name);
                        setEditFileName(rowData.formId);
                      }}
                    />
                    <img
                      src={Delete}
                      alt="delete icon"
                      onClick={() => {
                        setShowDeleteModal(rowData.formId);
                      }}
                    />
                  </>
                ) : (
                  <img src={Download} alt="Download icon" onClick={() => getFormData(rowData.formId, true)} />
                )}
              </div>
            ),
          };
          return (
            <>
              <TableRow
                data={Object.values(sortedData)}
                key={index}
                id={index.toString()}
                extraClassesRow={extraClasses}
                handleCloseTooltip={() => null}
                handleOpenTooltip={() => null}
                tooltipVisible={false}
                onRowClick={() => null}
              />
            </>
          );
        })}
        <Pagination
          colSpan={10}
          count={sort.totalElements ?? 0}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={sort.pageNumber}
          rowsPerPage={sort.rowsPerPage}
        />
        </>
      ):
        <DataNotFound image={NotFound} text="No forms found" />
      }
      <ConfirmationDialog
        open={showModal}
        description={<FormUploadLayout handleCancel={handleCancel} />}
        successButtonText={""}
        failureButtonText={""}
        handleSuccess={() => null}
        handleFailure={() => null}
      />
      <ConfirmationDialog
        open={showDeleteModal?.length > 0}
        description={"Are you sure you want to delete this form? This action cannot be undone."}
        successButtonText={"Yes"}
        failureButtonText={"No"}
        handleSuccess={handleDelete}
        handleFailure={() => setShowDeleteModal("")}
        loading={loading}
      />
      <ConfirmationDialog
        open={editFileName.length > 0}
        description={<EditFileName fileName={fileName} setFileName={setFileName} />}
        successButtonText={"Confirm"}
        failureButtonText={"Cancel"}
        handleSuccess={handleEditFormTemplateName}
        handleFailure={() => {
          setFileName("");
          setEditFileName("");
        }}
        loading={loading}
      />
      <Modal open={showFormFile?.length > 0} onClose={() => setShowFormFile("")}>
        <div className="modal relative z-10">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl">
                  <div className="bg-white p-4 xl:p-6">
                    <span className="modal-close absolute top-2 right-2 w-7 h-7 bg-white rounded-full">
                      <img src={close} alt="" className="w-full h-full cursor-pointer" onClick={() => setShowFormFile("")} />
                    </span>
                    <embed src={showFormFile} className="w-full" style={{ height: "80vh" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FormsScreen;
