import React, { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { AuthPermission, UserStates } from "../../services/auth.service";
import InviteUserSideBarContent from "../../components/forms/User/InviteUserSideBarContent";
import ProtectedComponent from "../../components/ProtectedComponent/ProtectedComponent";
import UserSideBar from "../../components/UserSideBar/UserSideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelInvitation,
  closeToast,
  closeTooltipUser,
  openTooltipUser,
  resendInvitation,
  searchUser,
  updateUser,
  UserDataForRender,
} from "../../redux/slices/UserSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { User } from "../../models/user.model";
import { Button, Pagination, TextInput } from "../../components";
import { TableHeader, TableRow } from "../../components/TableComponents/TableComponents";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { getSorting, getUserRoleByType } from "../../utils";
import { Office } from "../../models/office.model";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { UserType } from "../../services/user.service";
import EditUser from "../../components/forms/EditUser/EditUser";
import { headers } from "./UserScreenData";
import { CircularProgress } from "@mui/material";
import { sortObj } from "../../redux/slices/OfficeSlice";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NoUsersFound from "../../assets/images/no-team.png";
import { SESSION_STORAGE_USER_STATE, UserStateTypes, colors } from "../../utils/constants";
import { startCase } from "lodash";
import { useDebouncedCallback } from "use-debounce";

const UserScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { usersList, error, errorMessage, toastVisible, loading } = useSelector((state: RootState) => state.user);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const toast = useContext(ToastContext);
  const [sidebarVisibility, setSidebarVisibility] = useState(false);
  const [errorInitiated, seterrorInitiated] = useState(false);
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [sortMethods, setSortMethods] = useState<boolean[]>(headers.map(() => false));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(-1);
  const [selectedUserData, setselectedUserData] = useState<User | undefined>(undefined);
  const [extraClasses] = useState<string[]>(headers.map(head => head.extraClasses));
  const [term, setTerm] = useState<string>("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [tooltipStates, setTooltipStates] = useState<boolean[]>(new Array(usersList.data ? usersList.data.content.length : 10).fill(false));
  const [editUserSidebar, setEditUserSidebar] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDataForRender | null>(null);
  const [selectedUserState, setSelectedUserState] = useState("");
  const [disableUserConfirmationBox, setDisableUserConfirmationBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState({
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "",
  });

  const hideSideBar = () => {
    setSidebarVisibility(false);
    seterrorInitiated(false);
  };

  const showSidebar = () => setSidebarVisibility(true);

  const onInviteClick = () => {
    seterrorInitiated(true);
  };

  const updateTable = (sortObj?: sortObj, searchText?: string) => {
    const pageNumToBeCalled = sortObj?.pageNumber ?? sort.pageNumber;
    const sizeToBeCalled = sortObj?.rowsPerPage ?? sort.rowsPerPage;
    const sortBy = sortObj?.sortedBy ?? sort.sortedBy;
    const searchT = searchText ?? "";
    const oldUserState = sessionStorage.getItem(SESSION_STORAGE_USER_STATE);
    let userState = "";
    if (oldUserState && oldUserState !== null) {
      userState = oldUserState;
    }
    dispatch(
      searchUser({
        term: searchT?.trim().length >= 2 ? searchT.trim() : "",
        page: pageNumToBeCalled,
        size: sizeToBeCalled,
        sortType: sortBy,
        userState: userState?.trim()?.length ? userState.trim() : undefined,
      })
    ).then(() => {
      setSort({ ...sort, pageNumber: pageNumToBeCalled, rowsPerPage: sizeToBeCalled, sortedBy: sortBy });
    });
  };

  const onSuccessfulInvite = () => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, term);
  };

  const handleSort = (index: number) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    setActiveSortIndex(index);
    updateTable({ sortedBy: sortMethodString, pageNumber: 0, rowsPerPage: sort.rowsPerPage }, term);
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: newPage, rowsPerPage: sort.rowsPerPage }, term);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: 0, rowsPerPage: parseInt(event.target.value, 10) }, term);
  };

  const handleTooltipClick = async (type: string, id: string) => {
    const selectedUser = usersList.data?.content.find(item => item.email === id);
    setselectedUserData(selectedUser);
    if (selectedUser) setSelectedUser(selectedUser);
    const userId = usersList.data?.content.find(item => item.email === id)?.userId;
    switch (type) {
      case "Resend Invite":
        if (userId) {
          dispatch(resendInvitation({ userId: userId })).then(res => {
            if (res.payload) {
              toast?.openToast("User invitation sent");
            } else {
              toast?.openToast("Unable to resend invitation");
            }
          });
        }
        break;
      case "Cancel Invite":
        setConfirmationOpen(true);
        break;
      case "Disable User":
        setDisableUserConfirmationBox(true);
        break;
      case "Enable User":
        if (selectedUser?.userId) {
          updateUserState(UserStateTypes.Active, selectedUser);
        }
        break;
      case "Remove User":
        break;
      case "Edit User":
        setEditUserSidebar(true);
        break;
      default:
        toast?.openToast();
        break;
    }
    setTooltipStates([...tooltipStates].fill(false));
  };

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));

  //TODO: Disable rows per page option if available users are less that option value.

  const refreshTable = () => {
    if (error === false) {
      updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, term);
    }
  };

  const onAgreeClick = () => {
    if (selectedUserData) {
      dispatch(cancelInvitation({ userId: selectedUserData?.userId })).then(response => {
        if (response.payload) refreshTable();
        setTimeout(() => {
          dispatch(closeToast());
        }, 2000);
      });
    }
    setselectedUserData(undefined);
    setConfirmationOpen(false);
  };

  const onDisagreeClick = () => {
    setConfirmationOpen(false);
    setselectedUserData(undefined);
    setDisableUserConfirmationBox(false);
  };

  const debounced = useDebouncedCallback(value => {
    if (value && value.trim().length >= 2) {
      updateTable({ ...sort, pageNumber: 0 }, value.trim());
    }
  }, 1000);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    const oldSearchTerm = term;
    setTerm(input);
    debounced(e.currentTarget.value);
    if (oldSearchTerm !== "" && input.length <= 1) {
      updateTable(sort);
    }
  };

  const handleToolTipOpen = (index: number) => {
    dispatch(openTooltipUser(index));
  };

  const handleToolTipClose = () => {
    dispatch(closeTooltipUser());
  };

  const getIsPCP = (offices: Office[] | undefined, userId: string) => {
    if (!offices) return false;
    for (let i = 0; i < offices.length; i++) {
      const selectedOffice = offices[i];
      if (selectedOffice.primaryCareProviders?.some(pcp => pcp.userId === userId)) {
        return true;
      }
    }
    return false;
  };

  const handleRowClick = (data: UserDataForRender) => {
    setEditUserSidebar(true);
    setSelectedUser(data);
  };

  const updateUserState = async (val: string, userData: User) => {
    if (userData?.userId) {
      const updateUserData = { userId: userData?.userId, userState: val };
      await dispatch(updateUser({ user: updateUserData }));
      updateTable(sort, term);
    }
  };

  const onChangeUserState = (val: string) => {
    setSelectedUserState(val);
    sessionStorage.setItem(SESSION_STORAGE_USER_STATE, val);
    updateTable(sort, term);
  };

  const authorizedToEdit =
    currentUser && currentUser.userType ? [UserType.SystemAdmin, UserType.OfficeAdmin].includes(currentUser.userType as UserType) : false;
  const toolTipOptions = authorizedToEdit ? ["Edit User", "Disable User"] : ["View User"];
  const toolTipOptions1 = authorizedToEdit ? ["Edit User", "Enable User"] : ["View User"];

  useEffect(() => {
    updateTable();
    const oldUserState = sessionStorage.getItem(SESSION_STORAGE_USER_STATE);
    if (oldUserState && oldUserState !== null) {
      setSelectedUserState(oldUserState);
    } else {
      setSelectedUserState("All Users");
    }
  }, []);

  useEffect(() => {
    if (toastVisible) {
      toast?.openToast(errorMessage);
    }
  }, [toastVisible]);

  return (
    <>
      {confirmationOpen ? (
        <ConfirmationDialog
          open={confirmationOpen}
          title={`Cancel invitation for ${selectedUserData !== undefined ? selectedUserData.email : ""}`}
          description={""}
          successButtonText={"Agree"}
          handleSuccess={onAgreeClick}
          failureButtonText={"Disagree"}
          handleFailure={onDisagreeClick}
        />
      ) : null}
      {disableUserConfirmationBox ? (
        <ConfirmationDialog
          open={disableUserConfirmationBox}
          title={`Are you sure you want to disable?`}
          description={"This user will no longer be able to access Docnote."}
          successButtonText={"Confirm"}
          handleSuccess={() => {
            if (selectedUser) updateUserState(UserStateTypes.Disabled, selectedUser);
            setSelectedUser(null);
            setDisableUserConfirmationBox(false);
          }}
          failureButtonText={"Cancel"}
          handleFailure={onDisagreeClick}
        />
      ) : null}
      <main>
        <div className="container mx-auto p-8">
          <OfficeSectionsHeader showMeta={false} />
          <div className="block lg:flex justify-between items-center mb-6">
            <div className="w-full lg:w-1/4 mb-5 lg:mb-0">
              <h2 className="heading mb-0 lg:mb-5 uppercase">Users</h2>
              <p className="text-base font-medium">Manage your users here.</p>
            </div>
            <div className="block md:flex lg:justify-end w-full lg:w-3/4 space-x-0 md:space-x-3">
              <select className={`general-select w-full md:max-w-sm`} value={selectedUserState} onChange={e => onChangeUserState(e.target.value)}>
                <option>Select User State</option>
                <option value={UserStateTypes.All}>{UserStates.All}</option>
                <option value={UserStateTypes.Active}>{UserStates.Active}</option>
                <option value={UserStateTypes.Invited}>{UserStates.Invited}</option>
                <option value={UserStateTypes.Disabled}>{UserStates.Disabled}</option>
              </select>
              <div className="flex items-center w-full mt-2 md:mt-0">
                <div className="relative w-full">
                  <TextInput
                    value={term}
                    onChangeFunc={handleSearch}
                    placeholder={"Search users"}
                    extraInputClass="w-full md:max-w-sm"
                    isSearchInput={true}
                  />
                </div>
              </div>
              <ProtectedComponent requiredPermission={[AuthPermission.User]}>
                <div className="block md:flex md:justify-end items-center ml-0 md:ml-5">
                  <span className="md:mr-5 font-medium text-center hidden md:inline-block">or</span>
                  <Button text="Invite User" onClickFunc={() => showSidebar()} AdditionalClassNames="whitespace-nowrap w-auto mt-3 md:mt-0" />
                </div>
              </ProtectedComponent>
            </div>
          </div>

          {!loading ? (
            <div className="block">
              {usersList?.data?.content && usersList?.data?.content?.length > 0 ? (
                <>
                  <div className="flex justify-between items-center border-b border-docsigna-blue-light">
                    {headers.map((head, index) => (
                      <TableHeader
                        title={head.title}
                        index={index}
                        key={index}
                        sortIcon={sortIcons[index]}
                        handleSort={handleSort}
                        extraClassesHeader={head.extraClasses}
                        isClickable={head.isClickable}
                      />
                    ))}
                    <div className="w-auto p-5 py-3">
                      <span className="text-base text-docsigna-purple-dark inline-block w-5"></span>
                    </div>
                  </div>
                  {usersList.data?.content.map((rowData, index) => {
                    const sortedData = {
                      name: rowData.firstName + " " + rowData.lastName,
                      office: rowData.offices && rowData.offices.length ? rowData.offices[0].name : "--",
                      email: (
                        <a href={`mailto:${rowData.email}`} className="cursor-pointer hover:underline" onClick={e => e.stopPropagation()}>
                          {rowData.email}
                        </a>
                      ),
                      role: getUserRoleByType(rowData.userType),
                      pcp: getIsPCP(rowData.offices, rowData.userId) ? (
                        <FiberManualRecordIcon htmlColor={colors.CyanBlue} sx={{ fontSize: 16 }} />
                      ) : (
                        ""
                      ),
                      status: startCase(rowData.userState),
                    };
                    return (
                      <TableRow
                        data={Object.values(sortedData)}
                        key={index}
                        onRowClick={() => (rowData.userState !== UserStateTypes.Invited ? handleRowClick(rowData) : null)}
                        tooltipVisible={rowData.tooltipVisible}
                        handleOpenTooltip={() => handleToolTipOpen(index)}
                        handleCloseTooltip={() => handleToolTipClose()}
                        id={rowData.email}
                        extraClassesRow={extraClasses}
                        tooltipOptions={
                          rowData.userState === UserStateTypes.Invited
                            ? ["Resend Invite", "Cancel Invite"]
                            : rowData.userState === UserStateTypes.Active
                            ? toolTipOptions
                            : toolTipOptions1
                        }
                        handleTooltipClick={handleTooltipClick}
                      />
                    );
                  })}
                  <Pagination
                    colSpan={10}
                    count={usersList.data ? usersList.data.totalElements : 0}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    page={sort.pageNumber}
                    rowsPerPage={sort.rowsPerPage}
                  />
                </>
              ) : (
                <DataNotFound image={NoUsersFound} text="No users found" />
              )}
            </div>
          ) : (
            <CircularProgress size={40} color={"info"} style={{ marginLeft: "50%", marginTop: "10%" }} />
          )}
          <div>
            <UserSideBar
              title="Invite New User"
              visible={sidebarVisibility}
              LeftbuttonText="Cancel"
              RightbuttonText="Invite"
              onCancelClick={hideSideBar}
              onInviteClick={onInviteClick}
              onClickOutside={() => (!isLoading ? hideSideBar() : null)}>
              <InviteUserSideBarContent
                errorInitiated={errorInitiated}
                onCancelClick={hideSideBar}
                onSuccess={onSuccessfulInvite}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </UserSideBar>
            <UserSideBar
              title={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
              visible={editUserSidebar}
              onCancelClick={hideSideBar}
              onInviteClick={onInviteClick}
              onClickOutside={() => (!isLoading ? setEditUserSidebar(false) : null)}>
              <EditUser
                onCancelClick={() => setEditUserSidebar(false)}
                userId={selectedUser?.userId || ""}
                insideOffice={false}
                onSuccess={() => updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, term)}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </UserSideBar>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserScreen;
