import { colors } from "./constants";

const defaultStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 16,
    fontWeight: "normal",
  },
  "&multiLine": {
    control: {
      // fontFamily: "monospace",
      minHeight: 160,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      border: `1px solid ${colors.DarkBlue}`,
      boxShadow: 'none',
      borderRadius: "8px",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      margin: "0px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#e0f1ff",
      },
    },
  },
};
export default defaultStyle;
