import { Link } from "react-router-dom";
import React from "react";

export interface BreadCrumbsType {
  title: string | undefined;
  key: string;
}
const BreadCrumbs = ({ items }: { items: BreadCrumbsType[] }) => {
  return (
    <div className="mb-3 text-sm font-medium text-gray-400">
      {items.map((item: BreadCrumbsType, index) => {
        if (index < items.length - 1) {
          return (
            <React.Fragment key={index}>
              <Link to={item.key} className="text-sm text-docsigna-orange capitalize hover:text-sm hover:underline hover:underline-offset-2">
                {item.title}
              </Link>
              {" > "}
            </React.Fragment>
          );
        } else {
          return (
            <span className="text-sm capitalize text-docsigna-orange" key={item.key}>
              {item.title}
            </span>
          );
        }
      })}
    </div>
  );
};

export default BreadCrumbs;
