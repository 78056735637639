import { ApiPage, emptyApiPage } from "../models/api-page.model";
import { Fee } from "../models/fee.model";
import { apiService } from "./api.service";

export class FeeService {
  private static BASE_PATH = "/api/v1";
  private static FEES_PATH = `${this.BASE_PATH}/fees`;
  private static OFFICES_PATH = `${this.BASE_PATH}/offices`;

  /*
    SYSTEM FEES
  */
  static async getAllSystemFees(page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Fee>> {
    const response = await apiService.get(`${this.FEES_PATH}?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<Fee>();

    return response.data as ApiPage<Fee>;
  }

  static async searchSystemFees(term: string, page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Fee>> {
    const response = await apiService.post(`${this.FEES_PATH}/search?page=${page}&size=${size}&sort=${sort}`, {
      term: term,
    });
    if (!response.success) return emptyApiPage<Fee>();

    return response.data as ApiPage<Fee>;
  }

  // Returns saved fee with ID included, or null on failure
  static async addSystemFee(fee: Fee): Promise<Fee | null> {
    const response = await apiService.post(`${this.FEES_PATH}`, fee);
    if (!response.success) return null;

    return response.data as Fee;
  }

  static async updateSystemFee(fee: Fee): Promise<Fee | null> {
    const response = await apiService.put(`${this.FEES_PATH}/${fee.feeId}`, fee);
    if (!response.success) return null;

    return response.data as Fee;
  }

  static async deleteSystemFee(feeId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.FEES_PATH}/${feeId}`);
    if (!response.success) return false;

    return true;
  }

  /*
    OFFICE FEES
  */

  static async getOfficeFees(officeId: string, page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Fee>> {
    const response = await apiService.get(`${this.OFFICES_PATH}/${officeId}/fees?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<Fee>();

    return response.data as ApiPage<Fee>;
  }

  static async searchOfficeFees(officeId: string, term: string, page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Fee>> {
    const response = await apiService.post(`${this.OFFICES_PATH}/${officeId}/fees/search?page=${page}&size=${size}&sort=${sort}`, {
      term: term,
    });
    if (!response.success) return emptyApiPage<Fee>();

    return response.data as ApiPage<Fee>;
  }

  static async addOfficeFee(officeId: string, fee: Fee): Promise<Fee | null> {
    const response = await apiService.post(`${this.OFFICES_PATH}/${officeId}/fees`, fee);
    if (!response.success) return null;

    return response.data as Fee;
  }

  static async updateOfficeFee(officeId: string, fee: Fee): Promise<Fee | null> {
    const response = await apiService.post(`${this.OFFICES_PATH}/${officeId}/fees`, fee);
    if (!response.success) return null;

    return response.data as Fee;
  }
}
