import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Comment, ConfirmationDialog, DropArea, FullScreenLoader, PaymentForm, TextInput, Tooltip, UserSideBar } from "../../components";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { AppDispatch, RootState } from "../../redux/store";
import { getFileSize, onFileError, patientRenderState, renderState } from "../../utils";
import PaymentSummaryBox from "../ViewRequest/PatientViewRequestComps/PaymentSummaryBox";
import { LinearProgress, Modal } from "@mui/material";
import { saveAs } from "file-saver";
import { FileTypes, ToastVariants } from "../../models";
import { requestFilesType } from "../../redux/slices/OfficeRequestSlice";
import {
  addRequestFiles,
  addRequestMessage,
  getRequest,
  getRequestFiles,
  getRequestMessages,
  getRequestOffice,
  setRequestMessageRead,
  setRequestState,
} from "../../redux/slices/OnBoardingSlice";
import { colors, SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED } from "../../utils/constants";
import { setOpenPaymentSidebar } from "../../redux/slices/RequestSlice";
import { useNavigate } from "react-router-dom";
import { RequestState, RequestType } from "../../models/request.model";
import close from "../../assets/images/close.svg";
import { clearUploadedFiles } from "../../redux/slices/FileSlice";
import { UserType } from "../../services/user.service";
import { ServiceBilledTo } from "../../models/service.model";
import { clearPatientRequestData } from "../../redux/slices/UserSlice";

const GuestViewRequest = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedOffice, RequestData, loading, pending, RequestMessage } = useSelector((state: RootState) => state.onBoarding);
  const { openPaymentSidebar } = useSelector((state: RootState) => state.request);
  const { paymentSucceded } = useSelector((state: RootState) => state.officeRequest);

  const paidOrSubmitted = RequestData?.paymentComplete || RequestData?.submitted;
  const toast = useContext(ToastContext);
  const navigate = useNavigate();
  const [files, setFiles] = useState<requestFilesType[]>([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [patientFiles, setPatientFiles] = useState<any>([]);
  const [communicationMsg, setCommunicationMsg] = useState("");
  const [officeCommunicationData, setOfficeCommunicationData] = useState<any>([]);
  const guestToken = sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED);
  const allowedFiles = [FileTypes.JPEG, FileTypes.PNG, FileTypes.PDF];
  const [dropAreaError, setDropAreaError] = useState<string>("");
  const addedFiles: string[] = [];
  const [uploadProgress, setUploadProgress] = useState(100);
  const [modalopen, setModalopen] = useState(false);
  const [messageReached, setMessageReached] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isArchived = RequestData?.archived;
  const isBilledToPatient =
    (RequestData?.serviceBilledToOverride
      ? RequestData?.serviceBilledToOverride === ServiceBilledTo.Patient
      : RequestData?.service?.serviceBilledTo === ServiceBilledTo.Patient) || RequestData?.requestType === RequestType.DocnoteDebit;
  const isCancelable = (isBilledToPatient && !RequestData?.paymentComplete) || (!isBilledToPatient && !RequestData?.submitted);

  const [fileUrl, setFileUrl] = useState<any>("");
  const handleTooltip = (open: boolean, id?: string) => {
    const temp = [...files];
    if (open) {
      setFiles(temp.map(file => ({ ...file, tooltipVisible: file.requestFileId === id })));
    } else {
      setFiles(temp.map(file => ({ ...file, tooltipVisible: false })));
    }
  };

  const handleDownloadFile = async (url: string, fileName: string) => {
    const blob = await fetch(url).then(r => r.blob());
    saveAs(blob, fileName);
  };

  const fileToolTipOptions = ["View File"];
  const handleTooltipOption = (type: string, id: string) => {
    if (type === fileToolTipOptions[0]) {
      const fileId = files.find(v => v.requestFileId === id)?.url;
      setFileUrl(fileId || "");
      setModalopen(true);
    }
  };

  const handleConfirmationBox = (valueSelected: "yes" | "no") => {
    if (valueSelected === "no") {
      setConfirmationOpen(false);
    } else {
      if (selectedOffice?.officeId) {
        dispatch(setRequestState({ guestToken: guestData.GuestToken, state: RequestState.Cancelled })).then(act => {
          if (act.payload !== null) {
            dispatch(getRequest({ guestToken: guestData.GuestToken }));
            toast?.openToast("Request Cancelled!");
          } else {
            toast?.openToast("Something went wrong!", 2000, ToastVariants.Error);
          }
        });
        setConfirmationOpen(false);
      }
    }
  };

  const handleScroll = async () => {
    const position = window.pageYOffset;
    if (position > 500) {
      setMessageReached(true);

      const RequestUnReadMessage = RequestMessage?.filter(f => f.unread === true);

      if (RequestUnReadMessage?.length) {
        const setReadPromises = RequestUnReadMessage?.map(async e => {
          if (guestData.GuestToken) {
            await dispatch(setRequestMessageRead({ guestToken: guestData.GuestToken, messageId: e.requestMessageId, read: true }));
          }
        });
        await Promise.all(setReadPromises);

        await dispatch(getRequest({ guestToken: guestData.GuestToken }));
        await dispatch(getRequestMessages({ guestToken: guestData.GuestToken })).then(action => {
          if (action.payload) {
            setOfficeCommunicationData(action.payload);
          }
        });
      }
    }
  };

  const guestData = JSON.parse(guestToken || "{}");

  useEffect(() => {
    if (guestData) {
      dispatch(getRequest({ guestToken: guestData.GuestToken }));
      dispatch(getRequestFiles({ guestToken: guestData.GuestToken })).then(action => {
        if (action.payload) {
          setPatientFiles(action.payload);
        }
      });
    }
  }, [paymentSucceded]);

  useEffect(() => {
    if (guestToken === null) {
      navigate("/guest/login");
    }
    dispatch(getRequest({ guestToken: guestData.GuestToken })).then(act => {
      const acceptedTerm = JSON.stringify(act.payload);
      const parseTerm = JSON.parse(acceptedTerm);

      if (parseTerm?.acceptedTerms === false) {
        navigate("/terms-and-conditions");
      }
    });
    dispatch(getRequestOffice({ guestToken: guestData.GuestToken }));
    dispatch(getRequestMessages({ guestToken: guestData.GuestToken })).then(action => {
      if (action.payload) {
        setOfficeCommunicationData(action.payload);
      }
    });
    dispatch(getRequestFiles({ guestToken: guestData.GuestToken })).then(action => {
      if (action.payload) {
        setPatientFiles(action.payload);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(clearPatientRequestData());
    return () => {
      dispatch(clearUploadedFiles());
    };
  }, []);

  useEffect(() => {
    const AllFiles =
      patientFiles && patientFiles?.length
        ? patientFiles?.map((ele: any) => {
            return { tooltipVisible: true, ...ele };
          })
        : [];
    setFiles(AllFiles);
  }, [patientFiles]);

  useEffect(() => {
    if (RequestData?.requestId && messageReached === false) {
      window.addEventListener("scroll", handleScroll, { passive: true });

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [RequestData, messageReached]);

  const openFile = (requestFileId: string) => {
    const fileToOpen = files.find(f => f.requestFileId === requestFileId);
    setFileUrl(fileToOpen?.url || "");
  };

  const handleSentCommunicationMsg = () => {
    const senderName = RequestData ? RequestData?.firstName + " " + RequestData?.lastName : "";
    dispatch(addRequestMessage({ guestToken: guestData.GuestToken, senderName, message: communicationMsg })).then(() => {
      dispatch(getRequestMessages({ guestToken: guestData.GuestToken })).then(action => {
        if (action.payload) {
          setOfficeCommunicationData(action.payload);
          setCommunicationMsg("");
        }
      });
    });
  };

  const addFles = (requestFileIds: string[]) => {
    const filesToAdd: string[] = [];
    requestFileIds.forEach(id => {
      if (!addedFiles.includes(id)) {
        filesToAdd.push(id);
        addedFiles.push(id);
        dispatch(addRequestFiles({ guestToken: guestData.GuestToken, fileIds: [id] })).then(() => {
          dispatch(clearUploadedFiles());
          dispatch(getRequestFiles({ guestToken: guestData.GuestToken })).then(action => {
            if (action.payload) {
              setPatientFiles(action.payload);
            }
          });
        });
      }
    });
  };

  const handleDropArea = (requestFileIds: string[]) => {
    addFles(requestFileIds);
  };

  const serviceBilledTo =
    (RequestData?.serviceBilledToOverride
      ? RequestData?.serviceBilledToOverride === ServiceBilledTo.Patient
      : RequestData?.service?.serviceBilledTo === ServiceBilledTo.Patient) || RequestData?.requestType === RequestType.DocnoteDebit;

  return (
    <main>
      {loading || RequestData === null ? <FullScreenLoader /> : null}
      <div className="container mx-auto p-8">
        <div className="flex justify-between items-start mb-6">
          <div className="flex justify-between items-start flex-wrap w-full pr-0 md:pr-12 lg:pr-24">
            <div className="text-left w-full md:w-auto mb-2 md:mb-0">
              <h2 className="heading">{`${RequestData?.firstName} ${RequestData?.lastName}`}</h2>
              <p className="text-md">Request # {RequestData?.requestNumber}</p>
            </div>
            <span className="inline-block text-sm text-white md:ml-2 bg-docsigna-purple-light rounded-full font-medium py-2 px-5">
              {serviceBilledTo
                ? RequestData?.state
                  ? renderState[RequestData?.state]
                  : null
                : RequestData?.state
                ? patientRenderState(RequestData.state)
                : null}
            </span>
          </div>
          <div className="hidden md:block w-full md:max-w-xs"></div>
        </div>

        <div className="flex flex-wrap md:flex-nowrap flex-col-reverse md:flex-row">
          <div className="w-full mt-3 md:mt-0">
            <Box title="Patient Details" extraClasses="pt-2">
              <>
                <p className="text-md mb-2">
                  <span className="font-medium">OHIP Number:</span> {RequestData?.ohipNumber ? RequestData?.ohipNumber : "-"}
                </p>
                <p className="text-md mb-2">
                  <span className="font-medium">Date of Birth:</span>{" "}
                  {RequestData?.dateOfBirth ? moment(`${RequestData?.dateOfBirth}`).format("ll") : "-"}
                </p>
                <p className="text-md mb-2">
                  <span className="font-medium">Email Address:</span> <span className="text-base">{RequestData?.email}</span>
                </p>
                <p className="text-md">
                  <span className="font-medium">Phone #:</span> {RequestData?.phone}
                </p>
              </>
            </Box>
            <div className="flex justify-between items-center flex-wrap mb-1 mr-24">
              <p className="font-semibold">Request Details</p>
              {!isArchived &&
              RequestData?.state &&
              [RequestState.PendingPayment, RequestState.PendingVerification].includes(RequestData?.state) &&
              RequestData?.requestType === RequestType.Standard ? (
                <Button
                  AdditionalClassNames={paidOrSubmitted ? "opacity-30" : ""}
                  text="Cancel Request"
                  onClickFunc={() => setConfirmationOpen(true)}
                />
              ) : null}
            </div>
            <Box title="">
              <>
                <p className="text-md mb-2">
                  <span className="font-medium">Request#:</span> {RequestData?.requestNumber}
                </p>
                <p className="text-md mb-2">
                  <span className="font-medium">Date:</span> {moment(`${RequestData?.createdDate.slice(0, 10)}`).format("ll")}
                </p>
                <p className="text-md mb-2">
                  <span className="font-medium">Service:</span> {RequestData?.service?.name}
                </p>
                <p className="text-md mb-2">
                  <span className="font-medium">Office:</span> {selectedOffice?.name} <br /> Phone # {selectedOffice?.phone} | Fax #{" "}
                  {selectedOffice?.fax}
                  <br /> {selectedOffice?.street}, {selectedOffice?.city}, {selectedOffice?.state}, {selectedOffice?.zip}
                </p>
                <p className="text-md mb-2">
                  <span className="font-medium">Notes:</span> {RequestData?.details}
                </p>

                <p className="text-sm">{""}</p>
              </>
            </Box>
            {RequestData?.requestType !== RequestType.DocnoteDebit ? (
              <Box title="Files">
                <>
                  <>
                    <p style={{ color: colors.Red }}>{dropAreaError}</p>
                    {isArchived || RequestData?.state === RequestState.Complete || RequestData?.state === RequestState.Cancelled ? null : (
                      <div className="text-center mb-2 relative">
                        <DropArea
                          allowedFileTypes={allowedFiles}
                          maxFileSize={50 * 1024 * 1024}
                          maxNumOfFiles={10}
                          onError={err => setDropAreaError(onFileError(err))}
                          onFilesChange={handleDropArea}
                          onProgress={setUploadProgress}
                          hideImageInPreview
                          hidePreviewAfterUpload
                          filePreviewVisible={false}
                        />
                      </div>
                    )}
                    {uploadProgress !== 100 ? (
                      <div className="flex justify-between items-center border-b w-full">
                        <div className="w-full">
                          <LinearProgress value={uploadProgress} variant="determinate" />
                        </div>
                      </div>
                    ) : null}
                  </>

                  {files.length
                    ? files?.map((file, index) => {
                        return (
                          <div key={index} className="block lg:flex justify-between items-center border-b w-full">
                            <div className="w-full lg:w-1/2 p-3 pl-0 pr-0 lg:pr-3 pb-0 lg:pb-3">
                              <p
                                className="break-all text-base text-docsigna-blue-light cursor-pointer"
                                onClick={() => {
                                  openFile(file.requestFileId ? file.requestFileId : "");
                                  setModalopen(true);
                                }}>
                                {file.originalFilename}
                              </p>
                            </div>
                            <div className="flex justify-between items-center w-full lg:w-1/2">
                              <div className="inline-block w-full p-3 pl-0 lg:pl-3 truncate">{moment(`${file.createdDate}`).format("ll")}</div>
                              <div className="inline-block w-full p-3 pl-0 lg:pl-3 truncate">{getFileSize(file.size)}</div>
                              <div className="inline-block w-auto p-3 pl-0 lg:pl-3 relative">
                                <p className="text-base text-docsigna-blue-dark inline-block w-5">
                                  <Tooltip
                                    open={file.tooltipVisible}
                                    onSelect={type => handleTooltipOption(type, file.requestFileId ? file.requestFileId : "")}
                                    options={fileToolTipOptions}
                                    handleOpenTooltip={() => {
                                      handleTooltip(true, file.requestFileId);
                                    }}
                                    handleCloseTooltip={() => {
                                      handleTooltip(false);
                                    }}
                                    additionalComponents={[
                                      <div className="block" key={"1"} style={{ cursor: "pointer" }}>
                                        <p
                                          className="block text-base px-4 py-3 border-b border-gray-200"
                                          onClick={() => {
                                            if (file.url) handleDownloadFile(file.url, file.originalFilename);
                                            handleTooltip(false);
                                          }}>
                                          Download File
                                        </p>
                                      </div>,
                                    ]}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : RequestData?.state === RequestState.Complete || RequestData?.state === RequestState.Cancelled
                    ? "-"
                    : null}
                </>
              </Box>
            ) : null}
            <Box title="Office Communication">
              <>
                {officeCommunicationData.length
                  ? officeCommunicationData?.map((cmt: any) => {
                      return (
                        <Comment
                          from={
                            cmt?.sender?.userType === UserType.SystemAdmin
                              ? selectedOffice?.name || ""
                              : `${cmt?.sender ? cmt?.sender?.firstName + " " + cmt?.sender?.lastName : cmt?.senderName}`
                          }
                          text={cmt.message}
                          key={cmt.requestMessageId}
                          time={cmt.createdDate}
                          comment={cmt}
                        />
                      );
                    })
                  : RequestData?.state === RequestState.Cancelled
                  ? "-"
                  : null}

                {isArchived || RequestData?.state === RequestState.Cancelled ? null : (
                  <div className={`tabs inline-block pt-3 mt-2 w-full  ${officeCommunicationData?.length && "border-t border-slate-200"}`}>
                    <div className="tab-nav flex mb-1">
                      <span onClick={() => toast?.openToast()} className="text-md text-center font-medium p-2 pl-0 -mb-px mr-8 cursor-pointer">
                        Message Doctor&apos;s Office
                      </span>
                    </div>
                    <div className="tab-content text-right">
                      <TextInput
                        isTextArea
                        value={communicationMsg}
                        onChangeFunc={e => setCommunicationMsg(e.currentTarget.value)}
                        placeholder="Add a message to the doctor’s office"
                        extraInputClass="mb-4 h-20 resize-none"
                        onBlur={e => setCommunicationMsg(e.currentTarget.value.trim())}
                      />
                      <Button
                        text="Send"
                        width="fit"
                        disabled={communicationMsg.length === 0 || pending}
                        onClickFunc={() => handleSentCommunicationMsg()}
                        AdditionalClassNames="px-8 py-1 "
                      />
                    </div>
                  </div>
                )}
              </>
            </Box>
          </div>
          <div className="w-full md:max-w-xs">
            <PaymentSummaryBox
              showPaymentDetails={
                (RequestData?.state === RequestState.PendingPayment ||
                  RequestData?.state === RequestState.InProgress ||
                  RequestData?.state === RequestState.Complete) &&
                serviceBilledTo
              }
            />
          </div>
        </div>
      </div>
      <UserSideBar
        visible={openPaymentSidebar}
        onClickOutside={() => {
          !isLoading ? dispatch(setOpenPaymentSidebar(false)) : null;
        }}
        title={"Payment Details"}>
        <PaymentForm officeTaxEnabled={selectedOffice?.taxEnabled} setIsLoading={setIsLoading} />
      </UserSideBar>

      <Modal open={modalopen} onClose={() => setModalopen(false)}>
        <div className="modal relative z-10">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl">
                  <div className="bg-white p-4 xl:p-6">
                    <span className="modal-close absolute top-2 right-2 w-7 h-7 bg-white rounded-full">
                      <img src={close} alt="" className="w-full h-full cursor-pointer" onClick={() => setModalopen(false)} />
                    </span>
                    <embed src={fileUrl} className="w-full" style={{ height: "80vh" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationDialog
        open={confirmationOpen}
        description={
          isCancelable
            ? "Are you sure you want to cancel this request? If you change your mind you will need to begin a new request."
            : "Please contact your doctor's office to cancel this request."
        }
        title={""}
        successButtonText={isCancelable ? "Yes" : ""}
        handleSuccess={() => {
          isCancelable ? handleConfirmationBox("yes") : {};
        }}
        failureButtonText={isCancelable ? "No" : "Close"}
        handleFailure={() => {
          handleConfirmationBox("no");
        }}
      />
    </main>
  );
};

export default GuestViewRequest;
