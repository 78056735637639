import { DeleteOutline } from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import { PayloadAction } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import pdfIcon from "../../assets/images/pdfIcon.png";
import { FileTypes, DropAreaErrors } from "../../models";
import { DsFile } from "../../models/ds-file.model";
import { deleteFile, uploadFile } from "../../redux/slices/FileSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { convertToPdf, fileNameFun } from "../../utils";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { colors } from "../../utils/constants";

interface DropAreaType {
  allowedFileTypes: FileTypes[];
  onFilesChange: (fileIds: string[]) => void;
  onError: (errors: DropAreaErrors[]) => void;
  onProgress?: (prg: number) => void;
  maxFileSize: number;
  filePreviewVisible?: boolean;
  maxNumOfFiles: number;
  hideImageInPreview?: boolean;
  hidePreviewAfterUpload?: boolean;
  children?: Element;
  dropAreaText?: string;
  hideDropAreaAfterUploadLimit?: boolean;
  isDarkBg?: boolean;
}

/* 
  onFilesChange will be a callback passed from parent which is triggered when files in the dropArea are updated.
  onError function will be triggered when user uploads files which are not acceptable or exceeds limits.
*/

const DropArea = ({
  allowedFileTypes = [],
  onFilesChange,
  onError,
  onProgress = () => {},
  maxFileSize,
  filePreviewVisible = true,
  maxNumOfFiles = 1,
  hideImageInPreview = false,
  hidePreviewAfterUpload = false,
  hideDropAreaAfterUploadLimit = false,
  children,
  dropAreaText,
  isDarkBg = false,
}: DropAreaType) => {
  const dispatch = useDispatch<AppDispatch>();
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<DropAreaErrors[]>([]);
  const [filesProgress, setFilesProgress] = useState<number[]>([]);
  const { uploadedFiles, loading } = useSelector((state: RootState) => state.file);
  const allowedFileTypesStrings = allowedFileTypes.map(type => type.toString());
  const onFileDrop = (acceptedFiles: File[]) => {
    const fileTypeError = acceptedFiles.some(file => allowedFileTypesStrings.includes(file.type) === false);
    const fileSizeError = acceptedFiles.some(file => file.size > maxFileSize);

    const tempErrors: DropAreaErrors[] = [];

    if (fileTypeError) {
      if (!tempErrors.includes(DropAreaErrors.INVALID_FILE_TYPE)) {
        tempErrors.push(DropAreaErrors.INVALID_FILE_TYPE);
      }
    } else {
      if (tempErrors.includes(DropAreaErrors.INVALID_FILE_TYPE)) {
        tempErrors.splice(errors.indexOf(DropAreaErrors.INVALID_FILE_TYPE), 1);
      }
    }

    if (fileSizeError) {
      if (!tempErrors.includes(DropAreaErrors.FILE_SIZE_EXCEEDED)) {
        tempErrors.push(DropAreaErrors.FILE_SIZE_EXCEEDED);
      }
    } else {
      if (tempErrors.includes(DropAreaErrors.FILE_SIZE_EXCEEDED)) {
        tempErrors.splice(errors.indexOf(DropAreaErrors.FILE_SIZE_EXCEEDED), 1);
      }
    }

    const filteredFiles = acceptedFiles.filter(file => allowedFileTypesStrings.includes(file.type) && file.size <= maxFileSize);

    const newFilesArray = [...files, ...filteredFiles];

    if (newFilesArray.length > maxNumOfFiles) {
      if (!tempErrors.includes(DropAreaErrors.MAX_NUMBER_OF_FILES_EXCEEDED)) {
        tempErrors.push(DropAreaErrors.MAX_NUMBER_OF_FILES_EXCEEDED);
      }
    } else {
      if (tempErrors.includes(DropAreaErrors.MAX_NUMBER_OF_FILES_EXCEEDED)) {
        tempErrors.splice(errors.indexOf(DropAreaErrors.MAX_NUMBER_OF_FILES_EXCEEDED), 1);
      }
    }

    setErrors(tempErrors);
    setFiles(newFilesArray.slice(0, maxNumOfFiles));
  };

  const onDeleteClick = (index: number, id: string) => {
    const fileToBeDeleted = uploadedFiles.find(file => file.fileId === id);
    if (fileToBeDeleted) {
      dispatch(deleteFile({ fileId: fileToBeDeleted.fileId })).then((value: PayloadAction<any>) => {
        if (value.payload.completed === true) {
          const tempFiles = [...files.slice(0, index), ...files.slice(index + 1)];
          setFiles(tempFiles);
          if (!tempFiles.length) {
            onFilesChange([]);
          }
        }

        const tempProgressArray = [...filesProgress];
        tempProgressArray.splice(index, 1);
        setFilesProgress(tempProgressArray);
      });
    }
  };

  useEffect(() => {
    if (files.length > 0) {
      let tempProgressArray: number[] = [];
      tempProgressArray = [...filesProgress, ...new Array(Math.max(files.length - filesProgress.length, 0)).fill(0)];
      files.forEach((file, index) => {
        if (tempProgressArray.length > 0) {
          if (tempProgressArray[index] === 0) {
            let fileToUpload = file;

            if (file.type !== FileTypes.PDF) {
              // let newImage1: HTMLImageElement;
              const newImage1 = new Image();
              newImage1.src = URL.createObjectURL(file);
              newImage1.onload = () => {
                fileToUpload = new File([convertToPdf([file], [newImage1.height, newImage1.width])[0]], fileNameFun(file.name) + ".pdf", {
                  type: FileTypes.PDF,
                });
                dispatch(
                  uploadFile({
                    file: fileToUpload,
                    callback: progress => {
                      tempProgressArray = [...tempProgressArray.slice(0, index), progress, ...tempProgressArray.slice(index + 1)];
                      setFilesProgress(tempProgressArray);
                    },
                  })
                ).then(action => {
                  if (action.payload === null) {
                    setErrors([DropAreaErrors.SOMETHING_WENT_WRONG]);
                  }
                });
              };
            } else {
              dispatch(
                uploadFile({
                  file: fileToUpload,
                  callback: progress => {
                    tempProgressArray = [...tempProgressArray.slice(0, index), progress, ...tempProgressArray.slice(index + 1)];
                    setFilesProgress(tempProgressArray);
                  },
                })
              ).then(action => {
                if (action.payload === null) {
                  setErrors([DropAreaErrors.SOMETHING_WENT_WRONG]);
                }
              });
            }
          }
        }
      });
    }
  }, [files]);

  useEffect(() => {
    onError(errors);
  }, [errors]);

  useEffect(() => {
    if (uploadedFiles.length) onFilesChange(uploadedFiles.map(file => file.fileId));
  }, [uploadedFiles]);

  useEffect(() => {
    if (filesProgress.every(f => f === 100)) {
      onProgress(100);
    } else {
      const tempPrgs = filesProgress.filter(f => f < 100);
      onProgress((tempPrgs.reduce((s, a) => s + a, 0) / (tempPrgs.length * 100)) * 100);
    }
  }, [filesProgress]);

  const FileGrid = () => {
    const fileComponentMaxHeight = 50;

    return (
      <div style={{ display: "grid" }}>
        {filePreviewVisible
          ? files.map((file, index) => {
              if (hidePreviewAfterUpload && filesProgress[index] === 100) return null;
              if (file.type.includes("image")) {
                let remoteFileObj: DsFile | undefined;
                if (filesProgress[index] === 100) {
                  remoteFileObj = uploadedFiles.find(remoteFile => remoteFile.originalFilename.split(".")[0] === file.name.split(".")[0]);
                }
                return (
                  <div className={"p-2"} key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {!hideImageInPreview ? (
                      <div style={{ width: fileComponentMaxHeight }}>
                        {filesProgress[index] === 100 ? <img src={pdfIcon} style={{ maxHeight: fileComponentMaxHeight }} alt="" /> : null}
                      </div>
                    ) : null}
                    <div className="px-2 w-full">
                      <p className="text-sm text-docsigna-blue-dark font-medium">{file.name}</p>
                      {filesProgress[index] !== 100 ? <LinearProgress variant={"determinate"} value={filesProgress[index] || 0} /> : null}
                    </div>
                    {filesProgress[index] === 100 ? (
                      <div style={{ cursor: "pointer", float: "right" }} onClick={() => onDeleteClick(index, remoteFileObj?.fileId || "")}>
                        <DeleteOutline htmlColor={colors.Orange} />
                      </div>
                    ) : null}
                  </div>
                );
              }
              if (file.type === FileTypes.PDF) {
                const remoteFileObj = uploadedFiles.find(remoteFile => remoteFile.originalFilename.split(".")[0] === file.name.split(".")[0]);
                return (
                  <div className={"p-2"} key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {!hideImageInPreview ? (
                      <div style={{ width: fileComponentMaxHeight }}>
                        {filesProgress[index] === 100 ? <img src={pdfIcon} style={{ maxHeight: fileComponentMaxHeight }} alt="" /> : null}
                      </div>
                    ) : null}
                    <div className="px-2 w-full">
                      <p className="text-sm text-docsigna-blue-dark font-medium">{file.name}</p>
                      {filesProgress[index] !== 100 ? <LinearProgress variant={"determinate"} value={filesProgress[index] || 0} /> : null}
                    </div>
                    {filesProgress[index] === 100 ? (
                      <div style={{ cursor: "pointer", float: "right" }} onClick={() => onDeleteClick(index, remoteFileObj?.fileId || "")}>
                        <DeleteOutline htmlColor={colors.Orange} />
                      </div>
                    ) : null}
                  </div>
                );
              }
              const remoteFileObj = uploadedFiles.find(remoteFile => remoteFile.originalFilename.split(".")[0] === file.name.split(".")[0]);
              return (
                <div className={"p-2"} key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {!hideImageInPreview ? <div style={{ width: fileComponentMaxHeight }}></div> : null}
                  <div className="px-2 w-full">
                    <p className="text-sm text-docsigna-blue-dark font-medium">{file.name}</p>
                    {filesProgress[index] !== 100 ? <LinearProgress variant={"determinate"} value={filesProgress[index] || 0} /> : null}
                  </div>
                  {filesProgress[index] === 100 ? (
                    <div style={{ cursor: "pointer", float: "right" }} onClick={() => onDeleteClick(index, remoteFileObj?.fileId || "")}>
                      <DeleteOutline htmlColor={colors.Orange} />
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: File[]) => {
      onFileDrop(files);
    },
    disabled: loading,
    //noClick: true, // Prevent the default click behavior
  });

  return (
    <div>
      {hideDropAreaAfterUploadLimit && uploadedFiles.length === maxNumOfFiles ? null : children ? (
        <div {...getRootProps({ className: "dropzone" })}>
          <>
            <input {...getInputProps()} />
            {children}
          </>
        </div>
      ) : (
        <div {...getRootProps({ className: "dropzone outline-none" })}>
          <input {...getInputProps()} />
          <div
            style={{
              marginTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "0.3s",
            }}
            className={`h-20 rounded ${isDarkBg ? "bg-transparent border border-docsigna-blue-dark" : "bg-docsigna-pink-light"}`}>
            <AttachFileIcon htmlColor={colors.DarkBlue} className="rotate-[45deg]" />
            {dropAreaText ? (
              <p className="ml-1 font-medium">Upload {dropAreaText} here</p>
            ) : (
              <p className="ml-1 font-medium">Upload attachment{maxNumOfFiles > 1 ? "s" : ""} here</p>
            )}
          </div>
        </div>
      )}
      <FileGrid />
    </div>
  );
};

export default DropArea;
