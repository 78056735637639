import React, { useContext, useEffect } from "react";
import { OfficeCard, ServiceCard } from "../../components";
import { Office } from "../../models/office.model";
import services from "../../sample-data/services.json";
import { Link, useLocation } from "react-router-dom";
import HomePageHeader from "./HomePageHeader";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { SESSION_STORAGE_REQUEST_DATA } from "../../utils/constants";

type LocationState = {
  state: Office;
};
const SingleOffice = () => {
  const toast = useContext(ToastContext);
  const { state } = useLocation() as LocationState;
  const office = state;
  useEffect(() => {
    sessionStorage.removeItem(SESSION_STORAGE_REQUEST_DATA);
  }, []);
  return (
    <main>
      <HomePageHeader />
      <section className="patients__section patients__banner home-screen blur-bg">
        <div className="container md:flex pt-10 md:pt-0">
          {/* left card started */}
          <div className="flex justify-center md:w-1/2 w-full">
            <div className="w-full md:w-80">
              <OfficeCard
                title={office.name}
                address={`${office.street} ${office.city} ${office.state}`}
                fax={office.fax}
                phone={office.phone}
                primaryContact=""
                hidePrimaryContact={true}
              />
              <div className="flex gap-7 justify-center md:justify-between mt-5 overflow-hidden">
                <div className="flex gap-2 flex-col">
                  <p className="font-medium text-xs">Not your doctor?</p>
                  <Link to="/" className="text-xs text-docsigna-orange underline font-medium">
                    Try a different search
                  </Link>
                </div>
                <div className="bg-docsigna-blue-dark w-[1px]" />
                <div className="flex gap-2 flex-col">
                  <p className="font-medium text-xs">Your doctor not listed?</p>
                  <p className="text-xs text-docsigna-orange underline font-medium cursor-pointer" onClick={() => toast?.openToast()}>
                    Recommend your doctor
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* left card ended */}

          {/* right services started */}
          <div className="flex justify-center md:w-1/2 w-full relative">
            <div className="w-full md:w-80 px-6 sm:px-14 md:px-0 relative mt-9">
              {services.map(service => (
                <ServiceCard
                  serviceId={service.serviceId}
                  key={service.serviceId}
                  serviceName={service.serviceName}
                  serviceDescription={service.serviceDescription}
                />
              ))}
            </div>
          </div>
          {/* right services ended */}
        </div>
      </section>
    </main>
  );
};

export default SingleOffice;
