// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactNode } from "react";

export enum Theme {
  "Dark" = "dark",
  "Light" = "light",
}

export enum ToastVariants {
  Success = "success",
  Warn = "warn",
  Error = "error",
}

export interface TypographyProps {
  children: ReactNode;
  AdditionalClassNames?: string;
}

export interface TableDataType {
  //TODO: check type for dynamic created jsx elements
  data?: (ReactNode | string)[];
  childElements?: (JSX.Element | string)[];
  extraClassesRow?: string[];
  tooltipOptions?: string[];
  handleTooltipClick?: (option: string, id: string) => void;
  id?: string;
  onRowClick?: (id?: string) => void;
  tooltipVisible: boolean;
  handleOpenTooltip: () => void;
  handleCloseTooltip: () => void;
  cursorType?: string;
  disabledOptions?: string[];
  extraClassesTooltip?: string;
  allowHover?:boolean;
  parentExtraClasses?: string;
}

export interface TableHeaderType {
  title: string;
  index: number;
  sortIcon: JSX.Element | null;
  handleSort: (index: number) => void;
  extraClassesHeader: string;
  isClickable?: boolean;
  isCheckbox?: boolean;
  selectAll?: (checked: boolean) => void;
  isChecked?: boolean;
  isCheckboxDisabled?:boolean;
  titleColor?: string; 
}

export interface headersType {
  title: string;
  extraClasses: string;
  sortKey: string;
  isClickable: boolean;
  isCheckbox?: boolean;
  titleColor?: string; 
}

export interface ConfirmationDialogType {
  open: boolean;
  title?: string;
  description: React.ReactNode | string;
  successButtonText: string;
  handleSuccess: () => void;
  failureButtonText: string;
  handleFailure: () => void; // TODO :: change type to (params) => {} when confirmed
  hideActionButtons?: boolean;
  maxWidth?: "sm" | "lg" | "md";
  showCrossIcon?:boolean;
  titleBold?: boolean;
  actionButtonPosition?: 'Center' | 'Right' | 'Left' | 'Split'
  canClickOutSide?: boolean;
  loading?: boolean;
}

export default interface Office {
  officeName: string;
  officeAddress: string;
  officePhone: string;
  officeFax: string;
  officePrimaryContact: string;
  officePrimaryContactEmail: string;
  officeNumOfUsers: 0;
}

export enum FormType {
  CreateAccount = "create-account",
  ContinueGuest = "continue-guest",
}

// Types added from https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
export enum FileTypes {
  JPEG = "image/jpeg",
  JPG = "image/jpg",
  PNG = "image/png",
  WEBP = "image/webp",
  GIF = "image/gif",
  SVG = "image/svg+xml",
  PDF = "application/pdf",
  CSV = "application/csv",
}

export enum DropAreaErrors {
  INVALID_FILE_TYPE,
  MAX_NUMBER_OF_FILES_EXCEEDED,
  FILE_SIZE_EXCEEDED,
  SOMETHING_WENT_WRONG,
}

export enum RequestStatesOptions {
  "Pending-Verification" = "Pending Verification",
  "Pending-Payment" = "Pending Payment",
  "In-Progress" = "In Progress",
  "Waiting-for-Signature" = "Waiting for Signature",
  "Final-Review" = "Final Review",
  "Complete" = "Complete",
  "Cancelled" = "Cancelled",
}

export enum UserTypeLevel {
  "system-admin",
  "office-admin",
  "accounting",
  "user",
  "patient",
}
