import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextInput } from "../../components";
import { colors } from "../../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FormType } from "../../models";
import { validatePass } from "../../utils";
const EmailVerified = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const userType = useSelector((state: RootState) => state.onBoarding.user);
  const navigate = useNavigate();

  useEffect(() => {
    setValidPassword(validatePass(password));
  }, [password]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="flex justify-between w-full">
        <div className="w-full max-w-2xl mx-auto mt-16 md:mt-24 px-10 md:px-0">
          <h3 className="text-3xl font-light mb-2">Thanks - your email is verified</h3>
          <p className="text-base mb-5">
            {userType === FormType.CreateAccount
              ? "Thanks for verifying your email. Please log in with your password."
              : "Thanks for verifying your email. Please click below to continue."}
          </p>
          <div style={{ color: colors.Gray }} className="py-2">
            <div className="tab-content py-5 w-80">
              {userType === FormType.CreateAccount ? (
                <div className="mb-6">
                  <label className="text-sm text-gray-700 block w-full">
                    <span className="text-red-500">*</span>
                  </label>
                  <TextInput onChangeFunc={e => setPassword(e.currentTarget.value)} isPassword value={password} placeholder="Enter Password" />
                </div>
              ) : null}
              {error ? <p style={{ color: colors.Red }}>Incorrect Password.</p> : null}
              <Button
                text={userType === FormType.CreateAccount ? "Log in" : "Continue"}
                width="full"
                disabled={!validPassword && userType === FormType.CreateAccount}
                onClickFunc={() => navigate("/onboarding/submit-request")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerified;
