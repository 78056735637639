import React, { useContext } from "react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";

import Marquee from "react-fast-marquee";

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

import { Button, ConfirmationDialog } from "../components";
import TermsAndConditions from "./Guest/TermsAndCondition";
import PrivacyPolicy from "./Guest/PrivacyPolicy";

import { Dialog, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../utils/constants";

import "../assets/css/doctors.css";

import Logo from "../assets/images/DocnoteLogo.svg";
import Banner from "../assets/images/doctors__banner.png";
// import Placeholder from "../assets/images/doctors__placeholder-Image.png";
import HappyPhysicians from "../assets/images/doctors__happy-physicians.png";
import HappyPhysicians2 from "../assets/images/doctors__happy-physicians2.png";
import DigitalFirst from "../assets/images/doctors__digital-first.png";
import CentralizedExperience from "../assets/images/doctors__centralized-experience.png";
import SeamlessConnectivity from "../assets/images/doctors__seamless-connectivity.png";
import AutomatedPayment from "../assets/images/doctors__automated-payment.png";
import ProgressTracking from "../assets/images/doctors__progress-tracking.png";
import ElectronicDelivery from "../assets/images/doctors__electronic-delivery.png";
import SystemRecord from "../assets/images/doctors__system-of-record.png";
import PrivacySecurity from "../assets/images/doctors__privacy-security.png";
import FareFeeStructure from "../assets/images/doctors__fare-fee-structure.png";
import Next from "../assets/images/doctors__next.svg";
import Prev from "../assets/images/doctors__prev.svg";
import { formatPhoneNumber, validateEmail } from "../utils";
import { Phone_Fax_Regex } from "../utils/constants";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { submitWebsiteContactForm } from "../redux/slices/OnBoardingSlice";
import { ToastContext } from "../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../models";

gsap.registerPlugin(ScrollTrigger);

export interface ContanctFormFieldTypes {
  id: string;
  type: "text" | "email" | "phone" | "textarea";
  placeholder: string;
  required: boolean;
  errorMesage: string;
  errorMesage1?: string;
  label: string;
  value: string;
  isValid: boolean;
}

const contanctFormData: ContanctFormFieldTypes[] = [
  {
    id: "name",
    type: "text",
    label: "Name",
    placeholder: "Name",
    required: true,
    errorMesage: "Please provide a name",
    value: "",
    isValid: false,
  },
  {
    id: "email",
    type: "email",
    label: "Email",
    placeholder: "Email",
    required: true,
    errorMesage: "Please provide an email address",
    value: "",
    isValid: false,
  },
  {
    id: "phone",
    type: "phone",
    label: "Phone",
    placeholder: "Phone",
    required: true,
    errorMesage: "Please provide a phone number",
    value: "",
    isValid: false,
  },
  {
    id: "officeName",
    type: "text",
    label: "Office Name",
    placeholder: "Office Name",
    required: true,
    errorMesage: "Please provide a office name",
    value: "",
    isValid: false,
  },
  {
    id: "message",
    type: "textarea",
    label: "Message",
    placeholder: "Message",
    required: true,
    errorMesage: "Please provide a message",
    value: "",
    isValid: false,
  },
];

export default function Doctors() {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const [open, setOpen] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [contactFormFields, setContactFormFields] = useState<ContanctFormFieldTypes[]>(contanctFormData);
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const { innerWidth: width, innerHeight: height } = window;
  const [openCalendar, setOpenCalendar] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };
  const getMissionSection = useRef<HTMLInputElement | null>(null);
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabClick = (e: any, tab: any) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  const validateForm = () => {
    return contactFormFields
      .map(v => {
        if (v.required === false) return true;
        return v.isValid;
      })
      .every(v => v);
  };

  const handleChange = (id: string, val: string) => {
    const oldValues = [...contactFormFields];
    const index = oldValues.findIndex(a => a.id === id);
    let value = val;
    let valid = value?.trim() !== "";
    switch (id) {
      case "phone":
        value = formatPhoneNumber(value as string);
        valid = Phone_Fax_Regex.test(value);
        break;
      case "email":
        valid = validateEmail((value as string).trim());
        break;
      default:
        break;
    }
    setContactFormFields([
      ...oldValues.slice(0, index),
      {
        ...oldValues[index],
        value: value,
        isValid: valid,
      },
      ...oldValues.slice(index + 1),
    ]);
  };

  const handleSubmit = async () => {
    setShowErrors(true);
    setLoading(true);
    const formValid = validateForm();
    if (formValid) {
      const res = await dispatch(
        submitWebsiteContactForm({
          name: contactFormFields[0].value,
          email: contactFormFields[1].value,
          phone: contactFormFields[2].value,
          office: contactFormFields[3].value,
          message: contactFormFields[4].value,
        })
      );
      if (res) {
        toast?.openToast("Form submitted successfully.", 2000, ToastVariants.Success);
        setShowErrors(false);
        setContactFormFields(contanctFormData);
      } else {
        toast?.openToast("Something went wrong. Try again!", 2000, ToastVariants.Error);
      }
    }
    setLoading(false);
  };

  const items = [
    {
      quote: "No more awkward non-medical conversations!",
    },
    {
      quote: "We've minimized so much wasted travel",
    },
    {
      quote: "We finally have control of our form workflow",
    },
    {
      quote: "It's growing my annual fee program",
    },
    {
      quote: "It's a breath of fresh air for my bookkeeper",
    },
    {
      quote: "We're billing over $1,500 a month now and are managing it with minimal effort",
    },
    {
      quote: "My MOA says it's better than a raise",
    },
  ];
  function Item(props: any) {
    return (
      <Paper>
        <h2 className="doctors__title">{props.item.quote}</h2>
      </Paper>
    );
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const graphics = document.querySelector(".doctors__future-relationship .doctors__future-relationship-graphics");
    const circle1 = document.querySelector(".doctors__future-relationship .doctors__future-relationship-graphics-circle-1");
    const circle1Data = document.querySelector(
      ".doctors__future-relationship .doctors__future-relationship-graphics-circle-1 .doctors__future-relationship-graphics-circle-data p"
    );
    gsap.set(circle1, {
      x: () => -1 * (window.innerWidth * 0.2),
      y: () => 1 * (window.innerWidth * 0.2),
      opacity: 0,
    });
    gsap.set(circle1Data, {
      x: () => -50,
      y: () => 50,
      opacity: 0,
    });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: graphics,
          start: "top bottom",
          end: "bottom center",
          scrub: true,
          markers: false,
          invalidateOnRefresh: true, // handle resize
        },
      })
      .to(circle1, {
        x: () => 0,
        y: () => 0,
        opacity: 1,
      })
      .to(circle1Data, {
        x: () => 0,
        y: () => 0,
        opacity: 1,
      });

    const circle2 = document.querySelector(".doctors__future-relationship .doctors__future-relationship-graphics-circle-2");
    const circle2Data = document.querySelector(
      ".doctors__future-relationship .doctors__future-relationship-graphics-circle-2 .doctors__future-relationship-graphics-circle-data p"
    );
    gsap.set(circle2, {
      y: () => -1 * (window.innerWidth * 0.2),
      opacity: 0,
    });
    gsap.set(circle2Data, {
      y: () => -50,
      opacity: 0,
    });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: graphics,
          start: "top bottom",
          end: "bottom center",
          scrub: true,
          markers: false,
          invalidateOnRefresh: true, // handle resize
        },
      })
      .to(circle2, {
        y: () => 0,
        opacity: 1,
      })
      .to(circle2Data, {
        y: () => 0,
        opacity: 1,
      });

    const circle3 = document.querySelector(".doctors__future-relationship .doctors__future-relationship-graphics-circle-3");
    const circle3Data = document.querySelector(
      ".doctors__future-relationship .doctors__future-relationship-graphics-circle-3 .doctors__future-relationship-graphics-circle-data p"
    );
    gsap.set(circle3, {
      x: () => 1 * (window.innerWidth * 0.2),
      y: () => 1 * (window.innerWidth * 0.2),
      opacity: 0,
    });
    gsap.set(circle3Data, {
      x: () => 50,
      y: () => 50,
      opacity: 0,
    });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: graphics,
          start: "top bottom",
          end: "bottom center",
          scrub: true,
          markers: false,
          invalidateOnRefresh: true, // handle resize
        },
      })
      .to(circle3, {
        x: () => 0,
        y: () => 0,
        opacity: 1,
      })
      .to(circle3Data, {
        x: () => 0,
        y: () => 0,
        opacity: 1,
      });
  }, []);
  return (
    <main className="doctors__body">
      <header className="doctors__header">
        <div className="container flex justify-between items-center">
          <Link to={"/"}>
            <img src={Logo} alt="" />
          </Link>
          <span
            className="doctors__button cursor-pointer"
            onClick={e => {
              e.preventDefault();
              setOpenCalendar(true);
            }}>
            Book a Demo
          </span>
        </div>
      </header>
      <section className="doctors__banner">
        <div className="container doctors__banner-wrapper">
          <img src={Banner} alt="" className="doctors__banner-image" />
          <div className="doctors__banner-content">
            <h6 className="doctors__title">The all-in-one platform for uninsured medical services</h6>
            <h1 className="doctors__title">
              Reinventing <br />
              the Uninsured <br />
              Experience
            </h1>
            <p>Imagine an experience everyone will talk about in a good way</p>
            <div className="sm:flex sm:items-center block space-x-0 sm:space-x-6 space-y-2 sm:space-y-0">
              <Button
                text="Book a Live Consultation"
                onClickFunc={() => setOpenCalendar(true)}
                width="fit"
                AdditionalClassNames={"demo-button-big"}
              />
              <br className="block sm:hidden" />
              <Link
                to="/"
                className="doctors__link"
                onClick={e => {
                  e.preventDefault();
                  window.scrollTo({ top: getMissionSection.current?.offsetTop, left: 0, behavior: "smooth" });
                }}>
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="doctors__section doctors__mission" id="doctors__mission" ref={getMissionSection}>
        <div className="container">
          <h6 className="doctors__title">Our Mission</h6>
          <p>
            To make the uninsured experience a <br />
            convenient and efficient one that delights
          </p>
        </div>
      </section>
      <section className="doctors__section doctors__benefits">
        <div className="container">
          <h6 className="doctors__title">Uninsured Services Program Governance</h6>
          <h3 className="doctors__title">The Docnote Difference</h3>
          <div className="doctors__tabs">
            <div className="doctors__tabs-nav">
              <ul>
                <li className={activeTab === "tab1" ? "active" : ""}>
                  <a href="/" onClick={e => handleTabClick(e, "tab1")}>
                    Physician Benefits
                  </a>
                </li>
                <li className={activeTab === "tab2" ? "active" : ""}>
                  <a href="/" onClick={e => handleTabClick(e, "tab2")}>
                    Patient Benefits
                  </a>
                </li>
              </ul>
            </div>
            {activeTab === "tab1" && (
              <div className="doctors__tabs-content">
                <div className="flex items-center flex-wrap">
                  <div className="w-full lg:w-1/2 pr-0 lg:pr-10 pb-10 lg:pb-0">
                    <h4 className="doctors__title">Ensure Fair Remuneration</h4>
                    <p>
                      Get compensated for all eligible uninsured services rendered in an upfront and efficient manner that never misses an opportunity
                      and relieves the administrative burden.
                    </p>
                    <h4 className="doctors__title">Maintain Value and Integrity</h4>
                    <p>
                      Eliminate awkward payment conversations and collection practices that compromise the integrity of the physician-patient
                      relationship, overwhelm staff, and undervalue a physician&apos;s time.
                    </p>
                  </div>
                  <div className="w-full lg:w-1/2 pl-0 lg:pl-10">
                    <img src={HappyPhysicians} alt="" className="w-full" />
                  </div>
                </div>
              </div>
            )}
            {activeTab === "tab2" && (
              <div className="doctors__tabs-content">
                <div className="flex items-center flex-wrap">
                  <div className="w-full lg:w-1/2 pr-0 lg:pr-10 pb-10 lg:pb-0">
                    <h4 className="doctors__title">Instant Access Phenomenon</h4>
                    <p>
                      Patients can remotely submit requests at their convenience, avoiding the time and trouble of scheduling office visits. This
                      preferred approach mitigates stress and anxiety.
                    </p>
                    <h4 className="doctors__title">Progress Tracking and Status Updates</h4>
                    <p>
                      Real-time reporting on the status of form completion requests brings a level of visibility that patients have come to expect in
                      the digital age. A first in Canadian health care, it is a concierge standard.
                    </p>
                  </div>
                  <div className="w-full lg:w-1/2 pl-0 lg:pl-10">
                    <img src={HappyPhysicians2} alt="" className="w-full" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="doctors__section doctors__future-relationship">
        <div className="container">
          <h6 className="doctors__title">Creating Shared Value To Sustain Our Health Care System</h6>
          <div className="doctors__future-relationship-graphics">
            <div className="doctors__future-relationship-graphics-circle doctors__future-relationship-graphics-circle-1">
              <div className="doctors__future-relationship-graphics-circle-data">
                <p>
                  Professional <br />
                  Self-worth
                </p>
              </div>
            </div>
            <div className="doctors__future-relationship-graphics-circle doctors__future-relationship-graphics-circle-2">
              <div className="doctors__future-relationship-graphics-circle-data">
                <p>
                  Convenience and <br />
                  Efficiency
                </p>
              </div>
            </div>
            <div className="doctors__future-relationship-graphics-circle doctors__future-relationship-graphics-circle-3">
              <div className="doctors__future-relationship-graphics-circle-data">
                <p>
                  Mental <br />
                  Well-being
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="doctors__section doctors__get-paid">
        <Marquee>
          <h2 className="doctors__title-big">
            Sick Notes <span className="doctors__tag-spacer"></span> Medical/Legal Reports <span className="doctors__tag-spacer"></span> CPP
            Disability Medical <span className="doctors__tag-spacer"></span>
          </h2>
        </Marquee>
        <Marquee direction="right">
          <h2 className="doctors__title-big">
            Prescription Renewals <span className="doctors__tag-spacer"></span> Attending physician&apos;s statement{" "}
            <span className="doctors__tag-spacer"></span> EI Compassionate Care Benefit <span className="doctors__tag-spacer"></span>
          </h2>
        </Marquee>
        <Marquee>
          <h2 className="doctors__title-big">
            Driver&apos;s Medical Exam (MTO) <span className="doctors__tag-spacer"></span> Federal Disability Tax Credit{" "}
            <span className="doctors__tag-spacer"></span> CRA Medical Expense Tax Credit <span className="doctors__tag-spacer"></span>
          </h2>
        </Marquee>
        <Marquee direction="right">
          <h2 className="doctors__title-big">
            CPP Disability Medical <span className="doctors__tag-spacer"></span> Sick Notes <span className="doctors__tag-spacer"></span>{" "}
            Medical/Legal Reports <span className="doctors__tag-spacer"></span>
          </h2>
        </Marquee>
      </section>
      <section className="doctors__section doctors__unlike-other-platform">
        <div className="container">
          <h6 className="doctors__title">All-In-One-Platform Vs Point Solutions</h6>
          <p>More than a point solution, Docnote takes you all the way.</p>
          <div className="doctors__other-platforms">
            <div className="doctors__other-platform">
              <img src={DigitalFirst} alt="" className="m-auto mb-5" />
              Digital First
            </div>
            <div className="doctors__other-platform">
              <img src={CentralizedExperience} alt="" className="m-auto mb-5" />
              Centralized Experience
            </div>
            <div className="doctors__other-platform">
              <img src={SeamlessConnectivity} alt="" className="m-auto mb-5" />
              Seamless Connectivity
            </div>
            <div className="doctors__other-platform">
              <img src={AutomatedPayment} alt="" className="m-auto mb-5" />
              Automated Payment
            </div>
            <div className="doctors__other-platform">
              <img src={ProgressTracking} alt="" className="m-auto mb-5" />
              Progress Tracking
            </div>
            <div className="doctors__other-platform">
              <img src={ElectronicDelivery} alt="" className="m-auto mb-5" />
              Electronic Delivery
            </div>
            <div className="doctors__other-platform">
              <img src={SystemRecord} alt="" className="m-auto mb-5" />
              System of Record
            </div>
            <div className="doctors__other-platform">
              <img src={PrivacySecurity} alt="" className="m-auto mb-5" />
              Privacy & Security
            </div>
          </div>
        </div>
      </section>
      <section className="doctors__section doctors__testimonials">
        <div className="container">
          <Carousel NextIcon={<img src={Next} width={38} alt="" />} PrevIcon={<img src={Prev} width={38} alt="" />}>
            {items.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
        </div>
      </section>
      <section className="doctors__section doctors__fair">
        <div className="container">
          <div className="doctors__fair-wrapper">
            <div className="flex items-center flex-wrap">
              <div className="w-full lg:w-1/2 pr-0 lg:pr-10 pb-10 lg:pb-0">
                <h3 className="doctors__title">Fair Fee Structure</h3>
                <p>
                  No upfront investment is required to use Docnote and physicians are not bound by any subscription costs or fixed term agreements.
                  Docnote is a pay-as-you-go service for patients designed with flexibility and convenience in mind.
                </p>
              </div>
              <div className="w-full lg:w-1/2 pl-0 lg:pl-10">
                <img src={FareFeeStructure} alt="" className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="doctors__section doctors__schedule-call">
        <div className="container">
          <h4 className="doctors__title">
            Ready to realize the full potential of <br />
            your uninsured services program?
          </h4>
          <Button text="Schedule a Call" onClickFunc={() => setOpenCalendar(true)} width="fit" AdditionalClassNames={"demo-button-big"} />
        </div>
      </section>
      <section className="doctors__section doctors__join-community">
        <div className="container">
          <div className="doctors__join-community-wrapper">
            {/* <div className="doctors__join-community-wrapper-inside">
                            <h6 className="doctors__title">join the community</h6>
                            <p>Sign up today to be part of a growing community of physicians and <br />MOAs dedicated to staying well-informed and doing things differently!</p>
                            <ul>
                                <li>
                                    <input type="text" placeholder="Your email*" className="doctors__inputText" />
                                </li>
                                <li>
                                    <input type="text" placeholder="Your medical office address" className="doctors__inputText" />
                                </li>
                                <li className="flex justify-between items-center flex-wrap pb-6 border-b border-white">
                                    <label className="checkbox doctors__inputCheckbox mb-6 lg:mb-0">I agree to receive Docnote updates, announcements and offers
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                    </label>
                                    <button className="doctors__button">Sign Up</button>
                                </li>
                                <li>
                                    <p>By clicking Sign Up you&apos;re confirming that you agree with our <a href="/">Terms and Conditions</a>.</p>
                                </li>
                            </ul>
                        </div> */}
            <div className="doctors__join-community-wrapper-inside">
              <h6 className="doctors__title">Contact us</h6>
              <ul>
                {contactFormFields?.map(item => {
                  return (
                    <li key={item.id} className="text-left">
                      {item.type !== "textarea" ? (
                        <input
                          type="text"
                          placeholder={`${item.placeholder}*`}
                          className="doctors__inputText shadow-none outline-none"
                          value={item.value}
                          onChange={e => handleChange(item.id, e.target.value)}
                        />
                      ) : (
                        <textarea
                          placeholder={`${item.placeholder}*`}
                          className="doctors__inputTextarea shadow-none outline-none resize-none"
                          value={item.value}
                          onChange={e => handleChange(item.id, e.target.value)}
                        />
                      )}
                      <span className="text-white font-medium leading-normal block">{showErrors && !item.isValid ? item.errorMesage : ""}</span>
                    </li>
                  );
                })}
              </ul>
              <div className="flex justify-center">
                <div className="w-42">
                  <Button
                    text={loading ? "Submitting..." : "Submit"}
                    onClickFunc={handleSubmit}
                    disabled={loading}
                    AdditionalClassNames="doctors__button py-[0.75rem] px-[1.5rem] leading-[1.25rem]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="doctors__footer">
        <div className="container">
          <div className="footer-left font-medium">Copyright © 2023 Docnote. All rights reserved.</div>
          <div className="footer-right">
            <Link to={"/"} className="font-medium">
              For Patients
            </Link>
            <span>|</span>
            <a
              href="/"
              className="font-medium"
              onClick={e => {
                e.preventDefault();
                handleClickOpen();
              }}>
              Terms of Services
            </a>
            <span>|</span>
            <a
              href="/"
              className="font-medium"
              onClick={e => {
                e.preventDefault();
                handleClickOpenPrivacyPolicy();
              }}>
              Privacy Policy
            </a>
          </div>
        </div>
      </footer>
      <Dialog open={openCalendar} className="demo__dialog relative">
        <DialogActions className="absolute top-3 right-4 cursor-pointer z-10">
          <CloseIcon htmlColor={colors.DarkBlue} style={{ fontSize: "28px" }} onClick={() => setOpenCalendar(false)} />
        </DialogActions>
        <iframe
          title="Booking Calender"
          src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1wLdrFSM3svULSrd2DZVA1QOGHMjMNvBKcIPJ4c1tGVbICIh_hnBCXkXp-CjNk-pXwoyqAoJaT"
          width={width < 769 ? width : "700"}
          height={width < 769 ? height : "580"}
          className="bg-white rounded-3xl"
        />
      </Dialog>
      <ConfirmationDialog
        maxWidth="lg"
        successButtonText=""
        failureButtonText="Close"
        open={open}
        description={<TermsAndConditions showCheckbox={false} />}
        handleSuccess={() => {}}
        handleFailure={handleClose}
      />
      <ConfirmationDialog
        maxWidth="lg"
        successButtonText=""
        failureButtonText="Close"
        open={openPrivacyPolicy}
        description={<PrivacyPolicy />}
        handleSuccess={() => {}}
        handleFailure={handleClosePrivacyPolicy}
      />
    </main>
  );
}
