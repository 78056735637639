import React, { ChangeEvent, useRef } from "react";
import Autocomplete from "react-google-autocomplete";
import { colors } from "../../utils/constants";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

interface GooglePlaceSearchComponentPropType {
  onPlaceSelected: (places: any | null) => void;
  countries?: string[];
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  isError?: boolean;
  errorMsg?: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  disabled?: boolean;
  extraClasses?: string;
}

const GooglePlaceSearchComponent = ({
  onPlaceSelected,
  countries = ["ca", "us"],
  placeholder = "Address",
  label = "Address",
  isRequired = false,
  errorMsg = "",
  isError = false,
  onChange = () => {},
  defaultValue = "",
  extraClasses = "",
}: GooglePlaceSearchComponentPropType) => {
  const autoCompleteRef = useRef(null);
  return (
    <>
      <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-1">
        {label}
        {isRequired ? <span className="text-red-500">*</span> : null}
      </label>
      <Autocomplete
        itemType="text"
        className={`text-base text-docsigna-blue-dark font-medium block w-full rounded-md h-[44px] border border-docsigna-blue-dark shadow-none outline-none bg-transparent ${extraClasses}`}
        apiKey={API_KEY}
        ref={autoCompleteRef}
        onEmptiedCapture={onChange}
        defaultValue={defaultValue}
        onChange={onChange}
        onPlaceSelected={place => onPlaceSelected(place)}
        options={{
          types: ["address"],
          componentRestrictions: { country: countries },
        }}
      />
      {isError ? <p style={{ color: colors.Red }}>{errorMsg}</p> : null}
    </>
  );
};

export default GooglePlaceSearchComponent;
