import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { formatCurrency } from "../../utils";

const CashFlowReport = ({ isPdf = false, selectedMonth = "" }: { isPdf?: boolean; selectedMonth?: string }) => {
  const { financeReportTabData } = useSelector((state: RootState) => state.finance);
  return (
    <div className="break-page-before">
      <div className="w-full">
        <h3 className="heading font-bold uppercase">Cash Flow</h3>
      </div>
      <div className={`p-5 bg-docsigna-pink-light rounded border border-docnote-pink-dark`}>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark pb-1">
          <div className="col-span-2">
            <p className="text-base font-medium">Credits</p>
          </div>
          <div>
            <p className="text-base font-medium">With HST</p>
          </div>
          <div>
            <p className="text-base font-medium">Without HST</p>
          </div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">Revenue Collected by Docnote</p>
          </div>
          <div>
            <p className="text-sm">
              {financeReportTabData?.withHstDsFeeSubTotal !== undefined
                ? formatCurrency((financeReportTabData?.withHstDsFeeSubTotal / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div>
            <p className="text-sm">
              {financeReportTabData?.withoutHstDsFeeSubTotal !== undefined
                ? formatCurrency((financeReportTabData?.withoutHstDsFeeSubTotal / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">HST on above item</p>
          </div>
          <div>
            <p className="text-sm">
              {financeReportTabData?.withHstTotal !== undefined
                ? formatCurrency((financeReportTabData?.withHstTotal / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div>
            <p className="text-sm">N/A</p>
          </div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">Total Credits Collected by Docnote</p>
          </div>
          <div>
            <p className="text-sm">
              {financeReportTabData?.withHstTotalCredits !== undefined
                ? formatCurrency((financeReportTabData?.withHstTotalCredits / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div>
            <p className="text-sm">
              {financeReportTabData?.withoutHstTotalCredits !== undefined
                ? formatCurrency((financeReportTabData?.withoutHstTotalCredits / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark mt-4 py-1">
          <div className="col-span-2">
            <p className="text-base font-medium">Debits</p>
          </div>
          <div>
            <p className="text-base font-medium">With HST</p>
          </div>
          <div>
            <p className="text-base font-medium">Without HST</p>
          </div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">Doctors DS fee</p>
          </div>
          <div>
            <p className="text-sm">
              {financeReportTabData?.withHstDoctorDsFee !== undefined
                ? formatCurrency((financeReportTabData?.withHstDoctorDsFee / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">Patient DS fee collection by office</p>
          </div>
          <div>
            <p className="text-sm">
              {financeReportTabData?.withHstPatientDsFee !== undefined
                ? formatCurrency((financeReportTabData?.withHstPatientDsFee / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">HST on above fees</p>
          </div>
          <div>
            <p className="text-sm">
              {financeReportTabData?.withHstDebits !== undefined
                ? formatCurrency((financeReportTabData?.withHstDebits / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">Total Debits Owed to Docnote</p>
          </div>
          <div>
            <p className="text-sm">
              {financeReportTabData?.withHstTotalDebits !== undefined
                ? formatCurrency((financeReportTabData?.withHstTotalDebits / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark mt-4 py-1">
          <div className="col-span-2">
            <p className="text-base font-medium">Refunds (Credits)</p>
          </div>
          <div>
            <p className="text-base font-medium">With HST</p>
          </div>
          <div>
            <p className="text-base font-medium">Without HST</p>
          </div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">Collected by Docnote</p>
          </div>
          <div>
            <p className="text-sm">$0.00</p>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">HST on above item</p>
          </div>
          <div>
            <p className="text-sm">$0.00</p>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
          <div className="col-span-2">
            <p className="text-sm">Total Refunds as Credits</p>
          </div>
          <div>
            <p className="text-sm">$0.00</p>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 mt-4 py-1">
          <div className="col-span-2">
            <p className="text-base font-medium">Net Payable to Doctor</p>
          </div>
          <div>
            <p className="text-base font-medium">
              {financeReportTabData?.netPayableToDoctor !== undefined
                ? formatCurrency((financeReportTabData?.netPayableToDoctor / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div>
            <p className="text-base font-medium"></p>
          </div>
        </div>
      </div>
      <div className={`p-5 bg-docsigna-pink-light rounded border border-docnote-pink-dark mt-5`}>
        <div className="w-full">
          <div className="grid grid-cols-4 border-b border-docnote-pink-dark pb-1">
            <div className="col-span-2">
              <p className="text-base font-medium">Collected By Office</p>
            </div>
            <div>
              <p className="text-base font-medium">With HST</p>
            </div>
            <div>
              <p className="text-base font-medium">Without HST</p>
            </div>
          </div>
          <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
            <div className="col-span-2">
              <p className="text-sm">Revenue Collected by Office</p>
            </div>
            <div>
              <p className="text-sm">
                {financeReportTabData?.withHstRevenueCollectedByOffice !== undefined
                  ? formatCurrency((financeReportTabData?.withHstRevenueCollectedByOffice / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div>
              <p className="text-sm">
                {financeReportTabData?.withoutHstRevenueCollectedByOffice !== undefined
                  ? formatCurrency((financeReportTabData?.withoutHstRevenueCollectedByOffice / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 border-b border-docnote-pink-dark py-1">
            <div className="col-span-2">
              <p className="text-sm">HST on above item</p>
            </div>
            <div>
              <p className="text-sm">
                {financeReportTabData?.withHstRevenueCollectedByOfficeHstCalculated !== undefined
                  ? formatCurrency((financeReportTabData?.withHstRevenueCollectedByOfficeHstCalculated / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div>
              <p className="text-sm">N/A</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashFlowReport;
