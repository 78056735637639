/* eslint-disable no-useless-escape */
import React, { useState, useEffect, ChangeEvent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TextInput, DatePicker, PasswordErrorList, Button, Checkbox, ConfirmationDialog } from "../../components";
import { formatPhoneNumber, passwordValidationList, validatePassword } from "../../utils";
import NumberFormat from "react-number-format";
import { Phone_Fax_Regex, PrimaryContactEmail, colors } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserType,
  getSessionStorage,
  sendEmailVerificationCode,
  updateRequestFormData,
  updateSelectedOffice,
} from "../../redux/slices/OnBoardingSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { FormType } from "../../models";
import { emailExists } from "../../redux/slices/CurrentUserSlice";
import TermsAndConditions from "../Guest/TermsAndCondition";
import PrivacyPolicy from "../Guest/PrivacyPolicy";
import {
  Autocomplete,
  InputAdornment,
  //InputAdornment,
  TextField,
} from "@mui/material";
import { NoDataFound, noData } from "./Landing";
import { Office } from "../../models/office.model";
import { listOfficeData } from "../../redux/slices/RequestSlice";
import "../../assets/css/styles.css";
import SearchIcon from "../../assets/images/search-icon.svg";

const RequestFrom = ({ formType = FormType.CreateAccount }: { formType?: FormType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const isCreateAccount = pathname === "/create-account";
  const officeId = useSelector((state: RootState) => state.onBoarding.selectedOffice?.officeId);
  const formDataRedux = useSelector((state: RootState) => state.onBoarding.requestFormData);
  const { pending } = useSelector((state: RootState) => state.onBoarding);
  const { loading } = useSelector((state: RootState) => state.currentUser);
  const formData = formDataRedux;
  const [pwdValidations, setPwdValidations] = useState(passwordValidationList);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [touchedPwd, setTouchedPwd] = useState(false);
  const passwordFlds = ["password", "confirmPassword"];
  const [dateError, setDateError] = useState(true);
  const [tcAccepted, setTcAccepted] = useState(formData[formData.findIndex(v => v.id === "requestFormTerms")].value === "true");
  const [ohipState, setOhipState] = useState(formData[4].value);
  const [ohipStateError, setOhipStateError] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [sendingEmailError, setSendingEmailError] = useState(false);
  const [emailExistError, setEmailExistError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const excludedFields = [
    "details",
    "recipientName",
    "recipientContact",
    "recipientConsent",
    "fileIds",
    "password",
    "confirmPassword",
    "requestFormTerms",
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [searchOffice, setSearchOffice] = useState<Office | null>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };

  const resetPasswordFields = (type: FormType) => {
    const updatedFields = formData.map(it => {
      const itCpy = { ...it };
      if (["password", "confirmPassword"].includes(it.id)) {
        itCpy.value = "";
        itCpy.isValid = type === FormType.ContinueGuest ? true : false;
      }
      setPwdValidations(passwordValidationList);
      return itCpy;
    });
    dispatch(updateRequestFormData(updatedFields));
  };

  const validateField = (name: string, value: string) => {
    let valid = false;
    switch (name) {
      case "firstName":
      case "lastName":
        valid = !!value.trim();
        break;
      case "phone":
        valid = Phone_Fax_Regex.test(value);
        break;
      case "email":
        valid = PrimaryContactEmail.test(value);
        break;
      case "password":
        valid = !!value.trim();
        break;
      case "confirmPassword":
        valid = value === formData.find(it => it.id === "password")?.value;
        break;
      case "birthday":
        valid = dateError;
        break;
      case "ohip":
        let array;
        if (ohipState.includes(" ")) {
          array = ohipState.split(" ");
        }

        if (array && array.length === 3) {
          valid = array[0].length === 4 && array[1].length === 3 && array[2].length === 3;
        } else {
          valid = false;
        }
        break;
      case "details":
        valid = !!value.trim();
        break;
      default:
        break;
    }
    return valid;
  };

  const validateForm = () => {
    if (formType === FormType.ContinueGuest) {
      return !(
        formData.map(v => v.isValid /* && !excludedFields.includes(v.id) */ || excludedFields.includes(v.id)).includes(false) ||
        ohipStateError ||
        !tcAccepted ||
        dateError
      );
    } else if (formType === FormType.CreateAccount) {
      return !(
        formData.map(v => v.isValid || excludedFields.includes(v.id)).includes(false) ||
        ohipStateError ||
        dateError ||
        !tcAccepted ||
        pwdValidations.map(p => p.isValid).includes(false) ||
        (isCreateAccount && !searchOffice)
      );
    }
  };

  const onClickSave = () => {
    setOhipStateError(ohipState?.trim().length > 0 && ohipState?.trim().length < 10 ? true : false);
    if (validateForm()) {
      if (formType === FormType.ContinueGuest) {
        setSendingEmail(true);
        setSendingEmailError(false);
        dispatch(
          sendEmailVerificationCode({
            email: formData.filter(v => v.id === "email")[0].value,
            firstName: formData.filter(v => v.id === "firstName")[0].value,
            lastName: formData.filter(v => v.id === "lastName")[0].value,
          })
        ).then(action => {
          setSendingEmail(false);
          if (action.payload) {
            navigate("/onboarding/verify-email");
          } else {
            setSendingEmailError(true);
          }
        });
      } else {
        const email = formData.filter(v => v.id === "email")[0].value;
        dispatch(emailExists({ email })).then(action => {
          if (action.payload === true) {
            setEmailExistError(true);
          } else {
            setSendingEmailError(false);
            dispatch(
              sendEmailVerificationCode({
                email: formData.filter(v => v.id === "email")[0].value,
                firstName: formData.filter(v => v.id === "firstName")[0].value,
                lastName: formData.filter(v => v.id === "lastName")[0].value,
              })
            ).then(action => {
              setSendingEmail(false);
              if (action.payload) {
                navigate("/onboarding/verify-email");
              } else {
                setSendingEmailError(true);
              }
            });
          }
        });
      }
    }
  };

  const handleInputChange = (e: any | string) => {
    let value = "";
    value = e.target.value;
    let cursorPosition = e.target.selectionEnd;
    switch (e.currentTarget.name) {
      case "phone":
        value = formatPhoneNumber(e.currentTarget.value);
        if (cursorPosition === 4 || cursorPosition === 8) {
          cursorPosition++;
        }
        break;
      case "birthday":
        const dateVals = value.split("-");
        value = e.currentTarget.value;
        if (parseInt(dateVals[0]) > 9999) {
          dateVals[0] = dateVals[0].substring(0, value.length - 1);
          value = dateVals.join("-");
        }
        break;
      case "confirmPassword":
        const passwordData = formData.filter(e => e.id === "password");
        const isValid = passwordData[0].value === value;
        const validationErrors = [...pwdValidations];
        validationErrors[5] = { ...validationErrors[5], isValid };
        if (value.length > 0) {
          setTouchedPwd(true);
        } else {
          setTouchedPwd(false);
        }
        setPwdValidations(validationErrors);
        break;
      case "password":
        const newValidations = pwdValidations.map(item => {
          const isValid = validatePassword(item.id, value);

          return { ...item, isValid };
        });
        if (value.length > 0) {
          setTouchedPwd(true);
        } else {
          setTouchedPwd(false);
        }
        setPwdValidations(newValidations);
        break;
      default:
        break;
    }

    const valid = validateField(e.currentTarget.name, value);

    //getting state
    const newFromState = formData.map(item => {
      if (item.id === e.currentTarget.name) {
        return { ...item, value, isValid: valid, cursorPosition };
      } else {
        return { ...item, cursorPosition };
      }
    });
    dispatch(updateRequestFormData(newFromState));
  };

  const handleBlur = (id: string, value: string) => {
    const index = formData.findIndex(item => item.id === id);
    if (index !== -1) {
      const newFormData = [
        ...formData.slice(0, index),
        {
          ...formData[index],
          value,
        },
        ...formData.slice(index + 1),
      ];
      dispatch(updateRequestFormData(newFormData));
    }
  };

  const handleTermsCheckbox = () => {
    setTcAccepted(!tcAccepted);
    dispatch(
      updateRequestFormData([
        ...formDataRedux.slice(
          0,
          formDataRedux.findIndex(v => v.id === "requestFormTerms")
        ),
        { ...formDataRedux[formDataRedux.findIndex(v => v.id === "requestFormTerms")], value: !tcAccepted ? "true" : "false" },
        ...formDataRedux.slice(formDataRedux.findIndex(v => v.id === "requestFormTerms") + 1),
      ])
    );
  };

  const changeDate = (dateVal: string) => {
    dispatch(
      updateRequestFormData([
        ...formDataRedux.slice(0, 3),
        { ...formDataRedux[3], value: dateVal?.toString(), isValid: dateVal !== "" },
        ...formDataRedux.slice(4),
      ])
    );
  };

  const changeOhipValue = (val: string) => {
    dispatch(updateRequestFormData([...formDataRedux.slice(0, 5), { ...formDataRedux[5], value: val }, ...formDataRedux.slice(6)]));
  };

  const { officeList } = useSelector((state: RootState) => state.request);

  const getFilteredData = (): Office[] | [] => {
    if (searchTerm.length <= 2) return [];

    const matchedFilteredData: Office[] = [];

    officeList.data?.content.forEach(el => {
      // discarding the loop if no primary care providers are there

      if (!el.primaryCareProviders || el.primaryCareProviders?.length === 0) {
        matchedFilteredData.push(el);
        return;
      }

      //searching for all the pcp which includes the search term in first name or in last name

      const drNameIncluded = el.primaryCareProviders?.filter(pcp => {
        const fullName = `${pcp.firstName.toLowerCase()} ${pcp.lastName.toLowerCase()}`;

        const containsFirstName = pcp.firstName.toLowerCase().includes(searchTerm.toLowerCase());
        const containsLastName = pcp.lastName.toLowerCase().includes(searchTerm.toLowerCase());
        const containsFullName = fullName.includes(searchTerm.toLowerCase());

        return containsFirstName || containsLastName || containsFullName;
      });

      //pushing this result only when of the doctor name is matching with search term

      const data = { ...el, primaryCareProviders: drNameIncluded };
      matchedFilteredData.push(data);
    });

    return matchedFilteredData;
  };

  const onOptionSelect = (officeData: Office) => {
    setSearchOffice(officeData);
    dispatch(updateSelectedOffice(officeData));
  };

  useEffect(() => {
    dispatch(getSessionStorage());
  }, []);

  useEffect(() => {
    if (searchTerm.length !== 0) {
      if (searchTerm.length > 2) {
        dispatch(listOfficeData({ searchTerm, page: 0, sortType: "", size: 100 }));
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    localStorage.setItem("formType", formType);
    dispatch(changeUserType(formType));
    resetPasswordFields(formType);
  }, [formType]);

  useEffect(() => {
    setOhipState(formDataRedux[4].value);
  }, [formDataRedux]);

  useEffect(() => {
    if (!officeId && !isCreateAccount) {
      navigate("/");
    }
  }, [officeId, isCreateAccount]);

  return (
    <>
      <main>
        <div className="flex justify-between w-full">
          <div className="w-full max-w-2xl mx-auto mt-12 pb-12 px-10 md:px-0">
            <h3 className="text-3xl font-light mb-2">Contact Details</h3>

            {formType === FormType.ContinueGuest ? (
              <p className="text-base mb-5">
                Personal information is required to confirm your identity. You can also{" "}
                <Link to="/onboarding/create-account" className="text-docsigna-purple-dark">
                  create an account
                </Link>{" "}
                to save your information for future requests.
              </p>
            ) : (
              <p className="text-base font-medium mb-5">Personal information is required to confirm your identity.</p>
            )}
            {sendingEmailError && <p style={{ color: colors.Red }}>An error has occurred, please try again</p>}
            {isCreateAccount ? (
              <>
                <section className="search mb-5">
                  {!searchOffice ? (
                    <div>
                      <label className="text-sm text-docsigna-blue-dark font-medium block w-full">Select your doctor’s office below:</label>
                      <Autocomplete
                        freeSolo={true}
                        className="rounded-md border border-docsigna-blue-dark"
                        id="search-office-box"
                        options={getFilteredData().length > 0 ? getFilteredData() : noData}
                        onInputChange={(e: React.SyntheticEvent<Element, Event>, value) => {
                          setSearchTerm(value ? value : "");
                        }}
                        disableClearable
                        openOnFocus
                        open={searchTerm.length > 2}
                        includeInputInList={true}
                        filterOptions={x => x}
                        componentsProps={{
                          paper: {
                            classes: "absolute left-0 top-13 w-full rounded-md border border-gray-300 bg-white",
                          },
                        }}
                        ListboxProps={{
                          className: "absolute left-0 top-13 w-full rounded-md border border-gray-300 bg-white",
                        }}
                        renderOption={(props, el: Office) => (
                          //using random number to avoid duplicate key as officeId
                          <span className="hover:bg-docsigna-purple-light" key={el.officeId + Math.random().toString()}>
                            {getFilteredData().length > 0 ? (
                              <div
                                className="search-row p-3 w-full border-b border-gray-300 hover:bg-docsigna-purple-light cursor-pointer"
                                onClick={() => onOptionSelect(el)}>
                                <p className="text-lg font-semibold mb-1">{el.name}</p>
                                <>
                                  {el.primaryCareProviders?.map((pcp, ind) => {
                                    return <p key={ind} className="text-sm font-semibold mb-1">{` Dr. ${pcp.firstName} ${pcp.lastName}`}</p>;
                                  })}
                                </>
                                <p className="text-sm"> {`${el.street} ${el.city} ${el.state}`} </p>
                              </div>
                            ) : (
                              <NoDataFound />
                            )}
                          </span>
                        )}
                        renderInput={params => {
                          // let newInputProps = getFilteredData().length ? params.InputProps : {}
                          const newInputProps = params.InputProps;
                          return (
                            <TextField
                              {...params}
                              placeholder="Search by doctor’s name or address"
                              type="search"
                              InputProps={{
                                ...newInputProps,
                                startAdornment: (
                                  <InputAdornment position="start" className="">
                                    <img src={SearchIcon} alt="search" width={18} height={18} />
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  outline: "none",
                                  margin: "0px",
                                  boxShadow: "none",
                                  padding: 0,
                                  border: 0,
                                },
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  ) : null}
                  {searchOffice ? (
                    <>
                      <h2 className="font-bold text-lg mb-0">{searchOffice?.name}</h2>
                      <p className="mb-2 font-medium">
                        {searchOffice?.street + ", " + searchOffice.city + ", " + searchOffice.state + " " + searchOffice.zip}
                      </p>
                    </>
                  ) : errorInitiated ? (
                    <p style={{ color: colors.Red }}>Please select office</p>
                  ) : null}
                </section>
                <h2 className="font-bold mb-0 capitalize">Personal information</h2>
              </>
            ) : null}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-5 pt-2">
              {formData.map((item, index, array) => {
                if (["password", "confirmPassword"].includes(item.id) && formType === FormType.ContinueGuest) {
                  return null;
                }
                if (item.id === "birthday") {
                  return (
                    <div className="py-0.5 flex" key={item.id}>
                      <div className="w-full">
                        <DatePicker
                          label={item.label}
                          isRequired={item.required}
                          name={item.id}
                          placeholder={item.placeholder}
                          value={item.value}
                          isError={dateError && errorInitiated}
                          onChange={val => changeDate(val)}
                          errorMsg={item.errorMesage}
                          onError={(e: boolean) => setDateError(e)}
                          showError={errorInitiated}
                        />
                      </div>
                    </div>
                  );
                } else if (item.id === "password" || item.id === "confirmPassword") {
                  return (
                    <div className="py-0.5" key={item.id}>
                      <TextInput
                        isError={!item.isValid && errorInitiated}
                        isRequired={item.required}
                        isPassword={passwordFlds.includes(item.id)}
                        name={item.id}
                        label={item.label}
                        value={String(item.value)}
                        placeholder={item.placeholder}
                        errorMsg={item.errorMesage}
                        onChangeFunc={e => handleInputChange(e)}
                        // cursorPosition={item.cursorPosition}
                      />
                    </div>
                  );
                } else if (item.id === "ohip") {
                  return (
                    <div className="py-0.5" key={item.id}>
                      <label className="text-sm text-docsigna-blue-dark font-medium block w-full">
                        {item.label}
                        {item.required ? <span className="text-red-500">*</span> : null}
                      </label>
                      <NumberFormat
                        value={item.value}
                        isNumericString
                        format={"#### ### ###"}
                        placeholder="Enter OHIP Number"
                        displayType="input"
                        name={item.id}
                        className="general-ohip"
                        onValueChange={values => {
                          changeOhipValue(values.value);
                        }}
                        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                          setOhipStateError(e.target.value?.trim().length > 0 && e.target.value?.trim().length < 10 ? true : false);
                        }}
                      />
                      {ohipStateError && errorInitiated ? <p style={{ color: colors.Red }}>{item.errorMesage}</p> : null}
                    </div>
                  );
                } else if (!excludedFields.includes(item.id)) {
                  return (
                    <div className="py-0.5" key={item.id}>
                      <TextInput
                        isError={!item.isValid && errorInitiated}
                        isRequired={item.required}
                        isPassword={passwordFlds.includes(item.id)}
                        name={item.id}
                        label={`${item.label}`}
                        value={String(item.value)}
                        placeholder={item.placeholder}
                        errorMsg={item.errorMesage}
                        onChangeFunc={e => handleInputChange(e)}
                        onBlur={e => handleBlur(item.id, e.currentTarget.value.trim())}
                        // cursorPosition={item.cursorPosition}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              {(touchedPwd && formType !== FormType.ContinueGuest) || (errorInitiated && formType !== FormType.ContinueGuest) ? (
                <div>
                  {pwdValidations.map(item => {
                    return (
                      <div key={item.id} className="my-1 ml-1">
                        <PasswordErrorList isValid={item.isValid} message={item.msg} />
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>

            <div className="py-0.5 mb-2">
              <label className="flex justify-start items-center">
                <Checkbox checked={tcAccepted} onChange={() => handleTermsCheckbox()} label="" />
                <span className="text-sm font-medium">
                  * I agree to Docnote&apos;s{" "}
                  <span className="text-sm text-docsigna-blue-light" onClick={() => handleClickOpen()}>
                    Terms and Conditions
                  </span>{" "}
                  and{" "}
                  <span className="text-sm text-docsigna-blue-light" onClick={() => handleClickOpenPrivacyPolicy()}>
                    Privacy Policy
                  </span>
                </span>
              </label>
              {!tcAccepted && errorInitiated ? <p style={{ color: colors.Red }}>{"You must agree to Docnote terms to continue"}</p> : null}
            </div>
            {emailExistError ? (
              <p style={{ color: colors.Red }}>An account already exists with this email address, please sign in to create your request.</p>
            ) : null}
            <div className="py-2 w-1/2 mb-5" onClick={() => setErrorInitiated(true)}>
              <Button
                onClickFunc={() => {
                  onClickSave();
                }}
                AdditionalClassNames="pointer px-5 mr-3"
                text={"Continue"}
                width="full"
                disabled={!validateForm() && errorInitiated}
                varient={sendingEmail || pending || loading ? "loading" : "Primary"}
              />
            </div>
          </div>
        </div>
        <ConfirmationDialog
          maxWidth="lg"
          successButtonText=""
          failureButtonText="Close"
          open={open}
          description={<TermsAndConditions showCheckbox={false} />}
          handleSuccess={() => {}}
          handleFailure={handleClose}
        />
        <ConfirmationDialog
          maxWidth="lg"
          successButtonText=""
          failureButtonText="Close"
          open={openPrivacyPolicy}
          description={<PrivacyPolicy />}
          handleSuccess={() => {}}
          handleFailure={handleClosePrivacyPolicy}
        />
      </main>
    </>
  );
};
export default RequestFrom;
