import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { RootState } from "../../redux/store";
import { UserType } from "../../services/user.service";
import { validateEmail } from "../../utils";
import Button from "../Button/Button";
import Checkbox from "../Checkbox/Checkbox";
import Select from "../Select/Select";
import TextInput from "../TextInput/TextInput";
import { formatPhoneNumber } from "../../utils";
import { AuthPermission } from "../../services/auth.service";
import { Phone_Fax_Regex } from "../../utils/constants";
import { Office } from "../../models/office.model";
interface EditFormDataType {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  errorMsg: string;
  isError: boolean;
  required: boolean;
  type: "text" | "select" | "phone";
  options?: string[];
}

const roleOptions = [
  {
    title: "System Superuser",
    key: UserType.SystemAdmin,
  },
  {
    title: "Office Superuser",
    key: UserType.OfficeAdmin,
  },
  {
    title: "Accounting",
    key: UserType.Accounting,
  },
  {
    title: "User",
    key: UserType.User,
  },
];

let checkBoxOptions = [
  {
    title: "Edit Office",
    key: AuthPermission.Office,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Users",
    key: AuthPermission.User,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Services",
    key: AuthPermission.Service,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Sign Forms",
    key: AuthPermission.Signing,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "View Reports",
    key: AuthPermission.Report,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "View/Edit Finance",
    key: AuthPermission.Finance,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Requests",
    key: AuthPermission.Request,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Legal",
    key: AuthPermission.Legal,
    checked: false,
    dependents: [],
    disabled: false,
  },
];

const ViewProfile = ({ onCancelClick }: { onCancelClick: () => void }) => {
  const user = useSelector((state: RootState) => state.currentUser.currentUser);
  const officeName = user?.offices?.length ? user?.offices[0].name : "";
  const formDataInit: EditFormDataType[] = [
    {
      id: "firstName",
      label: "First Name",
      placeholder: "Enter first name",
      value: user?.firstName || "",
      errorMsg: "Please enter first name.",
      isError: false,
      required: true,
      type: "text",
    },
    {
      id: "lastName",
      label: "Last Name",
      placeholder: "Enter last name",
      value: user?.lastName || "",
      errorMsg: "Please enter last name.",
      isError: false,
      required: true,
      type: "text",
    },
    {
      id: "email",
      label: "Email",
      placeholder: "Enter email address",
      value: user?.email || "",
      errorMsg: "Please enter email address.",
      isError: false,
      required: true,
      type: "text",
    },
    {
      id: "phone",
      label: "Phone Number (Optional)",
      placeholder: "Enter phone number",
      required: false,
      errorMsg: "Please enter a valid phone number",
      value: formatPhoneNumber(user?.phone ?? ""),
      isError: false,
      type: "phone",
    },
    {
      id: "office",
      label: "Office",
      placeholder: officeName || "Select Office",
      value: officeName,
      errorMsg: "Please select an office.",
      isError: false,
      required: false,
      type: "select",
      options: [],
    },
  ];
  const permission = user?.permissions;
  if (permission) {
    checkBoxOptions = checkBoxOptions?.map(p => {
      return { ...p, checked: permission?.includes(p.key) };
    });
  }

  const getIsPCP = (offices: Office[] | undefined, userId: string) => {
    if (!offices) return false;
    for (let i = 0; i < offices.length; i++) {
      const selectedOffice = offices[i];
      if (selectedOffice.primaryCareProviders?.some(pcp => pcp.userId === userId)) {
        return true;
      }
    }
    return false;
  };

  const [primaryCareProvider, setPrimaryCareProvider] = useState<boolean>(getIsPCP(user?.offices, user?.userId || ""));
  const [formData, setFormData] = useState<EditFormDataType[]>(formDataInit);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const toast = useContext(ToastContext);

  const handleChange = (id: string, value: string) => {
    const temp = [...formData];
    const updated = temp.map(v => {
      if (v.id === id) {
        v.value = value;
        if (v.id === "email") {
          v.isError = !validateEmail(value);
        } else if (v.id === "phone") {
          v.value = formatPhoneNumber(value);
          const temp = Phone_Fax_Regex.test(v.value);
          v.isError = !temp;
        } else {
          v.isError = value === "";
        }
      }
      return v;
    });
    setFormData(updated);
  };

  const validateForm = () => {
    return formData.every(v => {
      return !v.isError;
    });
  };

  useEffect(() => {
    setPrimaryCareProvider(getIsPCP(user?.offices, user?.userId || ""));
  }, [user]);

  return (
    <>
      <div className="px-8">
        {formData.map(form => {
          if (form.type === "text") {
            return (
              <div className="py-2" key={form.id}>
                <TextInput
                  onChangeFunc={e => handleChange(form.id, e.currentTarget.value)}
                  value={form.value}
                  placeholder={form.placeholder}
                  errorMsg={form.errorMsg}
                  isRequired={form.required}
                  isError={form.isError && errorInitiated}
                  label={form.label}
                  onBlur={e => handleChange(form.id, e.currentTarget.value.trim())}
                  isDarkBg={true}
                  readonly
                />
              </div>
            );
          }
          if (form.type === "phone") {
            return (
              <div key={form.id} className="py-2">
                <TextInput
                  isError={form.isError && errorInitiated}
                  value={form.value}
                  name={form.id}
                  onChangeFunc={e => handleChange(form.id, e.currentTarget.value)}
                  label={form.label}
                  errorMsg={form.errorMsg}
                  isRequired={form.required}
                  placeholder={form.placeholder}
                  isDarkBg={true}
                  readonly
                />
              </div>
            );
          }
          if (form.type === "select") {
            return (
              <div className="py-2" key={form.id}>
                <Select
                  onSelect={e => handleChange(form.id, e.currentTarget.value)}
                  options={form.options!}
                  required={form.required}
                  title={form.label}
                  placeHolder={form.placeholder}
                  disabled={true}
                  isDarkBg={true}
                  //   defaultValue={form.value}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
        <div className="py-2">
          <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-1">Role</label>
          <Checkbox
            onChange={() => {}}
            label={roleOptions.find(v => v.key === user?.userType)?.title || ""}
            checked={true}
            disabled={true}
            isDarkBg={true}
          />
        </div>
        <div className="py-2">
          <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-1">Permissions</label>
          {checkBoxOptions?.map(v => {
            return <Checkbox label={v.title} checked={v.checked} onChange={() => {}} key={v.key} disabled={true} isDarkBg={true} />;
          })}
          <Checkbox label="Primary Care Provider" checked={primaryCareProvider} onChange={() => null} disabled={true} isDarkBg={true} />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => {
              onCancelClick();
            }}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div onClick={() => setErrorInitiated(true)}>
            <Button
              onClickFunc={() => {
                if (validateForm()) toast?.openToast();
              }}
              AdditionalClassNames="pointer px-5"
              disabled={!validateForm() && errorInitiated}
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
