import { headersType } from "../../models";

export const headers: headersType[] = [
  {
    title: "Office Name",
    extraClasses: "w-full lg:w-240 pl-0 truncate",
    sortKey: "name",
    isClickable: true,
  },
  {
    title: "Address",
    extraClasses: "w-full hidden lg:block break-all",
    sortKey: "city",
    isClickable: true,
  },
  {
    title: "Contact",
    extraClasses: "w-full hidden lg:block",
    sortKey: "contactName",
    isClickable: true,
  },
  {
    title: "# Users",
    extraClasses: "w-160 hidden lg:block",
    sortKey: "userState",
    isClickable: false,
  },
  {
    title: "Status",
    extraClasses: "w-160 hidden lg:block",
    sortKey: "userState.name",
    isClickable: false,
  },
];
