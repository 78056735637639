import React, { useEffect, useState } from "react";
import TextInput from "../TextInput/TextInput";
import { logger } from "../../utils/logger";
import { MatchSubStringRegex } from "../../utils/constants";

interface OtpInputType {
  length: number;
  onChange: (text: string) => void;
}

const logs = logger.getLogger("OTPInputError");

const OtpInput = ({ length = 6, onChange }: OtpInputType) => {
  const [values, setValues] = useState(new Array(length).fill(""));
  const [focusArray, setFocusArray] = useState<boolean[]>([true, ...new Array<boolean>(length - 1).fill(false)]);
  const [isMounted, setIsMounted] = useState(false);
  const handleChange = (index: number, value: string) => {
    if (!MatchSubStringRegex.test(value)) {
      return;
    }
    if (!isNaN(parseInt(value)) && value !== " " && value !== "") {
      if (index === length - 1) {
        setFocusArray([...new Array(index + 1).fill(false)]);
      } else {
        setFocusArray([...new Array(index + 1).fill(false), true]);
      }
      if (value.length) {
        const tempValues = [...values];
        tempValues[index] = value.trim().substring(value.length - 1);
        setValues(tempValues);
      }
    }
  };

  const handlePaste = async (e: React.ClipboardEvent<HTMLDivElement>) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      const clipBoardText = await navigator.clipboard.readText();
      if (!MatchSubStringRegex.test(clipBoardText)) {
        return;
      }
      setValues([...clipBoardText.substring(0, length).split(""), ...new Array(Math.max(0, length - clipBoardText.length)).fill("")]);
      if (clipBoardText.length) setFocusArray([...new Array(clipBoardText.length - 1), true]);
    } catch (err) {
      logs.warn(JSON.stringify(err));
    }
  };

  const handleBackSpace = (index: number) => {
    try {
      if (index > 0) {
        setValues([...values.slice(0, index), "", ...values.slice(index + 1)]);
        setFocusArray([...new Array(index - 1).fill(false), true]);
      }
      if (index === 0) {
        setValues([...values.slice(0, index), "", ...values.slice(index + 1)]);
      }
    } catch (err) {
      logs.warn(JSON.stringify(err));
    }
  };

  useEffect(() => {
    if (isMounted) {
      onChange(values.join(""));
    } else {
      setIsMounted(true);
    }
  }, [values]);

  useEffect(() => {
    setValues(new Array(length).fill(""));
  }, [length]);

  return (
    <div className="flex flex-row">
      {values.map((data, index) => {
        return (
          <div
            onPaste={e => handlePaste(e)}
            key={index}
            className="mr-1 md:mr-4 w-12"
            onKeyDown={e => {
              if (e.key === "Backspace" || e.key === "Delete") {
                handleBackSpace(index);
              }
            }}>
            <TextInput
              isFocused={focusArray[index]}
              autoFocus={index === 0}
              placeholder="-"
              value={data}
              extraInputClass={"text-center disable-arrow"}
              onChangeFunc={event => {
                handleChange(index, event.target.value);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default OtpInput;
