import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { DashboardTabRevenueSection, DashboardTabGraphSection, DashboardTabTopEarningSection } from "../../components/Finance";

const DashboardTab = ({ noRecordFound = false }: { noRecordFound: boolean; renderFilters?: any }) => {
  const { financeReport } = useSelector((state: RootState) => state.finance);
  return (
    <div className="">
      {/* Section 1 */}
      {!noRecordFound ? <DashboardTabRevenueSection financeReport={financeReport} /> : null}
      {/* Section 2 */}
      {financeReport?.billedTo?.length ? <DashboardTabGraphSection financeReport={financeReport} /> : null}
      {/* Section 3 */}
      {financeReport?.services?.length ? <DashboardTabTopEarningSection financeReportServices={financeReport?.services} /> : null}
    </div>
  );
};
export default DashboardTab;
