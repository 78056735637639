import React from "react";
import ReactApexChart from "react-apexcharts";
import { FinanceBilledTo } from "../../models/finance.model";
import { AnnualPlanBilledToName, ServiceBilledTo, ServiceBilledToName } from "../../models/service.model";
import { formatCurrency } from "../../utils";
import { colors as ColorOptions } from "../../utils/constants";

const DountGraph = ({
  billedToList = [],
  graphVal = true,
  graphLabel = "",
  totalCount = "",
  height,
  location = "DashboardTab",
  showLegend = false,
}: {
  billedToList: FinanceBilledTo[];
  graphVal?: boolean;
  graphLabel: string;
  totalCount?: string;
  height?: number;
  location?: "AnnualTab" | "DashboardTab" | "AccountTab";
  showLegend?: boolean;
}) => {
  const billedTo = [...billedToList];
  const labels: any = [];
  const series: any = [];
  const labelValue = totalCount;
  const labelText = graphLabel ?? "";
  const state: any = {
    series: [],
    options: {
      chart: {
        animations: {
          enabled: false,
        },
      },
      labels: [],
      stroke: {
        width: 0,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            labels: {
              show: totalCount ? true : false,
              name: {
                show: true,
                fontWeight: 600,
                fontSize: height && height <= 200 ? "20px" : "36px",
                color: ColorOptions.DarkBlue,
                offsetY: location === "DashboardTab" ? 4 : 0,
                formatter: function () {
                  return labelValue;
                },
              },
              value: {
                show: true,
                fontWeight: 500,
                fontSize: location === "AccountTab" ? "18px" : "13px",
                color: ColorOptions.DarkBlue,
                offsetY: location === "DashboardTab" ? 5 : 10,
                formatter: function () {
                  return labelText;
                },
              },
              total: {
                show: true,
                fontWeight: 600,
                fontSize: height && height <= 200 ? "20px" : "36px",
                color: ColorOptions.DarkBlue,
                label: labelText,
                formatter: function () {
                  return labelText;
                },
              },
            },
          },
          offsetY: 5,
          expandOnClick: true,
        },
      },
      states: {
        // remove this for the hover effect on the chart color
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          filter: {
            type: "none",
          },
          allowMultipleDataPointsSelection: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: showLegend,
        formatter: function (val: any, opts: any) {
          // Get the actual value using the series and seriesIndex
          const seriesIndex = opts.seriesIndex;
          const actualValue = opts.w.config.series[seriesIndex];
          return actualValue;
        },
      },
      colors: [],
      responsive: [
        {
          breakpoint: 700,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
      tooltip: {
        marker: {
          show: false,
        },
        fillSeriesColor: false,
        x: {
          show: false,
        },
        y: {
          formatter: function (val: number) {
            return graphVal ? val : formatCurrency(val ? (val / 100).toString() : "0", "blur");
          },
          title: {
            formatter: function (seriesName: string) {
              const index = parseInt(seriesName.split("-")[1]);
              const item = billedTo[index - 1];
              const value: ServiceBilledTo | string = item?.billedTo ?? "";
              return value
                ? location === "AnnualTab"
                  ? AnnualPlanBilledToName[value as ServiceBilledTo]
                  : ServiceBilledToName[value as ServiceBilledTo]
                : "";
            },
          },
        },
      },
    },
  };
  const colors: string[] = [];
  billedTo.map(item => {
    switch (item.billedTo.toLowerCase()) {
      case ServiceBilledTo.Patient:
        labels.push(ServiceBilledToName[ServiceBilledTo.Patient]);
        colors.push(ColorOptions.PatientPays);
        break;
      case ServiceBilledTo.OHIP:
        labels.push(ServiceBilledToName[ServiceBilledTo.OHIP]);
        colors.push(ColorOptions.OHIP);
        break;
      case ServiceBilledTo.ThirdParty:
        labels.push(ServiceBilledToName[ServiceBilledTo.ThirdParty]);
        colors.push(ColorOptions.ThirdParty);
        break;
      case ServiceBilledTo.WSIB:
        labels.push(ServiceBilledToName[ServiceBilledTo.WSIB]);
        colors.push(ColorOptions.WSIB);
        break;
      case ServiceBilledTo.ServiceCanada:
        labels.push(ServiceBilledToName[ServiceBilledTo.ServiceCanada]);
        colors.push(ColorOptions.ServiceCanada);
        break;
      case ServiceBilledTo.PrescriptionRefill:
        labels.push(ServiceBilledToName[ServiceBilledTo.PrescriptionRefill]);
        colors.push(ColorOptions.PrescriptionRefill);
        break;
      // case ServiceBilledTo.DocnoteDebit:
      //   labels.push(ServiceBilledToName[ServiceBilledTo.DocnoteDebit]);
      //   colors.push(ColorOptions.DarkBlue);
      //   break;
      default:
        break;
    }
    if (graphVal) {
      series.push(item.count);
    } else {
      series.push(item.amount);
    }
    return true;
  });

  state.series = [...series];
  state.options.labels = [];
  state.options.colors = [...colors];
  return <ReactApexChart height={height} options={state.options} series={state.series} type="donut" />;
};

export default DountGraph;
