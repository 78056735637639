import { useSelector } from "react-redux";
import ReportHeader from "./ReportHeader";
import { RootState } from "../../redux/store";
import { formatCurrency } from "../../utils";
import ReportFooter from "./ReportFooter";

const CashFlowReportPDF = ({ isPdf = false, selectedMonth = "" }: { isPdf?: boolean; selectedMonth?: string }) => {
  const { financeReportTabData } = useSelector((state: RootState) => state.finance);
  return (
    <div className="break-page-before">
      <div className={`${isPdf ? "w-full h-a4-finance relative" : ""}`}>
        {isPdf ? (
          <ReportHeader title="Cash Flow" selectedMonth={selectedMonth} />
        ) : (
          <div className="w-full flex items-center justify-center p-2 bg-docsigna-purple-dark">
            <h3 className="text-lg font-semibold text-white font-roboto-regular">Cash Flow</h3>
          </div>
        )}
        <div className={`${!isPdf ? "border-2 border-t-0 border-docsigna-blue-light" : ""} px-5 py-8`}>
          <div className="grid grid-cols-4">
            <div className="col-span-2">
              <p className="font-roboto-regular text-sm">Credits</p>
            </div>
            <div></div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2 mb-3">
            <div className="col-span-2"></div>
            <div>
              <p className="font-roboto-regular text-sm">Forms With HST</p>
            </div>
            <div>
              <p className="font-roboto-regular text-sm">Forms Without HST</p>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light">Revenue Collected by Docnote</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.withHstDsFeeSubTotal !== undefined
                  ? formatCurrency((financeReportTabData?.withHstDsFeeSubTotal / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.withoutHstDsFeeSubTotal !== undefined
                  ? formatCurrency((financeReportTabData?.withoutHstDsFeeSubTotal / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light ml-3">HST on above item</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.withHstTotal !== undefined
                  ? formatCurrency((financeReportTabData?.withHstTotal / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">N/A</p>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light">Total Credits Collected by Docnote</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.withHstTotalCredits !== undefined
                  ? formatCurrency((financeReportTabData?.withHstTotalCredits / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.withoutHstTotalCredits !== undefined
                  ? formatCurrency((financeReportTabData?.withoutHstTotalCredits / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2 mb-2 mt-3">
            <div className="col-span-2">
              <p className="font-roboto-regular text-sm">Debits</p>
            </div>
            <div></div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light">Doctors DS fee</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.withHstDoctorDsFee !== undefined
                  ? formatCurrency((financeReportTabData?.withHstDoctorDsFee / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light">Patient DS fee collection by office</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.withHstPatientDsFee !== undefined
                  ? formatCurrency((financeReportTabData?.withHstPatientDsFee / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light ml-3">HST on above fees</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.withHstDebits !== undefined
                  ? formatCurrency((financeReportTabData?.withHstDebits / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light">Total Debits Owed to Docnote</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.withHstTotalDebits !== undefined
                  ? formatCurrency((financeReportTabData?.withHstTotalDebits / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2 mb-2 mt-3">
            <div className="col-span-2">
              <p className="font-roboto-regular text-sm">Refunds (Credits)</p>
            </div>
            <div></div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light">Collected by Docnote</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">$0.00</p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light ml-3">HST on above item</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">$0.00</p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-2">
              <p className="font-13 font-roboto-light">Total Refunds as Credits</p>
            </div>
            <div>
              <p className="font-13 font-roboto-light">$0.00</p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 gap-2 mt-5">
            <div className="col-span-2">
              <p className="font-roboto-regular text-sm">Net Payable to Doctor</p>
            </div>
            <div>
              <p className="font-roboto-regular text-sm">
                {financeReportTabData?.netPayableToDoctor !== undefined
                  ? formatCurrency((financeReportTabData?.netPayableToDoctor / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div></div>
          </div>
          {/* Part second */}
          <div className={`w-full flex justify-center py-5 ${isPdf ? "mt-3" : ""}`}>
            <hr className="w-8/12 border-docsigna-blue-dark" />
          </div>
          <div>
            <div className="grid grid-cols-4">
              <div className="col-span-2">
                <p className="font-roboto-regular text-sm">Collected By Office</p>
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="grid grid-cols-4 gap-2 mb-3">
              <div className="col-span-2"></div>
              <div>
                <p className="font-roboto-regular text-sm">Forms With HST</p>
              </div>
              <div>
                <p className="font-roboto-regular text-sm">Forms Without HST</p>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-2">
              <div className="col-span-2">
                <p className="font-13 font-roboto-light">Revenue Collected by Office</p>
              </div>
              <div>
                <p className="font-13 font-roboto-light">
                  {financeReportTabData?.withHstRevenueCollectedByOffice !== undefined
                    ? formatCurrency((financeReportTabData?.withHstRevenueCollectedByOffice / 100)?.toString(), "blur")
                    : "$0.00"}
                </p>
              </div>
              <div>
                <p className="font-13 font-roboto-light">
                  {financeReportTabData?.withoutHstRevenueCollectedByOffice !== undefined
                    ? formatCurrency((financeReportTabData?.withoutHstRevenueCollectedByOffice / 100)?.toString(), "blur")
                    : "$0.00"}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-2">
              <div className="col-span-2">
                <p className="font-13 font-roboto-light ml-3">HST on above item</p>
              </div>
              <div>
                <p className="font-13 font-roboto-light">
                  {financeReportTabData?.withHstRevenueCollectedByOfficeHstCalculated !== undefined
                    ? formatCurrency((financeReportTabData?.withHstRevenueCollectedByOfficeHstCalculated / 100)?.toString(), "blur")
                    : "$0.00"}
                </p>
              </div>
              <div>
                <p className="font-13 font-roboto-light">N/A</p>
              </div>
            </div>
          </div>
        </div>

        {isPdf ? <ReportFooter /> : null}
      </div>
    </div>
  );
};

export default CashFlowReportPDF;
