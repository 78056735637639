import React, { useState } from "react";
import { UserType } from "../../../services/user.service";
import { getPermissionName } from "../../../utils";
import Button from "../../Button/Button";
import { formatPhoneNumber } from "../../../utils";
import { UserDataForRender } from "../../../redux/slices/UserSlice";

interface EditFormDataType {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  errorMsg: string;
  isError: boolean;
  required: boolean;
  type: "text" | "select" | "phone";
  options?: string[];
}

const roleOptions = [
  {
    title: "System Superuser",
    key: UserType.SystemAdmin,
  },
  {
    title: "Office Superuser",
    key: UserType.OfficeAdmin,
  },
  {
    title: "Accounting",
    key: UserType.Accounting,
  },
  {
    title: "User",
    key: UserType.User,
  },
];

const ViewUser = ({ onCancelClick, selectedUser }: { onCancelClick: () => void; selectedUser: UserDataForRender | null }) => {
  const user = selectedUser;
  const officeName = user?.offices?.length ? user?.offices[0].name : "";
  const formDataInit: EditFormDataType[] = [
    {
      id: "firstName",
      label: "First Name",
      placeholder: "Enter first name",
      value: user?.firstName || "",
      errorMsg: "Please enter first name.",
      isError: false,
      required: true,
      type: "text",
    },
    {
      id: "lastName",
      label: "Last Name",
      placeholder: "Enter last name",
      value: user?.lastName || "",
      errorMsg: "Please enter last name.",
      isError: false,
      required: true,
      type: "text",
    },
    {
      id: "email",
      label: "Email",
      placeholder: "Enter email address",
      value: user?.email || "",
      errorMsg: "Please enter email address.",
      isError: false,
      required: true,
      type: "text",
    },
    {
      id: "phone",
      label: "Phone Number",
      placeholder: "Enter phone number",
      required: true,
      errorMsg: "Please enter a valid phone number",
      value: formatPhoneNumber(user?.phone ?? ""),
      isError: false,
      type: "phone",
    },
    {
      id: "office",
      label: "Office",
      placeholder: officeName || "Select Office",
      value: officeName,
      errorMsg: "Please select an office.",
      isError: false,
      required: false,
      type: "select",
      options: [],
    },
  ];

  const permissionsInit = user?.permissions.map(p => {
    return {
      id: p,
      title: getPermissionName(p),
      checked: true,
    };
  });
  const [formData] = useState<EditFormDataType[]>(formDataInit);
  const [permissions] = useState<typeof permissionsInit>(permissionsInit);

  return (
    <>
      <div className="px-8 py-5">
        {formData.map(form => {
          return (
            <div key={form.id} className="py-2">
              <p className="font-semibold text-xs">{form.label}</p>
              {form.value}
            </div>
          );
        })}
        <div className="py-2">
          <p className="font-semibold text-xs">Role</p>
          {roleOptions.find(v => v.key === user?.userType)?.title}
        </div>
        <div className="py-2">
          {permissions?.length ? <label className="font-semibold text-xs">Permissions</label> : null}
          {permissions?.map(v => {
            return <p key={v.id}>- {v.title}</p>;
          })}
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <div>
            <Button
              onClickFunc={() => {
                onCancelClick();
              }}
              AdditionalClassNames="pointer px-5"
              text="Close"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUser;
