import { useDispatch } from "react-redux";
import { resetState } from "../redux/slices/CurrentUserSlice";
import { resetErrors } from "../redux/slices/ErrorSlice";
import { resetStateFileSlice } from "../redux/slices/FileSlice";
import { resetStateOfficeSlice } from "../redux/slices/OfficeSlice";
import { resetStateOnBoardingSlice } from "../redux/slices/OnBoardingSlice";
import { resetStateServiceSlice } from "../redux/slices/ServiceSlice";
import { resetState as resetUserSlice } from "../redux/slices/UserSlice";

const useResetStore = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(resetState());
    dispatch(resetUserSlice());
    dispatch(resetErrors());
    dispatch(resetStateFileSlice());
    dispatch(resetStateOfficeSlice());
    dispatch(resetStateOnBoardingSlice());
    dispatch(resetStateServiceSlice());
  };
};

export default useResetStore;
