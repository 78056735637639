import { ApiPage, emptyApiPage } from "../models/api-page.model";
import { DsFile } from "../models/ds-file.model";
import { apiService } from "./api.service";

export class FileService {
  private static BASE_PATH = "/api/v1/files";

  static async uploadFile(file: File, callback?: (progress: number) => void): Promise<DsFile | null> {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiService.upload(this.BASE_PATH, formData, callback, false, true);
    if (!response.success) return null;

    return response.data as DsFile;
  }

  // TODO: Untested
  static async updateFile(fileId: string, file: File, callback?: (progress: number) => void): Promise<DsFile | null> {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiService.upload(`${this.BASE_PATH}/${fileId}`, formData, callback);
    if (!response.success) return null;

    return response.data.path as DsFile;
  }

  static async getAllFiles(page = 0, size = 20, sort = "originalFilename,asc"): Promise<ApiPage<DsFile>> {
    const response = await apiService.get(`${this.BASE_PATH}?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<DsFile>();

    return response.data as ApiPage<DsFile>;
  }

  static async getFile(fileId: string): Promise<DsFile | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${fileId}`);
    if (!response.success) return null;

    return response.data as DsFile;
  }

  static async deleteFile(fileId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/${fileId}`);
    if (!response.success) return false;

    return true;
  }
}
