import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { UserType } from "../../../services/user.service";
import { validateEmail, validatePhoneOrFaxNumber } from "../../../utils";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import { formatPhoneNumber } from "../../../utils";
import { AuthPermission } from "../../../services/auth.service";
import { UserTypeLevel } from "../../../models";
import Checkbox from "../../Checkbox/Checkbox";
import { getOffice, listOfficeDataForSelectList } from "../../../redux/slices/OfficeSlice";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../../Dialogs/ConfirmationDialog";
import { getJobTitles, updateOfficeUser, updateUser } from "../../../redux/slices/UserSlice";
import { useParams } from "react-router-dom";
import { Phone_Fax_Regex, colors } from "../../../utils/constants";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { Office } from "../../../models/office.model";
import { getUser } from "../../../redux/slices/CurrentUserSlice";
import { UserUpdate } from "../../../models/user-update.model";
import SelectNew from "../../Select/SelectNew";
interface EditFormDataType {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  errorMsg: string;
  isError: boolean;
  required: boolean;
  type: "text" | "select" | "phone" | "select-new";
  options?: {
    value?: string;
    title?: string;
  }[];
}

const checkBoxOptions = [
  {
    title: "Edit Office",
    key: AuthPermission.Office,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Users",
    key: AuthPermission.User,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Services",
    key: AuthPermission.Service,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Sign Forms",
    key: AuthPermission.Signing,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "View Reports",
    key: AuthPermission.Report,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "View/Edit Finance",
    key: AuthPermission.Finance,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Requests",
    key: AuthPermission.Request,
    selected: false,
    dependents: [],
    disabled: false,
  },
];

const EditUser = ({
  onCancelClick,
  userId,
  insideOffice = false,
  onSuccess,
  setIsLoading,
  isLoading,
}: {
  onCancelClick: () => void;
  userId: string;
  insideOffice: boolean;
  onSuccess: () => void;
  setIsLoading: (val: boolean) => void;
  isLoading: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const { usersList, jobTitles } = useSelector((state: RootState) => state.user);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const { id } = useParams();
  const [dataChanged, setDataChanged] = useState(false);
  const user = usersList.data?.content.find(u => u.userId === userId);
  const officeListTemp = useSelector((state: RootState) => state.office.officeList);
  const officeList = !insideOffice ? officeListTemp : { data: { content: [] } };
  const { currentOffice } = useSelector((state: RootState) => state.office);

  let roleOptionsInitialState = [
    {
      title: "System Superuser",
      key: UserType.SystemAdmin,
      selected: user?.userType === UserType.SystemAdmin,
      dependents: [UserType.OfficeAdmin, UserType.Accounting, UserType.User],
      disabled: false,
      level: UserTypeLevel[UserType.SystemAdmin],
    },
    {
      title: "Office Superuser",
      key: UserType.OfficeAdmin,
      selected: user?.userType === UserType.OfficeAdmin,
      dependents: [],
      disabled: false,
      level: UserTypeLevel[UserType.OfficeAdmin],
    },
    {
      title: "Accounting",
      key: UserType.Accounting,
      selected: user?.userType === UserType.Accounting,
      dependents: [],
      disabled: false,
      level: UserTypeLevel[UserType.Accounting],
    },
    {
      title: "User",
      key: UserType.User,
      selected: user?.userType === UserType.User,
      dependents: [],
      disabled: false,
      level: UserTypeLevel[UserType.User],
    },
  ];

  if (insideOffice) {
    roleOptionsInitialState = roleOptionsInitialState.filter(item => item.key !== UserType.SystemAdmin);
  }

  if (user?.userType === UserType.SystemAdmin || user?.userType === UserType.OfficeAdmin) {
    checkBoxOptions.forEach(perm => {
      perm.selected = true;
      perm.disabled = true;
    });
  }

  const [permissionCheckBoxes, setPermissionCheckBoxes] = useState(
    checkBoxOptions.map(opt => ({ ...opt, selected: user?.permissions.includes(opt.key) }))
  );

  const [roleOptions, setRoleOptions] = useState(roleOptionsInitialState);
  const [apiError, setApiError] = useState(false);

  const getIsPCP = (offices: Office[] | undefined, userId: string) => {
    if (!offices) return false;
    for (let i = 0; i < offices.length; i++) {
      const selectedOffice = offices[i];
      if (selectedOffice.primaryCareProviders?.some(pcp => pcp.userId === userId)) {
        return true;
      }
    }
    return false;
  };

  const [formData, setFormData] = useState<EditFormDataType[]>([]);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [roleOrPermissionChange, setRoleOrPermissionChange] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [primaryCareProvider, setPrimaryCareProvider] = useState<boolean>(getIsPCP(user?.offices, user?.userId || ""));
  const [legal, setLegal] = useState<boolean>(user?.permissions.includes(AuthPermission?.Legal) || false);
  const selectedOfficeId = !!formData.find(item => item.id === "office")?.value;

  const handleChange = useCallback(
    (id: string, value: string) => {
      setDataChanged(true);
      const updated = formData.map(v => {
        if (v.id !== id) return v;

        let isError = value === "";
        let formattedValue = value;

        switch (v.id) {
          case "email":
            isError = !validateEmail(value);
            break;
          case "phone":
            if (value.trim()) {
              formattedValue = formatPhoneNumber(value);
              isError = !Phone_Fax_Regex.test(formattedValue);
            } else {
              formattedValue = "";
              isError = false;
            }
            break;
          case "office":
            const selectedRole = roleOptions.find(r => r.selected)?.key;
            if (selectedRole === UserType.SystemAdmin) {
              isError = false;
            }
            break;
          default:
            isError = value === "";
        }

        return {
          ...v,
          value: formattedValue,
          isError,
        };
      });

      setFormData(updated);
    },
    [formData, roleOptions]
  );

  const onPrimaryProviderChange = () => {
    setDataChanged(true);
    setPrimaryCareProvider(!primaryCareProvider);
  };

  const validateForm = () => {
    return formData.every(v => {
      return v.required ? !v.isError : true;
    });
  };

  const onCheckBoxChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    setDataChanged(true);
    setRoleOrPermissionChange(true);
    const checks = [...permissionCheckBoxes];
    checks[index].selected = !checks[index].selected;

    if (event.currentTarget.name === "Edit Office" && event.currentTarget.checked) {
    }
    setPermissionCheckBoxes(checks);
  };

  const onRoleOptionSelect = (index: number) => {
    setDataChanged(true);
    setRoleOrPermissionChange(true);
    const tempRoleOption = [...roleOptions];
    tempRoleOption.forEach((v, i, a) => {
      if (i === index) {
        a[i].selected = !v.selected;
      } else {
        a[i].selected = false;
      }
    });

    if (tempRoleOption[index].key === UserType.SystemAdmin || tempRoleOption[index].key === UserType.OfficeAdmin) {
      const tempPermissions = [...permissionCheckBoxes];
      tempPermissions.forEach((value, i, array) => {
        array[i].disabled = tempRoleOption[index].selected;
        array[i].selected = tempRoleOption[index].selected;
      });
      setPermissionCheckBoxes(tempPermissions);
    }

    if (tempRoleOption[index].key === UserType.Accounting) {
      const tempPermissions = [...permissionCheckBoxes];
      tempPermissions.forEach((value, i, array) => {
        if (value.key === AuthPermission.Report || value.key === AuthPermission.Finance) {
          array[i].disabled = tempRoleOption[index].selected;
          array[i].selected = tempRoleOption[index].selected;
        } else {
          array[i].disabled = false;
          array[i].selected = false;
        }
      });
      setPermissionCheckBoxes(tempPermissions);
    }
    if (tempRoleOption[index].key === UserType.User) {
      const tempPermissions = [...permissionCheckBoxes];
      tempPermissions.forEach((value, i, array) => {
        array[i].disabled = false;
        array[i].selected = false;
      });
      setPermissionCheckBoxes(tempPermissions);
    }
    setRoleOptions(tempRoleOption);
  };

  const handleConfirmationBox = (valueSelected: "yes" | "no") => {
    if (valueSelected === "no") {
      setConfirmationOpen(false);
    }
    if (valueSelected === "yes") {
      saveUpdatedUser();
      setConfirmationOpen(false);
    }
  };

  const handleSave = () => {
    if (validateForm())
      if (!roleOrPermissionChange) {
        saveUpdatedUser();
      } else {
        setConfirmationOpen(true);
      }
  };

  const saveUpdatedUser = () => {
    if (dataChanged) {
      setIsLoading(true);
      const email = formData.find(f => f.id === "email")?.value;
      const firstName = formData.find(f => f.id === "firstName")?.value;
      const lastName = formData.find(f => f.id === "lastName")?.value;
      const phone = formData.find(f => f.id === "phone")?.value;
      const officeId = formData.find(f => f.id === "office")?.value;
      const userType = roleOptions.find(role => role.selected)?.key;
      const jobTitleId = formData.find(f => f.id === "jobTitle")?.value;
      const permissions = permissionCheckBoxes.filter(per => per.selected).map(p => p.key);
      if (userType === UserType.SystemAdmin) {
        permissions.push(AuthPermission.Admin);
      }
      if (legal === true) {
        permissions.push(AuthPermission.Legal);
      }
      if (user?.userId) {
        const updatedUser: UserUpdate = {
          userId: user?.userId,
          email,
          firstName,
          lastName,
          officeId,
          permissions,
          primaryCareProvider: primaryCareProvider,
          userType,
          phone,
          jobTitleId,
        };
        if (!phone) {
          delete updatedUser["phone"];
        }
        if (id && insideOffice) {
          dispatch(
            updateOfficeUser({
              officeId: id,
              user: updatedUser,
            })
          )
            .then(action => {
              if (action.payload !== null) {
                dispatch(getOffice({ officeId: id }));
                dispatch(getUser());
                toast?.openToast("User has been updated.");
                onSuccess();
                onCancelClick();
              } else {
                setApiError(true);
              }
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        } else if (currentUser?.userType === UserType.SystemAdmin) {
          dispatch(
            updateUser({
              user: updatedUser,
            })
          )
            .then(action => {
              if (action.payload !== null) {
                if (currentOffice?.officeId) {
                  dispatch(getOffice({ officeId: currentOffice?.officeId }));
                }
                dispatch(getUser());
                toast?.openToast("User has been updated.");
                onSuccess();
                onCancelClick();
              } else {
                setApiError(true);
              }
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
          toast?.openToast();
        }
      }
    }
  };

  const renderInputFields = () => {
    const items = formData.map(form => {
      const commonProps = {
        onChangeFunc: (e: any) => handleChange(form.id, e.currentTarget.value),
        value: form.value,
        placeholder: form.placeholder,
        errorMsg: form.errorMsg,
        isRequired: form.required,
        isError: form.isError && errorInitiated,
        label: form.label,
        isDarkBg: true,
        key: form.id,
      };
      switch (form.type) {
        case "text":
        case "phone":
          return (
            <div className="py-2" key={form.id}>
              <TextInput {...commonProps} onBlur={form.type === "text" ? e => handleChange(form.id, e.currentTarget.value.trim()) : undefined} />
            </div>
          );
        case "select":
          return (
            <div className="py-2" key={form.id}>
              <label className="text-sm font-medium text-docsigna-blue-dark block w-full mb-1">
                {form.label}
                {form.required && <span className="text-red-500">*</span>}
              </label>
              <select
                onChange={e => handleChange(form.id, e.currentTarget.value)}
                defaultValue={form.placeholder}
                className="general-select w-full bg-docsigna-pink-light">
                <option value={form.placeholder} disabled>
                  {form.placeholder}
                </option>
                {form.options?.map((val, i) => (
                  <option key={i} value={val.value}>
                    {val.title}
                  </option>
                ))}
              </select>
              {form.isError && errorInitiated && <p style={{ color: colors.Red }}>Please select an office.</p>}
            </div>
          );
        case "select-new":
          return !roleOptions[0].selected || (roleOptions[0].selected && selectedOfficeId) ? (
            <div className="py-2">
              <SelectNew
                title={form.label}
                required={true}
                value={commonProps.value}
                placeHolder={form.placeholder}
                onSelect={e => handleChange(form.id, e.target.value)}
                options={jobTitles.map(item => {
                  return { label: item.name, value: item.jobTitleId };
                })}
                errorMsg={commonProps.isError ? commonProps.errorMsg : ""}
                isDarkBg
              />
            </div>
          ) : null;
        default:
          return null;
      }
    });
    return items;
  };

  useEffect(() => {
    if (!insideOffice)
      if (officeList.data?.content) {
        const temp = [...formData];
        temp.forEach(val => {
          if (val.id === "office") {
            val.options = officeList.data?.content?.map(off => ({ title: off.name, value: off.officeId }));
          }
        });
        setFormData(temp);
      }
  }, [officeList]);

  useEffect(() => {
    setPrimaryCareProvider(getIsPCP(user?.offices, user?.userId || ""));
    if (user) {
      const officeName = user?.offices?.length ? user?.offices[0].name : "";
      const officeId = user?.offices?.length ? user?.offices[0].officeId : "";
      const formDataInit: EditFormDataType[] = [
        {
          id: "firstName",
          label: "First Name",
          placeholder: "Enter first name",
          value: user?.firstName || "",
          errorMsg: "Please enter first name.",
          isError: !user?.firstName,
          required: true,
          type: "text",
        },
        {
          id: "lastName",
          label: "Last Name",
          placeholder: "Enter last name",
          value: user?.lastName || "",
          errorMsg: "Please enter last name.",
          isError: !user?.lastName,
          required: true,
          type: "text",
        },
        {
          id: "email",
          label: "Email",
          placeholder: "Enter email address",
          value: user?.email || "",
          errorMsg: "Please enter email address.",
          isError: !validateEmail(user?.email),
          required: true,
          type: "text",
        },
        {
          id: "phone",
          label: "Phone Number (Optional)",
          placeholder: "Enter phone number",
          required: false,
          errorMsg: "Please enter a valid phone number",
          value: formatPhoneNumber(user?.phone ?? ""),
          isError: user?.phone ? !validatePhoneOrFaxNumber(user?.phone ?? "") : false,
          type: "phone",
        },
        {
          id: "office",
          label: "Office",
          placeholder: officeName || "Select Office",
          value: officeId || "",
          errorMsg: "Please select an office.",
          isError: user?.userType === UserType.SystemAdmin && !officeId ? false : !officeId,
          required: user?.userType === UserType.SystemAdmin && !officeId ? false : !!officeId,
          type: "select",
          options: officeList.data?.content?.map(off => ({ title: off.name, value: off.officeId })) || [],
        },
        {
          id: "jobTitle",
          label: "Job Title",
          placeholder: "Select job title",
          value: user?.jobTitle?.jobTitleId ?? "",
          errorMsg: "Please select job title",
          isError: user?.userType === UserType.SystemAdmin && !officeId ? false : !user?.jobTitle,
          required: user?.userType === UserType.SystemAdmin && !officeId ? false : !!officeId,
          type: "select-new",
          options: [],
        },
      ];
      setFormData(formDataInit);
    }
  }, [user]);

  useEffect(() => {
    const officeId = formData.find(item => item.id === "office")?.value;
    if (formData.length) {
      const updated = formData.map(v => {
        switch (v.id) {
          case "office":
            return {
              ...v,
              required: !roleOptions[0].selected,
              isError: !roleOptions[0].selected && !v.value,
            };
          case "jobTitle":
            return {
              ...v,
              value: v.value,
              isError: roleOptions[0].selected && !officeId ? false : !v.value,
              required: roleOptions[0].selected && !officeId ? false : true,
            };
          default:
            break;
        }

        return {
          ...v,
        };
      });

      setFormData(updated);
    }
  }, [roleOptions]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.userType === UserType.SystemAdmin) {
        dispatch(listOfficeDataForSelectList({ page: 0, sortType: "name,asc", size: 100 }));
      }
      dispatch(getJobTitles());
    }
  }, []);

  return (
    <>
      <div className="px-8 py-5">
        {apiError ? <p style={{ color: colors.Red }}>An error has occurred, please try again</p> : null}
        {renderInputFields()}
        <div className="py-2">
          <label className="text-sm font-bold text-gray-700 block w-full mb-1">
            Role<span className="text-red-500">*</span>
          </label>
          {roleOptions.map((role, index) => {
            return (
              <label key={role.key} className="block w-full mb-1 relative">
                <input
                  type={"radio"}
                  checked={role.selected}
                  disabled={role.disabled}
                  style={role.disabled ? { backgroundColor: "rgb(156 163 175)", cursor: "not-allowed" } : {}}
                  onChange={() => onRoleOptionSelect(index)}
                  className="general-radio"
                />
                <span className="text-base ml-2">{role.title}</span>
              </label>
            );
          })}
        </div>
        <div className="py-2">
          <label className="text-sm font-bold text-gray-700 block w-full mb-1">Permissions</label>
          {permissionCheckBoxes.map((data, index) => {
            const disabled = data.disabled;
            const checked = data.selected;
            return (
              <Checkbox
                disabled={disabled}
                key={index}
                label={data.title}
                name={data.key}
                checked={checked}
                onChange={e => (!disabled ? onCheckBoxChange(index, e) : null)}
                isDarkBg={true}
              />
            );
          })}
          <Checkbox
            disabled={false}
            label={"Legal"}
            // name={data.key}
            checked={legal}
            onChange={() => {
              setLegal(!legal);
              setRoleOrPermissionChange(true);
              setDataChanged(true);
            }}
            isDarkBg={true}
          />
          <Checkbox label="Primary Care Provider" checked={primaryCareProvider} onChange={onPrimaryProviderChange} isDarkBg={true} />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => {
              onCancelClick();
            }}
            disabled={isLoading}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button
              onClickFunc={() => {
                setErrorInitiated(true);
                handleSave();
              }}
              AdditionalClassNames="pointer px-5"
              disabled={(!validateForm() && errorInitiated) || isLoading}
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmationOpen}
        description={`This will change the permissions of this user, proceed?`}
        title={""}
        successButtonText={"Yes"}
        handleSuccess={() => {
          handleConfirmationBox("yes");
        }}
        failureButtonText={"No"}
        handleFailure={() => {
          handleConfirmationBox("no");
        }}
      />
    </>
  );
};

export default EditUser;
