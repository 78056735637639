import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { BreadCrumbs, Button, ConfirmationDialog, Pagination, ProtectedComponent, TextInput, UserSideBar, VerifyPatient } from "../../components";
import { TableHeader, TableRow } from "../../components/TableComponents/TableComponents";
import { headersType } from "../../models";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { useNavigate, useParams } from "react-router-dom";
import { cancelOfficePatientInvitation, resendOfficePatientInvitation, sortObj } from "../../redux/slices/OfficeSlice";
import { searchOfficePatients } from "../../redux/slices/OfficeSlice";
import { CircularProgress } from "@mui/material";
import { getSorting } from "../../utils";
import EditPatientProfile from "../../components/forms/EditPatientProfile/EditPatientProfile";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NotFound from "../../assets/images/no-patients.png";
import { AuthPermission, PatientStates, UserState, authService } from "../../services/auth.service";
import InvitePatient from "../../components/forms/InvitePatient/InvitePatient";
import { startCase } from "lodash";
import { SESSION_STORAGE_USER_STATE_PATIENT, UserStateTypes, colors } from "../../utils/constants";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { User } from "../../models/user.model";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDebouncedCallback } from "use-debounce";

const headers: headersType[] = [
  {
    title: "Name",
    extraClasses: "w-full pl-0 truncate",
    sortKey: "lastName",
    isClickable: true,
  },
  {
    title: "Email",
    extraClasses: "w-full hidden lg:block truncate",
    sortKey: "email",
    isClickable: true,
  },
  {
    title: "Verified",
    extraClasses: "w-120 hidden lg:block",
    sortKey: "",
    isClickable: false,
  },
  {
    title: "Status",
    extraClasses: "w-180 hidden lg:block",
    sortKey: "",
    isClickable: false,
  },
  {
    title: "Plan",
    extraClasses: "w-120 hidden lg:block text-docnote-pink-dark",
    sortKey: "",
    isClickable: false,
  },
];
const OfficePatients = ({ isAdminPage }: { isAdminPage: boolean }) => {
  const navigate = useNavigate();
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { patientList, loading } = useSelector((state: RootState) => state.office);
  const [editPatientOpen, setEditPatientOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [invitePatient, setInvitePatient] = useState(false);
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [sortMethods, setSortMethods] = useState<boolean[]>(headers.map(() => false));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(0);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [extraClasses] = useState<string[]>(headers.map(head => head.extraClasses));
  const [term, setTerm] = useState<string>("");
  const [sort, setSort] = useState({
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "lastName,asc",
  });
  const [showTooltip, setShowTooltip] = useState<number>(-1);
  const [selectedUserData, setselectedUserData] = useState<User | undefined>(undefined);
  const [selectedUserState, setSelectedUserState] = useState<PatientStates>(PatientStates.All);

  const updateTable = (sortObj?: sortObj, searchText?: string) => {
    const pageNumToBeCalled = sortObj?.pageNumber ?? sort.pageNumber;
    const sizeToBeCalled = sortObj?.rowsPerPage ?? sort.rowsPerPage;
    const sortBy = sortObj?.sortedBy ?? sort.sortedBy;
    const searchT = searchText ?? "";
    const oldUserState = sessionStorage.getItem(SESSION_STORAGE_USER_STATE_PATIENT);
    let userState = "";
    if (oldUserState && oldUserState !== null) {
      userState = oldUserState;
    }
    if (id) {
      dispatch(
        searchOfficePatients({
          officeId: id,
          term: searchT.trim().length >= 2 ? searchT.trim() : "",
          page: pageNumToBeCalled,
          size: sizeToBeCalled,
          sort: sortBy,
          userState: userState?.trim()?.length ? userState.trim() : undefined,
        })
      ).then(() => {
        setSort({ ...sort, pageNumber: pageNumToBeCalled, rowsPerPage: sizeToBeCalled, sortedBy: sortBy });
      });
    }
  };

  const handleSort = (index: number) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    setActiveSortIndex(index);
    updateTable({ sortedBy: sortMethodString, pageNumber: 0, rowsPerPage: sort.rowsPerPage }, term);
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: newPage, rowsPerPage: sort.rowsPerPage }, term);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: 0, rowsPerPage: parseInt(event.target.value, 10) }, term);
  };

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));
  const authorized = authService.currentUserPermissions.includes(AuthPermission.User);
  const activeToolTipOptions: string[] = ["View Profile", "Edit Profile", "Disable User"];
  const invitedToolTipOptions: string[] = ["Resend Invite", "Cancel Invite"];
  const disabledToolTipOptions: string[] = ["View Profile", "Edit Profile", "Enable User"];

  const debounced = useDebouncedCallback(value => {
    if (value && value.trim().length >= 2) {
      updateTable({ ...sort, pageNumber: 0 }, value.trim());
    }
  }, 1000);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    const oldSearchTerm = term;
    setTerm(input);
    debounced(input);
    if (oldSearchTerm !== "" && input.length <= 1) {
      updateTable(sort);
    }
  };

  const handleToolTipOpen = (index: number) => {
    setShowTooltip(index);
  };

  const handleToolTipClose = () => {
    setShowTooltip(-1);
  };

  const handleRowClick = (data: User) => {
    navigate(data.userId);
  };

  const onAgreeClick = () => {
    if (selectedUserData && id) {
      dispatch(cancelOfficePatientInvitation({ userId: selectedUserData?.userId, officeId: id })).then(response => {
        if (response.payload) updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, term);
      });
    }
    setselectedUserData(undefined);
    setConfirmationOpen(false);
  };

  const onDisagreeClick = () => {
    setConfirmationOpen(false);
    setselectedUserData(undefined);
  };

  const handleTooltipClick = async (type: string, emailId: string) => {
    const selectedUser = patientList.data?.content.find(item => item.email === emailId);
    setselectedUserData(selectedUser);
    const userId = selectedUser?.userId;
    switch (type) {
      case "Resend Invite":
        if (userId && id) {
          dispatch(resendOfficePatientInvitation({ userId: userId, officeId: id })).then(res => {
            if (res.payload) {
              toast?.openToast("User invitation sent");
            } else {
              toast?.openToast("Unable to resend invitation");
            }
          });
        }
        break;
      case "Cancel Invite":
        setConfirmationOpen(true);
        break;
      case "Edit Profile":
        if (selectedUser) setSelectedUser(selectedUser);
        break;
      case "View Profile":
        if (selectedUser) navigate(`${selectedUser.userId}`);
        break;
      default:
        toast?.openToast();
        break;
    }
  };

  const onChangeUserState = (val: PatientStates) => {
    setSelectedUserState(val);
    sessionStorage.setItem(SESSION_STORAGE_USER_STATE_PATIENT, val);
    updateTable(sort, term);
  };

  useEffect(() => {
    updateTable();
    const oldUserState = sessionStorage.getItem(SESSION_STORAGE_USER_STATE_PATIENT);
    if (oldUserState && oldUserState !== null) {
      setSelectedUserState(oldUserState as PatientStates);
    } else {
      setSelectedUserState(PatientStates.All);
    }
  }, []);

  return (
    <>
      <main>
        <div className="container mx-auto p-8">
          {!isAdminPage ? <OfficeSectionsHeader showMeta={true} /> : <BreadCrumbs items={[{ key: "#", title: "Patients" }]} />}
          <div className="block lg:flex justify-between items-center mb-6">
            <div className="w-full lg:w-1/4 mb-5 lg:mb-0">
              <h2 className="heading mb-0 lg:mb-5 uppercase">Patients</h2>
              <p className="text-base font-medium">Manage your patients here.</p>
            </div>
            <div className="block md:flex lg:justify-end w-full lg:w-3/4">
              <select
                className={`general-select mr-5 w-full md:w-1/4 mb-2 md:mb-0`}
                value={selectedUserState}
                onChange={e => onChangeUserState(e.target.value as PatientStates)}>
                <option value={UserStateTypes.All}>{PatientStates.All}</option>
                <option value={UserStateTypes.Active}>{PatientStates.Active}</option>
                <option value={UserStateTypes.Invited}>{PatientStates.Invited}</option>
                <option value={UserStateTypes.Disabled}>{PatientStates.Disabled}</option>
              </select>
              <div className="flex items-center">
                <div className="relative w-full">
                  <TextInput value={term} onChangeFunc={handleSearch} placeholder={"Search patients"} extraInputClass="w-full" isSearchInput={true} />
                </div>
              </div>
              <ProtectedComponent requiredPermission={[AuthPermission.User]}>
                <div className="block md:flex md:justify-end items-center ml-0 md:ml-5">
                  <span className="md:mr-5 font-medium text-center hidden md:inline-block">or</span>
                  <Button
                    text="Invite Patient"
                    onClickFunc={() => setInvitePatient(true)}
                    AdditionalClassNames="whitespace-nowrap w-auto mt-3 md:mt-0"
                  />
                </div>
              </ProtectedComponent>
            </div>
          </div>

          {loading === false ? (
            <div className="block">
              {patientList?.data?.content && patientList?.data?.content?.length > 0 ? (
                <>
                  <div className="flex justify-between items-center border-b border-docsigna-blue-light">
                    {headers.map((head, index) => (
                      <TableHeader
                        title={head.title}
                        index={index}
                        key={index}
                        sortIcon={sortIcons[index]}
                        handleSort={handleSort}
                        extraClassesHeader={head.extraClasses}
                        isClickable={head.isClickable}
                      />
                    ))}
                    <div className="w-40 p-5 py-3">
                      <span className="text-base text-docsigna-purple-dark inline-block w-5"></span>
                    </div>
                  </div>
                  {patientList?.data?.content?.map((rowData, index) => {
                    const sortedData = {
                      name: `${rowData.firstName ?? "-"} ${rowData.lastName ?? ""}`,
                      email: (
                        <a href={`mailto:${rowData.email}`} className="cursor-pointer hover:underline" onClick={e => e.stopPropagation()}>
                          {rowData.email}
                        </a>
                      ),
                      Verified:
                        rowData.userState === UserState.Active ? <FiberManualRecordIcon htmlColor={`${colors.CyanBlue}`} fontSize="small" /> : "",
                      status: startCase(rowData.userState),
                      plan: rowData.blockFee?.blockFeeId ? <FiberManualRecordIcon htmlColor={`${colors.Lilac}`} fontSize="small" /> : "",
                    };
                    return (
                      <TableRow
                        data={Object.values(sortedData)}
                        key={index}
                        onRowClick={() => handleRowClick(rowData)}
                        tooltipVisible={showTooltip === index}
                        handleOpenTooltip={() => handleToolTipOpen(index)}
                        handleCloseTooltip={() => handleToolTipClose()}
                        id={rowData.email}
                        extraClassesRow={extraClasses}
                        tooltipOptions={
                          authorized
                            ? rowData.userState === UserStateTypes.Invited
                              ? invitedToolTipOptions
                              : rowData.userState === UserStateTypes.Active
                              ? activeToolTipOptions
                              : rowData.userState === UserStateTypes.Active
                              ? disabledToolTipOptions
                              : ["View Profile"]
                            : ["View Profile"]
                        }
                        handleTooltipClick={handleTooltipClick}
                      />
                    );
                  })}
                  <Pagination
                    colSpan={10}
                    count={patientList.data ? patientList.data.totalElements : 0}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    page={sort.pageNumber}
                    rowsPerPage={sort.rowsPerPage}
                  />
                </>
              ) : (
                <DataNotFound image={NotFound} text="No patients found" />
              )}
            </div>
          ) : (
            <CircularProgress size={40} color={"info"} style={{ marginLeft: "50%", marginTop: "10%" }} />
          )}
        </div>
        <UserSideBar
          onClickOutside={() => {
            setEditPatientOpen(false);
          }}
          title="Patient Details"
          visible={editPatientOpen}>
          <VerifyPatient onClickCancel={() => setEditPatientOpen(false)} fromOffice={true} setIsLoading={() => null} />
        </UserSideBar>
        {selectedUser ? (
          <UserSideBar
            onClickOutside={() => {
              !isLoading ? setSelectedUser(null) : null;
            }}
            title={`${selectedUser?.firstName ?? "Invited Patient"} ${selectedUser?.lastName ?? ""}`}
            visible={selectedUser}>
            <EditPatientProfile
              userData={selectedUser}
              isEditable={true}
              onClickCancel={val => {
                setSelectedUser(null);
                if (val) {
                  updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, term);
                }
              }}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          </UserSideBar>
        ) : null}
        {invitePatient && id ? (
          <UserSideBar
            onClickOutside={() => {
              setInvitePatient(false);
            }}
            title={`Invite Patient(s)`}
            visible={true}
            LeftbuttonText="Cancel"
            RightbuttonText="Invite">
            <InvitePatient
              officeId={id}
              cancelInvite={val => {
                setInvitePatient(false);
                if (val) {
                  updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, term);
                }
              }}
            />
          </UserSideBar>
        ) : null}
        {confirmationOpen ? (
          <ConfirmationDialog
            open={confirmationOpen}
            title={`Cancel invitation for ${selectedUserData !== undefined ? selectedUserData.email : ""}`}
            description={""}
            successButtonText={"Agree"}
            handleSuccess={onAgreeClick}
            failureButtonText={"Disagree"}
            handleFailure={onDisagreeClick}
          />
        ) : null}
      </main>
    </>
  );
};

export default OfficePatients;
