import React from "react";

interface BoxPropType {
  title: string;
  children: JSX.Element;
  extraClasses?: string;
}

const Box = ({ children, title, extraClasses = "" }: BoxPropType) => {
  return (
    <div className="w-full md:pr-12 lg:pr-24 mb-6 md:mb-0">
      <div className="flex justify-between items-center mb-3">
        <p className="text-base font-semibold">{title}</p>
      </div>
      <div className={`p-6  w-full border border-docsigna-pink rounded-lg mb-6 ${extraClasses}`}>{children}</div>
    </div>
  );
};

export default Box;
