import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext/AuthContext";

const ProtectedRoute = ({ userRoles }: any) => {
  const location = useLocation();
  const auth = useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);
  let email = searchParams.get("email") || "";
  email = email ? email.replace(/ /g, "+") : "";
  if (auth?.isAuthenticated === true && auth?.userType) {
    if (userRoles.includes(auth?.userType)) return <Outlet />;
    return <Navigate to="/" />;
  } else if (auth?.isAuthenticated === false) {
    return <Navigate to={`/login${email ? `?email=${email}` : ""}`} />;
  } else {
    // if auth is undefiend
    return null;
  }
};

export default ProtectedRoute;
