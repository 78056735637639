import { combineReducers, configureStore, PayloadAction } from "@reduxjs/toolkit";
import CurrentUserSlice from "./slices/CurrentUserSlice";
import OnBoardingSlice from "./slices/OnBoardingSlice";
import UserSlice from "./slices/UserSlice";
import OfficeSlice from "./slices/OfficeSlice";
import FileSlice from "./slices/FileSlice";
import ServiceSlice from "./slices/ServiceSlice";
import ErrorSlice from "./slices/ErrorSlice";
import RequestSlice from "./slices/RequestSlice";
import OfficeRequestSlice from "./slices/OfficeRequestSlice";
import PatienSlice from "./slices/PatientSlice";
import FeeSlice from "./slices/FeeSlice";
import PaymentSlice from "./slices/PaymentSlice";
import NotificationSlice from "./slices/NotificationSlice";
import FinanceSlice from "./slices/FinanceSlice";
import BlockFeeSlice from "./slices/BlockFeeSlice";
import SearchSlice from "./slices/SearchSlice";
import FormSlice from "./slices/FormSlice";

const rootReducer = combineReducers({
  user: UserSlice,
  office: OfficeSlice,
  currentUser: CurrentUserSlice,
  onBoarding: OnBoardingSlice,
  file: FileSlice,
  service: ServiceSlice,
  error: ErrorSlice,
  request: RequestSlice,
  officeRequest: OfficeRequestSlice,
  patient: PatienSlice,
  fees: FeeSlice,
  payment: PaymentSlice,
  notifications: NotificationSlice,
  finance: FinanceSlice,
  blockFee: BlockFeeSlice,
  searchResults: SearchSlice,
  form: FormSlice,
});

export const resetState = () => ({
  type: "RESET_STATE",
});

const appReducer = (state: any, action: PayloadAction) => {
  let updatedState = state;
  if (action.type === "RESET_STATE") {
    updatedState = undefined;
  }
  return rootReducer(updatedState, action);
};

export const store = configureStore({
  reducer: appReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
