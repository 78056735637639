/* eslint-disable no-empty-pattern */
/* eslint-disable no-unreachable */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiErrorCode } from "../../models/api-error.model";
import { RequestPatientUpdate } from "../../models/request-patient-update.model";
import { OfficeService } from "../../services/office.service";
import { logger } from "../../utils/logger";
import { Receipt } from "../../models/receipt.model";
import { ApiPage } from "../../models/api-page.model";
import { sortObj } from "./OfficeSlice";
import { Document } from "../../models/document.model";

const log = logger.getLogger('PatientErrors');
export interface PatientRequestListType {
  data: null | PatientResponseType;
  sortedBy: string;
}

interface RequestDataType {
  id: string;
  service: string;
  ticket: string;
  provider: string;
  status: string;
  create: string;
  commentsCount: number;
  newCommentsCount: number;
}

interface PatientResponseType {
  content: RequestDataType[];
  totalElements: number;
}

const dummyRequestsData = {
  content: [
    {
      id: "1",
      service: "Medical Form",
      ticket: "# 1234567",
      provider: "Dr William Mahoney",
      status: "Under Review",
      create: "2022-01-25T10:00:00",
      commentsCount: 3,
      newCommentsCount: 1,
      tooltipVisible: false,
    },

    {
      id: "2",
      service: "Medical Form",
      ticket: "# 1234567",
      provider: "Dr William Mahoney",
      status: "Closed",
      create: "2022-12-25T10:00:00",
      commentsCount: 3,
      newCommentsCount: 1,
      tooltipVisible: false,
    },

    {
      id: "3",
      service: "Medical Form",
      ticket: "# 1234567",
      provider: "Dr William Mahoney",
      status: "Under Review",
      create: "2022-01-25T10:00:00",
      commentsCount: 3,
      newCommentsCount: 1,
      tooltipVisible: false,
    },

    {
      id: "4",
      service: "Medical Form",
      ticket: "# 1234567",
      provider: "Dr William Mahoney",
      status: "Under Review",
      create: "2022-01-25T10:00:00",
      commentsCount: 3,
      newCommentsCount: 1,
      tooltipVisible: false,
    },
  ],
  totalElements: 4,
};

export interface PatientRequestState {
  loading: boolean | undefined;
  errorMessage: string;
  error: boolean;
  errorType: ApiErrorCode;
  apiSucess: boolean;
  requestsList: PatientRequestListType;
  toastVisible: boolean;
  openRequestId: string;
  verificationError: boolean;
  showCurrentSubscription: boolean;
  receipts: Receipt[];
  documents: Document[];
  sortReceipts: sortObj;
  sortDocuments: sortObj;
}

const sort = {
  start: 0,
  end: 10,
  pageNumber: 0,
  rowsPerPage: 10,
  searchTerm: '',
  sortedBy: "name,asc",
}

const initialState: PatientRequestState = {
  loading: undefined,
  errorMessage: "",
  error: false,
  errorType: ApiErrorCode.UNKNOWN,
  apiSucess: false,
  requestsList: { data: null, sortedBy: "" },
  toastVisible: false,
  openRequestId: "",
  verificationError: false,
  showCurrentSubscription: false,
  receipts: [],
  documents: [],
  sortReceipts: sort,
  sortDocuments: sort
};

export const listPatientRequest = createAsyncThunk("patientRequestApi/list", async ({}, { rejectWithValue }) => {
  try {
    return true;
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(ApiErrorCode.UNKNOWN);
  }
});

export const searchPatientRequest = createAsyncThunk("patientRequestApi/list", async ({}, { rejectWithValue }) => {
  try {
    return true;
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(ApiErrorCode.UNKNOWN);
  }
});

export const verifyPatient = createAsyncThunk(
  "patientRequestApi/verifyPatient",
  async (
    {
      officeId,
      patientUpdate,
    }: {
      officeId: string;
      patientUpdate: RequestPatientUpdate;
    },
    { rejectWithValue }
  ) => {
    try {
      const data = await OfficeService.updateOfficeRequestPatient(officeId, patientUpdate);
      return { data };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(ApiErrorCode.UNKNOWN);
    }
  }
);

export const getPatientReceipts = createAsyncThunk(
  "patientRequestApi/getPatientReceipts",
    async ({ officeId, userId, page, size, sort }: {officeId:string; userId: string, page?: number; size?: number; sort?: string; }, {rejectWithValue}) => {
    try {
      const res = OfficeService.getPatientReceipts(officeId, userId, page, size, sort);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getPatientDocuments = createAsyncThunk(
  "patientRequestApi/getPatientDocuments",
    async ({ officeId, userId, page, size, sort }: {officeId:string; userId: string, page?: number; size?: number; sort?: string; }, {rejectWithValue}) => {
    try {
      const res = OfficeService.getPatientDocuments(officeId, userId, page, size, sort);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const PatientSlice = createSlice({
  name: "patientRequests",
  initialState,
  reducers: {
    setShowCurrentSubscription: (state, action) => {
      state.showCurrentSubscription = action.payload
    },
    resetState: state => {
      state.error = false;
      state.errorType = ApiErrorCode.UNKNOWN;
      state.loading = false;
      state.apiSucess = false;
    },
    open: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.toastVisible = true;
      setTimeout(() => {
        state.toastVisible = false;
      }, 2000);
    },
    close: state => {
      state.toastVisible = false;
      state.errorMessage = "";
    },
    getPatientServiceList: state => {
      state.requestsList.data = dummyRequestsData;
    },
    openRequest: (state, action) => {
      state.openRequestId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(listPatientRequest.pending, state => {
        if (state.requestsList.data === null) {
          state.loading = true;
        }
        state.error = false;
      })
      .addCase(listPatientRequest.fulfilled, state => {
        state.loading = false;
        state.error = false;
      })
      .addCase(listPatientRequest.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(verifyPatient.pending, state => {
        state.loading = true;
        state.verificationError = false;
      })
      .addCase(verifyPatient.rejected, state => {
        state.loading = true;
        state.verificationError = true;
      })
      .addCase(verifyPatient.fulfilled, (state, action) => {
        state.loading = true;
        if (!action.payload.data) {
          state.verificationError = true;
        } else {
          state.verificationError = false;
        }
      })
      .addCase(getPatientReceipts.pending, state => {
        state.loading = true;
      })
      .addCase(getPatientReceipts.rejected, state => {
        state.loading = false;
      })
      .addCase(getPatientReceipts.fulfilled, (state, action: PayloadAction<ApiPage<Receipt>>) => {
        state.loading = false;
        if (action.payload !== null) {
          const {content, totalElements, size, number} = action.payload;
          state.receipts = content;
          state.sortReceipts = {
            ...state.sortReceipts,
            pageNumber: number,
            rowsPerPage: size,
            totalElements: totalElements,
          }
        }
      })
      .addCase(getPatientDocuments.pending, state => {
        state.loading = true;
      })
      .addCase(getPatientDocuments.rejected, state => {
        state.loading = false;
      })
      .addCase(getPatientDocuments.fulfilled, (state, action: PayloadAction<ApiPage<Receipt>>) => {
        state.loading = false;
        if (action.payload !== null) {
          const {content, totalElements, size, number} = action.payload;
          state.documents = content;
          state.sortDocuments = {
            ...state.sortDocuments,
            pageNumber: number,
            rowsPerPage: size,
            totalElements: totalElements,
          }
        }
      });
  },
});

export const { getPatientServiceList, setShowCurrentSubscription } = PatientSlice.actions;
export default PatientSlice.reducer;
