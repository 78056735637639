import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiError, ApiErrorCode } from "../../models/api-error.model";
import { ApiPage } from "../../models/api-page.model";
import { Service, ServiceOwner } from "../../models/service.model";
import { ServiceService } from "../../services/service.service";
import { OfficeService } from "../../services/office.service";
import { setPreviousSelectedOfficeId } from "./OfficeSlice";
import { logger } from "../../utils/logger";

const log = logger.getLogger('ServiceErrors');
export interface ServiceState {
  error: boolean;
  errorType: ApiErrorCode;
  loading: boolean;
  apiSucess: boolean;
}

interface SearchServiceType {
  officeId?: string;
  term?: string;
  enabledOnly?: boolean;
  page: number;
  size: number;
  sort: string;
  serviceOwner?: ServiceOwner;
}

type ServiceListType = {
  sortedBy: string;
  data: ApiPage<Service> | null;
};

interface ListServiceType {
  searchTerm?: string;
  page: number;
  size: number;
  sortType: string;
  fullPageLoaderShown?: boolean;
}

interface sortObj {
  start: number;
  end: number;
  pageNumber: number;
  rowsPerPage: number;
  sortedBy: string;
  totalElements?:number;
  searchTerm?:string;
}

export interface ServiceStateType {
  error: boolean;
  errorType: ApiErrorCode;
  loading: boolean;
  apiSucess: boolean;
  serviceList: ServiceListType;
  lastSearchTerm: string;
  sort: sortObj;
  pageLoading: boolean;
  blockFeeServices: Service[];
  blockFeeServicesSortObj: sortObj;
}

type listServiceResponse = { data: ApiPage<Service>; sortType: string; page: number; size: number };

interface ListServiceType {
  officeId?: string;
  searchTerm?: string;
  page: number;
  size: number;
  sortType: string;
}

const sampleSortObj = {
  start: 0,
  end: 10,
  pageNumber: 0,
  rowsPerPage: 10,
  sortedBy: "name,asc",
  searchTerm: "",
}

const initialState: ServiceStateType = {
  loading: false,
  error: false,
  errorType: ApiErrorCode.UNKNOWN,
  apiSucess: false,
  serviceList: { data: null, sortedBy: "" },
  lastSearchTerm: "",
  sort: sampleSortObj,
  pageLoading: false,
  blockFeeServices: [],
  blockFeeServicesSortObj: sampleSortObj
};

export const addService = createAsyncThunk(
  "ServiceApi/add",
  async ({ officeId, name, description, price, addTax, enabled, sampleFileId, serviceBilledTo }: Service, { rejectWithValue }) => {
    // TODO : Make the sampleFileId dynamic, I've added an empty string to eliminate the type error.

    try {
      const res = await ServiceService.addService({ officeId, name, description, price, addTax, enabled, sampleFileId, serviceBilledTo });
      return res;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const updateService = createAsyncThunk("ServiceApi/update", async ({ serviceData }: { serviceData: Service }, { rejectWithValue }) => {
  try {
    const res = await ServiceService.updateService(serviceData);
    return res;
  } catch (e) {
    if ((e as ApiError).code === ApiErrorCode.EXISTS) {
      return rejectWithValue(ApiErrorCode.EXISTS);
    } else {
      log.warn(JSON.stringify(e));
      return rejectWithValue(ApiErrorCode.UNKNOWN);
    }
  }
});

export const getService = createAsyncThunk("ServiceApi/getService", async ({ serviceId }: { serviceId: string }, { rejectWithValue }) => {
  try {
    const res = await ServiceService.getService(serviceId);
    return res;
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(e);
  }
});

export const deleteService = createAsyncThunk("ServiceApi/deleteService", async ({ serviceId }: { serviceId: string }, { rejectWithValue }) => {
  try {
    const res = await ServiceService.deleteService(serviceId);
    return res;
  } catch (e) {
    if ((e as ApiError).code === ApiErrorCode.EXISTS) {
      return rejectWithValue(ApiErrorCode.EXISTS);
    } else {
      log.warn(JSON.stringify(e));
      return rejectWithValue(ApiErrorCode.UNKNOWN);
    }
  }
});

export const listServiceData = createAsyncThunk(
  "ServiceApi/list",
  async ({ officeId, page, sortType, size, fullPageLoaderShown }: ListServiceType, { rejectWithValue, dispatch }) => {
    try {
      if (officeId) {
        dispatch(setPreviousSelectedOfficeId(officeId));
        const res = await OfficeService.getOfficeServices(officeId, page, size, sortType);
        return { data: res, sortType: sortType, page, size, fullPageLoaderShown };
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchServiceData = createAsyncThunk(
  "ServiceApi/search",
  async ({ officeId, term, enabledOnly, page, size, sort, serviceOwner }: SearchServiceType, { rejectWithValue, dispatch }) => {
    try {
      if (officeId) {
        const res = await OfficeService.searchOfficeServices(
          officeId,
          term,
          enabledOnly,
          serviceOwner,
          page,
          size,
          sort.length > 0 ? sort : undefined
        );
        dispatch(setTableState({ page, size, sortType: sort }));
        return { data: res, sortType: sort, page, size };
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getAllServices = createAsyncThunk(
  "ServiceApi/getAllServices",
  async ({ page, sortType, size }: ListServiceType, { rejectWithValue }) => {
    try {
      const res = await ServiceService.getAllServices(page, size, sortType.length > 0 ? sortType : undefined);
      return { data: res, sortType: sortType, page, size };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const serviceServiceSearchServices = createAsyncThunk(
  "ServiceApi/searchServices",
  async ({ term, enabledOnly, page, size, sort }: SearchServiceType, { rejectWithValue, dispatch }) => {
    try {
      const res = await ServiceService.searchServices(term, enabledOnly, page, size, sort.length > 0 ? sort : undefined);
      dispatch(setTableState({ page, size, sortType: sort }));
      return { data: res, sortType: sort, page, size };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

// TODO: Do not use generic names for specific functions. This functions is specific to ServiceApi
export const setTableState = createAsyncThunk("ServiceApi/sortData", async ({ page, sortType, size }: ListServiceType, { rejectWithValue }) => {
  try {
    return { sortType, page, size };
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});

export const addOfficeService = createAsyncThunk(
  "ServiceApi/addOfficeService",
  async ({ officeId, serviceData }: { officeId: string; serviceData: Service }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.addOfficeService(officeId, serviceData);
      return res;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const updateOfficeService = createAsyncThunk(
  "ServiceApi/updateOfficeService",
  async ({ officeId, serviceData }: { officeId: string; serviceData: Service }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.updateOfficeService(officeId, serviceData);
      return res;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const copyServiceToOffice = createAsyncThunk(
  "ServiceApi/copyServiceToOffice",
  async ({ officeId, serviceId }: { officeId: string; serviceId: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.duplicateServiceToOffice(officeId, serviceId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const searchBlockFeeServices = createAsyncThunk(
  "ServiceApi/searchBlockFee",
  async ({ officeId, term, enabledOnly, page, size, sort }: SearchServiceType, { rejectWithValue, dispatch }) => {
    try {
      if (officeId) {
        const res = await OfficeService.searchOfficeServices(
          officeId,
          term,
          enabledOnly,
          ServiceOwner.Office,
          page,
          size,
          sort.length > 0 ? sort : undefined,
          true
        );
        return {data:res, sort, searchTerm: term};
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

type BlockFeeResponse  = {
  data: ApiPage<Service>,
  sort: string,
  searchTerm?: string,
}

export const ServiceSlice = createSlice({
  name: "ServiceApi",
  initialState,
  reducers: {
    listServices: state => {
      state.error = false;
      state.errorType = ApiErrorCode.UNKNOWN;
      state.loading = false;
      state.apiSucess = false;
    },
    resetStateServiceSlice: () => initialState,
    resetServiceTableSort: state => {
      state.sort = {
        start: 0,
        end: 10,
        pageNumber: 0,
        rowsPerPage: 10,
        sortedBy: "name,asc",
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addService.pending, state => {
        state.loading = false;
        state.error = false;
        state.apiSucess = false;
      })

      .addCase(addService.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload !== null) {
          state.apiSucess = true;
        } else {
          state.apiSucess = false;
        }
        state.loading = false;
        state.error = false;
      })

      .addCase(addService.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.errorType = action.payload;
        state.loading = false;
      })

      .addCase(updateService.pending, state => {
        state.loading = false;
        state.error = false;
        state.apiSucess = false;
      })

      .addCase(updateService.fulfilled, (state, action: PayloadAction<Service | null>) => {
        if (action.payload !== null) {
          state.apiSucess = true;
        } else {
          state.apiSucess = false;
        }
        state.loading = false;
        state.error = false;
      })

      .addCase(updateService.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.errorType = action.payload;
        state.loading = false;
      })

      .addCase(
        listServiceData.pending,
        (state, action: PayloadAction<any, any, { arg: ListServiceType; requestId: string; requestStatus: "pending" }>) => {
          state.loading = true;
          state.error = false;
          if (state.serviceList.data === null && action.meta.arg.fullPageLoaderShown) {
            state.pageLoading = true;
          }
        }
      )

      .addCase(listServiceData.fulfilled, (state, action: PayloadAction<listServiceResponse>) => {
        state.loading = false;
        state.error = false;
        state.serviceList.data = action.payload.data.size !== 0 ? action.payload.data : state.serviceList.data;
        const { sortType } = action.payload;
        state.serviceList.sortedBy = sortType;
        state.pageLoading = false;
      })

      .addCase(listServiceData.rejected, state => {
        state.error = true;
        state.loading = false;
        state.pageLoading = false;
      })

      // search service cases starts here
      .addCase(searchServiceData.pending, state => {
        if (!state.serviceList.data?.content?.length) state.loading = true;
        state.error = false;
      })

      .addCase(searchServiceData.fulfilled, (state, action: PayloadAction<listServiceResponse>) => {
        state.loading = false;
        state.error = false;
        state.serviceList.data = action.payload.data.size !== 0 ? action.payload.data : state.serviceList.data;
        const { sortType } = action.payload;
        state.serviceList.sortedBy = sortType;
      })

      .addCase(searchServiceData.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      // search service cases ends here

      .addCase(setTableState.fulfilled, (state, action: PayloadAction<ListServiceType>) => {
        state.loading = false;
        state.error = false;
        const { sortType, page, size } = action.payload;
        const obj = {
          pageNumber: page,
          rowsPerPage: size,
          sortedBy: sortType,
        };

        state.sort = { ...state.sort, ...obj };
      })
      .addCase(serviceServiceSearchServices.pending, state => {
        if (!state.serviceList.data?.content?.length) state.loading = true;
        state.error = false;
      })

      .addCase(serviceServiceSearchServices.fulfilled, (state, action: PayloadAction<listServiceResponse>) => {
        state.loading = false;
        state.error = false;
        state.serviceList.data = action.payload.data.size !== 0 ? action.payload.data : state.serviceList.data;
        const { sortType } = action.payload;
        state.serviceList.sortedBy = sortType;
      })

      .addCase(serviceServiceSearchServices.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getAllServices.pending, state => {
        if (state.serviceList.data === null) {
          state.loading = true;
          state.pageLoading = true;
        }

        state.error = false;
      })

      .addCase(getAllServices.fulfilled, (state, action: PayloadAction<listServiceResponse>) => {
        state.loading = false;
        state.error = false;
        state.serviceList.data = action.payload.data.size !== 0 ? action.payload.data : state.serviceList.data;
        const { sortType } = action.payload;
        state.serviceList.sortedBy = sortType;
        state.pageLoading = false;
      })

      .addCase(getAllServices.rejected, state => {
        state.error = true;
        state.loading = false;
        state.pageLoading = false;
      })
      .addCase(addOfficeService.pending, state => {
        state.loading = false;
        state.error = false;
        state.apiSucess = false;
      })

      .addCase(addOfficeService.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload !== null) {
          state.apiSucess = true;
        } else {
          state.apiSucess = false;
        }
        state.loading = false;
        state.error = false;
      })

      .addCase(addOfficeService.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.errorType = action.payload;
        state.loading = false;
      })
      .addCase(updateOfficeService.pending, state => {
        state.loading = false;
        state.error = false;
        state.apiSucess = false;
      })

      .addCase(updateOfficeService.fulfilled, (state, action: PayloadAction<Service | null>) => {
        if (action.payload !== null) {
          state.apiSucess = true;
        } else {
          state.apiSucess = false;
        }
        state.loading = false;
        state.error = false;
      })

      .addCase(updateOfficeService.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.errorType = action.payload;
        state.loading = false;
      })
      // search block fee services
      .addCase(searchBlockFeeServices.pending, state => {
        state.error = false;
      })
      .addCase(searchBlockFeeServices.fulfilled, (state, action: PayloadAction<BlockFeeResponse>) => {
        state.error = false;
        const { number, totalElements, size } = action.payload.data;
        const obj = {
          ...state.blockFeeServicesSortObj,
          pageNumber: number,
          rowsPerPage: size,
          totalElements: totalElements,
          sortedBy: action.payload.sort,
          searchTerm: action.payload?.searchTerm ?? state.blockFeeServicesSortObj.searchTerm
        };
        state.blockFeeServices = action.payload?.data?.content ?? [];
        state.blockFeeServicesSortObj = obj;
      })
      .addCase(searchBlockFeeServices.rejected, state => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const { listServices, resetStateServiceSlice, resetServiceTableSort } = ServiceSlice.actions;

export default ServiceSlice.reducer;
