import React, { useEffect } from "react";
import { Tabs } from "../../components";
import OfficeProfileTab from "./Tabs/OfficeProfileTab";
import LegalTab from "./Tabs/LegalTab";
import BillingInfoTab from "./Tabs/BillingInfoTab";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { useParams } from "react-router-dom";
import { RootState, AppDispatch } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { getOffice } from "../../redux/slices/OfficeSlice";
import { setActiveIndex } from "../../redux/slices/RequestSlice";
import OfficeOnboardingtaskList from "./OfficeOnboardingTaskList";

export default function OfficeInfo() {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { currentOffice } = useSelector((state: RootState) => state.office);
  const { activeTabIndex } = useSelector((state: RootState) => state.request);
  useEffect(() => {
    if (currentOffice === null && id) {
      dispatch(getOffice({ officeId: id }));
    }
  }, [currentOffice]);
  return (
    <main>
      <div className="container mx-auto p-8">
        <OfficeSectionsHeader showMeta={true} showStatus={true} />
        <div className="md:flex gap-10 relative ">
          <div className="tabs w-full">
            <Tabs
              components={[<OfficeProfileTab key="office-profile" />, <BillingInfoTab key="billing-info" />, <LegalTab key="legal" />]}
              options={["Office Profile", "Billing Info", "Legal"]}
              activeTab={activeTabIndex}
              onClickTab={index => dispatch(setActiveIndex(index))}
              fromOffice={true}
            />
          </div>
          {activeTabIndex !== 2 ? <OfficeOnboardingtaskList /> : null}
        </div>
      </div>
    </main>
  );
}
