import React from "react";

interface StatusCountProps {
  title: string;
  count: number | string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  active: boolean;
  countColor?: "primary" | "danger";
}

const StatusCount = ({ title, count, countColor = "primary", onClick, active }: StatusCountProps) => {
  return (
    <div
      className={`flex lg:inline-block text-center w-full max-w-[210px] px-4 py-5 mr-2 rounded-lg cursor-pointer bg-docsigna-pink-light border ${
        active ? "border-docsigna-blue-dark" : "border-docsigna-pink"
      }`}
      onClick={onClick}>
      <span className="block text-sm text-docsigna-blue-dark font-semibold text-center xl:mb-1 whitespace-nowrap pr-2 lg:pr-0">{title}</span>
      <span className={`text-base xl:text-xl block font-semibold ${countColor === "danger" ? "text-docsigna-orange" : "text-docsigna-blue-dark"}`}>
        {count}
      </span>
    </div>
  );
};

export default StatusCount;
