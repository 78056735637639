import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import Recipients from "../../../components/forms/Recipients/Recipients";
import { UserSideBar } from "../../../components";
import { useDispatch } from "react-redux";
import { getUserRequest } from "../../../redux/slices/UserSlice";
import { RequestType } from "../../../models/request.model";

const RequestDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const { patientRequestData } = useSelector((state: RootState) => state.user);
  const { currentOffice: selectedOffice } = useSelector((state: RootState) => state.office);
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const { requestFiles: files } = useSelector((state: RootState) => state.officeRequest);
  const canEdit = files.some(file => file.sentThirdParty);
  const closeForm = (val?: boolean) => {
    if (val) {
      if (currentUser?.userId && patientRequestData?.requestId)
        dispatch(getUserRequest({ reqeustId: patientRequestData?.requestId, userId: currentUser?.userId }));
    }
    setVisibleForm(false);
  };

  return (
    <>
      <p className="text-md mb-2">
        <span className="font-medium">Request#:</span> {patientRequestData?.requestNumber}
      </p>
      <p className="text-md mb-2">
        <span className="font-medium">Date:</span> {moment(patientRequestData?.createdDate).format("ll")}
      </p>
      <p className="text-md mb-2">
        <span className="font-medium">Service:</span> {patientRequestData?.service ? patientRequestData?.service.name : "Unassigned"}
      </p>
      <p className="text-md mb-2">
        <span className="font-medium">Office:</span> {selectedOffice?.name} <br /> Phone # {selectedOffice?.phone} | Fax # {selectedOffice?.fax}
        <br /> {selectedOffice?.street}, {selectedOffice?.city}, {selectedOffice?.state}, {selectedOffice?.zip}
      </p>
      <p className="text-md mb-2">
        <span className="font-medium">Additional Recipients: </span>
        &nbsp;{" "}
        <span className="text-md ">
          {patientRequestData?.recipientName ? `${patientRequestData?.recipientName}  (${patientRequestData?.recipientContact})` : "--"}{" "}
        </span>
        {!canEdit && patientRequestData?.requestType === RequestType.Standard ? (
          <span
            className="cursor-pointer text-md text-docsigna-blue-light font-medium"
            onClick={() => {
              setVisibleForm(true);
            }}>
            &nbsp; Edit
          </span>
        ) : null}
      </p>
      <p className="text-md mb-2">
        <span className="font-medium">Notes: </span>
        {patientRequestData?.details}
      </p>
      <UserSideBar
        visible={visibleForm}
        LeftbuttonText="Cancel"
        RightbuttonText="Add"
        onCancelClick={() => false}
        onInviteClick={() => false}
        onClickOutside={() => (!isLoading ? closeForm() : null)}
        title={"Edit 3rd Party Recipient"}>
        <Recipients onClickCancel={val => closeForm(val)} setIsLoading={setIsLoading} isLoading={isLoading} />
      </UserSideBar>
    </>
  );
};

export default RequestDetails;
