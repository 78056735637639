import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Request } from "../../models/request.model";
import { RequestFile } from "../../models/request-file.model";
import { logger } from "../../utils/logger";
import { User } from "../../models/user.model";
import { RequestService } from "../../services/request.service";
//import { ApiPage } from "../../models/api-page.model";
import { UserService } from "../../services/user.service";

const log = logger.getLogger("SearchScreenErrors");

export interface requestFilesType extends RequestFile {
  tooltipVisible: boolean;
}

interface sortObj {
  pageNumber: number;
  rowsPerPage: number;
  sortedBy: string;
  totalElements: number
}
export interface OfficeRequestStateType {
  error: boolean;
  loading: boolean;
  requestSearchTerm: '',
  searchedRequests: Request[];
  requestSort: sortObj
  patientSearchTerm: '',
  searchedPatients: User[];
  patientSort: sortObj
}

interface SearchRequestsType {
  searchTerm: string;
  page: number;
  size: number;
  sort?: string;
}

const initialState: OfficeRequestStateType = {
  loading: false,
  error: false,
  requestSearchTerm: '',
  searchedRequests: [],
  requestSort: {
    pageNumber:0,
    rowsPerPage: 10,
    sortedBy: "requestNumber,asc",
    totalElements: 0
  },
  patientSearchTerm: '',
  searchedPatients: [],
  patientSort: {
    pageNumber:0,
    rowsPerPage: 10,
    sortedBy: "lastName,asc",
    totalElements: 0
  }
};

export const searchRequests = createAsyncThunk(
  "searchSlice/requests",
  async ({ searchTerm, page, size, sort}: SearchRequestsType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setRequestSearchTerm(searchTerm));
      const res = await RequestService.searchRequest(
        searchTerm,
        page, size, sort
      );
      return {...res, sort};
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchPatients = createAsyncThunk(
  "searchSlice/patients",
  async ({ searchTerm, page, size, sort }: SearchRequestsType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPatientSearchTerm(searchTerm));
      const response = await UserService.searchPatients(searchTerm, page, size, sort);
      return {...response, sort}
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchSlice = createSlice({
  name: "searchSlice",
  initialState,
  reducers: {
    setRequestSearchTerm : (state, action) => {
      state.requestSearchTerm = action.payload
    },
    clearRequestSearchTerm : (state) => {
      state.requestSearchTerm = "";
      state.searchedRequests = [];
    },
    setPatientSearchTerm : (state, action) => {
      state.patientSearchTerm = action.payload
    },
    clearPatientSearchTerm : (state) => {
      state.patientSearchTerm = "";
      state.searchedPatients = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(searchRequests.pending, state => {
        state.loading = true;
        state.error = true;
      })
      .addCase(searchRequests.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = false;
        const {content, totalElements, size, number, sort} = action.payload;
        state.searchedRequests = content;
        state.requestSort = {...state.requestSort, totalElements, pageNumber: number, rowsPerPage: size, sortedBy: sort}
      })
      .addCase(searchRequests.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(searchPatients.pending, state => {
        state.loading = true;
        state.error = true;
      })
      .addCase(searchPatients.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = false;
        const {content, totalElements, size, number, sort} = action.payload;
        state.searchedPatients = content;
        state.patientSort = {...state.patientSort, totalElements, pageNumber: number, rowsPerPage: size, sortedBy: sort}
      })
      .addCase(searchPatients.rejected, state => {
        state.error = true;
        state.loading = false;
      })
  },
});

export const { setRequestSearchTerm, clearRequestSearchTerm, setPatientSearchTerm, clearPatientSearchTerm} = searchSlice.actions;
export default searchSlice.reducer;
