import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED } from "../../utils/constants";
import { Button, Checkbox } from "../../components";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setRequestAcceptedTerms } from "../../redux/slices/OnBoardingSlice";

import moment from "moment";
import { useSelector } from "react-redux";
const TermsAndConditions = ({ showCheckbox = true }: { showCheckbox?: boolean }) => {
  const [tcAccepted, setTcAccepted] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state.onBoarding);
  const guestToken = sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED);
  const guestData = JSON.parse(guestToken || "{}");
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (tcAccepted) {
      dispatch(setRequestAcceptedTerms({ guestToken: guestData.GuestToken, acceptedTerms: tcAccepted })).then(() => {
        navigate("/guest/view-request");
      });
    } else {
      setError("You must accept the terms before you can submit.");
    }
  };

  return (
    <>
      <div className="">
        <h2 className="text-2xl font-semibold mb-1">Terms and Conditions</h2>
        <p className="text-base mb-6">{`Effective date: ${moment().format("ll")}`}</p>
        <p className="text-base mb-3">
          Docnote facilitates the exchange of documents between Authorized Providers and Users and manages the transaction of payment for the
          documents. (the “Services”). We do so through web-based and mobile application platforms. This document constitutes our Terms and
          Conditions.
        </p>
        <p className="text-base mb-6">
          These Terms and Conditions form a legal agreement between Docnote and you, the user, concerning your access to and use of our platform. Use
          of our platform constitutes your acceptance of these Terms and Conditions. If you do not agree to the Terms and Conditions your remedy is to
          discontinue use of the platform immediately. Please print a copy of these Terms and Conditions for your records.
        </p>
        <h4 className="text-lg font-semibold mb-3">Modifications To These Terms And Conditions</h4>
        <p className="text-base mb-6">
          Docnote may, at its sole discretion and without prior notice to you, revise these Terms and Conditions at any time. Should these Terms and
          Conditions change materially, Docnote will update the effective date noted above and post a notice regarding the updated Terms and
          Conditions. Amended Terms and Conditions will be effective as of the Effective Date unless otherwise stated.
        </p>
        <h4 className="text-lg font-semibold mb-3">Access To The Platform</h4>
        <p className="text-base mb-6">
          Docnote grants you access to use the platform in accordance with the Terms and Conditions for the purpose of facilitating the exchange of
          information and documents between you and an Authorized Provider. Docnote may require additional authentication in order to ensure your
          identify before accessing the platform.
        </p>
        <h4 className="text-lg font-semibold mb-3">Prohibited Uses</h4>
        <p className="text-base mb-3">In using the platform, you agree not to:</p>
        <ul className="list-disc ml-4 mb-3">
          <li className="mb-3">
            Send or otherwise transmit to or through the platform any unlawful, infringing, harmful, harassing, defamatory, threatening, hateful or
            otherwise objectionable material of any kind, any material that can cause harm or delay to the platform, or computers of any kind, and any
            unsolicited advertising, solicitation, or promotional materials.
          </li>
          <li className="mb-3">Misrepresent your identity or affiliation in any way,</li>
          <li className="mb-3">
            Restrict or inhibit any person from using the platform, disclose personal information obtained from the platform or collect information
            about users of the platform,
          </li>
          <li className="mb-3">
            Reverse engineer, disassemble or decompile any section or technology on the platform, or attempt to do any of the foregoing,
          </li>
          <li className="mb-3">
            Gain unauthorized access to the platform, to other users&apos; information, names, personally identifiable information, or other
            information, or to other computers or websites connected or linked to the platform,
          </li>
          <li className="mb-3">
            Launch or use any automated system, including without limitation, &quot;robots,&quot; &quot;spiders,&quot; or &quot;offline readers,&quot;
            that access the platform in a manner that sends more request messages to our servers in a given period of time than a human can reasonably
            produce in the same period by using a conventional web browser,
          </li>
          <li className="mb-3">
            Send or otherwise transmit to the platform chain letters, unsolicited messages, so-called &quot;spamming&quot; or &quot;phishing&quot;
            messages, or messages marketing or advertising goods and services,
          </li>
          <li className="mb-3">
            Post, transmit or otherwise make available any virus, worm, spyware or any other computer code, file or program that may or is intended to
            damage or hijack the operation of any hardware, software, or telecommunications equipment,
          </li>
          <li className="mb-3">Violate any applicable laws or regulations in any way,</li>
          <li className="mb-3">Alter or modify any part of the content or services offered on or through the platform,</li>
          <li className="mb-3">Allow any other person to use the platform with your registration, link, or login information,</li>
          <li className="mb-3">Breach or otherwise circumvent Docnote&apos;s security or authentication measures; and</li>
          <li className="mb-3">Assist or permit any persons in engaging in any of the activities described above.</li>
        </ul>
        <h4 className="text-lg font-semibold mb-3">Docnote&apos;s Role</h4>
        <p className="text-base mb-3">
          Docnote does not engage in the practice of medicine or provide health care. Docnote is not an electronic medical record, insurance company,
          a medical provider or healthcare organization. Services we provide are limited to facilitating the exchange of documents between Authorized
          Providers and Users and managing the transaction of payment for the documents. To do so we collect and use personal information directly
          from you. We will not collect any more information than is necessary to provide Services.
        </p>
        <p className="text-base mb-6">
          We are independent from your insurer, legal representative, any third-party providers, employer and Authorized Providers. We are not
          responsible for acts, omissions, decisions, treatment suggestions and/or recommendations or for the content of the communications made by
          them.
        </p>
        <h4 className="text-lg font-semibold mb-3">Your Representations And Warranties</h4>
        <p className="text-base mb-3">
          We are committed to protecting the privacy, confidentiality and security of all personal information that is entrusted to us. It is
          important that Docnote obtain accurate personal information from you such as your name, email address and OHIP number.
        </p>
        <p className="text-base mb-3">By accessing the Docnote Platform, you represent and warrant the following:</p>
        <ul className="list-disc ml-4 mb-3">
          <li className="mb-3">You are at least eighteen (18) years of age,</li>
          <li className="mb-3">You have the legal ability and authority to enter into these Terms and Conditions,</li>
          <li className="mb-3">The information you provide is accurate and complete,</li>
          <li className="mb-3">
            If any information you provide to Docnote becomes inaccurate, incomplete, or otherwise false you will immediately notify us at{" "}
            <span className="font-bold text-docsigna-purple-dark">privacy@getDocSigna.ca</span>,
          </li>
          <li className="mb-3">
            You allow Docnote to collect, use and disclose personal information about you to provide the Services and for research, planning or
            delivery purposes,
          </li>
          <li className="mb-3">
            You allow documentation of a personal nature to be reviewed by Docnote internal staff to provide the services and for the purposes of
            research, planning, delivering services, administrative responsibilities, risk management and to maintain or improve the quality of the
            Services,
          </li>
          <li className="mb-3">You agree to the sharing and/or exchange of your personal information with the Authorized Provider you identified,</li>
          <li className="mb-3">
            You agree to the sharing and/or exchange of information if sharing is necessary because of an emergency that threatens the health or
            safety of yourself or another individual (this includes with the Emergency Department of a hospital),
          </li>
          <li className="mb-3">
            Research: Docnote pursues ongoing data analysis to constantly improve our product, and you understand and agree that anonymized
            information may be gathered from your records for this purpose. Docnote respects your privacy and will maintain confidentiality. At no
            time will Docnote use, release or publish any identifying information.
          </li>
          <li className="mb-3">You agree to allow Docnote to contact you in the future for feedback, research and quality purposes.</li>
          <li className="mb-3">You will comply with any and all laws applicable to your use of the platform,</li>
          <li className="mb-3">You will not interfere with or disrupt Docnote security measures, and</li>
          <li className="mb-3">
            {" "}
            You acknowledge that by providing information on the platform Docnote is not providing &quot;healthcare services&quot; under any
            provincial health plans.
          </li>
        </ul>
        <h4 className="text-lg font-semibold mb-3">Personal Information And Privacy Policy</h4>
        <p className="text-base mb-6">
          In these Terms and Conditions, &quot;personal information&quot; means any information about an identifiable individual, such as your name,
          email address, mailing address, gender, date of birth, any personal or protected health information, or any data about you that you elect to
          provide electronically through the platform and any other information that identifies who you are. Personal Information will be used by
          Docnote solely in accordance with these Terms and Conditions, and Docnote&apos;s{" "}
          <span className="font-bold text-docsigna-purple-dark">Privacy Policy</span> or as otherwise agreed upon between you and Docnote in writing.
        </p>
        <h4 className="text-lg font-semibold mb-3">Intellectual Property</h4>
        <p className="text-base mb-6">
          Docnote is the sole and exclusive owner of the platform including any and all copyright, patent, trademark, trade secret and other ownership
          and intellectual property rights, in and to the platform and any related materials and documentation.
        </p>
        <h4 className="text-lg font-semibold mb-3">Indemnification And Limitation Of Liability</h4>
        <p className="text-base mb-6">
          You agree to defend, indemnify and hold Docnote, its officers, directors, employees, shareholders, affiliates, third party contractors,
          agents and suppliers harmless from and against any claims, actions or demands, losses, liabilities, damages, costs, expenses and settlements
          (including without limitation reasonable attorney and accounting fees), resulting from or alleged to result from, directly or indirectly,
          your a) violation of these Terms and Conditions; b) access to the platform and c) provision of other disclosure to Docnote of any other
          information or data and the use of same by Docnote as contemplated hereunder.
        </p>
        <h4 className="text-lg font-semibold mb-3">Choice Of Law And Jurisdiction</h4>
        <p className="text-base mb-6">
          These Terms and Conditions are governed by the laws of Ontario, Canada. The parties irrevocably submit to the exclusive jurisdiction of the
          courts of the Province of Ontario in respect of all matters and disputes arising hereunder and waive any defense of lack of personal
          jurisdiction in that jurisdiction. These Terms and Conditions are not governed by the{" "}
          <i>United Nations Convention on Contracts for the International Sale of Goods</i>, the application of which is hereby expressly excluded. If
          any provision of these Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such
          provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and
          effect.
        </p>
        <h4 className="text-lg font-semibold mb-3">Waiver</h4>
        <p className="text-base mb-6">
          No delay or omission by Docnote to exercise any right or power it has under these Terms and Conditions or to object to the failure of any
          covenant of you to be performed in a timely and complete manner, shall impair any such right or power or be construed as a waiver of any
          succeeding breach or any other covenant. Any waivers by Docnote must be in writing and signed by an authorized representative of Docnote.
        </p>
        <h4 className="text-lg font-semibold mb-3">Assignment</h4>
        <p className="text-base mb-6">
          These Terms and Conditions are personal to you, and are not assignable, transferable, or sublicensable by you except with Docnote&apos;s{" "}
          prior written consent. Docnote may assign, transfer, or delegate any of its rights and obligations hereunder without your consent.
        </p>
        <h4 className="text-lg font-semibold mb-3">Contact Notices</h4>
        <p className="text-base mb-3">
          If you have any questions about the Terms and Conditions, or need to provide notice to, or communicate with, Docnote under the Terms and
          Conditions, please contact Docnote using the following contact details:
        </p>
        <p className="text-base mb-3">
          Docnote Inc.,
          <br />
          Docnote Privacy Officer
          <br />
          481 London Road,
          <br />
          Sarnia, ON, N7T4X3
          <br />
          <span className="font-bold text-docsigna-purple-dark">privacy@getDocSigna.ca</span>
        </p>
        <p className="text-base mb-6">
          Docnote may provide notices or communications to you on the platform, and you agree that such notices shall constitute notice to you whether
          or not you actually access the notice.
        </p>
        {error ? <span className="text-red-500">{error}</span> : null}
        {showCheckbox ? (
          <div className="flex justify-between">
            <label className="flex justify-start items-center">
              <Checkbox
                checked={tcAccepted}
                onChange={() => {
                  setTcAccepted(!tcAccepted);
                  setError("");
                }}
                label=""
              />
              <span className="text-sm text-slate-500 ml-2">I accept the Terms of Services and Conditions</span>
            </label>
            <Button text={"Submit"} onClickFunc={() => handleSubmit()} varient={loading ? "loading" : "Primary"} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TermsAndConditions;
