import { ChangeEvent, useState } from "react";
import { TextInput, Button, Checkbox } from "../..";
import { formatCurrency, validateCurrency } from "../../../utils";
import { addOfficeFee, addSystemFee } from "../../../redux/slices/FeeSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { AmountReplaceRegex, colors } from "../../../utils/constants";
export interface AddFeeDataType {
  id: string;
  placeholder: string;
  required: boolean;
  errorMesage: string;
  errorMesage1?: string;
  label: string;
  value: string;
  isValid: boolean;
}
const addFeeData: AddFeeDataType[] = [
  {
    id: "feeName",
    label: "Fee Name",
    placeholder: "Name of fee",
    required: true,
    errorMesage: "Please provide a fee name",
    value: "",
    isValid: false,
  },
  {
    id: "description",
    label: "Description",
    placeholder: "Description of fee (optional)",
    required: false,
    errorMesage: "Maximum 100 characters",
    value: "",
    isValid: true,
  },
  {
    id: "feePrice",
    label: "Price (per unit)",
    placeholder: "$",
    required: true,
    errorMesage: "Please provide a fee price",
    value: "",
    isValid: false,
  },
];
/* 
isAdminPage will be true if form is rendered outside of an office
e.g. http://localhost:3000/fees
*/
const AddFee = ({
  onCancelClick,
  isAdminPage,
  setIsLoading,
  isLoading,
}: {
  onCancelClick: (val?: boolean) => void;
  isAdminPage: boolean;
  setIsLoading: (val: boolean) => void;
  isLoading: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const [feeFormData, setFeeFormData] = useState<AddFeeDataType[]>(addFeeData);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [hstCheckBox, setHstCheckBox] = useState(false);
  const [enableFeeCheckBox, setEnableFeeCheckBox] = useState(false);
  const [addFeeError, setAddFeeError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [feeNameError, setFeeNameError] = useState(false);
  const [feeNameErrMsg, setfeeNameErMsg] = useState("");

  const validateField = (name: string, value: string) => {
    let valid = false;
    switch (name) {
      case "feePrice":
        valid = validateCurrency(value);
        break;
      case "feeName":
        valid = !!value.trim();
        break;
      case "description":
        valid = true;
        break;
      default:
        break;
    }
    return valid;
  };
  const handleFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    let value = e.currentTarget.value;
    switch (e.currentTarget.name) {
      case "feePrice":
        value = formatCurrency(value, "");
        break;
      case "description":
        if ((value as string).length > 100) {
          setDescriptionError(true);
        } else {
          setDescriptionError(false);
          value as string;
        }
        break;
      case "feeName":
        if ((value as string).length > 50) {
          setfeeNameErMsg("Maximum 50 character");
          setFeeNameError(true);
        } else {
          setFeeNameError(false);
          setfeeNameErMsg("");
          value as string;
        }
        break;
      default:
        break;
    }
    const valid = validateField(e.currentTarget.name, value);
    setFeeFormData([
      ...feeFormData.slice(0, index),
      {
        ...feeFormData[index],
        value,
        isValid: valid,
      },
      ...feeFormData.slice(index + 1),
    ]);
  };
  const onClickCancel = () => {
    onCancelClick();
  };
  const onClickSave = () => {
    setErrorInitiated(true);
    setIsLoading(true);
    const allValid = feeFormData.every(el => el.isValid);
    if (allValid) {
      setAddFeeError(false);
      if (isAdminPage) {
        dispatch(
          addSystemFee({
            name: feeFormData.filter(v => v.id === "feeName")[0].value,
            addTax: hstCheckBox,
            price: +feeFormData.filter(v => v.id === "feePrice")[0].value.replace(AmountReplaceRegex, "") * 100,
            enabled: enableFeeCheckBox,
            description:
              feeFormData.filter(v => v.id === "description")[0].value !== ""
                ? feeFormData.filter(v => v.id === "description")[0].value.trim()
                : undefined,
          })
        )
          .then(action => {
            if (action.payload !== null) {
              onCancelClick(true);
            } else {
              setAddFeeError(true);
            }
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else {
        if (currentOffice?.officeId) {
          dispatch(
            addOfficeFee({
              fee: {
                name: feeFormData.filter(v => v.id === "feeName")[0].value,
                addTax: hstCheckBox,
                price: +feeFormData.filter(v => v.id === "feePrice")[0].value.replace(AmountReplaceRegex, "") * 100,
                enabled: enableFeeCheckBox,
                officeId: currentOffice?.officeId,
                description:
                  feeFormData.filter(v => v.id === "description")[0].value !== ""
                    ? feeFormData.filter(v => v.id === "description")[0].value.trim()
                    : undefined,
              },
              officeId: currentOffice?.officeId,
            })
          )
            .then(action => {
              if (action.payload !== null) {
                onCancelClick(true);
              } else {
                setAddFeeError(true);
              }
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        }
      }
    } else {
      setIsLoading(false);
    }
  };
  const onBlurPrice = (e: { currentTarget: { value: string } }) => {
    const value = e.currentTarget.value;
    const newVal = formatCurrency(value, "blur");
    const index = feeFormData.findIndex(el => el.id === "feePrice");
    setFeeFormData([
      ...feeFormData.slice(0, index),
      {
        ...feeFormData[index],
        value: newVal,
      },
      ...feeFormData.slice(index + 1),
    ]);
  };

  const handleBlur = (id: string, value: string) => {
    const index = feeFormData.findIndex(item => item.id === id);
    if (index !== -1) {
      setFeeFormData([
        ...feeFormData.slice(0, index),
        {
          ...feeFormData[index],
          value,
        },
        ...feeFormData.slice(index + 1),
      ]);
    }
  };

  return (
    <>
      <div className="inline-block h-scrollable-screen overflow-auto w-full">
        <p className="text-base px-8 pb-5 font-medium">Please provide the details for this new fee.</p>
        {addFeeError ? (
          <p className="text-center" style={{ color: colors.Red }}>
            Unable to add fee, please try again.
          </p>
        ) : null}
        {feeFormData.map((item: AddFeeDataType, index) => {
          if (item.id === "description") {
            return (
              <div key={item.id} className="px-8 py-2">
                <TextInput
                  isError={(!item.isValid && errorInitiated) || descriptionError}
                  value={item.value}
                  name={item.id}
                  onChangeFunc={e => handleFieldChange(e, index)}
                  label={item.label}
                  errorMsg={item.errorMesage}
                  isRequired={item.required}
                  placeholder={item.placeholder}
                  showCounter={true}
                  counterMessage={"100"}
                  isTextArea={true}
                  onBlur={e => handleBlur(item.id, e.target.value.trim())}
                  isDarkBg={true}
                />
              </div>
            );
          }
          if (item.id === "feePrice") {
            return (
              <div key={item.id} className="px-8 py-2">
                <TextInput
                  isError={!item.isValid && errorInitiated}
                  value={item.value}
                  name={item.id}
                  onChangeFunc={e => handleFieldChange(e, index)}
                  label={item.label}
                  errorMsg={item.errorMesage}
                  isRequired={item.required}
                  placeholder={item.placeholder}
                  onBlur={e => onBlurPrice(e)}
                  isDarkBg={true}
                />
              </div>
            );
          }
          return (
            <div key={item.id} className="px-8 py-2">
              <TextInput
                isError={(!item.isValid && errorInitiated) || feeNameError}
                value={item.value}
                name={item.id}
                onChangeFunc={e => handleFieldChange(e, index)}
                label={item.label}
                errorMsg={feeNameErrMsg.length ? feeNameErrMsg : item.errorMesage}
                isRequired={item.required}
                placeholder={item.placeholder}
                onBlur={e => handleBlur(item.id, e.target.value.trim())}
                isDarkBg={true}
              />
            </div>
          );
        })}
        <div className="px-8 py-2">
          <Checkbox
            id="add-fee-hst"
            checked={hstCheckBox}
            onChange={e => setHstCheckBox(e.currentTarget.checked)}
            label="HST applicable"
            isDarkBg={true}
          />
        </div>
        <div className="px-8 py-2">
          <Checkbox
            id="add-fee-enable"
            checked={enableFeeCheckBox}
            onChange={e => setEnableFeeCheckBox(e.currentTarget.checked)}
            label="Enable fee"
            isDarkBg={true}
          />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            disabled={isLoading}
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button
              onClickFunc={onClickSave}
              disabled={(feeFormData.some(el => !el.isValid) && errorInitiated) || descriptionError || feeNameError || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddFee;
