import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RootState } from "../redux/store";
import { AuthContext } from "../contexts/AuthContext/AuthContext";

const indexRoute = "/";
const phoneDependantRoutes = [""];
const emailDependantRoutes = [...phoneDependantRoutes, "/onboarding/verify-success", "/onboarding/submit-request"];
const officeDependantRoutes = [...emailDependantRoutes, "/onboarding/office", "/onboarding/request-form", "/onboarding/verify-email"];

const ProtectedOnboardingRoute = () => {
  const { pathname } = useLocation();
  const auth = useContext(AuthContext);
  const { selectedOffice, emailVerified, phoneVerified } = useSelector((state: RootState) => state.onBoarding);

  if (auth?.isAuthenticated === true) {
    return <Navigate to={"/"} />;
  }

  if (officeDependantRoutes.includes(pathname) && selectedOffice === null) {
    return <Navigate to={indexRoute} />;
  }

  if (emailDependantRoutes.includes(pathname) && !emailVerified) {
    return <Navigate to={"/onboarding/request-form"} />;
  }
  if (phoneDependantRoutes.includes(pathname) && !phoneVerified) {
    return <Navigate to={"/onboarding/submit-request"} />;
  }

  return <Outlet />;
};

export default ProtectedOnboardingRoute;
