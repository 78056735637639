export enum ApiErrorCode {
  UNKNOWN,
  AUTH_BAD_CREDENTIALS,
  AUTH_CONFIRM_USER,
  BAD_REQUEST, // Something wrong with the request (missing required fields, bad formats, etc.)
  EXISTS, // Content already exists
  NOT_FOUND, // The item requested (usually by ID) does not exist
}

export class ApiError {
  code!: ApiErrorCode;
  message?: string;

  constructor(code: ApiErrorCode = ApiErrorCode.UNKNOWN, message?: string) {
    this.code = code;
    this.message = message;
  }
}
