import React, { useState, useEffect, useContext } from "react";
import NumberFormat from "react-number-format";
import { formatPhoneNumber, validateEmail } from "../../../utils";
import { Phone_Fax_Regex, colors } from "../../../utils/constants";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import DatePicker from "../../DatePicker/DatePicker";
import TextInput from "../../TextInput/TextInput";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getOfficeBlockFees } from "../../../redux/slices/BlockFeeSlice";
import { updateOfficeUser } from "../../../redux/slices/UserSlice";
import { User } from "../../../models/user.model";
import { UserUpdate } from "../../../models/user-update.model";

interface formDataType {
  id: string;
  value: string | boolean;
  title: string;
  placeholder: string;
  required: boolean;
  errorMessage: string;
  valid: boolean;
  type: "textInput" | "ohip" | "birthday" | "checkbox" | "billingPlan" | "textArea";
}

const formDataInitialState: formDataType[] = [
  {
    id: "firstName",
    value: "",
    title: "First Name",
    placeholder: "First Name",
    required: true,
    errorMessage: "Please provide first name",
    valid: false,
    type: "textInput",
  },
  {
    id: "lastName",
    value: "",
    title: "Last Name",
    placeholder: "Last Name",
    required: true,
    errorMessage: "Please provide last name",
    valid: false,
    type: "textInput",
  },
  {
    id: "email",
    value: "",
    title: "Email",
    placeholder: "Email Address",
    required: true,
    errorMessage: "Please provide valid email address",
    valid: false,
    type: "textInput",
  },
  {
    id: "dateOfBirth",
    value: "",
    title: "Date of Birth",
    placeholder: "MM/DD/YYYY",
    required: true,
    errorMessage: "Please enter valid date of birth",
    valid: false,
    type: "birthday",
  },
  {
    id: "phone",
    value: "",
    title: "Phone",
    placeholder: "Enter Phone number",
    required: true,
    errorMessage: "Please enter valid phone number",
    valid: false,
    type: "textInput",
  },
  {
    id: "ohipNumber",
    value: "",
    title: "OHIP Number (Optional)",
    placeholder: "Enter OHIP Number",
    required: false,
    errorMessage: "Please enter OHIP number",
    valid: false,
    type: "ohip",
  },
  {
    id: "billingPlan",
    value: "",
    title: "Active Billing Plan",
    placeholder: "",
    required: false,
    errorMessage: "Please select billing plan",
    valid: false,
    type: "billingPlan",
  },
  {
    id: "note",
    value: "",
    title: "Notes",
    placeholder: "",
    required: false,
    errorMessage: "Please add notes",
    valid: false,
    type: "textInput",
  },
  {
    id: "verified",
    value: "",
    title: "This patient has been verified.",
    placeholder: "",
    required: false,
    errorMessage: "",
    valid: false,
    type: "checkbox",
  },
];

const EditPatientProfile = ({
  onClickCancel,
  isEditable = false,
  userData,
  setIsLoading,
  isLoading,
}: {
  onClickCancel: (val?: boolean) => void;
  isEditable?: boolean;
  userData?: User;
  setIsLoading?: (val: boolean) => void;
  isLoading?: boolean;
}) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const [formData, setFormData] = useState(formDataInitialState);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const { verificationError } = useSelector((state: RootState) => state.patient);
  const { plans } = useSelector((state: RootState) => state.blockFee);

  const handleChange = (id: string, value: string | boolean) => {
    let tempFormData = [...formData];
    tempFormData = tempFormData.map(input => {
      const newInput = input;
      if (input.id === id) {
        if (id === "phone") {
          newInput.value = formatPhoneNumber(value as string);
        } else if (id === "verified") {
          newInput.value = typeof value === "boolean" && value ? "yes" : "no";
        } else {
          newInput.value = value;
        }
        newInput.valid = validateFormField(input.id, value as string, input.required);
      }
      return newInput;
    });
    setFormData(tempFormData);
  };

  const validateForm = () => {
    return formData.every(val => !val.required || (val.required && val.valid));
  };

  const handleClickSave = async () => {
    if (!isEditable) {
      toast?.openToast();
      return;
    }
    if (!validateForm()) {
      setErrorInitiated(true);
      return;
    }
    const firstName = (formData.find(item => item.id === "firstName")?.value as string).trim();
    const lastName = (formData.find(item => item.id === "lastName")?.value as string).trim();
    const email = (formData.find(item => item.id === "email")?.value as string).trim();
    const phone = (formData.find(item => item.id === "phone")?.value as string).trim();
    const dateOfBirth = (formData.find(item => item.id === "dateOfBirth")?.value as string).trim();
    const ohipNumber = (formData.find(item => item.id === "ohipNumber")?.value as string).trim();
    const billingPlan = (formData.find(item => item.id === "billingPlan")?.value as string).trim();
    const verified = (formData.find(item => item.id === "verified")?.value as string).trim();

    const note = (formData.find(item => item.id === "note")?.value as string).trim();
    if (id && userData?.userId && email && firstName && lastName) {
      const updatedUser: UserUpdate = {
        userId: userData?.userId,
        officeId: id,
        firstName,
        lastName,
        email,
        phone,
        dateOfBirth,
        ohipNumber,
        blockFeeId: billingPlan ? billingPlan : null,
        note,
        verified: verified === "yes" ? true : false,
      };
      setIsLoading ? setIsLoading(true) : null;
      const resp = await dispatch(
        updateOfficeUser({
          officeId: id,
          user: updatedUser,
        })
      );
      setIsLoading ? setIsLoading(false) : null;
      if (resp?.payload) {
        toast?.openToast("Patient profile updated successfully!");
        onClickCancel(true);
      } else {
        toast?.openToast("Patient profile update failed");
      }
    }
  };

  const renderInput = (inp: formDataType) => {
    switch (inp.type) {
      case "textInput":
        return (
          <TextInput
            key={inp.id}
            value={inp.value as string}
            onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
            placeholder={inp.placeholder}
            errorMsg={inp.errorMessage}
            isError={!inp.valid && errorInitiated}
            label={inp.title}
            isRequired={inp.required}
            readonly={!isEditable}
            onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
            isDarkBg={true}
            isTextArea={inp.id === "note"}
            extraInputClass={inp.id === "note" ? "resize-none h-40" : ""}
          />
        );
      case "ohip":
        return (
          <div key={inp.id}>
            <label className="text-sm text-docsigna-dark-blue font-medium block w-full mb-0.5 mt-0 pt-0">
              {inp.title}
              {inp.required ? <span className="text-red-500">*</span> : null}
            </label>
            <NumberFormat
              value={inp.value as string}
              isNumericString
              format={"#### ### ###"}
              placeholder="Enter OHIP Number"
              displayType="input"
              readOnly={!isEditable}
              className="text-base block font-medium w-full rounded-md bg-docsigna-pink-light border-docsigna-blue-dark"
              onValueChange={values => handleChange(inp.id, values.value)}
            />
            {!inp.valid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMessage}</p> : null}
          </div>
        );
      case "birthday":
        return !isEditable ? (
          <TextInput
            key={inp.id}
            value={moment(inp.value as string).format("MMM DD, YYYY")}
            onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
            placeholder={inp.placeholder}
            errorMsg={inp.errorMessage}
            isError={!inp.valid && errorInitiated}
            label={inp.title}
            isRequired={inp.required}
            readonly={true}
            isDarkBg={true}
          />
        ) : (
          <DatePicker
            key={inp.id}
            label={inp.title}
            isRequired={inp.required}
            name={inp.id}
            placeholder={inp.placeholder}
            value={inp.value as string}
            isError={!inp.valid && errorInitiated}
            onChange={val => handleChange(inp.id, val)}
            errorMsg={inp.errorMessage}
            disabled={!isEditable}
            isDarkBg={true}
          />
        );

      case "checkbox":
        return (
          <>
            <Checkbox
              key={inp.id}
              id={"patientVerified"}
              label={inp.title}
              checked={inp.value === "yes"}
              name={inp.id}
              onChange={e => handleChange(inp.id, e.currentTarget.checked)}
              disabled={!isEditable}
              isDarkBg={true}
            />
            {!inp.valid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMessage}</p> : null}
          </>
        );
      case "billingPlan":
        return (
          <div key={inp.id}>
            <label className="text-sm text-docsigna-dark-blue font-medium block w-full mb-0.5 mt-0 pt-0">
              {inp.title}
              {inp.required ? <span className="text-red-500">*</span> : null}
            </label>{" "}
            <div className="w-full">
              <select
                disabled={!isEditable}
                onChange={e => handleChange(inp.id, e.currentTarget.value)}
                value={inp.value as string}
                className="general-select w-full bg-transparent">
                <option value="">No active plan</option>

                {plans?.map(plan => {
                  return (
                    <option value={plan.blockFeeId} key={plan.blockFeeId}>
                      {plan.name}
                    </option>
                  );
                })}
              </select>
              <div>{!inp.valid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMessage}</p> : null}</div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const validateFormField = (name: string, value: string, required: boolean) => {
    if (!required && !value) return true;

    let valid = false;

    switch (name) {
      case "phone":
        valid = Phone_Fax_Regex.test(formatPhoneNumber(value as string));
        break;
      case "email":
        valid = validateEmail(value as string);
        break;
      case "ohip":
        valid = (value as string).trim() !== "" ? (value as string).length === 10 : true;
        break;
      default:
        valid = typeof value === "string" ? value.length > 0 : true;
        break;
    }

    return valid;
  };

  const populatePatientFormData = (user?: User): formDataType[] => {
    const newFormData = formDataInitialState;
    if (!user) return newFormData;
    const newData = newFormData.map(item => {
      const newItem = { ...item };
      switch (newItem.id) {
        case "billingPlan":
          newItem.value = user?.blockFee?.blockFeeId ?? "";
          break;
        case "verified":
          newItem.value = user.verified ? "yes" : "no";
          break;
        default:
          newItem.value = user[item.id as keyof User]?.toString() ?? "";
          newItem.valid = validateFormField(newItem.id, newItem.value, newItem.required);
          break;
      }
      return newItem;
    });
    return newData;
  };

  useEffect(() => {
    if (userData) {
      const newData = populatePatientFormData(userData);
      setFormData(newData);
    }
  }, [userData]);

  useEffect(() => {
    if (id) {
      dispatch(getOfficeBlockFees({ officeId: id }));
    }
    return () => {
      setFormData(formDataInitialState);
    };
  }, [dispatch, id]);

  return (
    <>
      {verificationError ? <p className="text-sm px-8 py-5 text-red-400 text-center">An error has occurred, please try again</p> : null}
      {formData.map(input =>
        input.id === "patientFirstName" || input.id === "patientLastName" ? (
          <div key={input.id} className="px-8 py-2">
            {renderInput(input)}
          </div>
        ) : null
      )}

      {formData.map(input =>
        input.id === "patientFirstName" || input.id === "patientLastName" ? null : input.id === "patientVerified" ? (
          userData?.userType !== "patient" ? (
            <div key={input.id} className="px-8 py-2">
              {renderInput(input)}
            </div>
          ) : null
        ) : (
          <div key={input.id} className="px-8 py-2">
            {renderInput(input)}
          </div>
        )
      )}

      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            disabled={isLoading}
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text={isEditable ? "Cancel" : "Close"}
            width="fit"
            varient={isEditable ? "Secondary" : "Primary"}
          />
          {isEditable ? (
            <div>
              <Button
                onClickFunc={() => {
                  handleClickSave();
                }}
                disabled={isLoading || (!validateForm() && errorInitiated)}
                AdditionalClassNames="pointer px-5"
                text="Save"
                width="fit"
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default EditPatientProfile;
