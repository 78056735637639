import React, { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { AuthPermission, UserStates } from "../../services/auth.service";
import InviteUserSideBarContent from "../../components/forms/User/InviteUserSideBarContent";
import ProtectedComponent from "../../components/ProtectedComponent/ProtectedComponent";
import UserSideBar from "../../components/UserSideBar/UserSideBar";
import { useDispatch, useSelector } from "react-redux";
import { closeTooltipUser, openTooltipUser, searchOfficeUser, UserDataForRender, updateOfficeUser } from "../../redux/slices/UserSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { User } from "../../models/user.model";
import { Button, Pagination, TextInput, ViewUser } from "../../components";
import { TableHeader, TableRow } from "../../components/TableComponents/TableComponents";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { getSorting, getUserRoleByType } from "../../utils";
import { useParams } from "react-router-dom";
import { Office } from "../../models/office.model";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { cancelOfficeUserInvitation, resendOfficeUserInvitation, sortObj } from "../../redux/slices/OfficeSlice";
import EditUser from "../../components/forms/EditUser/EditUser";
import { headers } from "./OfficeTeamData";
import { CircularProgress } from "@mui/material";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NotFound from "../../assets/images/no-team.png";
import { SESSION_STORAGE_USER_STATE_TEAM, UserStateTypes, colors } from "../../utils/constants";
import { startCase } from "lodash";
import { useDebouncedCallback } from "use-debounce";

const OfficeTeam = () => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const { usersList, error, errorMessage, toastVisible, loading } = useSelector((state: RootState) => state.user);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const { id } = useParams();
  const [sidebarVisibility, setSidebarVisibility] = useState(false);
  const [errorInitiated, seterrorInitiated] = useState(false);
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [sortMethods, setSortMethods] = useState<boolean[]>(headers.map(() => false));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(-1);
  const [selectedUserData, setselectedUserData] = useState<User | undefined>(undefined);
  const [extraClasses] = useState<string[]>(headers.map(head => head.extraClasses));
  const [term, setTerm] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<UserDataForRender | null>(null);
  const [editUserSidebar, setEditUserSidebar] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [tooltipStates, setTooltipStates] = useState<boolean[]>(new Array(usersList.data ? usersList.data.content.length : 10).fill(false));
  const [viewUserSideBar, setViewUserSideBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserState, setSelectedUserState] = useState<UserStates>(UserStates.All);
  const [disableUserConfirmationBox, setDisableUserConfirmationBox] = useState(false);
  const [sort, setSort] = useState({
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "",
  });

  const officeId = id ? id : "";

  const hideSideBar = () => {
    setSidebarVisibility(false);
    setViewUserSideBar(false);
    setEditUserSidebar(false);
    seterrorInitiated(false);
  };

  const showSidebar = () => setSidebarVisibility(true);

  const openEditUserSideBar = () => {
    if (currentUser?.permissions.includes(AuthPermission.User)) {
      setEditUserSidebar(true);
    } else {
      setViewUserSideBar(true);
    }
  };

  const onInviteClick = () => {
    seterrorInitiated(true);
  };

  const updateTable = (sortObj?: sortObj, searchText?: string) => {
    const pageNumToBeCalled = sortObj?.pageNumber ?? sort.pageNumber;
    const sizeToBeCalled = sortObj?.rowsPerPage ?? sort.rowsPerPage;
    const sortBy = sortObj?.sortedBy ?? sort.sortedBy;
    const searchT = searchText ?? "";
    const oldUserState = sessionStorage.getItem(SESSION_STORAGE_USER_STATE_TEAM);
    let userState = "";
    if (oldUserState && oldUserState !== null) {
      userState = oldUserState;
    }
    if (id) {
      dispatch(
        searchOfficeUser({
          officeId,
          term: searchT?.trim()?.length >= 2 ? searchT.trim() : "",
          page: pageNumToBeCalled,
          size: sizeToBeCalled,
          sortType: sortBy,
          userState: userState?.trim()?.length ? userState.trim() : undefined,
        })
      ).then(() => {
        setSort({ ...sort, pageNumber: pageNumToBeCalled, rowsPerPage: sizeToBeCalled, sortedBy: sortBy });
      });
    }
  };

  const onSuccessfulInvite = () => {
    updateTable();
  };

  const handleSort = (index: number) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    setActiveSortIndex(index);
    updateTable({ sortedBy: sortMethodString, pageNumber: 0, rowsPerPage: sort.rowsPerPage }, term);
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: newPage, rowsPerPage: sort.rowsPerPage }, term);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: 0, rowsPerPage: parseInt(event.target.value, 10) }, term);
  };

  const handleTooltipClick = async (type: string, emailId: string) => {
    const selectedUser = usersList.data?.content.find(item => item.email === emailId);
    setselectedUserData(selectedUser);
    if (selectedUser) setSelectedUser(selectedUser);

    const userId = selectedUser?.userId;
    switch (type) {
      case "Resend Invite":
        if (userId) {
          dispatch(resendOfficeUserInvitation({ userId: userId, officeId })).then(res => {
            if (res.payload) {
              toast?.openToast("User invitation sent");
            } else {
              toast?.openToast("Unable to resend invitation");
            }
          });
        }
        break;
      case "Cancel Invite":
        setConfirmationOpen(true);
        break;
      case "Remove User":
        break;
      case "Edit User":
        openEditUserSideBar();
        break;
      case "View User":
        openEditUserSideBar();
        break;
      case "Disable User":
        setDisableUserConfirmationBox(true);
        break;
      case "Enable User":
        if (selectedUser?.userId) {
          updateUserState(UserStateTypes.Active, selectedUser);
          setSelectedUser(null);
        }
        break;
      default:
        toast?.openToast();
        break;
    }
    setTooltipStates([...tooltipStates].fill(false));
  };

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));

  //TODO: Disable rows per page option if available users are less that option value.

  const refreshTable = () => {
    if (error === false) {
      updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, term);
    }
  };

  const onAgreeClick = () => {
    if (selectedUserData) {
      dispatch(cancelOfficeUserInvitation({ userId: selectedUserData?.userId, officeId })).then(response => {
        if (response.payload) refreshTable();
      });
    }
    setselectedUserData(undefined);
    setConfirmationOpen(false);
  };

  const onDisagreeClick = () => {
    setConfirmationOpen(false);
    setselectedUserData(undefined);
    setDisableUserConfirmationBox(false);
  };

  const debounced = useDebouncedCallback(value => {
    if (value && value.trim().length >= 2) {
      updateTable({ ...sort, pageNumber: 0 }, value.trim());
    }
  }, 1000);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    const oldSearchTerm = term;
    setTerm(input);
    debounced(input);
    if (oldSearchTerm !== "" && input.length <= 1) {
      updateTable(sort);
    }
  };

  const handleToolTipOpen = (index: number) => {
    dispatch(openTooltipUser(index));
  };

  const handleToolTipClose = () => {
    dispatch(closeTooltipUser());
  };

  const handleRowClick = (data: UserDataForRender) => {
    openEditUserSideBar();
    setSelectedUser(data);
  };

  const getIsPCP = (offices: Office[] | undefined, userId: string) => {
    if (!offices) return false;
    const filtered = offices.filter((office: Office) => office.officeId === officeId);
    const selectedOffice: Office | undefined = filtered ? filtered[0] : undefined;
    if (selectedOffice) {
      return selectedOffice.primaryCareProviders?.some(pcp => pcp.userId === userId);
    } else {
      return false;
    }
  };

  const onChangeUserState = (val: UserStates) => {
    setSelectedUserState(val);
    sessionStorage.setItem(SESSION_STORAGE_USER_STATE_TEAM, val);
    updateTable(sort, term);
  };

  const updateUserState = async (val: string, userData: User) => {
    if (userData?.userId) {
      const updateUserData = { userId: userData?.userId, userState: val, officeId: id };
      if (id) {
        await dispatch(updateOfficeUser({ officeId: id, user: updateUserData }));
        updateTable(sort, term);
      }
    }
  };

  const authorizedToEdit = currentUser && currentUser.permissions ? currentUser.permissions.includes(AuthPermission.User) : false;
  const toolTipOptions = authorizedToEdit ? ["Edit User", "Disable User"] : ["View User"];
  const toolTipOptions1 = authorizedToEdit ? ["Edit User", "Enable User"] : ["View User"];

  useEffect(() => {
    updateTable();
    const oldUserState = sessionStorage.getItem(SESSION_STORAGE_USER_STATE_TEAM);
    if (oldUserState && oldUserState !== null) {
      setSelectedUserState(oldUserState as UserStates);
    } else {
      setSelectedUserState(UserStates.All);
    }
  }, []);

  useEffect(() => {
    if (toastVisible) {
      toast?.openToast(errorMessage);
    }
  }, [toastVisible]);

  return (
    <>
      {confirmationOpen ? (
        <ConfirmationDialog
          open={confirmationOpen}
          title={`Cancel invitation for ${selectedUserData !== undefined ? selectedUserData.email : ""}`}
          description={""}
          successButtonText={"Agree"}
          handleSuccess={onAgreeClick}
          failureButtonText={"Disagree"}
          handleFailure={onDisagreeClick}
        />
      ) : null}
      {disableUserConfirmationBox ? (
        <ConfirmationDialog
          open={disableUserConfirmationBox}
          title={`Are you sure you want to disable?`}
          description={"This user will no longer be able to access Docnote."}
          successButtonText={"Confirm"}
          handleSuccess={() => {
            if (selectedUser) updateUserState(UserStateTypes.Disabled, selectedUser);
            setSelectedUser(null);
            setDisableUserConfirmationBox(false);
          }}
          failureButtonText={"Cancel"}
          handleFailure={onDisagreeClick}
        />
      ) : null}
      <main>
        <div className="container mx-auto p-8">
          {/* <BreadCrumbs items={breadCrumbItems}/> */}
          <OfficeSectionsHeader showMeta={true} />

          <div className="block lg:flex justify-between items-center flex-wrap mb-6">
            <div className="w-full lg:w-1/3 mb-5 lg:mb-0">
              <h2 className="heading uppercase">Team</h2>
            </div>
            <div className="block md:flex lg:justify-end w-full lg:w-2/3">
              <select
                className={`general-select mr-5 w-full md:w-1/4 mb-2 md:mb-0`}
                value={selectedUserState}
                onChange={e => onChangeUserState(e.target.value as UserStates)}>
                <option value={UserStateTypes.All}>{UserStates.All}</option>
                <option value={UserStateTypes.Active}>{UserStates.Active}</option>
                <option value={UserStateTypes.Invited}>{UserStates.Invited}</option>
                <option value={UserStateTypes.Disabled}>{UserStates.Disabled}</option>
              </select>
              <div className="flex items-center">
                <div className="relative w-full">
                  <TextInput value={term} onChangeFunc={handleSearch} placeholder={"Search users"} extraInputClass="w-full" isSearchInput={true} />
                </div>
              </div>
              <ProtectedComponent requiredPermission={[AuthPermission.User]}>
                <div className="block md:flex md:justify-end items-center ml-0 md:ml-5">
                  <span className="md:mr-5 font-medium text-center hidden md:inline-block">or</span>
                  <Button text="Invite User" onClickFunc={() => showSidebar()} AdditionalClassNames="whitespace-nowrap w-auto mt-3 md:mt-0" />
                </div>
              </ProtectedComponent>
            </div>
            <p className="text-base font-medium mt-5">
              Invite office teammates and get more done—faster. Manage your team’s user accounts, assign roles, and access permissions.
            </p>
          </div>
          {loading === false ? (
            <div className="block">
              {usersList?.data?.content && usersList?.data?.content?.length > 0 ? (
                <>
                  <div className="flex justify-between items-center border-b border-docsigna-blue-light">
                    {headers.map((head, index) => (
                      <TableHeader
                        title={head.title}
                        index={index}
                        key={index}
                        sortIcon={sortIcons[index]}
                        handleSort={handleSort}
                        extraClassesHeader={head.extraClasses}
                        isClickable={head.isClickable}
                      />
                    ))}
                    <div className="w-auto p-5 py-3">
                      <span className="text-base text-docsigna-purple-dark inline-block w-5"></span>
                    </div>
                  </div>
                  {usersList.data?.content.map((rowData, index) => {
                    const sortedData = {
                      name: rowData.firstName + " " + rowData.lastName,
                      email: (
                        <a href={`mailto:${rowData.email}`} className="cursor-pointer hover:underline" onClick={e => e.stopPropagation()}>
                          {rowData.email}
                        </a>
                      ),
                      role: getUserRoleByType(rowData.userType),
                      pcp: getIsPCP(rowData.offices, rowData.userId) ? <FiberManualRecordIcon sx={{ color: colors.CyanBlue, fontSize: 16 }} /> : "",
                      status: startCase(rowData.userState),
                    };
                    return (
                      <TableRow
                        data={Object.values(sortedData)}
                        key={index}
                        onRowClick={() => (rowData.userState !== UserStateTypes.Invited ? handleRowClick(rowData) : null)}
                        tooltipVisible={rowData.tooltipVisible}
                        handleOpenTooltip={() => handleToolTipOpen(index)}
                        handleCloseTooltip={() => handleToolTipClose()}
                        id={rowData.email}
                        extraClassesRow={extraClasses}
                        tooltipOptions={
                          rowData.userState === UserStateTypes.Invited
                            ? ["Resend Invite", "Cancel Invite"]
                            : rowData.userState === UserStateTypes.Active
                            ? toolTipOptions
                            : toolTipOptions1
                        }
                        handleTooltipClick={handleTooltipClick}
                      />
                    );
                  })}
                  <Pagination
                    colSpan={10}
                    count={usersList.data ? usersList.data.totalElements : 0}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    page={sort.pageNumber}
                    rowsPerPage={sort.rowsPerPage}
                  />
                </>
              ) : (
                <DataNotFound image={NotFound} text="No team members found" />
              )}
            </div>
          ) : (
            <CircularProgress size={40} color={"info"} style={{ marginLeft: "50%", marginTop: "10%" }} />
          )}
          <div>
            <UserSideBar
              title="Invite New User"
              visible={sidebarVisibility}
              LeftbuttonText="Cancel"
              RightbuttonText="Invite"
              onCancelClick={hideSideBar}
              onInviteClick={onInviteClick}
              onClickOutside={() => (!isLoading ? hideSideBar() : null)}>
              <InviteUserSideBarContent
                isInsideOffice={true}
                errorInitiated={errorInitiated}
                onCancelClick={hideSideBar}
                onSuccess={onSuccessfulInvite}
                officeId={officeId}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </UserSideBar>
          </div>
        </div>
        <UserSideBar
          title={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
          visible={editUserSidebar}
          onCancelClick={hideSideBar}
          onInviteClick={onInviteClick}
          onClickOutside={() => (!isLoading ? hideSideBar() : null)}>
          <EditUser
            onCancelClick={() => setEditUserSidebar(false)}
            userId={selectedUser?.userId || ""}
            insideOffice
            onSuccess={() => updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, term)}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </UserSideBar>
        <UserSideBar
          title={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
          visible={viewUserSideBar}
          onCancelClick={hideSideBar}
          onInviteClick={onInviteClick}
          onClickOutside={hideSideBar}>
          <ViewUser onCancelClick={hideSideBar} selectedUser={selectedUser} />
        </UserSideBar>
      </main>
    </>
  );
};

export default OfficeTeam;
