import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";

export interface ServiceCardPropType {
  serviceId: string;
  serviceName: string;
  serviceDescription: string;
}

/* 
    SearchInput Component.
    Props : 
        options : Array of strings which will be the options to select from while typing in the search field.
        onChangeFunc : Function which will excute when a value is selected. 
*/

const ServiceCard = ({ serviceId, serviceName, serviceDescription }: ServiceCardPropType) => {
  return (
    <div className="text-center">
      <Link to={`${serviceId}`}>
        <Button text={serviceName} onClickFunc={() => null} width="fit" />
      </Link>
      {serviceDescription ? <div className="text-base text-center mt-3 mb-6" dangerouslySetInnerHTML={{ __html: serviceDescription }} /> : null}
    </div>
  );
};

export default ServiceCard;
