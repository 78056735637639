import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { Office, OfficeState } from "../../models/office.model";
import { listOfficeData } from "../../redux/slices/RequestSlice";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { clearSessionStorage, resetStateOnBoardingSlice, updateSelectedOffice } from "../../redux/slices/OnBoardingSlice";
import "../../assets/css/styles.css";
import SearchIcon from "../../assets/images/search-icon.svg";
//import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HomePageHeader from "./HomePageHeader";
import { ConfirmationDialog } from "../../components";
import TermsAndConditions from "../Guest/TermsAndCondition";
import PrivacyPolicy from "../Guest/PrivacyPolicy";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "../../assets/css/patients.css";
import VideoPoster from "../../assets/images/patients__video.png";
import Search from "../../assets/images/patients__doctor-search.svg";
import Confirmation from "../../assets/images/patients__form.svg";
import Submit from "../../assets/images/patients__submit.svg";
import MedicalConcierge from "../../assets/images/patients__medical-concierge.png";
// import PatientsService from "../../assets/images/patients__service-image.png";
import PatientsService1 from "../../assets/images/patients__service-image1.png";
import PatientsService2 from "../../assets/images/patients__service-image2.png";
import PatientsService3 from "../../assets/images/patients__service-image3.png";
import PatientsService4 from "../../assets/images/patients__service-image4.png";
import PatientsService5 from "../../assets/images/patients__service-image5.png";
import PatientsService6 from "../../assets/images/patients__service-image6.png";
import InactiveSlide from "../../assets/images/landing/inactive-slide.svg";
import ActiveSlide from "../../assets/images/landing/active-slide.svg";
import Fees from "../../assets/images/patients__fees-image.png";
import ReactPlayer from "react-player";

export const NoDataFound = () => {
  return (
    <div className="search-row p-3 w-full border-b border-gray-300 cursor-pointer py-1">
      <p className="text-sm mb-1 text-slate-600"> No Data Found</p>
    </div>
  );
};

export const noData = [
  {
    name: "No Data Found",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    fax: "",
    contactName: "",
    contactEmail: "",
    officeState: OfficeState.Draft,
  },
];

const items = [
  // {
  //   quote:
  //     "I love being able to access all of my doctor’s notes and forms in one place. The system is really easy to use and I’ve been able to take unpaid days off of work so much more easily now that I’ve used Docnote.",
  //   author: "Abigail Kenny, Sarnia, Ontario",
  // },
  {
    quote:
      "I love being able to put requests through on Docnote without having to scramble to get to my doctor’s office. The system is really easy to use and I’ve been able to get the notes and forms I need effortlessly.",
    author: "Sharon Kenny, Sarnia, Ontario",
  },
];

export default function Landing() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const unInsuredServiceRef = useRef<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = React.useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const { officeList } = useSelector((state: RootState) => state.request);

  const getFilteredData = (): Office[] | [] => {
    if (searchTerm.length <= 2) return [];

    const matchedFilteredData: Office[] = [];

    officeList.data?.content.forEach(el => {
      // discarding the loop if no primary care providers are there

      if (!el.primaryCareProviders || el.primaryCareProviders?.length === 0) {
        matchedFilteredData.push(el);
        return;
      }

      //searching for all the pcp which includes the search term in first name or in last name

      const drNameIncluded = el.primaryCareProviders?.filter(pcp => {
        const fullName = `${pcp.firstName.toLowerCase()} ${pcp.lastName.toLowerCase()}`;

        const containsFirstName = pcp.firstName.toLowerCase().includes(searchTerm.toLowerCase());
        const containsLastName = pcp.lastName.toLowerCase().includes(searchTerm.toLowerCase());
        const containsFullName = fullName.includes(searchTerm.toLowerCase());

        return containsFirstName || containsLastName || containsFullName;
      });

      //pushing this result only when of the doctor name is matching with search term

      const data = { ...el, primaryCareProviders: drNameIncluded };
      matchedFilteredData.push(data);
    });

    return matchedFilteredData;
  };

  const onOptionSelect = (officeData: Office) => {
    navigate("onboarding/office", { state: officeData });
    dispatch(clearSessionStorage());
    dispatch(resetStateOnBoardingSlice());
    dispatch(updateSelectedOffice(officeData));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };

  useEffect(() => {
    if (searchTerm.length !== 0) {
      if (searchTerm.length > 2) {
        dispatch(listOfficeData({ searchTerm, page: 0, sortType: "", size: 100 }));
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="patients__body">
        <HomePageHeader />
        <section className="patients__section patients__banner home-screen">
          <div className="container">
            <label className="heading font-semibold tracking-[2px] text-base uppercase">The better way to get uninsured medical services</label>
            <h1 className="heading capitalize text-5xl sm:text-6xl lg:text-7xl mt-2 font-extrabold">Your doctor&apos;s</h1>
            <h1 className="heading capitalize text-5xl sm:text-6xl lg:text-7xl mt-2 font-extrabold mb-12">note delivered</h1>
            <Autocomplete
              freeSolo={true}
              className="w-full sm:w-[500px] lg:w-[550px]"
              options={getFilteredData().length > 0 ? getFilteredData() : noData}
              onInputChange={(e: React.SyntheticEvent<Element, Event>, value) => {
                setSearchTerm(value ? value : "");
              }}
              disableClearable
              openOnFocus
              open={searchTerm.length > 2}
              includeInputInList={true}
              filterOptions={x => x}
              ListboxProps={{
                className: "absolute left-0 top-13 w-full rounded-md bg-docsigna-pink-light",
                style: {
                  padding: 0,
                },
              }}
              renderOption={(props, el: Office) => (
                <span className="hover:bg-docsigna-purple-light" key={el.officeId + Math.random().toString()}>
                  {getFilteredData().length > 0 ? (
                    <div className="p-3 w-full border-b hover:bg-docsigna-purple-light cursor-pointer" onClick={() => onOptionSelect(el)}>
                      <p className="text-lg font-semibold mb-1">{el.name}</p>
                      <>
                        {el.primaryCareProviders?.map((pcp, ind) => {
                          return <p key={ind} className="text-sm font-semibold mb-1">{` Dr. ${pcp.firstName} ${pcp.lastName}`}</p>;
                        })}
                      </>
                      <p className="text-sm"> {`${el.street} ${el.city} ${el.state}`} </p>
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
                </span>
              )}
              renderInput={params => {
                const newInputProps = params.InputProps;
                return (
                  <TextField
                    {...params}
                    className="bg-docsigna-pink-light rounded-full border-none text-2xl"
                    placeholder="Type your doctor’s name to get started"
                    type="search"
                    InputProps={{
                      ...newInputProps,
                      startAdornment: (
                        <InputAdornment position="start" className="ml-3">
                          <img src={SearchIcon} alt="search" width={26} height={26} />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        border: "none !important",
                        outline: "none",
                        margin: "0px",
                        boxShadow: "none",
                        fontSize: "24px",
                        padding: "2px 5px",
                      },
                    }}
                  />
                );
              }}
            />
          </div>
        </section>
        <section className="patients__section patients__video">
          <div className="container">
            <img
              src={VideoPoster}
              alt=""
              className={`cursor-pointer w-full m-auto ${!showVideo ? "visible" : "invisible"}`}
              onClick={() => setShowVideo(true)}
            />
            <div className={`absolute left-0 top-0 w-full h-full ${showVideo ? "visible" : "invisible"}`}>
              <ReactPlayer url={"https://vimeo.com/868926969"} playing={showVideo} controls width={"100%"} height={"100%"} />
            </div>
          </div>
        </section>
        <section id="how-it-works" className="patients__section patients__welcome">
          <div className="container">
            <h4 className="patients__title">
              Welcome to Docnote. To request an uninsured medical service <br />
              from your doctor, getting started is as easy 1-2-3
            </h4>
            <div className="block lg:flex justify-between">
              <div className="patients__welcome-steps patients__step1 w-full lg:w-1/3">
                <div className="patients__welcome-steps-icon">
                  <img src={Search} alt="" />
                </div>
                <p className="font-bold mb-1">1. Look up your doctor&apos;s office.</p>
                <p>Search our directory to find their profile</p>
              </div>
              <div className="patients__welcome-steps patients__step2 w-full lg:w-1/3">
                <div className="patients__welcome-steps-icon">
                  <img src={Submit} alt="" />
                </div>
                <p className="font-bold mb-1">2. Submit your request.</p>
                <p>Make a request and process your payment through our secure system.</p>
              </div>
              <div className="patients__welcome-steps patients__step3 w-full lg:w-1/3">
                <div className="patients__welcome-steps-icon">
                  <img src={Confirmation} alt="" />
                </div>
                <p className="font-bold mb-1">3. Receive confirmation.</p>
                <p>Once the service is completed by your doctor you will be notified.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="patients__section patients__medical-concierge">
          <div className="container">
            <div className="patients__medical-concierge-left">
              <h4 className="patients__title">Your medical concierge</h4>
              <h3 className="patients__title">Take control and enjoy an uninsured medical service experience that delights</h3>
              <p>
                Getting what you need from your doctor should be the last thing to stress you out. With Docnote, get access to uninsured services like
                sick notes, insurance forms and remote prescription renewals in the easiest way possible.
              </p>
              <p
                className="patients__button cursor-pointer"
                onClick={e => {
                  e.preventDefault();
                  window.scrollTo({ top: unInsuredServiceRef.current?.offsetTop, left: 0, behavior: "smooth" });
                }}>
                Learn more about uninsured services
              </p>
            </div>
            <div className="patients__medical-concierge-right">
              <img src={MedicalConcierge} alt="" />
            </div>
          </div>
        </section>
        <section ref={unInsuredServiceRef} id="uninsured-section" className="patients__section patients__uninsured-services">
          <div className="container">
            <h4 className="patients__title">What are uninsured services and why are they not covered by OHIP?</h4>
            <p>
              In Ontario, uninsured health care services refer to medical services that are not considered medically necessary by the Ontario Health
              Insurance Plan (OHIP) and are therefore not covered. Such services may include administrative tasks like filling out sick notes and
              insurance forms, or performing elective procedures like cosmetic surgery.
            </p>
            <p>
              OHIP focuses its resources on essential medical needs, leaving individuals to cover the cost of uninsured services, either through
              private insurance or out-of-pocket payments. Providers charge for these uninsured services based on fee guidelines set by relevant
              provincial entities like the Ontario Medical Association (OMA) and the College of Physicians and Surgeons of Ontario (CPSO).
            </p>
          </div>
        </section>
        <section className="patients__section patients__instant-access-uninsured-services">
          <div className="container">
            <h3 className="patients__title">
              Instant access for all your uninsured medical <br />
              service needs, right at your fingertips
            </h3>
            <div className="patients__services">
              <div className="patients__service patients__service1">
                <div className="patients__service-image">
                  <img src={PatientsService1} alt="" />
                </div>
                <p>Back to Work/Sick Note</p>
              </div>
              <div className="patients__service patients__service1">
                <div className="patients__service-image">
                  <img src={PatientsService2} alt="" />
                </div>
                <p>Remote Prescription Renewal</p>
              </div>
              <div className="patients__service patients__service1">
                <div className="patients__service-image">
                  <img src={PatientsService3} alt="" />
                </div>
                <p>Disability Tax Credit</p>
              </div>
              <div className="patients__service patients__service1">
                <div className="patients__service-image">
                  <img src={PatientsService4} alt="" />
                </div>
                <p>Insurance Form</p>
              </div>
              <div className="patients__service patients__service1">
                <div className="patients__service-image">
                  <img src={PatientsService5} alt="" />
                </div>
                <p>Travel Cancellation</p>
              </div>
              <div className="patients__service patients__service1">
                <div className="patients__service-image">
                  <img src={PatientsService6} alt="" />
                </div>
                <p>Other</p>
              </div>
            </div>
          </div>
        </section>
        <section className="patients__section patients__happy-people">
          <div className="mx-auto bg-white rounded-[50px] flex flex-col max-w-[1080px] pt-[80px] pb-[120px] md:px-[92px] px-[24px] text-center">
            <h4 className="patients__title">Healthy people are happy people</h4>
            <Carousel
              autoPlay
              infiniteLoop
              stopOnHover
              swipeable={true}
              showThumbs={false}
              showStatus={false}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                return isSelected ? (
                  <li>
                    <img src={ActiveSlide} alt="active-slide" />
                  </li>
                ) : (
                  <li>
                    <img src={InactiveSlide} alt="Inactive slide" />
                  </li>
                );
              }}>
              {items.map((items, index) => {
                return (
                  <div key={index}>
                    <p className="italic text-[32px] text-docsigna-blue-dark mb-[16px] font-medium">{items.quote}</p>
                    <p className="text-docsigna-blue-dark text-[22px] font-medium">{items.author}</p>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </section>
        <section className="patients__section patients__docnote-fees">
          <div className="container">
            <div className="patients__docnote-fees-left">
              <h4 className="patients__title">A small price to pay for speed and convenience.</h4>
              <h3 className="patients__title">Docnote fees and taxes</h3>
              <p>
                Docnote is a pay-as-you-go service for patients designed with flexibility and convenience in mind. Fees are charged based on the
                services being offered to help support the Docnote platform and cover a range of operating costs. Service fees vary and are subject to
                change. You can view the service fee total on every request before paying as well as any applicable taxes.
              </p>
            </div>
            <div className="patients__docnote-fees-right">
              <img src={Fees} alt="" />
            </div>
          </div>
        </section>
        <section className="patients__section patients__find-doctor-get-start">
          <div className="container">
            <div className="patients__find-doctor-get-start-wrapper">
              <h3 className="patients__title">Skip a trip or two to the doctor&apos;s office to save time and travel.</h3>
              <p>
                Get your notes, forms and other uninsured needs met from the comfort of your own home or anywhere else for that matter. Find your
                doctor and get started today.
              </p>
              <div className="flex justify-center mt-5">
                <Autocomplete
                  freeSolo={true}
                  className="w-full sm:w-[500px] lg:w-[550px] border border-docsigna-blue-dark rounded-full"
                  options={getFilteredData().length > 0 ? getFilteredData() : noData}
                  onInputChange={(e: React.SyntheticEvent<Element, Event>, value) => {
                    setSearchTerm(value ? value : "");
                  }}
                  disableClearable
                  openOnFocus
                  open={searchTerm.length > 2}
                  includeInputInList={true}
                  filterOptions={x => x}
                  ListboxProps={{
                    className: "absolute left-0 top-13 w-full rounded-md bg-docsigna-pink-light border border-docsigna-purple-dark",
                    style: {
                      padding: 0,
                    },
                  }}
                  renderOption={(props, el: Office) => (
                    <span className="hover:bg-docsigna-purple-light" key={el.officeId + Math.random().toString()}>
                      {getFilteredData().length > 0 ? (
                        <div className="p-3 w-full border-b hover:bg-docsigna-purple-light cursor-pointer" onClick={() => onOptionSelect(el)}>
                          <p className="text-lg font-semibold mb-1">{el.name}</p>
                          <>
                            {el.primaryCareProviders?.map((pcp, ind) => {
                              return <p key={ind} className="text-sm font-semibold mb-1">{` Dr. ${pcp.firstName} ${pcp.lastName}`}</p>;
                            })}
                          </>
                          <p className="text-sm"> {`${el.street} ${el.city} ${el.state}`} </p>
                        </div>
                      ) : (
                        <NoDataFound />
                      )}
                    </span>
                  )}
                  renderInput={params => {
                    // let newInputProps = getFilteredData().length ? params.InputProps : {}
                    const newInputProps = params.InputProps;
                    return (
                      <TextField
                        {...params}
                        className="rounded-full border-none text-2xl"
                        placeholder="Type your doctor’s name here"
                        type="search"
                        InputProps={{
                          ...newInputProps,
                          startAdornment: (
                            <InputAdornment position="start" className="ml-3">
                              <img src={SearchIcon} alt="search" width={26} height={26} />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            border: "none !important",
                            outline: "none",
                            margin: "0px",
                            boxShadow: "none",
                            fontSize: "24px",
                            padding: "2px 5px",
                          },
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <footer className="patients__footer">
          <div className="container">
            <div className="footer-left font-medium">Copyright © 2023 Docnote. All rights reserved.</div>
            <div className="footer-right">
              <Link to={"doctors"} className="font-medium text-docsigna-blue-light text-base">
                For Doctors
              </Link>
              <span>|</span>
              <a
                href="/"
                className="font-medium text-docsigna-blue-light text-base"
                onClick={e => {
                  e.preventDefault();
                  handleClickOpen();
                }}>
                Terms of Services
              </a>
              <span>|</span>
              <a
                href="/"
                className="font-medium text-docsigna-blue-light text-base"
                onClick={e => {
                  e.preventDefault();
                  handleClickOpenPrivacyPolicy();
                }}>
                Privacy Policy
              </a>
            </div>
          </div>
        </footer>
        <ConfirmationDialog
          maxWidth="lg"
          successButtonText=""
          failureButtonText="Close"
          open={open}
          description={<TermsAndConditions showCheckbox={false} />}
          handleSuccess={() => {}}
          handleFailure={handleClose}
        />
        <ConfirmationDialog
          maxWidth="lg"
          successButtonText=""
          failureButtonText="Close"
          open={openPrivacyPolicy}
          description={<PrivacyPolicy />}
          handleSuccess={() => {}}
          handleFailure={handleClosePrivacyPolicy}
        />
      </main>
    </>
  );
}
