import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, OtpInput } from "../../components";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { clearSessionStorage, sendPhoneVerificationCode, submitRequest, verifyPhoneCode } from "../../redux/slices/OnBoardingSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { colors } from "../../utils/constants";

const VerifyPhone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const [otpInput, setOtpInput] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const formData = useSelector((state: RootState) => state.onBoarding.requestFormData);
  const { pending } = useSelector((state: RootState) => state.onBoarding);

  const handleTryAgain = () => {
    setOtpError(false);
    setOtpInput("");
    dispatch(sendPhoneVerificationCode({ phone: formData[formData.findIndex(v => v.id === "phone")].value })).then(action => {
      if (action?.payload !== null) {
        toast?.openToast("Code has been sent again.");
      }
    });
  };

  const onSubmitClick = () => {
    setOtpError(false);
    dispatch(verifyPhoneCode({ phone: formData[formData.findIndex(v => v.id === "phone")].value, code: otpInput })).then(action => {
      if (action.payload) {
        dispatch(submitRequest()).then(act => {
          if (act.payload === null) {
            if (!pending) {
              setSubmitError(true);
            }
          } else {
            dispatch(clearSessionStorage());

            navigate("/onboarding/thank-you");
          }
        });
      } else {
        setOtpError(true);
      }
    });
  };

  useEffect(() => {
    if (pending) {
      setSubmitError(false);
    }
  }, [pending]);

  useEffect(() => {
    if (otpInput.length === 6) {
      onSubmitClick();
    } else {
      setSubmitError(false);
    }
  }, [otpInput]);

  return (
    <>
      <div className="flex justify-between w-full">
        <div className="w-full max-w-2xl mx-auto py-2 my-12 px-10 md:px-0">
          <h3 className="text-3xl font-light mb-2">We need to validate your phone number.</h3>
          <p className="text-base mb-5 font-medium">We have sent a 6 digit code to your phone number: +1-{formData[4].value}.</p>
          <OtpInput length={6} onChange={e => setOtpInput(e)} />
          <div className="tab-content py-5 w-80">
            <Button
              text="Continue"
              width="full"
              disabled={otpInput.length !== 6}
              onClickFunc={onSubmitClick}
              varient={pending ? "loading" : "Primary"}
            />
          </div>
          {submitError ? <p style={{ color: colors.Red }}>{"Failed to submit the form. Please check the data you've entered."}</p> : null}
          {otpError ? <p style={{ color: colors.Red }}>{"Invalid code, please try again"}</p> : null}
          <br />
          <p className="text-base mb-5 font-medium">
            Didn{"'"}t receive a code?{" "}
            <span className="text-base text-docsigna-purple-dark cursor-pointer" onClick={() => handleTryAgain()}>
              Try again
            </span>{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default VerifyPhone;
