export enum DsNotificationType {
  Request = "request",
}

export type DsNotification = {
  notificationId: string;
  userId: string;
  notificationType: DsNotificationType;
  entityId: string;
  text: string;
  unread: boolean;
  createdDate: string;
};

export type DsNotificationCounts = {
  total: number;
  unread: number;
};
