import { ChangeEvent, useState, useEffect } from "react";
import { Button } from "../..";
import { colors } from "../../../utils/constants";
import { Autocomplete, TextField } from "@mui/material";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getOfficeFees } from "../../../redux/slices/FeeSlice";
import { UpdateFee } from "../../../models/update-fee.model";
import { Fee } from "../../../models/fee.model";
export interface AddFeeDataType {
  id: number;
  feeType: any;
  numberOfUnits: number | null;
  valid: boolean;
  price: number;
}
const AddEditFee = ({
  onCancelClick,
  officeId,
  requestId,
  addedFee,
}: {
  onCancelClick: (updateFee?: UpdateFee[]) => void;
  officeId: string | null;
  requestId: string | null;
  addedFee?: UpdateFee[];
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { feeList } = useSelector((state: RootState) => state.fees);
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const [addFeeId, setAddFeeId] = useState(1);
  const feeTypeOptions: { value: string | undefined; label: string | undefined }[] | undefined = feeList?.content?.map(e => {
    if (e.enabled === true) {
      return {
        value: e.feeId,
        label: e.name,
      };
    } else {
      return {
        value: "",
        label: "",
      };
    }
  });
  const newFeeTypeOptions = feeTypeOptions?.filter(f => f.value !== "");
  const [feeOptions, setFeeOptions] = useState<any>();
  const addFeeData: AddFeeDataType[] = [
    {
      feeType: { value: "", label: "" },
      id: addFeeId,
      numberOfUnits: 1,
      valid: false,
      price: 0,
    },
  ];
  const [feeFormData, setFeeFormData] = useState<AddFeeDataType[]>(addFeeData);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [disabledState, setDisabledState] = useState(false);

  const handleFieldChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: "fee" | "units",
    id: number,
    newValue?: { value: string | undefined; label: string | undefined } | null
  ) => {
    const value = e.target.value;
    const temp = [...feeFormData];
    switch (type) {
      case "fee":
        const tempPrice = feeList?.content.filter(f => f.feeId === newValue?.value)[0];
        temp.forEach(v => {
          if (v.id === id) {
            v.feeType = newValue;
            v.price = tempPrice?.price ? tempPrice?.price / 100 : 0;
          }
        });
        break;
      case "units":
        temp.forEach(v => {
          if (v.id === id) {
            v.numberOfUnits = +value;
          }
        });
        break;
      default:
        break;
    }
    temp.forEach(v => {
      v.valid = v?.feeType?.value !== "" && v?.numberOfUnits !== null && v?.numberOfUnits > 0;
    });
    setDisabledState(false);
    setFeeFormData(temp);
  };

  const handleDeleteFee = (id: number) => {
    const index = feeFormData.findIndex(v => v.id === id);
    setFeeFormData([...feeFormData.slice(0, index), ...feeFormData.slice(index + 1)]);
  };
  const onClickSave = () => {
    const allValid = feeFormData.every(el => el.valid);
    if (!disabledState && allValid) {
      const feeData = feeFormData?.map(ele => {
        if (ele.feeType.value) {
          return {
            feeId: ele.feeType.value ? ele.feeType.value : "",
            requestId: requestId ? requestId : "",
            quantity: ele.numberOfUnits ? ele.numberOfUnits : 1,
          };
        } else {
          return {
            feeId: ele.feeType ? ele.feeType : "",
            requestId: requestId ? requestId : "",
            quantity: ele.numberOfUnits ? ele.numberOfUnits : 1,
          };
        }
      });
      //localStorage.setItem("additionalFee", JSON.stringify(feeData));
      onCancelClick(feeData);
    }
  };

  useEffect(() => {
    if (addedFee?.length && feeList?.content?.length) {
      const existingFees = addedFee?.map((item: UpdateFee, index: number) => {
        const newItem = feeList?.content?.find((it: Fee) => it.feeId === item.feeId);
        return {
          feeType: { value: newItem?.feeId, label: newItem?.name },
          id: index,
          numberOfUnits: item.quantity,
          valid: newItem?.name && item.quantity ? true : false,
          price: newItem?.price ?? 0,
        };
      });
      if (existingFees) setFeeFormData(existingFees);
    }
  }, [feeList, addedFee]);

  useEffect(() => {
    const selectedOptions = feeFormData.map(ele => {
      return ele?.feeType?.label;
    });
    const filteredOptions = newFeeTypeOptions?.filter(f => !selectedOptions.includes(f.label));
    setFeeOptions(filteredOptions);
  }, [feeFormData]);

  useEffect(() => {
    setFeeOptions(newFeeTypeOptions);
  }, [feeList]);

  useEffect(() => {
    if (feeList === null) {
      dispatch(
        getOfficeFees({
          officeId: currentOffice?.officeId,
          page: 0,
          sortType: "name,asc",
          size: 100,
          fullPageLoaderShown: true,
        })
      );
    }
  }, []);

  return (
    <>
      <div className="inline-block h-scrollable-screen overflow-auto w-full">
        <p className="text-base px-8 pb-5 font-medium">You can add multiple fees for each service.</p>
        <div className="px-8 py-5">
          {feeFormData.map((fee, index) => {
            return (
              <div key={index}>
                {index ? <hr className="my-5 border-docsigna-blue-light" /> : null}
                <div className="flex justify-between">
                  <b className="w-80">Fee #{index + 1}</b>
                  <p className="justify-end float-right cursor-pointer" style={{ color: colors.Red }} onClick={() => handleDeleteFee(fee.id)}>
                    Remove
                  </p>
                </div>
                <Autocomplete
                  className=" py-2 "
                  id="combo-box-demo"
                  onChange={(e, newValue) => handleFieldChange(e as unknown as ChangeEvent<HTMLInputElement>, "fee", fee.id, newValue)}
                  options={feeOptions ? feeOptions : []}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={option => (option.label !== undefined ? option.label : "")}
                  value={fee.feeType}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <label className="text-sm font-medium block w-full mb-1">
                        {"Fee Type"}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Select Fee type"
                        {...params.inputProps}
                        className="text-base block w-full rounded-md bg-transparent border-docsigna-blue-dark shadow-none outline-none"></input>
                    </div>
                  )}
                />
                {fee.feeType === null || (fee.feeType.value === "" && errorInitiated) ? (
                  <p style={{ color: colors.Red }}>Please select a fee type.</p>
                ) : null}
                <div className="w-94">
                  <div>
                    <label className="text-sm font-medium block w-full mb-1">
                      {"Number of Units"}
                      {fee.numberOfUnits}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="flex justify-between item-center">
                      <TextField
                        id="add-edit-fees"
                        className=" w-24"
                        type="number"
                        inputProps={{ min: 1 }}
                        onChange={e => handleFieldChange(e as unknown as ChangeEvent<HTMLInputElement>, "units", fee.id, null)}
                        defaultValue={fee.numberOfUnits ? fee.numberOfUnits.toString() : undefined}
                        value={fee.numberOfUnits ?? 1}
                      />
                      <p className="w-48 mt-4">@ ${fee.price} per unit</p>
                      <p className="w-24"></p>
                    </div>
                  </div>
                </div>
                {fee.numberOfUnits === null && errorInitiated ? (
                  <p className="w-full pt-1" style={{ color: colors.Red }}>
                    Please select number of units.
                  </p>
                ) : null}
              </div>
            );
          })}
          <hr className="my-5 border-docsigna-blue-light" />
          <p
            className="text-docsigna-blue-light font-medium cursor-pointer"
            onClick={() => {
              setAddFeeId(addFeeId + 1);
              setFeeFormData([
                ...feeFormData,
                {
                  feeType: { value: "", label: "" },
                  id: addFeeId + 1,
                  numberOfUnits: 1,
                  valid: false,
                  price: 0,
                },
              ]);
            }}>
            Add New Fee
          </p>
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button onClickFunc={() => onCancelClick()} AdditionalClassNames="pointer px-5 mr-3" text="Cancel" width="fit" varient="Secondary" />
          <div onClick={onClickSave}>
            <Button
              onClickFunc={() => {
                setErrorInitiated(true);
              }}
              disabled={feeFormData.some(el => !el.valid) && errorInitiated}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddEditFee;
