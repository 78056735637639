import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileService } from "../../services/file.service";
import { DsFile } from "../../models/ds-file.model";
import { OfficeService } from "../../services/office.service";
import axios from "axios";
import { logger } from "../../utils/logger";

const log = logger.getLogger('FileErrors');
interface InitialStateType {
  loading: boolean;
  error: boolean;
  success: boolean;
  uploadedFiles: DsFile[];
  getFileDetails: DsFile | null;
  filesForMerge: File[];
  invoiceCreate: boolean;
}

const initialState: InitialStateType = {
  loading: false,
  error: false,
  success: false,
  uploadedFiles: [],
  getFileDetails: null,
  filesForMerge: [],
  invoiceCreate: false,
};

export const FileSlice = createSlice({
  name: "fileApi",
  initialState,
  reducers: {
    clearUploadedFiles: state => {
      state.uploadedFiles = [];
      state.getFileDetails = null;
    },
    uploadedInvoice: (state, action) => {
      state.invoiceCreate = action.payload;
    },
    resetStateFileSlice: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(uploadFile.pending, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(uploadFile.rejected, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(uploadFile.fulfilled, (state, action: PayloadAction<DsFile | null, any, any>) => {
        state.loading = false;
        if (action.payload !== null) {
          state.success = true;
          state.uploadedFiles = [...state.uploadedFiles, action.payload];
          state.filesForMerge = [...state.filesForMerge, action.meta];
        }
      })
      .addCase(deleteFile.pending, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(deleteFile.rejected, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(deleteFile.fulfilled, (state, action: PayloadAction<{ completed: boolean; fileID: string }>) => {
        state.loading = false;
        if (action.payload.completed === true) {
          state.success = true;
          state.uploadedFiles = state.uploadedFiles.filter(file => file.fileId !== action.payload.fileID);
        }
      })
      .addCase(getFile.pending, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(getFile.rejected, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(getFile.fulfilled, (state, action: PayloadAction<DsFile | null>) => {
        state.loading = false;
        if (action.payload !== null) {
          state.getFileDetails = action.payload;
        }
      })
      .addCase(getOfficeServiceSampleFile.pending, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(getOfficeServiceSampleFile.rejected, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(getOfficeServiceSampleFile.fulfilled, (state, action: PayloadAction<DsFile | null>) => {
        state.loading = false;
        if (action.payload !== null) {
          state.getFileDetails = action.payload;
        }
      })
      .addCase(loadFilesForMerge.fulfilled, (state, action: PayloadAction<File | undefined>) => {
        if (action.payload) state.filesForMerge = [...state.filesForMerge, action.payload];
        state.loading = false;
      })
      .addCase(loadFilesForMerge.pending, state => {
        state.loading = true;
      })
      .addCase(loadFilesForMerge.rejected, state => {
        state.loading = false;
      })
      .addCase(uploadFileForMerge.pending, state => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(uploadFileForMerge.rejected, state => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(uploadFileForMerge.fulfilled, (state, action: PayloadAction<DsFile | null, any, any>) => {
        state.loading = false;
        if (action.payload !== null) {
          state.success = true;
          state.filesForMerge = [...state.filesForMerge, action.meta];
        }
      });
  },
});
export const uploadFile = createAsyncThunk(
  "fileApi/upload",
  async ({ file, callback = () => {} }: { file: File; callback?: (progress: number) => void }, { rejectWithValue }) => {
    try {
      const res = await FileService.uploadFile(file, callback);
      return res as DsFile;
    } catch (err) {
      log.warn(JSON.stringify(err));
      return rejectWithValue(err);
    }
  }
);

export const uploadFileNew = createAsyncThunk(
  "fileApi/upload-only",
  async ({ file, callback = () => {} }: { file: File; callback?: (progress: number) => void }, { rejectWithValue }) => {
    try {
      const res = await FileService.uploadFile(file, callback);
      return res;
    } catch (err) {
      log.warn(JSON.stringify(err));
      return rejectWithValue(err);
    }
  }
);

export const uploadSingleFile = createAsyncThunk(
  "fileApi/uploadSingleFile",
  async ({ file, callback = () => {} }: { file: File; callback?: (progress: number) => void }, { rejectWithValue }) => {
    try {
      const res = await FileService.uploadFile(file, callback);
      return res?.fileId ?? undefined;
    } catch (err) {
      log.warn(JSON.stringify(err));
      return undefined;
    }
  }
);

export const uploadFileForMerge = createAsyncThunk(
  "fileApi/uploadFileForMerge",
  async ({ file, callback = () => {} }: { file: File; callback?: (progress: number) => void }, { rejectWithValue }) => {
    try {
      const res = await FileService.uploadFile(file, callback);
      return res;
    } catch (err) {
      log.warn(JSON.stringify(err));
      return rejectWithValue(err);
    }
  }
);

export const deleteFile = createAsyncThunk("fileApi/delete", async ({ fileId }: { fileId: string }, { rejectWithValue }) => {
  try {
    const res = await FileService.deleteFile(fileId);
    return { completed: res, fileID: fileId };
  } catch (err) {
    log.warn(JSON.stringify(err));
    return rejectWithValue(err);
  }
});

export const deleteAllFiles = createAsyncThunk("fileApi/deleteAll", async ({ fileIds }: { fileIds: string[] }, { rejectWithValue }) => {
  try {
    fileIds.forEach(async (file: string) => {
      await FileService.deleteFile(file);
    });
  } catch (err) {
    log.warn(JSON.stringify(err));
    return rejectWithValue(err);
  }
});

export const getFile = createAsyncThunk("fileApi/getFile", async ({ fileId }: { fileId: string }, { rejectWithValue }) => {
  try {
    const res = await FileService.getFile(fileId);
    if (res !== null) {
      return res;
    } else {
      rejectWithValue(null);
      return null;
    }
  } catch (err) {
    log.warn(JSON.stringify(err));
    rejectWithValue(null);
    return null;
  }
});

export const getOfficeServiceSampleFile = createAsyncThunk(
  "fileSlice/getOfficeServiceSampleFile",
  async ({ officeId, serviceId }: { officeId: string; serviceId: string }, { rejectWithValue }) => {
    try {
      const res = OfficeService.getOfficeServiceSampleFile(officeId, serviceId);
      return res;
    } catch (err) {
      log.warn(JSON.stringify(err));
      return rejectWithValue(null);
    }
  }
);

export const loadFilesForMerge = createAsyncThunk("officeRequest/loadFilesForMerge", async (file: DsFile, { rejectWithValue }) => {
  try {
    if (file.url) {
      const res = await axios.get(file.url, { responseType: "blob" });
      return res as unknown as File;
    }
  } catch (err) {
    log.warn(JSON.stringify(err));
    return rejectWithValue(null);
  }
});

// Action creators are generated for each case reducer function
export const { clearUploadedFiles, resetStateFileSlice, uploadedInvoice } = FileSlice.actions;

export default FileSlice.reducer;
