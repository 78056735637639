import React, { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import { formatPhoneNumber, validateEmail, validatePhoneOrFaxNumber } from "../../../utils";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { OfficeService } from "../../../services/office.service";
import { ToastVariants } from "../../../models";
import { setRequestData } from "../../../redux/slices/OfficeRequestSlice";
import { UserType } from "../../../services/user.service";

const Recipients = ({
  onClickCancel,
  setIsLoading,
  isLoading,
}: {
  onClickCancel: (val?: boolean) => void;
  setIsLoading: (val: boolean) => void;
  isLoading?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { assignRequestError, requestData, loading } = useSelector((state: RootState) => state.officeRequest);
  const { patientRequestData } = useSelector((state: RootState) => state.user);
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const request = currentUser?.userType === UserType.Patient ? patientRequestData : requestData;
  const [errorInitiated, setErrorInitiated] = useState(false);
  const toast = useContext(ToastContext);
  const [errors, setErrors] = useState({
    name: false,
    emailOrFax: false,
  });
  const [name, setname] = useState(request?.recipientName || "");
  const [emailOrFax, setEmailOrFax] = useState(request?.recipientContact || "");

  const handleNameChange = (value: string) => {
    setname(value);
    let isValid = value !== "";
    if (value === "") {
      isValid = emailOrFax === "";
    }

    setErrors({ emailOrFax: emailOrFax === "" && value.length > 0, name: !isValid });
  };

  const validateForm = () => {
    return !errors.emailOrFax && !errors.name;
  };

  const handleEmailOrFaxInput = (event: React.ChangeEvent<HTMLInputElement>, cursorPosition: number) => {
    const value = event.currentTarget.value.trim();
    if (validateEmail(value)) {
      setErrors({ name: value.length > 0 && name === "", emailOrFax: false });
      setEmailOrFax(value);
    } else if (validatePhoneOrFaxNumber(formatPhoneNumber(value))) {
      setErrors({ name: value.length > 0 && name === "", emailOrFax: false });
      setEmailOrFax(formatPhoneNumber(value));
    } else {
      setErrors({ name: value.length > 0 && name === "", emailOrFax: true });
      setEmailOrFax(value);
    }
  };

  const saveRecipient = async () => {
    if (!name.trim() || !emailOrFax.trim()) {
      setErrors({ name: !name.trim(), emailOrFax: !emailOrFax.trim() });
      return;
    }
    if (request?.officeId && request?.requestId) {
      setIsLoading(true);
      try {
        const resp = await OfficeService.updateRequestRecipients(name, emailOrFax, request.requestId, request.officeId);
        setIsLoading(false);
        if (resp !== null) {
          onClickCancel(true);
          dispatch(setRequestData(resp));
          toast?.openToast("Updated successfully!", 2000, ToastVariants.Success);
        } else {
          toast?.openToast("Updation failed!");
        }
      } catch (_) {
        setIsLoading(false);
        toast?.openToast("Something went wrong please try again!", 2000, ToastVariants.Error);
      }
    }
  };

  return (
    <>
      {assignRequestError ? <p className="text-red-400 text-center mt-3">An error has occurred, please try again</p> : null}
      <div className="px-8 py-2">
        <TextInput
          onChangeFunc={e => handleNameChange(e.currentTarget.value)}
          // cursorPosition={formData[formData.findIndex(v => v.id === "recipientName")].cursorPosition}
          value={name}
          label={"Recipient Name"}
          placeholder="Enter name"
          errorMsg="Please provide recipient name"
          isError={errors.name && errorInitiated}
          onBlur={e => handleNameChange(e.currentTarget.value.trim())}
          isDarkBg={true}
        />
      </div>
      <div className="px-8 py-2">
        {" "}
        <TextInput
          onChangeFunc={e => handleEmailOrFaxInput(e, e.target.selectionEnd)}
          value={emailOrFax}
          label="Email or Fax Number"
          placeholder="Enter email address or fax number"
          errorMsg="Please provide valid email address or fax number with format 111-222-333"
          isError={errors.emailOrFax && errorInitiated}
          isDarkBg={true}
        />
      </div>

      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
            disabled={isLoading}
          />
          <div>
            <Button
              onClickFunc={() => {
                setErrorInitiated(true);
                if (validateForm()) {
                  saveRecipient();
                }
              }}
              varient={loading ? "loading" : "Primary"}
              disabled={(!validateForm() && errorInitiated) || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Recipients;
