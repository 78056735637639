import React, { ChangeEvent } from "react";
import { colors } from "../../utils/constants";

interface TextAreaPropType {
  label: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  isRequired?: boolean;
  errorMsg?: string;
  value?: string;
  name?: string;
  isError?: boolean;
  extraTextAreaClass?: string;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = ({
  label = "",
  onChange = () => {},
  placeholder = "",
  isRequired = false,
  errorMsg = "",
  value = "",
  name = "",
  isError = false,
  extraTextAreaClass = "",
  onBlur,
}: TextAreaPropType) => {
  return (
    <>
      <label className="text-sm font-medium text-docsigna-blue-dark block w-full mb-1">
        {label}
        {isRequired ? <span className="text-red-500">*</span> : null}
      </label>
      <textarea
        className={"text-base block w-full rounded-md shadow-none border-docsigna-blue-dark" + extraTextAreaClass}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={e => onChange(e)}
        onBlur={e => (onBlur ? onBlur(e) : null)}></textarea>
      {isError ? <p style={{ color: colors.Red }}>{errorMsg}</p> : null}
    </>
  );
};

export default TextArea;
