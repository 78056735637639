import { useEffect, useState } from "react";
import PatientWelcomeIcon from "../../assets/images/patient-welcome-icon.png";
import LoadMore from "../../assets/images/load-more-arrow.svg";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { Button, ConfirmationDialog } from "../../components";
import { Office } from "../../models/office.model";
import { Modal } from "@mui/material";
import Map from "../../components/Map";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../utils/constants";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency, getTimeInStringFormat } from "../../utils";
import { useDispatch } from "react-redux";
import { getOfficeUninsuredServices } from "../../redux/slices/OfficeSlice";
import { setShowNotifications } from "../../redux/slices/NotificationSlice";
import { Service } from "../../models/service.model";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const PatientDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [showMap, setShowMap] = useState(false);
  const [showCloseAlert, setShowCloseAlert] = useState(false);
  const [showUninsuredService, setShowUninsuredService] = useState(false);
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const { uninsuredServices } = useSelector((state: RootState) => state.office);
  const { notifications } = useSelector((state: RootState) => state.notifications);
  const office: Office | undefined = currentUser?.offices?.length ? currentUser?.offices[0] : undefined;

  const handleViewAllNotification = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(setShowNotifications(true));
  };

  const init = async () => {
    if (office?.officeId) {
      await dispatch(getOfficeUninsuredServices({ officeId: office.officeId, page: 0, size: 200, sort: "name,asc" }));
    }
  };

  const showTime = (val?: string) => {
    return val ? moment(val, "HH:mm:ss").format("hh:mm A") : "";
  };

  const renderCloseMessage = () => {
    return (
      <div className="flex flex-col gap-4">
        <h2 className="font-bold">{office?.name} is currently closed</h2>
        <p>{office?.tempClosedReason ?? ""}</p>
        <p>Your request will not be reviewed until the office has reopened.</p>
      </div>
    );
  };

  const navigateRequest = () => navigate(`/patients/${currentUser?.userId}/requests/create-request`);

  const sortServices = (a: Service, b: Service) => {
    // Check if either is featured
    if (a.featured && !b.featured) return -1;
    if (!a.featured && b.featured) return 1;

    // If both are featured or neither is featured, sort by name
    return a.name.localeCompare(b.name);
  };

  const address =
    office?.street && office?.city && office?.state && office?.zip
      ? `${office?.street}, ${office?.city}, ${office?.state}, ${office?.zip}`
      : undefined;

  const featuredServices = uninsuredServices?.length ? [...uninsuredServices] : [];
  featuredServices.sort(sortServices);

  useEffect(() => {
    init();
  }, [office]);

  return (
    <>
      <main>
        <div className="p-4 md:p-[30px]">
          <div className="flex flex-wrap md:flex-nowrap md:grid-cols-2 gap-4 md:gap-7">
            <div className="md:w-8/12 w-full">
              <div className="bg-[#F3F3F9] p-[30px] relative rounded-3xl flex">
                <div className="w-8/12">
                  <h1 className="text-lg font-extrabold mb-[5px] uppercase">
                    HI {currentUser?.firstName} {currentUser?.lastName}
                  </h1>
                  <h2 className="heading text-[24px] leading-[36px] font-semibold mb-[15px]">WELCOME TO DOCNOTE</h2>
                  <p className="text-gray-600 mb-6">
                    {`Welcome to Docnote—our secure portal designed with your care, convenience, and privacy in mind. Easily make your uninsured service requests online and communicate directly with ${office?.name}.`}
                  </p>
                </div>
                <div className="absolute right-8 w-4/12 flex items-center top-0 bottom-0">
                  <img src={PatientWelcomeIcon} alt="Illustration" className="" />
                </div>
              </div>
              <div className="flex flex-wrap lg:flex-nowrap w-full gap-4 md:gap-7 justify-between mt-4 md:mt-7 lg:min-h-[60%]">
                <div className="bg-[#F3F3F9] p-[30px] rounded-3xl w-full">
                  <h2 className="heading font-semibold text-2xl uppercase">Recent Notifications</h2>
                  {notifications?.length ? (
                    <>
                      <ul className="py-5">
                        {notifications.slice(0, 3).map(notification => {
                          return (
                            <li key={notification.notificationId} className="flex flex-col gap-2 pb-2 pt-3 border-b">
                              <Link to={`/patients/${currentUser?.userId}/requests/${notification.entityId}`}>
                                <h3 className="text-base font-medium">{notification.text}</h3>
                                <span className="opacity-50">{getTimeInStringFormat(notification.createdDate)}</span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      {notifications?.length > 3 ? (
                        <Button text="View All Notifications" AdditionalClassNames="uppercase" width="fit" onClickFunc={handleViewAllNotification} />
                      ) : null}
                    </>
                  ) : (
                    <div className="text-center text-base font-medium flex flex-col gap-10 mt-10 border-b pb-2">
                      <p>You currently do not have any notifications.</p>
                      <p>Once a request is initiated, updates regarding the status will be displayed here.</p>
                    </div>
                  )}
                </div>
                <div className="bg-[#F3F3F9] p-[30px] rounded-3xl w-full">
                  <h2 className="heading font-semibold text-2xl uppercase">{office?.name}</h2>
                  <div className="flex gap-5">
                    <div>
                      <h3 className="text-base font-medium">
                        {office?.street},{office?.street2 ? ` Unit ${office?.street2}, ` : ""}
                      </h3>
                      <h3 className="text-base font-medium">
                        {office?.city}, {office?.state}, {office?.zip}
                      </h3>
                    </div>
                    <Button text="View Map" width="full" onClickFunc={() => setShowMap(true)} AdditionalClassNames="!w-max" />
                  </div>
                  <div className="mt-6 flex flex-wrap gap-2">
                    {office?.phone ? (
                      <div>
                        Ph: <b>{office.phone},</b>
                      </div>
                    ) : null}
                    {office?.fax ? (
                      <div className="">
                        F: <b>{office.fax}</b>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex gap-5 mt-7">
                    <div className="w-full">
                      <h3 className="text-base font-medium">Office Hours</h3>
                      {office?.tempClosedReason ? (
                        <p className="text-docnote-orange text-center">{office.tempClosedReason}</p>
                      ) : (
                        <ul>
                          <li className="flex justify-start gap-5 text-sm">
                            <div className="w-12">
                              <b>Mon:</b>
                            </div>
                            <b>{office?.monClosed ? "Closed" : `${showTime(office?.monOpen)} - ${showTime(office?.monClose)}`}</b>
                          </li>
                          <li className="flex justify-start gap-5 text-sm">
                            <div className="w-12">
                              <b>Tue:</b>
                            </div>
                            <b>{office?.tueClosed ? "Closed" : `${showTime(office?.tueOpen)} - ${showTime(office?.tueClose)}`}</b>
                          </li>
                          <li className="flex justify-start gap-5 text-sm">
                            <div className="w-12">
                              <b>Wed:</b>
                            </div>
                            <b>{office?.wedClosed ? "Closed" : `${showTime(office?.wedOpen)} - ${showTime(office?.wedClose)}`}</b>
                          </li>
                          <li className="flex justify-start gap-5 text-sm">
                            <div className="w-12">
                              <b>Thu:</b>
                            </div>
                            <b>{office?.thuClosed ? "Closed" : `${showTime(office?.thuOpen)} - ${showTime(office?.thuClose)}`}</b>
                          </li>
                          <li className="flex justify-start gap-5 text-sm">
                            <div className="w-12">
                              <b>Fri:</b>
                            </div>
                            <b>{office?.friClosed ? "Closed" : `${showTime(office?.friOpen)} - ${showTime(office?.friClose)}`}</b>
                          </li>
                          <li className="flex justify-start gap-5 text-sm">
                            <div className="w-12">
                              <b>Sat:</b>
                            </div>
                            <b>{office?.satClosed ? "Closed" : `${showTime(office?.satOpen)} - ${showTime(office?.satClose)}`}</b>
                          </li>
                          <li className="flex justify-start gap-5 text-sm">
                            <div className="w-12">
                              <b>Sun:</b>
                            </div>
                            <b>{office?.sunClosed ? "Closed" : `${showTime(office?.sunOpen)} - ${showTime(office?.sunClose)}`}</b>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:w-4/12 w-full flex-col gap-4 md:gap-7">
              <div
                onClick={() => (office?.tempClosed ? setShowCloseAlert(true) : navigateRequest())}
                className="bg-docnote-orange rounded-3xl flex justify-between relative cursor-pointer">
                <h2 className="text-white uppercase heading text-2xl font-semibold p-[30px] pr-0">
                  create a <br />
                  new request
                </h2>
                <div className="bg-[#FF724D] w-32 h-full rounded-r-3xl rounded-tl-[50%] rounded-bl-[50%] flex justify-center items-center">
                  <div className="border-2 border-white rounded-full w-10 h-10 flex justify-center items-center">
                    <span className="text-[40px] font-normal text-white">+</span>
                  </div>
                </div>
              </div>
              <div className="bg-docnote-green rounded-3xl p-[30px] pb-[20px] flex flex-col justify-between relative">
                <h2 className="heading text-[22px] text-[#1A4382] font-semibold uppercase">What are Uninsured services ?</h2>
                <p className="mt-[16px] text-[15px] text-[#1A4382]">
                  In Ontario, uninsured health care services refer to medical services that are not considered medically necessary by the Ontario
                  Health Insurance Plan (OHIP) and are therefore not covered. Such services may include administrative tasks like filling out sick
                  notes and insurance forms, or performing elective procedures like cosmetic surgery.
                </p>
                {featuredServices?.length ? (
                  <>
                    <h2 className="heading mt-[20px] text-[22px] text-docnote-blue-light font-semibold uppercase">Uninsured services</h2>
                    <ul>
                      {featuredServices?.slice(0, 3).map(service => {
                        return (
                          <li
                            key={service.serviceId}
                            className="heading text-lg text-docnote-blue-light font-normal border-b border-docnote-blue-light border-opacity-20 py-[5px] flex justify-between items-center">
                            <h2>{service.name}</h2>
                            <h2>{formatCurrency((service?.price / 100).toString(), "blur")}</h2>
                          </li>
                        );
                      })}
                    </ul>
                    {featuredServices?.length > 3 ? (
                      <div className="flex justify-center mt-5">
                        <img src={LoadMore} alt="" onClick={() => setShowUninsuredService(true)} width={48} height={48} />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Modal open={showMap} onClose={() => setShowMap(false)} className="flex justify-center items-center">
          <div className="w-full md:w-5/12 h-3/4 p-10 bg-docnote-green rounded-2xl flex flex-col justify-between relative">
            <CloseIcon htmlColor={colors.DarkBlue} className="absolute top-3 right-3 cursor-pointer" onClick={() => setShowMap(false)} />
            {apiKey && address ? <Map address={`${address}`} apiKey={apiKey} /> : null}
          </div>
        </Modal>
        <Modal open={showUninsuredService} onClose={() => setShowUninsuredService(false)} className="flex justify-center items-center">
          <div className="w-full md:w-4/12 h-1/2 p-10 bg-docnote-green rounded-2xl flex flex-col justify-between relative overflow-y-auto">
            <CloseIcon htmlColor={colors.DarkBlue} className="absolute top-3 right-3 cursor-pointer" onClick={() => setShowUninsuredService(false)} />
            <div>
              <h2 className="heading uppercase text-2xl font-semibold mb-3">Uninsured services</h2>
              <div className="flex justify-between items-center heading uppercase text-lg font-semibold py-1 border-b border-docnote-blue-light">
                <h2>Services</h2>
                <h2>Cost</h2>
              </div>
              <ul>
                {uninsuredServices?.map((service, index) => {
                  return (
                    <li
                      key={`${service.serviceId}-${index}`}
                      className="flex gap-2 justify-between items-center heading capitalize text-lg py-1 border-b border-docnote-blue-light border-opacity-20">
                      <p>{service.name}</p>
                      <p>{formatCurrency((service?.price / 100).toString(), "blur")}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Modal>
        <ConfirmationDialog
          open={showCloseAlert}
          description={renderCloseMessage()}
          handleFailure={() => setShowCloseAlert(false)}
          handleSuccess={() => navigateRequest()}
          successButtonText="Proceed"
          failureButtonText="Cancel"
        />
      </main>
    </>
  );
};

export default PatientDashboard;
