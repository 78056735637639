import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { formatDate, invoicePrice } from "../../../utils";
import Logo from "../../../../src/assets/images/logo.png";
import moment from "moment";
import paidStamp from "../../../assets/images/paid-stamp.png";

const InvoicePatientPdf = () => {
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const { invoiceObject } = useSelector((state: RootState) => state.officeRequest);
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const { serviceAmount, total, subTotal, hst, dsFees } = invoicePrice(invoiceObject);

  return (
    <>
      <div className="w-full h-a4 flex flex-col  ">
        <div className="px-8">
          <div className="flex justify-between">
            <span>
              <img src={Logo} alt="" className="w-48" />
            </span>
            <span>
              <img src={paidStamp} alt="" className="w-36 h-14" />
            </span>
          </div>
          <div className="flex justify-between">
            <p className="text-lg font-semibold mb-1 py-2">Invoice #{requestData?.requestNumber}</p>
            <p className="text-lg font-semibold mb-1 py-2">Date: {moment(requestData?.createdDate).format("ll")}</p>
          </div>
          <div id="invoice-details">
            <div>
              <h2 className="text-xl mb-1 font-semibold">{currentOffice?.name}</h2>
              <p className="font-light ">{`${currentOffice?.street}, ${currentOffice?.city},`}</p>
              <p className="font-light">{` ${currentOffice?.state}, ${currentOffice?.zip}`}</p>
              <p className="font-light">{` Phone: ${currentOffice?.phone ?? "-"}`}</p>
              <p className="font-light">{` Fax: ${currentOffice?.fax ?? "-"}`}</p>
              {currentOffice?.taxNumber ? <p className="font-light mb-4"> HST # {currentOffice?.taxNumber}</p> : null}
            </div>
          </div>

          <div id="invoice-patient-details" className="mt-3 mb-5">
            <p className="text font-semibold">Patient Details:</p>
            <p>
              {requestData?.firstName} {requestData?.lastName}
            </p>
            <p>Date of Birth: {formatDate(requestData?.dateOfBirth ? requestData?.dateOfBirth : "", "PP")}</p>
          </div>
        </div>
        <div id="invoice-fee" className="py-2  px-8" style={{ backgroundColor: "#F5F5F5" }}>
          <p className="text font-semibold mb-3">Fees</p>
        </div>

        <div className="px-8">
          <div className="flex">
            <p className="w-full">{invoiceObject?.service?.description}</p>
            <p className="right-0 mr-0 ml-auto">${(serviceAmount || 0).toFixed(2)}</p>
          </div>
          <div>
            {invoiceObject?.fees?.map((fee, index) => (
              <div className="flex" key={index}>
                <p className="w-full">{fee?.description}</p>
                <p className="right-0 mr-0 ml-auto">${(fee.amount / 100).toFixed(2)}</p>
              </div>
            ))}
          </div>
          <hr className="border border-black mt-4 mb-4" />
          <>
            <div>
              <div className="flex">
                <p className="py-0.5 w-full ml-[50%]">Subtotal</p>
                <p className="right-0 mr-0">${subTotal.toFixed(2)}</p>
              </div>

              <div className="flex">
                <p className="py-0.5 w-full ml-[50%]">Docnote Fee</p>
                <p className="right-0 mr-0">${(+dsFees).toFixed(2)}</p>
              </div>

              <div className="flex">
                <p className="py-0.5 w-full ml-[50%]">HST</p>
                <p className="right-0 mr-0">${hst.toFixed(2)}</p>
              </div>
            </div>
            <hr className="border border-black mt-4 mb-4" />
          </>
          <div className="flex">
            <p className="py-0.5 w-full ml-[50%] font-semibold">Invoice total</p>
            <p className="right-0 mr-0 font-semibold">${total.toFixed(2)}</p>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <p className="text-xs text-center text-docsigna-purple">481 London Road, Unit 109, Sarnia, ON N7T 4X3 HST # 703533687 RT 0001</p>
        <p className=" text-xs text-center text-docsigna-purple mb-5">
          This service has been provided by Bluewater Promotions Inc., operating as Docnote
        </p>
      </div>
    </>
  );
};

export default InvoicePatientPdf;
