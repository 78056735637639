import { Fade, Slide } from "@mui/material";
import React, { MouseEventHandler } from "react";

interface UserSideBarPropType {
  title: string;
  visible?: boolean;
  onCancelClick?: () => void;
  onInviteClick?: () => void;
  LeftbuttonText?: string;
  RightbuttonText?: string;
  children: JSX.Element;
  onClickOutside: MouseEventHandler<HTMLDivElement>;
}

const UserSideBar = ({ visible = false, onClickOutside = () => {}, children, title }: UserSideBarPropType) => {
  return (
    <div>
      <Fade in={visible} appear={false} mountOnEnter unmountOnExit>
        <div
          style={{ opacity: 0.5 }}
          onClick={onClickOutside}
          className="fixed top-0 left-0 w-screen h-screen bg-docsigna-overlay opacity-50 z-20"></div>
      </Fade>
      <Slide direction="left" in={visible} mountOnEnter unmountOnExit>
        <div className="fixed top-0 right-0 w-11/12 max-w-120 h-screen z-20 bg-docsigna-pink-light">
          <div className="text-xl px-8 py-5 text-docsigna-blue-dark font-bold">{title}</div>
          <div className="inline-block h-screen overflow-auto w-full pb-52">{children}</div>
        </div>
      </Slide>
    </div>
  );
};

export default UserSideBar;
