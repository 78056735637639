import React from "react";
import { colors } from "../../utils/constants";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

interface PasswordErrorPropType {
  message: string;
  isValid: boolean;
}
const TextInput = ({ message = "", isValid = false }: PasswordErrorPropType) => {
  const color = isValid ? colors.DarkBlue : colors.Orange;

  const icon = isValid ? <CheckCircleOutlinedIcon fontSize="small" /> : <CancelOutlinedIcon fontSize="small" />;

  return (
    <div>
      <p style={{ color, fontSize: "15px", lineHeight: "18px", display: "flex", gap: "4px", alignItems: "center" }}>
        <span>{icon}</span>
        <span>{message}</span>
      </p>
    </div>
  );
};

export default TextInput;
