import { ApiError, ApiErrorCode } from "../models/api-error.model";
import { ApiPage, emptyApiPage } from "../models/api-page.model";
import { InvitationResponse } from "../models/invitation-response.model";
import { Request } from "../models/request.model";
import { UserUpdate } from "../models/user-update.model";
import { CreateUser, JobTitle, User } from "../models/user.model";
import { apiService } from "./api.service";
import { AuthPermission } from "./auth.service";

export enum UserType {
  SystemAdmin = "system-admin",
  OfficeAdmin = "office-admin",
  Accounting = "accounting",
  User = "user",
  Patient = "patient",
}
export class UserService {
  private static BASE_PATH = "/api/v1/users";

  static async getAllUsers(page = 0, size = 20, sort = "lastName,asc"): Promise<ApiPage<User>> {
    const response = await apiService.get(`${this.BASE_PATH}?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<User>();

    return response.data as ApiPage<User>;
  }

  static async searchUsers(term: string, page = 0, size = 20, sort = "lastName,asc", userState?:string): Promise<ApiPage<User>> {
    const response = await apiService.post(`${this.BASE_PATH}/search?page=${page}&size=${size}&sort=${sort}`, {
      term: term ? term : undefined,
      userState
    });
    if (!response.success) return emptyApiPage<User>();

    return response.data as ApiPage<User>;
  }

  static async getUser(userId: string): Promise<User | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${userId}`);
    if (!response.success) return null;

    return response.data as User;
  }

  static async emailExists(email: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/email-exists`, {
      email: email,
    });
    if (!response.success) return false;

    return true;
  }

  static async inviteUser(
    firstName: string,
    lastName: string,
    email: string,
    jobTitleId: string,
    userType: UserType = UserType.SystemAdmin,
    permissions: AuthPermission[],
    primaryCareProvider: boolean,
    officeId?: string,
    phone?: string
  ): Promise<InvitationResponse | null> {
    const response = await apiService.post(`${this.BASE_PATH}/invite`, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      userType: userType,
      permissions: permissions,
      primaryCareProvider: primaryCareProvider,
      officeId: officeId,
      phone: phone,
      jobTitleId
    });

    if (!response.success) {
      throw new ApiError(response.status === 409 ? ApiErrorCode.EXISTS : ApiErrorCode.UNKNOWN, JSON.stringify(response.data));
    }

    return response.data as InvitationResponse;
  }

  static async cancelUserInvitation(userId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/invite/${userId}`);
    if (!response.success) return false;

    return true;
  }

  static async resendUserInvitation(userId: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/invite-resend`, {
      userId: userId,
    });
    if (!response.success) return false;

    return true;
  }

  static async getUserByInviteToken(token: string): Promise<User | null> {
    const response = await apiService.get(`${this.BASE_PATH}/tokens/${token}`);
    if (!response.success) return null;

    return response.data as User;
  }

  static async acceptUserInvitation(token: string, password: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/invite-accept`, {
      token: token,
      password: password,
    });
    if (!response.success) return false;

    return true;
  }

  static async updateUser(user: UserUpdate): Promise<User | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${user.userId}`, user);
    if (!response.success) return null;

    return response.data as User;
  }

  static async getUserRequests(userId: string, page = 0, size = 20, sort = "createdDate,desc"): Promise<ApiPage<Request>> {
    const response = await apiService.get(`${this.BASE_PATH}/${userId}/requests?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<Request>();

    return response.data as ApiPage<Request>;
  }

  static async getUserRequest(userId: string, requestId: string): Promise<Request | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${userId}/requests/${requestId}`);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async createUser(user: CreateUser): Promise<CreateUser | ApiError> {
    const response = await apiService.post(`${this.BASE_PATH}/register`, user);
    if (!response.success) throw new ApiError(response?.data?.message);

    return response.data as CreateUser;
  }

  static async searchPatients(term: string, page=0, size=10, sort = 'lastName,asc'): Promise<ApiPage<User>> {
    const response = await apiService.post(`/api/v1/patients/search?page=${page}&size=${size}&sort=${sort}`, {
      term
    });
    if (!response.success) return emptyApiPage<User>();

    return response.data as ApiPage<User>;
  }

  static async getJobTitles(): Promise<ApiPage<JobTitle>> {
    const response = await apiService.get(`/api/v1/job-titles`);
    if (!response.success) return emptyApiPage();
    return response.data;
  }
}
