import { useCallback, useContext, useRef, useState } from "react";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { getOfficeRequest, setOfficeRequestUrgent } from "../../redux/slices/OfficeRequestSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  Button,
  Checkbox,
  Comment,
  ConfirmationDialog,
  FullScreenLoader,
  ProtectedComponent,
  RequestOverViewFileUpload,
  TextInput,
} from "../../components";
import { AuthPermission, UserState } from "../../services/auth.service";
import { billedToOptions, formatDate, formatOhipNumber } from "../../utils";
import {
  getOfficeRequestPatientMessages,
  addOfficeRequestMessage,
  getOfficeRequestInternalMessages,
  setOfficeRequestMessageRead,
} from "../../redux/slices/RequestSlice";
import { RequestState, RequestType, RequestVerificationStateType } from "../../models/request.model";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../utils/defaultStyle";
import { User } from "@sentry/react";
import { getOfficeUsersWithPermission } from "../../redux/slices/OfficeSlice";
import { useNavigate } from "react-router-dom";
import classNames from "../../assets/css/mention.module.css";

const RequestOverviewTab = ({
  requestId,
  officeId,
  handleFromVisibility,
}: {
  requestId: string | undefined;
  officeId: string | undefined;
  handleFromVisibility: (val: RequestVerificationStateType["visibleForm"] | null) => void;
}) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(false);
  const [getRequestData, setGetRequestData] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [internalPending, setInternalPending] = useState(false);
  const [externalPending, setExternalPending] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [staff, setStaff] = useState<{ display: string; id: string }[]>([]);
  const toast = useContext(ToastContext);
  const { requestData, loading } = useSelector((state: RootState) => state.officeRequest);
  const { userWithPermission } = useSelector((state: RootState) => state.office);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const { internalMessage, patientMessage } = useSelector((state: RootState) => state.request);
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const permissions = currentUser?.permissions;
  const [commentInputs, setCommentInputs] = useState({ internal: "", patientCommunication: "" });
  const isAuthorizedToEdit = Array.isArray(permissions) && permissions.includes(AuthPermission.Request);
  const isCompletedOrCancelled = requestData?.state === RequestState.Complete || requestData?.state === RequestState.Cancelled;
  const isCancelled = requestData?.state === RequestState.Cancelled;
  const isArchived = requestData?.archived;
  const showDeclineRequest = requestData?.state === RequestState.PendingVerification && requestData?.requestType === RequestType.Standard;
  const isThirdParty =
    requestData?.requestType === RequestType.ThirdPartyOffice ||
    requestData?.requestType === RequestType.PrescriptionRefill ||
    requestData?.requestType === RequestType.DocnoteDebit;

  const openForm = (formName: RequestVerificationStateType["visibleForm"]) => {
    handleFromVisibility(formName);
  };

  const onMentionClick = () => {
    toast?.openToast();
  };

  const handleScroll = async () => {
    if (requestData?.assignedTo ? requestData?.assignedTo?.userId === currentUser?.userId : true) {
      const patientUnReadMessage = patientMessage?.filter(f => f.unread === true);
      const internalUnReadMessage = internalMessage?.filter(f => f.unread === true);
      if (internalUnReadMessage?.length || patientUnReadMessage?.length) {
        const setReadPromises: any = [];
        patientUnReadMessage?.forEach(async e => {
          if (officeId && requestId && e.requestId === requestId) {
            const promise = dispatch(setOfficeRequestMessageRead({ officeId, requestId, messageId: e.requestMessageId, read: true }));
            setReadPromises.push(promise);
          }
        });
        internalUnReadMessage?.forEach(async e => {
          if (officeId && requestId && e.requestId === requestId) {
            const promise = dispatch(setOfficeRequestMessageRead({ officeId, requestId, messageId: e.requestMessageId, read: true }));
            setReadPromises.push(promise);
          }
        });

        await Promise.all(setReadPromises);

        if (officeId && requestId) {
          dispatch(getOfficeRequest({ officeId, requestId }));
          dispatch(getOfficeRequestPatientMessages({ officeId, requestId }));
          dispatch(getOfficeRequestInternalMessages({ officeId, requestId }));
        }
      }
    }
  };

  const handleUrgent = (checked: boolean) => {
    if (officeId && requestId)
      dispatch(setOfficeRequestUrgent({ officeId: officeId, requestId: requestId, urgent: checked })).then(action => {
        if (action.payload !== null) toast?.openToast("Status successfully changed.");
      });
  };

  const sendPatientCommentMessage = () => {
    if (commentInputs.patientCommunication.trim() !== "" && officeId && requestId && currentUser) {
      setExternalPending(true);
      dispatch(
        addOfficeRequestMessage({
          officeId: officeId,
          requestId: requestId,
          message: commentInputs.patientCommunication,
          internal: false,
          senderId: currentUser?.userId,
        })
      ).then(() => {
        setExternalPending(false);
        dispatch(getOfficeRequest({ officeId, requestId }));
        setCommentInputs(comments => {
          return { ...comments, patientCommunication: "" };
        });
        dispatch(getOfficeRequestPatientMessages({ officeId, requestId }));
      });
    }
  };

  const sendInternalCommentMessage = () => {
    if (commentInputs.internal.trim() !== "" && officeId && requestId && currentUser) {
      setInternalPending(true);
      dispatch(
        addOfficeRequestMessage({
          officeId: officeId,
          requestId: requestId,
          message: commentInputs.internal,
          internal: true,
          senderId: currentUser?.userId,
        })
      ).then(() => {
        setInternalPending(false);
        dispatch(getOfficeRequest({ officeId, requestId }));
        setCommentInputs(comments => {
          return { ...comments, internal: "" };
        });
        dispatch(getOfficeRequestInternalMessages({ officeId, requestId }));
      });
    }
  };

  const readAction = (messageId: string, read: boolean) => {
    if (requestId && officeId) {
      dispatch(setOfficeRequestMessageRead({ officeId, requestId, messageId, read })).then(_ => {
        dispatch(getOfficeRequestPatientMessages({ officeId, requestId }));
      });
    }
  };

  const init = async () => {
    if (requestId && officeId) {
      await dispatch(getOfficeRequest({ officeId, requestId }));
      setGetRequestData(true);
      await dispatch(getOfficeUsersWithPermission({ officeId, permission: AuthPermission.Request, page: 0, sort: "", size: 100 }));
      await dispatch(getOfficeRequestInternalMessages({ officeId, requestId }));
      await dispatch(getOfficeRequestPatientMessages({ officeId, requestId }));
      setInitialized(true);
    }
  };

  useEffect(() => {
    init();
  }, [officeId, requestId]);

  useEffect(() => {
    let newOptions = userWithPermission?.data?.content
      .filter(item => item.userState === "active")
      ?.map((user: User) => {
        return { display: `${user.firstName} ${user.lastName}`, id: user.userId };
      });
    newOptions = newOptions ? newOptions : [];
    setStaff(newOptions);
  }, [userWithPermission]);

  useEffect(() => {
    if (isCompletedOrCancelled || isArchived) {
      setReadOnly(true);
    } else setReadOnly(false);
  }, [requestData]);

  useEffect(() => {
    if (getRequestData && requestData?.requestId === undefined) {
      navigate("/offices");
    }
  }, [getRequestData, requestData]);

  const handleIntersect: IntersectionObserverCallback = useCallback(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Run your function when the div becomes visible
          handleScroll();
          // If you only want the function to run once, you can unobserve the element
          observer.unobserve(entry.target);
        }
      });
    },
    [initialized, requestData, currentUser]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // Add negative 100px margin to the bottom
      threshold: 0.1, // Trigger when 10% of the target is visible
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.disconnect();
      }
    };
  }, [handleIntersect]);

  useEffect(() => {
    localStorage.removeItem("additionalFee");
  }, []);

  return (
    <div className="flex flex-wrap md:flex-nowrap">
      {/* new code start */}
      {loading && requestData?.patientVerified === undefined ? <FullScreenLoader /> : null}
      <div className="w-full  mb-6 md:mb-0">
        <div className="flex items-center mb-3">
          <p className="text-base font-semibold">Patient Details</p>
          {requestData?.patient?.userState === UserState.Invited && requestData?.requestType !== RequestType.DocnoteDebit ? (
            <Button
              onClickFunc={() => null}
              text={"Pending Account Creation"}
              width="fit"
              AdditionalClassNames={`py-1.5 ml-2 !bg-docnote-green !border-docnote-green !text-black`}
            />
          ) : (
            <Button
              onClickFunc={() => (isAuthorizedToEdit && !readOnly ? openForm("VerifyPatient") : null)}
              text={requestData?.patientVerified ? "Verified" : "Unverified"}
              width="fit"
              verified={requestData?.patientVerified}
              AdditionalClassNames={`py-1.5 ml-2`}
            />
          )}

          {showDeclineRequest ? (
            <span className="ml-auto">
              <Button onClickFunc={() => openForm("DeclineRequest")} AdditionalClassNames="pointer py-1.5" text="Decline Request" width="fit" />
            </span>
          ) : null}
        </div>
        <div className="p-6 pt-2 w-full bg-docsigna-pink-light rounded-lg mb-6">
          {readOnly ? null : (
            <div className="flex justify-end">
              <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
                <span className="text-docsigna-blue-light text-sm cursor-pointer font-medium" onClick={() => openForm("VerifyPatient")}>
                  Edit
                </span>
              </ProtectedComponent>
            </div>
          )}
          <p className="text-md mb-2">
            <span className="font-medium">OHIP Number:</span> {requestData?.ohipNumber ? formatOhipNumber(requestData?.ohipNumber) : "-"}
          </p>
          <p className="text-md mb-2">
            <span className="font-medium">Date of Birth:</span> {requestData?.dateOfBirth ? formatDate(requestData?.dateOfBirth, "PP") : "-"}
          </p>
          <p className="text-md mb-2">
            <span className="font-medium">Email Address:</span>{" "}
            {requestData?.email ? (
              readOnly ? (
                <>{requestData?.email}</>
              ) : (
                <a href={`mailto:${requestData?.email}`} className="text-docsigna-purple-dark font-medium">
                  {requestData?.email}
                </a>
              )
            ) : (
              "-"
            )}
          </p>
          <p className="text-md">
            <span className="font-medium">Phone #:</span> {requestData?.phone ? requestData?.phone : "-"}
            {((isThirdParty && requestData?.patient?.userId) || !isThirdParty) && requestData?.phone && !readOnly ? (
              <span className="text-docsigna-purple-dark ml-5 cursor-pointer font-medium" onClick={() => openForm("SendMessage")}>
                Send Text
              </span>
            ) : null}
          </p>
        </div>

        <div className="flex justify-between items-center flex-wrap mb-3">
          <p className="text-base font-semibold w-full md:w-auto mb-2 md:mb-0">Request Details</p>
          {readOnly ? null : (
            <Checkbox
              label="Flag request as URGENT"
              checked={requestData?.urgent}
              onChange={e => handleUrgent(e.currentTarget.checked)}
              name="urgent_checkbox"
              id="urgent_checkbox"
            />
          )}
        </div>
        <div className="p-6 w-full border border-docsigna-pink rounded-lg mb-6">
          <p className="text-md mb-2">
            <span className="font-medium">Request#:</span> {requestData?.requestNumber}
          </p>
          <p className="text-md mb-2">
            <span className="font-medium">Date:</span> {formatDate(requestData?.createdDate ? requestData?.createdDate : "", "PP")}
          </p>
          <p className="text-md mb-2">
            <>
              <span className="font-medium">Service:</span> &nbsp;
              <span
                className={`text-md ${readOnly ? "" : "text-docsigna-blue-light font-medium cursor-pointer"}`}
                onClick={() => (isAuthorizedToEdit && !readOnly ? openForm("AssignService") : null)}>
                {requestData?.service ? requestData.service.name : "Unassigned"}
              </span>
            </>
          </p>
          {requestData && requestData.serviceNotes ? (
            <p className="text-md mb-2">
              <span className="font-medium">Service Notes:</span> {requestData?.serviceNotes ? requestData?.serviceNotes : ""}
            </p>
          ) : null}
          {requestData?.serviceBilledToOverride ? (
            <p className="text-md mb-2">
              <span className="font-medium">Billed To:</span>
              &nbsp;{" "}
              <span
                onClick={() => {
                  isAuthorizedToEdit && !readOnly && !isThirdParty ? openForm("AssignService") : null;
                }}
                className={`text-md ${isThirdParty || readOnly ? "" : "text-docsigna-blue-light font-medium cursor-pointer"}`}>
                {requestData?.serviceBilledToOverride ? billedToOptions[requestData?.serviceBilledToOverride] : requestData?.service?.serviceBilledTo}
              </span>
            </p>
          ) : null}
          <p className="text-md mb-2">
            <span className="font-medium">Assigned to:</span>
            &nbsp;{" "}
            <span
              onClick={() => {
                isAuthorizedToEdit && !readOnly ? openForm("AssignRequest") : null;
              }}
              className={`text-md ${readOnly ? "" : "text-docsigna-blue-light font-medium cursor-pointer"}`}>
              {requestData && requestData.assignedTo ? `${requestData.assignedTo.firstName} ${requestData.assignedTo.lastName}` : " Unassigned"}
            </span>
          </p>

          <p className="text-md mb-2">
            <span className="font-medium">Primary Care Provider:</span>
            &nbsp;
            <span
              onClick={() => {
                isAuthorizedToEdit && !readOnly ? openForm("AssignRequest") : null;
              }}
              className={`text-md ${readOnly ? "" : "text-docsigna-blue-light font-medium cursor-pointer"}`}>
              {" "}
              {requestData && requestData.primaryCareProvider
                ? `${requestData.primaryCareProvider.firstName} ${requestData.primaryCareProvider.lastName}`
                : " Unassigned"}
            </span>
          </p>
          {requestData?.requestType !== RequestType?.DocnoteDebit ? (
            <p className="text-md mb-2">
              <span className="font-medium"> Recipients: </span>
              &nbsp;{" "}
              <span className="text-md ">
                {requestData?.recipientName ? `Patient, ${requestData?.recipientName}  (${requestData?.recipientContact})` : readOnly ? "--" : null}{" "}
              </span>
              {readOnly ? null : (
                <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
                  <span
                    className="cursor-pointer text-md text-docsigna-blue-light font-medium"
                    onClick={() => {
                      if (isAuthorizedToEdit) openForm("Recipients");
                    }}>
                    &nbsp; Edit
                  </span>
                </ProtectedComponent>
              )}
            </p>
          ) : null}

          <p className="text-md mb-2">
            <span className="font-medium">Notes: </span>
            {readOnly ? null : (
              <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
                <span
                  className="cursor-pointer text-md text-docsigna-blue-light font-medium"
                  onClick={() => {
                    if (isAuthorizedToEdit && requestData?.requestType) {
                      if ([RequestType.ThirdPartyOffice, RequestType.WSIBOffice, RequestType.ServiceCanadaOffice].includes(requestData?.requestType))
                        openForm("EditNotes");
                      else setShowConfirmDialog(true);
                    }
                  }}>
                  &nbsp; Edit
                </span>
              </ProtectedComponent>
            )}
          </p>
          <p className="text-sm">{requestData?.details ?? ""}</p>
        </div>
        {requestData?.requestType !== RequestType.DocnoteDebit ? (
          <RequestOverViewFileUpload officeId={officeId} requestId={requestId} readOnly={readOnly} />
        ) : null}

        {/* Start of Comment section */}
        <div className="flex justify-between items-center mb-3" ref={divRef}>
          <p className="text-base font-semibold">Internal Communication</p>
        </div>
        <div
          className={`w-full mb-6 ${
            (internalMessage && internalMessage.length) || (!isCancelled && !isArchived) ? "p-6 border border-docsigna-pink rounded-lg" : ""
          }`}>
          {internalMessage && internalMessage.length ? (
            <div>
              {internalMessage?.map(cmt => (
                <Comment
                  onMentionClick={onMentionClick}
                  time={cmt.createdDate}
                  from={`${cmt.sender?.firstName} ${cmt.sender?.lastName}`}
                  text={cmt.message}
                  key={cmt.requestMessageId}
                  staff={staff}
                  comment={cmt}
                />
              ))}
            </div>
          ) : isCancelled || isArchived ? (
            "-"
          ) : null}
          {!isCancelled && !isArchived ? (
            <div className="text-right">
              <MentionsInput
                style={defaultStyle}
                placeholder="Add a message to internal staff"
                value={commentInputs.internal}
                className="mentions"
                classNames={classNames}
                onChange={(e, newValue) => {
                  setCommentInputs({ ...commentInputs, internal: newValue });
                }}>
                <Mention
                  trigger={"@"}
                  data={staff}
                  className={classNames.mentions__mention}
                  displayTransform={(id, display) => {
                    return `@${display}`;
                  }}
                  appendSpaceOnAdd={true}
                  markup="[##__id__|@__display__##]"
                />
              </MentionsInput>
              <Button
                text="Send"
                disabled={internalPending}
                onClickFunc={() => {
                  sendInternalCommentMessage();
                }}
                width="fit"
                name="send-internal-comment"
                AdditionalClassNames="mt-4 px-5"
              />
            </div>
          ) : null}
        </div>
        {!isThirdParty || requestData?.requestType === RequestType.DocnoteDebit ? (
          <>
            <div className="flex justify-between items-center mb-3">
              <p className="text-base font-semibold">Patient Communication</p>
            </div>
            <div
              className={`w-full ${
                (patientMessage && patientMessage.length) || (!isCancelled && !isArchived) ? "p-6 rounded-lg border border-docsigna-pink" : ""
              }`}>
              {patientMessage && patientMessage.length ? (
                <div>
                  {patientMessage?.map(cmt => (
                    <Comment
                      onMentionClick={onMentionClick}
                      time={cmt.createdDate}
                      from={
                        currentUser?.userType === "patient"
                          ? currentOffice?.name || ""
                          : `${cmt?.senderName ? cmt?.senderName : cmt?.sender?.firstName + " " + cmt?.sender?.lastName}`
                      }
                      key={cmt.requestMessageId}
                      text={cmt.message}
                      disableMentions
                      comment={cmt}
                      showReadAction={cmt?.sender?.userId !== currentUser?.userId && !isArchived}
                      readAction={readAction}
                    />
                  ))}
                </div>
              ) : isCancelled || isArchived ? (
                "-"
              ) : null}
              {!isCancelled && !isArchived ? (
                <div className="text-right">
                  <TextInput
                    isTextArea
                    extraInputClass="h-40"
                    placeholder="Send external message to patient"
                    value={commentInputs.patientCommunication}
                    onChangeFunc={e => setCommentInputs({ ...commentInputs, patientCommunication: e.currentTarget.value })}
                    onBlur={e => setCommentInputs({ ...commentInputs, patientCommunication: e.currentTarget.value.trim() })}
                  />
                  <Button
                    text="Send"
                    onClickFunc={() => {
                      sendPatientCommentMessage();
                    }}
                    disabled={externalPending}
                    name="send-patient-comment"
                    AdditionalClassNames="mt-4 px-5"
                    width="fit"
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : null}

        <ConfirmationDialog
          open={showConfirmDialog}
          title={"Patient will see any changes made to the notes section.  Please confirm you would like to proceed."}
          description={""}
          successButtonText={"Confirm"}
          handleSuccess={() => {
            openForm("EditNotes");
            setShowConfirmDialog(false);
          }}
          failureButtonText={"Cancel"}
          handleFailure={() => {
            setShowConfirmDialog(false);
          }}
        />

        {/* End of Comment section */}
      </div>
      {/* new code end */}
    </div>
  );
};

export default RequestOverviewTab;
