import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import moment from "moment";
import { formatCurrency, getSorting } from "../../utils";
import { Pagination, TableHeader, TableRow } from "../../components";
import { sortObj } from "../../redux/slices/OfficeSlice";
import { headers } from "./RequestsData";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServiceBilledTo } from "../../models/service.model";
import { Office } from "../../models/office.model";

const RequestTab = ({
  loadMoreRequests,
  sort,
  renderFilters,
  selectedOffice,
}: {
  loadMoreRequests: (page: number, size: number, sortBy: string) => void;
  sort: sortObj;
  renderFilters?: any;
  selectedOffice: Office | null;
}) => {
  const navigate = useNavigate();
  const [sortMethods, setSortMethods] = useState<boolean[]>(headers.map(() => false));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(headers.findIndex(item => item.sortKey === sort.sortedBy));
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [extraClasses] = useState<string[]>(headers.map(head => head.extraClasses));
  const { financeRequests, sort: pageable } = useSelector((state: RootState) => state.finance);
  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));

  const handleSort = (index: number) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    setActiveSortIndex(index);
    loadMoreRequests(0, 10, sortMethodString);
  };
  return (
    <>
      {financeRequests?.length ? (
        <div className="block">
          <div className="flex justify-between items-center border-b border-docsigna-blue-light">
            {headers.map((head, index) => (
              <TableHeader
                title={head.title}
                index={index}
                key={index}
                sortIcon={sortIcons[index]}
                handleSort={handleSort}
                extraClassesHeader={head.extraClasses}
                isClickable={head.isClickable}
              />
            ))}
          </div>
          {financeRequests.map((rowData, index) => {
            const total = rowData?.total ? rowData?.total / 100 : 0;
            const hst = rowData?.hst ? rowData?.hst / 100 : 0;
            const sortedData = {
              patient: <p className="truncate">{rowData.patientName}</p>,
              request: rowData?.requestNumber ?? "XXXXX",
              service: <p className="truncate">{rowData.serviceName}</p>,
              date: (
                <p className="truncate">
                  {rowData.serviceBilledToOverride === ServiceBilledTo.Patient
                    ? moment(rowData.paymentDate).format("LL")
                    : moment(rowData.submittedDate).format("LL")}
                </p>
              ),
              total: formatCurrency(total.toString(), "blur"),
              hst: formatCurrency(hst.toString(), "blur"),
              payment: rowData.paymentComplete ? "Complete" : "Pending",
              paymentMethod: rowData.paymentComplete ? (rowData.paymentMethod === "annual-plan" ? "Office" : "Stripe") : "",
            };
            return (
              <TableRow
                data={Object.values(sortedData)}
                key={index}
                onRowClick={() => (selectedOffice?.officeId ? navigate(`/offices/${selectedOffice?.officeId}/requests/${rowData.requestId}`) : null)}
                tooltipVisible={false}
                handleOpenTooltip={() => null}
                handleCloseTooltip={() => null}
                id={rowData.requestId}
                extraClassesRow={extraClasses}
              />
            );
          })}
          <Pagination
            colSpan={10}
            count={pageable.totalElements}
            onPageChange={(e, newPage) => {
              loadMoreRequests(newPage, sort.rowsPerPage, sort.sortedBy);
            }}
            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              loadMoreRequests(0, parseInt(event.target.value, 10), sort.sortedBy)
            }
            page={sort.pageNumber}
            rowsPerPage={sort.rowsPerPage}
          />
        </div>
      ) : null}
    </>
  );
};

export default RequestTab;
