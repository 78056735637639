export enum ServiceBilledTo {
  Patient = "patient",
  OHIP = "ohip",
  WSIB = "wsib",
  ThirdParty = "third-party",
  ServiceCanada = "service-canada",
  DocnoteDebit = "docnote-debit",
  PrescriptionRefill = "prescription-refill"
}

export enum ServiceBilledToName {
  "patient" = "Patient",
  "ohip" = "OHIP",
  "wsib" = "WSIB",
  "third-party" = "3rd Party",
  "service-canada" = "Service Canada",
  "docnote-debit" = "Docnote Debit",
  "prescription-refill" = "Prescription Refill"
}

export enum AnnualPlanBilledToName {
  "patient" = "Individual Plan",
  "ohip" = "Couple Senior",
  "wsib" = "Family Plan",
  "third-party" = "Couple Plan",
  "service-canada" = "Individual Senior",
  "docnote-debit" = "",
  "prescription-refill" = ""
}

export enum ServiceOwner {
  System = "system",
  Office = "office",
}

export type Service = {
  serviceId?: string;
  officeId?: string;
  sampleFileId?: string;
  name: string;
  description?: string;
  price: number;
  addTax: boolean;
  serviceBilledTo?: ServiceBilledTo;
  enabled: boolean;
  blockFeeDiscountPct?:number;
  uninsured?: boolean;
  featured?: boolean;
};
