import React from "react";
interface TabsPropType {
  options: string[];
  components: JSX.Element[];
  activeTab?: number;
  onClickTab?: (index: number) => void;
  fromOffice?: boolean;
  showBottomBorder?: boolean;
}

const ButtonTabs = ({ options = [""], components, activeTab = 0, onClickTab = () => {}, showBottomBorder }: TabsPropType) => {
  return (
    <div className="tabs">
      <div className={`flex justify-between ${showBottomBorder ? "border-b" : ""}`}>
        <div className="tab-nav flex">
          {options.map((TabTitle, index) => {
            return (
              <span
                key={index}
                className={`heading text-lg text-center px-6 py-4 rounded-t-lg mr-2 font-semibold
                  ${activeTab === index ? "bg-docsigna-pink-light" : "bg-docsigna-blue-dark text-white"}
                `}
                onClick={() => onClickTab(index)}
                style={{ cursor: "pointer" }}>
                {TabTitle}
              </span>
            );
          })}
        </div>
      </div>
      {components.length && components[activeTab] ? <div className="tab-content">{components[activeTab]}</div> : null}
    </div>
  );
};

export default ButtonTabs;
