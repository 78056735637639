import React, { ChangeEvent, useState } from "react";
import SearchIcon from "../../assets/images/search.svg";
import down from "../../../src/assets/images/down.svg";
import { ClickAwayListener } from "@mui/material";

type Props = {
  value: string;
  onSelect: (newValue: any) => void;
  options: any[];
  renderOption?: (option: any) => string | React.ReactNode;
  renderValue?: (option: any) => string | React.ReactNode;
  label?: string;
  required?: boolean;
  placeholder?: string;
  extraClasses?: string;
  noOptionText?: string;
  isSearchBox?: boolean;
  searchValue?: string;
  onSearchChange?: (e: ChangeEvent<HTMLInputElement>, newValue: string) => void;
  onSearcSelect?: (newValue: string) => void;
};
const SelectWithSearch = ({
  value,
  onSelect,
  required = false,
  isSearchBox,
  searchValue,
  onSearchChange,
  onSearcSelect,
  label,
  noOptionText = "No Option",
  extraClasses = "",
  options,
  placeholder,
  renderOption,
  renderValue,
}: Props) => {
  const [toggleOpenSelect, setToggleOpenSelect] = useState(false);
  return (
    <div className="w-full">
      {label ? (
        <label className="font-medium text-docsigna-blue-dark text-sm block mb-1">
          {" "}
          {label} {required && <span className="text-red-400"> * </span>}{" "}
        </label>
      ) : null}
      <div className={`relative rounded  ${extraClasses}`}>
        <button
          type="button"
          onClick={() => setToggleOpenSelect(prev => !prev)}
          className="bg-transparent rounded h-full w-full flex items-center justify-between px-2 font-medium text-docsigna-blue-dark shadow-none border border-docsigna-blue-dark p-2.5 leading-[1.41rem]">
          {(renderValue ? renderValue(value) : value) || placeholder}{" "}
          <div className="h-1.5">
            <img src={down} className={`h-1.5`} alt="" />
          </div>
        </button>
        {toggleOpenSelect ? (
          <ClickAwayListener onClickAway={() => setToggleOpenSelect(false)}>
            <div className="absolute z-50 shadow-lg w-full py-2 border rounded-md bg-white m-0 border-docsigna-blue-dark">
              {isSearchBox ? (
                <div className="px-2 mb-2 ">
                  <div className="w-full  border border-docsigna-blue-dark h-[35px] rounded flex justify-between items-center pr-2">
                    <input
                      value={searchValue}
                      onChange={e => onSearchChange && onSearchChange(e, e.target.value)}
                      className={`border-0 w-full rounded outline-none shadow-none h-full border-none ${searchValue?.length ? "text-black" : ""}`}
                      placeholder="Search"
                    />

                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
              ) : null}
              {options.length ? (
                // Options
                <div className={`${searchValue?.length ? "" : "h-48"}  overflow-auto`}>
                  {options.map((option, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          onSelect(option);
                          setToggleOpenSelect(false);
                          onSearcSelect && onSearcSelect("");
                        }}
                        className="cursor-pointer  hover:bg-slate-100  border-slate-400 px-2 py-2">
                        {renderOption ? renderOption(option) : option}
                      </div>
                    );
                  })}
                </div>
              ) : (
                // No Option Text
                <div className="text-center text-black-400">{noOptionText}</div>
              )}
            </div>
          </ClickAwayListener>
        ) : null}
      </div>
    </div>
  );
};
export default SelectWithSearch;
