import { ApiPage, emptyApiPage } from "../models/api-page.model";
import { Service } from "../models/service.model";
import { apiService } from "./api.service";

export class ServiceService {
  private static BASE_PATH = "/api/v1/services";

  static async getAllServices(page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Service>> {
    const response = await apiService.get(`${this.BASE_PATH}?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<Service>();

    return response.data as ApiPage<Service>;
  }

  static async searchServices(term?: string, enabledOnly = false, page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Service>> {
    const response = await apiService.post(`${this.BASE_PATH}/search?page=${page}&size=${size}&sort=${sort}`, {
      term: term,
      enabledOnly: enabledOnly,
    });
    if (!response.success) return emptyApiPage<Service>();

    return response.data as ApiPage<Service>;
  }

  static async getService(serviceId: string): Promise<Service | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${serviceId}`);
    if (!response.success) return null;

    return response.data as Service;
  }

  // Returns saved service with ID included, or null on failure
  static async addService(service: Service): Promise<Service | null> {
    const response = await apiService.post(`${this.BASE_PATH}`, service);
    if (!response.success) return null;

    return response.data as Service;
  }

  // Returns saved service with ID included, or null on failure
  static async updateService(service: Service): Promise<Service | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${service.serviceId}`, service);
    if (!response.success) return null;

    return response.data as Service;
  }

  static async deleteService(serviceId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/${serviceId}`);
    if (!response.success) return false;

    return true;
  }
}
