import { useContext, useEffect, useState } from "react";
import { Button, DatePicker, RenderName, TextInput } from "../../components";
import { AppDispatch, RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { User } from "../../models/user.model";
import { formatOhipNumberNew } from "../../utils";
import NumberFormat from "react-number-format";
import { colors } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { updatePatientUserProfile } from "../../redux/slices/CurrentUserSlice";
import { UserUpdate } from "../../models/user-update.model";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../../models";

interface formDataType {
  id: string;
  value: string;
  label: string;
  placeholder: string;
  required: boolean;
  errorMessage: string;
  valid: boolean;
}

const initialData: formDataType[] = [
  {
    id: "firstName",
    label: "First Name",
    required: false,
    placeholder: "Enter first name",
    value: "",
    valid: false,
    errorMessage: "Please provide first name",
  },
  {
    id: "lastName",
    label: "Last Name",
    required: false,
    placeholder: "Enter last name",
    value: "",
    valid: false,
    errorMessage: "Please provide last name",
  },
  {
    id: "ohipNumber",
    label: "OHIP Number",
    required: false,
    placeholder: "Enter OHIP Number",
    value: "",
    valid: true,
    errorMessage: "Please provide valid ohip number",
  },
  {
    id: "dateOfBirth",
    label: "Date Of Birth",
    required: false,
    placeholder: "MM/DD/YYYY",
    value: "",
    valid: false,
    errorMessage: "Please provide date of birth",
  },
];

const PatientProfile = () => {
  const toast = useContext(ToastContext);
  const [errorState, setErrorState] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.currentUser.currentUser);

  const validateForm = () => {
    return formData.every(val => val.valid);
  };

  const handleChange = (value: string, id: string) => {
    const index = formData.findIndex(item => item.id === id);
    const item = formData.find(item => item.id === id);
    let valid = !!value.trim();
    if (index !== -1 && item) {
      switch (id) {
        case "ohipNumber":
          if ((value as string).trim().length > 0) {
            valid = (value as string).length ? (value as string).length === 10 : true;
          } else {
            valid = true;
          }
          break;
      }
      setFormData([...formData.slice(0, index), { ...item, value: value, valid }, ...formData.slice(index + 1)]);
    }
  };

  const handleSave = async () => {
    setErrorState(false);
    if (!validateForm()) {
      setErrorState(true);
      return;
    }
    if (user && user?.offices?.length && user?.offices[0]?.officeId) {
      const { firstName, lastName, ohipNumber, dateOfBirth } = user;
      const oldObj = {
        firstName,
        lastName,
        ohipNumber,
        dateOfBirth,
      };
      const newObject = formData.reduce((acc: { [key: string]: string }, field) => {
        acc[field.id] = field.value;
        return acc;
      }, {} as { [key: string]: string });
      if (JSON.stringify(newObject) === JSON.stringify(oldObj)) {
        setEditProfile(false);
        return;
      }
      setLoading(true);
      const body: UserUpdate = {
        ...newObject,
        userId: user.userId,
        officeId: user?.offices[0]?.officeId,
        email: user?.email,
        phone: user?.phone,
      };
      const res = await dispatch(updatePatientUserProfile({ body, userId: user.userId, officeId: user?.offices[0]?.officeId }));
      if (!res.payload) {
        toast?.openToast("Unable to update profile", 2000, ToastVariants.Error);
      }
      toast?.openToast("Profile updated successfully!", 2000, ToastVariants.Success);
      setLoading(false);
      setEditProfile(false);
    }
  };

  const populateFormData = (user?: User): formDataType[] => {
    let newFormData = initialData;
    if (!user) return newFormData;
    newFormData = newFormData.map(item => {
      const newItem = { ...item };
      const val = user[item.id as keyof User]?.toString() ?? "";
      newItem.value = val;
      newItem.valid = !!val?.trim();
      return newItem;
    });
    return newFormData;
  };

  useEffect(() => {
    if (user) {
      const newFormData = populateFormData(user);
      setFormData(newFormData);
    }
  }, [user]);

  return (
    <main>
      <div className="container mx-auto p-8 relative">
        <div className="bg-slate-200 p-5 rounded-3xl">
          <div className="flex flex-wrap justify-between items-center">
            <h2 className="heading uppercase font-semibold">Profile</h2>
            {!editProfile ? <Button text="Edit" onClickFunc={() => setEditProfile(true)} AdditionalClassNames="!w-28" /> : null}
          </div>
          {!editProfile ? (
            <div className="flex flex-col gap-4">
              <RenderName title="Name" subTitle={`${user?.firstName} ${user?.lastName}`} type="input-list" />
              <RenderName title="OHIP Number" subTitle={user?.ohipNumber ? formatOhipNumberNew(user?.ohipNumber) : "-"} type="input-list" />
              <RenderName title="Date Of Birth" subTitle={user?.dateOfBirth ?? "-"} type="input-list" />
            </div>
          ) : (
            <div className="flex flex-col gap-4 mt-5">
              <div className="flex gap-5">
                {formData.slice(0, 2).map(item => {
                  return (
                    <div className="md:w-1/2 w-full" key={item.id}>
                      <TextInput
                        label={item.label}
                        placeholder={item.placeholder}
                        isRequired={item.required}
                        value={item.value}
                        onChangeFunc={e => handleChange(e.target.value, item.id)}
                        isError={errorState && !item.valid}
                        errorMsg={item.errorMessage}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="flex gap-5">
                {formData.slice(2, 3).map(item => {
                  return (
                    <div className="md:w-1/2 w-full" key={item.id}>
                      <label className="text-sm text-docsigna-blue-dark font-medium block w-full">{item.label}</label>
                      <NumberFormat
                        value={item.value as string}
                        isNumericString
                        format={"#### ### ###"}
                        placeholder={item.placeholder}
                        displayType="input"
                        className="general-ohip !bg-white"
                        onValueChange={values => {
                          handleChange(values.value, item.id);
                        }}
                      />
                      {!item.valid && errorState ? <p style={{ color: colors.Red }}>{item.errorMessage}</p> : null}
                    </div>
                  );
                })}
                <div className="md:w-1/2 w-full" />
              </div>
              <div className="flex gap-5">
                {formData.slice(3).map(item => {
                  return (
                    <div className="md:w-1/2 w-full" key={item.id}>
                      <DatePicker
                        name={item.id}
                        isRequired={item.required}
                        label={item.label}
                        placeholder={item.placeholder}
                        value={item.value}
                        onChange={val => handleChange(val, item.id)}
                        isError={errorState && !item.valid}
                        errorMsg={item.errorMessage}
                      />
                    </div>
                  );
                })}
                <div className="md:w-1/2 w-full" />
              </div>
              <div className="flex">
                <Button
                  text="Save"
                  onClickFunc={handleSave}
                  AdditionalClassNames="!w-28"
                  disabled={(!validateForm() && errorState) || loading}
                  varient={loading ? "loading" : "Primary"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default PatientProfile;
