import { ClickAwayListener } from "@mui/material";
import React, { useRef } from "react";

interface DropDownMenuPropType {
  options: string[];
  onClickOutside: () => void;
  onSelect: (index: number) => void;
  additionalComponents: JSX.Element[];
  disabledOptions?: string[];
}

const DropDownMenu = ({ options, onClickOutside, onSelect, additionalComponents, disabledOptions = [] }: DropDownMenuPropType) => {
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <ClickAwayListener key={options.toString()} onClickAway={onClickOutside}>
      <div
        ref={divRef}
        //TODO :: not coming pixel perfect, need to make it pixel perfect
        className="absolute right-0 top-3 bg-white w-48 rounded-md z-10 border shadow-md ">
        {options.length
          ? options.map((text, index) => {
              return (
                <div
                  className="block"
                  key={index}
                  onClick={e => {
                    e.stopPropagation();
                    onSelect(index);
                  }}
                  style={{
                    cursor: disabledOptions.includes(text) ? "" : "pointer",
                    // backgroundColor: disabledOptions.includes(text) ? "#e8e8e8" : "",
                  }}>
                  <p className="block text-base text-docsigna-blue-dark font-medium px-4 py-3 duration-100 hover:bg-gray-200 ">{text}</p>
                </div>
              );
            })
          : null}
        {additionalComponents}
      </div>
    </ClickAwayListener>
  );
};

export default DropDownMenu;
