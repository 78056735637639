import React from "react";
interface TabsPropType {
  options: string[];
  components: JSX.Element[];
  activeTab?: number;
  onClickTab?: (index: number) => void;
  fromOffice?: boolean;
  showBottomBorder?: boolean;
}

const Tabs = ({ options = [""], components, activeTab = 0, onClickTab = () => {}, showBottomBorder }: TabsPropType) => {
  return (
    <div className="tabs">
      <div className={`flex justify-between ${showBottomBorder ? "border-b" : ""}`}>
        <div className="tab-nav flex w-full border-b">
          {options.map((TabTitle, index) => {
            return (
              <span
                key={index}
                className={`
                text-lg text-center font-bold py-2 -mb-px mr-5 ${
                  activeTab === index ? "border-b border-docsigna-purple text-docsigna-blue-ldark" : "text-docsigna-blue-light"
                }
                `}
                onClick={() => onClickTab(index)}
                style={{ cursor: "pointer" }}>
                {TabTitle}
              </span>
            );
          })}
        </div>
      </div>
      {components.length && components[activeTab] ? <div className="tab-content py-5">{components[activeTab]}</div> : null}
    </div>
  );
};

export default Tabs;
