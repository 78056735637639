import { createContext, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastVariants } from "../../models";
import { RootState } from "../../redux/store";

export interface ToastContextType {
  isVisible: boolean;
  openToast: (Message?: string, TimeOut?: number, toastVariant?: ToastVariants) => void;
  closeToast: () => void;
  toastMsg: string;
  variant: ToastVariants;
  progress: number; // Progress of the toast between 0 to 100 based on it's timeout value.
}

export const ToastContext = createContext<ToastContextType | null>(null);

const ToastContextProvider = ({ children }: { children: ReactNode }) => {
  const [isVisible, setVisible] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [variant, setVariant] = useState(ToastVariants.Success);
  const [progress, setProgress] = useState<number>(0);
  const toastReducerSlice = useSelector((state: RootState) => state.user);
  let toastTimeout: NodeJS.Timeout;
  const openToast = (Message = "Feature under development", TimeOut = 2000, toastVariant: ToastVariants = ToastVariants.Success) => {
    clearTimeout(toastTimeout);
    setVisible(true);
    setToastMsg(Message);
    setVariant(toastVariant);
    //const time = +new Date();
    // const progressLoop = setInterval(() => {
    //   setProgress(((+new Date() - time) / TimeOut) * 100);
    // }, 100);
    toastTimeout = setTimeout(() => {
      setVisible(false);
      //clearInterval();
      setProgress(0);
    }, TimeOut);
  };
  const closeToast = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (toastReducerSlice.toastVisible) {
      openToast(toastReducerSlice.errorMessage);
    }
  }, [toastReducerSlice]);

  const value = { isVisible, openToast, closeToast, toastMsg, setToastMsg, variant, setVariant, progress };
  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};

export default ToastContextProvider;
