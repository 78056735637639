import { useContext, useEffect, useState } from "react";
import { Pagination, RenderName, TableHeader, TableRow, UserSideBar } from "../../components";
import { headersType } from "../../models";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { copyServiceToOffice, searchBlockFeeServices } from "../../redux/slices/ServiceSlice";
import { AuthPermission } from "../../services/auth.service";
import { Service } from "../../models/service.model";
import ViewService from "../../components/ViewService/ViewService";
import EditService from "../../components/forms/EditService/EditService";
import { isEmpty } from "lodash";
import { getSorting } from "../../utils";

export const headers: headersType[] = [
  {
    title: "Service",
    extraClasses: "w-full pl-0 break-all",
    sortKey: "name",
    isClickable: true,
  },
  {
    title: "Description",
    extraClasses: "w-full hidden lg:block break-all",
    sortKey: "description",
    isClickable: true,
  },
  {
    title: "Discount",
    extraClasses: "w-full hidden lg:block",
    sortKey: "blockFeeDiscountPct",
    isClickable: true,
  },
];

const Services = ({ officeId }: { officeId: string }) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const [sortMethods, setSortMethods] = useState<boolean[]>(headers.map(() => false));
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(0);
  const [showTooltip, setShowTooltip] = useState(-1);
  const [openEditService, setOpenEditService] = useState(false);
  const [openViewService, setOpenViewService] = useState(false);
  const [serviceToBeUpdated, setServiceToBeUpdated] = useState<Service | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [extraClasses] = useState<string[]>(headers.map(head => head.extraClasses));
  const { blockFeeServices, blockFeeServicesSortObj } = useSelector((state: RootState) => state.service);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);

  const load = async (page = 0, size = 20, sortBy = "name,desc", term?: string) => {
    if (officeId)
      dispatch(
        searchBlockFeeServices({
          page,
          size,
          sort: sortBy,
          term,
          officeId,
        })
      );
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    load(newPage, blockFeeServicesSortObj.rowsPerPage, blockFeeServicesSortObj.sortedBy, blockFeeServicesSortObj.searchTerm);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    load(blockFeeServicesSortObj.pageNumber, parseInt(event.target.value, 10), blockFeeServicesSortObj.sortedBy, blockFeeServicesSortObj.searchTerm);
  };

  const handleTooltipClick = (type: string, id: string | null) => {
    const editService = blockFeeServices.find(f => f.serviceId === id);
    if (type === "Edit Service") {
      if (id) {
        if (currentUser?.permissions.includes(AuthPermission.Service)) {
          if (editService) {
            setServiceToBeUpdated(editService);
            setOpenEditService(true);
          }
        } else {
          setOpenViewService(true);
        }
      }
    } else if (type === "View Service") {
      if (editService) {
        setServiceToBeUpdated(editService);
        setOpenViewService(true);
      }
    } else {
      toast?.openToast();
    }
  };

  const copyServiceToOfficeFunc = async (service: Service) => {
    if (service?.serviceId) {
      try {
        setIsLoading(true);
        const res: any = await dispatch(
          copyServiceToOffice({
            officeId,
            serviceId: service.serviceId,
          })
        );
        if (res?.payload && !isEmpty(res?.payload)) {
          setOpenEditService(true);
          toast?.openToast("Service copied successfully.");
          load(
            blockFeeServicesSortObj.pageNumber,
            blockFeeServicesSortObj.rowsPerPage,
            blockFeeServicesSortObj.sortedBy,
            blockFeeServicesSortObj.searchTerm
          );
          if (res?.payload?.serviceId) {
            setServiceToBeUpdated(res.payload);
          }
        }
        setIsLoading(false);
      } catch (_) {
        setIsLoading(false);
      }
    }
  };

  const handleSort = (index: number) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    setActiveSortIndex(index);
    load(blockFeeServicesSortObj.pageNumber, blockFeeServicesSortObj.rowsPerPage, sortMethodString, blockFeeServicesSortObj.searchTerm);
  };

  useEffect(() => {
    if (!blockFeeServices.length) {
      load(blockFeeServicesSortObj.pageNumber, blockFeeServicesSortObj.rowsPerPage, blockFeeServicesSortObj.sortedBy);
    }
  }, []);

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));

  const tooltipOptions = currentUser?.permissions.includes(AuthPermission.Service) ? ["Edit Service"] : ["View Service"];

  return (
    <div className="w-full relative">
      <div className="flex justify-between items-center border-b border-docsigna-blue-light">
        {headers.map((head, index) => (
          <TableHeader
            title={head.title}
            index={index}
            key={index}
            sortIcon={sortIcons[index]}
            handleSort={handleSort}
            extraClassesHeader={head.extraClasses}
            isClickable={head.isClickable}
          />
        ))}
        <div className="w-40 p-5 py-3">
          <span className="text-base text-docsigna-purple-dark inline-block w-5"></span>
        </div>
      </div>
      {blockFeeServices.map((rowData, index) => {
        const sortedData = {
          service: <RenderName title={rowData.name} subTitle={""} />,
          description: rowData.description ?? "--",
          discount: rowData.blockFeeDiscountPct ? `${rowData.blockFeeDiscountPct}%` : "-",
        };
        return (
          <TableRow
            data={Object.values(sortedData)}
            key={index}
            id={rowData.serviceId}
            tooltipVisible={showTooltip === index}
            extraClassesRow={extraClasses}
            handleOpenTooltip={() => setShowTooltip(index)}
            handleCloseTooltip={() => setShowTooltip(-1)}
            tooltipOptions={tooltipOptions}
            handleTooltipClick={type => handleTooltipClick(type, rowData.serviceId || null)}
            onRowClick={() => null}
          />
        );
      })}
      <Pagination
        colSpan={10}
        count={blockFeeServicesSortObj.totalElements ?? 0}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={blockFeeServicesSortObj.pageNumber}
        rowsPerPage={blockFeeServicesSortObj.rowsPerPage}
      />
      {serviceToBeUpdated ? (
        <UserSideBar
          visible={openEditService && serviceToBeUpdated !== null}
          LeftbuttonText="Cancel"
          RightbuttonText="Add"
          onCancelClick={() => setOpenEditService(false)}
          onInviteClick={() => setOpenEditService(false)}
          onClickOutside={() => (!isLoading ? setOpenEditService(false) : null)}
          title="Edit Service">
          <EditService
            serviceId={serviceToBeUpdated?.serviceId || ""}
            serviceToBeUpdated={serviceToBeUpdated}
            isAdmin={false}
            onCancelClick={(val?: boolean) => {
              setOpenEditService(false);
              setIsLoading(false);
              if (val) {
                load(
                  blockFeeServicesSortObj.pageNumber,
                  blockFeeServicesSortObj.rowsPerPage,
                  blockFeeServicesSortObj.sortedBy,
                  blockFeeServicesSortObj.searchTerm
                );
              }
              setServiceToBeUpdated(null);
            }}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </UserSideBar>
      ) : null}
      <UserSideBar visible={openViewService} onClickOutside={() => setOpenViewService(false)} title="View Service">
        <ViewService
          onCancelClick={() => setOpenViewService(false)}
          service={serviceToBeUpdated}
          officeId={officeId}
          isLoading={false}
          onCopyClick={service => copyServiceToOfficeFunc(service)}
        />
      </UserSideBar>
    </div>
  );
};

export default Services;
