import { useState } from "react";
import { FinanceBilledTo } from "../../models/finance.model";
import { formatCurrency } from "../../utils";
import { colors } from "../../utils/constants";
import DountGraph from "../DonutGraph/DonutGraph";
import Tabs from "../Tabs/Tabs";

export const serviceBilledToObj: any = {
  patient: {
    name: "Individual Plan",
    color: colors.GreenYellow,
  },
  "third-party": {
    name: "Couple Plan",
    color: colors.Orange,
  },
  wsib: {
    name: "Family Plan",
    color: colors.CyanBlue,
  },
  "service-canada": {
    name: "Individual Senior",
    color: colors.Lilac,
  },
  ohip: {
    name: "Couple Senior",
    color: colors.PictonBlue,
  },
};

const OfficeFinanceTabGraphSection = ({ billedTo }: { billedTo: any }) => {
  const [activeTab, setActiveTab] = useState(0);
  const totalAmount = billedTo?.reduce((sum: number, entry: any) => sum + entry.amount, 0);
  const totalCount = billedTo?.reduce((sum: number, entry: any) => sum + entry.count, 0);
  const Tab1 = () => {
    return (
      <div className="mt-10">
        {billedTo?.length ? (
          <DountGraph
            location="AnnualTab"
            height={350}
            billedToList={billedTo}
            graphLabel={"Revenue Generated-This Month"}
            totalCount={formatCurrency((totalAmount / 100)?.toString(), "blur")}
          />
        ) : null}
      </div>
    );
  };
  const Tab2 = () => {
    return (
      <div className="mt-10 relative">
        {billedTo?.length ? (
          <DountGraph
            location="AnnualTab"
            height={350}
            billedToList={billedTo}
            graphLabel={"Total Number Of Subscribers"}
            graphVal={false}
            totalCount={totalCount}
          />
        ) : null}
      </div>
    );
  };
  return (
    <div className="finance-graph mt-10">
      <Tabs
        components={[]}
        options={[`REVENUE BY PLAN TYPE`, "NUMBER OF SUBSCRIBERS"]}
        activeTab={activeTab}
        onClickTab={setActiveTab}
        fromOffice={true}
      />
      <div className="w-full block lg:flex">
        {billedTo?.length ? (
          <div className="w-full lg:w-1/2 flex items-center justify-center">
            <div className="flex flex-col w-full mt-5 lg:mt-0 items-center">
              {billedTo.map((item: FinanceBilledTo) => {
                return (
                  <div key={item?.billedTo} className="flex w-full lg:w-2/3 text-docsigna-blue-ldark border-b border-docsigna-blue-dark py-3">
                    <div className="flex w-2/3">
                      <div
                        className="w-5 h-5 rounded-full mr-3"
                        style={{ backgroundColor: serviceBilledToObj[item?.billedTo]?.color ?? "transparent" }}
                      />
                      <p className="text-sm font-semibold">{serviceBilledToObj[item?.billedTo]?.name ?? ""}</p>
                    </div>
                    <div className="text-sm font-semibold text-right mr-5 w-1/3">
                      {activeTab === 1 ? item.count : formatCurrency((item?.amount / 100)?.toString(), "blur")}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        <div className="w-full lg:w-1/2">{activeTab === 0 ? <Tab1 /> : <Tab2 />}</div>
      </div>
    </div>
  );
};

export default OfficeFinanceTabGraphSection;
