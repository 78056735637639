import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, DropArea, TextArea, TextInput } from "../../components";
import { FileTypes, FormType } from "../../models";
import { addRequest, clearSessionStorage, sendPhoneVerificationCode, updateRequestFormData } from "../../redux/slices/OnBoardingSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { formatPhoneNumber, onFileError, validateEmail, validatePhoneOrFaxNumber } from "../../utils";
import { colors } from "../../utils/constants";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { Request, RequestType } from "../../models/request.model";
import { clearUploadedFiles } from "../../redux/slices/FileSlice";

const SubmitRequest = () => {
  const auth = useContext(AuthContext);
  const { pending } = useSelector((state: RootState) => state.onBoarding);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { selectedOffice, requestFormData } = useSelector((state: RootState) => state.onBoarding);
  const { loading } = useSelector((state: RootState) => state.file);
  const formData = useSelector((state: RootState) => state.onBoarding.requestFormData);
  const name = formData[formData.findIndex(v => v.id === "recipientName")].value;
  const details = formData[formData.findIndex(v => v.id === "details")].value;
  const emailOrFax = formData[formData.findIndex(v => v.id === "recipientContact")].value;
  const [additionalCopyCheck, setAdditionalCopyCheck] = useState(formData[formData.findIndex(v => v.id === "recipientName")].value.length > 0);
  const [dropAreaError, setDropAreaError] = useState<string>("");
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [sendingPhoneOTP, setSendingPhoneOTP] = useState(false);
  const [sendingPhoneOtpError, setSendingPhoneOtpError] = useState(false);
  const [createAccountError, setCreateAccountError] = useState(false);
  const currentOffice = useSelector((state: RootState) => state.onBoarding.selectedOffice?.officeId);
  const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
  const formType = localStorage.getItem("formType");
  const firstName = formData.filter(v => v.id === "firstName")[0].value;
  const lastName = formData.filter(v => v.id === "lastName")[0].value;
  const phone = formData.filter(v => v.id === "phone")[0].value;
  const email = formData.filter(v => v.id === "email")[0].value;
  const dateOfBirth = formData.filter(v => v.id === "birthday")[0].value;
  const ohipNumber = formData.filter(v => v.id === "ohip")[0].value;
  const fileIds = requestFormData.filter(v => v.id === "fileIds")[0].value;
  const files: string[] = fileIds?.length ? fileIds.split(",") : [];
  const password = formData.filter(v => v.id === "password")[0].value;

  const [errors, setErrors] = useState({
    name: true,
    emailOrFax: true,
    details: true,
  });

  const onFileChange = (fileIds: string[]) => {
    const indexInData = requestFormData.findIndex(v => v.id === "fileIds");
    dispatch(
      updateRequestFormData([
        ...requestFormData.slice(0, indexInData),
        { ...requestFormData[indexInData], value: fileIds.toString() },
        ...requestFormData.slice(indexInData + 1),
      ])
    );
  };

  const validateForm = () => {
    if (additionalCopyCheck) {
      return !errors.details && !errors.emailOrFax && !errors.name;
    } else {
      return !errors.details;
    }
  };

  const onLogInClick = async (email: string, password: string, patientId: string) => {
    const Login = await auth?.signin({
      email: email.trim(),
      password: password.trim(),
    });
    if (Login) {
      setRequestCompleted(false);
      navigate(`/patients/${patientId}/dashboard`);
    }
  };

  const handleAdditionalCopyCheck = (value: boolean) => {
    if (!value) {
      setName("");
      setEmailOrFax("");
      setErrors({
        ...errors,
        name: name?.trim().length === 0,
        emailOrFax: !validateEmail(emailOrFax) || !new RegExp("^[0-9]{3}-[0-9]{3}-[0-9]{4}$").test(emailOrFax),
      });
    }
    setAdditionalCopyCheck(value);
  };

  const handleDetailChange = (value: string) => {
    const indexInData = requestFormData.findIndex(v => v.id === "details");
    dispatch(
      updateRequestFormData([
        ...requestFormData.slice(0, indexInData),
        { ...requestFormData[indexInData], value: value.endsWith("  ") ? value.trim() : value },
        ...requestFormData.slice(indexInData + 1),
      ])
    );
  };

  const handleNameChange = (value: string) => {
    setName(value);
    const invalid = value?.trim().length > 0 ? false : true;
    setErrors({ ...errors, name: invalid });
  };

  const onSubmitClick = () => {
    if (validateForm()) {
      if (formType === null || formType === FormType.ContinueGuest) {
        setSendingPhoneOTP(true);
        dispatch(sendPhoneVerificationCode({ phone: requestFormData[requestFormData.findIndex(v => v.id === "phone")].value })).then(action => {
          setSendingPhoneOTP(false);
          setSendingPhoneOtpError(false);
          if (action.payload) {
            navigate("/onboarding/verify-phone");
          } else {
            setSendingPhoneOtpError(true);
          }
        });
      } else {
        setRequestCompleted(true);
        if (currentOffice)
          dispatch(
            addRequest({
              request: {
                requestType: RequestType.Standard,
                firstName,
                lastName,
                dateOfBirth,
                ohipNumber,
                phone,
                email,
                details: details,
                fileIds: files,
                recipientName: name === "" ? undefined : name,
                recipientContact: emailOrFax === "" ? undefined : emailOrFax,
                officeId: currentOffice,
                password,
                acceptedTerms: true,
              },
            })
          ).then(action => {
            if (action.payload !== null) {
              dispatch(clearSessionStorage());
              localStorage.removeItem("formType");
              const stringifyId = JSON.stringify(action.payload);
              const parseId: Request = JSON.parse(stringifyId);

              onLogInClick(email, password, parseId?.patient?.userId ?? "");
            } else {
              setRequestCompleted(false);
              setCreateAccountError(true);
            }
          });
      }
    }
  };
  const handleEmailOrFaxInput = (event: React.ChangeEvent<HTMLInputElement>, cursorPosition: number) => {
    let value = event.currentTarget.value;
    let inValid = false;
    if (validateEmail((value as string).trim())) {
      inValid = false;
    } else if (validatePhoneOrFaxNumber(formatPhoneNumber(value as string))) {
      value = formatPhoneNumber(value as string);
      inValid = false;
    } else {
      inValid = true;
    }
    setErrors({ ...errors, emailOrFax: inValid });
    setEmailOrFax(value);
  };

  const setName = (value: string) => {
    const indexInData = requestFormData.findIndex(v => v.id === "recipientName");
    dispatch(
      updateRequestFormData([
        ...requestFormData.slice(0, indexInData),
        { ...requestFormData[indexInData], value },
        ...requestFormData.slice(indexInData + 1),
      ])
    );
  };

  const setEmailOrFax = (value: string) => {
    const indexInData = requestFormData.findIndex(v => v.id === "recipientContact");
    dispatch(
      updateRequestFormData([
        ...requestFormData.slice(0, indexInData),
        { ...requestFormData[indexInData], value },
        ...requestFormData.slice(indexInData + 1),
      ])
    );
  };

  useEffect(() => {
    setErrors({
      ...errors,
      emailOrFax: !validateEmail(emailOrFax) || !new RegExp("^[0-9]{3}-[0-9]{3}-[0-9]{4}$").test(emailOrFax),
    });
    return () => {
      dispatch(clearUploadedFiles());
    };
  }, []);

  useEffect(() => {
    let emailOrFaxError = false;
    if (isNaN(parseInt(emailOrFax.charAt(0)))) {
      emailOrFaxError = !validateEmail(emailOrFax);
    } else {
      emailOrFaxError = !new RegExp("^[0-9]{3}-[0-9]{3}-[0-9]{4}$").test(emailOrFax);
    }
    setErrors({
      ...errors,
      details: details.trim().length === 0,
      name: name.trim().length === 0,
      emailOrFax: emailOrFaxError,
    });
  }, [name, details]);

  return (
    <div className="flex justify-between w-full">
      <div className="w-full max-w-2xl mx-auto mt-12 pb-12 px-12 md:px-0">
        <h3 className="text-3xl font-light mb-2">Welcome, {formData[0].value}. Complete your request.</h3>
        <p className="text-base mb-5 font-medium">
          To complete your request, submit the details you would like to provide to {selectedOffice?.contactName}.
        </p>
        {sendingPhoneOtpError && <p style={{ color: colors.Red }}>An error has occurred, please try again</p>}
        <label className="text-sm font-medium block w-full mb-1">
          {"Notes"}
          <span className="text-red-500">*</span>
        </label>
        <TextArea
          onChange={e => handleDetailChange(e.currentTarget.value)}
          value={details}
          label=""
          placeholder="Enter notes here"
          errorMsg="Please provide information about this request"
          isError={errors.details && errorInitiated}
          onBlur={e => handleDetailChange(e.currentTarget.value.trim())}
        />
        <div className="py-2 pb-0">
          <p className="text-base mb-2">
            <span className="block text-base font-medium" style={{ fontWeight: 600 }}>
              Files
            </span>{" "}
            You can attach up to 10 JPEG, PDF or PNG files
          </p>
        </div>
        <div className="py-2">
          <p style={{ color: colors.Red }}>{dropAreaError}</p>
          <DropArea
            allowedFileTypes={[FileTypes.JPEG, FileTypes.PNG, FileTypes.PDF]}
            onFilesChange={onFileChange}
            onError={err => setDropAreaError(onFileError(err))}
            maxFileSize={50 * 1024 * 1024}
            maxNumOfFiles={10}
            filePreviewVisible={true}
            dropAreaText={"files"}
          />
          <p className="text-base mt-2">Tip: Take a photo of your document and upload it from your device&apos;s photo library.</p>
        </div>
        <div className="py-2">
          <p className="text-base mb-2">
            <span className="block text-base text-gray-700 font-bold">Recipient</span> A copy of the requested form will be sent if below information
            is completed.
          </p>

          <Checkbox
            checked={additionalCopyCheck}
            onChange={e => handleAdditionalCopyCheck(e.currentTarget.checked)}
            label="Additional copy of this form to be sent to a 3rd party"
          />
          {additionalCopyCheck ? (
            <>
              <div style={{ display: "flex" }} className="max-w-2xl">
                <div className="py-2" style={{ paddingRight: "0.5rem", width: "50%" }}>
                  <label className="text-sm text-gray-700 block w-full">
                    {"Recipient Name"}
                    <span className="text-red-500">*</span>
                  </label>
                  <TextInput
                    onChangeFunc={e => handleNameChange(e.currentTarget.value)}
                    value={name}
                    label=""
                    placeholder="Enter name"
                    errorMsg="Please provide recipient name"
                    isError={errors.name && errorInitiated}
                  />
                </div>
                <div className="py-2" style={{ width: "50%" }}>
                  <label className="text-sm text-gray-700 block w-full">
                    {" Email or Fax Number"}
                    <span className="text-red-500">*</span>
                  </label>
                  <TextInput
                    onChangeFunc={e => handleEmailOrFaxInput(e, e.target.selectionEnd)}
                    value={emailOrFax}
                    label=""
                    placeholder="Enter email address or fax number"
                    errorMsg="Please provide valid email address or fax number with format 111-222-3333"
                    isError={errors.emailOrFax && errorInitiated}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
        {createAccountError ? <p style={{ color: colors.Red }}>An error has occurred, please try again.</p> : null}

        <div className="py-2 w-1/2" onClick={() => setErrorInitiated(true)}>
          <Button
            text="Continue"
            disabled={(!validateForm() && errorInitiated) || loading}
            AdditionalClassNames="p-2.5"
            onClickFunc={onSubmitClick}
            varient={sendingPhoneOTP || pending || requestCompleted ? "loading" : "Primary"}
          />
        </div>
      </div>
    </div>
  );
};

export default SubmitRequest;
