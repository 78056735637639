import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { billedToOptions, formatCurrency } from "../../utils";
const FinancesReport = ({ isPdf = false, selectedMonth = "" }: { isPdf?: boolean; selectedMonth?: string }) => {
  const { financeReportTabData } = useSelector((state: RootState) => state.finance);
  return (
    <div className={`${isPdf ? "w-full h-a4-finance relative" : ""}`}>
      <div className="w-full">
        <h3 className="heading font-bold uppercase">Finances</h3>
      </div>
      <div className={`p-5 bg-docsigna-pink-light rounded border border-docnote-pink-dark`}>
        <div className="grid grid-cols-1">
          <div className="relative">
            <div className="grid grid-cols-3 gap-4 border-b border-docnote-pink-dark pb-1">
              <p className="text-base font-medium col-span-2">Payor</p>
              <p className="text-base font-medium">Revenue</p>
            </div>
            {financeReportTabData?.billedTo?.length
              ? financeReportTabData.billedTo.map((item, ind) => {
                  return (
                    <div key={item.billedTo} className="grid grid-cols-3 gap-4 pl-2 border-b border-docnote-pink-dark py-1">
                      <p className="col-span-2 text-sm">{billedToOptions[item.billedTo]}</p>
                      <p className="text-sm">{item?.amount !== undefined ? formatCurrency((item?.amount / 100)?.toString(), "blur") : "$0.00"}</p>
                    </div>
                  );
                })
              : null}

            <div className="grid grid-cols-3 gap-4 border-b border-docnote-pink-dark py-1">
              <p className="col-span-2 text-base font-medium ">Total Revenue</p>
              <p className="text-base font-medium">
                {financeReportTabData?.totalRevenue !== undefined
                  ? formatCurrency((financeReportTabData?.totalRevenue / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div className="grid grid-cols-1 gap-4 border-b border-docnote-pink-dark py-1">
              <p className="text-base font-medium">Expenses</p>
            </div>
            <div className="grid grid-cols-3 gap-4 border-b border-docnote-pink-dark pl-2 py-1">
              <p className="col-span-2 text-sm">Discounts Provided</p>
              <p className="text-sm">
                {financeReportTabData?.discountsProvided !== undefined
                  ? formatCurrency((financeReportTabData?.discountsProvided / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div className="grid grid-cols-3 gap-4 border-b border-docnote-pink-dark pl-2 py-1">
              <p className="col-span-2 text-sm">Docnote Fee 15%</p>
              <p className="text-sm">
                {financeReportTabData?.feesOwedToDs !== undefined
                  ? formatCurrency((financeReportTabData?.feesOwedToDs / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div className="grid grid-cols-3 gap-4 border-b border-docnote-pink-dark pl-2 py-1">
              <p className="col-span-2 text-sm">
                Stripe Fee
                <br />
                (2.9% + $0.30 per transaction)
              </p>
              <p className="text-sm">
                {financeReportTabData?.stripeFee !== undefined
                  ? formatCurrency((financeReportTabData?.stripeFee / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div className="grid grid-cols-3 gap-4 py-1">
              <p className="col-span-2 text-base font-medium">Net Income</p>
              <p className="text-base font-medium">
                {financeReportTabData?.netIncome !== undefined
                  ? formatCurrency((financeReportTabData?.netIncome / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-start relative p-5 bg-docsigna-pink-light rounded border border-docnote-pink-dark mt-5">
        <div className="w-full">
          <div className="grid grid-cols-1 gap-4 border-b border-docnote-pink-dark py-1">
            <p className="text-base font-medium">Invoices Outstanding (Office to Collect)</p>
          </div>
          <div className="grid grid-cols-3 gap-4 border-b border-docnote-pink-dark pl-2 py-1">
            <p className="col-span-2 text-sm">30 days past-due</p>
            <p className="text-sm">
              {financeReportTabData?.outstanding30DaysPastDue !== undefined
                ? formatCurrency((financeReportTabData?.outstanding30DaysPastDue / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div className="grid grid-cols-3 gap-4 border-b border-docnote-pink-dark pl-2 py-1">
            <p className="col-span-2 text-sm">60 days past-due</p>
            <p className="text-sm">
              {financeReportTabData?.outstanding60DaysPastDue !== undefined
                ? formatCurrency((financeReportTabData?.outstanding60DaysPastDue / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div className="grid grid-cols-3 gap-4 border-b border-docnote-pink-dark pl-2 py-1">
            <p className="col-span-2 text-sm">90+ days past-due</p>
            <p className="text-sm">
              {financeReportTabData?.outstanding90DaysPastDue !== undefined
                ? formatCurrency((financeReportTabData?.outstanding90DaysPastDue / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
          <div className="grid grid-cols-3 gap-4 py-1">
            <p className="col-span-2 text-base font-medium">Total Invoices Outstanding</p>
            <p className="text-sm">
              {financeReportTabData?.outstandingTotalPastDue !== undefined
                ? formatCurrency((financeReportTabData?.outstandingTotalPastDue / 100)?.toString(), "blur")
                : "$0.00"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancesReport;
