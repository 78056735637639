import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { sendOfficeRequestTextMessage } from "../../../redux/slices/UserSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { getOfficeRequestPatientMessages } from "../../../redux/slices/RequestSlice";

const SendMessage = ({
  onClickCancel,
  setIsLoading,
  isLoading,
}: {
  onClickCancel: () => void;
  setIsLoading: (val: boolean) => void;
  isLoading?: boolean;
}) => {
  const { id, requestId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state?.user);
  const toast = useContext(ToastContext);

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(true);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Message is required");
  const [apiError, setApiError] = useState("");

  const handleChange = (value: string) => {
    if ((value as string).length > 150) {
      setMessageError(true);
      setErrorMessage("Maximum 150 characters");
    } else if ((value as string).length < 1) {
      setMessage(value as string);
      setMessageError(true);
      setErrorMessage("Message is required");
    } else {
      setMessageError(false);
      setMessage(value as string);
      setErrorMessage("Message is required");
    }
  };

  const handleSubmit = () => {
    if (id && requestId && message && !messageError) {
      setIsLoading(true);
      dispatch(sendOfficeRequestTextMessage({ officeId: id, requestId, message: message.trim() }))
        .then(act => {
          setIsLoading(false);
          if (act.payload === true) {
            dispatch(getOfficeRequestPatientMessages({ officeId: id, requestId }));
            onClickCancel();
            toast?.openToast("Text message sent successfully!");
          } else {
            setApiError("Something went wrong please try again!");
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <>
      <div className="px-8 py-2">
        <label className="text-sm text-docsigna-blue-dark font-medium block w-full mt-3 mb-2">
          {"Type message below"}
          <span className="text-red-500">*</span>
        </label>
        <TextInput
          value={message}
          disabled={loading}
          onChangeFunc={e => handleChange(e.currentTarget.value)}
          placeholder={"Text message"}
          errorMsg={errorMessage}
          isError={errorInitiated && messageError}
          isRequired={false}
          isTextArea={true}
          showCounter={true}
          counterMessage={"150"}
          extraInputClass="h-48"
          onBlur={e => handleChange(e.currentTarget.value.trim())}
          isDarkBg={true}
        />
        {apiError ? <span className="text-red-500">{apiError}</span> : null}
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onClickCancel()}
            disabled={isLoading}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button
              onClickFunc={() => {
                setErrorInitiated(true);
                handleSubmit();
              }}
              varient={loading ? "loading" : "Primary"}
              disabled={(errorInitiated && messageError) || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Send"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMessage;
