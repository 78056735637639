import { RequestFee } from "./request-fee.model";
import { Service, ServiceBilledTo } from "./service.model";
import { UserInfo } from "./user-info.model";

export enum RequestType {
  Standard = "standard",
  StandardOffice = "standard-office",
  QuickFormOffice = "quick-form-office",
  ThirdPartyOffice = "third-party-office",
  PrescriptionRefill = "prescription-office",
  DocnoteDebit = "docnote-debit",
  WSIBOffice = "wsib-office",
  ServiceCanadaOffice = "service-canada-office",
}

export enum RequestState {
  PendingVerification = "pending-verification",
  PendingPayment = "pending-payment",
  InProgress = "in-progress",
  WaitingSignature = "waiting-signature",
  FinalReview = "final-review",
  Complete = "complete",
  Cancelled = "cancelled",
  RefundRequested = "refund-requested",
  Refunded = "refunded"
}

export enum RequestDiscountType {
  Percentage = "percentage",
  Amount = "amount",
}

export enum RequestPaymentMethod {
  Stripe = "stripe",
  External = "external",
  ManualDiscount = "manual-discount",
  BlockFee = "block-fee",
}

export enum ArchiveState {
  Any = "any",
  Archived = "archived",
  NotArchived = "not-archived",
}

export enum RefundRequestState {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected'
}

export type RefundRequest = {
  refundRequestId: string;
  requestId: string;
  refundRequestState: RefundRequestState;
  requestReason: string;
  requestReasonOther: string;
  rejectedReason: string;
}

export type Request = {
  requestId: string;
  requestType: RequestType;
  requestNumber: string;
  officeId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  dateOfBirth?: string | null;
  ohipNumber?: string | null;
  details: string | null;
  recipientName?: string;
  recipientContact?: string;
  recipientConsent?: boolean;
  patientVerified: boolean;
  state: RequestState;
  archived: boolean;
  assignedTo?: UserInfo;
  primaryCareProvider?: UserInfo;
  service?: Service;
  servicePriceOverride?: number;
  serviceNotes?: string;
  serviceBilledToOverride?: ServiceBilledTo;
  urgent: boolean;
  requestFileIds?: string[];
  requestFees?: RequestFee[];
  createdDate: string;
  numMessages?: number;
  numUnreadMessages?: number;
  discountType?: RequestDiscountType;
  discountAmount?: number;
  submitted?: boolean;
  submittedDate?: string;
  paymentComplete?: boolean;
  paymentMethod?: RequestPaymentMethod;
  paymentDate?: string;
  invoiceCreated?: boolean;
  acceptedTerms?: boolean;
  refunded?: boolean;
  refundDate?: string;
  updatedDate?: string;
  fulfillOnPayment?: boolean;
  paymentDetails?: string;
  officeName?: string;
  patient?: UserInfo;
  refundRequest?: RefundRequest;
};

export interface RequestVerificationStateType {
  visibleForm:
    | "VerifyPatient"
    | "AssignService"
    | "AssignRequest"
    | "overview"
    | "Recipients"
    | "SendMessage"
    | "DeclineRequest"
    | "EditNotes"
    | "MarkAsPaid"
    | null;
  completed: RequestVerificationStateType["visibleForm"][];
}
