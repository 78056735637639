import React, { useEffect, useState, useContext } from "react";
import { Button, ConfirmationDialog, ProtectedComponent, RenderName, StatusCount, TextInput } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { getOffice, sortObj } from "../../redux/slices/OfficeSlice";
import { TableHeader, TableRow } from "../../components/TableComponents/TableComponents";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { clearRequestData, getOfficeRequestCounts, searchOfficeRequests } from "../../redux/slices/OfficeRequestSlice";
import { getOfficeUsersWithPermission } from "../../redux/slices/OfficeSlice";
import { RefundRequestState, Request, RequestState } from "../../models/request.model";
import { Pagination } from "../../components";
import { AuthPermission } from "../../services/auth.service";
import { User } from "../../models/user.model";
import moment from "moment";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { setActiveIndex } from "../../redux/slices/RequestSlice";
import { billedToOptions, getSorting, renderState } from "../../utils";
import { headers } from "./OfficeDetailData";
import { CircularProgress } from "@mui/material";
import NoRequestFound from "../../assets/images/no-result.png";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import { ArchiveState } from "../../models/request.model";
import { SESSION_STORAGE_ARCHIVE_STATE_TYPE, SESSION_STORAGE_TEMP_CLOSED } from "../../utils/constants";
import { useDebouncedCallback } from "use-debounce";
import { searchServiceData } from "../../redux/slices/ServiceSlice";
import { ServiceOwner } from "../../models/service.model";
import { OfficeState } from "../../models/office.model";
import { OfficeService } from "../../services/office.service";

const statusCountsInit = [
  { id: "urgent", title: "Urgent", count: 0, isDanger: true, type: "urgent" },
  { id: "pendingVerification", title: "Pending Verification", count: 0, isDanger: false, type: RequestState.PendingVerification },
  { id: "pendingPayment", title: "Pending Payment", count: 0, isDanger: false, type: RequestState.PendingPayment },
  { id: "inProgress", title: "In Progress", count: 0, isDanger: false, type: RequestState.InProgress },
  { id: "waitingForSignature", title: "Waiting for Signature", count: 0, isDanger: false, type: RequestState.WaitingSignature },
  { id: "finalReview", title: "Final Review", count: 0, isDanger: false, type: RequestState.FinalReview },
  { id: "completed", title: "Complete", count: 0, isDanger: false, type: RequestState.Complete },
];

const usersList = [
  {
    patientName: "Jane Doe",
    assignTo: "Jillian Ross",
    serviceName: "Disability Tax Credit",
    pcp: "Dr. William Mahoney",
    status: "Under Review",
    createdAt: "16/07/2022",
    commentsCount: 3,
    newCommentsCount: 1,
  },
];
const OfficeDetailsScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const [tooltipStates, setTooltipStates] = useState<boolean[]>(new Array(usersList ? usersList.length : 10).fill(false));
  const [sortMethods, setSortMethods] = useState<boolean[]>(headers.map(() => false));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(2);
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [statusCounts, setStatusCounts] = useState<typeof statusCountsInit>(statusCountsInit);
  const [tableFilter, setTableFilter] = useState<RequestState | "urgent" | null>(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedArchiveState, setSelectedArchiveState] = useState<ArchiveState>(ArchiveState.NotArchived);
  const { id } = useParams();
  const [term, setTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCloseAlert, setShowCloseAlert] = useState(false);
  const { officeRequestList, requestCounts, loading } = useSelector((state: RootState) => state.officeRequest);
  const { userWithPermission, currentOffice, loadingOffice } = useSelector((state: RootState) => state.office);
  const { serviceList } = useSelector((state: RootState) => state.service);
  const moreOptions = ["View Details"];

  const [sort, setSort] = useState({
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "requestNumber,desc",
  });

  const refreshStatusCount = (val: ArchiveState, assignedTo?: string) => {
    if (id && val && val !== ArchiveState.Archived) {
      dispatch(getOfficeRequestCounts({ officeId: id, assignedTo, archiveState: val }));
    }
  };

  const handleAllUserSelect = (e: string) => {
    setSelectedUser(e);
    refreshStatusCount(selectedArchiveState, e);
    updateTable(
      { ...sort, pageNumber: 0 },
      term,
      tableFilter === "urgent",
      tableFilter && tableFilter !== "urgent" ? tableFilter : undefined,
      e,
      selectedArchiveState
    );
  };

  const updateTable = (sortObj?: sortObj, searchText?: string, urgent?: boolean, filter?: RequestState, user?: string, archive?: string) => {
    const pageNumToBeCalled = sortObj?.pageNumber ?? sort.pageNumber;
    const sizeToBeCalled = sortObj?.rowsPerPage ?? sort.rowsPerPage;
    const sortBy = sortObj?.sortedBy ?? sort.sortedBy;
    const searchT = searchText ?? "";
    const archiveVal = archive ? (archive as ArchiveState) : ArchiveState.NotArchived;
    const tabFilter = archiveVal !== ArchiveState.Archived ? filter ?? null : undefined;
    if (!id) return;
    dispatch(
      searchOfficeRequests({
        officeId: id,
        searchTerm: searchT,
        assignedTo: user !== "" ? user : undefined,
        page: pageNumToBeCalled,
        size: sizeToBeCalled,
        sort: sortBy,
        state: tabFilter ?? undefined,
        urgent: archiveVal !== ArchiveState.Archived && urgent ? urgent : undefined,
        archiveState: archiveVal,
      })
    ).then(() => {
      setSort({ ...sort, pageNumber: pageNumToBeCalled, rowsPerPage: sizeToBeCalled, sortedBy: sortBy });
    });
  };

  const handleSort = (index: number, firstTime = false) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    let finalArchiveStateVal = selectedArchiveState;
    if (firstTime && id) {
      const archiveStateVal = sessionStorage.getItem(SESSION_STORAGE_ARCHIVE_STATE_TYPE);
      const val: ArchiveState = archiveStateVal ? (archiveStateVal as ArchiveState) : ArchiveState.NotArchived;
      setSelectedArchiveState(val);
      finalArchiveStateVal = val;
      refreshStatusCount(finalArchiveStateVal);
    }
    updateTable(
      { ...sort, pageNumber: 0, sortedBy: sortMethodString },
      term,
      tableFilter === "urgent",
      tableFilter && tableFilter !== "urgent" ? tableFilter : undefined,
      selectedUser,
      finalArchiveStateVal
    );
    setActiveSortIndex(index);
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateTable(
      { ...sort, pageNumber: newPage },
      term,
      tableFilter === "urgent",
      tableFilter && tableFilter !== "urgent" ? tableFilter : undefined,
      selectedUser,
      selectedArchiveState
    );
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateTable(
      { ...sort, pageNumber: 0, rowsPerPage: parseInt(event.target.value, 10) },
      term,
      tableFilter === "urgent",
      tableFilter && tableFilter !== "urgent" ? tableFilter : undefined,
      selectedUser,
      selectedArchiveState
    );
  };

  const handleTooltipClick = (type: string, id: string) => {
    switch (type) {
      case "View Details":
        navigate(id);
        break;
      default:
        toast?.openToast();
        break;
    }
    setTooltipStates([...tooltipStates].fill(false));
  };
  const handleToolTipOpen = (index: number) => {
    setTooltipStates([...tooltipStates.slice(0, index).fill(false), true, ...tooltipStates.slice(index + 1).fill(false)]);
  };

  const debounced = useDebouncedCallback(value => {
    if (value && value.trim().length >= 2) {
      updateTable(
        { ...sort, pageNumber: 0 },
        value.trim(),
        tableFilter === "urgent",
        tableFilter && tableFilter !== "urgent" ? tableFilter : undefined,
        selectedUser,
        selectedArchiveState
      );
    }
  }, 1000);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    const oldSearchTerm = term;
    setTerm(input);
    debounced(input);
    if (oldSearchTerm !== "" && input.length <= 1) {
      updateTable(
        sort,
        "",
        tableFilter === "urgent",
        tableFilter && tableFilter !== "urgent" ? tableFilter : undefined,
        selectedUser,
        selectedArchiveState
      );
    }
  };

  const handleToolTipClose = () => {
    setTooltipStates([...tooltipStates].fill(false));
  };

  const onRowClick = (requestId: string) => {
    dispatch(clearRequestData());
    navigate(requestId);
  };

  const setDefaultSorting = () => {
    let sortMethodString = sortKeys[2];
    sortMethodString += ",desc";
    setActiveSortIndex(2);
    setSortMethods([...sortMethods.slice(0, 2), true, ...sortMethods.slice(2 + 1)]);
    return sortMethodString;
  };

  const onStatusCountClick = (statusId: string) => {
    if (id) {
      if (statusId === "urgent") {
        setTableFilter(tableFilter === statusId ? null : "urgent");
        const sorting = setDefaultSorting();
        updateTable({ ...sort, sortedBy: sorting }, term, tableFilter === statusId ? undefined : true, undefined, selectedUser, selectedArchiveState);
      } else {
        let state;
        switch (statusId) {
          case "pendingVerification":
            state = RequestState.PendingVerification;
            break;
          case "pendingPayment":
            state = RequestState.PendingPayment;
            break;
          case "inProgress":
            state = RequestState.InProgress;
            break;
          case "waitingForSignature":
            state = RequestState.WaitingSignature;
            break;
          case "finalReview":
            state = RequestState.FinalReview;
            break;
          case "completed":
            state = RequestState.Complete;
            break;
        }
        if (tableFilter === state) {
          const sorting = setDefaultSorting();
          updateTable({ ...sort, pageNumber: 0, sortedBy: sorting }, term, undefined, undefined, selectedUser, selectedArchiveState);
        } else {
          updateTable({ ...sort, pageNumber: 0, sortedBy: "updatedDate,desc" }, term, undefined, state, selectedUser, selectedArchiveState);
          setActiveSortIndex(6);
          setSortMethods([...sortMethods.slice(0, 6), true, ...sortMethods.slice(6 + 1)]);
        }
        if (state) setTableFilter(tableFilter === state ? null : state);
      }
    }
  };

  const handleArchiveStateChange = (val: ArchiveState) => {
    setSelectedArchiveState(val);
    if (val === ArchiveState.Archived) {
      setTableFilter(null);
    } else {
      refreshStatusCount(val, selectedUser);
    }
    sessionStorage.setItem(SESSION_STORAGE_ARCHIVE_STATE_TYPE, val);
    updateTable(
      { ...sort, pageNumber: 0 },
      term,
      tableFilter === "urgent",
      tableFilter && tableFilter !== "urgent" ? tableFilter : undefined,
      selectedUser,
      val
    );
  };

  const renderCloseMessage = () => {
    return (
      <div className="flex flex-col gap-4">
        <h2 className="font-bold">The office is currently closed. You will need to open in order to access requests.</h2>
      </div>
    );
  };

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));

  useEffect(() => {
    handleSort(2, true);
    dispatch(setActiveIndex(0));
  }, []);

  useEffect(() => {
    if (requestCounts !== null) {
      const newCounts = [...statusCounts];
      newCounts.forEach(count => {
        count.count = (requestCounts as { [k: string]: number })[count.id];
      });
      setStatusCounts(newCounts);
    }
  }, [requestCounts]);

  useEffect(() => {
    if (id) {
      const officeId = id;
      if (id !== currentOffice?.officeId && !loadingOffice) dispatch(getOffice({ officeId }));
      dispatch(
        getOfficeUsersWithPermission({
          officeId,
          permission: AuthPermission.Request,
          page: 0,
          sort: "",
          size: 100,
        })
      );
      dispatch(
        searchServiceData({
          officeId: officeId,
          page: 0,
          size: 1,
          sort: "name,asc",
          enabledOnly: true,
          serviceOwner: ServiceOwner.Office,
        })
      );
    }
  }, [id, dispatch]);

  useEffect(() => {
    const isAlertShown = sessionStorage.getItem(SESSION_STORAGE_TEMP_CLOSED);
    if (currentOffice?.tempClosed && currentOffice.officeId && isAlertShown !== currentOffice.officeId) {
      setShowCloseAlert(true);
      sessionStorage.setItem(SESSION_STORAGE_TEMP_CLOSED, currentOffice.officeId);
    }
  }, [currentOffice]);

  return (
    <main>
      <div className="container mx-auto p-8 pb-4">
        <OfficeSectionsHeader showMeta={true} />
        <div className="block xl:flex justify-between items-center mb-6">
          <div className="w-full xl:w-1/5">
            <h2 className="heading uppercase">Requests</h2>
          </div>
          <div className="block lg:flex justify-end w-full xl:w-4/5">
            <div className="block md:flex items-center justify-end space-y-2 md:space-y-0">
              <select
                className="general-select w-full md:w-3/12"
                value={selectedUser}
                onChange={e => {
                  handleAllUserSelect(e.currentTarget.value);
                }}>
                <option value="">All Users</option>
                {userWithPermission
                  ? userWithPermission.data?.content
                      .filter(user => user.userState === "active")
                      ?.map((user: User) => {
                        return <option value={user.userId} key={user.userId}>{`${user.firstName} ${user.lastName}`}</option>;
                      })
                  : null}
              </select>
              <select
                className="general-select md:mx-5 w-full md:w-4/12"
                value={selectedArchiveState}
                onChange={e => {
                  const val: ArchiveState = e.currentTarget.value as ArchiveState;
                  handleArchiveStateChange(val);
                }}>
                <option value={`${ArchiveState.Any}`}>All Requests</option>
                <option value={`${ArchiveState.NotArchived}`}>Active Requests</option>
                <option value={`${ArchiveState.Archived}`}>Archived Requests</option>
              </select>
              <div className="w-full md:w-5/12">
                <TextInput value={term} onChangeFunc={handleSearch} placeholder={"Search Requests"} extraInputClass="w-full" isSearchInput={true} />
              </div>
            </div>
            <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
              <div className="flex lg:justify-end items-center ml-5 mt-5 lg:mt-0">
                <span className="mr-5 font-medium">or</span>
                <Button
                  text="Create Request"
                  onClickFunc={() => {
                    currentOffice?.officeState !== OfficeState.Published
                      ? toast?.openToast("Office must be published before creating a request")
                      : !serviceList?.data?.content?.length
                      ? toast?.openToast("Add services to your office in order to create a request")
                      : !currentOffice?.tempClosed
                      ? navigate(`create-request`)
                      : setShowCloseAlert(true);
                  }}
                  AdditionalClassNames={`whitespace-nowrap ${
                    !serviceList?.data?.content?.length || currentOffice?.officeState !== OfficeState.Published ? "opacity-30" : ""
                  }`}
                />
              </div>
            </ProtectedComponent>
          </div>
        </div>
        <div className="flex mb-6 w-full overflow-auto">
          {statusCounts.map(count => {
            return (
              <StatusCount
                onClick={() => {
                  selectedArchiveState !== ArchiveState.Archived ? onStatusCountClick(count.id) : null;
                }}
                title={count.title}
                count={selectedArchiveState !== ArchiveState.Archived ? count.count : "-"}
                key={count.id}
                active={tableFilter === count.type}
                countColor={count.isDanger ? "danger" : "primary"}
              />
            );
          })}
        </div>
        {!loading ? (
          <div className="block">
            {officeRequestList?.data?.content && officeRequestList?.data?.content?.length > 0 ? (
              <>
                <div className="flex justify-between items-center border-b border-docsigna-blue-light">
                  {headers.map((head, index) => (
                    <TableHeader
                      title={head.title}
                      index={index}
                      key={index}
                      sortIcon={sortIcons[index]}
                      handleSort={handleSort}
                      extraClassesHeader={head.extraClasses}
                      isClickable={head.isClickable}
                    />
                  ))}
                  <div className="w-auto p-5 py-3">
                    <span className="text-base text-docsigna-purple-dark inline-block w-5"></span>
                  </div>
                </div>
                {officeRequestList.data?.content.map((rowData: Request, index: number) => {
                  const sortedData = {
                    urgent: rowData.urgent ? <span className="dot"></span> : <span className="w-2.5 h-2.5 block"></span>,
                    name: (
                      <RenderName
                        title={rowData.firstName + " " + rowData.lastName}
                        subTitle={`${rowData.assignedTo ? `Assigned: ${rowData.assignedTo.firstName} ${rowData.assignedTo.lastName}` : "Unassigned"}`}
                        extraClasses="ml-0"
                      />
                    ),
                    requestNumber: rowData.requestNumber,
                    service: (
                      <RenderName
                        extraClasses="text-sm"
                        title={rowData.service ? rowData.service.name : "--"}
                        subTitle={`PCP: ${
                          rowData.primaryCareProvider
                            ? `${rowData.primaryCareProvider.firstName} ${rowData.primaryCareProvider.lastName}`
                            : "Unassigned"
                        }`}
                      />
                    ),
                    billedto: rowData?.serviceBilledToOverride
                      ? billedToOptions[rowData?.serviceBilledToOverride]
                      : rowData?.service?.serviceBilledTo
                      ? billedToOptions[rowData?.service?.serviceBilledTo]
                      : "-",
                    status:
                      rowData?.refundRequest?.refundRequestState === RefundRequestState.Pending ? (
                        <span className={`text-docsigna-orange`}>Refund Requested</span>
                      ) : (
                        renderState[rowData.state]
                      ),

                    updated: moment(rowData.updatedDate).format("ll"),
                    comments: (
                      <>
                        {rowData.numMessages ? rowData.numMessages : 0}
                        {rowData.numUnreadMessages ? (
                          <span className="text-xs ml-1 text-green-700 font-medium py-1 px-2 bg-green-100 rounded-full">
                            {rowData.numUnreadMessages} New
                          </span>
                        ) : null}
                      </>
                    ),
                  };
                  return (
                    <TableRow
                      tooltipVisible={tooltipStates[index]}
                      handleOpenTooltip={() => handleToolTipOpen(index)}
                      handleCloseTooltip={() => handleToolTipClose()}
                      data={Object.values(sortedData)}
                      key={index}
                      extraClassesRow={headers.map(header => header.extraClasses)}
                      tooltipOptions={moreOptions}
                      handleTooltipClick={type => handleTooltipClick(type, rowData.requestId)}
                      onRowClick={() => {
                        onRowClick(rowData.requestId);
                      }}
                    />
                  );
                })}
                <Pagination
                  colSpan={10}
                  count={officeRequestList.data ? officeRequestList.data.totalElements : 0}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  page={sort.pageNumber}
                  rowsPerPage={sort.rowsPerPage}
                />
              </>
            ) : (
              <DataNotFound image={NoRequestFound} text="No requests found" />
            )}
          </div>
        ) : (
          <CircularProgress size={40} color={"info"} style={{ marginLeft: "50%", marginTop: "10%" }} />
        )}
      </div>
      <ConfirmationDialog
        open={showCloseAlert}
        description={renderCloseMessage()}
        handleFailure={() => setShowCloseAlert(false)}
        handleSuccess={async () => {
          if (currentOffice?.officeId) {
            setIsLoading(true);
            const res = await OfficeService.setOfficeTemporarilyClosed(currentOffice?.officeId, false);
            if (res) {
              await dispatch(getOffice({ officeId: currentOffice?.officeId }));
              navigate(`create-request`);
            }
            setIsLoading(false);
          }
        }}
        canClickOutSide={false}
        loading={isLoading}
        successButtonText="Open Office"
        failureButtonText="Remain Closed"
        //hideActionButtons={false}
      />
    </main>
  );
};

export default OfficeDetailsScreen;
