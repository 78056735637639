import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../utils/constants";

const ThankYou = () => {
  const userData = localStorage.getItem("userData");
  const data = userData ? JSON.parse(userData) : null;
  return (
    <main>
      <div className="flex justify-between w-full">
        <div className="w-full max-w-2xl mx-auto md:my-12 px-10 text-center md:px-0">
          <h3 className="text-3xl font-light mb-4">Thank you for submitting your request!</h3>
          <p className="font-light ">Please check your email for status updates,</p>
          <p>
            or you can access your guest request{" "}
            <span className="text-base">
              <Link
                to={`/guest/login${data?.email && data?.requestNumber ? `?email=${data?.email}&requestNumber=${data?.requestNumber}` : ""}`}
                style={{ color: colors.Blue }}>
                {" "}
                here
              </Link>
            </span>
            .
          </p>
        </div>
      </div>
    </main>
  );
};

export default ThankYou;
