import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { RootState, AppDispatch } from "../../redux/store";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getFinanceRequests, getFinanceReport, setActiveFinanceTab, getFinanceReportTabData } from "../../redux/slices/FinanceSlice";
import { DatePicker, SelectWithSearch, Tabs } from "../../components";
import { ToastVariants } from "../../models";
import { Button } from "../../components";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NotFound from "../../assets/images/no-financial.png";
import DashboardTab from "./DashboardTab";
import RequestTab from "./RequestTab";
import { listOfficeData, setSelectedOffice } from "../../redux/slices/OfficeSlice";
import ReportingTab from "./ReportingTab";
import { Office } from "../../models/office.model";
import { AllOfficeObj } from "../../utils/constants";

const AdminFinanceScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const { financeRequests, financeReport, loading, activeTab, sort } = useSelector((state: RootState) => state.finance);
  const { officeList, selectedOffice } = useSelector((state: RootState) => state.office);
  const date = new Date();
  const monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [monthOptions, setMonthOptions] = useState<string[]>(["january"]);
  const [selectedMonth, setSelectedMonth] = useState<string>(`${monthName[date.getMonth()] + " " + date.getFullYear()}`);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [triger, setTriger] = useState(false);
  const [selectedOfficeSearch, setSelectedOfficeSearch] = useState("");
  const [selectedMonthReportingTab, setSelectedMonthReportingTab] = useState("");

  date.setDate(1);
  const monthArray: string[] = [];
  for (let i = 0; i <= 11; i++) {
    monthArray.push(monthName[date.getMonth()] + " " + date.getFullYear());
    date.setMonth(date.getMonth() - 1);
  }

  const fetchData = (officeId = "", startDate: string, endDate: string) => {
    dispatch(getFinanceReport({ officeId, startDate: new Date(startDate), endDate: new Date(endDate) }));
    dispatch(
      getFinanceRequests({
        officeId: officeId,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        page: 0,
        size: 10,
        sort: sort.sortedBy,
      })
    );
  };

  const handleMonthChange = (e: string) => {
    setSelectedMonth(e);
    setStartDate("");
    setEndDate("");
    const selectedMonth = e.split(" ");
    const month = monthName.indexOf(selectedMonth[0]);
    const year = selectedMonth[1];
    const firstDay = moment([year, month]).startOf("month").format("YYYY-MM-DD");
    const lastDay = moment([year, month]).endOf("month").add(1, "day").format("YYYY-MM-DD");
    fetchData(selectedOffice?.officeId, firstDay, lastDay);
  };

  const applyFilter = () => {
    if (startDate > endDate) {
      toast?.openToast("End date should be greater then start date", 2000, ToastVariants.Error);
      return;
    }
    if (moment(endDate, "YYYY-MM-DD").diff(moment(startDate, "YYYY-MM-DD"), "year") > 0) {
      toast?.openToast("Date range should not be greater then one year", 2000, ToastVariants.Error);
      return;
    }
    const firstDay = moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    const lastDay = moment(endDate, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD");
    fetchData(selectedOffice?.officeId, firstDay, lastDay);
  };

  const loadMoreRequests = (page: number, size: number, sortBy: string) => {
    const splitMonth = selectedMonth.split(" ");
    const month = monthName.indexOf(splitMonth[0]);
    const year = splitMonth[1];
    let firstDay = moment([year, month]).startOf("month").format("YYYY-MM-DD");
    let lastDay = moment([year, month]).endOf("month").add(1, "day").format("YYYY-MM-DD");
    if (startDate && endDate) {
      if (startDate > endDate) {
        return;
      }
      firstDay = moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      lastDay = moment(endDate, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD");
    }
    dispatch(
      getFinanceRequests({
        officeId: selectedOffice?.officeId ?? undefined,
        startDate: new Date(firstDay),
        endDate: new Date(lastDay),
        page,
        size,
        sort: sortBy,
      })
    );
  };

  const handleTabChange = (index: number) => {
    if (index === 2 && !selectedOffice?.officeId) {
      toast?.openToast("Please select a single office from the dropdown to access reporting.", 2000, ToastVariants.Warn);
      return;
    }
    dispatch(setActiveFinanceTab({ index }));
  };

  const onOptionSelect = (value: Office) => {
    if (value.officeId === "") {
      handleTabChange(0);
    } else {
      let firstDay = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
      let lastDay = moment().startOf("month").format("YYYY-MM-DD");
      if (selectedMonthReportingTab) {
        const splitMonth = selectedMonthReportingTab.split(" ");
        const month = monthName.indexOf(splitMonth[0]);
        const year = splitMonth[1];
        firstDay = moment([year, month]).startOf("month").format("YYYY-MM-DD");
        lastDay = moment([year, month]).endOf("month").add(1, "day").format("YYYY-MM-DD");
      }
      dispatch(
        getFinanceReportTabData({
          officeId: value.officeId ?? "",
          startDate: new Date(firstDay),
          endDate: new Date(lastDay),
        })
      );
    }
    dispatch(setSelectedOffice(value));
    setSelectedOfficeSearch("");
  };

  const changeReportTabMonth = (val: string) => {
    setSelectedMonthReportingTab(val);
    const splitMonth = val.split(" ");
    const month = monthName.indexOf(splitMonth[0]);
    const year = splitMonth[1];
    const firstDay = moment([year, month]).startOf("month").format("YYYY-MM-DD");
    const lastDay = moment([year, month]).endOf("month").add(1, "day").format("YYYY-MM-DD");
    dispatch(
      getFinanceReportTabData({
        officeId: selectedOffice?.officeId ?? "",
        startDate: new Date(firstDay),
        endDate: new Date(lastDay),
      })
    );
  };

  useEffect(() => {
    if (monthArray.length === 12 && triger === false) {
      setMonthOptions(monthArray);
      setTriger(true);
    }
  }, [monthArray]);

  useEffect(() => {
    const firstDay = moment().startOf("month").format("YYYY-MM-DD");
    const lastDay = moment().add(1, "M").startOf("month").format("YYYY-MM-DD");
    fetchData(selectedOffice?.officeId, firstDay, lastDay);
  }, [selectedOffice, dispatch]);

  useEffect(() => {
    dispatch(listOfficeData({ page: 0, size: 100, sortType: "name,asc" }));
  }, [dispatch]);

  const noRecordFound = !(
    financeReport?.totalRevenue ||
    financeReport?.discountsProvided ||
    financeReport?.feesOwedToDs ||
    financeReport?.netIncome ||
    financeReport?.dsCollected ||
    financeReport?.collectedByOffice ||
    financeReport?.outstandingPayments ||
    financeReport?.totalTaxApplicableRevenue ||
    financeReport?.taxCollected
  );

  let officesList: Office[] = [{ ...AllOfficeObj }];
  if (officeList?.data?.content?.length) {
    officesList = [...officesList, ...officeList?.data?.content];
  }
  const renderFilters = () => {
    return (
      <div className="flex items-center flex-wrap relative gap-2 my-5">
        <div className="">
          <SelectWithSearch
            value={selectedOffice?.name ?? ""}
            onSearcSelect={newalue => {
              setSelectedOfficeSearch(newalue);
            }}
            onSelect={newValue => {
              onOptionSelect(newValue);
            }}
            options={officesList.filter(o => `${o.name.toLowerCase()}`.includes(selectedOfficeSearch.toLowerCase())) || []}
            extraClasses="w-52"
            placeholder={"All Offices"}
            isSearchBox={true}
            searchValue={selectedOfficeSearch}
            renderOption={option => {
              return <div>{option.name}</div>;
            }}
            renderValue={option => {
              const renderVal = officesList?.find(o => `${o.name}` === option);
              return renderVal ? `${renderVal.name}` : "";
            }}
            onSearchChange={(e, newValue) => {
              setSelectedOfficeSearch(newValue);
            }}
          />
        </div>
        {activeTab < 2 ? (
          <>
            <div className="flex items-center justify-start p-0 xl:mb-0 mt-2 sm:mt-0">
              <select
                onChange={e => {
                  handleMonthChange(e.target.value);
                }}
                value={selectedMonth}
                className={`general-select w-52 space-x-0`}>
                {monthOptions.map((ele, index) => {
                  return <option key={index}>{ele}</option>;
                })}
              </select>
            </div>
            <div className="flex items-center flex-wrap gap-2">
              <div className="w-28">
                <span className="text-base font-medium">or show From:</span>
              </div>
              <div className="w-40">
                <DatePicker
                  label={""}
                  name=""
                  isRequired={false}
                  placeholder={"MM/DD/YYYY"}
                  value={startDate}
                  onChange={val => {
                    setStartDate(val);
                  }}
                  errorMsg={""}
                  isError={false}
                  disabled={false}
                  isDob={false}
                />
              </div>
              <span className="text-base font-medium pl-3 xl:pl-0 pr-3 xl:pr-0">To:</span>
              <div className="w-40">
                <DatePicker
                  label={""}
                  name=""
                  isRequired={false}
                  placeholder={"MM/DD/YYYY"}
                  value={endDate}
                  onChange={val => {
                    setEndDate(val);
                  }}
                  errorMsg={""}
                  isError={false}
                  disabled={false}
                  isDob={false}
                />
              </div>
              <Button
                onClickFunc={applyFilter}
                AdditionalClassNames="ml-3 xl:ml-0 mt-2 sm:mt-0"
                text="View"
                disabled={!startDate || !endDate || loading}
              />
            </div>
          </>
        ) : null}
        {activeTab === 2 ? (
          <div className="flex items-center justify-start">
            <select
              className="general-select w-52"
              onChange={e => {
                changeReportTabMonth(e.target.value);
              }}
              value={selectedMonthReportingTab}>
              {monthOptions.slice(1).map((ele: string, index: number) => {
                return (
                  <option key={index} value={ele}>
                    {ele}
                  </option>
                );
              })}
            </select>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <main
        style={{
          minHeight: "calc(100vh - 96px)",
        }}>
        <div className="container mx-auto p-8 relative">
          <Tabs components={[]} options={["Dashboard", "Offices"]} activeTab={1} />
          {/* {activeTab < 2 ? (
            <div className="h-11 absolute right-[2rem] top-[2.3rem] hidden sm:block">
              <Button text="Export to CSV" onClickFunc={() => toast?.openToast()} AdditionalClassNames="" />
            </div>
          ) : null} */}
          {renderFilters()}
          {loading ? (
            <CircularProgress size={40} color={"info"} style={{ marginLeft: "50%", marginTop: "10%" }} />
          ) : (
            <>
              <div className="relative">
                <Tabs
                  components={[
                    <DashboardTab key="dashboard-tab" noRecordFound={noRecordFound} renderFilters={renderFilters} />,
                    <RequestTab
                      key="request-tab"
                      loadMoreRequests={loadMoreRequests}
                      sort={sort}
                      renderFilters={renderFilters}
                      selectedOffice={selectedOffice}
                    />,
                    <ReportingTab
                      key="reporting-tab"
                      monthOptions={monthOptions}
                      changeReportTabMonth={changeReportTabMonth}
                      selectedMonth={selectedMonthReportingTab}
                      selectedOffice={selectedOffice}
                      monthName={monthName}
                    />,
                  ]}
                  options={["Dashboard", "Requests", "Reporting"]}
                  activeTab={activeTab}
                  onClickTab={handleTabChange}
                />
              </div>
              {activeTab !== 2 && !financeReport?.billedTo?.length && !financeRequests?.length ? (
                <DataNotFound image={NotFound} text="No financial records found" />
              ) : null}
            </>
          )}
        </div>
      </main>
    </>
  );
};
export default AdminFinanceScreen;
