/* eslint-disable no-useless-escape */

import { OfficeState } from "../models/office.model";

// LocalStorage Keys
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const SESSION_STORAGE_REQUEST_DATA = "request-data";
export const SESSION_STORAGE_OFFICE_DATA = "selected-office-data";
export const SESSION_STORAGE_ONBOARDING_EMAIL_VERIFIED = "session-storage-onboarding-email-verified";
export const SESSION_STORAGE_ONBOARDING_PHONE_VERIFIED = "session-storage-onboarding-phone-verified";
export const SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED = "session-storage-onboarding-request-number-verified";
export const LOCAL_STORAGE_RESET_PASSWORD_EMAIL = "local-storage-reset-password-email";
export const SESSION_STORAGE_PAYMENT_INTENT = "session-storage-payment-intent";
export const SESSION_STORAGE_ARCHIVE_STATE_TYPE = "session-storage-archive-state-type";
export const SESSION_STORAGE_NOTIFICATION_TAB = "session-storage-notification-tab";
export const SESSION_STORAGE_USER_STATE = "session-storage-user-state";
export const SESSION_STORAGE_USER_STATE_TEAM = "session-storage-user-state-team";
export const SESSION_STORAGE_USER_STATE_PATIENT = "session-storage-user-state-patient";
export const SESSION_STORAGE_PATIENT_BLOCK_FEE = "session-storage-patient-block-fee";
export const SESSION_STORAGE_NEW_REQUEST = "session-storage-new-request";
export const SESSION_STORAGE_TEMP_CLOSED = "session-storage-temp-closed";
export const SESSION_STORAGE_SHOW_MFA = "session-storage-show-mfa";

export const MatchSubStringRegex = /^\d+$/;
export const CheckRequestNoRegex = /[ A-Za-z@!#$%^&\\\*()/?`~<>,.;:'"_+{}\]\[=|]/i;
export const DiscountValueRegex = /[$,.]/g;
export const AmountReplaceRegex = /[^\d.]/g;
export const Phone_Fax_Regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
export const CurrencyRegex = /^\d*(\.\d{0,2})?$/;
export const PrimaryContactEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DiscountValueValidation = /^([1-9]|[1-9][0-9]|100)$/

//Colours
export const colors = {
  Red: "#EE1818",
  Blue: "#4D4AE2",
  Gray: "#61697D",
  Green: "#5CCB4E",
  placeholderColor: "#A5A9B5",
  DarkBlue: '#1E294F',
  LightBlue: '#1A4382',
  Orange: '#FB5B30',
  GreenYellow: '#DDEE59',
  DarkGreen: "#dae25f",
  Lilac: '#C2A1CC',
  PictonBlue: '#40A6DE',
  CyanBlue: '#4F7DBF',
  PatientPays: '#DAE360',
  ThirdParty: '#F15D36',
  WSIB: '#4F7DBF',
  ServiceCanada: '#C2A1CC',
  OHIP: '#40A6DE',
  PrescriptionRefill: '#F69D99',
};

export const ShowServiceExemptionOfficeIds = [
  "46ca7d01-2bb7-46b4-a992-70afbef565b0",
  "77731df2-54d5-4989-94c7-07c99157194c",
  "167388df-652b-4cc1-b9e0-8d3306e20c42",
  "9ffdef8d-3878-4355-91f3-4debb33d0041",
  "8a367bd3-3258-46f0-af0e-72cfe81bdc2a", // Test Office (prod)
];

export const UserStateTypes = {
  All: "",
  Active: 'active',
  Invited: 'invited',
  Disabled: 'disabled',
}

export const AllOfficeObj = {
  name: 'All Offices',
  officeId: '',
  street: '',
  city: '',
  state: '',
  zip:'',
  country:'',
  phone:'',
  fax:'',
  contactName:'',
  contactEmail:'',
  officeState: OfficeState.Draft,
}

export const FinanceReportKeys = [
  "totalRevenue",
  "discountsProvided",
  "feesOwedToDs",
  "stripeFee",
  "netIncome",
  "outstanding30DaysPastDue",
  "outstanding60DaysPastDue",
  "outstanding90DaysPastDue",
  "outstandingTotalPastDue",
  "withHstDsFeeSubTotal",
  "withoutHstDsFeeSubTotal",
  "withHstTotal",
  "withHstTotalCredits",
  "withoutHstTotalCredits",
  "withHstDoctorDsFee",
  "withHstPatientDsFee",
  "withHstDebits",
  "withHstTotalDebits",
  "netPayableToDoctor",
  "withHstRevenueCollectedByOffice",
  "withoutHstRevenueCollectedByOffice",
  "withHstRevenueCollectedByOfficeHstCalculated"
]

export const ProvinceList = [
  { value: "AB", text: "Alberta" },
  { value: "BC", text: "British Columbia" },
  { value: "MB", text: "Manitoba" },
  { value: "NB", text: "New Brunswick" },
  { value: "NL", text: "Newfoundland and Labrador" },
  { value: "NT", text: "Northwest Territories" },
  { value: "NS", text: "Nova Scotia" },
  { value: "NU", text: "Nunavut" },
  { value: "ON", text: "Ontario" },
  { value: "PE", text: "Prince Edward Island" },
  { value: "QC", text: "Quebec" },
  { value: "SK", text: "Saskatchewan" },
  { value: "YT", text: "Yukon" },
];

export enum ContactType {
  InsuranceCompany = 'Insurance Company',
  Lawyer = 'Lawyer',
  Pharmacy = 'Pharmacy',
  Other = 'Other',
}