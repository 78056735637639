import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, TextInput } from "../../components";
import { forgotPassword } from "../../redux/slices/CurrentUserSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { validateEmail } from "../../utils";
import { colors, LOCAL_STORAGE_RESET_PASSWORD_EMAIL } from "../../utils/constants";
import bgImg from "../../assets/images/banner.jpg";

const ForgotPassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { logInEmail, loading } = useSelector((state: RootState) => state.currentUser);
  const [email, setemail] = useState(logInEmail || "");
  const [emailError, setEmailError] = useState(false);
  const [initErrorCheck, setinitErrorCheck] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const onEmailChange = (email: string) => {
    if (initErrorCheck) {
      setEmailError(!validateEmail(email));
    }
    setemail(email);
  };
  const handleConfirm = () => {
    setinitErrorCheck(true);
    setApiError(false);
    setEmailError(!validateEmail(email));
    if (validateEmail(email)) {
      dispatch(forgotPassword({ email: email })).then(action => {
        if (action.payload) {
          localStorage.setItem(LOCAL_STORAGE_RESET_PASSWORD_EMAIL, email);
          setEmailSent(true);
        } else {
          setApiError(true);
        }
      });
    }
  };
  return (
    <main className="">
      <div
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleConfirm();
          }
        }}>
        <section className="max-h-[21rem] overflow-hidden">
          <img src={bgImg} alt="bg" />
        </section>
        <section className="lg:flex m-auto mt-10">
          <div className="w-full lg:w-[32rem] m-auto rounded-3xl p-10 bg-docsigna-pink-light mb-10">
            {emailSent ? (
              <p>An email has been sent to {email} with a link to reset your password.</p>
            ) : (
              <div className="">
                <h3 className="heading uppercase">Forgot Password</h3>
                <div className="tabs">
                  {apiError ? (
                    <p className="text-center" style={{ color: colors.Red }}>
                      {"An error has occurred, please try again."}
                    </p>
                  ) : (
                    <div className="font-medium mt-5">
                      <span>Please enter your email address below so we can send you a link to reset your password.</span>
                    </div>
                  )}
                  <div className="mt-5">
                    <TextInput
                      isRequired
                      label="Email"
                      errorMsg={"Please enter a valid email address"}
                      value={email}
                      placeholder="Enter email address"
                      isError={emailError}
                      onChangeFunc={e => onEmailChange(e.target.value)}
                      isDarkBg={true}
                    />

                    <div className="flex mt-5">
                      <Button text="Confirm" onClickFunc={handleConfirm} disabled={emailError} varient={loading ? "loading" : "Primary"} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default ForgotPassword;
