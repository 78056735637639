/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from "react";
import { formatPhoneNumber, onFileError, validateEmail, validatePhoneOrFaxNumber } from "../../utils";
import { colors } from "../../utils/constants";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import DropArea from "../../components/DropArea/DropArea";
import TextInput from "../../components/TextInput/TextInput";
import { addRequest } from "../../redux/slices/OnBoardingSlice";
import { RootState, AppDispatch } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { clearUploadedFiles } from "../../redux/slices/FileSlice";
import { RequestType } from "../../models/request.model";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { FileTypes, ToastVariants } from "../../models";
import { useNavigate } from "react-router-dom";

interface FormData {
  id: string;
  title: string;
  placeholder: string;
  type: string;
  value: string | boolean;
  errorMsg: string;
  required: boolean;
  valid: boolean;
  visible: boolean;
}
const initialState: FormData[] = [
  {
    id: "details",
    title: "",
    placeholder: "Enter details here",
    type: "textarea",
    value: "",
    errorMsg: "Please enter the details.",
    required: false,
    valid: false,
    visible: true,
  },
  {
    id: "section1",
    title: "Attachments",
    placeholder: "You can attach up to 10 JPEG, PDF or PNG files",
    type: "section",
    value: "",
    errorMsg: "",
    required: true,
    valid: true,
    visible: true,
  },
  {
    id: "droparea",
    title: "",
    placeholder: "",
    type: "droparea",
    value: "",
    errorMsg: "",
    required: true,
    valid: true,
    visible: true,
  },
  {
    id: "section2",
    title: "Recipient",
    placeholder: "A copy of your requested form will be sent to you.",
    type: "section",
    value: "",
    errorMsg: "",
    required: true,
    valid: true,
    visible: true,
  },
  {
    id: "additionalCopyCheck",
    title: "Additional copy of this form to be sent to a 3rd party.",
    placeholder: "",
    type: "checkbox",
    value: "",
    errorMsg: "",
    required: true,
    valid: true,
    visible: true,
  },
  {
    id: "name",
    title: "Recipient Name",
    placeholder: "Enter name",
    type: "textinput",
    value: "",
    errorMsg: "Please enter the name of recipient.",
    required: true,
    valid: false,
    visible: false,
  },
  {
    id: "emailOrFax",
    title: " Email or Fax Number",
    placeholder: "Enter email address or fax number",
    type: "textinput",
    value: "",
    errorMsg: "Please provide valid email address or fax number with format 111-222-3333",
    required: true,
    valid: false,
    visible: false,
  },
];

const windowObj = window as any;

const CreatePatientRequest = () => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormData[]>([...initialState]);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [addRequestError, setAddRequestError] = useState(false);
  const [dropAreaError, setDropAreaError] = useState<string>("");
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const officeId = currentUser && currentUser?.offices && currentUser?.offices?.length ? currentUser.offices[0].officeId : undefined;

  const handleChange = (id: string, val: string | boolean) => {
    let temp = [...formData];
    const inputIndex = temp.findIndex(a => a.id === id);
    let value = val;
    let valid = value !== "";
    if (inputIndex !== -1) {
      switch (id) {
        case "additionalCopyCheck":
          const toChange = ["emailOrFax", "name"];
          if (value) {
            temp = temp.map(val => {
              const item = { ...val };
              if (toChange.includes(item.id)) {
                item.visible = true;
              }
              return item;
            });
          } else {
            temp = temp.map(val => {
              const item = { ...val };
              if (toChange.includes(item.id)) {
                item.visible = false;
                item.value = "";
                item.valid = false;
              }
              return item;
            });
          }
          break;
        case "emailOrFax":
          if (validateEmail((value as string).trim())) {
            valid = true;
          } else if (validatePhoneOrFaxNumber(formatPhoneNumber(value as string))) {
            valid = true;
            value = formatPhoneNumber(value as string);
          } else {
            valid = false;
          }
          break;
        case "name":
        case "details":
          valid = (value as string)?.trim().length !== 0;
          break;
        default:
          break;
      }
      setFormData([
        ...temp.slice(0, inputIndex),
        {
          ...temp[inputIndex],
          value: value,
          valid: valid,
        },
        ...temp.slice(inputIndex + 1),
      ]);
    }
  };

  const validateForm = () => {
    return formData.every(v => {
      if (!v.visible) return true;
      if (v.type === "section" || v.type === "additionalCopyCheck" || v.type === "droparea") return true;
      return v.valid;
    });
  };

  const saveRequest = () => {
    const details = formData.find(f => f.id === "details")?.value as string;
    const recipientName = formData.find(f => f.id === "name")?.value as string;
    const files = (formData.find(f => f.id === "droparea")?.value as string).split(",");
    const recipientContact = formData.find(f => f?.id === "emailOrFax")?.value as string;

    if (officeId && currentUser) {
      setLoading(true);
      if (windowObj && windowObj?.userGuiding && windowObj?.userGuiding?.launchSurvey) {
        windowObj?.userGuiding?.launchSurvey(3340);
      }
      dispatch(
        addRequest({
          request: {
            requestType: RequestType.Standard,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            dateOfBirth: currentUser.dateOfBirth ? currentUser.dateOfBirth : "",
            ohipNumber: currentUser.ohipNumber ? currentUser.ohipNumber : "",
            phone: currentUser.phone ? currentUser.phone : "",
            email: currentUser.email,
            details,
            fileIds: files[0].length ? files : [],
            recipientName: recipientName === "" ? undefined : recipientName,
            recipientContact: recipientContact === "" ? undefined : recipientContact,
            officeId: officeId,
            patientId: currentUser.userId,
            acceptedTerms: true,
          },
        })
      )
        .then(action => {
          if (action.payload !== null) {
            toast?.openToast("Request created successfully.", 2000, ToastVariants.Success);
            navigate(`/patients/${currentUser?.userId}/requests`);
          } else {
            setAddRequestError(true);
          }
          setLoading(false);
        })
        .catch(_ => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      setFormData([...initialState]);
      dispatch(clearUploadedFiles());
    };
  }, []);

  return (
    <div className="p-[30px] lg:p-[50px]">
      <div className="w-full max-w-[900px]">
        {addRequestError ? (
          <p style={{ color: colors.Red }} className="text-base mb-[10px]">
            {"Unable to create request at this time, please try again."}
          </p>
        ) : null}
        <h2 className="text-[24px] mb-[30px]">You can submit a new request below</h2>
        <div className="block text-xs text-gray-700 mb-[10px]">
          {"Request Notes"}
          <span className="text-red-500">*</span>
        </div>
        <p className="text-xs font-medium mb-[10px]">
          {
            "For peace of mind and to ensure your request is fully addressed, please provide all relevant information, including dates and any necessary medical details your primary care provider may need when making a request."
          }
        </p>
        {formData.slice(0, 5).map(val => {
          if (val.visible) {
            switch (val.type) {
              case "textarea":
                return (
                  <div key={val.id} className="mb-[30px]">
                    <TextInput
                      isTextArea
                      value={val.value as string}
                      onChangeFunc={e => handleChange(val.id, e.currentTarget.value)}
                      placeholder={val.placeholder}
                      isError={!val.valid && errorInitiated}
                      errorMsg={val.errorMsg}
                      label={val.title}
                      isRequired={val.required}
                      extraInputClass="resize-none min-h-24"
                    />
                  </div>
                );
              case "textinput":
                return (
                  <div key={val.id} className="mb-[30px]">
                    <TextInput
                      value={val.value as string}
                      onChangeFunc={e => handleChange(val.id, e.currentTarget.value)}
                      placeholder={val.placeholder}
                      isError={!val.valid && errorInitiated}
                      errorMsg={val.errorMsg}
                      label={val.title}
                      isRequired={val.required}
                    />
                  </div>
                );
              case "droparea":
                return (
                  <div key={val.id} className="mb-[30px]">
                    {dropAreaError ? <p className="text-xs text-red-500 mb-[10px]">{dropAreaError}</p> : null}
                    <DropArea
                      allowedFileTypes={[FileTypes.JPEG, FileTypes.PNG, FileTypes.PDF]}
                      onFilesChange={e => handleChange(val.id, e.toString())}
                      onError={err => setDropAreaError(onFileError(err))}
                      maxFileSize={50 * 1024 * 1024}
                      maxNumOfFiles={10}
                      filePreviewVisible={true}
                    />
                  </div>
                );
              case "section":
                return (
                  <div key={val.id} className={`${val.id === "section2" ? "" : ""}`}>
                    <p className="text-xs font-medium mb-[10px]">
                      <span className="block text-xs text-gray-700 mb-[10px]">{val.title}</span>
                      {val.placeholder}
                    </p>
                  </div>
                );
              case "checkbox":
                return (
                  <div key={val.id} className="mb-[30px]">
                    <Checkbox label={val.title} checked={val.value as boolean} onChange={e => handleChange(val.id, e.currentTarget.checked)} />
                    {!val.valid && errorInitiated ? <p style={{ color: colors.Red }}>{val.errorMsg}</p> : null}
                  </div>
                );
              default:
                break;
            }
          }
        })}
        <div className={`grid md:grid-cols-2 gap-[15px] md:gap-[30px] w-full ${formData ? "mb-[50px]" : ""}`}>
          {formData.slice(5).map(val => {
            return val.visible ? (
              <div key={val.id}>
                <TextInput
                  value={val.value as string}
                  onChangeFunc={e => handleChange(val.id, e.currentTarget.value)}
                  placeholder={val.placeholder}
                  isError={!val.valid && errorInitiated}
                  errorMsg={val.errorMsg}
                  label={val.title}
                  isRequired={val.required}
                />
              </div>
            ) : null;
          })}
        </div>
        <div>
          <div className="flex justify-start">
            <Button
              disabled={loading}
              onClickFunc={() => navigate(-1)}
              AdditionalClassNames="pointer px-5 mr-3 uppercase font-bold"
              text="Cancel"
              width="fit"
              varient="Outlined"
            />
            <div onClick={() => setErrorInitiated(true)}>
              <Button
                onClickFunc={() => {
                  if (validateForm()) {
                    saveRequest();
                  }
                }}
                varient={loading ? "loading" : "Primary"}
                disabled={(!validateForm() && errorInitiated) || loading}
                AdditionalClassNames="pointer px-5 uppercase font-bold"
                text="Submit Request"
                width="fit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreatePatientRequest;
