/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useContext, useEffect } from "react";
import { Button, ConfirmationDialog, InvoiceForm, PaymentForm, ProtectedComponent, TextInput, Tooltip, UserSideBar } from "../../components";
import dropDownImg from "../../assets/images/down.svg";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { AuthPermission, UserState } from "../../services/auth.service";
import tickImg from "../../assets/images/tick-white.svg";
import {
  addOfficeRequestFiles,
  getOfficeRequest,
  setOfficeRefundReason,
  setOfficeRequestState,
  setOfficeRequestSubmitted,
  undoOfficePaymentStatus,
} from "../../redux/slices/OfficeRequestSlice";
import { RequestStatesOptions, ToastVariants } from "../../models";
import { RefundRequestState, RequestPaymentMethod, RequestState, RequestType, RequestVerificationStateType } from "../../models/request.model";
import { setActiveIndex, setOpenPaymentSidebar } from "../../redux/slices/RequestSlice";
import { getOfficeFees } from "../../redux/slices/FeeSlice";
import { ServiceBilledTo } from "../../models/service.model";
import { Modal } from "@mui/material";
import PaymentDetailSummaryBox from "./PatientViewRequestComps/PaymentDetailSummaryBox";
import { addOfficeRequestTimelineEvent, getOfficeRequestTimeline } from "../../redux/slices/UserSlice";
import { billedToOptions, calculateOverridePrice, formatCurrency, priceReview, renderState, showRefundState } from "../../utils";
import InvoicePatientPdf from "../../components/forms/InvoiceForm/PatientInvoicePdf";
import { uploadedInvoice, uploadFileNew } from "../../redux/slices/FileSlice";
import { OfficeService } from "../../services/office.service";
import { AmountReplaceRegex } from "../../utils/constants";
import { useDebouncedCallback } from "use-debounce";
import { DsFile, RequestFileTypes } from "../../models/ds-file.model";
import RadioInput from "../../components/RadioInput/RadioInput";

const html2pdf = require("html2pdf.js");

enum PriceOverrideStates {
  Pricing = "Pricing",
  Confirmation = "Confirmation",
  Warning = "Warning",
  PaymentOptions = "PaymentOptions",
  None = "None",
}

enum RefundReason {
  ChangePaymentMethod = "Change Payment Method",
  PaymentRefunded = "Payment Refunded",
}

const RequestDetailsSummaryBox = ({
  officeId,
  requestId,
  newPrice = "",
  newPriceAmount = 0,
  handleFromVisibility,
  setNewPrice,
  setNewPriceAmount,
}: {
  officeId: string;
  requestId: string;
  newPrice?: string;
  newPriceAmount?: number;
  handleFromVisibility: (val: RequestVerificationStateType["visibleForm"] | null) => void;
  setNewPrice?: (val: string) => void;
  setNewPriceAmount?: (val: number) => void;
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const [modalopen, setModalopen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [confirmationText, setConfirmationText] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAdjustedPaymentModal, setShowAdjustedPaymentModal] = useState<PriceOverrideStates>(PriceOverrideStates.None);
  const [refundModel, setRefundModel] = useState(false);
  const [refundReason, setRefundReason] = useState<RefundReason | undefined>();
  const [refundReasonText, setRefundReasonText] = useState("");
  const { requestData, invoiceObject } = useSelector((state: RootState) => state.officeRequest);
  const { invoiceCreate } = useSelector((state: RootState) => state.file);
  const { timeline } = useSelector((state: RootState) => state.user);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const { openPaymentSidebar } = useSelector((state: RootState) => state.request);
  const billedTo = requestData?.serviceBilledToOverride ? requestData?.serviceBilledToOverride : requestData?.service?.serviceBilledTo;
  const [dropDownWidth, setDropDownWidth] = useState(document.getElementById("request-status-div")?.clientWidth || 0);
  const permissions = currentUser?.permissions;
  const isPatient = billedTo === ServiceBilledTo.Patient;
  const isDocnoteDebit = requestData?.requestType === RequestType.DocnoteDebit;
  const isPrescriptionRefill = requestData?.requestType === RequestType.PrescriptionRefill;
  const isOHIP = billedTo === ServiceBilledTo.OHIP;
  const showComplete = isOHIP ? requestData?.submitted : requestData?.paymentComplete;
  const isVerified = requestData?.patientVerified;
  const serviceAssigned = requestData?.service !== undefined;
  const pcpAndStaffAssigne = requestData?.primaryCareProvider && requestData?.assignedTo;
  const isAllStepsCompleted = isVerified && serviceAssigned && pcpAndStaffAssigne;
  const invoiceCreated = requestData?.invoiceCreated;
  const paymentDoneWithStripe = requestData?.paymentMethod === RequestPaymentMethod?.Stripe;
  const isRequestCancelled = requestData && requestData.state && renderState[requestData?.state] === RequestStatesOptions["Cancelled"] ? true : false;
  const showPaidAndSubmit =
    billedTo === ServiceBilledTo.WSIB || billedTo === ServiceBilledTo.ServiceCanada || billedTo === ServiceBilledTo.ThirdParty;
  const showPendingPayment = !requestData?.paymentComplete && billedTo && [ServiceBilledTo.Patient, ServiceBilledTo.DocnoteDebit].includes(billedTo);
  const isAuthorizedToEdit = Array.isArray(permissions) && permissions?.includes(AuthPermission.Request);
  const statusChangeFlag =
    (requestData?.patientVerified && requestData.service?.name.length && requestData.assignedTo && requestData.primaryCareProvider) ||
    (requestData?.requestType && [RequestType.DocnoteDebit, RequestType.PrescriptionRefill].includes(requestData?.requestType));
  const isStandardRequest = requestData?.fulfillOnPayment === false;
  const isArchived = requestData?.archived;
  const verificatinTaskListCompleted = isVerified && serviceAssigned && !!pcpAndStaffAssigne;
  const showPaidButton =
    (isPatient || isPrescriptionRefill || isDocnoteDebit) && verificatinTaskListCompleted && !requestData?.paymentComplete && !requestData.refunded;
  const isThirdParty =
    requestData?.requestType === RequestType.ThirdPartyOffice ||
    requestData?.requestType === RequestType.PrescriptionRefill ||
    requestData?.requestType === RequestType.DocnoteDebit;

  const { hstDesc, hstPercentage, hstValue, additionalFeeTotal } = priceReview(invoiceObject ?? undefined);
  const { serviceFee, hstAmount, total } = calculateOverridePrice(requestData, !!hstValue, newPriceAmount);

  const openForm = (formName: RequestVerificationStateType["visibleForm"]) => {
    handleFromVisibility(formName);
  };

  const handleFinalizeInvoice = () => {
    setModalopen(true);
  };

  const handleReminderEmail = async () => {
    if (requestData?.patient?.userState === UserState.Invited) {
      await OfficeService.sendInviteReminder(officeId, requestId);
      toast?.openToast("Reminder email to create an account has been sent", 2000, ToastVariants.Success);
      return;
    }
    if (requestData?.state !== RequestState.PendingVerification) {
      const resp = await OfficeService.sendPaymentReminder(officeId, requestId);
      if (resp) toast?.openToast("Payment reminder email has been sent");
      else toast?.openToast("Payment reminder email failed", 2000, ToastVariants.Error);
    } else {
      toast?.openToast("Request should be verified", 2000, ToastVariants.Warn);
    }
  };

  const handleMarkedAsButton = async (text: string) => {
    if (text === "paid") {
      openForm("MarkAsPaid");
    } else {
      await dispatch(setOfficeRequestSubmitted({ officeId, requestId, status: true })).then(action => {
        if (action.payload !== null) {
          toast?.openToast(`Marked As ${text}!`);
          dispatch(
            addOfficeRequestTimelineEvent({
              event: `${currentUser?.firstName + " " + currentUser?.lastName} marked as ${text}`,
              officeId,
              requestId,
            })
          );
          if (billedTo === ServiceBilledTo.OHIP) {
            dispatch(setOfficeRequestState({ officeId, requestId, state: RequestState.Complete }));
          }
          if (showPaidAndSubmit) {
            dispatch(setOfficeRequestState({ officeId, requestId, state: RequestState.PendingPayment }));
          }
          dispatch(getOfficeRequest({ officeId, requestId }));
        }
      });
    }
  };

  const handleConfirmationBox = (valueSelected: "yes" | "Cancel") => {
    if (confirmationText === "complete") {
      if (valueSelected === "Cancel") {
        setConfirmationOpen(false);
      }
      if (valueSelected === "yes") {
        handleStateChange(RequestStatesOptions.Complete);
        setConfirmationOpen(false);
      }
    } else if (confirmationText === "paid") {
      if (valueSelected === "yes") {
        handleMarkedAsButton("paid");
        setConfirmationOpen(false);
      } else {
        setConfirmationOpen(false);
      }
    } else if (confirmationText === "submitted") {
      if (valueSelected === "yes") {
        handleMarkedAsButton("submitted");
        setConfirmationOpen(false);
      } else {
        setConfirmationOpen(false);
      }
    } else if (confirmationText === "externalPayCancel") {
      if (valueSelected === "yes") {
        setConfirmationOpen(false);
        setConfirmationText("cancel");
        setConfirmationOpen(true);
      } else {
        setConfirmationOpen(false);
      }
    } else {
      if (valueSelected === "Cancel") {
        setConfirmationOpen(false);
      }
      if (valueSelected === "yes") {
        handleStateChange(RequestStatesOptions.Cancelled);
        setConfirmationOpen(false);
      }
    }
  };

  const handleStateChange = (state: string) => {
    // officeId;
    // requestId;
    let stateToSend: any;
    switch (state) {
      case RequestStatesOptions["Pending-Verification"]:
        stateToSend = RequestState.PendingVerification;
        break;
      case RequestStatesOptions["Pending-Payment"]:
        stateToSend = RequestState.PendingPayment;
        break;
      case RequestStatesOptions["In-Progress"]:
        stateToSend = RequestState.InProgress;
        break;
      case RequestStatesOptions["Waiting-for-Signature"]:
        stateToSend = RequestState.WaitingSignature;
        break;
      case RequestStatesOptions["Final-Review"]:
        stateToSend = RequestState.FinalReview;
        break;
      case RequestStatesOptions["Complete"]:
        stateToSend = RequestState.Complete;
        break;
      case RequestStatesOptions["Cancelled"]:
        stateToSend = RequestState.Cancelled;
        break;
    }
    setShowDropDown(false);
    if (stateToSend && officeId && requestId)
      dispatch(setOfficeRequestState({ officeId: officeId, requestId: requestId, state: stateToSend })).then(action => {
        if (action.payload !== null) {
          if (requestData?.state !== stateToSend) {
            dispatch(
              addOfficeRequestTimelineEvent({
                event: `${currentUser?.firstName + " " + currentUser?.lastName} updated status to ${renderState[stateToSend]}`,
                officeId,
                requestId,
              })
            ).then(() => {
              dispatch(getOfficeRequest({ officeId, requestId }));
            });
          }

          toast?.openToast("Status successfully changed.");
        }
      });
  };

  const downloadInvoiceAsPdf = () => {
    const element1 = document.getElementById("invoice-patient-container-pdf");

    element1?.classList.remove("hidden");
    const fileName = `Request ${requestData?.requestNumber} Receipt`;
    const opt = {
      margin: [5, 0, 5, 0],
      filename: fileName,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4, dpi: 192, letterRendering: true, useCORS: true },
      jsPDF: {
        unit: "mm",
        format: "a4",
        compress: true,
        orientation: "portrait",
      },
      pagebreak: { mode: "avoid-all" },
    };
    html2pdf()
      .set(opt)
      .from(element1)
      .outputPdf("arraybuffer")
      .then(async (blob: Blob) => {
        element1?.classList.add("hidden");
        element1?.classList.remove("min-w-full");

        const file = new File([blob], fileName + ".pdf", {
          type: "application/pdf",
        });
        await uploadNewFile(file);
        dispatch(uploadedInvoice(true));
      })
      .then(() => {
        element1?.classList.add("hidden");
        toast?.openToast("Receipt created successfully!", 2000, ToastVariants.Success);
      });
  };

  const undoButton = () => {
    const show = requestData?.state === RequestState.PendingPayment ? moment().diff(requestData?.submittedDate, "days") < 30 : true;
    return show ? (
      <span
        onClick={() => {
          dispatch(setOfficeRequestSubmitted({ officeId, requestId, status: false })).then(action => {
            if (action.payload !== null) {
              dispatch(setOfficeRequestState({ officeId, requestId, state: RequestState.InProgress })).then(res => {
                dispatch(getOfficeRequest({ officeId, requestId }));
              });
            }
          });
        }}
        className="ml-1 text-xs font-bold text-docsigna-blue-light px-0 bg-transparent hover:bg-transparent cursor-pointer">
        Undo
      </span>
    ) : null;
  };

  const undoPaymentButton = () => {
    return (
      <span
        onClick={() => {
          setRefundModel(true);
        }}
        className="ml-1 text-xs font-bold text-docsigna-blue-light px-0 bg-transparent hover:bg-transparent cursor-pointer">
        Undo
      </span>
    );
  };

  const adjustPaymentReceived = () => {
    return (
      <div className="px-10 font-manrope">
        {showAdjustedPaymentModal === PriceOverrideStates.PaymentOptions ? (
          <div className="flex flex-col justify-center items-center gap-5 text-black font-base font-semibold">
            <h2 className="capitalize">Select payment received below</h2>
            <Button
              width="fit"
              text="Full Payment Received"
              onClickFunc={() => {
                setShowAdjustedPaymentModal(PriceOverrideStates.None);
                openForm("MarkAsPaid");
              }}
              AdditionalClassNames="min-w-[200px] py-5"
            />
            <Button
              width="fit"
              text="Price Override"
              onClickFunc={() => {
                setShowAdjustedPaymentModal(PriceOverrideStates.Pricing);
              }}
              AdditionalClassNames="min-w-[200px] py-5"
            />
          </div>
        ) : (
          <></>
        )}
        {showAdjustedPaymentModal === PriceOverrideStates.Pricing ? (
          <>
            <div className="flex justify-between items-center text-black font-base font-semibold">
              <h2>PRICE OVERRIDE AMOUNT</h2>
              <TextInput
                onChangeFunc={e => handleOverridePrice(e.currentTarget.value)}
                value={newPrice}
                placeholder="Enter price"
                extraInputClass="w-[105px] float-right"
              />
            </div>
            <h3 className="text-base font-semibold text-docsigna-purple mt-3">Payment Adjustment</h3>
            <div className="w-full flex  justify-between">
              <h3 className="text-base font-medium text-docsigna-purple">Service</h3>
              <h3 className="text-base font-medium text-docsigna-purple">{formatCurrency((serviceFee / 100).toString(), "blur")}</h3>
            </div>
            <hr className="my-2 border-docsigna-blue-dark opacity-25" />
            {hstAmount > 0 ? (
              <>
                <div className="w-full flex justify-between">
                  <div className="text-md font-small col-span-2">{hstDesc ? `${hstDesc} (${hstPercentage ? `${hstPercentage}%` : ""})` : "HST"}</div>
                  <div className="text-md font-small col-span-2">{formatCurrency((hstAmount / 100).toString(), "blur")}</div>
                </div>
                <hr className="my-2 border-docsigna-blue-dark opacity-25" />
              </>
            ) : null}
            <div className="w-full flex justify-between">
              <div className="text-md font-small font-bold col-span-2">Total </div>
              <div className="text-md font-small font-bold col-span-2">{formatCurrency((total / 100).toString(), "blur")}</div>
            </div>
            <p className="text-sm font-semibold text-docsigna-purple">Confirm the amount adjusted above is correct prior to proceeding. </p>
          </>
        ) : (
          <></>
        )}
        {showAdjustedPaymentModal === PriceOverrideStates.Confirmation ? (
          <div className="flex justify-center">
            <p className="text-lg font-semibold text-docsigna-purple">Would you like to create an updated invoice for this request?</p>
          </div>
        ) : (
          <></>
        )}
        {showAdjustedPaymentModal === PriceOverrideStates.Warning ? (
          <div className="flex justify-center">
            <p className="text-lg font-semibold text-docsigna-purple">
              We are unable to allow for payment adjustments on this request as there are additional fees attached. Please cancel and resubmit your
              request if you need to adjust the payment
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const paymentUndoReason = () => {
    return (
      <div className="px-10 font-manrope">
        <h2 className="text-lg font-bold">Please provide a reason for undoing the payment on this request</h2>
        <div>
          <RadioInput
            value={refundReason ?? ""}
            onChangeFunc={e => setRefundReason(e as RefundReason)}
            options={[RefundReason.ChangePaymentMethod, RefundReason.PaymentRefunded]}
          />
        </div>
        {refundReason === RefundReason.PaymentRefunded ? (
          <div className="flex flex-col gap-2 mb-5 mt-2">
            <h2 className="text-lg font-bold">Please provide any additional notes</h2>
            <TextInput
              onChangeFunc={e => setRefundReasonText(e.target.value)}
              value={refundReasonText}
              label=""
              placeholder="Write note here"
              isTextArea
              extraInputClass="resize-none min-h-24"
            />
          </div>
        ) : null}
        <p>This action cannot be undone</p>
      </div>
    );
  };

  const closeRefundReasonModel = () => {
    setRefundModel(false);
    setRefundReason(undefined);
    setRefundReasonText("");
  };

  const updateEvent = async (officeId: string, requestId: string, event: string) => {
    await dispatch(
      addOfficeRequestTimelineEvent({
        event,
        officeId,
        requestId,
      })
    );
  };

  const handleSubmitRefundReason = async () => {
    if (refundReason === RefundReason.PaymentRefunded && !refundReasonText?.trim().length) {
      toast?.openToast("Please provide reason note");
      return;
    }
    setIsLoading(true);
    let auditTrail = "";
    const action = await dispatch(undoOfficePaymentStatus({ officeId, requestId, status: false }));
    if (refundReason === RefundReason.ChangePaymentMethod) {
      if (action.payload !== null) {
        await dispatch(setOfficeRequestState({ officeId, requestId, state: RequestState.PendingPayment }));
        auditTrail = `${currentUser?.firstName + " " + currentUser?.lastName} updated status to ${renderState[RequestState.PendingPayment]}`;
        await updateEvent(officeId, requestId, auditTrail);
      }
    } else {
      await dispatch(
        setOfficeRefundReason({
          officeId,
          requestId,
          reason: refundReasonText,
        })
      );
      auditTrail = `${currentUser?.firstName + " " + currentUser?.lastName} issued a refund. Reason provided ${refundReasonText}`;
      await updateEvent(officeId, requestId, auditTrail);
      await dispatch(setOfficeRequestState({ officeId, requestId, state: RequestState.Cancelled }));
      await updateEvent(officeId, requestId, `${currentUser?.firstName + " " + currentUser?.lastName} updated status to cancelled`);
    }
    await dispatch(getOfficeRequest({ officeId, requestId }));
    setIsLoading(false);
    closeRefundReasonModel();
  };

  const debounced = useDebouncedCallback(value => {
    setNewPriceAmount ? setNewPriceAmount(value) : null;
  }, 750);

  const handleOverridePrice = (value: string) => {
    const amount = formatCurrency(value as string, "");
    setNewPrice ? setNewPrice(amount) : null;
    const amnt = +amount.replace(AmountReplaceRegex, "") * 100;
    debounced(parseFloat(amnt.toFixed(2)));
  };

  const handleSuccess = async () => {
    if (!newPrice) {
      toast?.openToast("Please enter price", 2000, ToastVariants.Warn);
      return;
    }
    if (showAdjustedPaymentModal === PriceOverrideStates.Warning) {
      handleStateChange(RequestStatesOptions.Cancelled);
    }
    // if (showAdjustedPaymentModal === PriceOverrideStates.Confirmation) {
    //   const res = await createInvoice();
    //   if (!res) return;
    //   handleMarkedAsButton("paid");
    // }
    if (showAdjustedPaymentModal === PriceOverrideStates.Pricing) {
      setNewPriceAmount ? setNewPriceAmount(serviceFee) : null;
      openForm("MarkAsPaid");
      setShowAdjustedPaymentModal(PriceOverrideStates.None);
    }
  };

  const uploadNewFile = async (file: File) => {
    if (file) {
      const response = await dispatch(
        uploadFileNew({
          file: file,
          callback: undefined,
        })
      );
      const fileId = (response?.payload as DsFile)?.fileId;
      if (fileId && requestData) {
        await dispatch(
          addOfficeRequestFiles({
            officeId: requestData?.officeId,
            requestId: requestData.requestId,
            fileIds: [fileId],
            requestFileType: RequestFileTypes.Receipt,
          })
        );
      }
    }
  };

  const createInvoiceVisible =
    (requestData?.serviceBilledToOverride && requestData?.serviceBilledToOverride !== null) ||
    (!requestData?.serviceBilledToOverride && requestData?.service?.serviceBilledTo !== null);

  useEffect(() => {
    dispatch(
      getOfficeFees({
        officeId: currentOffice?.officeId,
        page: 0,
        sortType: "name,asc",
        size: 100,
        fullPageLoaderShown: true,
      })
    );
    setDropDownWidth(document.getElementById("request-status-div")?.clientWidth || 0);
    window.addEventListener("resize", () => {
      setDropDownWidth(document.getElementById("request-status-div")?.clientWidth || 0);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    if (requestData?.state === RequestState.Complete || requestData?.archived) {
      setReadOnly(true);
    } else setReadOnly(false);
  }, [requestData]);

  useEffect(() => {
    if (requestData?.officeId && requestData?.requestId) {
      dispatch(getOfficeRequestTimeline({ officeId: requestData?.officeId, requestId: requestData?.requestId, page: 0, size: 100 }));
    }
  }, [requestData]);

  return (
    <div className="w-full md:max-w-xs" id="request-status-div">
      <div
        className={`text-md font-medium flex justify-between items-center py-2 px-4 mb-3 w-full bg-docsigna-blue-light-30 rounded-lg ${
          isAuthorizedToEdit ? "hover:cursor-pointer" : ""
        } `}>
        {isArchived ||
        isRequestCancelled ||
        (requestData?.refundRequest && requestData?.refundRequest?.refundRequestState === RefundRequestState.Pending) ||
        (requestData?.patient?.userState === UserState.Invited && requestData?.requestType !== RequestType.DocnoteDebit) ? (
          <div className="flex items-center justify-between">
            <p className="font-bold">
              Status:{" "}
              <span className="font-medium">
                {!isRequestCancelled && requestData?.refundRequest
                  ? showRefundState(requestData?.refundRequest?.refundRequestState)
                  : requestData?.state
                  ? renderState[requestData.state]
                  : null}
              </span>
            </p>
            {/* {isAuthorizedToEdit ? <img src={dropDownImg} className="h-2" /> : null} */}
          </div>
        ) : (
          <Tooltip
            options={[]}
            onSelect={() => {}}
            handleCloseTooltip={() => setShowDropDown(false)}
            imageComponent={
              <div
                className="flex items-center justify-between"
                onClick={() => {
                  if (isAuthorizedToEdit) {
                    setShowDropDown(true);
                  }
                  setDropDownWidth(document.getElementById("request-status-div")?.clientWidth || 0);
                }}>
                <p className="font-bold">
                  Status : <span className="font-medium">{requestData && requestData.state ? renderState[requestData.state] : null}</span>
                </p>
                {isAuthorizedToEdit ? <img src={dropDownImg} className="h-2" alt="" /> : null}
              </div>
            }
            additionalComponents={[
              <div
                key="wfnv"
                style={{ width: dropDownWidth, right: "-1.05rem" }}
                className="absolute right-0 bg-white w-full shadow-xl rounded-lg border border-docsigna-purple-light z-10">
                {requestData?.state === "pending-verification" ? (
                  <div className="block">
                    <p
                      onClick={() => handleStateChange(RequestStatesOptions["Pending-Verification"])}
                      className="block text-md font-normal hover:font-medium px-4 py-1.5 hover:bg-docsigna-purple-light cursor-pointer  border-gray-200">
                      Pending Verification
                    </p>
                  </div>
                ) : null}
                {showPendingPayment ? (
                  <div className="block">
                    <p
                      onClick={() => {
                        if (isStandardRequest) {
                          statusChangeFlag
                            ? handleStateChange(RequestStatesOptions["Pending-Payment"])
                            : toast?.openToast("Unable to update status while still pending verification", 2000, ToastVariants.Error);
                        } else {
                          handleStateChange(RequestStatesOptions["Pending-Payment"]);
                        }
                      }}
                      className="block text-md font-normal hover:font-medium px-4 py-1.5 hover:bg-docsigna-purple-light cursor-pointer  border-gray-200">
                      Pending Payment
                    </p>
                  </div>
                ) : null}
                <div className="block">
                  <p
                    onClick={() => {
                      if (isStandardRequest) {
                        statusChangeFlag
                          ? handleStateChange(RequestStatesOptions["In-Progress"])
                          : toast?.openToast("Unable to update status while still pending verification", 2000, ToastVariants.Error);
                      } else {
                        handleStateChange(RequestStatesOptions["In-Progress"]);
                      }
                    }}
                    className="block text-md font-normal hover:font-medium px-4 py-1.5 hover:bg-docsigna-purple-light cursor-pointer  border-gray-200">
                    In Progress
                  </p>
                </div>
                <div className="block">
                  <p
                    onClick={() => {
                      if (isStandardRequest) {
                        statusChangeFlag
                          ? handleStateChange(RequestStatesOptions["Waiting-for-Signature"])
                          : toast?.openToast("Unable to update status while still pending verification", 2000, ToastVariants.Error);
                      } else {
                        handleStateChange(RequestStatesOptions["Waiting-for-Signature"]);
                      }
                    }}
                    className="block text-md font-normal hover:font-medium px-4 py-1.5 hover:bg-docsigna-purple-light cursor-pointer  border-gray-200">
                    Waiting for Signature
                  </p>
                </div>
                <div className="block">
                  <p
                    onClick={() => {
                      if (isStandardRequest) {
                        statusChangeFlag
                          ? handleStateChange(RequestStatesOptions["Final-Review"])
                          : toast?.openToast("Unable to update status while still pending verification", 2000, ToastVariants.Error);
                      } else {
                        handleStateChange(RequestStatesOptions["Final-Review"]);
                      }
                    }}
                    className="block text-md font-normal hover:font-medium px-4 py-1.5 hover:bg-docsigna-purple-light cursor-pointer  border-gray-200">
                    Final Review
                  </p>
                </div>
                {showPaidAndSubmit ? (
                  requestData?.submitted ? (
                    requestData?.paymentComplete ? null : (
                      <div className="block">
                        <p
                          onClick={() => {
                            if (isStandardRequest) {
                              statusChangeFlag
                                ? handleStateChange(RequestStatesOptions["Pending-Payment"])
                                : toast?.openToast("Unable to update status while still pending verification", 2000, ToastVariants.Error);
                            } else {
                              handleStateChange(RequestStatesOptions["Pending-Payment"]);
                            }
                          }}
                          className="block text-md font-normal hover:font-medium px-4 py-1.5 hover:bg-docsigna-purple-light cursor-pointer  border-gray-200">
                          Pending Payment
                        </p>
                      </div>
                    )
                  ) : (
                    <div className="block">
                      <p
                        onClick={() => toast?.openToast("This request must be marked as submitted.", 2000, ToastVariants.Error)}
                        className="block text-md font-normal hover:font-medium px-4 py-1.5 hover:bg-docsigna-purple-light cursor-pointer opacity-70 ">
                        Pending Payment
                      </p>
                    </div>
                  )
                ) : null}
                {showComplete ? (
                  <div className="block">
                    <p
                      onClick={() => {
                        if (isStandardRequest) {
                          if (statusChangeFlag) {
                            setConfirmationOpen(true);
                            setConfirmationText("complete");
                          } else {
                            toast?.openToast("Unable to update status while still pending verification", 2000, ToastVariants.Error);
                          }
                        } else {
                          setConfirmationOpen(true);
                          setConfirmationText("complete");
                        }
                      }}
                      className="block text-md font-normal hover:font-medium px-4 py-1.5 hover:bg-docsigna-purple-light cursor-pointer ">
                      Complete
                    </p>
                  </div>
                ) : (
                  <div className="block">
                    <p
                      onClick={() =>
                        toast?.openToast(`This request must be marked as ${isOHIP ? "submitted" : "paid"} to complete`, 2000, ToastVariants.Error)
                      }
                      className="block text-md font-normal hover:font-medium px-4 py-1.5 hover:bg-docsigna-purple-light cursor-pointer opacity-70 ">
                      Complete
                    </p>
                  </div>
                )}
              </div>,
            ]}
            open={showDropDown}
          />
        )}
      </div>

      <div className="hidden md:block w-full border border-docsigna-pink rounded-lg mb-3">
        <div className="w-full bg-docsigna-pink-light rounded-lg rounded-b-none p-5">
          {" "}
          <p className="text-sm font-semibold mb-3">Verification Task List</p>
          <ul>
            <li
              className="text-sm mb-1 flex justify-start items-center cursor-pointer font-medium"
              onClick={() => {
                (isAuthorizedToEdit && readOnly) || isRequestCancelled ? null : openForm("VerifyPatient");
              }}>
              <div className="flex justify-center items-center mr-2 w-5 h-5 bg-docsigna-orange rounded-full">
                <img src={tickImg} className={`h-2 ${isVerified ? "" : "opacity-30"}`} alt="" />
              </div>
              Verify Patient Details
            </li>
            <li
              className="text-sm mb-1 flex justify-start items-center cursor-pointer font-medium"
              onClick={() => {
                isAuthorizedToEdit && !isArchived && !isRequestCancelled ? openForm("AssignService") : null;
              }}>
              <div className="flex justify-center items-center mr-2 w-5 h-5 bg-docsigna-orange rounded-full">
                <img src={tickImg} className={`h-2 ${serviceAssigned ? "" : "opacity-30"}`} alt="" />
              </div>
              Assign Service
            </li>
            <li
              className="text-sm mb-1 flex justify-start items-center cursor-pointer font-medium"
              onClick={() => {
                if (isAuthorizedToEdit && !isArchived && !isRequestCancelled) openForm("AssignRequest");
              }}>
              <div className="flex justify-center items-center mr-2 w-5 h-5 bg-docsigna-orange rounded-full">
                <img src={tickImg} className={`h-2 ${pcpAndStaffAssigne ? "" : "opacity-30"}`} alt="" />
              </div>
              Assign Staff & PCP
            </li>
          </ul>
        </div>

        <PaymentDetailSummaryBox officeId={officeId} requestId={requestId} discountType={requestData?.discountType} />

        {!isRequestCancelled && billedTo && billedTo?.length ? (
          <div className="w-full bg-white rounded-lg rounded-t-none py-3 px-4">
            {showPaidAndSubmit || isOHIP ? (
              <>
                <p className="text-sm mb-1">
                  <span className="font-semibold">{"Submitted Status :"}</span>{" "}
                  {requestData?.submitted ? `Sent to ${billedToOptions[billedTo]}` : "Pending"}
                  {!isOHIP && requestData?.submitted && !requestData?.paymentComplete && !isArchived ? undoButton() : null}
                  {isOHIP && requestData?.submitted && !isArchived ? undoButton() : null}
                </p>
                {!isOHIP ? (
                  <p className="text-sm mb-1">
                    <span className="font-semibold">{"Payment Status :"}</span> {requestData?.paymentComplete ? "Complete" : "Pending"}
                    {!isArchived && !isPatient && requestData?.paymentComplete && requestData?.paymentMethod === "external"
                      ? undoPaymentButton()
                      : null}
                  </p>
                ) : null}
              </>
            ) : (
              <p className="text-sm mb-1">
                <span className="font-semibold">{"Payment Status :"}</span> {requestData?.paymentComplete ? "Complete" : "Pending"}
                {!isArchived && requestData?.paymentComplete && requestData?.paymentMethod === "external" ? undoPaymentButton() : null}
              </p>
            )}
            <span className="text-sm">
              {readOnly ? null : (
                <>
                  {" "}
                  {!requestData?.paymentComplete && !requestData?.refunded && (isPatient || isDocnoteDebit) ? (
                    <span onClick={() => handleReminderEmail()} className="text-sm text-docsigna-blue-light font-medium cursor-pointer">
                      Send Reminder Email
                    </span>
                  ) : null}
                  {createInvoiceVisible && !isPatient && !isDocnoteDebit ? (
                    <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
                      <span
                        className={`text-sm text-docsigna-blue-light font-medium px-0 bg-transparent hover:bg-transparent cursor-pointer ${
                          isAllStepsCompleted ? "" : "opacity-30"
                        }`}
                        onClick={() => {
                          if (isAllStepsCompleted) {
                            handleFinalizeInvoice();
                          } else {
                            toast?.openToast("Unable to create invoice while still pending verification", 2000, ToastVariants.Error);
                          }
                        }}>
                        Create Invoice
                      </span>
                    </ProtectedComponent>
                  ) : null}{" "}
                </>
              )}
            </span>

            {showPaidAndSubmit ? (
              <>
                {isDocnoteDebit || requestData?.submitted ? null : (
                  <>
                    <br></br>
                    <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
                      <span
                        className={
                          requestData?.submitted
                            ? `text-sm text-docsigna-blue-light font-medium px-0 bg-transparent hover:bg-transparent cursor-text ${
                                requestData?.submitted ? "opacity-30" : ""
                              }`
                            : `text-sm text-docsigna-blue-light font-medium px-0 bg-transparent hover:bg-transparent cursor-pointer ${
                                invoiceCreated ? "" : "opacity-30"
                              }`
                        }
                        onClick={() => {
                          if (!requestData?.submitted) {
                            if (invoiceCreated) {
                              setConfirmationOpen(true);
                              setConfirmationText("submitted");
                            } else {
                              toast?.openToast(`Invoice must be created before marking this request as submitted.`, 2000, ToastVariants.Error);
                            }
                          }
                        }}>
                        {`Mark As ${"Submitted"}`}
                      </span>
                    </ProtectedComponent>
                  </>
                )}{" "}
                {requestData?.refunded || requestData?.paymentComplete ? null : (
                  <>
                    <br></br>
                    <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
                      <span
                        className={
                          requestData?.paymentComplete
                            ? `text-sm text-docsigna-blue-light font-medium px-0 bg-transparent hover:bg-transparent cursor-text ${
                                requestData?.paymentComplete ? "opacity-30" : ""
                              }`
                            : `text-sm text-docsigna-blue-light font-medium px-0 bg-transparent hover:bg-transparent cursor-pointer ${
                                requestData?.submitted ? "" : "opacity-30"
                              }`
                        }
                        onClick={() => {
                          if (!requestData?.paymentComplete) {
                            if (requestData?.submitted) {
                              if (additionalFeeTotal === 0 && [ServiceBilledTo.ServiceCanada, ServiceBilledTo.WSIB].includes(billedTo)) {
                                setShowAdjustedPaymentModal(PriceOverrideStates.PaymentOptions);
                              } else {
                                setConfirmationOpen(true);
                                setConfirmationText("paid");
                              }
                            } else {
                              toast?.openToast(`Request must be submitted before marking as paid.`, 2000, ToastVariants.Error);
                            }
                          }
                        }}>
                        {`Mark As ${"Paid"}`}
                      </span>
                    </ProtectedComponent>
                    <br></br>
                  </>
                )}
              </>
            ) : (isOHIP && !requestData?.submitted) || (showPaidButton && !isDocnoteDebit) || (isPatient && !verificatinTaskListCompleted) ? (
              <>
                <br></br>
                <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
                  <span
                    className={
                      isPatient && (requestData?.paymentComplete || requestData?.submitted || !verificatinTaskListCompleted)
                        ? `text-sm text-docsigna-blue-light font-medium px-0 bg-transparent hover:bg-transparent cursor-text ${
                            requestData?.submitted || !verificatinTaskListCompleted ? "opacity-30" : ""
                          }`
                        : `text-sm text-docsigna-blue-light font-medium px-0 bg-transparent hover:bg-transparent cursor-pointer ${
                            isOHIP ? (invoiceCreated ? "" : "opacity-30") : ""
                          }`
                    }
                    onClick={() => {
                      if (isOHIP) {
                        if (invoiceCreated) {
                          setConfirmationOpen(true);
                          setConfirmationText("submitted");
                        } else {
                          toast?.openToast("Invoice must be created before marking this request as submitted.", 2000, ToastVariants.Error);
                        }
                      } else {
                        if (verificatinTaskListCompleted && isPatient && (!requestData?.paymentComplete || !requestData?.submitted)) {
                          setConfirmationOpen(true);
                          setConfirmationText("paid");
                        } else if (!verificatinTaskListCompleted) {
                          toast?.openToast("Unable to pay while still pending verification", 2000, ToastVariants.Error);
                        }
                      }
                    }}>
                    {`Mark As ${isPatient ? "Paid" : "Submitted"}`}
                  </span>
                </ProtectedComponent>
                <br></br>
              </>
            ) : (
              <br></br>
            )}
            {(!isThirdParty || isPrescriptionRefill || isDocnoteDebit) &&
            !isArchived &&
            isAuthorizedToEdit &&
            (isPatient || isPrescriptionRefill || isDocnoteDebit) &&
            (showPaidButton || !verificatinTaskListCompleted) &&
            !requestData?.paymentComplete ? (
              <>
                <span
                  onClick={() => {
                    if (isPatient && !verificatinTaskListCompleted) {
                      toast?.openToast("Unable to pay while still pending verification", 2000, ToastVariants.Error);
                    } else {
                      dispatch(setOpenPaymentSidebar(true));
                    }
                  }}
                  className={` ${
                    !verificatinTaskListCompleted && isPatient
                      ? "text-sm text-docsigna-blue-light font-medium cursor-text opacity-30"
                      : `text-sm text-docsigna-blue-light font-medium cursor-pointer ${
                          requestData?.submitted || (!verificatinTaskListCompleted && isPatient) ? "opacity-30" : ""
                        }`
                  } `}>
                  Manual Credit Card Payment
                </span>
                <br></br>
              </>
            ) : null}
            {!isArchived && billedTo?.length && isPatient ? (
              <>
                <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
                  {invoiceCreate ? (
                    <span
                      className={`text-sm text-docsigna-blue-light font-medium px-0 bg-transparent hover:bg-transparent cursor-pointer opacity-30`}>
                      Create Receipt
                    </span>
                  ) : (
                    <span
                      className={`text-sm text-docsigna-blue-light font-medium px-0 bg-transparent hover:bg-transparent cursor-pointer ${
                        requestData?.paymentComplete ? "" : "opacity-30"
                      }
                        `}
                      onClick={() => {
                        if (requestData?.paymentComplete) {
                          downloadInvoiceAsPdf();
                        } else {
                          toast?.openToast("Payment should be completed to create receipt", 2000, ToastVariants.Error);
                        }
                      }}>
                      Create Receipt
                    </span>
                  )}
                </ProtectedComponent>
              </>
            ) : null}
          </div>
        ) : null}
        <div className="w-full bg-docsigna-pink-light rounded-b-none p-5">
          <p className="text-sm mb-1 flex justify-between">
            <span className="font-semibold">Recent Activity</span>
            <span
              onClick={() => (billedTo === ServiceBilledTo.Patient ? dispatch(setActiveIndex(2)) : dispatch(setActiveIndex(1)))}
              className="cursor-pointer">
              Show All
            </span>
          </p>
          <ul>
            {timeline?.data?.content?.slice(0, 3).map((ele, index) => {
              return (
                <li key={ele.requestTimeLineId}>
                  {index !== 0 ? <hr className="my-2 border-docsigna-blue-dark opacity-25" /> : null}
                  <div className="ml-6 relative">
                    <div
                      className={`absolute w-3 h-3 rounded-full mt-2.5 top-5 -left-6 z-10 ${
                        index === 0 ? "bg-docsigna-blue-light" : "bg-docsigna-blue-dark opacity-25"
                      }`}></div>
                    <time className="mb-1 text-sm font-medium leading-none text-docsigna-blue-light">
                      {moment(ele.createdDate).format("MMM DD, YYYY h:mm a")}
                    </time>
                    <h3 className="text-md font-medium truncate">
                      <span className={`break-all text-base text-sm ${index === 0 ? "font-bold" : null}`}>{ele.event}</span>
                    </h3>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
        <div className="block text-right">
          {readOnly || isRequestCancelled ? null : (
            <Button
              AdditionalClassNames={`text-sm cursor-pointer ${requestData?.paymentComplete && paymentDoneWithStripe ? "opacity-30" : ""}`}
              width={"fit"}
              onClickFunc={() => {
                if (!requestData?.paymentComplete) {
                  setConfirmationOpen(true);
                  setConfirmationText("cancel");
                } else {
                  if (paymentDoneWithStripe) {
                    toast?.openToast(`This request must be refunded to the patient before it can be cancelled.`, 2000, ToastVariants.Error);
                  } else {
                    setConfirmationOpen(true);
                    setConfirmationText("externalPayCancel");
                  }
                }
              }}
              text={"Cancel Request"}
              varient="Secondary"
            />
          )}
        </div>
      </ProtectedComponent>
      <ConfirmationDialog
        open={confirmationOpen}
        description={
          {
            complete: `Reminder: make sure all final documents have been sent to their recipients before completing this request.`,
            cancel: `Are you sure you want to cancel this request? This action cannot be undone.`,
            paid: `Are you sure you want to mark this request as paid?`,
            externalPayCancel: `Please confirm payment for this request has been refunded to the patient.`,
            default: `Are you sure you want to mark this request as submitted?`,
          }[confirmationText] || `Are you sure you want to mark this request as submitted?`
        }
        title={""}
        successButtonText={confirmationText === "complete" ? "Complete" : confirmationText === "externalPayCancel" ? "confirm" : "Yes"}
        handleSuccess={() => {
          handleConfirmationBox("yes");
        }}
        failureButtonText={"Cancel"}
        handleFailure={() => {
          handleConfirmationBox("Cancel");
        }}
      />

      <Modal open={modalopen} onClose={() => setModalopen(false)}>
        <div className="modal absolute z-10">
          <InvoiceForm
            onClose={() => {
              setModalopen(false);
              // onClickCancel();
            }}
          />
        </div>
      </Modal>
      <div className=" absolute -top-[100%]">
        <div id="invoice-patient-container-pdf" className="hidden">
          <InvoicePatientPdf />
        </div>
      </div>
      {/* Payment form sidebar */}
      <UserSideBar
        visible={openPaymentSidebar}
        LeftbuttonText="Cancel"
        RightbuttonText="Add"
        onClickOutside={() => (!isLoading ? dispatch(setOpenPaymentSidebar(!openPaymentSidebar)) : null)}
        title={"Payment Details"}>
        <PaymentForm isOffice setIsLoading={setIsLoading} />
      </UserSideBar>

      {/* Show Adjust Payment Received model */}
      <ConfirmationDialog
        open={showAdjustedPaymentModal !== PriceOverrideStates.None}
        title={""}
        description={adjustPaymentReceived()}
        handleSuccess={handleSuccess}
        successButtonText={
          showAdjustedPaymentModal === PriceOverrideStates.Confirmation
            ? "Create"
            : showAdjustedPaymentModal === PriceOverrideStates.Warning
            ? "Cancel  Request"
            : "Confirm"
        }
        failureButtonText={showAdjustedPaymentModal === PriceOverrideStates.Confirmation ? "No" : "Cancel"}
        actionButtonPosition="Center"
        hideActionButtons={showAdjustedPaymentModal !== PriceOverrideStates.PaymentOptions}
        canClickOutSide={showAdjustedPaymentModal === PriceOverrideStates.PaymentOptions}
        handleFailure={() => {
          if (setNewPrice && setNewPriceAmount) {
            setNewPrice("");
            setNewPriceAmount(0);
          }
          setShowAdjustedPaymentModal(PriceOverrideStates.None);
        }}
        loading={isLoading}
      />
      <ConfirmationDialog
        open={refundModel}
        title={""}
        description={paymentUndoReason()}
        handleSuccess={handleSubmitRefundReason}
        successButtonText={"Confirm"}
        failureButtonText={"Cancel"}
        canClickOutSide={undefined}
        handleFailure={closeRefundReasonModel}
        loading={isLoading}
      />
    </div>
  );
};

export default RequestDetailsSummaryBox;
