import { useSelector } from "react-redux";
import useBreadCrumbs from "../../hooks/useBreadCrumbs";
import { RootState } from "../../redux/store";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import { upperCase } from "lodash";
import { OfficeState } from "../../models/office.model";

const OfficeSectionsHeader = ({ showMeta = false, showStatus = false }: { showMeta?: boolean; showStatus?: boolean }) => {
  const { currentOffice } = useSelector((state: RootState) => state.office);

  const breadCrumbs = useBreadCrumbs();
  return (
    <>
      <BreadCrumbs items={breadCrumbs} />
      {showMeta ? (
        <>
          <h2 className="mb-2 heading">
            {upperCase(currentOffice?.name)}
            <span
              className={`py-2 mb-2 px-4 text-base text-center p-2.5 h-10 rounded-lg self-end font-bold ${
                currentOffice?.officeState === OfficeState.Published ? "" : "opacity-40"
              }`}>
              {showStatus ? (currentOffice?.officeState === OfficeState.Published ? "Published" : "Unpublished") : null}
            </span>
          </h2>
          <p className="font-medium mb-6 text-sm">{`${currentOffice?.street}, ${currentOffice?.city}, ${currentOffice?.state}, ${currentOffice?.zip}`}</p>
        </>
      ) : null}
    </>
  );
};

export default OfficeSectionsHeader;
