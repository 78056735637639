import { headersType } from "../../models";

export const headersInitialState: headersType[] = [
  {
    title: "Service",
    extraClasses: "w-full lg:w-240 pl-0 truncate",
    sortKey: "name",
    isClickable: true,
  },
  {
    title: "Description",
    extraClasses: "w-full hidden lg:block break-all",
    sortKey: "",
    isClickable: false,
  },
  {
    title: "Price",
    extraClasses: "w-160 hidden lg:block",
    sortKey: "price",
    isClickable: true,
  },
  {
    title: "Plan",
    extraClasses: "w-160 hidden lg:block",
    sortKey: "plan",
    isClickable: false,
  },
  {
    title: "Enable",
    extraClasses: "w-160 hidden lg:block",
    sortKey: "enabled",
    isClickable: true,
  },
  {
    title: "Featured",
    extraClasses: "w-full hidden lg:block",
    sortKey: "featured",
    isClickable: true,
  },
  {
    title: "",
    extraClasses: "w-40 ",
    sortKey: "",
    isClickable: false,
  },
];

export const headersDocTemplates: headersType[] = [
  {
    title: "Select All",
    extraClasses: "w-full hidden lg:flex lg:w-140 pl-0 ml-0",
    sortKey: "selectAll",
    isClickable: false,
    isCheckbox: true,
  },
  {
    title: "Service",
    extraClasses: "w-full lg:w-240 pl-0 md:pl-5 truncate",
    sortKey: "name",
    isClickable: true,
  },
  {
    title: "Description",
    extraClasses: "w-full hidden lg:block break-all",
    sortKey: "",
    isClickable: false,
  },
  {
    title: "Price",
    extraClasses: "w-160 hidden lg:block",
    sortKey: "price",
    isClickable: true,
  },
  {
    title: "",
    extraClasses: "w-40 ",
    sortKey: "",
    isClickable: false,
  },
];
