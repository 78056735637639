import React, { useState, useEffect, useContext } from "react";
import { AuthPermission } from "../../services/auth.service";
import ProtectedComponent from "../../components/ProtectedComponent/ProtectedComponent";
import {
  AddFee,
  BreadCrumbs,
  Button,
  Checkbox,
  ConfirmationDialog,
  Pagination,
  TableHeader,
  TableRow,
  Tabs,
  TextInput,
  UserSideBar,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { formatNumToDec, getSorting } from "../../utils";
import { getAllSystemFees, getOfficeFees, resetFeeTableSort, searchOfficeFees, searchSystemFees } from "../../redux/slices/FeeSlice";
import EditFee from "../../components/forms/EditFee/EditFee";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import ViewFee from "../../components/ViewFee/ViewFee";
import { headersDocTemplates, headersInitialState } from "./FeesData";
import { CircularProgress } from "@mui/material";
import { sortObj } from "../../redux/slices/OfficeSlice";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NotFound from "../../assets/images/no-fees.png";
import { useDebouncedCallback } from "use-debounce";

const FeeToast = "Fee created at the Docnote level cannot be edited or deleted within an office.";

const FeesScreen = ({ isAdminPage }: { isAdminPage: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const { feeList, sort, loading } = useSelector((state: RootState) => state.fees);
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const [showAddFeeSideBar, setShowAddFeeSideBar] = useState(false);
  const [editFeeFormVisible, setEditFeeFormVisible] = useState(false);
  const [viewFeeSidebarVisible, setViewFeeSidebarVisible] = useState(false);
  const [headers, setHeaders] = useState(headersInitialState);
  const [sortMethods, setSortMethods] = useState([true, false, false, false, false]);
  const [activeColumn, setActiveColumn] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const [officeId, setOfficeId] = useState(currentOffice?.officeId || "");
  const [firstRender, setFirstRender] = useState(true);
  const [tooltipStates, setTooltipStates] = useState<boolean[]>(new Array(feeList?.content ? feeList.content.length : 0).fill(false));
  const [selectedFee, setSelectedFee] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const feeSideBar = (val: boolean) => setShowAddFeeSideBar(val);
  const sortKeys = headers.map(head => head.sortKey);
  const extraClasses = headers.map(head => head.extraClasses);
  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeColumn === index ? (item ? headerIconAsc : headerIconDesc) : null));

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: newPage, rowsPerPage: sort.rowsPerPage }, searchTerm);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: 0, rowsPerPage: parseInt(event.target.value, 10) }, searchTerm);
  };

  const updateTable = (sortObj?: sortObj, searchText?: string) => {
    const pageNumToBeCalled = sortObj?.pageNumber ?? sort.pageNumber;
    const sizeToBeCalled = sortObj?.rowsPerPage ?? sort.rowsPerPage;
    const sortBy = sortObj?.sortedBy ?? sort.sortedBy;
    const searchT = searchText ?? "";
    if (searchT.length >= 2) {
      if (isAdminPage) {
        dispatch(
          searchSystemFees({
            page: pageNumToBeCalled,
            size: sizeToBeCalled,
            sortType: sortBy,
            searchTerm: searchT,
          })
        );
      } else {
        dispatch(
          searchOfficeFees({
            officeId: officeId,
            page: pageNumToBeCalled,
            size: sizeToBeCalled,
            sortType: sortBy,
            searchTerm: searchT,
          })
        );
      }
      return;
    }
    if (isAdminPage) {
      dispatch(
        getAllSystemFees({
          page: pageNumToBeCalled,
          size: sizeToBeCalled,
          sortType: sortBy,
          fullPageLoaderShown: true,
        })
      );
    } else {
      dispatch(
        getOfficeFees({
          officeId: officeId,
          page: pageNumToBeCalled,
          size: sizeToBeCalled,
          sortType: sortBy,
          fullPageLoaderShown: true,
        })
      );
    }
  };

  const onHeaderClick = (index: number) => {
    if (sortKeys[index] === "") return;
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setActiveColumn(index);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    updateTable({ sortedBy: sortMethodString, pageNumber: 0, rowsPerPage: sort.rowsPerPage }, searchTerm);
  };

  //const tooltipOptions = ["Edit Fee", "Enable Fee", "Delete Fee"];
  const tooltipOptions =
    currentUser?.permissions.includes(AuthPermission.Service) && activeIndex === 0
      ? isAdminPage
        ? ["Edit Fee"]
        : ["Edit Fee", "Copy Fee"]
      : // [ "Disable Service", "Delete Service"]
        ["View Fee"];
  const handleTooltipClick = (option: string, feeId: string) => {
    switch (option) {
      case "View Fee":
        setSelectedFee(feeId);
        setViewFeeSidebarVisible(true);
        break;
      case tooltipOptions[0]:
        if (!feeList?.content.find(f => f.feeId === feeId)?.officeId && !isAdminPage) {
          toast?.openToast(FeeToast, 4000);
        } else {
          setSelectedFee(feeId);
          setEditFeeFormVisible(true);
        }
        break;
      case tooltipOptions[2]:
        if (!feeList?.content.find(f => f.feeId === feeId)?.officeId && !isAdminPage) {
          toast?.openToast(FeeToast, 4000);
        } else {
          setConfirmationOpen(true);
        }
        break;
      default:
        toast?.openToast();
        break;
    }
    setTooltipStates([...tooltipStates].fill(false));
  };

  const debounced = useDebouncedCallback(value => {
    if (value && value.trim().length >= 2) {
      updateTable({ ...sort, pageNumber: 0 }, value.trim());
    }
  }, 1000);

  const handleSearchInput = (text: string) => {
    const input = text;
    const oldSearchTerm = searchTerm;
    setSearchTerm(input);
    debounced(input);
    if (oldSearchTerm !== "" && input.length <= 1) {
      updateTable(sort);
    }
  };

  const handleToolTipOpen = (index: number) => {
    setTooltipStates([...tooltipStates.slice(0, index).fill(false), true, ...tooltipStates.slice(index + 1).fill(false)]);
  };

  const handleToolTipClose = () => {
    setTooltipStates([...tooltipStates].fill(false));
  };

  const handleDeleteFee = () => {
    toast?.openToast();
    setConfirmationOpen(false);
    setSelectedFee(null);
  };

  const handleRowClick = (id: string) => {
    if (id) {
      setSelectedFee(id);
      if (!feeList?.content.find(f => f.feeId === id)?.officeId && !isAdminPage) {
        setViewFeeSidebarVisible(true);
      } else {
        if (currentUser?.permissions.includes(AuthPermission.Service)) {
          setEditFeeFormVisible(true);
        } else {
          setViewFeeSidebarVisible(true);
        }
      }
    }
  };

  const changeServiceTab = (index: number) => {
    setActiveIndex(index);
    if (index === 1) {
      setHeaders(headersDocTemplates);
    } else {
      setHeaders(headersInitialState);
    }
  };

  useEffect(() => {
    setTooltipStates(new Array(feeList?.content ? feeList.content.length : 0).fill(false));
  }, [feeList]);

  useEffect(() => {
    if (!firstRender) {
      updateTable();
    }
  }, [officeId, firstRender]);

  useEffect(() => {
    if (currentOffice && currentOffice.officeId) setOfficeId(currentOffice?.officeId);
  }, [currentOffice]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      dispatch(resetFeeTableSort());
    }
  }, []);

  return (
    <main>
      {confirmationOpen ? (
        <ConfirmationDialog
          open={confirmationOpen}
          description={`This will permanently delete this fee, proceed?`}
          title={""}
          successButtonText={"Yes"}
          handleSuccess={handleDeleteFee}
          failureButtonText={"No"}
          handleFailure={() => setConfirmationOpen(false)}
        />
      ) : null}
      <div className="container mx-auto p-8">
        {!isAdminPage ? <OfficeSectionsHeader showMeta={true} /> : <BreadCrumbs items={[{ key: "#", title: "Fees" }]} />}
        <div className="block lg:flex justify-between items-center flex-wrap mb-6">
          <div className="w-full lg:w-1/2 mb-5 lg:mb-0">
            <h2 className="heading uppercase">Fees</h2>
          </div>
          <div className="block md:flex lg:justify-end w-full lg:w-1/2">
            <div className="flex items-center">
              <div className="relative w-full">
                <TextInput
                  onChangeFunc={e => {
                    handleSearchInput(e.currentTarget.value);
                  }}
                  value={searchTerm}
                  placeholder="Search fees"
                  extraInputClass="w-full"
                  isSearchInput={true}
                />
              </div>
            </div>
            {activeIndex === 0 ? (
              <ProtectedComponent requiredPermission={[AuthPermission.Service]}>
                <div className="block md:flex md:justify-end items-center ml-0 md:ml-5">
                  <span className="md:mr-5 font-medium text-center hidden md:inline-block">or</span>
                  <Button text="Create Fee" onClickFunc={() => feeSideBar(true)} AdditionalClassNames="whitespace-nowrap w-auto mt-3 md:mt-0" />
                </div>
              </ProtectedComponent>
            ) : null}
            {activeIndex === 1 ? (
              <div className="relative w-auto ml-5 flex items-center">
                <Button AdditionalClassNames="w-auto" text="Copy Selected Fees To Office" onClickFunc={() => toast?.openToast()} />
              </div>
            ) : null}
          </div>
          <p className="text-base font-medium mt-5">
            Manage and customize your fees for common tasks. Use the Docnote template, based on best practices, to easily copy suggested fees to your
            office.
          </p>
        </div>

        <div>
          {!loading ? (
            <div className="block">
              {!isAdminPage ? (
                <div className="mb-5">
                  <Tabs
                    components={[]}
                    options={["Office Fees", "Docnote Templates"]}
                    activeTab={activeIndex}
                    onClickTab={changeServiceTab}
                    fromOffice={false}
                    showBottomBorder={false}
                  />
                </div>
              ) : null}
              {feeList?.content && feeList?.content?.length > 0 ? (
                <>
                  <div className="flex justify-between items-center border-b border-docsigna-blue-light">
                    {headers.map((head, index: number) => (
                      <TableHeader
                        title={head.title}
                        index={index}
                        key={index}
                        sortIcon={sortIcons[index]}
                        isClickable={head.isClickable}
                        handleSort={onHeaderClick}
                        extraClassesHeader={extraClasses[index]}
                        isCheckbox={head?.isCheckbox}
                        selectAll={() => toast?.openToast()}
                      />
                    ))}
                  </div>

                  {feeList.content.map((rowData, index) => {
                    const sortedData: {
                      selectAll?: any;
                      fee: string;
                      description: any;
                      price: string;
                      status?: any;
                    } = {
                      selectAll: <div></div>,
                      fee: rowData.name,
                      description: <div className="flex justify-between items-center">{rowData.description ? rowData.description : ""}</div>,
                      price: "$" + formatNumToDec(rowData.price / 100, 2),
                      status: !isAdminPage ? (
                        <div className="ml-3.5" onClick={e => e.stopPropagation()}>
                          <Checkbox label="" checked={rowData.enabled} onChange={() => toast?.openToast()} />
                        </div>
                      ) : rowData.enabled ? (
                        "Enabled"
                      ) : (
                        "Disabled"
                      ),
                    };

                    if (activeIndex === 1) {
                      //const copiedItem = selectedServiceIdsForCopy.find(item => item.serviceId === rowData?.serviceId);
                      delete sortedData.status;
                      sortedData.selectAll = (
                        <div className="ml-0" onClick={e => e.stopPropagation()}>
                          <Checkbox label="" onChange={() => toast?.openToast()} />
                        </div>
                      );
                    } else {
                      delete sortedData.selectAll;
                    }

                    return (
                      <TableRow
                        data={Object.values(sortedData)}
                        key={index}
                        id={rowData.feeId}
                        extraClassesRow={extraClasses}
                        tooltipOptions={tooltipOptions}
                        handleTooltipClick={(option, id) => handleTooltipClick(option, id)}
                        handleCloseTooltip={handleToolTipClose}
                        handleOpenTooltip={() => handleToolTipOpen(index)}
                        tooltipVisible={tooltipStates[index]}
                        onRowClick={id => (id ? handleRowClick(id) : null)}
                        disabledOptions={!rowData.officeId && !isAdminPage ? [tooltipOptions[1], tooltipOptions[2]] : []}
                      />
                    );
                  })}
                  <Pagination
                    colSpan={10}
                    count={feeList?.content ? feeList.totalElements : 0}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    page={sort.pageNumber}
                    rowsPerPage={sort.rowsPerPage}
                  />
                </>
              ) : (
                <DataNotFound image={NotFound} text="No fees found" />
              )}
            </div>
          ) : (
            <CircularProgress size={40} color={"info"} style={{ marginLeft: "50%", marginTop: "10%" }} />
          )}

          <UserSideBar
            visible={showAddFeeSideBar}
            LeftbuttonText="Cancel"
            RightbuttonText="Add"
            onCancelClick={() => feeSideBar(false)}
            onInviteClick={() => feeSideBar(false)}
            onClickOutside={() => (!isLoading ? feeSideBar(false) : null)}
            title="Add Fee">
            <AddFee
              onCancelClick={val => {
                feeSideBar(false);
                if (val) {
                  updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, searchTerm);
                }
              }}
              isAdminPage={isAdminPage}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          </UserSideBar>
          <UserSideBar
            visible={editFeeFormVisible && selectedFee !== null}
            LeftbuttonText="Cancel"
            RightbuttonText="Add"
            onCancelClick={() => setEditFeeFormVisible(false)}
            onInviteClick={() => setEditFeeFormVisible(false)}
            onClickOutside={() => (!isLoading ? setEditFeeFormVisible(false) : null)}
            title="Edit Fee">
            <EditFee
              feeId={selectedFee || ""}
              disabled={!currentUser?.permissions.includes(AuthPermission.Service)}
              onCancelClick={val => {
                setEditFeeFormVisible(false);
                if (val) {
                  updateTable({ sortedBy: sort.sortedBy, pageNumber: sort.pageNumber, rowsPerPage: sort.rowsPerPage }, searchTerm);
                }
              }}
              isAdminPage={isAdminPage}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          </UserSideBar>
          <UserSideBar
            visible={viewFeeSidebarVisible && selectedFee !== null}
            onClickOutside={() => setViewFeeSidebarVisible(false)}
            title="View Fee">
            <ViewFee feeId={selectedFee || ""} onCancelClick={() => setViewFeeSidebarVisible(false)} />
          </UserSideBar>
        </div>
      </div>
    </main>
  );
};

export default FeesScreen;
