import React, { useContext, useEffect, useState } from "react";
import { getUserLevel, validateEmail } from "../../../utils";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import TextInput from "../../TextInput/TextInput";
import { AuthPermission } from "../../../services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { getJobTitles, inviteUser, resetState } from "../../../redux/slices/UserSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { ApiErrorCode } from "../../../models/api-error.model";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { inviteOfficeUser, listOfficeDataForSelectList as listOffice, resetInviteUserApiSuccess } from "../../../redux/slices/OfficeSlice";
import { UserType } from "../../../services/user.service";
import { ToastVariants, UserTypeLevel } from "../../../models";
import SelectNew from "../../Select/SelectNew";

const roleOptionsInitialState = [
  {
    title: "System Superuser",
    key: UserType.SystemAdmin,
    selected: false,
    dependents: [UserType.OfficeAdmin, UserType.Accounting, UserType.User],
    disabled: false,
    level: UserTypeLevel[UserType.SystemAdmin],
  },
  {
    title: "Office Superuser",
    key: UserType.OfficeAdmin,
    selected: false,
    dependents: [],
    disabled: false,
    level: UserTypeLevel[UserType.OfficeAdmin],
  },
  {
    title: "Accounting",
    key: UserType.Accounting,
    selected: false,
    dependents: [],
    disabled: false,
    level: UserTypeLevel[UserType.Accounting],
  },
  {
    title: "User",
    key: UserType.User,
    selected: true,
    dependents: [],
    disabled: false,
    level: UserTypeLevel[UserType.User],
  },
];

const checkBoxOptions = [
  {
    title: "Edit Office",
    key: AuthPermission.Office,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Users",
    key: AuthPermission.User,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Services",
    key: AuthPermission.Service,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Sign Forms",
    key: AuthPermission.Signing,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "View Reports",
    key: AuthPermission.Report,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "View/Edit Finance",
    key: AuthPermission.Finance,
    selected: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Requests",
    key: AuthPermission.Request,
    selected: false,
    dependents: [],
    disabled: false,
  },
];

interface InviteUserSideBarContentPropType {
  errorInitiated?: boolean;
  onCancelClick: () => void;
  onSuccess: () => void;
  officeId?: string;
  isInsideOffice?: boolean;
  setIsLoading: (val: boolean) => void;
  isLoading: boolean;
}
const InviteUserSideBarContent = ({
  onCancelClick,
  onSuccess,
  officeId,
  isInsideOffice = false,
  setIsLoading,
  isLoading,
}: InviteUserSideBarContentPropType) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const { error, loading, errorType, apiSucess, jobTitles } = useSelector((state: RootState) => state.user);
  const { errorType: officeErrorType, inviteUserApiSuccess, error: officeSliceError } = useSelector((state: RootState) => state.office);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const officeListtemp = useSelector((state: RootState) => state.office.officeList);
  const officeList = currentUser?.userType === UserType.SystemAdmin ? officeListtemp : { data: { content: currentUser?.offices } };
  const [email, setemail] = useState<string>("");
  const [jobTitle, setJobTitle] = useState("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [jobTitleError, setJobTitleError] = useState<boolean>(!!officeId ?? false);
  const [errorInitiated, setErrorInitiated] = useState<boolean>(false);
  const [firstMount, setFirstMount] = useState<boolean>(true);
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>(officeId ?? "");
  const [validRoleSelected, setValidRoleSelected] = useState<boolean>(false);
  const [primaryCareProvider, setPrimaryCareProvider] = useState<boolean>(false);
  const [legal, setLegal] = useState<boolean>(false);

  const [permissionCheckBoxes, setPermissionCheckBoxes] = useState(
    checkBoxOptions.map(opt => ({ ...opt, disabled: !currentUser?.permissions.includes(opt.key) }))
  );
  const [roleOptions, setRoleOptions] = useState(
    roleOptionsInitialState.map(usr => ({ ...usr, disabled: usr.level < getUserLevel(currentUser?.userType as UserType) }))
  );

  const [isValidOfficeSelected, setIsValidOfficeSelected] = useState(false);
  const [officeRequired, setOfficeRequired] = useState(true);

  const onCheckBoxChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const checks = [...permissionCheckBoxes];
    checks[index].selected = !checks[index].selected;

    if (event.currentTarget.name === "Edit Office" && event.currentTarget.checked) {
      setSelectedOfficeId("");
    }
    setPermissionCheckBoxes(checks);
  };

  const handleChange = (id: string, value: string) => {
    switch (id) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setemail(value);
        break;
      case "jobTitle":
        setJobTitle(value);
        setJobTitleError(false);
        break;
      default:
        break;
    }
  };

  const onInviteClick = async () => {
    setErrorInitiated(true);

    if (!roleOptions[0].selected && !isValidOfficeSelected) return;
    const hasJobTitlt = roleOptions[0].selected && !selectedOfficeId ? true : !!jobTitle;
    if (validateEmail(email) && !(firstName === "" || firstName === null) && !(lastName === "" || lastName === null) && hasJobTitlt) {
      const filteredSelectedPermissions: AuthPermission[] = permissionCheckBoxes.filter(value => value.selected).map(value => value.key);

      const userType = roleOptions.find(role => role.selected)?.key;
      if (legal === true) {
        filteredSelectedPermissions.push(AuthPermission.Legal);
      }
      if (userType === UserType.SystemAdmin) {
        filteredSelectedPermissions.push(AuthPermission.Admin);
      }
      setIsLoading(true);
      if (isInsideOffice) {
        if (selectedOfficeId !== "") {
          await dispatch(
            inviteOfficeUser({
              firstName,
              lastName,
              email,
              selectedPermissions: filteredSelectedPermissions,
              officeId: selectedOfficeId,
              userType: userType,
              primaryCareProvider: primaryCareProvider,
              jobTitleId: jobTitle,
            })
          );
        } else {
          await dispatch(
            inviteOfficeUser({
              firstName,
              lastName,
              email,
              selectedPermissions: filteredSelectedPermissions,
              userType: userType,
              primaryCareProvider: primaryCareProvider,
              jobTitleId: jobTitle,
            })
          );
        }
      } else {
        if (selectedOfficeId !== "") {
          await dispatch(
            inviteUser({
              firstName,
              lastName,
              email,
              selectedPermissions: filteredSelectedPermissions,
              officeId: selectedOfficeId,
              userType: userType,
              primaryCareProvider: primaryCareProvider,
              jobTitleId: jobTitle,
            })
          );
        } else {
          await dispatch(
            inviteUser({
              firstName,
              lastName,
              email,
              selectedPermissions: filteredSelectedPermissions,
              userType: userType,
              primaryCareProvider: primaryCareProvider,
              jobTitleId: jobTitle,
            })
          );
        }
      }
      setIsLoading(false);
    }
  };

  const onRoleOptionSelect = (index: number) => {
    const tempRoleOption = [...roleOptions];

    if (tempRoleOption[index].key === UserType.SystemAdmin) {
      setOfficeRequired(false);
      if (!isInsideOffice) {
        setSelectedOfficeId("");
      }
      setJobTitle("");
      setJobTitleError(isInsideOffice);
    } else {
      setOfficeRequired(true);
    }

    tempRoleOption.forEach((v, i, a) => {
      if (i === index) {
        a[i].selected = !v.selected;
      } else {
        a[i].selected = false;
      }
    });

    if (tempRoleOption[index].key === UserType.SystemAdmin && tempRoleOption[index].selected) {
      setIsValidOfficeSelected(true);
    } else {
      if (selectedOfficeId === "") {
        setIsValidOfficeSelected(false);
      }
    }

    if (tempRoleOption[index].key === UserType.SystemAdmin || tempRoleOption[index].key === UserType.OfficeAdmin) {
      const tempPermissions = [...permissionCheckBoxes];
      tempPermissions.forEach((value, i, array) => {
        array[i].disabled = tempRoleOption[index].selected;
        array[i].selected = tempRoleOption[index].selected;
      });
      setPermissionCheckBoxes(tempPermissions);
    }

    if (tempRoleOption[index].key === UserType.Accounting) {
      const tempPermissions = [...permissionCheckBoxes];
      tempPermissions.forEach((value, i, array) => {
        if (value.key === AuthPermission.Report || value.key === AuthPermission.Finance) {
          array[i].disabled = tempRoleOption[index].selected;
          array[i].selected = tempRoleOption[index].selected;
        } else {
          array[i].disabled = false;
          array[i].selected = false;
        }
      });
      setPermissionCheckBoxes(tempPermissions);
    }
    if (tempRoleOption[index].key === UserType.User) {
      const tempPermissions = [...permissionCheckBoxes];
      tempPermissions.forEach((value, i, array) => {
        array[i].disabled = false;
        array[i].selected = false;
      });
      setPermissionCheckBoxes(tempPermissions);
    }
    setRoleOptions(tempRoleOption);
  };

  const onPrimaryProviderChange = () => {
    setPrimaryCareProvider(!primaryCareProvider);
  };

  const handleOfficeChange = (id: string) => {
    setSelectedOfficeId(id);
    if (roleOptions[0].selected && id && !jobTitle) {
      setJobTitleError(true);
    } else if (roleOptions[0].selected && !id && !jobTitle) {
      setJobTitleError(false);
    } else if (id && !jobTitle) {
      setJobTitleError(true);
    }
  };

  useEffect(() => {
    if (errorInitiated) {
      setEmailError(!validateEmail(email));
      setFirstNameError(firstName === "" || firstName === null);
      setLastNameError(lastName === "" || lastName === null);
    }
  }, [firstName, lastName, email, errorInitiated, permissionCheckBoxes]);

  useEffect(() => {
    if (!firstMount) {
      if (error || officeSliceError) {
        if (errorType === ApiErrorCode.EXISTS || officeErrorType === ApiErrorCode.EXISTS) {
          toast?.openToast("A user with this email address already exists", 2000, ToastVariants.Error);
        } else {
          toast?.openToast("Unable to invite user, please try again", 2000, ToastVariants.Error);
        }
      }
    } else {
      setFirstMount(false);
    }
  }, [error, errorType, loading, officeSliceError, officeErrorType]);

  useEffect(() => {
    if (apiSucess || inviteUserApiSuccess) {
      dispatch(resetState());
      dispatch(resetInviteUserApiSuccess());
      onCancelClick();
      toast?.openToast("User invitation sent");
      onSuccess();
    }
  }, [apiSucess, inviteUserApiSuccess]);

  useEffect(() => {
    setValidRoleSelected(roleOptions.some(v => v.selected));
  }, [roleOptions]);

  useEffect(() => {
    setIsValidOfficeSelected(selectedOfficeId !== "");
  }, [selectedOfficeId]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.userType === UserType.SystemAdmin) {
        dispatch(listOffice({ page: 0, sortType: "name,asc", size: 100 }));
      }
      dispatch(getJobTitles());
    }
  }, []);

  return (
    <>
      <p className="text-base px-8 pb-5 font-medium">
        Provide the required information to invite the user to Docnote. The user will receive a link to create their account and password.
      </p>

      <div className="px-8">
        <div className="py-2 pt-0">
          <TextInput
            onChangeFunc={e => handleChange("firstName", e.currentTarget.value)}
            label="First Name"
            placeholder="Enter first name"
            isRequired={true}
            errorMsg={"Please enter your first name"}
            value={firstName}
            isError={firstNameError}
            onBlur={e => handleChange("firstName", e.target.value.trim())}
            isDarkBg={true}
          />
        </div>
        <div className="py-2">
          <TextInput
            onChangeFunc={e => handleChange("lastName", e.currentTarget.value)}
            label="Last Name"
            placeholder="Enter last name"
            isRequired={true}
            errorMsg={"Please enter your last name"}
            value={lastName}
            isError={lastNameError}
            onBlur={e => handleChange("lastName", e.target.value.trim())}
            isDarkBg={true}
          />
        </div>
        <div className="py-2">
          <TextInput
            onChangeFunc={e => handleChange("email", e.currentTarget.value)}
            label="Email"
            placeholder="Enter email address"
            isRequired={true}
            errorMsg={"Please enter a valid email address"}
            value={email}
            isError={emailError}
            onBlur={e => handleChange("email", e.target.value.trim())}
            isDarkBg={true}
          />
        </div>
        <div className="py-2">
          <label className="text-sm font-medium text-gray-700 block w-full mb-1">
            Office {officeRequired ? <span className="text-red-500">*</span> : null}
          </label>
          <select
            value={selectedOfficeId}
            onChange={e => handleOfficeChange(e.currentTarget.value)}
            className="general-select w-full bg-docsigna-pink-light">
            {officeId ? (
              <option value={officeId}>{officeList?.data?.content?.find(item => item.officeId === officeId)?.name}</option>
            ) : (
              <>
                <option value="">Select Office</option>
                {officeList?.data?.content?.map(item => {
                  return (
                    <option value={item?.officeId} key={item.officeId}>
                      {item?.name}
                    </option>
                  );
                })}
              </>
            )}
          </select>
          {!isValidOfficeSelected && officeRequired && errorInitiated ? <p style={{ color: "#EE1818" }}>Please select an office.</p> : null}
        </div>
        {officeRequired || (roleOptions[0].selected && selectedOfficeId) ? (
          <div className="py-2">
            <SelectNew
              title="Job Title"
              required={true}
              value={jobTitle}
              placeHolder="Select job title"
              onSelect={e => handleChange("jobTitle", e.target.value)}
              options={jobTitles.map(item => {
                return { label: item.name, value: item.jobTitleId };
              })}
              errorMsg={
                errorInitiated && jobTitleError && (officeRequired || (roleOptions[0].selected && selectedOfficeId)) ? "Please select job title" : ""
              }
              isDarkBg
            />
          </div>
        ) : null}
      </div>

      <div className="px-8 py-2">
        <label className="text-sm font-bold text-gray-700 block w-full mb-1">
          Role<span className="text-red-500">*</span>
        </label>
        {roleOptions.map((role, index) => {
          return (isInsideOffice && role.key !== UserType.SystemAdmin) || !isInsideOffice ? (
            <label key={role.key} className="block w-full mb-1 relative">
              <input
                type={"radio"}
                checked={role.selected}
                disabled={role.disabled}
                style={role.disabled ? { backgroundColor: "rgb(156 163 175)", cursor: "not-allowed" } : {}}
                onChange={() => onRoleOptionSelect(index)}
                className="general-radio"
              />
              <span className="text-base ml-2">{role.title}</span>
            </label>
          ) : null;
        })}
        {!validRoleSelected && errorInitiated ? <p style={{ color: "#EE1818" }}>Please select a role.</p> : null}
      </div>
      <div className="px-8 py-2">
        <label className="text-sm font-bold text-gray-700 block w-full mb-1">Permissions</label>
        {permissionCheckBoxes.map((data, index) => {
          const disabled = data.disabled;
          const checked = data.selected;
          return (
            <Checkbox
              disabled={disabled}
              key={index}
              label={data.title}
              name={data.key}
              checked={checked}
              onChange={e => (!disabled ? onCheckBoxChange(index, e) : null)}
              isDarkBg={true}
            />
          );
        })}
        <Checkbox
          disabled={false}
          label={"Legal"}
          // name={data.key}
          checked={legal}
          onChange={() => setLegal(!legal)}
          isDarkBg={true}
        />
      </div>
      <div className="px-8 py-2">
        <Checkbox label="Primary Care Provider" checked={primaryCareProvider} onChange={onPrimaryProviderChange} isDarkBg={true} />
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onCancelClick()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
            disabled={isLoading}
          />
          <div>
            <Button
              onClickFunc={() => onInviteClick()}
              disabled={
                (emailError || firstNameError || lastNameError || jobTitleError || (officeRequired && !isValidOfficeSelected) || isLoading) &&
                errorInitiated
              }
              AdditionalClassNames="pointer px-5"
              text="Invite"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteUserSideBarContent;
