/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "..";
import avatar from "../../assets/images/avatar.png";
import { AppDispatch, resetState, RootState } from "../../redux/store";
import { useLocation } from "react-router-dom";
import { AuthPermission } from "../../services/auth.service";
import useHomeRoute from "../../hooks/useHomeRoute";
import { UserType } from "../../services/user.service";
import { UserSideBar, EditProfile } from "../";
import { hideLoginInHeaderPaths } from "../../navigation";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpOutline from "@mui/icons-material/HelpOutline";
import Logo from "../../assets/images/logo.png";
import EditPatientProfile from "../forms/EditPatientProfile/EditPatientProfile";
import Notifications from "../Notifications/Notifications";
import { useDispatch } from "react-redux";
import { getUserNotificationCounts, getUserNotifications, setShowNotifications } from "../../redux/slices/NotificationSlice";
import { SESSION_STORAGE_NOTIFICATION_TAB } from "../../utils/constants";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import Support from "../../screens/Guest/Support";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import EditAccountSetting from "../forms/EditAccountSetting/EditAccountSetting";
import ViewProfile from "../ViewProfile/ViewProfile";
// import { setShowCurrentSubscription } from "../../redux/slices/PatientSlice";

export interface NavListType {
  id: string;
  title: string;
  key: string;
  permissions: AuthPermission[];
}

const HeaderNew = ({ showLeftMenu }: { showLeftMenu?: boolean }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.currentUser.currentUser);
  const { count, showNotifications } = useSelector((state: RootState) => state.notifications);
  const location = useLocation();
  const { pathname } = location;
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
  const [profileVisible, setProfileVisible] = useState(false);
  const [editProfileVisible, setEditProfileVisible] = useState(false);
  const [editAccountSetting, setEditAccountSetting] = useState(false);
  const [hideLoginOpt, setHideLoginOpt] = useState(false);
  const [patientUser, setPatientUser] = useState<null | any>(null);
  const [userOfficeId, setUserOfficeId] = useState<string>("");
  const [openSupport, setOpenSupport] = useState(false);
  const { homeLink } = useHomeRoute();

  let isOfficeUser = "";
  if (user?.userType !== UserType.SystemAdmin && user?.userType !== UserType.Patient && user?.offices?.length && user?.offices[0]?.officeId) {
    isOfficeUser = user?.offices[0].officeId;
  }
  let currentOfficeId = pathname.split("/")[2] || "temp";

  const logout = () => {
    authContext?.signout();
    sessionStorage.clear();
    localStorage.clear();
    dispatch(resetState());
  };

  const handleMenuOptionClick = (index: number) => {
    setOptionsVisible(false);
    switch (user?.userType) {
      case UserType.Patient:
        switch (index) {
          case 0:
            navigate(`patients/${user.userId}/dashboard/profile`);
            break;
          case 1:
            //dispatch(setShowCurrentSubscription(true));
            navigate(`patients/${user.userId}/dashboard/account-settings`);
            break;
          case 2:
            logout();
            break;
          default:
            break;
        }
        return;
      default:
        switch (index) {
          case 0:
            setEditProfileVisible(true);
            break;
          case 1:
            setEditAccountSetting(true);
            break;
          case 2:
            logout();
            break;
          default:
            break;
        }
        return;
    }
  };

  const handleToolTipOpen = () => {
    setOptionsVisible(true);
    dispatch(setShowNotifications(false));
  };

  const handleToolTipClose = () => {
    setOptionsVisible(false);
  };

  useEffect(() => {
    if (user !== null) {
      if (user?.offices !== undefined && user.offices.length > 0) {
        currentOfficeId = user.offices[0].officeId ? user.offices[0].officeId : "";
        setUserOfficeId(currentOfficeId);
      }
      if (user?.userId) {
        dispatch(getUserNotificationCounts({ userId: user?.userId }));
        dispatch(getUserNotifications({ userId: user?.userId }));
      }
    }
  }, [user]);

  useEffect(() => {
    if (hideLoginInHeaderPaths.includes(location.pathname)) {
      setHideLoginOpt(true);
    } else {
      setHideLoginOpt(false);
    }
    window.addEventListener("resize", () => {
      if (window.outerWidth > 1023) {
        document.getElementById("mobile-nav")?.classList.add("hidden");
      }
    });
  }, [location]);

  const selectedTab = sessionStorage.getItem(SESSION_STORAGE_NOTIFICATION_TAB);

  const handleCloseSupport = (val: boolean) => {
    setOpenSupport(val);
  };

  const profileTooltipOptions = ["Profile", "Account Settings", "Sign Out"];

  return (
    <header className={`relative py-8 ${showLeftMenu ? "pl-[60px]" : ""}`}>
      <div className="">
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center">
            <Link to={homeLink}>
              <img src={Logo} alt="" className="logo" />
            </Link>
          </div>
          {!hideLoginOpt ? (
            <div className="relative flex justify-between items-center">
              <div className="mr-3 relative cursor-pointer">
                {isOfficeUser ? <HelpOutline htmlColor="#ffffff" style={{ fontSize: "24px" }} onClick={() => setOpenSupport(true)} /> : null}
                {openSupport ? (
                  <ConfirmationDialog
                    maxWidth="lg"
                    successButtonText=""
                    failureButtonText=""
                    open={openSupport}
                    description={<Support officeId={isOfficeUser} />}
                    handleSuccess={() => {}}
                    handleFailure={() => handleCloseSupport(false)}
                    showCrossIcon={true}
                  />
                ) : null}
              </div>
              {user && authContext?.isAuthenticated ? (
                <>
                  {userOfficeId ? (
                    <div className="mr-3 relative cursor-pointer">
                      <NotificationsNoneIcon
                        htmlColor="#ffffff"
                        style={{ fontSize: "24px" }}
                        onClick={() => dispatch(setShowNotifications(!showNotifications))}
                      />
                      {count.unread ? (
                        <div className="absolute w-2 h-2 font-bold text-white text-center rounded-2xl flex justify-center items-center bg-docsigna-orange top-2 right-0.5" />
                      ) : null}
                      {showNotifications && userOfficeId ? (
                        <Notifications
                          setShowNotifications={val => dispatch(setShowNotifications(val))}
                          userOfficeId={userOfficeId}
                          selectedTab={selectedTab ? (selectedTab === "yes" ? true : false) : true}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  <Tooltip
                    open={optionsVisible}
                    handleCloseTooltip={() => handleToolTipClose()}
                    handleOpenTooltip={() => handleToolTipOpen()}
                    imageComponent={
                      <div className="flex relative cursor-pointer items-center">
                        <span className="text-xl font-medium mr-2 text-white hidden sm:block">{`${user?.firstName ? user?.firstName : ""} ${
                          user?.lastName ? user?.lastName : ""
                        }`}</span>
                        <img src={avatar} alt="avatar" className="w-5 h-5" />
                      </div>
                    }
                    onSelect={(_type, _id, index = -1) => handleMenuOptionClick(index)}
                    options={profileTooltipOptions}
                  />
                </>
              ) : !pathname.includes("/terms-and-conditions") && !pathname.includes("/privacy-policy") ? (
                !pathname.match("/") ? (
                  <Link to="login" className="flex">
                    <span className="text-base font-medium mr-2">Log in</span>
                    <img src={avatar} alt="avatar" className="w-5 h-5" />
                  </Link>
                ) : (
                  <div className="header-right">
                    <nav>
                      <ul>
                        <li>
                          {!["/login", "/login/2fa", "/onboarding/verify-email", "/onboarding/submit-request", "/onboarding/verify-phone"].includes(
                            pathname
                          ) ? (
                            <Link to="login">
                              <Button text="Login" onClickFunc={() => null} AdditionalClassNames="hover:bg-docsinga-orange" />
                            </Link>
                          ) : null}
                        </li>
                      </ul>
                    </nav>
                  </div>
                )
              ) : (
                <Link to={`${pathname.includes("/terms-and-conditions") ? "privacy-policy" : "terms-and-conditions"}`} className="flex">
                  <span className="text-base font-medium mr-2">
                    {pathname.includes("/terms-and-conditions") ? "Privacy Policy" : "Terms and Conditions"}
                  </span>
                </Link>
              )}
            </div>
          ) : (
            <span className="text-base font-medium mr-2 m-3"></span>
          )}
        </div>
      </div>
      <UserSideBar onClickOutside={() => undefined} title={`${user?.firstName} ${user?.lastName}`} visible={profileVisible}>
        <ViewProfile onCancelClick={() => setProfileVisible(false)} />
      </UserSideBar>
      <UserSideBar onClickOutside={() => undefined} title={`Profile`} visible={editProfileVisible}>
        <EditProfile onCancelClick={() => setEditProfileVisible(false)} />
      </UserSideBar>
      <UserSideBar onClickOutside={() => undefined} title={`Account Settings`} visible={editAccountSetting}>
        <EditAccountSetting onCancelClick={() => setEditAccountSetting(false)} />
      </UserSideBar>
      {patientUser ? (
        <UserSideBar
          onClickOutside={() => {
            setPatientUser(null);
          }}
          title={`${patientUser?.firstName} ${patientUser?.lastName}`}
          visible={patientUser}>
          <EditPatientProfile userData={patientUser} onClickCancel={() => setPatientUser(null)} />
        </UserSideBar>
      ) : null}
    </header>
  );
};

export default HeaderNew;
