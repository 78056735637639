import React, { useContext, useEffect, useRef, useState } from "react";
import { ToastContext } from "../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../models";

interface Coordinates {
  lat: number | null;
  lng: number | null;
}

interface MapWithGeocodeProps {
  address: string;
  apiKey: string;
}

const Map = ({ address, apiKey }: MapWithGeocodeProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const toast = useContext(ToastContext);
  const [coordinates, setCoordinates] = useState<Coordinates>();

  // Function to initialize the map and add a marker
  const initMap = () => {
    if (mapRef.current && coordinates?.lat && coordinates?.lng && coordinates?.lat !== null && coordinates?.lng !== null) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: coordinates?.lat, lng: coordinates?.lng },
        zoom: 14,
      });

      // Add marker to the map
      new window.google.maps.Marker({
        position: { lat: coordinates?.lat, lng: coordinates?.lng },
        map: map,
      });
    }
  };

  // Function to load the Google Maps script
  const loadScript = (url: string) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    script.onload = initMap;
    document.head.appendChild(script);
  };

  useEffect(() => {
    const fetchCoordinates = async () => {
      const encodedAddress = encodeURIComponent(address);
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.status === "OK") {
          const location = data.results[0].geometry.location;
          setCoordinates({ lat: location.lat, lng: location.lng });
        } else {
          toast?.openToast("Address not found", 2000, ToastVariants.Error);
        }
      } catch (error) {
        toast?.openToast("Address not found", 2000, ToastVariants.Error);
      }
    };

    fetchCoordinates();
  }, [address, apiKey]);

  useEffect(() => {
    if (coordinates?.lat && coordinates?.lng && coordinates.lat !== null && coordinates.lng !== null && apiKey) {
      if (!window?.google) {
        loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`);
      } else {
        initMap();
      }
    }
  }, [coordinates, apiKey]);

  return (
    <>
      <div ref={mapRef} className="rounded-2xl w-full h-full" />
      <div className="flex justify-center">
        <a
          href={`https://maps.google.com?q=${coordinates?.lat}, ${coordinates?.lng}`}
          rel="noreferrer"
          target="_blank"
          className="text-docsigna-purple font-bold text-base mt-5">
          Open in Google Map
        </a>
      </div>
    </>
  );
};

export default Map;
