const ProgressBar = ({ color, progress, isVertical }: { color?: string; progress: number; isVertical?: boolean }) => {
  const bgColor = "docsigna-purple";
  return (
    <div className={`relative overflow-hidden rounded-full ${!isVertical ? "w-full h-[33px]" : "w-[33px] h-full"}`}>
      <div className={`relative rounded-full border border-dashed border-${bgColor} h-full w-full`}></div>
      <div
        className={`absolute top-0 bg-${bgColor} ${
          isVertical ? "bottom-0 rounded-full" : `left-0 ${progress < 10 ? "rounded-l-full" : "rounded-full"}`
        }`}
        style={{ height: `${isVertical ? progress : "100"}%`, width: `${!isVertical ? progress : "100"}%` }}></div>
    </div>
  );
};

export default ProgressBar;
