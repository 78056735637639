/* eslint-disable no-useless-escape */
import React, { useState, useEffect, ChangeEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput, DatePicker, PasswordErrorList, Button, OtpInput } from "../../components";
import { formatPhoneNumber, validatePassword } from "../../utils";
import NumberFormat from "react-number-format";
import { Phone_Fax_Regex, PrimaryContactEmail, colors } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  RequestFormInitDataType,
  sendEmailVerificationCode,
  sendPhoneVerificationCode,
  updateRequestFormData,
  verifyEmailCode,
  verifyPhoneCode,
} from "../../redux/slices/OnBoardingSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { acceptPatientInvitation } from "../../redux/slices/OfficeSlice";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { emailExists } from "../../redux/slices/CurrentUserSlice";
import { User } from "../../models/user.model";

enum PatientInviteSteps {
  Register = "register",
  VerifyEmail = "verifyEmail",
  VerfifyPhone = "verifyPhone",
}

export const requestFormInitData: RequestFormInitDataType[] = [
  {
    id: "firstName",
    label: "First Name",
    placeholder: "Enter first name",
    required: true,
    errorMesage: "Please provide a first name",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "lastName",
    label: "Last Name",
    placeholder: "Enter last name",
    required: true,
    errorMesage: "Please provide a last name",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "email",
    label: "Email",
    placeholder: "Enter email address",
    required: true,
    errorMesage: "Please enter a valid email address",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "birthday",
    label: "Date of Birth",
    placeholder: "MM/DD/YYYY",
    required: true,
    errorMesage: "Valid date required",
    value: "",
    cursorPosition: 0,
    isValid: true,
  },
  {
    id: "phone",
    label: "Mobile Phone Number",
    placeholder: "Enter mobile number",
    required: true,
    errorMesage: "Please provide a mobile number",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "ohip",
    label: "OHIP Number (Optional)",
    placeholder: "Enter OHIP Number",
    required: false,
    errorMesage: "Valid OHIP number required",
    value: "",
    cursorPosition: 0,
    isValid: true,
  },
  {
    id: "password",
    label: "Password",
    placeholder: "Enter Password",
    required: true,
    errorMesage: "Please provide a password",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
  {
    id: "confirmPassword",
    label: "Confirm Password",
    placeholder: "Confirm password",
    required: true,
    errorMesage: "Please provide a confirm password",
    value: "",
    cursorPosition: 0,
    isValid: false,
  },
];

const validationsInitData = [
  {
    id: "lowerCase",
    isValid: false,
    msg: "Password must contain a lower case letter",
  },
  {
    id: "upperCase",
    isValid: false,
    msg: "Password must contain an upper case letter",
  },
  {
    id: "specialCharacter",
    isValid: false,
    msg: "Password must contain a special character",
  },
  {
    id: "cotainNumber",
    isValid: false,
    msg: "Password must contain a number",
  },
  {
    id: "eightCharacters",
    isValid: false,
    msg: "Password must contain a atleast  8 characters",
  },
  { id: "passwordMatch", isValid: false, msg: "Password must match" },
];

const VerifyEmail = ({ email, emailVerified }: { email: string; emailVerified: () => void }) => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [sendingOTP, setSendingOTP] = useState(false);
  const [otpInput, setOtpInput] = useState("");

  const onContinueClick = () => {
    setError(false);
    setSendingOTP(true);
    dispatch(verifyEmailCode({ email: email, code: otpInput })).then(action => {
      if (action.payload) {
        emailVerified();
      } else {
        setSendingOTP(false);
        setError(true);
      }
    });
  };
  useEffect(() => {
    if (otpInput.length === 6) {
      onContinueClick();
    } else {
      setError(false);
    }
  }, [otpInput]);
  return (
    <>
      <h3 className="text-3xl font-light mb-2">Email Verification</h3>
      <p className="text-base mb-5">You have received a 6-digit code to the email {email}. Enter it below to verify your account.</p>
      <OtpInput length={6} onChange={e => setOtpInput(e)} />
      {error ? <p style={{ color: colors.Red }}>Invalid code, please try again</p> : null}

      <div className="tab-content py-5 w-80">
        <Button
          text="Continue"
          width="full"
          disabled={otpInput.length !== 6}
          onClickFunc={() => onContinueClick()}
          varient={sendingOTP ? "loading" : "Primary"}
        />
      </div>
    </>
  );
};

const VerifyPhone = ({ phone, phoneVerified }: { phone: string; phoneVerified: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const [otpInput, setOtpInput] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const handleTryAgain = () => {
    setOtpError(false);
    setOtpInput("");
    dispatch(sendPhoneVerificationCode({ phone })).then(action => {
      if (action?.payload !== null) {
        toast?.openToast("Code has been sent again.");
      }
    });
  };

  const onSubmitClick = () => {
    setOtpError(false);
    setLoading(true);
    dispatch(verifyPhoneCode({ phone: phone, code: otpInput })).then(action => {
      if (action.payload) {
        phoneVerified();
      } else {
        setLoading(false);
        setOtpError(true);
      }
    });
  };

  useEffect(() => {
    if (loading) {
      setSubmitError(false);
    }
  }, [loading]);

  useEffect(() => {
    if (otpInput.length === 6) {
      onSubmitClick();
    } else {
      setSubmitError(false);
    }
  }, [otpInput]);
  return (
    <>
      <h3 className="text-3xl font-light mb-2">We need to validate your phone number.</h3>
      <p className="text-base mb-5 font-medium">We have sent a 6 digit code to your phone number: +1-{phone}.</p>
      <OtpInput length={6} onChange={e => setOtpInput(e)} />
      <div className="tab-content py-5 w-80">
        <Button text="Continue" width="full" disabled={otpInput.length !== 6} onClickFunc={onSubmitClick} varient={loading ? "loading" : "Primary"} />
      </div>
      {submitError ? <p style={{ color: colors.Red }}>{"Failed to submit the form. Please check the data you've entered."}</p> : null}
      {otpError ? <p style={{ color: colors.Red }}>{"Invalid code, please try again"}</p> : null}
      <br />
      <p className="text-base mb-5 font-medium">
        Didn’t receive a code?{" "}
        <span className="text-base text-docsigna-purple-dark cursor-pointer" onClick={() => handleTryAgain()}>
          Try again
        </span>{" "}
      </p>
    </>
  );
};

const InvitationForm = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  //const { search } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  //const email = search ? new URLSearchParams(search).get("email")?.replace(/\s/g, "+") : "";
  const { invitationTokenDetails } = useSelector((state: RootState) => state.user);
  const officeId = invitationTokenDetails?.offices?.[0].officeId ?? "";
  const token = invitationTokenDetails?.token ?? "";
  const patientId = invitationTokenDetails?.userId ?? "";
  const { pending } = useSelector((state: RootState) => state.onBoarding);
  const { loading } = useSelector((state: RootState) => state.currentUser);
  const [currentStep, setCurrentStep] = useState<PatientInviteSteps>(PatientInviteSteps.Register);
  const [formData, setFormData] = useState(requestFormInitData);
  const [pwdValidations, setPwdValidations] = useState(validationsInitData);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const passwordFlds = ["password", "confirmPassword"];
  const [dateError, setDateError] = useState(true);
  const [ohipState, setOhipState] = useState(formData[5].value);
  const [ohipStateError, setOhipStateError] = useState(false);
  const [sendingEmail] = useState(false);
  const [sendingEmailError, setSendingEmailError] = useState("");

  const validateField = (name: string, value: string) => {
    let valid = false;
    switch (name) {
      case "firstName":
      case "lastName":
        valid = !!value.trim();
        break;
      case "phone":
        valid = Phone_Fax_Regex.test(value);
        break;
      case "email":
        valid = PrimaryContactEmail.test(value);
        break;
      case "password":
        valid = !!value.trim();
        break;
      case "confirmPassword":
        valid = value === formData.find(it => it.id === "password")?.value;
        break;
      case "birthday":
        valid = dateError;
        break;
      case "ohip":
        let array;
        if (ohipState.includes(" ")) {
          array = ohipState.split(" ");
        }

        if (array && array.length === 3) {
          valid = array[0].length === 4 && array[1].length === 3 && array[2].length === 3;
        } else {
          valid = false;
        }
        break;
      case "details":
        valid = !!value.trim();
        break;
      default:
        break;
    }
    return valid;
  };

  const validateForm = () => {
    return !(formData.map(v => v.isValid).includes(false) || ohipStateError || dateError);
  };

  const handleInputChange = (e: any | string) => {
    let value = "";
    value = e.target.value;
    let cursorPosition = e.target.selectionEnd;
    switch (e.currentTarget.name) {
      case "phone":
        value = formatPhoneNumber(e.currentTarget.value);
        if (cursorPosition === 4 || cursorPosition === 8) {
          cursorPosition++;
        }
        break;
      case "birthday":
        const dateVals = value.split("-");
        value = e.currentTarget.value;
        if (parseInt(dateVals[0]) > 9999) {
          dateVals[0] = dateVals[0].substring(0, value.length - 1);
          value = dateVals.join("-");
        }
        break;
      case "confirmPassword":
        const passwordData = formData.filter(e => e.id === "password");
        const isValid = passwordData[0].value === value;
        const validationErrors = [...pwdValidations];
        validationErrors[5] = { ...validationErrors[5], isValid };
        setPwdValidations(validationErrors);
        break;
      case "password":
        const newValidations = pwdValidations.map(item => {
          const isValid = validatePassword(item.id, value);

          return { ...item, isValid };
        });
        setPwdValidations(newValidations);
        break;
      default:
        break;
    }

    const valid = validateField(e.currentTarget.name, value);

    //getting state
    const newFromState = formData.map(item => {
      if (item.id === e.currentTarget.name) {
        return { ...item, value, isValid: valid, cursorPosition };
      } else {
        return { ...item, cursorPosition };
      }
    });
    setFormData(newFromState);
  };

  const handleBlur = (id: string, value: string) => {
    const index = formData.findIndex(item => item.id === id);
    if (index !== -1) {
      const newFormData = [
        ...formData.slice(0, index),
        {
          ...formData[index],
          value,
        },
        ...formData.slice(index + 1),
      ];
      dispatch(updateRequestFormData(newFormData));
    }
  };

  const changeDate = (dateVal: string) => {
    setFormData([...formData.slice(0, 3), { ...formData[3], value: dateVal?.toString(), isValid: dateVal !== "" }, ...formData.slice(4)]);
  };

  const changeOhipValue = (val: string) => {
    setFormData([...formData.slice(0, 5), { ...formData[5], value: val }, ...formData.slice(6)]);
  };

  const onClickSave = async () => {
    setErrorInitiated(true);
    setOhipStateError(ohipState?.trim().length > 0 && ohipState?.trim().length < 10 ? true : false);
    if (validateForm()) {
      setSendingEmailError("");
      const newEmail = formData.filter(v => v.id === "email")[0].value;
      const firstName = formData.filter(v => v.id === "firstName")[0].value;
      const lastName = formData.filter(v => v.id === "lastName")[0].value;
      if (invitationTokenDetails?.email !== newEmail) {
        const res = await dispatch(emailExists({ email: newEmail }));
        if (res?.payload) {
          setSendingEmailError("An account already exists with this email address.");
          return;
        }
        dispatch(sendEmailVerificationCode({ email: newEmail, firstName, lastName })).then(action => {
          if (action.payload) {
            setCurrentStep(PatientInviteSteps.VerifyEmail);
          } else {
            setSendingEmailError("An error has occurred, please try again!");
          }
        });
      } else {
        verifyPhoneNumber();
      }
    }
  };

  const verifyPhoneNumber = () => {
    dispatch(sendPhoneVerificationCode({ phone: formData.filter(v => v.id === "phone")[0].value })).then(action => {
      if (action.payload) {
        setCurrentStep(PatientInviteSteps.VerfifyPhone);
      } else {
      }
    });
  };

  const finalSubmit = async () => {
    if (officeId) {
      const res = await dispatch(
        acceptPatientInvitation({
          firstName: formData[0].value,
          lastName: formData[1].value,
          email: formData[2].value,
          phone: formData[4].value,
          dateOfBirth: formData[3].value,
          ohipNumber: formData[5].value?.trim() !== "" ? formData[5].value?.trim() : undefined,
          password: formData[6].value,
          officeId: officeId,
          token: token,
        })
      );

      if (res.payload) {
        const Login = await auth?.signin({
          email: formData[2].value,
          password: formData[6].value,
        });
        if (Login) {
          navigate(`/patients/${patientId}/dashboard`);
        }
      }
    }
  };

  // useEffect(() => {
  //   setOhipState(formData[5].value);
  // }, [formData]);

  useEffect(() => {
    const newFormData = [...requestFormInitData];
    if (invitationTokenDetails) {
      const newData = newFormData.map(item => {
        const newItem = { ...item };
        switch (newItem.id) {
          case "ohip":
            newItem.value = invitationTokenDetails[item.id as keyof User]?.toString() ?? "";
            newItem.isValid = true;
            setOhipState(newItem.value);
            break;
          default:
            newItem.value = invitationTokenDetails[item.id as keyof User]?.toString() ?? "";
            const valid = validateField(newItem.id, newItem.value);
            newItem.isValid = valid;
            break;
        }
        return newItem;
      });
      setFormData(newData);
    } else {
      setFormData(newFormData);
    }
  }, [invitationTokenDetails]);
  const phoneNumber = formData.find(item => item.id === "phone")?.value ?? "";

  return (
    <>
      <main>
        <div className="w-full">
          <div className="w-full max-w-2xl mx-auto">
            {currentStep === PatientInviteSteps.Register ? (
              <>
                <h3 className="text-xl text-center font-medium mb-5 text-docsigna-blue-light">
                  Complete the below required fields in order to set up your account
                </h3>
                {sendingEmailError ? <p style={{ color: colors.Red }}>{sendingEmailError}</p> : null}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {formData.map(item => {
                    if (item.id === "birthday") {
                      return (
                        <div className="py-0.5 flex" key={item.id}>
                          <div className="w-full">
                            <DatePicker
                              label={item.label}
                              isRequired={item.required}
                              name={item.id}
                              placeholder={item.placeholder}
                              value={item.value}
                              isError={dateError && errorInitiated}
                              onChange={val => changeDate(val)}
                              errorMsg={item.errorMesage}
                              onError={(e: boolean) => setDateError(e)}
                              showError={errorInitiated}
                            />
                          </div>
                        </div>
                      );
                    } else if (item.id === "password" || item.id === "confirmPassword") {
                      return (
                        <div className="py-0.5" key={item.id}>
                          <TextInput
                            isError={!item.isValid && errorInitiated}
                            isRequired={item.required}
                            isPassword={passwordFlds.includes(item.id)}
                            name={item.id}
                            label={item.label}
                            value={String(item.value)}
                            placeholder={item.placeholder}
                            errorMsg={item.errorMesage}
                            onChangeFunc={e => handleInputChange(e)}
                            // cursorPosition={item.cursorPosition}
                          />
                        </div>
                      );
                    } else if (item.id === "ohip") {
                      return (
                        <div className="py-0.5" key={item.id}>
                          <label className="text-sm text-docsigna-blue-dark font-medium block w-full">
                            {item.label}
                            {item.required ? <span className="text-red-500">*</span> : null}
                          </label>
                          <NumberFormat
                            value={item.value}
                            isNumericString
                            format={"#### ### ###"}
                            placeholder="Enter OHIP Number"
                            displayType="input"
                            name={item.id}
                            className="general-ohip"
                            onValueChange={values => {
                              changeOhipValue(values.value);
                            }}
                            onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                              setOhipStateError(e.target.value?.trim().length > 0 && e.target.value?.trim().length < 10 ? true : false);
                            }}
                          />
                          {ohipStateError && errorInitiated ? <p style={{ color: colors.Red }}>{item.errorMesage}</p> : null}
                        </div>
                      );
                    } else {
                      return (
                        <div className="py-0.5" key={item.id}>
                          <TextInput
                            isError={!item.isValid && errorInitiated}
                            isRequired={item.required}
                            isPassword={passwordFlds.includes(item.id)}
                            name={item.id}
                            label={`${item.label}`}
                            value={String(item.value)}
                            placeholder={item.placeholder}
                            errorMsg={item.errorMesage}
                            onChangeFunc={e => handleInputChange(e)}
                            onBlur={e => handleBlur(item.id, e.currentTarget.value.trim())}
                            // cursorPosition={item.cursorPosition}
                          />
                        </div>
                      );
                    }
                  })}
                  <div>
                    {formData[6].value &&
                      pwdValidations.map(item => {
                        return (
                          <div key={item.id} className="my-1 ml-2">
                            <PasswordErrorList isValid={item.isValid} message={item.msg} />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="py-2 flex justify-around">
                  <Button
                    onClickFunc={() => {
                      navigate("/");
                    }}
                    AdditionalClassNames="pointer px-5 mr-3"
                    text={"Cancel"}
                    disabled={loading || sendingEmail || pending}
                    width="fit"
                    varient="Secondary"
                  />
                  <Button
                    onClickFunc={() => {
                      onClickSave();
                    }}
                    AdditionalClassNames="pointer px-5 mr-3"
                    text={"Complete Registration"}
                    disabled={!validateForm() && errorInitiated}
                    varient={sendingEmail || pending || loading ? "loading" : "Primary"}
                  />
                </div>
              </>
            ) : null}
            {currentStep === PatientInviteSteps.VerifyEmail ? <VerifyEmail email={formData[2].value} emailVerified={verifyPhoneNumber} /> : null}
            {currentStep === PatientInviteSteps.VerfifyPhone ? <VerifyPhone phone={phoneNumber} phoneVerified={finalSubmit} /> : null}
          </div>
        </div>
      </main>
    </>
  );
};
export default InvitationForm;
