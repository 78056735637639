import { useContext, useEffect } from "react";
import InvitationForm from "./InvitationForm";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { AppDispatch, RootState } from "../../redux/store";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvitationTokenDetails } from "../../redux/slices/UserSlice";

const PatientInvitation = () => {
  const authContext = useContext(AuthContext);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const invitationToken = location.pathname.split("/")[2];
  const { invitationTokenDetails } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (invitationToken) {
      const isAuthenticated = authContext?.isAuthenticated;
      if (isAuthenticated) authContext.signout();
    }
    dispatch(fetchInvitationTokenDetails({ invitationToken }));
  }, [invitationToken]);

  return (
    <main className={`relative flex justify-center items-center patient-invite-container`}>
      <div className="absolute w-4/5 h-[90%] rounded bg-white shadow-lg overflow-y-auto flex justify-center items-center">
        {invitationTokenDetails === undefined ? (
          //UI to be fixed and extracted as a component
          <div>Fetching token details...</div>
        ) : (
          <>
            {invitationTokenDetails === null ? (
              //UI to be fixed
              <div className="w-80 font-bold text-center">Invitation is either invalid or expired, please request a new invitation from Docnote.</div>
            ) : (
              <InvitationForm />
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default PatientInvitation;
