import React, { useState, useEffect, useContext } from "react";
import { AuthPermission } from "../../services/auth.service";
import ProtectedComponent from "../../components/ProtectedComponent/ProtectedComponent";
import UserSideBar from "../../components/UserSideBar/UserSideBar";
import AddOffice from "../../components/forms/AddOffice/AddOffice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  clearPatientsList,
  closeTooltipOffice,
  listOfficeData as listOffice,
  openTooltipOffice,
  searchOffice,
  setPreviousSelectedOfficeId,
  sortObj,
} from "../../redux/slices/OfficeSlice";
import { Button, Pagination, RenderName, TableHeader, TableRow, TextInput } from "../../components";
import { useNavigate } from "react-router-dom";
import OfficeSectionsHeader from "../../components/OfficeSectionsHeader/OfficeSectionsHeader";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { clearOfficeRequestList } from "../../redux/slices/OfficeRequestSlice";
import { clearUserList } from "../../redux/slices/UserSlice";
import { clearFeeList } from "../../redux/slices/FeeSlice";
import { resetStateServiceSlice } from "../../redux/slices/ServiceSlice";
import { headers } from "./OfficeScreenData";
import { CircularProgress } from "@mui/material";
import { getSorting } from "../../utils";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import NoOffices from "../../assets/images/no-result.png";
import { Office } from "../../models/office.model";
import ViewOffice from "../../components/ViewOffice/ViewOffice";

const OfficesScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const [showAddOfficeSidebar, setShowAddOfficeSidebar] = useState(false);
  const officeSidebar = (val: boolean) => setShowAddOfficeSidebar(val);
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [extraClasses] = useState<string[]>(headers.map(head => head.extraClasses));
  const [sortMethods, setSortMethods] = useState([false, false, false, false]);
  const [activeColumn, setActiveColumn] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [officeDetails, setOfficeDetails] = useState<Office | null>(null);
  const { officeList, sort, previousSelectedOfficeId, loading } = useSelector((state: RootState) => state.office);
  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeColumn === index ? (item ? headerIconAsc : headerIconDesc) : null));

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: newPage, rowsPerPage: sort.rowsPerPage }, searchTerm);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateTable({ sortedBy: sort.sortedBy, pageNumber: 0, rowsPerPage: parseInt(event.target.value, 10) }, searchTerm);
  };

  const updateTable = (sortObj?: sortObj, searchText?: string) => {
    const pageNumToBeCalled = sortObj?.pageNumber ?? sort.pageNumber;
    const sizeToBeCalled = sortObj?.rowsPerPage ?? sort.rowsPerPage;
    const sortBy = sortObj?.sortedBy ?? sort.sortedBy;
    const searchT = searchText ?? "";
    if (searchT.length >= 2) {
      dispatch(
        searchOffice({
          searchTerm: searchT,
          page: pageNumToBeCalled,
          size: sizeToBeCalled,
          sortType: sortBy,
        })
      );
      return;
    } else {
      dispatch(listOffice({ page: pageNumToBeCalled, size: sizeToBeCalled, sortType: sortBy }));
    }
  };

  const onHeaderClick = (index: number) => {
    if (index !== 3 && index !== 4) {
      const sortMethodString = getSorting(sortKeys, index, sortMethods);
      setActiveColumn(index);
      setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
      updateTable({ sortedBy: sortMethodString, pageNumber: 0, rowsPerPage: sort.rowsPerPage }, searchTerm);
    }
  };
  const handleTooltipClick = async (type: string, id: string) => {
    switch (type) {
      case "View Details":
        const findOffice = officeList.data?.content.find(item => item.officeId === id);
        if (findOffice) {
          setOfficeDetails(findOffice);
        }
        break;
      default:
        toast?.openToast();
        break;
    }
  };

  const handleSearchInput = (text: string) => {
    const input = text;
    setSearchTerm(input);
    if (input && input.trim().length >= 2) {
      updateTable({ ...sort, pageNumber: 0 }, input.trim());
    } else if (input.length <= 1) {
      updateTable(sort);
    }
  };

  const handleToolTipOpen = (index: number) => {
    dispatch(openTooltipOffice(index));
  };

  const handleToolTipClose = () => {
    dispatch(closeTooltipOffice());
  };

  useEffect(() => {
    updateTable();
  }, []);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
  }, [searchTerm]);

  useEffect(() => {
    if (previousSelectedOfficeId) {
      clearData();
      dispatch(setPreviousSelectedOfficeId(""));
    }
  }, []);

  const clearData = () => {
    dispatch(clearOfficeRequestList());
    dispatch(clearPatientsList());
    dispatch(clearUserList());
    dispatch(clearFeeList());
    dispatch(resetStateServiceSlice());
  };

  return (
    <main>
      <div className="container mx-auto p-8">
        <OfficeSectionsHeader showMeta={false} />
        <div className="block lg:flex justify-between items-center mb-6">
          <div className="w-full lg:w-1/3 mb-5 lg:mb-0">
            <h2 className="heading mb-0 lg:mb-5 uppercase">Offices</h2>
            <p className="text-base font-medium">Manage your offices here.</p>
          </div>
          <div className="block md:flex lg:justify-end w-full lg:w-2/3">
            <div className="flex items-center">
              <div className="relative w-full">
                <TextInput
                  onChangeFunc={e => {
                    handleSearchInput(e.currentTarget.value);
                  }}
                  value={searchTerm}
                  placeholder="Search offices"
                  name="officeSearch"
                  extraInputClass="w-full md:max-w-sm"
                  isSearchInput={true}
                />
              </div>
            </div>
            <ProtectedComponent requiredPermission={[AuthPermission.Admin]}>
              <div className="block md:flex md:justify-end items-center ml-0 md:ml-5">
                <span className="md:mr-5 font-medium text-center hidden md:inline-block">or</span>
                <Button text="Add Office" onClickFunc={() => officeSidebar(true)} AdditionalClassNames="whitespace-nowrap w-auto mt-3 md:mt-0" />
              </div>
            </ProtectedComponent>
          </div>
        </div>

        {!loading ? (
          <div className="block">
            {officeList?.data?.content && officeList?.data?.content?.length > 0 ? (
              <>
                <div className="flex justify-between items-center border-b border-docsigna-blue-light">
                  {headers.map((head, index) => (
                    <TableHeader
                      title={head.title}
                      index={index}
                      key={index}
                      sortIcon={sortIcons[index]}
                      handleSort={onHeaderClick}
                      extraClassesHeader={head.extraClasses}
                      isClickable={head.isClickable}
                    />
                  ))}
                  <div className="w-40 p-5 py-3">
                    <span className="text-base text-docsigna-purple-dark inline-block w-5"></span>
                  </div>
                </div>
                {officeList.data?.content.map((rowData, index) => {
                  const sortedData = {
                    name: <RenderName title={rowData.name} subTitle={rowData.phone} />,
                    addressLine1: <RenderName title={rowData.street} subTitle={rowData.city + ", " + rowData.state + " " + rowData.zip} />,
                    contactName: (
                      <div key={index} className={`${extraClasses[2]}`}>
                        <a
                          className="text-docsigna-blue-light hover:underline-offset-2 hover:underline"
                          href={`mailto: ${rowData.contactEmail}`}
                          onClick={e => e.stopPropagation()}>
                          {rowData.contactName}
                        </a>
                      </div>
                    ),
                    numUsers: rowData.numUsers,
                    status: <span className="capitalize">{rowData.officeState}</span>,
                  };
                  return (
                    <TableRow
                      data={Object.values(sortedData)}
                      key={index}
                      id={rowData.officeId}
                      tooltipVisible={rowData.tooltipVisible}
                      handleOpenTooltip={() => handleToolTipOpen(index)}
                      handleCloseTooltip={() => handleToolTipClose()}
                      extraClassesRow={extraClasses}
                      tooltipOptions={["View Details", "View Reports", "Suspend Office"]}
                      handleTooltipClick={handleTooltipClick}
                      onRowClick={() => {
                        navigate("/offices/" + rowData.officeId + "/requests");
                        dispatch(setPreviousSelectedOfficeId(rowData.officeId));
                        clearData();
                      }}
                    />
                  );
                })}
                <Pagination
                  colSpan={10}
                  count={officeList.data ? officeList.data.totalElements : 0}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  page={sort.pageNumber}
                  rowsPerPage={sort.rowsPerPage}
                />
              </>
            ) : (
              <DataNotFound image={NoOffices} text="No offices found" />
            )}
          </div>
        ) : (
          <CircularProgress size={40} color={"info"} style={{ marginLeft: "50%", marginTop: "10%" }} />
        )}
        {officeDetails ? (
          <UserSideBar
            visible={!!officeDetails}
            LeftbuttonText="Cancel"
            RightbuttonText="Add"
            onCancelClick={() => setOfficeDetails(null)}
            onClickOutside={() => setOfficeDetails(null)}
            title="Office Details">
            <ViewOffice onCancelClick={() => setOfficeDetails(null)} office={officeDetails} />
          </UserSideBar>
        ) : null}

        <UserSideBar
          visible={showAddOfficeSidebar}
          LeftbuttonText="Cancel"
          RightbuttonText="Add"
          onCancelClick={() => officeSidebar(false)}
          onInviteClick={() => officeSidebar(false)}
          onClickOutside={() => (!isLoading ? officeSidebar(false) : null)}
          title="Add New Office">
          <AddOffice onCancelClick={() => officeSidebar(false)} setIsLoading={setIsLoading} isLoading={isLoading} />
        </UserSideBar>
      </div>
    </main>
  );
};

export default OfficesScreen;
