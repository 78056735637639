import { Fade, Popper } from "@mui/material";
import React, { ReactNode } from "react";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { colors } from "../../utils/constants";

interface TooltipPropType {
  options: string[];
  onSelect: (type: string, id: string, index?: number) => void | undefined;
  imageComponent?: ReactNode;
  additionalClasses?: string;
  id?: string; // id of the item where tooltip is rendered
  open?: boolean;
  handleOpenTooltip?: () => void;
  handleCloseTooltip?: () => void;
  additionalComponents?: JSX.Element[];
  disabledOptions?: string[];
}

const Tooltip = ({
  options,
  onSelect = () => {},
  imageComponent = <MoreHorizIcon htmlColor={`${colors.Orange}`} className="cursor-pointer" />,
  handleOpenTooltip = () => {},
  handleCloseTooltip = () => {},
  additionalClasses = "",
  id = "",
  open = false,
  additionalComponents = [],
  disabledOptions,
}: TooltipPropType) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onOptionSelect = (type: string, id = "", index: number) => {
    onSelect(type, id, index);
    handleCloseTooltip();
  };

  const onClickOutSide = () => {
    handleCloseTooltip();
  };

  return (
    <>
      <span
        className={"text-base inline-block w-full" + additionalClasses}
        onClick={e => {
          setAnchorEl(e.currentTarget);
          if (open) {
            handleCloseTooltip();
          } else {
            handleOpenTooltip();
          }
          e.stopPropagation();
        }}>
        {imageComponent}
      </span>
      {anchorEl ? (
        <Popper transition open={open} anchorEl={anchorEl} style={{ zIndex: 100 }} placement={"right-end"} disablePortal={false}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={100}>
              <span>
                <DropDownMenu
                  additionalComponents={additionalComponents}
                  options={options}
                  onClickOutside={onClickOutSide}
                  onSelect={index => onOptionSelect(options[index], id, index)}
                  disabledOptions={disabledOptions}
                />
              </span>
            </Fade>
          )}
        </Popper>
      ) : null}
    </>
  );
};

export default Tooltip;
