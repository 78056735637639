import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getOfficeRequestTimeline } from "../../redux/slices/UserSlice";
import { AppDispatch, RootState } from "../../redux/store";
import moment from "moment";

export default function RequestTimelineTab() {
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const dispatch = useDispatch<AppDispatch>();
  const { timeline } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (requestData?.officeId && requestData?.requestId) {
      dispatch(getOfficeRequestTimeline({ officeId: requestData?.officeId, requestId: requestData?.requestId, page: 0, size: 100 }));
    }
  }, [requestData]);

  return (
    <main>
      <div className="container mx-auto pt-8">
        <div className="tabs">
          <div className="tab-content">
            <div className="flex flex-wrap md:flex-nowrap">
              <div className="mb-6 md:mb-0 w-full">
                <div className="p-6 w-full border border-slate-200 rounded-lg mb-6">
                  <ol
                    className={`relative ${
                      timeline?.data?.content ? (timeline?.data?.content.length > 1 ? "border-l" : null) : null
                    } border-gray-200`}>
                    {timeline?.data?.content.map((timeline, index) => {
                      return (
                        <li key={index} className="mb-6 ml-4 relative">
                          <div
                            className={`absolute w-4 h-4 rounded-full mt-2 top-5 -left-6 border border-white z-10 ${
                              index === 0 ? "bg-docsigna-blue-light" : "bg-gray-200"
                            }`}></div>
                          <time className="mb-1 text-sm font-medium leading-none text-docsigna-blue-light">
                            {moment(timeline.createdDate).format("MMM DD, YYYY h:mm a")}
                          </time>
                          <h5 className={`break-all text-base text-sm text-docsigna-blue-dark ${index === 0 ? "font-bold" : "font-medium"}`}>
                            {timeline.event}
                          </h5>
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
