/* eslint-disable no-useless-escape */
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { TextInput, PasswordErrorList, Button } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { acceptInvitation } from "../../../redux/slices/UserSlice";
import { AuthContext } from "../../../contexts/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../../../utils";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { SESSION_STORAGE_SHOW_MFA } from "../../../utils/constants";

export interface PasswordDataType {
  id: string;
  placeholder: string;
  required: boolean;
  errorMesage: string;
  label: string;
  value: string;
  isValid: boolean;
}

const passwordInitData = [
  {
    id: "password",
    label: "Password",
    placeholder: "Enter Password",
    required: true,
    errorMesage: "Please provide password",
    value: "",
    isValid: false,
  },
  {
    id: "confirmPassword",
    label: "Confirm Password",
    placeholder: "Confirm password",
    required: true,
    errorMesage: "Password and confirm password are not same",
    value: "",
    isValid: false,
  },
];

const validationsInitData = [
  {
    id: "lowerCase",
    isValid: false,
    msg: "Password must contain a lower case letter",
  },
  {
    id: "upperCase",
    isValid: false,
    msg: "Password must contain an upper case letter",
  },
  {
    id: "specialCharacter",
    isValid: false,
    msg: "Password must contain a special character",
  },
  { id: "cotainNumber", isValid: false, msg: "Password must contain a number" },
  {
    id: "eightCharacters",
    isValid: false,
    msg: "Password must contain a atleast  8 characters",
  },
  { id: "passwordMatch", isValid: false, msg: "Password must match" },
];

const PasswordConfirmationForm = () => {
  const [passwordData, setPasswordData] = useState(passwordInitData);
  const [validations, setValidations] = useState(validationsInitData);
  const dispatch: AppDispatch = useDispatch();
  const auth = useContext(AuthContext);
  const toast = useContext(ToastContext);
  const navigate = useNavigate();
  const [touchedInput, setTouchedInput] = useState(false);
  const { loading, isUserAccepted, invitationTokenDetails, error } = useSelector((state: RootState) => state.user);

  const keyUpHandler = (e: any) => {
    if (e.key === "Enter" && e.target.name === "confirmPassword") {
      !loading && validations.every(it => it.isValid) ? handleLoginPress() : null;
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.currentTarget.value;
    if (e.currentTarget.name === "confirmPassword") {
      const isValid = passwordData[0].value === value;

      const validationErrors = [...validations];
      validationErrors[5] = { ...validationErrors[5], isValid };
      setValidations(validationErrors);
    } else {
      const newValidations = validations.map(item => {
        const isValid = validatePassword(item.id, value);

        return { ...item, isValid };
      });

      setValidations(newValidations);
    }

    setPasswordData([
      ...passwordData.slice(0, index),
      {
        ...passwordData[index],
        value,
      },
      ...passwordData.slice(index + 1),
    ]);
  };

  const handleLoginPress = () => {
    setTouchedInput(false);
    dispatch(acceptInvitation({ invitationToken: invitationTokenDetails?.token || "", password: passwordData[0].value }));
  };

  useEffect(() => {
    if (passwordData[0].value.length > 0 || passwordData[1].value.length > 0) {
      setTouchedInput(true);
    } else {
      setTouchedInput(false);
    }
  }, [passwordData]);

  useEffect(() => {
    if (isUserAccepted) {
      handleSignIn();
    }
  }, [isUserAccepted]);

  const handleSignIn = async () => {
    if (invitationTokenDetails?.email) {
      const signIn = await auth?.signin({ email: invitationTokenDetails?.email, password: passwordData[0].value });
      if (signIn) {
        sessionStorage.setItem(SESSION_STORAGE_SHOW_MFA, invitationTokenDetails?.email);
        navigate("/login");
      } else {
        toast?.openToast("Something went wrong. Please try again!");
      }
    } else {
      toast?.openToast("Something went wrong. Please try again!");
    }
  };

  return (
    <div className="w-full md:max-w-md mt-0">
      <h3 className="heading text-center">Welcome to Docnote!</h3>
      {error || isUserAccepted === false ? (
        <p className="text-red-700" style={{ color: "red" }}>
          An error has occurred, please try again
        </p>
      ) : (
        <p className="text-base font-medium text-center">Create a new password to access your account.</p>
      )}
      <div className="py-5">
        {passwordData.map((item, index) => {
          return (
            <div className="py-2" key={item.id}>
              <TextInput
                isRequired={item.required}
                label={item.label}
                value={item.value}
                placeholder={item.placeholder}
                name={item.id}
                isPassword
                onFocus={() => {}}
                onChangeFunc={e => handlePasswordChange(e, index)}
                onKeyUp={e => keyUpHandler(e)}
              />
            </div>
          );
        })}
        <div>
          {touchedInput
            ? validations.map(item => {
                return (
                  <div key={item.id} className="my-1 ml-2">
                    <PasswordErrorList isValid={item.isValid} message={item.msg} />
                  </div>
                );
              })
            : null}
        </div>
        <div className="py-2 mt-5">
          <Button
            varient={loading ? "loading" : "Primary"}
            onClickFunc={() => (!loading ? handleLoginPress() : null)}
            text={"Log In"}
            disabled={!validations.every(it => it.isValid)}
          />
        </div>
      </div>
    </div>
  );
};

export default PasswordConfirmationForm;
