import { useDebouncedCallback } from "use-debounce";
import { BreadCrumbs, ConfirmationDialog, Pagination, TableHeader, TableRow, TextInput } from "../../components";
import { headersType } from "../../models";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import { clearPatientSearchTerm, clearRequestSearchTerm, searchPatients, searchRequests } from "../../redux/slices/SearchSlice";
import { useSelector } from "react-redux";
import { Request } from "../../models/request.model";
import { useNavigate } from "react-router-dom";
import { User } from "../../models/user.model";
import { getSorting } from "../../utils";

export const patientSearchHeader: headersType[] = [
  {
    title: "Patient Name",
    extraClasses: "w-full lg:w-180 pl-5 truncate",
    sortKey: "lastName",
    isClickable: true,
  },
  {
    title: "Date of Birth",
    extraClasses: "w-full hidden lg:block",
    sortKey: "",
    isClickable: false,
  },
  {
    title: "Office Name",
    extraClasses: "w-full hidden lg:block truncate",
    sortKey: "office",
    isClickable: false,
  },
];

export const requestSearchHeader: headersType[] = [
  {
    title: "Request #",
    extraClasses: "w-full pl-5 truncate",
    sortKey: "requestNumber",
    isClickable: true,
  },
  {
    title: "Patient Name",
    extraClasses: "w-full lg:w-180 hidden lg:block truncate",
    sortKey: "",
    isClickable: false,
  },
  {
    title: "Office Name",
    extraClasses: "w-full hidden lg:block truncate",
    sortKey: "office.name",
    isClickable: true,
  },
];

const SearchScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [requestSearchTerm, setRequestSearchTerm] = useState("");
  const [patientSearchTerm, setPatientSearchTerm] = useState("");
  const [requestSortMethods, setRequestSortMethods] = useState([true, false, false]);
  const [patientSortMethods, setPatientSortMethods] = useState([true, false, false]);
  const [activeColumnRequest, setActiveColumnRequest] = useState<number>(-1);
  const [activeColumnPatient, setActiveColumnPatient] = useState<number>(-1);
  const [selectedOffice, setSelectedOffice] = useState("");
  const [patient, setPatient] = useState<User | null>(null);

  const requestSortKeys = requestSearchHeader.map(head => head.sortKey);
  const patientSortKeys = patientSearchHeader.map(head => head.sortKey);
  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const requestSortIcons = requestSortMethods.map((item, index) => (activeColumnRequest === index ? (item ? headerIconAsc : headerIconDesc) : null));
  const patientSortIcons = patientSortMethods.map((item, index) => (activeColumnPatient === index ? (item ? headerIconAsc : headerIconDesc) : null));

  const {
    searchedRequests,
    requestSearchTerm: requestTerm,
    searchedPatients,
    patientSearchTerm: patientTerm,
    requestSort,
    patientSort,
  } = useSelector((state: RootState) => state.searchResults);

  const requestSearchApi = (term: string, page: number, size: number, sort?: string) => {
    dispatch(
      searchRequests({
        searchTerm: term.trim(),
        page,
        size,
        sort,
      })
    );
  };

  const patientSearchApi = (term: string, page: number, size: number, sort?: string) => {
    dispatch(
      searchPatients({
        searchTerm: term.trim(),
        page,
        size,
        sort,
      })
    );
  };

  const requestSearchDebounced = useDebouncedCallback((val: string) => {
    if (val && val.trim().length > 2) requestSearchApi(val, 0, 10);
  }, 500);

  const patientSearchDebounced = useDebouncedCallback((val: string) => {
    if (val && val.trim().length > 2) patientSearchApi(val, 0, 10);
  }, 500);

  const handleRequestSearch = (val: string) => {
    const oldTerm = requestSearchTerm;
    setRequestSearchTerm(val);
    requestSearchDebounced(val);
    if (oldTerm.length > 2 && val.trim().length <= 2) {
      dispatch(clearRequestSearchTerm());
    }
  };

  const handlePatientSearch = (val: string) => {
    const oldTerm = patientSearchTerm;
    setPatientSearchTerm(val);
    patientSearchDebounced(val);
    if (oldTerm.length > 2 && val.trim().length <= 2) {
      dispatch(clearPatientSearchTerm());
    }
  };

  const onRequestHeaderClick = (index: number) => {
    const sortMethodString = getSorting(requestSortKeys, index, requestSortMethods);
    setActiveColumnRequest(index);
    setRequestSortMethods([...requestSortMethods.slice(0, index), !requestSortMethods[index], ...requestSortMethods.slice(index + 1)]);
    requestSearchApi(requestSearchTerm, 0, requestSort.rowsPerPage, sortMethodString);
  };

  const onPatientHeaderClick = (index: number) => {
    const sortMethodString = getSorting(patientSortKeys, index, patientSortMethods);
    setActiveColumnPatient(index);
    setPatientSortMethods([...patientSortMethods.slice(0, index), !patientSortMethods[index], ...requestSortMethods.slice(index + 1)]);
    patientSearchApi(patientSearchTerm, 0, patientSort.rowsPerPage, sortMethodString);
  };

  const onPageChangeRequest = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    requestSearchApi(requestSearchTerm, newPage, requestSort.rowsPerPage, requestSort.sortedBy);
  };

  const onRowsPerPageChangeRequest = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    requestSearchApi(requestSearchTerm, 0, parseInt(event.target.value, 10), requestSort.sortedBy);
  };

  const onPageChangePatient = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    patientSearchApi(patientSearchTerm, newPage, patientSort.rowsPerPage, patientSort.sortedBy);
  };

  const onRowsPerPageChangePatient = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    patientSearchApi(patientSearchTerm, 0, parseInt(event.target.value, 10), patientSort.sortedBy);
  };

  const handleOfficeSelect = (id: string) => {
    setSelectedOffice(id);
  };

  const handleDialogSuccess = () => {
    selectedOffice && patient?.userId ? navigate(`/offices/${selectedOffice}/patients/${patient.userId}`) : null;
    setPatient(null);
    setSelectedOffice("");
  };

  const noResultsFound = () => {
    return (
      <div className="h-1/2 flex justify-center items-center">
        <p className="font-semibold">No results found</p>
      </div>
    );
  };

  useEffect(() => {
    if (requestTerm) setRequestSearchTerm(requestTerm);
    if (patientTerm) setPatientSearchTerm(patientTerm);
  }, []);

  return (
    <div className="container mx-auto p-8">
      <BreadCrumbs items={[{ key: "#", title: "Search" }]} />
      <div className="w-full mb-5">
        <h2 className="heading mb-0 lg:mb-5 uppercase">Search</h2>
        <p className="text-base font-medium">Search for patients or a request # here</p>
      </div>
      <div className="grid lg:grid-cols-2 gap-10 min-h-[350px]">
        {/* Patient search section */}
        <div className="bg-docsigna-pink-light h-full rounded-md relative">
          <div className="flex justify-between items-center px-5 pt-5">
            <h3 className="heading text-3xl leading-none">Patients</h3>
            <div className="w-1/2">
              <TextInput
                onChangeFunc={e => handlePatientSearch(e.target.value)}
                disabled={false}
                value={patientSearchTerm}
                placeholder={"Search for a patient"}
                extraInputClass="w-1/2"
                isSearchInput={true}
              />
            </div>
          </div>
          <div className="flex justify-between items-center border-b border-b-black mt-3">
            {patientSearchHeader.map((head, index) => (
              <TableHeader
                title={head.title}
                index={index}
                key={index}
                sortIcon={patientSortIcons[index]}
                isClickable={head.isClickable}
                handleSort={onPatientHeaderClick}
                titleColor="text-black"
                extraClassesHeader={head.extraClasses}
              />
            ))}
          </div>
          {searchedPatients.length > 0 ? (
            <>
              {searchedPatients.map((rowData: User, index: number) => {
                const offices = rowData?.offices?.length ? rowData.offices : [];
                const singleOffice = offices?.length ? offices[0] : undefined;
                const officeNames = offices.length ? offices.map(item => item.name).join(", ") : "-";
                const sortedData = {
                  patientName: `${rowData.firstName ?? "-"} ${rowData.lastName ?? ""}`,
                  dob: `${rowData.dateOfBirth ?? "-"}`,
                  officeName: `${officeNames}`,
                };
                return (
                  <TableRow
                    allowHover={true}
                    tooltipVisible={false}
                    handleOpenTooltip={() => null}
                    handleCloseTooltip={() => null}
                    data={Object.values(sortedData)}
                    key={index}
                    extraClassesRow={patientSearchHeader.map(header => header.extraClasses)}
                    tooltipOptions={[]}
                    onRowClick={() => {
                      offices?.length > 1
                        ? setPatient(rowData)
                        : singleOffice?.officeId
                        ? navigate(`/offices/${singleOffice?.officeId}/patients/${rowData.userId}`)
                        : null;
                    }}
                  />
                );
              })}
              <Pagination
                colSpan={10}
                count={patientSort.totalElements}
                onPageChange={onPageChangePatient}
                onRowsPerPageChange={onRowsPerPageChangePatient}
                page={patientSort.pageNumber}
                rowsPerPage={patientSort.rowsPerPage}
              />
            </>
          ) : (
            noResultsFound()
          )}
        </div>

        {/* Request search section */}
        <div className="bg-docsigna-pink-light h-full rounded-md">
          <div className="flex justify-between items-center px-5 pt-5">
            <h3 className="heading text-3xl leading-none">Request #</h3>
            <div className="w-1/2">
              <TextInput
                onChangeFunc={e => {
                  handleRequestSearch(e.target.value);
                }}
                disabled={false}
                value={requestSearchTerm}
                placeholder={"Search for a request #"}
                isSearchInput={true}
              />
            </div>
          </div>
          <div className="flex justify-between items-center border-b border-b-black mt-3">
            {requestSearchHeader.map((head, index) => (
              <TableHeader
                title={head.title}
                index={index}
                key={index}
                sortIcon={requestSortIcons[index]}
                isClickable={head.isClickable}
                handleSort={onRequestHeaderClick}
                titleColor="text-black"
                extraClassesHeader={head.extraClasses}
              />
            ))}
          </div>
          {searchedRequests.length > 0 ? (
            <>
              {searchedRequests.map((rowData: Request, index: number) => {
                const sortedData = {
                  requestNumber: rowData.requestNumber,
                  patientName: `${rowData.firstName ?? "-"} ${rowData.lastName ?? ""}`,
                  officeName: `${rowData?.officeName ?? "-"}`,
                };
                return (
                  <TableRow
                    allowHover={true}
                    tooltipVisible={false}
                    handleOpenTooltip={() => null}
                    handleCloseTooltip={() => null}
                    data={Object.values(sortedData)}
                    key={index}
                    extraClassesRow={requestSearchHeader.map(header => header.extraClasses)}
                    tooltipOptions={[]}
                    onRowClick={() => {
                      navigate(`/offices/${rowData.officeId}/requests/${rowData.requestId}`);
                    }}
                  />
                );
              })}
              <Pagination
                colSpan={10}
                count={requestSort.totalElements}
                onPageChange={onPageChangeRequest}
                onRowsPerPageChange={onRowsPerPageChangeRequest}
                page={requestSort.pageNumber}
                rowsPerPage={requestSort.rowsPerPage}
              />
            </>
          ) : (
            noResultsFound()
          )}
        </div>
      </div>
      {patient ? (
        <ConfirmationDialog
          open={!!patient?.offices?.length}
          title={"This patient is attached to multiple offices"}
          titleBold={true}
          description={
            <div>
              <p>Please select an office below to view profile</p>
              <select className="general-select w-full bg-transparent" value={selectedOffice} onChange={e => handleOfficeSelect(e.target.value)}>
                <option value={""}>{"Select office"}</option>
                {patient?.offices?.map(item => {
                  return (
                    <option key={item.officeId} value={item.officeId}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          }
          successButtonText={"Yes"}
          handleSuccess={handleDialogSuccess}
          failureButtonText={"Cancel"}
          handleFailure={() => {
            setPatient(null);
            setSelectedOffice("");
          }}
        />
      ) : null}
    </div>
  );
};

export default SearchScreen;
