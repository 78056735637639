import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { billedToOptions, formatCurrency } from "../../utils";
import ReportHeader from "./ReportHeader";
import ReportFooter from "./ReportFooter";
const FinancesReportPDF = ({ isPdf = false, selectedMonth = "" }: { isPdf?: boolean; selectedMonth?: string }) => {
  const { financeReportTabData } = useSelector((state: RootState) => state.finance);
  return (
    <div className={`${isPdf ? "w-full h-a4-finance relative" : ""}`}>
      {isPdf ? (
        <ReportHeader title="Finances" selectedMonth={selectedMonth} />
      ) : (
        <div className="w-full flex items-center justify-center p-2 bg-docsigna-purple-dark">
          <h3 className="text-lg font-semibold text-white font-roboto-regular">Finances</h3>
        </div>
      )}
      <div className={`${!isPdf ? "border-2 border-t-0 border-docsigna-blue-light" : ""} px-5 py-8`}>
        <div className="grid grid-cols-2">
          <div className="relative">
            <div className="absolute right-6 h-full w-px bg-docsigna-blue-dark" />
            <div className="grid grid-cols-2 gap-4 mb-5">
              <p className="text-sm">Payor</p>
              <p className="text-sm">Revenue</p>
            </div>
            {financeReportTabData?.billedTo?.length
              ? financeReportTabData.billedTo.map((item, ind) => {
                  return (
                    <div key={item.billedTo} className="grid grid-cols-2 gap-4 mb-2">
                      <p className="font-13 font-roboto-light">{billedToOptions[item.billedTo]}</p>
                      <p className="font-13 font-roboto-light">
                        {item?.amount !== undefined ? formatCurrency((item?.amount / 100)?.toString(), "blur") : "$0.00"}
                      </p>
                    </div>
                  );
                })
              : null}

            <div className="grid grid-cols-2 gap-4 mt-5 mb-5">
              <p className="font-roboto-regular text-sm">Total Revenue</p>
              <p className="font-roboto-regular text-sm">
                {financeReportTabData?.totalRevenue !== undefined
                  ? formatCurrency((financeReportTabData?.totalRevenue / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-5">
              <p className="font-roboto-regular text-sm">Expenses</p>
              <p className="text-sm"></p>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-2">
              <p className="font-13 font-roboto-light">Discounts Provided</p>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.discountsProvided !== undefined
                  ? formatCurrency((financeReportTabData?.discountsProvided / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-2">
              <p className="font-13 font-roboto-light">Docnote Fee 15%</p>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.feesOwedToDs !== undefined
                  ? formatCurrency((financeReportTabData?.feesOwedToDs / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-2">
              <p className="font-13 font-roboto-light">
                Stripe Fee
                <br />
                (2.9% + $0.30 per transaction)
              </p>
              <p className="font-13 font-roboto-light">
                {financeReportTabData?.stripeFee !== undefined
                  ? formatCurrency((financeReportTabData?.stripeFee / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-5 mb-5">
              <p className="font-roboto-regular text-sm">Net Income</p>
              <p className="font-roboto-regular text-sm">
                {financeReportTabData?.netIncome !== undefined
                  ? formatCurrency((financeReportTabData?.netIncome / 100)?.toString(), "blur")
                  : "$0.00"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center relative">
            <div>
              <div className="grid grid-cols-1 gap-4 mt-5 mb-5 text-center">
                <p className="font-roboto-regular text-sm">Invoices Outstanding (Office to Collect)</p>
              </div>
              <div className="">
                <div className="grid grid-cols-3 gap-4 mb-2">
                  <p className="font-13 font-roboto-light col-span-2">30 days past-due</p>
                  <p className="font-13 font-roboto-light text-right">
                    {financeReportTabData?.outstanding30DaysPastDue !== undefined
                      ? formatCurrency((financeReportTabData?.outstanding30DaysPastDue / 100)?.toString(), "blur")
                      : "$0.00"}
                  </p>
                </div>
                <div className="grid grid-cols-3 gap-4 mb-2">
                  <p className="font-13 font-roboto-light col-span-2">60 days past-due</p>
                  <p className="font-13 font-roboto-light text-right">
                    {financeReportTabData?.outstanding60DaysPastDue !== undefined
                      ? formatCurrency((financeReportTabData?.outstanding60DaysPastDue / 100)?.toString(), "blur")
                      : "$0.00"}
                  </p>
                </div>
                <div className="grid grid-cols-3 gap-4 mb-2">
                  <p className="font-13 font-roboto-light col-span-2">90+ days past-due</p>
                  <p className="font-13 font-roboto-light text-right">
                    {financeReportTabData?.outstanding90DaysPastDue !== undefined
                      ? formatCurrency((financeReportTabData?.outstanding90DaysPastDue / 100)?.toString(), "blur")
                      : "$0.00"}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 mt-5 mb-5">
                <p className="col-span-2 font-roboto-regular font-13 -ml-5">Total Invoices Outstanding</p>
                <p className="font-roboto-regular font-13 text-right">
                  {financeReportTabData?.outstandingTotalPastDue !== undefined
                    ? formatCurrency((financeReportTabData?.outstandingTotalPastDue / 100)?.toString(), "blur")
                    : "$0.00"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPdf ? <ReportFooter /> : null}
    </div>
  );
};

export default FinancesReportPDF;
