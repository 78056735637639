import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { formatCurrency, formatDate, formatOhipNumber, priceReview } from "../../../utils";
import Logo from "../../../../src/assets/images/logo.png";
import moment from "moment";
import { ServiceBilledTo } from "../../../models/service.model";

const InvoicePdf = ({
  notes,
  recipientContact,
  recipientName,
  recipientFax,
  recipientEmail,
  address,
}: {
  notes: string;
  recipientContact: string;
  recipientName: string;
  address: string;
  recipientFax?: string;
  recipientEmail?: string;
}) => {
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const { invoiceObject } = useSelector((state: RootState) => state.officeRequest);
  const billedTo = requestData?.serviceBilledToOverride ? requestData?.serviceBilledToOverride : requestData?.service?.serviceBilledTo;
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const {
    servicePrice,
    dscDesc,
    dscValue,
    blockFeeDiscountDesc,
    blockFeeDiscountAmount,
    additionalFeeTotal,
    subTotal,
    dsFee,
    dsFeeDesc,
    hstValue,
    hstPercentage,
    hstDesc,
    total,
  } = priceReview(invoiceObject ? invoiceObject : undefined);
  const [showHstInvoice, setShowHstInvoice] = useState(true);
  const [showDetailInvoice, setShowDeailInvoice] = useState(false);
  const [showWsib, setShowWsib] = useState(false);
  const [showRecipientDetails, setShowRecipientDetails] = useState(false);
  const [showOhip, setShowOhip] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showWsibAdderess, setShowWsibAddreess] = useState(false);

  const showDetails = (type: string) => {
    switch (type) {
      case ServiceBilledTo.OHIP:
        setShowHstInvoice(false);
        setShowDeailInvoice(true);
        setShowOhip(true);
        break;
      case ServiceBilledTo.Patient:
        break;
      case ServiceBilledTo.ServiceCanada:
        setShowDeailInvoice(true);
        setShowHstInvoice(false);
        setShowAddress(true);
        break;
      case ServiceBilledTo.ThirdParty:
        setShowRecipientDetails(true);
        break;
      case ServiceBilledTo.WSIB:
        setShowWsib(true);
        setShowWsibAddreess(true);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    showDetails(billedTo ? billedTo : "");
  }, [billedTo]);
  return (
    <>
      <div className="w-full h-a4 flex flex-col">
        <div className="px-8">
          <span className="w-8">
            <img src={Logo} alt="" className="w-48" />
          </span>
          <div className="flex justify-between">
            <p className="text-lg font-semibold mb-1 py-2">Invoice #{requestData?.requestNumber}</p>
            <p className="text-lg font-semibold mb-1 py-2">Date: {moment().format("ll")}</p>
          </div>
          <div id="invoice-details">
            <div>
              <h2 className="text-xl mb-1 font-semibold">{currentOffice?.name}</h2>
              <p className="font-light ">{`${currentOffice?.street}, ${currentOffice?.city},`}</p>
              <p className="font-light">{` ${currentOffice?.state}, ${currentOffice?.zip}`}</p>
              <p className="font-light">{` Phone: ${currentOffice?.phone ?? "-"}`}</p>
              <p className="font-light">{` Fax: ${currentOffice?.fax ?? "-"}`}</p>
              {showHstInvoice ? currentOffice?.taxNumber ? <p className="font-light mb-4"> HST # {currentOffice?.taxNumber}</p> : null : null}
              {showWsib ? (
                <div>
                  <p className="text font-semibold">WSIB# {currentOffice?.wsibNumber}</p>
                </div>
              ) : null}
              {showWsibAdderess && address !== "" ? (
                <div className="py-4">
                  <p className="font-semibold">Address:</p>
                  <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: address.replace(/\n/g, "<br>") }} />
                </div>
              ) : null}
            </div>
          </div>
          {showRecipientDetails ? (
            <div id="pdf-recipient-detail">
              {recipientName || recipientContact ? <p className="text font-semibold">Attention:</p> : null}
              {recipientName.length ? <p>{recipientName}</p> : null}
              {!(recipientEmail || recipientFax) && recipientContact.length ? <p>{recipientContact}</p> : null}
              {recipientEmail?.length ? <p>{recipientEmail}</p> : null}
              {recipientFax?.length ? <p>{recipientFax}</p> : null}
            </div>
          ) : null}
          <div>
            {" "}
            {showAddress && address !== "" ? (
              <div className="py-4">
                <p className="font-semibold">Attn: Service Canada</p>
                <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: address.replace(/\n/g, "<br>") }} />
              </div>
            ) : null}
          </div>
          <div id="invoice-patient-details" className="mt-3 mb-5">
            <p className="text font-semibold">Patient Details:</p>
            <p>
              {requestData?.firstName} {requestData?.lastName}
            </p>
            <p>Date of Birth: {formatDate(requestData?.dateOfBirth ? requestData?.dateOfBirth : "", "PP")}</p>

            {showOhip ? (
              <p className="font-light mb-6"> OHIP Number: {formatOhipNumber(requestData?.ohipNumber ? requestData?.ohipNumber : "")}</p>
            ) : null}
          </div>
        </div>
        <div id="invoice-fee" className="py-2  px-8" style={{ backgroundColor: "#F5F5F5" }}>
          <p className="text font-semibold mb-3">Fees</p>
        </div>

        <div className="px-8">
          <div className="flex">
            <p className="w-full">{requestData?.service?.name}</p>
            <p className="right-0 mr-0 ml-auto">{formatCurrency(servicePrice.toString(), "blur")}</p>
          </div>
          <div>
            {additionalFeeTotal > 0
              ? invoiceObject?.fees?.map((fee, index) => (
                  <div className="flex" key={index}>
                    <p className="w-full">{fee?.description}</p>
                    <p className="right-0 mr-0 ml-auto">${(fee.amount / 100).toFixed(2)}</p>
                  </div>
                ))
              : null}
          </div>
          <hr className="border border-black mt-4 mb-4" />
          {dscValue > 0 ? (
            <div className="flex">
              <p className="py-0.5 w-full ml-[50%] font-bold">{dscDesc}</p>
              <p className="right-0 mr-0 font-bold">{formatCurrency(dscValue.toString(), "blur")}</p>
            </div>
          ) : null}
          {blockFeeDiscountAmount > 0 ? (
            <div className="flex">
              <p className="py-0.5 w-full ml-[50%] font-bold">Block Fee: {blockFeeDiscountDesc}</p>
              <p className="right-0 mr-0 font-bold">{formatCurrency(blockFeeDiscountAmount.toString(), "blur")}</p>
            </div>
          ) : null}
          <div className="flex">
            <p className="py-0.5 w-full ml-[50%]">Subtotal</p>
            <p className="right-0 mr-0">{formatCurrency(subTotal.toString(), "blur")}</p>
          </div>
          {showDetailInvoice ? (
            <hr className="border border-black mt-4 mb-4" />
          ) : (
            <>
              <div>
                {showWsib ? null : dsFee > 0 ? (
                  <div className="flex">
                    <p className="py-0.5 w-full ml-[50%]">{dsFeeDesc}</p>
                    <p className="right-0 mr-0">{formatCurrency(dsFee.toString(), "blur")}</p>
                  </div>
                ) : null}
                {hstValue > 0 ? (
                  <div className="flex">
                    <p className="py-0.5 w-full ml-[50%]">{hstDesc ? `${hstDesc} (${hstPercentage ? `${hstPercentage}%` : ""})` : "HST"}</p>
                    <p className="right-0 mr-0">{formatCurrency(hstValue.toString(), "blur")}</p>
                  </div>
                ) : null}
              </div>
              <hr className="border border-black mt-4 mb-4" />
            </>
          )}
          <div className="flex">
            <p className="py-0.5 w-full ml-[50%] font-semibold">Invoice total</p>
            <p className="right-0 mr-0 font-semibold">{formatCurrency(total.toString(), "blur")}</p>
          </div>
        </div>

        <div className="py-8 px-8">
          {" "}
          {notes !== "" ? (
            <>
              <p className="font-semibold">Invoice Notes:</p>
              <p>{notes}</p>
            </>
          ) : null}
        </div>
      </div>
      <div>
        {" "}
        <p className="text-xs text-center text-docsigna-purple">
          481 London Road, Unit 109, Sarnia, ON N7T 4X3 {`${showHstInvoice ? "HST # 703533687 RT 0001" : ""}`}
        </p>
        <p className=" text-xs text-center text-docsigna-purple mb-5">
          This service has been provided by Bluewater Promotions Inc., operating as Docnote
        </p>
      </div>
    </>
  );
};

export default InvoicePdf;
