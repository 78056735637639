import React, { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { BreadCrumbs, Button, ConfirmationDialog, Pagination, PatientRequestForm, UserSideBar } from "../../components";
import { TableHeader, TableRow } from "../../components/TableComponents/TableComponents";
import { getPatientServiceList, setShowCurrentSubscription } from "../../redux/slices/PatientSlice";
import { Link, useParams } from "react-router-dom";
import { BreadCrumbsType } from "../../components/BreadCrumbs/BreadCrumbs";
import moment from "moment";
import { getUserRequests } from "../../redux/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { getSorting, patientRenderState, renderState } from "../../utils";
import { headers } from "./PatientDashboardData";
import { ServiceBilledTo } from "../../models/service.model";
import { sortObj } from "../../redux/slices/OfficeSlice";
import PatientScreenDialog from "../../assets/images/patient-screen-dailog.png";
import Dialog from "@mui/material/Dialog";
import PatientSubscriptionForm from "./PatientSubscritptionForm";
import PatientSubscriptionDetials from "./PatientSubscriptionDetails";
import { SESSION_STORAGE_PATIENT_BLOCK_FEE } from "../../utils/constants";
import { RefundRequestState, RequestType } from "../../models/request.model";
import UpdatePaymentMethod from "./UpdatePaymentMethod";

const SubscriptionDialog = ({ handleDialog }: { handleDialog: (val: boolean) => void }) => {
  return (
    <div className="relative 12">
      <img src={PatientScreenDialog} alt="" className="hidden md:block" />
      <div className="relative md:absolute md:top-10 md:left-12 z-10 w-full md:w-7/12 bg-[#DDEE59] md:bg-transparent p-6 md:p-0 rounded-lg md:rounded-none">
        <h3 className="heading font-normal text-2xl uppercase">New Block Fee Available</h3>
        <p>Your doctor has a block fee available that will save you money on your form requests.</p>
        <h4 className="mt-5 font-bold">Some of the items you will save on include:</h4>
        <ul className="pl-5 list-disc">
          <li>No charge for sick notes</li>
          <li>No charge for prescription renewals</li>
          <li>50% off all other forms</li>
        </ul>
        <Link to={"#"} className="text- text-xs text-docsigna-orange">
          Learn More
        </Link>
        <h3 className="heading font-normal text-lg mt-2">Interested in joining?</h3>
        <div className="flex gap-3 mt-3">
          <button onClick={() => handleDialog(false)} className="bg-slate-500 rounded-full px-7 py-1 text-white">
            No thanks
          </button>
          <button onClick={() => handleDialog(true)} className="bg-docsigna-orange rounded-full px-7 py-1 text-white">
            Sign Me Up
          </button>
        </div>
      </div>
    </div>
  );
};

const PatientRequests = () => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [extraClasses] = useState(headers.map(head => head.extraClasses));
  const [sortMethods, setSortMethods] = useState<boolean[]>(headers.map(() => false));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(-1);
  const [showAddOfficeSidebar, setShowAddOfficeSidebar] = useState(false);
  const [tableState, setTableState] = useState({
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "createdDate,desc",
  });
  const [showDialog, setShowDialog] = useState(false);
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);
  const [showUpdatePaymentMethodForm, setShowUpdatePaymentMethodForm] = useState(false);
  const [showCloseAlert, setShowCloseAlert] = useState(false);

  const { errorMessage, toastVisible, showCurrentSubscription } = useSelector((state: RootState) => state.patient);
  const { userRequest } = useSelector((state: RootState) => state.user);
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const loading = false;
  const office = currentUser?.offices?.[0];
  const { patientId } = useParams();

  const breadcrumbs: BreadCrumbsType[] = [
    {
      title: "Requests",
      key: "#",
    },
  ];

  const updateTable = (sortObj?: sortObj) => {
    const pageNumToBeCalled = sortObj?.pageNumber ?? tableState.pageNumber;
    const sizeToBeCalled = sortObj?.rowsPerPage ?? tableState.rowsPerPage;
    const sortBy = sortObj?.sortedBy ?? tableState.sortedBy;
    if (currentUser?.userId)
      dispatch(getUserRequests({ userId: currentUser?.userId, page: pageNumToBeCalled, size: sizeToBeCalled, sort: sortBy })).then(() => {
        setTableState({ ...tableState, pageNumber: pageNumToBeCalled, rowsPerPage: sizeToBeCalled, sortedBy: sortBy });
      });
  };

  const handleRowClick = (id: string) => {
    navigate(`/patients/${patientId}/requests/${id}`);
  };

  const handleDialog = (val: boolean) => {
    if (val) {
      setShowSubscriptionForm(true);
    }
    setShowDialog(!showDialog);
  };

  const handleSubmitPayment = (val: boolean) => {
    setShowSubscriptionForm(false);
    if (val) {
      dispatch(setShowCurrentSubscription(true));
    }
  };

  const handleUpdatePaymentMethod = async (val: boolean) => {
    setShowUpdatePaymentMethodForm(false);
    if (val) {
      await dispatch(setShowCurrentSubscription(true));
      toast?.openToast("Payment method updated!");
    }
  };

  const handleSort = (index: number) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    setActiveSortIndex(index);
    updateTable({ sortedBy: sortMethodString, pageNumber: 0, rowsPerPage: tableState.rowsPerPage });
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateTable({ sortedBy: tableState.sortedBy, pageNumber: newPage, rowsPerPage: tableState.rowsPerPage });
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTableState({ ...tableState, rowsPerPage: parseInt(event.target.value, 10) });
    updateTable({ sortedBy: tableState.sortedBy, pageNumber: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleChangeSubscription = () => {
    dispatch(setShowCurrentSubscription(false));
    setShowSubscriptionForm(true);
  };

  const handleChangeCard = async (val: boolean) => {
    await dispatch(setShowCurrentSubscription(false));
    setShowSubscriptionForm(false);
    setShowUpdatePaymentMethodForm(val);
  };

  const renderCloseMessage = () => {
    return (
      <div className="flex flex-col gap-4">
        <h2 className="font-bold">{office?.name} is currently closed</h2>
        <p>{office?.tempClosedReason ?? ""}</p>
        <p>Your request will not be reviewed until the office has reopened.</p>
      </div>
    );
  };

  const navigateRequest = () => navigate(`/patients/${patientId}/requests/create-request`);

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));

  const RenderTitleSubTitle = ({ title }: { title: string }) => {
    return (
      <>
        <span>{title}</span>
      </>
    );
  };

  useEffect(() => {
    localStorage.removeItem("redirectPath");
    const canShowBFDialog = sessionStorage.getItem(SESSION_STORAGE_PATIENT_BLOCK_FEE);
    if (canShowBFDialog === null && process.env.REACT_APP_ENV === "development") {
      setShowDialog(true);
      sessionStorage.setItem(SESSION_STORAGE_PATIENT_BLOCK_FEE, "yes");
    }
  }, []);

  useEffect(() => {
    dispatch(getPatientServiceList());
  }, []);

  useEffect(() => {
    if (currentUser?.userId) {
      updateTable();
    }
  }, [currentUser?.userId]);

  useEffect(() => {
    if (toastVisible) {
      toast?.openToast(errorMessage);
    }
  }, [toastVisible]);

  return (
    <>
      <main>
        <div className="container mx-auto p-4 md:p-8 min-h-[80vh]">
          <BreadCrumbs items={breadcrumbs} />
          <div className="md:flex justify-between items-center mb-4 md:mb-6">
            <h2 className="heading uppercase">Hi {currentUser?.firstName}</h2>
            <Button onClickFunc={() => (office?.tempClosed ? setShowCloseAlert(true) : navigateRequest())} text={"Submit a Request"} AdditionalClassNames="w-fit" />
          </div>

          <p className="text-base mb-2 md:mb-5 font-medium">You will find all your current and past form requests below. </p>

          {loading === false ? (
            <div className="block">
              <div className="flex justify-between items-center border-b border-docsigna-blue-light">
                {headers.map((head, index) => (
                  <TableHeader
                    title={head.title}
                    index={index}
                    key={index}
                    sortIcon={sortIcons[index]}
                    handleSort={handleSort}
                    extraClassesHeader={extraClasses[index]}
                    isClickable={head.isClickable}
                  />
                ))}
              </div>
              {userRequest.data !== undefined && userRequest.data !== null
                ? userRequest.data.content.map((rowData: any, index: number) => {
                    const sortedData = {
                      request: rowData.requestNumber ? rowData.requestNumber : "-",
                      service: rowData?.service?.name ? <RenderTitleSubTitle title={rowData.service.name} /> : "-",
                      provider: rowData?.primaryCareProvider?.firstName
                        ? `Dr. ${rowData.primaryCareProvider.firstName} ${rowData.primaryCareProvider.lastName}`
                        : "-",
                      status:
                        rowData?.refundRequest?.refundRequestState === RefundRequestState.Pending ? (
                          <span className={`text-docsigna-orange`}>Refund Requested</span>
                        ) : (rowData?.serviceBilledToOverride || rowData?.service?.serviceBilledTo) === ServiceBilledTo.Patient ||
                          rowData?.requestType === RequestType.DocnoteDebit ? (
                          rowData?.state ? (
                            renderState[rowData.state]
                          ) : (
                            "-"
                          )
                        ) : rowData?.state ? (
                          patientRenderState(rowData?.state)
                        ) : (
                          "-"
                        ),
                      created: moment(rowData.createdDate).format("ll"),
                      comment: (
                        <>
                          <span> {rowData.numMessages ? rowData.numMessages : "-"}</span>
                          {rowData.numUnreadMessages ? (
                            <span className="text-xs m-2 text-green-700 font-medium py-1 px-2 bg-green-100 rounded-full">
                              {rowData.numUnreadMessages} New
                            </span>
                          ) : null}
                        </>
                      ),
                    };
                    return (
                      <TableRow
                        handleCloseTooltip={() => {}}
                        handleOpenTooltip={() => {}}
                        tooltipVisible={false}
                        data={Object.values(sortedData)}
                        key={index}
                        id={index.toString()}
                        extraClassesRow={extraClasses}
                        // tooltipOptions={[""]}
                        handleTooltipClick={() => {
                          toast?.openToast();
                        }}
                        onRowClick={() => handleRowClick(rowData.requestId)}
                      />
                    );
                  })
                : null}
              {true ? (
                <Pagination
                  colSpan={10}
                  count={userRequest.data ? userRequest.data.totalElements : 0}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  page={tableState.pageNumber}
                  rowsPerPage={tableState.rowsPerPage}
                  // rowsPerPageOptions={[10, 25, 50, 100].filter(i => userRequest.data && i < userRequest.data?.totalElements)}
                />
              ) : null}
            </div>
          ) : (
            <h2>Loading ...</h2>
          )}
          <div></div>
        </div>
        <UserSideBar
          visible={showAddOfficeSidebar}
          LeftbuttonText="Cancel"
          RightbuttonText="Add"
          onCancelClick={() => setShowAddOfficeSidebar(false)}
          onInviteClick={() => setShowAddOfficeSidebar(false)}
          onClickOutside={() => setShowAddOfficeSidebar(false)}
          title="Submit a Request">
          <PatientRequestForm onClickCancel={() => setShowAddOfficeSidebar(false)} />
        </UserSideBar>

        {/* Subscription sidebar */}
        <UserSideBar
          visible={showSubscriptionForm}
          LeftbuttonText=""
          RightbuttonText=""
          onCancelClick={() => setShowSubscriptionForm(false)}
          onInviteClick={() => setShowSubscriptionForm(false)}
          onClickOutside={() => setShowSubscriptionForm(false)}
          title="Subscription Options">
          <PatientSubscriptionForm handleSubmitPayment={handleSubmitPayment} />
        </UserSideBar>

        <UserSideBar
          visible={showCurrentSubscription}
          LeftbuttonText=""
          RightbuttonText=""
          onCancelClick={() => dispatch(setShowCurrentSubscription(false))}
          onInviteClick={() => dispatch(setShowCurrentSubscription(false))}
          onClickOutside={() => dispatch(setShowCurrentSubscription(false))}
          title="Current Subscription">
          <PatientSubscriptionDetials handleChangeSubscription={handleChangeSubscription} handleChangeCard={handleChangeCard} />
        </UserSideBar>

        <UserSideBar
          visible={showUpdatePaymentMethodForm}
          LeftbuttonText=""
          RightbuttonText=""
          onCancelClick={() => dispatch(setShowCurrentSubscription(false))}
          onInviteClick={() => dispatch(setShowCurrentSubscription(false))}
          onClickOutside={() => dispatch(setShowCurrentSubscription(false))}
          title="Update Payment">
          <UpdatePaymentMethod handleUpdatePaymentMethod={handleUpdatePaymentMethod} />
        </UserSideBar>

        <Dialog open={showDialog} className="patient-block-fee-dialog">
          <SubscriptionDialog handleDialog={handleDialog} />
        </Dialog>
        <ConfirmationDialog
          open={showCloseAlert}
          description={renderCloseMessage()}
          handleFailure={() => setShowCloseAlert(false)}
          handleSuccess={() => navigateRequest()}
          successButtonText="Proceed"
          failureButtonText="Cancel"
        />
      </main>
    </>
  );
};

export default PatientRequests;
