import { headersType } from "../../models";

export const headers: headersType[] = [
  {
    title: "Name",
    extraClasses: "w-full pl-0 truncate",
    sortKey: "lastName",
    isClickable: true,
  },
  {
    title: "Email",
    extraClasses: "w-full hidden lg:block truncate",
    sortKey: "email",
    isClickable: true,
  },
  {
    title: "Role",
    extraClasses: "w-full hidden lg:block",
    sortKey: "userType.name",
    isClickable: true,
  },

  {
    title: "PCP",
    extraClasses: "w-180 hidden lg:block",
    sortKey: "",
    isClickable: false,
  },

  {
    title: "Status",
    extraClasses: "w-180 hidden lg:block",
    sortKey: "userState.name",
    isClickable: true,
  },
];
