import { PaymentDetails } from "../models/payment-details.model";
import { PaymentIntent } from "../models/payment-intent.model";
import { apiService } from "./api.service";

export class PaymentService {
  private static BASE_PATH = "/api/v1/stripe";

  static async getPaymentDetails(officeId: string, requestId: string): Promise<PaymentDetails | null> {
    const response = await apiService.get(`${this.BASE_PATH}/offices/${officeId}/requests/${requestId}/payment-details`);
    if (!response.success) return null;

    return response.data as PaymentDetails;
  }

  static async getPaymentIntent(intentId: string): Promise<PaymentIntent | null> {
    const response = await apiService.get(`${this.BASE_PATH}/payment-intents/${intentId}`);
    if (!response.success) return null;

    return response.data as PaymentIntent;
  }

  static async createPaymentIntent(requestId: string): Promise<PaymentIntent | null> {
    const response = await apiService.post(`${this.BASE_PATH}/payment-intents`, {
      requestId: requestId,
    });
    if (!response.success) return null;

    return response.data as PaymentIntent;
  }

  static async refundPayment(officeId: string, requestId: string, reason: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/offices/${officeId}/refund`, {
      requestId: requestId,
      reason
    });
    if (!response.success) return false;

    return true;
  }
}
