/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadedInvoice, uploadFileNew } from "../../../redux/slices/FileSlice";
import {
  addOfficeRequestFiles,
  createOfficeRequestInvoice,
  getOfficeRequest,
  getOfficeRequestInvoice,
} from "../../../redux/slices/OfficeRequestSlice";
import { RootState, AppDispatch } from "../../../redux/store";
import {
  formatCurrency,
  formatDate,
  formatOhipNumber,
  formatPhoneNumber,
  priceReview,
  validateEmail,
  validatePhoneOrFaxNumber,
} from "../../../utils";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import UserSideBar from "../../UserSideBar/UserSideBar";
import Logo from "../../../../src/assets/images/logo.png";
import moment from "moment";
import { ServiceBilledTo, ServiceBilledToName } from "../../../models/service.model";
import InvoicePdf from "./InvoicePdf";
import { Phone_Fax_Regex, colors } from "../../../utils/constants";
import { Contact, ContactMethodType } from "../../../models/contact-model";
import { ContactService } from "../../../services/contact.service";
import SelectWithSearch from "../../Select/SelectWithSearch";
import { DsFile, RequestFileTypes } from "../../../models/ds-file.model";

const html2pdf = require("html2pdf.js");

interface InvoiceFormType {
  onClose: () => void;
}

const InvoiceForm = ({ onClose }: InvoiceFormType) => {
  const dispatch = useDispatch<AppDispatch>();
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const { invoiceObject } = useSelector((state: RootState) => state.officeRequest);
  const billedTo = requestData?.serviceBilledToOverride ? requestData?.serviceBilledToOverride : requestData?.service?.serviceBilledTo;
  const currentOffice = useSelector((state: RootState) => state.office.currentOffice);
  const [notes, setNotes] = useState("");
  const [recipientContact, setRecipientContact] = useState(requestData?.recipientContact || "");
  const [recipientName, setRecipientName] = useState(requestData?.recipientName || "");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientFax, setRecipientFax] = useState("");
  const [sideBar, setSideBar] = useState(false);
  const [fileName, setFileName] = useState("DocnoteInvoice" + requestData?.requestNumber);
  const [savingPdf, setSavingPdf] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const {
    servicePrice,
    dscDesc,
    dscValue,
    blockFeeDiscountDesc,
    blockFeeDiscountAmount,
    additionalFeeTotal,
    subTotal,
    dsFee,
    dsFeeDesc,
    hstValue,
    hstPercentage,
    hstDesc,
    total,
  } = priceReview(invoiceObject ? invoiceObject : undefined);
  const [showHstInvoice, setShowHstInvoice] = useState(true);
  const [showDetailInvoice, setShowDeailInvoice] = useState(false);
  const [showWsib, setShowWsib] = useState(false);
  const [showRecipientDetails, setShowRecipientDetails] = useState(false);
  const [showOhip, setShowOhip] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showWsibAdderess, setShowWsibAddreess] = useState(false);
  const [serviceCanadaAdd, setServiceCanadaAdd] = useState(`PO Box 2020 Station Main
Chatham ON
N7M 6B2`);
  const [wsibAdd, setWsibAdd] = useState(`Workplace Safety and Insurance Board
200 Front St. West
15th Floor
Toronto ON M5V 3J1`);

  const [officeContacts, setOfficeContacts] = useState<Contact[]>([]);
  const [selectedContactsearch, setSelectContactSearch] = useState("");
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>(undefined);
  const [selectedContactMethod, setContactMethod] = useState<ContactMethodType>(ContactMethodType.Email);
  const [showError, setShowError] = useState<boolean>(false);

  const showDetails = (type: string) => {
    switch (type) {
      case ServiceBilledTo.OHIP:
        setShowHstInvoice(false);
        setShowDeailInvoice(true);
        setShowOhip(true);
        break;
      case ServiceBilledTo.Patient:
        break;
      case ServiceBilledTo.ServiceCanada:
        setShowDeailInvoice(true);
        setShowHstInvoice(false);
        setShowAddress(true);
        break;
      case ServiceBilledTo.ThirdParty:
        setShowRecipientDetails(true);
        break;
      case ServiceBilledTo.WSIB:
        setShowWsibAddreess(true);
        setShowWsib(true);
        break;
      default:
        break;
    }
  };

  const downloadInvoiceAsPdf = () => {
    setSavingPdf(true);
    const element = document.getElementById("invoice-container");
    const element1 = document.getElementById("invoice-container-pdf");
    const invoiceLoader = document.getElementById("invoice-loader");
    element1?.classList.remove("hidden");
    element?.classList.add("hidden");
    invoiceLoader?.classList.remove("hidden");
    element?.classList.add("min-w-full");

    const opt = {
      margin: [5, 0, 5, 0],
      filename: fileName,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4, dpi: 192, letterRendering: true, useCORS: true },
      jsPDF: {
        unit: "mm",
        format: "a4",
        compress: true,
        orientation: "portrait",
      },
      pagebreak: { mode: "avoid-all" },
    };
    html2pdf()
      .set(opt)
      .from(element1)
      .outputPdf("arraybuffer")
      .then((blob: Blob) => {
        element1?.classList.remove("hidden");
        element?.classList.add("hidden");
        element1?.classList.remove("min-w-full");
        element?.classList.add("h-80");
        invoiceLoader?.classList.add("hidden");

        const file = new File([blob], fileName + ".pdf", {
          type: "application/pdf",
        });

        setFileToUpload(file);
        dispatch(uploadedInvoice(true));
      });
  };

  const validateRecipientContact = () => {
    return (
      (!selectedContact && !(validateEmail(recipientContact) || Phone_Fax_Regex.test(recipientContact))) ||
      (selectedContact && !(validateEmail(recipientEmail) || Phone_Fax_Regex.test(recipientFax)))
    );
  };

  const handleSave = () => {
    setShowError(false);
    if (requestData?.serviceBilledToOverride === ServiceBilledTo.ThirdParty && (recipientName?.trim().length === 0 || validateRecipientContact())) {
      setShowError(true);
      return;
    }
    setSideBar(true);
    createOfficeRequest();
  };

  const createOfficeRequest = () => {
    if (requestData?.officeId && requestData?.requestId) {
      dispatch(createOfficeRequestInvoice({ officeId: requestData?.officeId, requestId: requestData?.requestId, notes: notes })).then(() => {
        dispatch(getOfficeRequest({ officeId: requestData?.officeId, requestId: requestData?.requestId }));
      });
    }
  };

  const handleEmailOrFaxInput = (event: React.ChangeEvent<HTMLInputElement>, cursorPosition: number) => {
    const value = event.currentTarget.value;
    if (validateEmail(value as string)) {
      setRecipientContact(value);
    } else if (validatePhoneOrFaxNumber(formatPhoneNumber(value as string))) {
      setRecipientContact(formatPhoneNumber(value));
    } else {
      setRecipientContact(value);
    }
  };

  const updateRecipients = (contact: Contact, method: ContactMethodType) => {
    if (contact) {
      setRecipientName(`${contact.company ?? ""}`);
      setRecipientContact(method === ContactMethodType.Email ? contact?.email ?? "" : contact.fax ?? "");
      setRecipientEmail(contact?.email ?? "");
      setRecipientFax(contact?.fax ?? "");
    }
  };

  const onContactOptionSelect = (contact: Contact) => {
    if (contact) {
      setSelectedContact(contact);
      setSelectContactSearch("");

      const newContactMethod =
        contact?.contactPreference && contact?.contactPreference !== "Phone"
          ? (contact?.contactPreference as ContactMethodType)
          : selectedContactMethod;
      setContactMethod(newContactMethod);
      updateRecipients(contact, newContactMethod);
    }
  };

  useEffect(() => {
    showDetails(billedTo ? billedTo : "");
  }, [billedTo]);

  useEffect(() => {
    if (!requestData?.recipientName) {
      if (requestData?.serviceBilledToOverride === ServiceBilledTo.WSIB || requestData?.serviceBilledToOverride === ServiceBilledTo.ServiceCanada) {
        setRecipientName(ServiceBilledToName[requestData?.serviceBilledToOverride]);
      }
    }

    if (requestData?.officeId && requestData?.requestId) {
      dispatch(getOfficeRequestInvoice({ officeId: requestData?.officeId, requestId: requestData?.requestId }));
    }
  }, [requestData]);

  useEffect(() => {
    if (fileToUpload) {
      dispatch(
        uploadFileNew({
          file: fileToUpload,
          callback: undefined,
        })
      ).then(async action => {
        const fileId = (action?.payload as DsFile)?.fileId;
        if (action?.payload !== null && requestData) {
          await dispatch(
            addOfficeRequestFiles({
              officeId: requestData?.officeId,
              requestId: requestData.requestId,
              fileIds: [fileId],
              requestFileType: RequestFileTypes.Invoice,
            })
          );
          setSavingPdf(false);
        }
      });
      setSideBar(false);
      onClose();
    }
  }, [fileToUpload]);

  useEffect(() => {
    const fetchContacts = async () => {
      if (requestData?.officeId && !officeContacts.length) {
        const resp = await ContactService.searchContacts(requestData?.officeId, 0, 200);
        if (resp?.content?.length) {
          setOfficeContacts(resp?.content);
        }
      }
    };
    fetchContacts();
  }, [requestData?.officeId]);

  return (
    <>
      <main className="w-screen h-screen bg-white flex justify-center  overflow-auto ">
        <div id="invoice-loader" className="w-screen h-screen absolute bg-white justify-center align-center hidden">
          <p className="mt-96">Generating PDF ...</p>
        </div>
        <div id="invoice-container" className="w-1/3 bg-white ">
          <div className="px-8">
            <span className="w-8">
              <img src={Logo} alt="" className="w-48" />
            </span>
            <div className="flex justify-between">
              <p className="text-lg font-semibold mb-1 py-2">Invoice #{requestData?.requestNumber}</p>
              <p className="text-lg font-semibold mb-1 py-2">Date: {moment().format("ll")}</p>
            </div>

            <div id="invoice-details">
              <div>
                <h2 className="text-xl mb-1 font-semibold">{currentOffice?.name}</h2>
                <p className="font-light ">{`${currentOffice?.street}, ${currentOffice?.city},`}</p>
                <p className="font-light">{` ${currentOffice?.state}, ${currentOffice?.zip}`}</p>
                <p className="font-light">{` Phone: ${currentOffice?.phone ?? "-"}`}</p>
                <p className="font-light">{` Fax: ${currentOffice?.fax ?? "-"}`}</p>
                <p className="font-light">{` ${currentOffice?.state}, ${currentOffice?.zip}`}</p>
                {showHstInvoice ? currentOffice?.taxNumber ? <p className="font-light mb-4"> HST # {currentOffice?.taxNumber}</p> : null : null}
                {showWsib ? (
                  <div>
                    <p className="text font-semibold">WSIB# {currentOffice?.wsibNumber}</p>
                  </div>
                ) : null}
                {showWsibAdderess ? (
                  <>
                    <p className="text font-semibold mt-4">Address:</p>
                    <TextInput extraInputClass="h-36" onChangeFunc={e => setWsibAdd(e.currentTarget.value)} value={wsibAdd} isTextArea />
                  </>
                ) : null}
              </div>
            </div>
            {showRecipientDetails ? (
              <div id="invoice-recipient-detail">
                <p className="text font-semibold">Attention:</p>
                <SelectWithSearch
                  value={selectedContact?.company ? `${selectedContact?.company}` : ""}
                  onSearcSelect={newalue => {
                    setSelectContactSearch(newalue);
                  }}
                  onSelect={newValue => {
                    onContactOptionSelect(newValue);
                  }}
                  options={officeContacts?.filter(o => `${o?.company?.toLowerCase()}`.includes(selectedContactsearch.toLowerCase())) || []}
                  placeholder={"Select Company"}
                  isSearchBox={true}
                  searchValue={selectedContactsearch}
                  renderOption={option => {
                    return <div>{option.company}</div>;
                  }}
                  renderValue={option => {
                    const renderVal = officeContacts?.find(o => `${o?.company}` === option);
                    return renderVal ? `${renderVal?.company}` : "";
                  }}
                  onSearchChange={(e, newValue) => {
                    setSelectContactSearch(newValue);
                  }}
                />
                <TextInput
                  onChangeFunc={e => setRecipientName(e.currentTarget.value)}
                  value={recipientName}
                  label="Recipient name"
                  onBlur={e => setRecipientName(e.currentTarget.value.trim())}
                  errorMsg="Please provide recipient name"
                  isError={showError && recipientName?.trim().length === 0}
                />
                {!selectedContact ? (
                  <>
                    <TextInput
                      onChangeFunc={e => handleEmailOrFaxInput(e, e.target.selectionEnd)}
                      value={recipientContact}
                      placeholder="Email or Fax"
                      label="Recipient contact"
                      errorMsg="Please provide valid email address or fax number with format 111-222-3333"
                      isError={showError && validateRecipientContact()}
                    />
                  </>
                ) : (
                  <>
                    <TextInput onChangeFunc={e => setRecipientEmail(e.target.value)} value={recipientEmail} label="Recipient email" />
                    <TextInput onChangeFunc={e => setRecipientFax(formatPhoneNumber(e.target.value))} value={recipientFax} label="Recipient fax" />
                    {showError && validateRecipientContact() ? (
                      <p style={{ color: colors.Red }}>Please provide at least one valid contact for the recipient, either an email or fax.</p>
                    ) : (
                      <p></p>
                    )}
                  </>
                )}
              </div>
            ) : null}
            <div id="pdf-recipient-detail" className="hidden">
              {recipientName || recipientContact ? <p className="text font-semibold">Attention:</p> : null}
              {recipientName.length ? <p>{recipientName}</p> : null}
              {recipientContact.length ? <p>{recipientContact}</p> : null}
            </div>

            {showAddress ? (
              <div>
                <h2 className="text-xl mb-1 mt-2 font-semibold">Attn: Service Canada</h2>
                <TextInput
                  extraInputClass="h-36"
                  onChangeFunc={e => setServiceCanadaAdd(e.currentTarget.value)}
                  value={serviceCanadaAdd}
                  isTextArea
                />
              </div>
            ) : null}

            <div id="invoice-patient-details" className="mt-3 mb-5">
              <p className="text font-semibold">Patient Details:</p>
              <p>
                {requestData?.firstName} {requestData?.lastName}
              </p>
              <p>Date of Birth: {formatDate(requestData?.dateOfBirth ? requestData?.dateOfBirth : "", "PP")}</p>

              {showOhip ? (
                <p className="font-light mb-6"> OHIP Number: {formatOhipNumber(requestData?.ohipNumber ? requestData?.ohipNumber : "")}</p>
              ) : null}
            </div>
          </div>
          <div id="invoice-fee" className="py-2 mt-2 mb-2 px-8" style={{ backgroundColor: "#F5F5F5" }}>
            <p className="text font-semibold">Fees</p>
          </div>
          <div id="pdf-fee" className="py-1 px-8 hidden" style={{ backgroundColor: "#F5F5F5" }}>
            <p className="text font-semibold bg-red mb-4">Fees</p>
          </div>
          <div className="px-8">
            <div className="flex">
              <p className="w-full">{requestData?.service?.name}</p>
              <p className="right-0 mr-0 ml-auto">{formatCurrency(servicePrice.toString(), "blur")}</p>
            </div>
            <div>
              {additionalFeeTotal > 0
                ? invoiceObject?.fees?.map((fee, index) => (
                    <div className="flex" key={index}>
                      <p className="w-full">{fee?.description}</p>
                      <p className="right-0 mr-0 ml-auto">${(fee.amount / 100).toFixed(2)}</p>
                    </div>
                  ))
                : null}
            </div>
            <hr className="border border-black mt-4 mb-4" />
            {dscValue > 0 ? (
              <div className="flex">
                <p className="py-0.5 w-full font-bold">{dscDesc}</p>
                <p className="right-0 mr-0 font-bold">{formatCurrency(dscValue.toString(), "blur")}</p>
              </div>
            ) : null}
            {blockFeeDiscountAmount > 0 ? (
              <div className="flex">
                <p className="py-0.5 w-full font-bold">Block Fee: {blockFeeDiscountDesc}</p>
                <p className="right-0 mr-0 font-bold">{formatCurrency(blockFeeDiscountAmount.toString(), "blur")}</p>
              </div>
            ) : null}
            <div className="flex">
              <p className="py-0.5 w-full">Subtotal</p>
              <p className="right-0 mr-0">{formatCurrency(subTotal.toString(), "blur")}</p>
            </div>
            {showDetailInvoice ? (
              <hr className="border border-black mt-4 mb-4" />
            ) : (
              <>
                <div>
                  {showWsib ? null : dsFee > 0 ? (
                    <div className="flex">
                      <p className="py-0.5 w-full">{dsFeeDesc}</p>
                      <p className="right-0 mr-0">{formatCurrency(dsFee.toString(), "blur")}</p>
                    </div>
                  ) : null}
                  {hstValue > 0 ? (
                    <div className="flex">
                      <p className="py-0.5 w-full">{hstDesc ? `${hstDesc} (${hstPercentage ? `${hstPercentage}%` : ""})` : "HST"}</p>
                      <p className="right-0 mr-0">{formatCurrency(hstValue.toString(), "blur")}</p>
                    </div>
                  ) : null}
                </div>
                <hr className="border border-black mt-4 mb-4" />
              </>
            )}
            <div className="flex">
              <p className="py-0.5 w-full ml-[50%] font-semibold">Invoice total</p>
              <p className="right-0 mr-0 font-semibold">{formatCurrency(total.toString(), "blur")}</p>
            </div>
          </div>
          <div id="notes-container" className="py-8 px-8">
            <div id="invoice-notes-input">
              <TextInput
                onChangeFunc={e => setNotes(e.currentTarget.value)}
                value={notes}
                isTextArea
                label="Invoice Notes"
                onBlur={e => setNotes(e.currentTarget.value.trim())}
              />
              <div className="w-full flex justify-end py-8">
                <div className="right-0 max-w-max">
                  <div className="flex">
                    <Button onClickFunc={onClose} text="Cancel" varient="Secondary" width="fit" AdditionalClassNames="px-8 mr-2" />
                    <Button
                      onClickFunc={() => {
                        handleSave();
                      }}
                      text="Save"
                      width="fit"
                      AdditionalClassNames="px-8 "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="invoice-notes-pdf" className="hidden">
              {notes !== "" ? (
                <>
                  <p className="font-semibold">Invoice Notes:</p>
                  <p>{notes}</p>
                </>
              ) : null}
            </div>
            <div className="" id="footer-div">
              <p className="text-xs text-center text-docsigna-purple">
                <span>481 London Road, Unit 109, Sarnia, ON N7T 4X3 </span>
                <br></br>
                <span>{`${showHstInvoice ? "HST # 703533687 RT 0001" : ""}`}</span>
              </p>
              <p className=" text-xs text-center text-docsigna-purple mb-5">
                This service has been provided by Bluewater Promotions Inc., <br></br>
                <span>operating as Docnote</span>
              </p>
            </div>
          </div>
        </div>
        <UserSideBar visible={sideBar} onInviteClick={() => setSideBar(false)} onClickOutside={() => setSideBar(false)} title="Save Invoice">
          <div className="px-8 py-4" key={"Save-invoice-form"}>
            <TextInput
              onChangeFunc={e => {
                setFileName(e.currentTarget.value);
              }}
              value={fileName}
              label="Invoice Name"
              placeholder="Enter invoice Name"
              isDarkBg={true}
            />
            <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
              <div className="px-8 py-5 flex justify-end">
                <Button
                  onClickFunc={() => setSideBar(false)}
                  AdditionalClassNames="pointer px-5 mr-3"
                  text="Cancel"
                  width="fit"
                  varient="Secondary"
                />
                <div id="save-button">
                  <Button
                    onClickFunc={() => {
                      downloadInvoiceAsPdf();
                    }}
                    AdditionalClassNames="pointer px-5"
                    text="Save"
                    width="fit"
                    disabled={fileName === ""}
                    varient={savingPdf ? "loading" : "Primary"}
                  />
                </div>
              </div>
            </div>
          </div>
        </UserSideBar>
      </main>
      <div id="invoice-container-pdf" className="flex-col hidden">
        <InvoicePdf
          notes={notes}
          recipientContact={recipientContact}
          recipientEmail={selectedContact && recipientEmail?.length && validateEmail(recipientEmail) ? recipientEmail : undefined}
          recipientFax={selectedContact && recipientFax?.length && Phone_Fax_Regex.test(recipientFax) ? recipientFax : undefined}
          recipientName={recipientName}
          address={showWsibAdderess ? wsibAdd : serviceCanadaAdd}
        />
      </div>
    </>
  );
};

export default InvoiceForm;
