import { BlockFee } from "../models/block-fee.model";
import { apiService } from "./api.service";

export class BlockFeeService {
  private static BASE_PATH = "/api/v1";
  private static BASE_PATH_OFFICES = "/api/v1/offices";
  private static BLOCK_FEE = "block-fees";

  static async getSubscriptionTypes(): Promise<string[]> {
    const response = await apiService.get(`${this.BASE_PATH}/subscription-types`);
    if (!response.success) return [];

    return response.data as string[];
  }

  static async createOfficeBlockFee(officeId: string,name: string,price: number,subscriptionType: string,enabled: boolean): Promise<BlockFee | number> {
    const response = await apiService.post(`${this.BASE_PATH_OFFICES}/${officeId}/${this.BLOCK_FEE}`,{
        officeId,
        subscriptionType,
        name,
        price,
        enabled
    });
    if (!response.success) return response.status ?? 500

    return response.data as BlockFee;
  }

  static async updateOfficeBlockFee(blockFeeId: string,officeId: string,name: string, subscriptionType: string,price: number,enabled: boolean): Promise<BlockFee | number> {
    const response = await apiService.put(`${this.BASE_PATH_OFFICES}/${officeId}/${this.BLOCK_FEE}/${blockFeeId}`,{
        officeId,
        blockFeeId,
        subscriptionType,
        name,
        price,
        enabled
    });
    if (!response.success) return response.status ?? 500

    return response.data as BlockFee;
  }

  static async getOfficeBlockFees(officeId: string): Promise<BlockFee[]> {
    const response = await apiService.get(`${this.BASE_PATH_OFFICES}/${officeId}/${this.BLOCK_FEE}`);
    if (!response.success) return [];

    return response.data as BlockFee[];
  }

  static async getOfficeSingleBlockFee(officeId: string): Promise<BlockFee | null> {
    const response = await apiService.get(`${this.BASE_PATH_OFFICES}/${officeId}/${this.BLOCK_FEE}`);
    if (!response.success) return null;

    return response.data as BlockFee;
  }

  static async removeBlockFee(officeId: string, blockFeeId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH_OFFICES}/${officeId}/block-fees/${blockFeeId}`);
    if (!response.success) return false;

    return true;
  }
}
