/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from "react";
import { FileTypes, ToastVariants } from "../../../models";
import { formatPhoneNumber, onFileError, validateEmail, validatePhoneOrFaxNumber } from "../../../utils";
import { colors } from "../../../utils/constants";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import DropArea from "../../DropArea/DropArea";
import TextInput from "../../TextInput/TextInput";
import { addRequest } from "../../../redux/slices/OnBoardingSlice";
import { RootState, AppDispatch } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { clearUploadedFiles } from "../../../redux/slices/FileSlice";
import { getUserRequests } from "../../../redux/slices/UserSlice";
import { RequestType } from "../../../models/request.model";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";

interface FormData {
  id: string;
  title: string;
  placeholder: string;
  type: string;
  value: string | boolean;
  errorMsg: string;
  required: boolean;
  valid: boolean;
  visible: boolean;
}
const initialState: FormData[] = [
  {
    id: "details",
    title: "",
    placeholder: "Enter notes here",
    type: "textarea",
    value: "",
    errorMsg: "Please enter the notes.",
    required: false,
    valid: false,
    visible: true,
  },
  {
    id: "section1",
    title: "Attachments",
    placeholder: "You can attach up to 10 JPEG, PDF or PNG files",
    type: "section",
    value: "",
    errorMsg: "",
    required: true,
    valid: true,
    visible: true,
  },
  {
    id: "droparea",
    title: "",
    placeholder: "",
    type: "droparea",
    value: "",
    errorMsg: "",
    required: true,
    valid: true,
    visible: true,
  },
  {
    id: "section2",
    title: "Recipient",
    placeholder: "A copy of your requested form will be sent to you.",
    type: "section",
    value: "",
    errorMsg: "",
    required: true,
    valid: true,
    visible: true,
  },
  {
    id: "additionalCopyCheck",
    title: "Additional copy of this form to be sent to a 3rd party.",
    placeholder: "",
    type: "checkbox",
    value: "",
    errorMsg: "",
    required: true,
    valid: true,
    visible: true,
  },
  {
    id: "name",
    title: "Recipient Name",
    placeholder: "Enter name",
    type: "textinput",
    value: "",
    errorMsg: "Please enter the name of recipient.",
    required: true,
    valid: false,
    visible: false,
  },
  {
    id: "emailOrFax",
    title: " Email or Fax Number",
    placeholder: "Enter email address or fax number",
    type: "textinput",
    value: "",
    errorMsg: "Please provide valid email address or fax number with format 111-222-3333",
    required: true,
    valid: false,
    visible: false,
  },
];
const PatientRequestForm = ({ onClickCancel }: { onClickCancel: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormData[]>([...initialState]);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [addRequestError, setAddRequestError] = useState(false);
  const [dropAreaError, setDropAreaError] = useState<string>("");
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const officeId = currentUser && currentUser?.offices && currentUser?.offices?.length ? currentUser.offices[0].officeId : undefined;

  const handleChange = (id: string, val: string | boolean) => {
    let temp = [...formData];
    const inputIndex = temp.findIndex(a => a.id === id);
    let value = val;
    let valid = value !== "";
    if (inputIndex !== -1) {
      switch (id) {
        case "additionalCopyCheck":
          const toChange = ["emailOrFax", "name"];
          if (value) {
            temp = temp.map(val => {
              const item = { ...val };
              if (toChange.includes(item.id)) {
                item.visible = true;
              }
              return item;
            });
          } else {
            temp = temp.map(val => {
              const item = { ...val };
              if (toChange.includes(item.id)) {
                item.visible = false;
                item.value = "";
                item.valid = false;
              }
              return item;
            });
          }
          break;
        case "emailOrFax":
          if (validateEmail((value as string).trim())) {
            valid = true;
          } else if (validatePhoneOrFaxNumber(formatPhoneNumber(value as string))) {
            valid = true;
            value = formatPhoneNumber(value as string);
          } else {
            valid = false;
          }
          break;
        case "name":
        case "details":
          valid = (value as string)?.trim().length !== 0;
          break;
        default:
          break;
      }
      setFormData([
        ...temp.slice(0, inputIndex),
        {
          ...temp[inputIndex],
          value: value,
          valid: valid,
        },
        ...temp.slice(inputIndex + 1),
      ]);
    }
  };

  const validateForm = () => {
    return formData.every(v => {
      if (!v.visible) return true;
      if (v.type === "section" || v.type === "additionalCopyCheck" || v.type === "droparea") return true;
      return v.valid;
    });
  };

  const saveRequest = () => {
    const details = formData.find(f => f.id === "details")?.value as string;
    const recipientName = formData.find(f => f.id === "name")?.value as string;

    const files = (formData.find(f => f.id === "droparea")?.value as string).split(",");
    const recipientContact = formData.find(f => f?.id === "emailOrFax")?.value as string;

    if (officeId && currentUser) {
      setLoading(true);
      dispatch(
        addRequest({
          request: {
            requestType: RequestType.Standard,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            dateOfBirth: currentUser.dateOfBirth ? currentUser.dateOfBirth : "",
            ohipNumber: currentUser.ohipNumber ? currentUser.ohipNumber : "",
            phone: currentUser.phone ? currentUser.phone : "",
            email: currentUser.email,
            details,
            fileIds: files[0].length ? files : [],
            recipientName: recipientName === "" ? undefined : recipientName,
            recipientContact: recipientContact === "" ? undefined : recipientContact,
            officeId: officeId,
            patientId: currentUser.userId,
            acceptedTerms: true,
          },
        })
      )
        .then(action => {
          if (action.payload !== null) {
            onClickCancel();
            dispatch(getUserRequests({ userId: currentUser.userId, page: 0, size: 10, sort: "createdDate,desc" }));
            toast?.openToast("Request created successfully.", 2000, ToastVariants.Success);
          } else {
            setAddRequestError(true);
          }
          setLoading(false);
        })
        .catch(_ => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      setFormData([...initialState]);
      dispatch(clearUploadedFiles());
    };
  }, []);

  return (
    <div>
      <div className="text-sm">
        {addRequestError ? (
          <p style={{ color: colors.Red }} className="text-base mb-5 px-8 py-2">
            {"Unable to create request at this time, please try again."}
          </p>
        ) : null}
        <div className="py-2 px-8">
          <span className="block text-base text-gray-700 font-bold">
            {"Notes"}
            <span className="text-red-500">*</span>
          </span>
        </div>
      </div>
      <p className="text-base px-8 font-medium">{"Please provide any information relevant to this request."}</p>
      <div className="px-8">
        {formData.map(val => {
          if (val.visible) {
            switch (val.type) {
              case "textarea":
                return (
                  <div key={val.id} className="py-2">
                    <TextInput
                      isTextArea
                      value={val.value as string}
                      onChangeFunc={e => handleChange(val.id, e.currentTarget.value)}
                      placeholder={val.placeholder}
                      isError={!val.valid && errorInitiated}
                      errorMsg={val.errorMsg}
                      label={val.title}
                      isRequired={val.required}
                      //onBlur={e => handleChange(val.id, e.currentTarget.value.trim())}
                      isDarkBg={true}
                    />
                  </div>
                );
              case "textinput":
                return (
                  <div key={val.id} className="py-2">
                    <TextInput
                      value={val.value as string}
                      onChangeFunc={e => handleChange(val.id, e.currentTarget.value)}
                      placeholder={val.placeholder}
                      isError={!val.valid && errorInitiated}
                      errorMsg={val.errorMsg}
                      label={val.title}
                      isRequired={val.required}
                      //onBlur={e => handleChange(val.id, e.currentTarget.value.trim())}
                      isDarkBg={true}
                    />
                  </div>
                );
              case "droparea":
                return (
                  <div key={val.id} className="py-2">
                    {dropAreaError ? <p className="pb-2 text-red-500">{dropAreaError}</p> : null}
                    <DropArea
                      allowedFileTypes={[FileTypes.JPEG, FileTypes.PNG, FileTypes.PDF]}
                      onFilesChange={e => handleChange(val.id, e.toString())}
                      onError={err => setDropAreaError(onFileError(err))}
                      maxFileSize={50 * 1024 * 1024}
                      maxNumOfFiles={10}
                      filePreviewVisible={true}
                      isDarkBg={true}
                    />
                  </div>
                );
              case "section":
                return (
                  <div key={val.id} className="py-2">
                    <p className="text-base">
                      <span className="block text-base text-gray-700 font-bold">{val.title}</span>
                      {val.placeholder}
                    </p>
                  </div>
                );
              case "checkbox":
                return (
                  <div key={val.id} className="py-2">
                    <Checkbox
                      label={val.title}
                      checked={val.value as boolean}
                      onChange={e => handleChange(val.id, e.currentTarget.checked)}
                      isDarkBg={true}
                    />
                    {!val.valid && errorInitiated ? <p style={{ color: colors.Red }}>{val.errorMsg}</p> : null}
                  </div>
                );
              default:
                break;
            }
          }
        })}
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            disabled={loading}
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div onClick={() => setErrorInitiated(true)}>
            <Button
              onClickFunc={() => {
                if (validateForm()) {
                  saveRequest();
                }
              }}
              varient={loading ? "loading" : "Primary"}
              disabled={(!validateForm() && errorInitiated) || loading}
              AdditionalClassNames="pointer px-5"
              text="Submit"
              width="fit"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PatientRequestForm;
