import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DsNotification, DsNotificationCounts } from "../../models/notification.model";
import { NotificationService } from "../../services/notification.service";
import { logger } from "../../utils/logger";

const log = logger.getLogger('NotificationErrors');
export interface NotificationSliceState {
  loading: boolean;
  notifications: DsNotification[];
  showNotifications: boolean;
  count: DsNotificationCounts;
  error: boolean;
  pageable: {
    totalPages: number;
    last: boolean;
    number: number;
  }
}

const initialState: NotificationSliceState = {
  loading: false,
  notifications: [],
  showNotifications: false,
  pageable: {
    totalPages: 0,
    last: true,
    number: 0,
  },
  count: {
    total: 0,
    unread: 0
  },
  error: false,
};

export const getUserNotificationCounts = createAsyncThunk(
    "notificationSlice/getUserNotificationCounts",
    async ({ userId }: { userId: string }, { rejectWithValue }) => {
        try {
            const res = await NotificationService.getUserNotificationCounts(userId);
            return res;
        } catch (e) {
            log.warn(JSON.stringify(e));
            return rejectWithValue(e);
        }
    }
);

export const getUserNotifications = createAsyncThunk(
    "notificationSlice/getUserNotifications",
    async ({ userId, page = 0 }: { userId: string; page?:number }, { rejectWithValue }) => {
        try {
            const res = await NotificationService.getUserNotifications(userId, page);
            if (res !== null) {
                return res;
            } else {
                return rejectWithValue(null);
            }
        } catch (e) {
          log.warn(JSON.stringify(e));
          return rejectWithValue(e);
        }
    }
);

export const markAllUserNotificationsAsRead = createAsyncThunk(
    "notificationSlice/markAllUserNotificationsAsRead",
    async ({ userId }: { userId: string }, { rejectWithValue, dispatch }) => {
        try {
            const res = await NotificationService.markAllUserNotificationsAsRead(userId);
            if (res !== null) {
                dispatch(getUserNotificationCounts({userId}));
                dispatch(getUserNotifications({userId, page:0}));
                return res;
            } else {
                return rejectWithValue(null);
            }
        } catch (e) {
          log.warn(JSON.stringify(e));
          return rejectWithValue(e);
        }
    }
);

export const setUserNotificationRead = createAsyncThunk(
    "notificationSlice/setUserNotificationRead",
    async ({ userId, notificationId, read }: { userId: string; notificationId: string; read: boolean }, { rejectWithValue, dispatch }) => {
        try {
            const res = await NotificationService.setUserNotificationRead(userId, notificationId, read);
            if (res !== null) {
                return res;
            } else {
                return rejectWithValue(null);
            }
        } catch (e) {
          log.warn(JSON.stringify(e));
          return rejectWithValue(e);
        }
    }
);

export const deleteUserNotification = createAsyncThunk(
    "notificationSlice/deleteUserNotification",
    async ({ userId, notificationId }: { userId: string; notificationId: string;}, { rejectWithValue, dispatch }) => {
        try {
            const res = await NotificationService.deleteUserNotification(userId, notificationId);
            if (res !== null) {
                return notificationId;
            } else {
                return rejectWithValue(null);
            }
        } catch (e) {
          log.warn(JSON.stringify(e));
          return rejectWithValue(e);
        }
    }
);

export const markAllUserNotificationsForRequestAsRead = createAsyncThunk(
    "notificationSlice/markAllUserNotificationsForRequestAsRead",
    async ({ userId, requestId }: { userId: string; requestId: string;}, { rejectWithValue, dispatch }) => {
        try {
            await NotificationService.markAllUserNotificationsForRequestAsRead(userId, requestId);
            dispatch(getUserNotificationCounts({userId}));
            dispatch(getUserNotifications({userId, page:0}));
        } catch (e) {
          log.warn(JSON.stringify(e));
          return rejectWithValue(e);
        }
    }
);

export const NotificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    clearNotifications: state => {
      state.notifications = [];
      state.count = initialState.count
      state.pageable = initialState.pageable
    },
    setShowNotifications: (state, action) => {
      state.showNotifications = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getUserNotificationCounts.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserNotificationCounts.fulfilled, (state, action: PayloadAction<DsNotificationCounts | any>) => {
        state.loading = false;
        if(action.payload) state.count = action.payload;
      })
      .addCase(getUserNotificationCounts.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getUserNotifications.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserNotifications.fulfilled, (state, action: PayloadAction<DsNotification | any>) => {
        state.loading = false;
        if(action.payload && action.payload?.content?.length) {
            state.notifications = action.payload.number ===0 ? [...action.payload.content] : [...state.notifications, ...action.payload.content];
            state.pageable = {
                totalPages: action.payload.totalPages,
                last: action.payload.last,
                number: action.payload.number,
            }
        }
      })
      .addCase(getUserNotifications.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(setUserNotificationRead.pending, state => {
        state.error = false;
      })
      .addCase(setUserNotificationRead.fulfilled, (state, action: PayloadAction<DsNotification | any>) => {
        state.loading = false;
        if(action.payload) {
            const oldNotifications = [...state.notifications];
            const index = oldNotifications.findIndex(item=> item.notificationId === action.payload.notificationId);
            if(index!==-1) {
                state.notifications = [...oldNotifications.slice(0, index), {...action.payload}, ...oldNotifications.slice(index+1)]
                if(action.payload.unread) {
                    state.count ={...state.count, unread: state.count.unread + 1}
                } else {
                    state.count = {...state.count, unread: state.count.unread - 1}
                }
            }
        }
      })
      .addCase(setUserNotificationRead.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteUserNotification.pending, state => {
        state.error = false;
      })
      .addCase(deleteUserNotification.fulfilled, (state, action: PayloadAction<DsNotification | any>) => {
        state.loading = false;
        const oldNotifications = [...state.notifications];
        if(action.payload){
            const index = oldNotifications.findIndex(item=> item.notificationId === action.payload);
            if(index!==-1) {
                const item = oldNotifications.find(item=> item.notificationId === action.payload);
                state.notifications = [...oldNotifications.slice(0, index), ...oldNotifications.slice(index+1)]
                if(item?.unread) {
                    state.count = {...state.count, unread: state.count.unread - 1}
                }
            }
        }    
      })
      .addCase(deleteUserNotification.rejected, state => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  clearNotifications, setShowNotifications
} = NotificationSlice.actions;
export default NotificationSlice.reducer;
