/* eslint-disable no-useless-escape */
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { TextInput, Button, GooglePlaceSearchComponent } from "../..";
import { formatPhoneNumber } from "../../../utils";
import { addOffice, listOfficeData } from "../../../redux/slices/OfficeSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { Phone_Fax_Regex, PrimaryContactEmail, colors } from "../../../utils/constants";
import { logger } from "../../../utils/logger";
import { CreateOffice } from "../../../models/create-office.model";

export interface AddOfficeInitDataType {
  id: string;
  placeholder: string;
  required: boolean;
  errorMesage: string;
  errorMesage1?: string;
  label: string;
  value: string;
  isValid: boolean;
}

const STREET_KEY = "route";
const CITY_KEY = ["locality", "sublocality_level_1"];
const STATE_KEY = "administrative_area_level_1";
const ZIP_KEY = ["zip_code", "postal_code"];
const COUNTRY_KEY = "country";
const STREET_NUMBER_KEY = "street_number";
const log = logger.getLogger("MapService");

interface GoogleAddressCompType {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GoogleAPIResponseType {
  address_components: GoogleAddressCompType[];
}

const getKeyValueOfAddress = (data: GoogleAPIResponseType | null, key: string | string[]) => {
  if (data === null) return "";

  if (Array.isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      const k = key[i];
      const addressComp: GoogleAddressCompType[] = data["address_components"];
      const keyArr = addressComp?.find(addrCom => addrCom.types.includes(k));
      if (keyArr !== undefined) {
        return keyArr["short_name"];
      }
    }
  } else {
    const addressComp: GoogleAddressCompType[] = data["address_components"];
    const keyArr = addressComp?.find(addrCom => addrCom.types.includes(key));
    return keyArr ? keyArr["short_name"] : "";
  }

  return "";
};

const addOfficeInitData: AddOfficeInitDataType[] = [
  {
    id: "officeName",
    label: "Office Name",
    placeholder: "Enter office name",
    required: true,
    errorMesage: "Please provide an office name",
    value: "",
    isValid: false,
  },
  {
    id: "address",
    label: "Address",
    placeholder: "Enter address",
    required: true,
    errorMesage: "Please provide an office address",
    errorMesage1: "A full address with street number is required",
    value: "",
    isValid: false,
  },
  {
    id: "phone",
    label: "Phone",
    placeholder: "Enter phone number",
    required: true,
    errorMesage: "Please enter a valid phone number",
    value: "",
    isValid: false,
  },
  {
    id: "fax",
    label: "Fax",
    placeholder: "Enter fax number",
    required: true,
    errorMesage: "Please enter a valid fax number",
    value: "",
    isValid: false,
  },
  {
    id: "primaryContactName",
    label: "Primary Contact Name",
    placeholder: "Enter primary contact name",
    required: true,
    errorMesage: "Please provide primary contact's name",
    value: "",
    isValid: false,
  },
  {
    id: "primaryContactEmail",
    label: "Primary Contact Email Address",
    placeholder: "Enter primary contact email",
    required: true,
    errorMesage: "Please provide primary contact's email",
    value: "",
    isValid: false,
  },
];

const AddOffice = ({
  onCancelClick,
  setIsLoading,
  isLoading,
}: {
  onCancelClick: () => void;
  setIsLoading: (val: boolean) => void;
  isLoading?: boolean;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [officeFormData, setOfficeFormData] = useState<AddOfficeInitDataType[]>(addOfficeInitData);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [errorMsgVisible, setErrorMsgVisible] = useState<boolean>(false);
  const [places, setPlaces] = useState("");
  const [addressValidate, setAddressValidate] = useState(false);
  const toast = useContext(ToastContext);
  const [addressData, setAddressData] = useState({ address_components: [] });
  const officeState = useSelector((state: RootState) => state.office);
  const { sort } = officeState;

  useEffect(() => {
    const index = officeFormData.findIndex(item => item.id === "address");
    setOfficeFormData([
      ...officeFormData.slice(0, index),
      {
        ...officeFormData[index],
        value: places,
        isValid: places.trim() !== "",
      },
      ...officeFormData.slice(index + 1),
    ]);
  }, [places]);

  useEffect(() => {
    if (errorInitiated && !officeState.apiSucess) {
      setErrorMsgVisible(true);
    } else {
      setErrorMsgVisible(false);
    }
  }, [officeState]);

  const validateField = (name: string, value: string) => {
    let valid = false;

    switch (name) {
      case "fax":
        valid = Phone_Fax_Regex.test(value);
        break;

      case "phone":
        valid = Phone_Fax_Regex.test(value);
        break;

      case "primaryContactEmail":
        valid = PrimaryContactEmail.test(value);
        break;

      case "primaryContactName":
        valid = !!value.trim();
        break;

      case "officeName":
        valid = !!value.trim();
        break;

      default:
        break;
    }
    return valid;
  };

  const handleBlur = (id: string, value: string) => {
    const index = officeFormData.findIndex(item => item.id === id);
    if (index !== -1) {
      setOfficeFormData([
        ...officeFormData.slice(0, index),
        {
          ...officeFormData[index],
          value,
        },
        ...officeFormData.slice(index + 1),
      ]);
    }
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const phoneNumLikeFlds = ["phone", "fax"];
    let value = e.currentTarget.value;
    if (phoneNumLikeFlds.includes(e.currentTarget.name)) {
      value = formatPhoneNumber(e.currentTarget.value);
    }

    const valid = validateField(e.currentTarget.name, value);
    setOfficeFormData([
      ...officeFormData.slice(0, index),
      {
        ...officeFormData[index],
        value,
        isValid: valid,
      },
      ...officeFormData.slice(index + 1),
    ]);
  };

  const disabledState = officeFormData.some(item => item.isValid === false);
  const getAddOfficeData = () => {
    return ["officeName", "address", "phone", "fax", "primaryContactEmail", "primaryContactName"]
      .map(key => {
        const foundVal = officeFormData?.find(el => el.id === key)?.value;
        return {
          [key]: foundVal ? foundVal : "",
        };
      })
      .reduce((acc, el) => {
        return { ...acc, ...el };
      }, {});
  };

  const validateAddress = (value: GoogleAddressCompType[]) => {
    const city = getKeyValueOfAddress({ address_components: value }, CITY_KEY);
    let streetNumber = getKeyValueOfAddress({ address_components: value }, STREET_NUMBER_KEY);
    if (typeof streetNumber !== "string") {
      streetNumber = "";
    }
    const street = getKeyValueOfAddress({ address_components: value }, STREET_KEY);
    const state = getKeyValueOfAddress({ address_components: value }, STATE_KEY);
    const zip = getKeyValueOfAddress({ address_components: value }, ZIP_KEY[0])
      ? getKeyValueOfAddress({ address_components: value }, ZIP_KEY[0])
      : getKeyValueOfAddress({ address_components: value }, ZIP_KEY[1]);
    const country = getKeyValueOfAddress({ address_components: value }, COUNTRY_KEY);
    const addressObj = { city, state, street, zip, country, streetNumber };
    log.debug(`Getting Map Address : ${JSON.stringify(addressObj)}`);
    if (!city || !street || !state || !zip || !country || !streetNumber) {
      return null;
    }

    return addressObj;
  };

  const onClickSave = () => {
    if (!disabledState) {
      //toast?.openToast();
      const { officeName, phone, fax, primaryContactEmail, primaryContactName } = getAddOfficeData();
      const addressValidation = validateAddress(addressData.address_components);
      if (addressValidation === null) {
        setAddressValidate(true);
        return;
      }
      setIsLoading(true);
      const dataToSend: CreateOffice = {
        name: officeName,
        city: addressValidation.city,
        street: addressValidation.streetNumber + " " + addressValidation.street,
        state: addressValidation.state,
        zip: addressValidation.zip,
        country: addressValidation.country,
        phone,
        fax,
        contactName: primaryContactName,
        contactEmail: primaryContactEmail,
      };
      setErrorInitiated(false);
      dispatch(addOffice(dataToSend))
        .then(action => {
          if (action.payload !== null) {
            dispatch(
              listOfficeData({
                page: sort.pageNumber,
                size: sort.rowsPerPage,
                sortType: sort.sortedBy,
              })
            );
            onCancelClick();
            toast?.openToast("Successfully added office!");
          }
          setIsLoading(false);
        })
        .catch(_ => {
          setIsLoading(false);
        });
    } else {
      setErrorInitiated(true);
    }
  };

  const onClickCancel = () => {
    onCancelClick();
  };

  const onPlaceSelect = (places: any) => {
    let addressValue = "";
    places?.address_components?.forEach((data: any) => {
      addressValue += `${data.long_name},`;
    });
    const addressValidation = validateAddress(places?.address_components);

    log.debug(`Response from google API : ${JSON.stringify(places)}`);

    if (addressValidation === null) {
      setAddressValidate(true);
      return;
    }
    setAddressValidate(false);
    setPlaces(addressValue);
    setAddressData({ address_components: places?.address_components });
  };

  const onAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "" || e.target.value === null) {
      setPlaces("");
    }
  };

  return (
    <div className="inline-block h-scrollable-screen overflow-auto">
      <p className="text-base px-8 py-5 font-medium">To add a new office, complete the information below.</p>
      {errorMsgVisible ? (
        <p className="text-center" style={{ color: colors.Red }}>
          An error has occurred adding this office
        </p>
      ) : null}
      {officeFormData.map((item: AddOfficeInitDataType, index) => {
        if (item.id === "address") {
          return (
            <div className="px-8 py-2" key={item.id}>
              <GooglePlaceSearchComponent
                label={item.label}
                isRequired={item.required}
                onPlaceSelected={onPlaceSelect}
                onChange={onAddressChange}
                countries={["us", "ca"]}
                errorMsg={addressValidate ? item.errorMesage1 : item.errorMesage}
                isError={(!item.isValid && errorInitiated) || addressValidate}
                placeholder={item.placeholder}
              />
            </div>
          );
        }
        if (item.id === "primaryContactEmail") {
          return (
            <div className="px-8 py-2" key={item.id}>
              <TextInput
                isError={!item.isValid && errorInitiated}
                value={item.value}
                name={item.id}
                onChangeFunc={e => handleFieldChange(e, index)}
                label={item.label}
                errorMsg={item.value === "" ? "Please provide primary contact's email" : "Please provide a valid email address"}
                isRequired={item.required}
                placeholder={item.placeholder}
                onBlur={e => handleBlur(item.id, e.target.value.trim())}
                isDarkBg={true}
              />
            </div>
          );
        }
        if (item.id === "phone" || item.id === "fax") {
          return (
            <div key={item.id} className="px-8 py-2">
              <TextInput
                isError={!item.isValid && errorInitiated}
                value={item.value}
                name={item.id}
                onChangeFunc={e => handleFieldChange(e, index)}
                label={item.label}
                errorMsg={item.errorMesage}
                isRequired={item.required}
                placeholder={item.placeholder}
                isDarkBg={true}
              />
            </div>
          );
        } else {
          return (
            <div key={item.id} className="px-8 py-2">
              <TextInput
                isError={!item.isValid && errorInitiated}
                value={item.value}
                name={item.id}
                onChangeFunc={e => handleFieldChange(e, index)}
                label={item.label}
                errorMsg={item.errorMesage}
                isRequired={item.required}
                placeholder={item.placeholder}
                onBlur={e => handleBlur(item.id, e.target.value.trim())}
                isDarkBg={true}
              />
            </div>
          );
        }
      })}

      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
            disabled={isLoading}
          />
          <div>
            <Button
              onClickFunc={onClickSave}
              disabled={(disabledState && errorInitiated) || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddOffice;
