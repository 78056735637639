import { useContext, useEffect, useState } from "react";
import { Pagination, RenderName, TableHeader, TableRow, UserSideBar } from "../../components";
import { headersType } from "../../models";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { searchBlockFeeSubscribers } from "../../redux/slices/OfficeSlice";
import { User } from "../../models/user.model";
import { AuthPermission } from "../../services/auth.service";
import EditPatientProfile from "../../components/forms/EditPatientProfile/EditPatientProfile";
import { getSorting } from "../../utils";

export const headers: headersType[] = [
  {
    title: "Name",
    extraClasses: "w-full pl-0 break-all",
    sortKey: "lastName",
    isClickable: true,
  },
  {
    title: "Email",
    extraClasses: "w-full hidden lg:block",
    sortKey: "email",
    isClickable: true,
  },
  {
    title: "Plan",
    extraClasses: "w-full hidden lg:block",
    sortKey: "blockFees",
    isClickable: true,
  },
];

const Subscribers = () => {
  const { id } = useParams();
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const [sortMethods, setSortMethods] = useState<boolean[]>(headers.map(() => false));
  const [sortKeys] = useState(headers.map(head => head.sortKey));
  const [activeSortIndex, setActiveSortIndex] = useState<number>(0);
  const [showTooltip, setShowTooltip] = useState(-1);
  const [openEditPatient, setOpenEditPatient] = useState(false);
  const [patientToBeUpdated, setPatientToBeUpdated] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [extraClasses] = useState<string[]>(headers.map(head => head.extraClasses));
  const { subscribers, subscribersSortObj } = useSelector((state: RootState) => state.office);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);

  // Hiding Plans from production
  if (process.env.REACT_APP_ENV === "production") {
    const planObj = headers.findIndex(obj => obj.title === "Plan");
    headers.splice(planObj, 1);
  }

  const load = async (page = 0, size = 20, sortBy = "lastName,desc", term?: string) => {
    if (id)
      dispatch(
        searchBlockFeeSubscribers({
          page,
          size,
          sort: sortBy,
          term,
          officeId: id,
        })
      );
  };

  const onPageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    load(newPage, subscribersSortObj.rowsPerPage, subscribersSortObj.sortedBy, subscribersSortObj.searchTerm);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    load(subscribersSortObj.pageNumber, parseInt(event.target.value, 10), subscribersSortObj.sortedBy, subscribersSortObj.searchTerm);
  };

  const handleTooltipClick = (type: string, id: string | null) => {
    const patient = subscribers.find(f => f.userId === id);
    if (id && patient) {
      setPatientToBeUpdated(patient);
      setOpenEditPatient(true);
    } else {
      toast?.openToast();
    }
  };

  const handleSort = (index: number) => {
    const sortMethodString = getSorting(sortKeys, index, sortMethods);
    setSortMethods([...sortMethods.slice(0, index), !sortMethods[index], ...sortMethods.slice(index + 1)]);
    setActiveSortIndex(index);
    load(subscribersSortObj.pageNumber, subscribersSortObj.rowsPerPage, sortMethodString, subscribersSortObj.searchTerm);
  };

  useEffect(() => {
    if (!subscribers.length) {
      load(subscribersSortObj.pageNumber, subscribersSortObj.rowsPerPage, subscribersSortObj.sortedBy);
    }
  }, []);

  const headerIconAsc = <p> ↑</p>;
  const headerIconDesc = <p> ↓</p>;
  const sortIcons = sortMethods.map((item, index) => (activeSortIndex === index ? (item ? headerIconAsc : headerIconDesc) : null));
  const tooltipOptions = currentUser?.permissions.includes(AuthPermission.User) ? ["Edit Patient"] : ["View Patient"];

  return (
    <div className="w-full relative">
      <div className="flex justify-between items-center border-b border-docsigna-blue-light">
        {headers.map((head, index) => (
          <TableHeader
            title={head.title}
            index={index}
            key={index}
            sortIcon={sortIcons[index]}
            handleSort={handleSort}
            extraClassesHeader={head.extraClasses}
            isClickable={head.isClickable}
          />
        ))}
        <div className="w-40 p-5 py-3">
          <span className="text-base text-docsigna-purple-dark inline-block w-5"></span>
        </div>
      </div>
      {subscribers.map((rowData: User, index) => {
        const sortedData = {
          name: <RenderName title={`${rowData.firstName} ${rowData.lastName}`} subTitle={""} />,
          email: rowData.email,
          plan: rowData?.blockFee?.name ?? "--",
        };
        return (
          <TableRow
            data={Object.values(sortedData)}
            key={index}
            id={rowData.userId}
            tooltipVisible={showTooltip === index}
            extraClassesRow={extraClasses}
            handleOpenTooltip={() => setShowTooltip(index)}
            handleCloseTooltip={() => setShowTooltip(-1)}
            tooltipOptions={tooltipOptions}
            handleTooltipClick={type => handleTooltipClick(type, rowData.userId || null)}
            onRowClick={() => null}
          />
        );
      })}
      <Pagination
        colSpan={10}
        count={subscribersSortObj?.totalElements ?? 0}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={subscribersSortObj.pageNumber}
        rowsPerPage={subscribersSortObj.rowsPerPage}
      />
      <UserSideBar
        onClickOutside={() => {
          !isLoading ? setPatientToBeUpdated(null) : null;
        }}
        title={`${patientToBeUpdated?.firstName} ${patientToBeUpdated?.lastName ?? ""}`}
        visible={openEditPatient && !!patientToBeUpdated}>
        <EditPatientProfile
          userData={patientToBeUpdated ?? undefined}
          isEditable={currentUser?.permissions.includes(AuthPermission.User)}
          onClickCancel={val => {
            setPatientToBeUpdated(null);
            if (val) {
              load(subscribersSortObj.pageNumber, subscribersSortObj.rowsPerPage, subscribersSortObj.sortedBy, subscribersSortObj.searchTerm);
            }
          }}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </UserSideBar>
    </div>
  );
};

export default Subscribers;
