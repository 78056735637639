import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FileTypes } from "../../models";
import { getOfficeServiceSampleFile } from "../../redux/slices/FileSlice";
import { AppDispatch, RootState } from "../../redux/store";
import Button from "../Button/Button";
import close from "../../assets/images/close.svg";
import { billedToOptions } from "../../utils";
import { Service } from "../../models/service.model";
import ProtectedComponent from "../ProtectedComponent/ProtectedComponent";
import { UserType } from "../../services/user.service";
import { AuthPermission } from "../../services/auth.service";

const ViewService = ({
  onCancelClick,
  service,
  officeId,
  isLoading = false,
  onCopyClick,
}: {
  onCancelClick: () => void;
  service: Service | null;
  officeId: string;
  onCopyClick: (service: Service) => void;
  isLoading: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const { getFileDetails } = useSelector((state: RootState) => state.file);
  const [modalopen, setModalOpen] = useState(false);
  const serviceToShow = service;

  useEffect(() => {
    if (serviceToShow?.sampleFileId && serviceToShow?.serviceId) {
      dispatch(getOfficeServiceSampleFile({ officeId, serviceId: serviceToShow?.serviceId }));
    }
  }, [serviceToShow]);

  return (
    <div className="px-8">
      <Modal open={modalopen} onClose={() => setModalOpen(false)}>
        <div className="modal relative z-10">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl">
                  <div className="bg-white p-4 xl:p-6">
                    <span className="modal-close absolute top-2 right-2 w-7 h-7 bg-white rounded-full">
                      <img src={close} alt="" className="w-full h-full cursor-pointer" onClick={() => setModalOpen(false)} />
                    </span>
                    {getFileDetails?.contentType === FileTypes.PDF ? (
                      <embed src={getFileDetails?.url} className="w-full" style={{ height: "80vh" }} />
                    ) : (
                      <img src={getFileDetails?.url} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="py-5">
        <p>
          {" "}
          <span className="font-semibold mr-20">Billed to </span>
          {serviceToShow && serviceToShow?.serviceBilledTo ? billedToOptions[serviceToShow.serviceBilledTo] : "To be confirmed"}
        </p>
      </div>
      <hr className="border-docsigna-blue-light" />
      <div className="py-2 mt-2">
        <p className="font-semibold text-md">Service Details</p>
      </div>
      <div className="py-2">
        <p className="font-semibold text-xs">Service Name</p>
        <p>{serviceToShow?.name}</p>
      </div>
      <div className="py-2">
        <p className="font-semibold text-xs">Service Price</p>
        <p>${(serviceToShow?.price || 0) / 100}</p>
      </div>
      <div className="py-2">
        <p className="font-semibold text-xs">HST Applicable</p>
        <p>{serviceToShow?.addTax ? "Yes" : "No"}</p>
      </div>
      <div className="py-2">
        {serviceToShow?.description ? <p className="font-semibold text-xs">Service Description</p> : null}
        <p>{serviceToShow?.description}</p>
      </div>
      <div className="py-2">
        {serviceToShow?.sampleFileId ? (
          <div className="cursor-pointer">
            {getFileDetails?.contentType === FileTypes.PDF ? (
              <div onMouseUp={() => setModalOpen(true)}>
                <embed src={getFileDetails.url} className="w-1/2" style={{ pointerEvents: "none" }} onClick={() => setModalOpen(true)} />
              </div>
            ) : (
              <img src={getFileDetails?.url} alt="" onClick={() => setModalOpen(true)} />
            )}
          </div>
        ) : null}
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <ProtectedComponent requiredPermission={currentUser?.userType === UserType.OfficeAdmin ? [AuthPermission.Service] : [AuthPermission.Admin]}>
            <Button
              varient="Secondary"
              onClickFunc={() => {
                onCancelClick();
                if (serviceToShow) onCopyClick(serviceToShow);
              }}
              AdditionalClassNames="pointer px-5 mr-5"
              text="Copy To Office"
              width="fit"
              disabled={isLoading}
            />
          </ProtectedComponent>
          <Button
            onClickFunc={() => {
              onCancelClick();
            }}
            disabled={isLoading}
            AdditionalClassNames="pointer px-5"
            text="Close"
            width="fit"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewService;
