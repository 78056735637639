import React from "react";

const DataNotFound = ({ image, text }: { image: string; text: string }) => {
  return (
    <div className="w-full flex flex-col-reverse text-center p-8">
      <div>
        <span className="font-medium text-xl text-docsigna-purple-dark">{text}</span>
      </div>
      {image ? (
        <div className="flex justify-center mb-2">
          <img src={image} alt="no data found" className="md:h-60" />
        </div>
      ) : null}
    </div>
  );
};

export default DataNotFound;
