import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PaymentService } from "../../services/payment.service";
import { PaymentIntent } from "../../models/payment-intent.model";
import { PaymentDetails } from "../../models/payment-details.model";
import { logger } from "../../utils/logger";

const log = logger.getLogger('PaymentErrors');
export interface PaymentSliceState {
  loading: boolean;
  message: string;
  amount: number;
  paymentIntent: PaymentIntent | null;
  error: boolean;
  createIntentError: boolean;
  getIntentError: boolean;
  cancelled: boolean;
  paymentDetails: PaymentDetails | null;
}

const initialState: PaymentSliceState = {
  loading: false,
  message: "",
  amount: 0,
  paymentIntent: null,
  error: false,
  createIntentError: false,
  cancelled: false,
  getIntentError: false,
  paymentDetails: null,
};

export const PaymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {
    setAmount: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },
    setPaymentIntent: (state, action: PayloadAction<PaymentIntent>) => {
      state.paymentIntent = action.payload;
    },
    resetPaymentSlice: state => {
      state.loading = false;
      state.message = "";
      state.amount = 0;
      state.paymentIntent = null;
      state.error = false;
      state.createIntentError = false;
      state.cancelled = false;
      state.getIntentError = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createPaymentIntent.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createPaymentIntent.fulfilled, (state, action: PayloadAction<PaymentIntent | null>) => {
        state.loading = false;
        state.paymentIntent = action.payload;
      })
      .addCase(createPaymentIntent.rejected, state => {
        state.loading = false;
        state.error = true;
        state.createIntentError = true;
      })
      .addCase(getPaymentIntent.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getPaymentIntent.fulfilled, (state, action: PayloadAction<PaymentIntent | null>) => {
        state.loading = false;
        state.paymentIntent = action.payload;
      })
      .addCase(getPaymentIntent.rejected, state => {
        state.loading = false;
        state.error = true;
        state.getIntentError = true;
      })
      .addCase(getPaymentDetails.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getPaymentDetails.fulfilled, (state, action: PayloadAction<PaymentDetails | null>) => {
        state.loading = false;
        state.paymentDetails = action.payload;
      })
      .addCase(getPaymentDetails.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(refundPayment.pending, state => {
        state.loading = true;
      })
      .addCase(refundPayment.fulfilled, state => {
        state.loading = false;
      })
      .addCase(refundPayment.rejected, state => {
        state.loading = false;
      });
  },
});

export const createPaymentIntent = createAsyncThunk(
  "PaymentSlice/createPaymentIntent",
  async ({ requestId }: { requestId: string }, { rejectWithValue }) => {
    try {
      const res = await PaymentService.createPaymentIntent(requestId);
      if (res !== null) {
        return res;
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);
export const getPaymentDetails = createAsyncThunk(
  "PaymentSlice/getPaymentDetails",
  async ({ officeId, requestId }: { officeId: string; requestId: string }, { rejectWithValue }) => {
    try {
      const res = await PaymentService.getPaymentDetails(officeId, requestId);
      if (res !== null) {
        return res;
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const refundPayment = createAsyncThunk(
  "PaymentSlice/refundPayment",
  async ({ officeId, requestId, reason }: { officeId: string; requestId: string; reason: string; }, { rejectWithValue }) => {
    try {
      const res = await PaymentService.refundPayment(officeId, requestId, reason);
      if (res !== null) {
        return res;
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const getPaymentIntent = createAsyncThunk("PaymentSlice/getPaymentIntent", async ({ intentId }: { intentId: string }, { rejectWithValue }) => {
  try {
    const res = await PaymentService.getPaymentIntent(intentId);
    if (res !== null) {
      return res;
    } else {
      return rejectWithValue(null);
    }
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(e);
  }
});

// Action creators are generated for each case reducer function
export const { setAmount, setPaymentIntent, resetPaymentSlice } = PaymentSlice.actions;

export default PaymentSlice.reducer;
