import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { OtpInput, TextInput } from "../../components";
import Button from "../../components/Button/Button";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { updateLogInEmail } from "../../redux/slices/CurrentUserSlice";
import { AppDispatch } from "../../redux/store";
import { validateEmail, validatePass } from "../../utils";
import { colors, SESSION_STORAGE_SHOW_MFA } from "../../utils/constants";
import bgImg from "../../assets/images/banner.jpg";
import useHomeRoute from "../../hooks/useHomeRoute";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../../models";
import { CircularProgress } from "@mui/material";

const LoginScreen = () => {
  const toast = useContext(ToastContext);
  const auth = useContext(AuthContext);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { homeLink } = useHomeRoute();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let emailParams = searchParams.get("email") || "";
  emailParams = emailParams ? emailParams.replace(/ /g, "+") : "";

  const [email, setemail] = useState(emailParams ?? "");
  const [password, setpassword] = useState("");
  const [showMFA, setShowMFA] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [initErrorCheck, setinitErrorCheck] = useState(false);
  /*
   * Validate the form, show error messages if invalid
   * Call login function and redirect if form is validated
   */
  const onLogInClick = async (resend?: boolean) => {
    setinitErrorCheck(true);
    setEmailError(!validateEmail(email));
    setPasswordError(!validatePass(password));
    if (validateEmail(email) && validatePass(password)) {
      setLoading(true);
      const Login = await auth?.signin({
        email: email.trim(),
        password: password.trim(),
      });
      if (Login) {
        if (Login?.emailVerificationRequired) {
          setShowMFA(true);
          if (resend) {
            toast?.openToast("A new code has been sent to your email.", 2000, ToastVariants.Success);
          }
        } else {
          navigate(homeLink);
        }
      }
      setLoading(false);
    }
  };

  const handleSubmitOTP = async () => {
    setLoading(true);
    const res = await auth?.authenticateMFA(email.trim(), otpInput);
    if (res) {
      setShowMFA(false);
      setEmailError(true);
      navigate(homeLink);
    } else {
      setEmailError(true);
    }
    setLoading(false);
  };

  const onEmailChange = (email: string) => {
    if (initErrorCheck) {
      setEmailError(!validateEmail(email));
    }
    setemail(email);
  };

  const onPassChange = (pass: string) => {
    if (initErrorCheck) {
      setPasswordError(!validatePass(pass));
    }
    setpassword(pass);
  };

  const onForgotPassword = () => {
    navigate("/forgot-password");
  };

  useEffect(() => {
    dispatch(updateLogInEmail({ email: email }));
  }, [email]);

  useEffect(() => {
    if (otpInput.length === 6) {
      handleSubmitOTP();
    } else {
      setEmailError(false);
    }
  }, [otpInput]);

  useEffect(() => {
    const email = sessionStorage.getItem(SESSION_STORAGE_SHOW_MFA);
    if (email) {
      setShowMFA(true);
      setemail(email);
      sessionStorage.removeItem(SESSION_STORAGE_SHOW_MFA);
    }
  }, []);

  return (
    <main className="">
      {!showMFA ? (
        <div
          onKeyDown={e => {
            if (e.key === "Enter") {
              onLogInClick();
            }
          }}>
          <section className="max-h-[21rem] overflow-hidden">
            <img src={bgImg} alt="bg" />
          </section>
          <section className="lg:flex m-auto w-full lg:w-9/12 mt-5 sm:mt-10 lg:gap-16 px-5 sm:px-10">
            <div className="w-full lg:w-1/2 rounded-t-3xl rounded-b-3xl lg:rounded-b-none p-10 bg-docsigna-blue-dark text-white font-medium mb-5 lg:mb-0">
              <p>
                If you have a Docnote account, please sign in using the form below. <span className="inline-block">the form below.</span>
              </p>
              <p className="mt-5">
                Looking to pay for a Docnote Debit request? Please click{" "}
                <span className="cursor-pointer text-docsigna-orange" onClick={() => navigate("/guest/login")}>
                  HERE
                </span>
                .
              </p>
              <p className="mt-5">
                Otherwise, you can initiate a request by searching for your doctor’s office from the{" "}
                <span className="inline-block">
                  main page{" "}
                  <span className="cursor-pointer text-docsigna-orange" onClick={() => navigate("/")}>
                    HERE
                  </span>
                  .
                </span>
              </p>
            </div>
            <div className="w-full lg:w-1/2 m-auto rounded-3xl p-10 bg-docsigna-pink-light mb-5 sm:mb-10">
              <ul>
                <li>
                  <h2 className="heading uppercase font-semibold">Login</h2>
                </li>
                <li className="mt-5">
                  <TextInput
                    label="Email"
                    isRequired
                    errorMsg={"Please enter a valid email address"}
                    value={email}
                    placeholder="Enter email address"
                    isError={emailError}
                    onChangeFunc={e => onEmailChange(e.target.value)}
                    isDarkBg={true}
                  />
                </li>
                <li className="mt-5">
                  <TextInput
                    label="Password"
                    isRequired
                    errorMsg={"Please enter your password"}
                    isError={passwordError}
                    value={password}
                    placeholder="Enter password"
                    onChangeFunc={e => onPassChange(e.target.value)}
                    isPassword
                    isDarkBg={true}
                  />
                </li>
                <li className="mt-5 flex justify-between items-center">
                  <Button text="Login" onClickFunc={() => onLogInClick()} disabled={auth?.pending || emailError || passwordError} />
                  <span className="font-medium cursor-pointer" onClick={onForgotPassword}>
                    Forgot password?
                  </span>
                </li>
              </ul>
              <p className="text-center" style={{ color: colors.Red }}>
                {auth?.errorMessage}
              </p>
            </div>
          </section>
        </div>
      ) : (
        <div className={`flex justify-center items-center w-full min-h-[calc(100vh-100px)] bg-banner-image bg-auto rounded-[30px]`}>
          <div className="md:w-1/2 w-max h-max px-20 py-10 rounded-2xl bg-white">
            <h3 className="text-3xl font-bold mb-2">Verification Code</h3>
            <p className="text-base mb-2">We have sent a 6 digit code to your email.</p>
            <p className="text-base mb-5">Please check your email and enter the code provided below.</p>
            <OtpInput length={6} onChange={e => setOtpInput(e)} />
            {emailError ? <p style={{ color: colors.Red }}>{"Code entered is invalid. Please click Try again to request a new code."}</p> : null}
            <div className="tab-content py-5 w-80">
              {loading ? (
                <CircularProgress size="1.5rem" sx={{ color: colors.Orange }} />
              ) : (
                <Button text="Continue" width="full" disabled={otpInput.length !== 6} onClickFunc={() => handleSubmitOTP()} varient={"Primary"} />
              )}
            </div>
            <p className="text-base font-medium">
              Didn’t receive a code?{" "}
              <span className="text-base text-docsigna-purple-dark cursor-pointer" onClick={() => onLogInClick(true)}>
                Try again
              </span>{" "}
            </p>
          </div>
        </div>
      )}
    </main>
  );
};

export default LoginScreen;
