import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../models/user.model";
import { UserService } from "../../services/user.service";
import { authService } from "../../services/auth.service";
import { logger } from "../../utils/logger";
import { ApiError, ApiErrorCode } from "../../models/api-error.model";
import { UserUpdate } from "../../models/user-update.model";

const log = logger.getLogger('AuthErrors');

export interface InitialState {
  error: boolean;
  errorMessage: string;
  loading: boolean;
  currentUser: User | null | undefined;
  logInEmail: string | null;
  fullScreenLoader: boolean;
}

const initialState: InitialState = {
  error: false,
  loading: false,
  errorMessage: "",
  currentUser: undefined,
  logInEmail: null,
  fullScreenLoader: false,
};

export const getUser = createAsyncThunk("authApi/getUser", async (_, { rejectWithValue }) => {
  try {
    const userID = authService.currentUserId ? authService.currentUserId : "";
    const currentUser = await UserService.getUser(userID);
    return currentUser;
  } catch (err) {
    log.warn(JSON.stringify(err));
    return rejectWithValue(err);
  }
});

export const emailExists = createAsyncThunk("authApi/emailExists", async ({ email }: { email: string }, { rejectWithValue }) => {
  try {
    const res = await UserService.emailExists(email);
    return res;
  } catch (e) {
    if ((e as ApiError).code === ApiErrorCode.EXISTS) {
      return rejectWithValue(ApiErrorCode.EXISTS);
    } else {
      log.warn(JSON.stringify(e));
      return rejectWithValue(ApiErrorCode.UNKNOWN);
    }
  }
});

export const forgotPassword = createAsyncThunk("authApi/forgotPassword", async ({ email }: { email: string }, { rejectWithValue }) => {
  try {
    const res = await authService.forgotPassword(email);
    return res;
  } catch (err) {
    log.warn(JSON.stringify(err));
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk(
  "authApi/resetPassword",
  async ({ email, code, password }: { email: string; code: string; password: string }, { rejectWithValue }) => {
    try {
      const res = await authService.resetPassword(email, code, password);
      return res;
    } catch (err) {
      log.warn(JSON.stringify(err));
      return rejectWithValue(err);
    }
  }
);

export const updatePatientUserProfile = createAsyncThunk("authApi/updatePatientUserProfile", async ({ body, userId, officeId }: {body: UserUpdate, userId: string, officeId: string}, { rejectWithValue }) => {
  try {
    const res = await authService.updatePatientUserProfile(userId, officeId, body);
    return res;
  } catch (err) {
    log.warn(JSON.stringify(err));
    return rejectWithValue(err);
  }
});

export const CurrentUserSlice = createSlice({
  name: "authApi",
  initialState,
  reducers: {
    resetState: state => {
      state.errorMessage = initialState.errorMessage;
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.currentUser = initialState.currentUser;
    },
    setCurrentUserNull: state => {
      state.currentUser = null;
    },
    updateLogInEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.logInEmail = action.payload.email;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.pending, state => {
        state.loading = true;
        state.error = false;
        if(!state.currentUser) state.fullScreenLoader = true;
      })
      .addCase(getUser.rejected, state => {
        state.loading = false;
        state.error = true;
        state.currentUser = null;
        state.fullScreenLoader = false;
      })
      .addCase(getUser.fulfilled, (state, action: PayloadAction<User | null>) => {
        if (action.payload !== null) {
          state.loading = false;
          state.error = false;
          state.currentUser = action.payload;
          state.fullScreenLoader = false;
        } else {
          state.loading = false;
          state.error = true;
          state.currentUser = action.payload;
          state.fullScreenLoader = false;
        }
      })
      .addCase(emailExists.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(emailExists.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(emailExists.fulfilled, (state, action: PayloadAction<boolean>) => {
        if (action.payload !== null) {
          state.loading = false;
          state.error = false;
        } else {
          state.loading = false;
          state.error = true;
        }
      })
      .addCase(forgotPassword.pending, state => {
        state.loading = true;
        state.error = false;
        state.fullScreenLoader = false;
      })
      .addCase(forgotPassword.rejected, state => {
        state.loading = false;
        state.error = true;
        state.fullScreenLoader = false;
      })
      .addCase(forgotPassword.fulfilled, state => {
        state.loading = false;
        state.error = false;
        state.fullScreenLoader = false;
      })
      .addCase(resetPassword.pending, state => {
        state.loading = true;
        state.error = false;
        state.fullScreenLoader = false;
      })
      .addCase(resetPassword.rejected, state => {
        state.loading = false;
        state.error = true;
        state.fullScreenLoader = false;
      })
      .addCase(resetPassword.fulfilled, state => {
        state.loading = false;
        state.error = false;
        state.fullScreenLoader = false;
      })
      .addCase(updatePatientUserProfile.fulfilled, (state, action: PayloadAction<User | null>) => {
        if (action.payload !== null) {
          state.currentUser = action.payload;
        }  
      });
  },
});

// Action creators are generated for each case reducer function
export const { resetState, setCurrentUserNull, updateLogInEmail } = CurrentUserSlice.actions;

export default CurrentUserSlice.reducer;
