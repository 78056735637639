import React, { useEffect, useContext, useState } from "react";
import { Box, ConfirmationDialog, TextInput } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { RequestPaymentMethod, RequestState } from "../../models/request.model";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";

import moment from "moment";
import { getPaymentDetails, refundPayment } from "../../redux/slices/PaymentSlice";
import { CircularProgress } from "@mui/material";
import { getExpiry } from "../../utils";
import { getOfficeRequest, setOfficeRequestState } from "../../redux/slices/OfficeRequestSlice";
import { ToastVariants } from "../../models";
import { addOfficeRequestTimelineEvent } from "../../redux/slices/UserSlice";

const PaymentInfoTab = ({ officeId, requestId }: { officeId?: string; requestId?: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const { paymentDetails, loading } = useSelector((state: RootState) => state.payment);
  const toast = useContext(ToastContext);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [refundReason, setRefundReason] = useState("");
  const paymentCompleted = requestData?.paymentComplete;
  const externalPayment = paymentCompleted && requestData?.paymentMethod === RequestPaymentMethod.External;

  const handleConfirmationBox = async (valueSelected: "Confirm" | "Cancel") => {
    if (valueSelected === "Cancel") {
      setConfirmationOpen(false);
      return;
    }
    if (!refundReason?.trim().length) {
      toast?.openToast("Please provide refund reason", 2000, ToastVariants.Warn);
      return;
    }
    if (valueSelected === "Confirm") {
      if (requestData?.officeId && requestData?.requestId) {
        await dispatch(refundPayment({ officeId: requestData?.officeId, requestId: requestData?.requestId, reason: refundReason }));
        await dispatch(
          addOfficeRequestTimelineEvent({
            event: `${currentUser?.firstName + " " + currentUser?.lastName} issued a refund. Reason provided was ${refundReason}`,
            officeId: requestData?.officeId,
            requestId: requestData?.requestId,
          })
        );
        await dispatch(setOfficeRequestState({ officeId: requestData?.officeId, requestId: requestData?.requestId, state: RequestState.Cancelled }));
        await dispatch(
          addOfficeRequestTimelineEvent({
            event: `${currentUser?.firstName + " " + currentUser?.lastName} updated status to ${RequestState.Cancelled}`,
            officeId: requestData?.officeId,
            requestId: requestData?.requestId,
          })
        );
        await dispatch(getOfficeRequest({ officeId: requestData?.officeId, requestId: requestData?.requestId }));
        toast?.openToast("Payment refunded successfully!");
        setConfirmationOpen(false);
      }
    }
  };

  const refundReasonDetaitls = () => {
    return (
      <div>
        <p className="text-base font-semibold mb-2">Please provide details for refund</p>
        <p className="text-base mb-2">
          <TextInput isTextArea onChangeFunc={e => setRefundReason(e.target.value)} value={refundReason} extraInputClass="resize-none min-h-52" />
        </p>
      </div>
    );
  };

  const handleRefund = () => {
    if (requestData?.paymentDate && moment().diff(requestData?.paymentDate, "days") > 30) {
      toast?.openToast("This request is more than 30 days old, unfortunately, we are unable to process a refund at this time.");
      return;
    }
    setConfirmationOpen(true);
  };

  useEffect(() => {
    if (officeId && requestId) dispatch(getPaymentDetails({ officeId, requestId }));
  }, []);

  return (
    <div className="flex flex-wrap md:flex-nowrap">
      {loading ? (
        <div className="px-[50%] mt-20">
          <CircularProgress />
        </div>
      ) : (
        <Box title="">
          {paymentCompleted ? (
            <>
              {!externalPayment ? (
                paymentDetails?.accountName?.length ? (
                  <>
                    <p className="text-base mb-2">Transaction Date: {moment(requestData?.paymentDate).format("ll")}</p>
                    <p className="text-base mb-2">
                      Card Type: <span className="capitalize">{paymentDetails?.cardType}</span>
                    </p>
                    <p className="text-base mb-2">
                      Cardholder Name: <span className="capitalize">{paymentDetails?.accountName}</span>{" "}
                    </p>
                    <p className="text-base mb-2">Card Number: XXXX XXXX XXXX {paymentDetails?.cardNumberLastDigits} </p>
                    <p className="text-base mb-2">Expiry: {getExpiry(paymentDetails?.expiry)}</p>
                    <p className="text-base font-semibold pb-3 mb-3">Amount: ${(paymentDetails ? paymentDetails?.amount / 100 : 0).toFixed(2)}</p>
                    <hr className="my-4 " />
                    <p className="text-base cursor-pointer">
                      <span onClick={() => toast?.openToast()} className="text-docsigna-blue-light">
                        Resend Receipt
                      </span>
                    </p>
                    {!requestData?.refundRequest ? (
                      <p className="text-base mt-3 cursor-pointer">
                        <span onClick={handleRefund} className=" text-docsigna-blue-light">
                          Refund Payment
                        </span>
                      </p>
                    ) : null}
                  </>
                ) : (
                  <p className="text-base">No payment information available.</p>
                )
              ) : (
                <>
                  <p className="text-base font-semibold pb-3 mb-3">Payment completed in office - {moment(requestData?.paymentDate).format("ll")}</p>
                  {requestData?.paymentDetails ? (
                    <>
                      <p className="text-base font-semibold pb-3 mb-3">Payment Details:</p>
                      <p className="text-base pb-3 mb-3">{requestData?.paymentDetails}</p>
                    </>
                  ) : null}
                </>
              )}
            </>
          ) : requestData?.refunded ? (
            <p className="text-base">Payment refunded to patient.</p>
          ) : (
            <p className="text-base">No payment information available.</p>
          )}
        </Box>
      )}
      <ConfirmationDialog
        open={confirmationOpen}
        title={""}
        description={refundReasonDetaitls()}
        successButtonText={"Confirm"}
        handleSuccess={() => {
          handleConfirmationBox("Confirm");
        }}
        failureButtonText={"Cancel"}
        handleFailure={() => {
          handleConfirmationBox("Cancel");
        }}
        actionButtonPosition="Left"
        loading={loading}
        canClickOutSide={false}
      />
    </div>
  );
};
export default PaymentInfoTab;
