import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, ConfirmationDialog, TextInput } from "../components";
import TermsAndConditions from "./Guest/TermsAndCondition";
import PrivacyPolicy from "./Guest/PrivacyPolicy";
import "../assets/css/demo.css";
import Logo from "../assets/images/logo.png";
import VideoPoster from "../assets/images/demo-video.png";
import ReactPlayer from "react-player";
import { Dialog, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Phone_Fax_Regex, colors } from "../utils/constants";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { ContanctFormFieldTypes } from "./Doctors";
import { formatPhoneNumber, validateEmail } from "../utils";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { submitWebsitePublicContactForm } from "../redux/slices/OnBoardingSlice";
import { ToastContext } from "../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../models";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const contanctFormData: ContanctFormFieldTypes[] = [
  {
    id: "name",
    type: "text",
    label: "Name",
    placeholder: "Your name*",
    required: true,
    errorMesage: "Please provide a name",
    value: "",
    isValid: false,
  },
  {
    id: "email",
    type: "email",
    label: "Email",
    placeholder: "Your email*",
    required: true,
    errorMesage: "Please provide an email address",
    value: "",
    isValid: false,
  },
  {
    id: "phone",
    type: "phone",
    label: "Phone",
    placeholder: "Your phone number",
    required: false,
    errorMesage: "Please provide a phone number",
    value: "",
    isValid: true,
  },
  {
    id: "office",
    type: "text",
    label: "Medical Office",
    placeholder: "Your medical office address",
    required: false,
    errorMesage: "Please provide a valid office address",
    value: "",
    isValid: true,
  },
  {
    id: "message",
    type: "textarea",
    label: "Message",
    placeholder: "Message",
    required: false,
    errorMesage: "Please provide a message",
    value: "",
    isValid: true,
  },
];

export default function Demo() {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const { innerWidth: width, innerHeight: height } = window;
  const [open, setOpen] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [contactFormFields, setContactFormFields] = useState<ContanctFormFieldTypes[]>(contanctFormData);
  const [place, setPlace] = useState("");
  const [loading, setLoading] = useState(false);
  const [agreeCheckbox, setAgreeCheckbox] = useState(true);

  const autoCompleteRef = useRef<any>(null);

  const videoRef = useRef<any>(null);
  const videoPosRef = useRef<any>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };

  const handleShowVideo = () => {
    if (videoRef?.current) {
      videoRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setShowVideo(true);
  };

  const validateForm = () => {
    return contactFormFields
      .map(v => {
        if (v.required === false) return true;
        return v.isValid;
      })
      .every(v => v);
  };

  const handleChange = (id: string, val: string) => {
    const oldValues = [...contactFormFields];
    const index = oldValues.findIndex(a => a.id === id);
    let value = val;
    let valid = value?.trim() !== "";
    switch (id) {
      case "phone":
        if (value?.trim().length) {
          value = formatPhoneNumber(value as string);
          valid = Phone_Fax_Regex.test(value);
        } else {
          valid = true;
        }
        break;
      case "email":
        valid = validateEmail((value as string).trim());
        break;
      default:
        break;
    }
    setContactFormFields([
      ...oldValues.slice(0, index),
      {
        ...oldValues[index],
        value: value,
        isValid: valid,
      },
      ...oldValues.slice(index + 1),
    ]);
  };

  const handleSubmit = async () => {
    setShowError(false);
    setLoading(true);
    const formValid = validateForm();
    if (formValid) {
      const res = await dispatch(
        submitWebsitePublicContactForm({
          name: contactFormFields[0].value.trim(),
          email: contactFormFields[1].value,
          phone: contactFormFields[2].value,
          office: place,
          message: contactFormFields[4].value.trim(),
          agreeUpdates: agreeCheckbox,
        })
      );
      if (res) {
        toast?.openToast("Form submitted successfully.", 2000, ToastVariants.Warn);
        setShowError(false);
        setContactFormFields(contanctFormData);
        setPlace("");
        const inputElement: any = document.getElementById("googleSearch");
        // Clear the value of the google input field
        if (inputElement && inputElement?.value) {
          inputElement.value = "";
        }
      } else {
        toast?.openToast("Something went wrong. Try again!", 2000, ToastVariants.Error);
      }
    } else {
      setShowError(true);
    }
    setLoading(false);
  };

  const onPlaceSelect = (places: any) => {
    let addressValue = "";
    places?.address_components?.forEach((data: any) => {
      addressValue += `${data.long_name},`;
    });
    if (addressValue) {
      setPlace(addressValue);
    }
  };

  const onAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "" || e.target.value === null) {
      setPlace("");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const scrollToVideo = () => {
    const scroll = Math.abs(videoPosRef.current.getBoundingClientRect().top - (window.innerHeight * 0.1));
    window.scrollTo({ top: scroll, left: 0, behavior: "smooth" });
  }

  return (
    <main className="demo__body">
      <header className="demo__header">
        <div className="container flex justify-between items-center">
          <Link to={"/"}>
            <img src={Logo} alt="" className="logo" />
          </Link>
          <Button text="Watch the video" onClickFunc={handleShowVideo} width="fit" AdditionalClassNames="hidden sm:block" />
          <button className="block sm:hidden" onClick={handleShowVideo}>
            <PlayCircleOutlineIcon htmlColor={colors.Orange} fontSize={"large"} />
          </button>
        </div>
      </header>
      <section className="demo__banner mt-5 sm:mt-0">
        <div className="container demo__banner-wrapper flex gap-6">
          <div className="demo__banner-content w-full md:w-1/2">
            <h6 className="demo__title">The all-in-one platform for uninsured medical services</h6>
            <h1 className="demo__title">
              Reinventing <br />
              the Uninsured <br />
              Experience
            </h1>
            <span className="text-docsigna-pink-light text-xl sm:text-3xl">
              Take your uninsured services <br />program to the next level and join <br />the movement.
            </span>
            <div className=" mt-3">
              <Link
                to="#"
                className="demo__link text-docsigna-pink-light flex items-center gap-2"
                // onClick={e => {
                //   e.preventDefault();
                //   setOpenCalendar(true);
                // }}
                onClick={() => scrollToVideo()}>
                Learn More <span className="inline-block rotate-90 mt-1">&#62;</span>
              </Link>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <iframe
              title="Booking Calender"
              src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1wLdrFSM3svULSrd2DZVA1QOGHMjMNvBKcIPJ4c1tGVbICIh_hnBCXkXp-CjNk-pXwoyqAoJaT"
              width={'100%'}
              height={width < 769 ? height : "580"}
              className="bg-white rounded-3xl"
            />
          </div>
        </div>
      </section>

      <section className="px-3 mt-10" ref={videoPosRef}>
        <div className="grid justify-center text-center gap-4">
          <p className="text-sm sm:text-lg text-docnote-green font-bold uppercase tracking-widest">A better approach to providing uninsured services</p>
          <p className="text-2xl sm:text-3xl text-docsigna-pink-light">
            More than a point solution,
            <br /> Docnote takes you all the way.
          </p>
          <div className="demo__video" ref={videoRef}>
            <div className="w-full md:w-1/2 relative my-6 m-auto rounded-2xl sm:rounded-3xl overflow-hidden" onClick={() => setShowVideo(true)}>
              <img
                src={VideoPoster}
                alt=""
                className={`w-full cursor-pointer relative z-1 ${!showVideo ? "block" : "invisible opacity-0"}`}
              />
              <div className="absolute left-0 top-0 w-full h-full z-0">
                <div className={`w-full h-full ${showVideo ? "block" : "invisible opacity-0"}`}>
                  <div className="responsive-iframe">
                    <ReactPlayer url={"https://vimeo.com/868926969"} playing={showVideo} controls width={"100%"} height={"100%"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-docsigna-pink-light mt-14 mb-10 hidden sm:block">
            Ready to realize the full  potential of <br />your uninsured services program?
          </p>
          <p className="text-2xl sm:text-4xl font-bold text-docsigna-pink-light mt-14 mb-10 block sm:hidden">
            Ready to realize the full <br />potential of your uninsured <br />services program?
          </p>
          <Button text="Talk To A Representative" onClickFunc={() => setOpenCalendar(true)} width="fit" AdditionalClassNames={'demo-button-big'} />
        </div>
      </section>

      <section className="demo__section demo__join-community">
        <div className="container">
          <div className="demo__join-community-wrapper">
            <div className="demo__join-community-wrapper-inside">
              <h6 className="demo__title text-docsigna-blue-dark text-xl text-center">Get in touch</h6>
              <p className="text-docsigna-blue-dark text-lg text-center">
                {`Have some questions to ask before booking the call?`} <br className="hidden sm:block" />{" "}
                {`No problem! Fill out the form below and we'll
                be in touch.`}
              </p>
              <div className="md:flex flex-col justify-center gap-6 mt-8 items-center">
                {contactFormFields?.map((item, index) => {
                  switch (item.id) {
                    case "message":
                      return (
                        <div className="md:w-7/12 mb-3 md:mb-0">
                          <textarea
                            value={item.value}
                            placeholder="Your message"
                            onChange={e => handleChange(item.id, e.target.value)}
                            className="demo__inputTextarea shadow-none outline-none bg-docsigna-blue-dark h-36 resize-none"
                          />
                        </div>
                      );
                    case "office":
                      return (
                        <div key={index} className="md:w-7/12 mb-3 md:mb-0">
                          <ReactGoogleAutocomplete
                            itemType="text"
                            id="googleSearch"
                            className={`text-base font-medium block w-full rounded-md p-2.5 border border-docsigna-blue-dark shadow-none outline-none bg-transparent demo__inputText bg-docsigna-blue-dark`}
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            ref={autoCompleteRef}
                            onPlaceSelected={onPlaceSelect}
                            onChange={onAddressChange}
                            countries={["us", "ca"]}
                            placeholder={item.placeholder}
                            options={{
                              types: ["establishment"],
                              componentRestrictions: { country: ["ca", "us"] },
                            }}
                          />
                        </div>
                      );
                    default:
                      return (
                        <div key={index} className="md:w-7/12 mb-3 md:mb-0">
                          <TextInput
                            placeholder={item.placeholder}
                            name={item.id}
                            value={item.value}
                            onChangeFunc={e => handleChange(item.id, e.target.value)}
                            isRequired={item.required}
                            isError={showError && !item.isValid}
                            errorMsg={item.errorMesage}
                            extraInputClass="demo__inputText shadow-none outline-none bg-docsigna-blue-dark"
                          />
                        </div>
                      );
                  }
                })}
              </div>
              <div className="md:flex md:justify-center md:items-center my-4">
                <div className="md:flex md:justify-between items-center md:w-7/12">
                  <div>
                    <label className="flex justify-start items-center text-docsigna-blue-dark">
                      <div>
                        <input
                          checked={agreeCheckbox}
                          type="checkbox"
                          className="outline-none shadow-none p-2.5"
                          onChange={e => setAgreeCheckbox(e.target.checked)}
                        />
                        <span className="checkmark"></span>
                      </div>
                      <div className="ml-3">
                        <p className="text-xs">I agree to receive Docnote updates, <br className="block lg:hidden" />announcements and offers</p>
                      </div>
                    </label>
                  </div>
                  <div className="w-42 mt-3 sm:mt-0">
                    <Button text={"Submit"} width="fit" onClickFunc={handleSubmit} disabled={loading} varient={loading ? "loading" : "Primary"} />
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <hr className="md:w-7/12 border-docsigna-blue-dark" />
              </div>
              <div className="flex justify-center text-left mt-4">
                <p className="md:w-7/12 text-docsigna-blue-dark text-xs">
                  {`By clicking submit you're confirming that you agree with our `}
                  <a
                    href="/"
                    className="font-medium"
                    onClick={e => {
                      e.preventDefault();
                      handleClickOpen();
                    }}>
                    {"Terms and Conditions"}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="demo__footer">
        <div className="container space-y-2 lg:space-y-0">
          <div className="footer-left font-medium text-docsigna-pink-light">Copyright © 2023 Docnote. All rights reserved.</div>
          <div className="footer-right">
            <a
              href="/"
              className="font-medium text-docsigna-pink-light"
              onClick={e => {
                e.preventDefault();
                handleClickOpen();
              }}>
              Terms of Services
            </a>
            <span>|</span>
            <a
              href="/"
              className="font-medium text-docsigna-pink-light"
              onClick={e => {
                e.preventDefault();
                handleClickOpenPrivacyPolicy();
              }}>
              Privacy Policy
            </a>
          </div>
        </div>
      </footer>

      <Dialog open={openCalendar} className="demo__dialog relative">
        <DialogActions className="absolute top-3 right-4 cursor-pointer z-10">
          <CloseIcon htmlColor={colors.DarkBlue} style={{ fontSize: "28px" }} onClick={() => setOpenCalendar(false)} />
        </DialogActions>
        <iframe
          title="Booking Calender"
          src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1wLdrFSM3svULSrd2DZVA1QOGHMjMNvBKcIPJ4c1tGVbICIh_hnBCXkXp-CjNk-pXwoyqAoJaT"
          width={width < 769 ? width : "700"}
          height={width < 769 ? height : "580"}
          className="bg-white rounded-3xl"
        />
      </Dialog>
      <ConfirmationDialog
        maxWidth="lg"
        successButtonText=""
        failureButtonText="Close"
        open={open}
        description={<TermsAndConditions showCheckbox={false} />}
        handleSuccess={() => { }}
        handleFailure={handleClose}
        showCrossIcon={true}
      />
      <ConfirmationDialog
        maxWidth="lg"
        successButtonText=""
        failureButtonText="Close"
        open={openPrivacyPolicy}
        description={<PrivacyPolicy />}
        handleSuccess={() => { }}
        handleFailure={handleClosePrivacyPolicy}
        showCrossIcon={true}
      />
    </main>
  );
}
