import { useContext, useEffect, useState } from "react";
import RequestOverviewTab from "./RequestOverviewTab";
import {
  AssignRequest,
  AssignService,
  MessageComponent,
  Tabs,
  TaskCard,
  UserSideBar,
  VerifyPatient,
  DeclineRequest,
  ConfirmationDialog,
  TextInput,
  Button,
} from "../../components";
import { useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import TimeTimelineTab from "./RequestTimelineTab";
import { BreadCrumbs } from "../../components";
import { getOffice } from "../../redux/slices/OfficeSlice";
import { setActiveIndex } from "../../redux/slices/RequestSlice";
import PaymentInfoTab from "./PaymentInfoTab";
import RequestDetailsSummaryBox from "./RequestDetailsSummaryBox";
import Recipients from "../../components/forms/Recipients/Recipients";
import { ServiceBilledTo } from "../../models/service.model";
import { markAllUserNotificationsForRequestAsRead } from "../../redux/slices/NotificationSlice";
import EditNotes from "../../components/forms/EditNotes/EditNotes";
import MarkAsPaid from "../../components/forms/MarkAsPaid/MarkAsPaid";
import { RefundRequestState, RequestState, RequestType, RequestVerificationStateType } from "../../models/request.model";
import PriceOverrideInvoiceForm from "../../components/forms/InvoiceForm/PriceOverrideInvoiceForm";
import { UserState } from "../../services/auth.service";
import { SESSION_STORAGE_NEW_REQUEST } from "../../utils/constants";
import RadioInput from "../../components/RadioInput/RadioInput";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { approveRejectRefundRequest, setOfficeRequestState } from "../../redux/slices/OfficeRequestSlice";
import { ToastVariants } from "../../models";
import { showRefundState } from "../../utils";
import { RefundReasonOptions } from "./PatientViewRequest";
import { addOfficeRequestTimelineEvent } from "../../redux/slices/UserSlice";

const ViewRequest = () => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const [newPrice, setNewPrice] = useState("");
  const [newPriceAmount, setNewPriceAmount] = useState(0);
  const [visibleForm, setVisibleForm] = useState<RequestVerificationStateType["visibleForm"] | null>(null);
  const [showAccountCreationDialog, setShowAccountCreationDialog] = useState(false);
  const [showRefundRequested, setShowRefundRequested] = useState(false);
  const [refundOption, setRefundOption] = useState("");
  const [denyText, setDenyText] = useState("");
  const { requestId, id: officeId } = useParams();
  const { currentOffice } = useSelector((state: RootState) => state.office);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const { activeTabIndex } = useSelector((state: RootState) => state.request);

  const closeForm = () => {
    setVisibleForm(null);
    if (newPrice || newPriceAmount) {
      setNewPrice("");
      setNewPriceAmount(0);
    }
  };

  const closeMarkAsPaidForm = () => {
    setVisibleForm(null);
    if (newPrice || newPriceAmount) {
      setNewPrice("");
      setNewPriceAmount(0);
    }
  };

  const openForm = (formName: RequestVerificationStateType["visibleForm"]) => {
    setVisibleForm(formName);
  };

  const handleFromVisibility = (val: RequestVerificationStateType["visibleForm"] | null) => {
    setVisibleForm(val);
  };

  const getSidebarTitle = () => {
    switch (visibleForm) {
      case "VerifyPatient":
        return "Patient Details";
      case "AssignRequest":
        return "Assign Request";
      case "AssignService":
        return requestData?.paymentComplete ? "Service" : "Service Details";
      case "overview":
        return "Task List";
      case "Recipients":
        return "Edit 3rd Party Recipient";
      case "SendMessage":
        return "Send Text Message";
      case "DeclineRequest":
        return "Reason";
      case "EditNotes":
        return "Edit Notes";
      case "MarkAsPaid":
        return "Payment Details (optional)";
      default:
        return "";
    }
  };

  const renderRefundRequested = () => {
    return (
      <div className="flex flex-col gap-3">
        <h2 className="font-bold">Provided Reason</h2>
        <p>
          {requestData?.refundRequest?.requestReason === RefundReasonOptions.Other
            ? requestData?.refundRequest?.requestReasonOther
            : requestData?.refundRequest?.requestReason}
        </p>
        <h2 className="font-bold">Please confirm how you wish to proceed with this refund request</h2>
        <div className="flex gap-5">
          <RadioInput value={refundOption} onChangeFunc={e => setRefundOption(e)} label={""} options={["Approve Request", "Deny Request"]} />
        </div>
        <h2 className="font-bold">Please provide any additional notes</h2>
        {refundOption === "Deny Request" ? (
          <TextInput isTextArea value={denyText} extraInputClass="resize-none min-h-24" onChangeFunc={e => setDenyText(e.target.value)} />
        ) : null}
        <p>This action cannot be undone</p>
      </div>
    );
  };

  const handleRefundAction = async () => {
    if (!refundOption) {
      toast?.openToast("Please provide reason");
      return;
    }
    let close = false;
    if (requestData?.officeId && requestData?.requestId && requestData?.refundRequest?.refundRequestId) {
      setIsLoading(true);
      switch (refundOption) {
        case "Approve Request":
          const res = await dispatch(
            approveRejectRefundRequest({
              officeId: requestData.officeId,
              requestId: requestData.requestId,
              refundRequestId: requestData.refundRequest.refundRequestId,
              action: true,
            })
          );
          if (res.payload) {
            close = true;
            await dispatch(
              setOfficeRequestState({ officeId: requestData?.officeId, requestId: requestData?.requestId, state: RequestState.Cancelled })
            );
            await dispatch(
              addOfficeRequestTimelineEvent({
                event: `${currentUser?.firstName + " " + currentUser?.lastName} updated status to ${RequestState.Cancelled}`,
                officeId: requestData?.officeId,
                requestId: requestData?.requestId,
              })
            );
            toast?.openToast("Refund request approved successfully!", 2000, ToastVariants.Success);
          } else {
            toast?.openToast("Approve refund request failed!", 2000, ToastVariants.Error);
          }
          break;
        case "Deny Request":
          if (!denyText?.trim().length) {
            toast?.openToast("Please provide deny reason!");
          } else {
            const res = await dispatch(
              approveRejectRefundRequest({
                officeId: requestData.officeId,
                requestId: requestData.requestId,
                refundRequestId: requestData.refundRequest.refundRequestId,
                action: false,
                reason: denyText?.trim(),
              })
            );
            if (res.payload) {
              close = true;
              toast?.openToast("Refund request denied successfully!", 2000, ToastVariants.Success);
            } else {
              toast?.openToast("Deny refund request failed!", 2000, ToastVariants.Error);
            }
          }
          break;
      }
      if (close) {
        setShowRefundRequested(false);
        setRefundOption("");
        setDenyText("");
      }
      setIsLoading(false);
    }
  };

  const breadCrumbItems = [
    { key: `/offices`, title: `Offices` },
    { key: `/offices/${currentOffice?.officeId}/requests`, title: currentOffice?.name },
    { key: `/offices/${currentOffice?.officeId}/requests`, title: `Requests` },
    { key: "", title: requestData?.requestNumber + "" },
  ];

  useEffect(() => {
    if (currentOffice === null && officeId) {
      dispatch(getOffice({ officeId }));
    }
    setVisibleForm(null);
  }, [currentOffice]);

  useEffect(() => {
    if (requestData?.requestNumber === undefined) {
      dispatch(setActiveIndex(0));
    }
  }, [requestData]);

  useEffect(() => {
    if (requestId && currentUser?.userId) {
      dispatch(markAllUserNotificationsForRequestAsRead({ requestId, userId: currentUser?.userId }));
    }
  }, [requestId, currentUser, dispatch]);

  useEffect(() => {
    if (
      requestData?.requestType &&
      ![RequestType.ThirdPartyOffice, RequestType.DocnoteDebit].includes(requestData?.requestType) &&
      requestData?.patient?.userId &&
      requestData?.patient?.userState === UserState.Invited
    ) {
      const reqId = sessionStorage.getItem(SESSION_STORAGE_NEW_REQUEST);
      if (reqId === requestData?.requestId) {
        setShowAccountCreationDialog(true);
        sessionStorage.removeItem(SESSION_STORAGE_NEW_REQUEST);
      }
    }
  }, [requestData?.patient]);

  const patientOrThirdParty = requestData?.serviceBilledToOverride
    ? requestData?.serviceBilledToOverride !== ServiceBilledTo.OHIP
    : requestData?.service?.serviceBilledTo !== ServiceBilledTo.OHIP;
  const tabOptions = patientOrThirdParty ? ["Overview", "Payment Info", "Timeline"] : ["Overview", "Timeline"];
  const activeTabs = patientOrThirdParty
    ? [
        <RequestOverviewTab key="request-overview" requestId={requestId} officeId={officeId} handleFromVisibility={handleFromVisibility} />,

        <PaymentInfoTab key={"payment-info"} officeId={officeId} requestId={requestId} />,
        <span key="cs-2">
          <TimeTimelineTab />
        </span>,
      ]
    : [
        <RequestOverviewTab key="request-overview" requestId={requestId} officeId={officeId} handleFromVisibility={handleFromVisibility} />,

        <span key="cs-2">
          <TimeTimelineTab />
        </span>,
      ];

  return (
    <main>
      <div className="container mx-auto p-8">
        <div className="w-full">
          <BreadCrumbs items={breadCrumbItems} />
        </div>
        <div className="flex justify-between items-start mb-3">
          <div className="text-left">
            <div className="flex gap-7 items-center">
              <h2 className="heading uppercase font-semibold">{`${requestData?.firstName || ""} ${requestData?.lastName || ""}`}</h2>
              {requestData?.refundRequest && requestData?.refundRequest?.refundRequestState === RefundRequestState.Pending ? (
                <div className="flex items-center gap-3 cursor-pointer">
                  <Button
                    AdditionalClassNames="!rounded-[6px] flex items-center gap-3 !px-[15px] !py-2"
                    text={showRefundState(requestData?.refundRequest?.refundRequestState)}
                    onClickFunc={() => setShowRefundRequested(true)}
                    icon={<p className="font-bold text-2xl leading-none">!</p>}
                  />
                </div>
              ) : null}
            </div>
            <div className="flex items-center">
              <p className="text-md font-medium">Request # {requestData?.requestNumber || ""}</p>
              {requestData?.urgent ? (
                <span className="inline-block text-xs uppercase text-red-800 font-medium py-1 px-2 md:ml-2 bg-red-100 rounded-full">Urgent</span>
              ) : null}
            </div>
          </div>
        </div>

        <div className="flex flex-wrap md:flex-nowrap flex-col-reverse md:flex-row">
          <div className="tabs w-full pr-0 md:pr-8 lg:pr-16">
            <Tabs components={activeTabs} options={tabOptions} activeTab={activeTabIndex} onClickTab={index => dispatch(setActiveIndex(index))} />

            <div className="tab-content"></div>
            <UserSideBar
              visible={visibleForm !== null}
              LeftbuttonText="Cancel"
              RightbuttonText="Add"
              onCancelClick={() => false}
              onInviteClick={() => false}
              onClickOutside={() => (!isLoading ? closeForm() : null)}
              title={getSidebarTitle()}>
              {visibleForm ? (
                <>
                  {
                    {
                      VerifyPatient: <VerifyPatient onClickCancel={closeForm} setIsLoading={setIsLoading} isLoading={isLoading} />,
                      AssignService: <AssignService onClickCancel={closeForm} setIsLoading={setIsLoading} isLoading={isLoading} />,
                      AssignRequest: <AssignRequest onClickCancel={closeForm} setIsLoading={setIsLoading} isLoading={isLoading} />,
                      Recipients: <Recipients onClickCancel={closeForm} setIsLoading={setIsLoading} isLoading={isLoading} />,
                      SendMessage: <MessageComponent onClickCancel={closeForm} setIsLoading={setIsLoading} isLoading={isLoading} />,
                      DeclineRequest: <DeclineRequest onClickCancel={closeForm} setIsLoading={setIsLoading} isLoading={isLoading} />,
                      EditNotes: <EditNotes onClickCancel={closeForm} setIsLoading={setIsLoading} isLoading={isLoading} />,
                      MarkAsPaid: (
                        <MarkAsPaid
                          onClickCancel={closeMarkAsPaidForm}
                          setIsLoading={setIsLoading}
                          isLoading={isLoading}
                          overridePrice={newPriceAmount}
                        />
                      ),
                      overview: (
                        <div className="inline-block w-full px-8">
                          <p className="text-sm py-5">Complete the following tasks</p>
                          <TaskCard completed onClick={() => openForm("VerifyPatient")} title={"Verify the Patient"} />
                          <TaskCard
                            completed
                            onClick={() => openForm("AssignService")}
                            title={requestData?.paymentComplete ? "Service" : "Select Service"}
                          />
                          <TaskCard completed onClick={() => openForm("AssignRequest")} title={"Assign the request to Staff & PCP"} />
                        </div>
                      ),
                    }[visibleForm]
                  }
                </>
              ) : (
                <></>
              )}
            </UserSideBar>
          </div>
          {activeTabIndex === 0 ? (
            <div className="w-full md:max-w-xs mt-0 pt-0">
              {officeId && requestId ? (
                <RequestDetailsSummaryBox
                  officeId={officeId}
                  requestId={requestId}
                  handleFromVisibility={handleFromVisibility}
                  setNewPriceAmount={setNewPriceAmount}
                  setNewPrice={setNewPrice}
                  newPrice={newPrice}
                  newPriceAmount={newPriceAmount}
                />
              ) : null}
            </div>
          ) : (
            <div className="w-full md:max-w-xs"> </div>
          )}
        </div>
      </div>
      <div className=" absolute -top-[100%]">
        <div id="invoice-patient-container-pdf" className="hidden">
          <PriceOverrideInvoiceForm />
        </div>
      </div>
      <ConfirmationDialog
        open={showAccountCreationDialog}
        title=""
        description={`The patient with email address ${requestData?.email} has previously be sent an invite to create an account.  They will be sent a reminder to complete the onboarding process before they can access this request.`}
        successButtonText="Continue"
        failureButtonText="Cancel"
        handleSuccess={() => setShowAccountCreationDialog(false)}
        handleFailure={() => setShowAccountCreationDialog(false)}
      />
      <ConfirmationDialog
        open={showRefundRequested}
        title=""
        description={renderRefundRequested()}
        successButtonText="Confirm"
        failureButtonText="Cancel"
        loading={isLoading}
        handleSuccess={handleRefundAction}
        handleFailure={() => setShowRefundRequested(false)}
      />
    </main>
  );
};

export default ViewRequest;
