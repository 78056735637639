import React from "react";

const RenderName = ({
  title,
  subTitle,
  extraClasses = "",
  type,
}: {
  title: string;
  subTitle: string;
  extraClasses?: string;
  type?: "list" | "input-list";
}) => {
  return (
    <div className={extraClasses}>
      <span className={`${type === "input-list" ? "block text-sm text-gray-400" : ""}`}>{title}</span>
      <span className={`${type === "input-list" ? "text-base font-medium block mt-2" : "block text-sm text-gray-400"}`}>{subTitle}</span>
    </div>
  );
};

export default RenderName;
