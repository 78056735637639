import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { UserType } from "../services/user.service";

const useHomeRoute = () => {
  const { currentUser } = useSelector((state: RootState) => state.currentUser);

  let homeLink = "";
  if (currentUser !== undefined && currentUser?.userType === UserType.Patient) homeLink = `/patients/${currentUser.userId}/dashboard`;
  else if (currentUser !== undefined && currentUser?.userType !== UserType.SystemAdmin && currentUser?.offices)
    homeLink = `/offices/${currentUser?.offices[0].officeId}/requests`;
  else if (currentUser !== undefined && currentUser?.userType === UserType.SystemAdmin) homeLink = "/offices";

  return { homeLink };
};

export default useHomeRoute;
