import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PasswordConfirmationForm from "../../components/forms/User/PasswordConfirmationForm";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { fetchInvitationTokenDetails } from "../../redux/slices/UserSlice";
import { AppDispatch, RootState } from "../../redux/store";

export default function AcceptInvitation() {
  const authContext = useContext(AuthContext);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const invitationToken = location.pathname.split("/")[2];
  const { invitationTokenDetails } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (invitationToken) {
      const isAuthenticated = authContext?.isAuthenticated;
      if (isAuthenticated) authContext.signout();
    }
    dispatch(fetchInvitationTokenDetails({ invitationToken }));
  }, []);

  return (
    <main>
      <div className="flex justify-between items-center w-full">
        <div className="flex justify-center items-center w-full p-10">
          {invitationTokenDetails === undefined ? (
            //UI to be fixed and extracted as a component
            <div>Fetching token details...</div>
          ) : (
            <>
              {invitationTokenDetails === null ? (
                //UI to be fixed
                <div className="w-80 text-center">Invitation is either invalid or expired, please request a new invitation from Docnote.</div>
              ) : (
                <PasswordConfirmationForm />
              )}
            </>
          )}
        </div>
      </div>
    </main>
  );
}
