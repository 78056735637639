import { colors } from "../../../utils/constants";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import Checkbox from "../../Checkbox/Checkbox";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { createBlockFee, editBlockFee } from "../../../redux/slices/BlockFeeSlice";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../../../models";
import { BlockFee } from "../../../models/block-fee.model";

const initial = {
  planName: "",
  price: "",
  frequency: "monthly",
  enabled: false,
};

//const rates = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32];

const AddPlan = ({
  handleClose,
  setLoading,
  loading,
  isEdit = false,
  plan = null,
}: {
  handleClose: () => void;
  setLoading: (val: boolean) => void;
  loading: boolean;
  isEdit?: boolean;
  plan?: BlockFee | null;
}) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const [inputs, setInputs] = useState(initial);
  const [error, setError] = useState(false);
  const [duplicatePlanName, setDuplicatePlanName] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    if (e.target.name === "price") {
      setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value.replace(/[^0-9]/g, "") }));
    } else {
      setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }
  };

  const close = () => {
    handleClose();
    setInputs(initial);
  };

  const createPlan = async () => {
    if (id) {
      setError(false);
      setDuplicatePlanName(false);
      setLoading(true);
      let data = undefined;
      if (!isEdit) {
        data = await dispatch(
          createBlockFee({
            officeId: id,
            name: inputs.planName,
            price: parseInt(inputs.price),
            subscriptionType: inputs.frequency,
            enabled: inputs.enabled,
          })
        );
      } else if (plan) {
        data = await dispatch(
          editBlockFee({
            officeId: plan?.officeId,
            blockFeeId: plan?.blockFeeId,
            name: inputs.planName,
            price: parseInt(inputs.price),
            subscriptionType: inputs.frequency,
            enabled: inputs.enabled,
          })
        );
      }
      if (data) {
        if (typeof data.payload === "number") {
          switch (data.payload) {
            case 409:
              setDuplicatePlanName(true);
              break;
            default:
              setError(true);
              break;
          }
          setLoading(false);
          return;
        }
        toast?.openToast(`Plan ${isEdit ? "updated" : "added"} successfully!`, 2000, ToastVariants.Success);
        close();
      }
    }
  };

  useEffect(() => {
    if (isEdit && plan) {
      setInputs({
        planName: plan.name,
        price: plan.price.toString(),
        frequency: plan.subscriptionType,
        enabled: plan.enabled,
      });
    }
  }, [isEdit, plan]);

  const disabled = !inputs.planName || !inputs.price || !inputs.frequency;

  return (
    <>
      <div className="inline-block h-scrollable-screen overflow-auto w-full">
        <p className="text-base px-8 pb-5 font-medium">Please provide the details for this option.</p>
        {error ? (
          <p className="text-center" style={{ color: colors.Red }}>
            Unable to {isEdit ? "update" : "add"} plan, please try again.
          </p>
        ) : (
          false
        )}
        <div className="px-8 py-2">
          <TextInput
            isError={duplicatePlanName}
            value={inputs.planName}
            name={"planName"}
            onChangeFunc={handleChange}
            label={"Plan Name"}
            errorMsg={"Plan name already exist"}
            isRequired={true}
            placeholder={"example: Family Plan"}
            isDarkBg={true}
          />
        </div>
        <div className="px-8 py-2">
          <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-1">
            {"Rate"}
            <span className="text-red-500">*</span>
          </label>
          <select onChange={handleChange} name={"price"} value={inputs.price} className={`general-select bg-transparent w-full`}>
            <option value={""}>Select Rate</option>
            <option value={0}>Free</option>
            {/* {rates.map((val, i) => {
              return (
                <option key={i} value={val}>
                  {`$${val}`}
                </option>
              );
            })} */}
          </select>
        </div>
        <div className="px-8 py-2 mb-5">
          <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-1">
            {"Payment Frequency"}
            <span className="text-red-500">*</span>
          </label>
          <p className="text-docsigna-blue-dark font-medium">Monthly</p>
          {/* <select
            onChange={handleChange}
            name={"frequency"}
            value={inputs.frequency}
            className={`block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}>
            <option value={""}>Select Frequency</option>
            {frequencies.map((val, i) => {
              return (
                <option key={i} value={val.toLocaleLowerCase()}>
                  {capitalize(val)}
                </option>
              );
            })}
          </select> */}
        </div>
        <hr className="mx-8 border-docsigna-blue-light" />
        <div className="px-8 py-2 mt-2">
          <Checkbox
            label="Enable plan option"
            checked={inputs.enabled}
            onChange={e => setInputs({ ...inputs, enabled: e.target.checked })}
            isDarkBg={true}
          />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button disabled={loading} onClickFunc={close} AdditionalClassNames="pointer px-5 mr-3" text="Cancel" width="fit" varient="Secondary" />
          <div>
            <Button onClickFunc={createPlan} disabled={disabled || loading} AdditionalClassNames="pointer px-5" text="Save" width="fit" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPlan;
