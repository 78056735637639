/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useContext, useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import { PaymentIntent } from "../../models/payment-intent.model";
import { PayloadAction } from "@reduxjs/toolkit";
import { getPaymentIntent } from "../../redux/slices/PaymentSlice";
import { colors, SESSION_STORAGE_PAYMENT_INTENT, SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED } from "../../utils/constants";
import Button from "../Button/Button";
import { setOpenPaymentSidebar } from "../../redux/slices/RequestSlice";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import {
  addOfficeRequestFiles,
  getOfficeRequestFiles,
  setOfficeRequestPaid,
  setOfficeRequestState,
  setPaymentSucceded,
} from "../../redux/slices/OfficeRequestSlice";
import { RequestPaymentMethod, RequestState, Request, RequestType } from "../../models/request.model";
import { setRequestPaid, setRequestState } from "../../redux/slices/OnBoardingSlice";
import { addGuestRequestTimelineEvent, addOfficeRequestTimelineEvent, getOfficeRequestTimeline } from "../../redux/slices/UserSlice";
import { useSelector } from "react-redux";
import { uploadFileNew } from "../../redux/slices/FileSlice";
import { DsFile, RequestFileTypes } from "../../models/ds-file.model";
import { ToastVariants } from "../../models";

const html2pdf = require("html2pdf.js");

const PaymentComponent = ({
  onCancelClick,
  paymentIntent,
  paymentRequestData,
  isOffice = false,
  setIsLoading,
}: {
  paymentIntent: PaymentIntent;
  onCancelClick: () => void;
  paymentRequestData: Request;
  isOffice?: boolean;
  setIsLoading?: (val: boolean) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const guestToken = sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_REQUEST_NUMBER_VERIFIED);
  const guestData = JSON.parse(guestToken || "{}");
  const [message, showMessage] = useState<string>("");
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const userName = isOffice ? `${currentUser?.firstName} ${currentUser?.lastName}` : "Office";

  const downloadInvoiceAsPdf = async () => {
    try {
      const element1 = document.getElementById("invoice-patient-container-pdf");
      element1?.classList.remove("hidden");
      const fileName = `Request ${paymentRequestData?.requestNumber} Receipt`;
      const opt = {
        margin: [5, 0, 5, 0],
        filename: fileName,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 4, dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: {
          unit: "mm",
          format: "a4",
          compress: true,
          orientation: "portrait",
        },
        pagebreak: { mode: "avoid-all" },
      };
      const blob: Blob = await html2pdf().set(opt).from(element1).outputPdf("arraybuffer");
      element1?.classList.add("hidden");
      element1?.classList.remove("min-w-full");
      const file = new File([blob], fileName + ".pdf", {
        type: "application/pdf",
      });
      const response = await dispatch(
        uploadFileNew({
          file: file,
          callback: undefined,
        })
      );
      if (paymentRequestData) {
        await dispatch(
          addOfficeRequestFiles({
            officeId: paymentRequestData?.officeId,
            requestId: paymentRequestData.requestId,
            fileIds: [(response.payload as DsFile).fileId],
            requestFileType: RequestFileTypes.Receipt,
          })
        );
      }
      element1?.classList.add("hidden");
      toast?.openToast("Receipt created successfully!", 2000, ToastVariants.Success);
      return true;
    } catch (_) {
      return true;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (setIsLoading) setIsLoading(true);
    showMessage("");
    if (stripe && elements) {
      try {
        const { error, paymentIntent: confirmIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: window.location.href,
          },
          redirect: "if_required",
        });
        if (error?.type === "card_error" || error?.type === "validation_error") {
          //   showMessage(error?.message || "");
        }
        const updateStateToComplete =
          paymentRequestData?.requestType === RequestType.PrescriptionRefill || paymentRequestData?.requestType === RequestType.DocnoteDebit;
        if (confirmIntent?.status === "succeeded" || confirmIntent?.status === "processing") {
          if (guestToken === null) {
            if (paymentRequestData) {
              if (!isOffice) await downloadInvoiceAsPdf();
              if (!updateStateToComplete && !paymentRequestData?.fulfillOnPayment) {
                await dispatch(
                  setOfficeRequestState({
                    officeId: paymentRequestData.officeId,
                    requestId: paymentRequestData.requestId,
                    state: RequestState.InProgress,
                  })
                );
              }
              if (updateStateToComplete) {
                await dispatch(
                  setOfficeRequestState({
                    officeId: paymentRequestData.officeId,
                    requestId: paymentRequestData.requestId,
                    state: RequestState.Complete,
                  })
                );
              }
              await dispatch(
                setOfficeRequestPaid({
                  officeId: paymentRequestData?.officeId,
                  requestId: paymentRequestData?.requestId,
                  paymentMethod: RequestPaymentMethod.Stripe,
                })
              ).then(() => {
                const eventMesage = `Payment made on Stripe by ${isOffice ? userName : "patient"}`;

                dispatch(
                  addOfficeRequestTimelineEvent({
                    event: eventMesage,
                    officeId: paymentRequestData?.officeId,
                    requestId: paymentRequestData?.requestId,
                  })
                ).then(() => {
                  if (isOffice) {
                    dispatch(
                      getOfficeRequestTimeline({
                        officeId: paymentRequestData?.officeId,
                        requestId: paymentRequestData?.requestId,
                        page: 0,
                        size: 100,
                      })
                    );
                  } else {
                    if (paymentRequestData?.officeId && paymentRequestData?.requestId) {
                      dispatch(getOfficeRequestFiles({ officeId: paymentRequestData?.officeId, requestId: paymentRequestData?.requestId }));
                    }
                  }
                });
              });
            }
          } else {
            if (paymentRequestData) {
              await dispatch(setRequestPaid({ guestToken: guestData.GuestToken, paymentMethod: RequestPaymentMethod.Stripe }));
              dispatch(
                addGuestRequestTimelineEvent({
                  event: "Payment made on Stripe by guest",
                  requestId: paymentRequestData?.requestId,
                  guestToken: guestData.GuestToken,
                })
              );
              if (!updateStateToComplete && !paymentRequestData?.fulfillOnPayment) {
                await dispatch(setRequestState({ guestToken: guestData.GuestToken, state: RequestState.InProgress }));
              }
              if (updateStateToComplete) {
                await dispatch(setRequestState({ guestToken: guestData.GuestToken, state: RequestState.Complete }));
              }
            }
          }
          await dispatch(setPaymentSucceded(true));

          setSuccess(true);
          showMessage("");
          sessionStorage.removeItem(SESSION_STORAGE_PAYMENT_INTENT);
          dispatch(setOpenPaymentSidebar(false));
          toast?.openToast("Payment successful.");
        } else if (error?.decline_code === "insufficient_funds") {
          formValid && showMessage("Insufficient funds, please try another card.");
        } else if (error?.code === "card_declined") {
          formValid && showMessage("Your card has been declined.");
        } else {
          formValid && showMessage("Something went wrong, please try again.");
        }
      } catch (err) {
        formValid && showMessage("Something went wrong, please try again.");
      }
    }

    setLoading(false);
    if (setIsLoading) setIsLoading(false);
  };

  const cancelIntent = () => {
    onCancelClick();
  };

  const onSubmitClick = () => {
    setErrorInitiated(true);
    handleSubmit();
  };

  const validatePaymentIntent = async () => {
    if (stripe) {
      if (paymentIntent) {
        dispatch(getPaymentIntent({ intentId: paymentIntent.id })).then((action: PayloadAction<PaymentIntent | any>) => {
          if (action.payload && (action.payload.status === "succeeded" || action.payload.status === "canceled")) {
            setExpired(true);
            sessionStorage.removeItem(SESSION_STORAGE_PAYMENT_INTENT);
          }
        });
      }
    }
  };

  useEffect(() => {
    validatePaymentIntent();
  }, [stripe]);

  return (
    <div>
      {!expired && !success ? (
        <>
          <p className="py-2 text-semibold">
            <b>Credit Card Information</b>
          </p>
          <PaymentElement
            className="stripe-payment-element"
            id={"Payment-Form-Stripe"}
            onChange={(e: any) => {
              setFormValid(e.complete);
              showMessage("");
            }}
          />
          <p style={{ color: colors.Red }} className="py-4 mb-4">
            {message}
          </p>
        </>
      ) : (
        <p>{message}</p>
      )}
      {success ? (
        <div>
          <h2>Thank you !!</h2>
        </div>
      ) : null}

      <div className="w-full px-8 py-5 flex justify-end absolute bottom-0 right-0 z-30 bg-docsigna-pink-light">
        <Button
          disabled={loading}
          onClickFunc={() => cancelIntent()}
          AdditionalClassNames="pointer px-5 mr-3"
          text="Cancel"
          width="fit"
          varient="Secondary"
        />
        <div>
          <Button
            disabled={(!stripe || !elements || loading || !formValid) && errorInitiated}
            onClickFunc={() => onSubmitClick()}
            AdditionalClassNames="pointer px-5"
            text="Submit"
            width="fit"
            varient={loading ? "loading" : "Primary"}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentComponent;
