/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { DropAreaErrors, Theme, UserTypeLevel } from "../models";
import { parse, isValid } from "date-fns";
import { enUS } from "date-fns/locale";
import { UserType } from "../services/user.service";
import { parseISO, format } from "date-fns";
import { AuthPermission } from "../services/auth.service";
import jsPDF from "jspdf";
import PDFMerger from "pdf-merger-js/browser";
import { RefundRequestState, Request, RequestDiscountType, RequestState } from "../models/request.model";
import { ServiceBilledTo } from "../models/service.model";
import { CurrencyRegex } from "./constants";
import moment from "moment";
import { PriceDetails } from "../models/price-details.model";
import { RequestFee } from "../models/request-fee.model";
import { Address } from "../models/contact-model";

export const renderState: any = {
  "pending-verification": "Pending Verification",
  "pending-payment": "Pending Payment",
  "in-progress": "In Progress",
  "waiting-signature": "Waiting for Signature",
  "final-review": "Final Review",
  complete: "Complete",
  cancelled: "Cancelled",
};
export const billedToOptions: any = {
  [ServiceBilledTo.Patient]: "Patient",
  [ServiceBilledTo.OHIP]: "OHIP",
  [ServiceBilledTo.WSIB]: "WSIB",
  [ServiceBilledTo.ThirdParty]: "3rd Party",
  [ServiceBilledTo.ServiceCanada]: "Service Canada",
  [ServiceBilledTo.DocnoteDebit]: "Docnote Debit",
};
export enum RequestType {
  NewRequest = "createRequest",
  QuickForm = "quickForm",
  ThirdParty = "3rdParty",
  PrescriptionRefill = "prescription-office",
}

export enum RequestTypeTitle {
  NewRequest = "Standard Request",
  QuickForm = "Quick Form",
  ThirdParty = "3rd Party",
  PrescriptionRefill = "Prescription Refill",
  DocnoteDebit = "Docnote Debit",
  WSIB = "WSIB",
  ServiceCanada = "Service Canada"
}

const getTheme = (theme: Theme) => {
  // theme logic
  return theme;
};

const validateEmail = (email: string) => {
  return (
    email
      .toLowerCase()
      .match(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      ) !== null
  );
};

const validatePhoneOrFaxNumber = (number: string) => {
  return (
    number
      .toLowerCase()
      .match(
        /^\d{3}-\d{3}-\d{4}$/
      ) !== null
  );
};

const formatInputDate = (dateStr: string) => {
  const daysInMonth = ["", 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const dateToFormat = dateStr.replaceAll("/", "");

  if (dateToFormat.length > 8) return dateStr.substring(0, dateStr.length - 1);

  if (dateToFormat.length > 4) {
    const mm = dateToFormat.substring(0, 2);
    const dd = dateToFormat.substring(2, 4);
    const remaining = dateToFormat.substring(4);

    if (+dd > daysInMonth[+dd]) {
      return mm + "/" + dd;
    } else {
      return mm + "/" + dd + "/" + remaining;
    }
  }

  if (dateToFormat.length > 2) {
    const mm = dateToFormat.substring(0, 2);
    const remaining = dateToFormat.substring(2);

    if (+mm > 12) {
      return mm;
    } else {
      return mm + "/" + remaining;
    }
  }
  return dateStr;
};

const isValidDate = (dateStr: string) => {
  const parsedDate = parse(dateStr, "P", new Date(), { locale: enUS });
  return isValid(parsedDate);
};

const formatNumToDec = (num: number, formatLimit: number) => {
  if (num - Math.floor(num) === 0) return num;
  return (Math.round(num * 100) / 100).toFixed(formatLimit);
};

const validatePass = (pass: string) => {
  return pass.length !== 0;
};

const formatPhoneNumber = (numStr: string) => {
  let phoneNumberString = numStr.replace(/[^0-9]/g, "");
  if (phoneNumberString.length >= 11 && (!numStr.includes("-") || !(numStr.length === 13 && numStr[3] === "-" && numStr[7] === "-"))) {
    phoneNumberString = phoneNumberString.slice(-10);
  }
  let result = "";
  if (phoneNumberString.length > 6) {
    result = phoneNumberString.substring(0, 3) + "-" + phoneNumberString.substring(3, 6) + "-" + phoneNumberString.substring(6);
  } else if (phoneNumberString.length > 3) {
    result = phoneNumberString.substring(0, 3) + "-" + phoneNumberString.substring(3);
  } else {
    result = phoneNumberString;
  }
  return result.substring(0, 12);
};

const formatOhipNumberNew = (numStr: string) => {
  const ohipNumber = numStr
    .replaceAll(/[a-z-]/g, "")
    .replace(/[!@#$%^&*]/g, "")
    .replaceAll(" ", "");
  let result = "";
  if (ohipNumber.length > 7) {
    result = ohipNumber.substring(0, 4) + " " + ohipNumber.substring(4, 7) + " " + ohipNumber.substring(7);
  } else if (ohipNumber.length > 4) {
    result = ohipNumber.substring(0, 4) + " " + ohipNumber.substring(4);
  } else {
    result = ohipNumber;
  }
  return result.substring(0, 12);
};

const formatOhipNumber = (numberString: string) => {
  let ohipNumber = numberString;
  ohipNumber = ohipNumber.replaceAll("  ", "");
  if (isNaN(+ohipNumber.slice(-1))) return ohipNumber.substring(0, ohipNumber.length - 1);
  if (ohipNumber.length > 12) return ohipNumber.substring(0, 12);
  if (ohipNumber.length < 4 || (ohipNumber.length !== 5 && ohipNumber.length !== 9)) ohipNumber.trim();
  if (ohipNumber.length < 4 && ohipNumber.endsWith(" ")) return ohipNumber.trimEnd();
  if ((ohipNumber.length === 5 || ohipNumber.length === 9) && ohipNumber.endsWith(" ")) return ohipNumber.trimEnd();
  if (ohipNumber.length > 7) {
    return ohipNumber.slice(0, 4) + " " + ohipNumber.slice(4, 7) + " " + ohipNumber.slice(7);
  }
  if (ohipNumber.length > 3 && ohipNumber.length < 8) {
    return ohipNumber.slice(0, 4) + " " + ohipNumber.slice(5);
  }
  return ohipNumber;
};

const formatDateForDatePicker = (dateStr: string) => {
  const dateString = dateStr.replaceAll("/", "").replaceAll(".", "");
  if (dateString.includes(" ") || isNaN(+dateString) || dateString.length > 8) return dateStr.substring(0, dateStr.length - 1);
  if (dateString.length > 4) {
    return dateString.substring(0, 2) + "/" + dateString.substring(2, 4) + "/" + dateString.substring(4);
  }
  if (dateString.length > 2) {
    return dateString.substring(0, 2) + "/" + dateString.substring(2);
  }
  return dateString;
};

const getUserRoleByType = (userType: string) => {
  let userRole = "";
  switch (userType) {
    case UserType.SystemAdmin:
      userRole = "System Superuser";
      break;
    case UserType.OfficeAdmin:
      userRole = "Office Superuser";
      break;
    case UserType.Accounting:
      userRole = "Accounting";
      break;
    case UserType.Patient:
      userRole = "Patient";
      break;
    case UserType.User:
      userRole = "User";
      break;
    default:
      break;
  }
  return userRole;
};

const formatNumber = (n: string) => {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatCurrency = (input: string, blur: string) => {
  // appends $ to value, validates decimal side

  let input_val = input;
  // don't validate empty input
  if (input_val === "") {
    return "";
  }

  // check for decimal
  if (input_val.indexOf(".") >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimal_pos = input_val.indexOf(".");

    // split number by decimal point
    let left_side = input_val.substring(0, decimal_pos);
    let right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // On blur make sure 2 numbers after decimal
    if (blur === "blur") {
      right_side += "00";
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    if (left_side) {
      input_val = "$" + left_side + "." + right_side;
    } else {
      input_val = "$0." + right_side;
    }
    // input_val =   "$"+left_side + "." + right_side;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = "$" + input_val;

    // final formatting
    if (blur === "blur" && input_val.replaceAll("$", "").length > 0) {
      input_val += ".00";
    }
  }

  if (input_val === "$") {
    return "";
  }

  return input_val;
};

const formatDate = (dateStr: string, dateFormat = "dd/MM/yyyy") => {
  if (!dateStr) return "";
  try {
    const parsedTime = parseISO(dateStr);
    const formattedTime = format(parsedTime, dateFormat);
    return formattedTime;
  } catch (e) {
    return "";
  }
};
const getExpiry = (string: string) => {
  const splittedString = string.split("/");
  const newStringArr = [];
  if (splittedString[0].length === 2) {
    newStringArr.push(splittedString[0]);
  } else {
    newStringArr.push("0" + splittedString[0]);
  }
  newStringArr.push(splittedString[1].slice(2, 4));
  return newStringArr.join("/");
};
const getPermissionName = (permission: AuthPermission) => {
  let val = "";
  switch (permission) {
    case AuthPermission.Finance:
      val = "View/Edit Finance";
      break;
    case AuthPermission.Signing:
      val = "Sign Forms";
      break;
    case AuthPermission.Office:
      val = "Edit Office";
      break;
    case AuthPermission.Request:
      val = "Edit Requests";
      break;
    case AuthPermission.Report:
      val = "View Reports";
      break;
    case AuthPermission.Service:
      val = "Edit Services";
      break;
    case AuthPermission.User:
      val = "Edit Users";
      break;
    case AuthPermission.Admin:
      val = "Admin";
      break;
  }
  return val;
};

const getFileSize = (size: number) => {
  let bytes = size;
  if (bytes < 1024) {
    return bytes + " B";
  }
  const units = ["kB", "MB", "GB"];
  let unitIndex = -1;
  do {
    bytes /= 1024;
    ++unitIndex;
  } while (Math.round(bytes) >= 1024 && unitIndex < units.length - 1);
  return Math.round(bytes) + " " + units[unitIndex];
};

const getMentions = (text: string) => {
  return text.match(/\B@[a-z0-9_-]+/gi);
};
const getFullNameMention = (text: string) => {
  const mention = text.match(/[^[\]]+(?=])/gi);
  const newMention = mention
    ? mention?.map(e => {
        return `@${e}`;
      })
    : [""];
  return [newMention];
};
const validateId = (id: string) => {
  return /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9][0-9a-z]{3}-[0-9a-z][0-9a-z]{3}-[0-9a-z]{12}$/i.test(id);
};

const percentOf = (amount: number, value: number) => {
  return ((value / amount) * 100).toFixed(0);
};

const fileNameFun = (name: string) => {
  if (name.includes(".png")) {
    return name.replace(".png", "");
  } else if (name.includes(".PNG")) {
    return name.replace(".PNG", "");
  } else if (name.includes(".jpg")) {
    return name.replace(".jpg", "");
  } else if (name.includes(".JPG")) {
    return name.replace(".JPG", "");
  } else if (name.includes(".jpeg")) {
    return name.replace(".jpeg", "");
  } else if (name.includes(".JPEG")) {
    return name.replace(".JPEG", "");
  }
  return name;
};

const officeHstvalidation = (text: string) => {
  return /[0-9]{9} RT [0-9]{4}$/.test(text);
};
const officeHstNonvalidation = (text: string) => {
  return /[0-9]{9}RT[0-9]{4}$/.test(text);
};
const blobToFile = (theBlob: any, fileName: string) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;

  return theBlob;
};

const convertToPdf = (files: any[], data: any[]) => {
  const max = { height: 300, width: 210 };

  const myNewFiles = files.map((ele: any) => {
    if (ele.type !== "application/pdf") {
      // let newImage1: any;
      const newImage1 = new Image();
      newImage1.src = URL.createObjectURL(ele);

      let flag = true;
      let height = data[0];
      let width = data[1];
      const ratio = data[0] / data[1];
      if (height > max.height || width > max.width) {
        if (height > width) {
          height = max.height;
          width = height * (1 / ratio);
        } else if (width > height) {
          width = 295;
          height = 295 * ratio;
          flag = false;
        }
      }

      const doc = new jsPDF(flag ? "p" : "l", "mm", "a4", true);
      doc.addImage(newImage1, "JPEG", 0, 0, width, height);

      return <File>blobToFile(doc.output("blob"), `${fileNameFun(ele.name)}.pdf`);
    }
    return <File>ele;
  });

  return myNewFiles;
};

const mergePDF = async (filesArray: (File | string)[]) => {
  const merger = new PDFMerger();
  const error: any = [];
  for (const file of filesArray) {
    try {
      await merger.add(file);
    } catch (e: any) {
      const errorMsg = JSON.stringify(e.message);
      const errorType = errorMsg?.includes("encrypted") ? "encrypted" : "other";
      error.push({ errorType: errorType, url: file });
      break;
    }
  }

  if (error?.length) {
    return error;
  } else {
    const mergedPdf = await merger.saveAsBlob();
    return blobToFile(mergedPdf, `final.pdf`);
  }
};
const getDocSignaFee = (price: number) => {
  return (price * 0.13).toFixed(2);
};

const getTotalServicePrice = (price: number, additionalFee: number, additionalFeeWithTax: number, addTax: boolean) => {
  const serviceHst = addTax ? price * 0.13 : 0;
  const hstAmount = additionalFeeWithTax ? additionalFeeWithTax * 0.13 : 0;
  const hstOnDocFee = price * 0.13 * 0.13;
  const total = hstAmount + additionalFee + hstOnDocFee + serviceHst + serviceHst * 0.13;

  return (+price + +getDocSignaFee(price) + total).toFixed(2);
};

const getUserLevel = (userType: UserType) => {
  return UserTypeLevel[userType];
};

const invoicePrice = (data: PriceDetails | null) => {
  const serviceAmount = data?.service ? data?.service?.grossAmount / 100 : 0;
  const subTotal = data?.service ? data?.service?.amount / 100 : 0;
  const additionalFees = data?.fees
    ? data?.fees?.map(ele => {
        return ele.amount / 100;
      })
    : [];
  const additionalFee = additionalFees?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const discount = data?.totalDiscount ? data?.totalDiscount / 100 : 0;
  const dsFees = data?.dsFee ? data?.dsFee?.amount / 100 : 0;
  const hst = data?.totalTax ? data?.totalTax / 100 : 0;
  const total = data?.totalAmount ? data?.totalAmount / 100 : 0;
  return { serviceAmount, additionalFee, dsFees, discount, subTotal, hst, total };
};

const patientRenderState = (state: string) => {
  switch (state) {
    case RequestState.PendingVerification:
      return renderState[RequestState.PendingVerification];
    case RequestState.Complete:
      return renderState[RequestState.Complete];
    case RequestState.Cancelled:
      return renderState[RequestState.Cancelled];
    default:
      return renderState[RequestState.InProgress];
  }
};

const calculatePrices = (requestData: Request | null, officeTax: boolean) => {
  const chargeDocsignaFee =
    requestData &&
    (requestData.serviceBilledToOverride === ServiceBilledTo.Patient ||
      // requestData.serviceBilledToOverride === ServiceBilledTo.Office ||
      requestData.serviceBilledToOverride === ServiceBilledTo.ThirdParty)
      ? true
      : false;
  const additionalFeesArray = requestData?.requestFees?.map(ele => {
    return (ele.fee.price / 100) * ele.quantity;
  });
  const additionalFeeWithAddTax = requestData?.requestFees?.map(ele => {
    if (ele.fee.addTax) {
      return (ele.fee.price / 100) * ele.quantity;
    } else {
      return 0;
    }
  });
  const additionalFeeWithTax = officeTax ? additionalFeeWithAddTax?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0;
  const additionalFee = additionalFeesArray?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  let serviceFee =
    requestData?.servicePriceOverride === 0
      ? 0
      : (requestData?.servicePriceOverride && requestData?.servicePriceOverride / 100) ||
        (requestData?.service?.price && requestData?.service?.price / 100) ||
        0;
  const initialServiceFee =
    requestData?.servicePriceOverride === 0
      ? 0
      : (requestData?.servicePriceOverride && requestData?.servicePriceOverride / 100) ||
        (requestData?.service?.price && requestData?.service?.price / 100) ||
        0;

  let subTotal = serviceFee + (additionalFee === undefined ? 0 : additionalFee);
  let discount = 0;
  if (requestData?.discountAmount) {
    if (requestData?.discountType === RequestDiscountType.Amount) {
      discount = requestData.discountAmount / 100;
      serviceFee = serviceFee - discount;
      subTotal = serviceFee + (additionalFee === undefined ? 0 : additionalFee);
    }
    if (requestData?.discountType === RequestDiscountType.Percentage) {
      discount = serviceFee * (requestData.discountAmount / 100);
      serviceFee = serviceFee - serviceFee * (requestData.discountAmount / 100);
      subTotal = serviceFee + (additionalFee === undefined ? 0 : additionalFee);
    }
  }

  const docSignaFee = subTotal * 0.1 > 2.5 ? subTotal * 0.1 : 2.5;
  const servicehstAmount = officeTax ? (requestData?.service?.addTax ? serviceFee * 0.13 : 0) : 0;
  const hstAmount =
    (requestData && requestData?.serviceBilledToOverride === ServiceBilledTo.OHIP) ||
    requestData?.serviceBilledToOverride === ServiceBilledTo.ServiceCanada
      ? 0
      : chargeDocsignaFee
      ? servicehstAmount + (additionalFeeWithTax === undefined ? 0 : additionalFeeWithTax * 0.13) + docSignaFee * 0.13
      : servicehstAmount + (additionalFeeWithTax === undefined ? 0 : additionalFeeWithTax * 0.13);
  const total = chargeDocsignaFee ? subTotal + docSignaFee + parseFloat(hstAmount.toFixed(2)) : subTotal + parseFloat(hstAmount.toFixed(2));
  return { serviceFee, initialServiceFee, additionalFee, subTotal, docSignaFee, hstAmount, total, chargeDocsignaFee, discount };
};

const getSorting = (sortKeys: string[], index: number, sortMethods: boolean[]) => {
  let sortMethodString = sortKeys[index];
  if (sortMethods[index]) {
    sortMethodString += ",asc";
  } else {
    sortMethodString += ",desc";
  }
  return sortMethodString;
};
const getTimeInStringFormat = (time: string) => {
  return moment(time).fromNow();
};

export const validatePassword = (name: string, value: string) => {
  let valid = false;
  switch (name) {
    case "lowerCase":
      valid = /[a-z]/g.test(value);
      break;
    case "upperCase":
      valid = /[A-Z]/g.test(value);
      break;
    case "specialCharacter":
      // eslint-disable-next-line no-useless-escape
      valid = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
      break;
    case "cotainNumber":
      valid = /\d/.test(value);
      break;
    case "eightCharacters":
      valid = /^.{8,}$/.test(value);
      break;
    default:
      break;
  }
  return valid;
};

const validateCurrency = (inputVal: string) => {
  let value = inputVal;
  value = value.replaceAll(",", "");
  value = value.replaceAll("$", "");

  if (value === "" || isNaN(parseFloat(value)) || !CurrencyRegex.test(value)) {
    return false;
  }
  return true;
};

const priceReview = (priceDetails?: PriceDetails) => {
  const servicePrice = priceDetails?.service?.grossAmount && priceDetails?.service?.grossAmount > 0 ? priceDetails?.service?.grossAmount / 100 : 0;
  const dscDesc = priceDetails?.discount?.description ?? "";
  const dscValue = priceDetails?.discount?.amount && priceDetails?.discount?.amount > 0 ? priceDetails?.discount?.amount / 100 : 0;
  const blockFeeDiscountDesc = priceDetails?.blockFeeDiscount?.description ?? "";
  const blockFeeDiscountAmount = priceDetails?.blockFeeDiscount?.amount && priceDetails?.blockFeeDiscount?.amount > 0 ? priceDetails?.blockFeeDiscount?.amount / 100 : 0;
  const addFeeValue = priceDetails?.fees?.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
  const additionalFeeTotal = addFeeValue ? addFeeValue / 100 : 0;
  let subTotal = priceDetails?.service?.amount && priceDetails?.service?.amount > 0 ? priceDetails?.service?.amount / 100 : 0;
  subTotal = subTotal + additionalFeeTotal;
  const dsFee = priceDetails?.dsFee?.amount && priceDetails?.dsFee?.amount > 0 ? priceDetails?.dsFee?.amount / 100 : 0;
  const dsFeeDesc = priceDetails?.dsFee?.description ?? "";
  const hstValue = priceDetails?.totalTax && priceDetails?.totalTax > 0 ? priceDetails?.totalTax / 100 : 0;
  const hstPercentage = priceDetails?.taxPercentage && priceDetails?.taxPercentage > 0 ? priceDetails?.taxPercentage : 0;
  const hstDesc = priceDetails?.taxDescription ?? "";
  const total = priceDetails?.totalAmount && priceDetails?.totalAmount > 0 ? priceDetails?.totalAmount / 100 : 0;

  return {
    servicePrice,
    dscDesc,
    dscValue,
    blockFeeDiscountDesc,
    blockFeeDiscountAmount,
    additionalFeeTotal,
    subTotal,
    dsFee,
    dsFeeDesc,
    hstValue,
    hstPercentage,
    hstDesc,
    total,
  };
};

const createUpdateRequestObject = (requestData: Request) => {
  const additionalFees =
    requestData?.requestFees && requestData?.requestFees?.length > 0
      ? requestData?.requestFees.map((item: RequestFee) => {
          return {
            feeId: item.fee?.feeId ?? "",
            requestId: requestData?.requestId,
            quantity: item.quantity,
          };
        })
      : undefined;
  const request = {
    requestId: requestData.requestId,
    officeId: requestData.officeId,
    requestType: requestData?.requestType,
    patientId: requestData?.patient?.userId,
    firstName: requestData.firstName,
    lastName: requestData.lastName,
    email: requestData?.email,
    phone: requestData?.phone ?? undefined,
    dateOfBirth: requestData?.dateOfBirth ?? undefined,
    ohipNumber: requestData?.ohipNumber ?? undefined,
    details: requestData?.details ?? undefined,
    recipientName: requestData?.recipientName,
    recipientContact: requestData?.recipientContact,
    recipientConsent: requestData?.recipientConsent,
    discountType: requestData?.discountType,
    discountAmount: requestData?.discountAmount,
    patientVerified: requestData?.patientVerified,
    acceptedTerms: requestData?.acceptedTerms,
    fulfillOnPayment: requestData?.fulfillOnPayment,
    assignedToUserId: requestData?.assignedTo?.userId,
    primaryCareProviderUserId: requestData?.primaryCareProvider?.userId,
    serviceId: requestData?.service?.serviceId,
    serviceBilledToOverride: requestData?.serviceBilledToOverride,
    servicePriceOverride: requestData?.servicePriceOverride,
    serviceNotes: requestData?.serviceNotes,
    fees: additionalFees,
    paymentDetails: requestData?.paymentDetails,
  };

  return request;
};

const validatePostalCode = (code: string) => {
  const patt = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
  return patt.test(code);
};

const formatAddress = (address: Address) => {
  // Extract individual components from the address object
  const street1 = address?.street1 || "";
  const street2 = address?.street2 || "";
  const city = address?.city || "";
  const province = address?.province || "";
  const postalCode = address?.postalCode || "";

  // Create an array to hold the address components
  const addressComponents = [street1, street2, city, province, postalCode];

  // Filter out empty components
  const formattedAddress = addressComponents.filter(component => component !== "").join(", ");

  return formattedAddress ? formattedAddress : "-";
};


const generateBarGraphYAxisValues = (min: number, max: number, count: number) => {
  const difference = (max - min) / (count - 1);
  const numbers = [];

  for (let i = 0; i < count; i++) {
    numbers.push(Math.round(min + i * difference));
  }

  return numbers;
}

const calculateOverridePrice = (requestData: Request | null, officeTax: boolean, newPrice: number) => {
  let serviceFee = newPrice;
  const hstAmount = officeTax ? (requestData?.service?.addTax ? parseFloat((serviceFee * 0.13).toFixed(2)) : 0) : 0;
  serviceFee = serviceFee - hstAmount;
  const total = serviceFee + parseFloat(hstAmount.toFixed(2));
  return { serviceFee, hstAmount, total };
};

const onFileError = (errors: DropAreaErrors[]) => {
  let errorMsg = "";
  if (errors.includes(DropAreaErrors.INVALID_FILE_TYPE)) {
    errorMsg += "Invalid File format \n";
  }
  if (errors.includes(DropAreaErrors.FILE_SIZE_EXCEEDED)) {
    errorMsg += "Attachments cannot be larger than 50MB \n";
  }
  if (errors.includes(DropAreaErrors.MAX_NUMBER_OF_FILES_EXCEEDED)) {
    errorMsg += "You have reached the 10 attachment limit \n";
  }
  if (errors.includes(DropAreaErrors.SOMETHING_WENT_WRONG)) {
    errorMsg += "Something went wrong, please try again";
  }
  return errorMsg;
};

const generateTimeList = (startHour:number, startMinute:number, endHour:number, intervalMinutes:number) => {
  const times = [];
  let hour = startHour;
  let minute = startMinute;

  while (hour < endHour || (hour === endHour && minute < 60)) {
    const period = hour < 12 ? 'AM' : 'PM';
    let displayHour = (hour % 12 === 0 ? 12 : hour % 12).toString(); // Handle 12-hour format
    displayHour = parseInt(displayHour) < 10 ? `0${displayHour}` : displayHour;
    const valueHour = hour < 10 ? `0${hour}` : hour.toString();
    const displayMinute = minute < 10 ? '0' + minute : minute;
    
    times.push({
      label: `${displayHour}:${displayMinute} ${period}`,
      value: `${valueHour}:${displayMinute}:00`,
    });
    
    minute += intervalMinutes;
    if (minute >= 60) {
      minute -= 60;
      hour++;
    }
  }
  return times;
}

const numberOnly = (value: string) => {
  return value.replace(/[^0-9]/g, '')
}

const passwordValidationList = [
  {
    id: "lowerCase",
    isValid: false,
    msg: "Password must contain a lower case letter",
  },
  {
    id: "upperCase",
    isValid: false,
    msg: "Password must contain an upper case letter",
  },
  {
    id: "specialCharacter",
    isValid: false,
    msg: "Password must contain a special character",
  },
  {
    id: "cotainNumber",
    isValid: false,
    msg: "Password must contain a number",
  },
  {
    id: "eightCharacters",
    isValid: false,
    msg: "Password must contain a atleast  8 characters",
  },
  { id: "passwordMatch", isValid: false, msg: "Password must match" },
];

const showRefundState = (status: RefundRequestState) => {
  return status === RefundRequestState.Pending
    ? "Refund Requested"
    : status === RefundRequestState.Approved
    ? "Refunded"
    : "Rejected"
}

const formatHstNumber = (number: string) => {
  // Remove any non-alphanumeric characters except for "RT"
  const cleaned = number.replace(/[^a-zA-Z0-9]/g, "");

  if (cleaned.length <= 4) return cleaned;
  if (cleaned.length <= 8) return `${cleaned.slice(0, 4)} ${cleaned.slice(4, 8)}`;
  if (cleaned.length <= 14) return `${cleaned.slice(0, 4)} ${cleaned.slice(4, 8)} ${cleaned.slice(8)}`;

  return `${cleaned.slice(0, 4)} ${cleaned.slice(4, 8)} ${cleaned.slice(8, 14)}`;
};

const  sortArrayByKey = <T>(array: T[], key: keyof T, order: string): T[] =>  {
  return array.slice().sort((a, b) => {
    const aValue = a[key];
    const bValue = b[key];

    // Handle different data types
    if (aValue instanceof Date && bValue instanceof Date) {
      return order === 'asc' ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      const comparison = aValue.localeCompare(bValue);
      return order === 'asc' ? comparison : -comparison;
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    }

    // Fallback for other types or mixed types
    if (aValue < bValue) {
      return order === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === 'asc' ? 1 : -1;
    }
    return 0; // values are equal
  });
}


export {
  getTheme,
  validateEmail,
  validatePass,
  formatPhoneNumber,
  isValidDate,
  formatInputDate,
  formatOhipNumber,
  getUserRoleByType,
  formatNumber,
  formatCurrency,
  formatDate,
  getExpiry,
  officeHstvalidation,
  officeHstNonvalidation,
  formatDateForDatePicker,
  getFileSize,
  getPermissionName,
  getMentions,
  validateId,
  formatNumToDec,
  convertToPdf,
  mergePDF,
  getDocSignaFee,
  getTotalServicePrice,
  fileNameFun,
  getUserLevel,
  calculatePrices,
  getFullNameMention,
  invoicePrice,
  percentOf,
  patientRenderState,
  getSorting,
  formatOhipNumberNew,
  validateCurrency,
  getTimeInStringFormat,
  priceReview,
  createUpdateRequestObject,
  validatePostalCode,
  formatAddress,
  validatePhoneOrFaxNumber,
  generateBarGraphYAxisValues,
  calculateOverridePrice,
  onFileError,
  generateTimeList,
  numberOnly,
  passwordValidationList,
  showRefundState,
  formatHstNumber,
  sortArrayByKey
  // getStringBetween,
};
