import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux/store";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { declineOfficeRequest } from "../../../redux/slices/OfficeRequestSlice";
import ConfirmationDialog from "../../Dialogs/ConfirmationDialog";
import { getOfficeRequestPatientMessages } from "../../../redux/slices/RequestSlice";

const DeclineRequest = ({
  onClickCancel,
  setIsLoading,
  isLoading,
}: {
  onClickCancel: () => void;
  setIsLoading: (val: boolean) => void;
  isLoading?: boolean;
}) => {
  const { id, requestId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state?.officeRequest);
  const toast = useContext(ToastContext);
  const [error, setError] = useState(true);
  const [apiError, setApiError] = useState("");
  const [message, setMessage] = useState("");
  const [errorInitiated, setErrorInitiated] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleChange = (value: string) => {
    if (value.length) {
      setError(false);
    } else {
      setError(true);
    }
    setMessage(value as string);
  };

  const handleSubmit = () => {
    if (id && requestId) {
      setConfirmationOpen(false);
      setIsLoading(true);
      dispatch(declineOfficeRequest({ officeId: id, requestId: requestId, reason: message }))
        .then(res => {
          setIsLoading(false);
          if (res !== null) {
            dispatch(getOfficeRequestPatientMessages({ officeId: id, requestId }));
            toast?.openToast("Request Declined");
            onClickCancel();
          } else {
            setApiError("Something went wrong.");
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <>
      <ConfirmationDialog
        open={confirmationOpen}
        title={"This will cancel the request and send an email to the patient with the reason you provided, continue?"}
        description={""}
        successButtonText={"Confirm"}
        handleSuccess={() => {
          if (!error) handleSubmit();
        }}
        failureButtonText={"Cancel"}
        handleFailure={() => setConfirmationOpen(false)}
      />
      <div className="px-8 py-2">
        <label className="text-sm text-gray-700 block w-full mt-3 mb-2">{"Please provide the reason for declining the request"}</label>
        <TextInput
          value={message}
          disabled={loading}
          onChangeFunc={e => handleChange(e.currentTarget.value)}
          placeholder={"Write the reason here"}
          isRequired={false}
          isTextArea={true}
          extraInputClass="h-48"
          errorMsg="Reason is required"
          isError={error && errorInitiated}
          onBlur={e => handleChange(e.currentTarget.value.trim())}
          isDarkBg={true}
        />
        {apiError ? <span className="text-red-500">{apiError}</span> : null}
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onClickCancel()}
            disabled={isLoading}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button
              onClickFunc={() => {
                setErrorInitiated(true);
                if (!error) setConfirmationOpen(true);
              }}
              varient={loading ? "loading" : "Primary"}
              disabled={(errorInitiated && error) || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Decline"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeclineRequest;
