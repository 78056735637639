const PrivacyPolicy = () => {
  return (
    <>
      <div className="">
        <h2 className="text-2xl font-semibold mb-1">Privacy Policy</h2>
        <p className="font-light mb-6">
          This Privacy Policy describes how We, Bluewater Promotions Inc. (&quot;<span className="font-bold">We</span>&quot;, &quot;
          <span className="font-bold">Us</span>&quot;, &quot;<span className="font-bold">Our</span> and &quot;<span className="font-bold">BPI</span>
          &quot;), collect, use, disclose, retain, protect and dispose of Your personal information in Our custody and control when You use Our
          website and the services made available through Our virtual healthcare application, Docnote (collectively, the &quot;
          <span className="font-bold">Services</span>&quot;).
        </p>
        <p className="text-base mb-3">
          By providing Us with personal information in connection with the use of Docnote, each user of Our Services (&quot;
          <span className="font-bold">You</span>&quot; and &quot;<span className="font-bold">Your</span>&quot;) agrees to Our collection, use and
          disclosure of Your personal information in accordance with this Privacy Policy.
        </p>
        <p className="text-base mb-6">
          This Privacy Policy forms part of the Docnote Terms and Conditions and should be read together with those Terms and Conditions. Unless
          otherwise defined in this Privacy Policy, all capitalized terms contained herein have the meaning ascribed to them in the Terms and
          Conditions.
        </p>
        <p className="text-base mb-6">
          You are deemed to accept this Privacy Policy by agreeing to its terms at the time You register to access and use Docnote.
        </p>
        <h4 className="text-lg font-semibold mb-3">1. Meaning of &quot;Personal Information&quot;</h4>
        <p className="text-base mb-6">
          <span className="font-bold">1.1 Meaning of &quot;Personal Information&quot;.</span> In this Privacy Policy, the term &quot;personal
          information&quot; means information about you as an individual (including health information), where you can be identified from the
          information alone or in combination with other information.
        </p>
        <h4 className="text-lg font-semibold mb-3">2. Application</h4>
        <p className="text-base mb-3">
          <span className="font-bold">2.1 Registered and Guest Users.</span> This Privacy Policy applies to the collection, use, retention, disposal
          and protection of Registered and Guest Users (Users) personal health information. Providers are subject to privacy legislation and
          professional requirements that govern the management of personal information in the course of facilitating the provision of services through
          Docnote. Providers are accountable for Users personal health information that we collect and process on their behalf. Users with questions
          about how their personal health information is handled by Providers should contact their Registered Office directly.
        </p>
        <p className="text-base mb-6">
          <span className="font-bold">2.2 De-identified Data.</span> This Privacy Policy does not apply to data that has been collected in respect of
          which all personal identifiers have been removed such that the information could not reasonably be used to identify the individual. We may
          use de-identified information for statistical analysis and other research purposes. For the avoidance of doubt, de-identified data is not
          personal information under this Privacy Policy
        </p>
        <h4 className="text-lg font-semibold mb-3">3. Personal Information Collection</h4>
        <p className="text-base mb-3">
          <span className="font-bold">3.1 What Information is Collected.</span> We collect and use personal information only for the purposes stated
          in this Privacy Policy or for such purposes to which You may otherwise consent. Personal information that We collect includes Your: (a)
          contact information (which may include name, address, phone number, email address), (b) gender, (c) date of birth, health-related
          information that You choose to disclose, including any medical and health history, medical records, prescription information, and (d) health
          card number. You can always choose not to disclose Your personal information, but this may make it impossible for Us to provide You with the
          Services or any part of them. If You provide Us with personal information relating to a third party We will assume that You have obtained
          all necessary consents from the third party to provide their personal information to Us for the purposes described in this Privacy Policy.
        </p>
        <p className="text-base mb-3">
          <span className="font-bold">3.2 Purposes for Collection.</span> We collect personal information for the purpose of registering You for the
          Services, providing information and Services to You (which includes the transmittal of Your Personal Information to Providers), and allowing
          Users to share information and communicate with Providers via Docnote. We may also request that You provide additional information that We
          may use to improve Our business and the Services We provide; however providing Us with this information is optional.
        </p>
        <p className="text-base mb-3">
          <span className="font-bold">3.3 Methods of Collection.</span> Personal information may be collected in a number of ways, including through
          Docnote or Our website, in person, over the phone, by mail, and from third parties who You have authorized to disclose personal information
          to Us.
        </p>
        <p className="text-base mb-6">
          <span className="font-bold">3.4 Access to Personal Information.</span> We make every reasonable effort to keep Your personal information as
          accurate, complete and up-to-date as necessary. If desired, You may verify the accuracy and completeness of your personal information in Our
          records. Users may update and edit any of their personal information except that You acknowledge that We are unable to update or edit any
          personal information that a Provider maintains or creates.. Users should contact the applicable Provider to access or request the correction
          of personal information that the Provider holds in their medical records.
        </p>
        <h4 className="text-lg font-semibold mb-3">4. Use And Disclosure Of Personal Information</h4>
        <p className="text-base mb-3">
          <span className="font-bold">4.1 General.</span> We use Your personal information to Provide Services to You and to help Us better understand
          Your needs, to improve the Services and for any additional purposes for which We have obtained your consent.
        </p>
        <p className="text-base mb-3">
          <span className="font-bold">4.2 Communicating with You.</span> We may use Your contact information for the purpose of communicating with You
          about our Services. You may opt-out or unsubscribe from optional communications, such as those that market Our services or inform You about
          our events.
        </p>
        <p className="text-base mb-6">
          <span className="font-bold">4.3 Disclosure of Personal Information to Third Parties.</span> We do not disclose personal information to third
          parties except as contemplated in this Privacy Policy or as otherwise permitted or required by law. In some instances, such as a legal
          proceeding or court order, We may be required to disclose Your personal information to authorities. We only disclose the information
          specifically requested and We take precautions to satisfy Ourselves that the authorities that are making the disclosure request have
          legitimate grounds to do so. Your personal information may be disclosed in situations where We are legally permitted to do so, such as in
          the course of employing reasonable and legal methods to enforce Our rights or to investigate suspicion of unlawful activities. We may
          release certain personal information when We believe that such release is reasonably necessary to protect the rights, property and safety of
          Ourselves and others.
        </p>
        <h4 className="text-lg font-semibold mb-3">5. Business Transactions</h4>
        <p className="text-base mb-6">
          <span className="font-bold">5.1 Business Transactions.</span> We may use and disclose Your personal Information to third parties in
          connection with the proposed or actual financing, insuring, sale, securitization, assignment or other disposal of all or part of Our
          business or assets for the purposes of evaluating, and allowing third parties to evaluate, and performing the proposed transaction. These
          purposes include, for example, permitting such third parties to determine whether to proceed or continue with the transaction, fulfilling
          any reporting or audit requirements to such parties, and/or disclosing personal information as part of concluding a sale or transfer of
          assets. Our successors and assigns may collect, use and disclose Your personal information for substantially the same purposes as those set
          out in this Privacy Policy. In the event a proposed transaction is not completed, We will require, by contract, the other party or parties
          to whom Your personal information was disclosed not to use or disclose Your personal information in any manner whatsoever for any purpose,
          and to return or destroy such personal information.
        </p>
        <h4 className="text-lg font-semibold mb-3">6. Third Party Service Providers</h4>
        <p className="text-base mb-6">
          <span className="font-bold">6.1 General.</span> We use third party service providers to provide technology, host data and servers, and
          otherwise assist Us in providing the Services. These third party service providers may have access to personal information as an incidental
          result of the services provided by them to Us, but these parties are not permitted to access Your personal information for their own
          purposes or uses.
        </p>
        <h4 className="text-lg font-semibold mb-3">7. Personal Information Retention And Deletion</h4>
        <p className="text-base mb-3">
          <span className="font-bold">7.1 Retention.</span> We process and store messages, logs, contact data and other related information in order
          to provide the Services. We will maintain such data and information in accordance with Our record retention policies and as permitted or
          required by law.
        </p>
        <p className="text-base mb-6">
          <span className="font-bold">7.2 Deletion.</span> We may reject, suspend, alter, remove or delete data that You provide to us or submit using
          the Services if We determine that such data breaches Our Terms and Conditions or that such actions are reasonable or necessary to protect Us
          or others. Within a reasonable time following termination of the Terms and Conditions, We will securely delete personal information from Our
          systems or convert this information into de- identifiable data. We will also securely delete personal information from Our systems upon Your
          request. When personal information is deleted or updated, it may take up to thirty (30) days or more from accessible systems, and up to
          ninety (90) days or more from backup systems, for Us to delete such personal information. You acknowledge and agree that if You request that
          Your personal information be deleted from Our records, it may not be possible to completely delete all personal information due to
          technological and legal constraints. In addition, Registered Providers may have retained copies of Registered Patients&lsquo; personal
          health information in accordance with their own privacy policies which We do not have control over.
        </p>
        <h4 className="text-lg font-semibold mb-3">8. Safeguarding Your Personal Information</h4>
        <p className="text-base mb-3">
          <span className="font-bold">8.1 General.</span> We take reasonable steps to protect personal information that We collect to prevent loss,
          misuse, unauthorized access, disclosure, alteration and destruction. We have in place reasonable physical, electronic and operating
          procedures to safeguard and secure the personal information We collect. Although We make reasonable efforts to protect personal information
          from loss, misuse, unauthorized access, disclosure, alteration and destruction, You should be aware that there is always some risk that an
          unauthorized party could find a way to breach Our security procedures and systems and/or those of Our service providers. This risk is
          heightened for information that is transmitted via unsecured or public Wi-Fi.
        </p>
        <p className="text-base mb-3">
          <span className="font-bold">8.2 Storage of Personal Information.</span> We store personal health information in Canada, with Amazon Web
          Services (<span className="font-bold">&quot;AWS&quot;</span>). AWS hosts all BPI servers, databases and applications in the AWS cloud.
        </p>
        <p className="text-base mb-3">
          <span className="font-bold">8.3 Your Role.</span> You also play an important role in protecting Your personal information. If you have a
          registered account You should:
        </p>
        <p className="text-base mb-3">(a) create a strong and unique Docnote password and update it periodically;</p>
        <p className="text-base mb-3">(b) not share Your account information with anyone;</p>
        <p className="text-base mb-3">
          (c) log out of Your account once You are finished using it, especially if You share Your device with another person;
        </p>
        <p className="text-base mb-3">(d) choose a quiet, private location from which to use the Services; and</p>
        <p className="text-base mb-6">(e) maintain Your software, devices and networks as required to ensure security.</p>
        <h4 className="text-lg font-semibold mb-3">9. Information We Collect Automatically</h4>
        <p className="text-base mb-6">
          <span className="font-bold">9.1 Cookies.</span> Our website may use &quot;cookies&quot; to enhance the user experience. Web cookies are very
          small text files that are stored on the user&apos;s computer from a webpage to keep track of information about the user&lsquo;s browsing on
          that site. The use of cookies allows Us to capture standard web traffic information, such as the time and date the user visited Our website,
          their IP address, and their browser information. The user may choose to set their web browser to refuse cookies, or to alert the user when
          cookies are being sent. If the user sets their web browser to disable cookies, some parts of the website may not be accessible to the user.
        </p>
        <h4 className="text-lg font-semibold mb-3">10. Links To Other Websites</h4>
        <p className="text-base mb-6">
          <span className="font-bold">10.1 Links to Other Websites.</span> Docnote may contain links to other websites that We do not own or operate.
          These links are not intended as an endorsement or referral to the linked websites. We strongly encourage You to review the privacy policies
          applicable to any website You visit. This Privacy Policy does not apply to such linked websites, and We are not responsible for the content
          or practices of any linked websites or their operators, which are provided solely for Your convenience.
        </p>
        <h4 className="text-lg font-semibold mb-3">11. Data Incidents</h4>
        <p className="text-base mb-3">
          <span className="font-bold">11.1 Data Incident.</span> Although We take reasonable measures which are designed to to prevent any loss, theft
          or unauthorized access, disclosure, use, modification or disposal of personal information (a “Data Incident”), there is no guarantee against
          such Data Incident. In the event of a Data Incident, We will, where required by law:
        </p>
        <p className="text-base mb-3">(a) inform You of the Data Incident; and</p>
        <p className="text-base mb-6">(b) provide You with information about the nature and scope of the Data Incident.</p>
        <h4 className="text-lg font-semibold mb-3">12. Law</h4>
        <p className="text-base mb-6">
          <span className="font-bold">12.1 Law.</span> As required by applicable privacy laws, We will work with You to promote and demonstrate
          compliance with privacy laws and this Privacy Policy by providing reasonable and necessary information and documentation to allow You to
          verify Our compliance with this Privacy Policy, and providing required information and assistance to You and to any regulatory or other
          governmental bodies or authorities with jurisdiction or oversight over privacy laws in connection with any investigations, audits or
          inquiries.
        </p>
        <h4 className="text-lg font-semibold mb-3">13. Contacting Us</h4>
        <p className="text-base mb-3">
          <span className="font-bold">13.1 Contacting Us.</span> You may contact Our Privacy Officer to make enquiries regarding Our privacy practices
          or with respect to the accuracy of Your personal information retained by Us and to request access to, or an update, correction or deletion
          of such information. You may also access some of Your personal information by accessing Your Docnote account. Any query, comments or
          concerns can be sent to Us by email at <span className="font-bold text-docsigna-purple-dark">privacy@DocSigna.ca</span> or by mail to the
          following address:
        </p>
        <p className="text-base mb-3">
          Bluewater Promotions Inc.,
          <br />
          481 London Road
          <br />
          Sarnia, Ontario Canada N7T 4X3
        </p>
        <p className="text-base mb-6">Attention: Privacy Officer</p>
        <h4 className="text-lg font-semibold mb-3">14. Changes To This Privacy Policy</h4>
        <p className="text-base mb-3">
          <span className="font-bold">14.1 Changes to this Privacy Policy.</span> We may change and update this Privacy Policy. We will notify Our
          users of changes to this Privacy Policy by sending notices by email to the addresses provided by Our users. The current version of this
          Privacy Policy will be posted to Our website. By continuing to use Our Services following a change to this Privacy Policy, or otherwise
          providing personal information, the user agrees to be bound by the revised version of this Privacy Policy.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
