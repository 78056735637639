import React, { useEffect, useState } from "react";
import tickImg from "../../assets/images/tick-white.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { ConfirmationDialog } from "../../components";
import { getOffice, setOfficePublished } from "../../redux/slices/OfficeSlice";
import { OfficeState } from "../../models/office.model";

const OfficeOnboardingtaskList = () => {
  const dispatch: AppDispatch = useDispatch();
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const { currentOffice } = useSelector((state: RootState) => state.office);
  const [trigerPublished, setTrigerPublished] = useState(false);
  const [published, setPublished] = useState(false);

  const officeProfileCompleted =
    currentOffice?.name &&
    currentOffice?.street &&
    currentOffice?.state &&
    currentOffice?.country &&
    currentOffice?.zip &&
    currentOffice?.phone &&
    currentOffice?.contactName &&
    currentOffice?.contactEmail &&
    currentOffice?.fax &&
    currentOffice?.yearsInPractice;

  const officeBilingInfoCompleted =
    currentOffice?.billingName &&
    currentOffice?.billingStreet &&
    currentOffice?.billingState &&
    currentOffice?.billingCountry &&
    currentOffice?.billingZip &&
    currentOffice?.bankAccount &&
    currentOffice?.bankInstitution &&
    currentOffice?.bankTransit &&
    currentOffice?.wsibNumber;
  const legalTermAccepted = currentOffice?.acceptedTerms;
  const assignedPcp = currentOffice?.primaryCareProviders?.length;
  // const trigerPublish = officeProfileCompleted&& officeBilingInfoCompleted&& legalTermAccepted&& assignedPcp
  const handlePublishClick = () => {
    setConfirmationOpen(true);
  };
  const handleConfirmationBox = (valueSelected: "yes" | "Cancel") => {
    if (valueSelected === "Cancel") {
      setConfirmationOpen(false);
    }
    if (valueSelected === "yes") {
      if (currentOffice?.officeId) {
        dispatch(setOfficePublished({ officeId: currentOffice?.officeId, published: published ? OfficeState.Draft : OfficeState.Published })).then(
          () => {
            if (currentOffice?.officeId) dispatch(getOffice({ officeId: currentOffice?.officeId }));
          }
        );
        setConfirmationOpen(false);
      }
    }
  };
  useEffect(() => {
    if (currentOffice !== null) {
      setPublished(currentOffice?.officeState === OfficeState.Published);
    }
  }, [currentOffice]);

  useEffect(() => {
    if (officeProfileCompleted && officeBilingInfoCompleted && legalTermAccepted && assignedPcp) {
      setTrigerPublished(true);
    } else {
      setTrigerPublished(false);
    }
  }, [officeProfileCompleted, officeBilingInfoCompleted, legalTermAccepted, assignedPcp, currentOffice]);

  return (
    <div className="w-full md:w-96 h-auto rounded-lg mb-5 mt-5">
      <div className="w-full bg-docsigna-pink-light rounded-lg rounded-b-none p-4">
        <p className="text-base font-semibold mb-4">Office Onboarding Task List</p>
        <>
          <ul>
            <li className="text-sm mb-2 flex justify-start items-center cursor-pointer" onClick={() => {}}>
              <div className="flex justify-center items-center mr-2 w-5 h-5 aspect-square bg-docsigna-orange rounded-full">
                <img src={tickImg} className={`h-2 ${officeProfileCompleted ? "" : "opacity-30"}`} alt="" />
              </div>
              <div className="w-full font-medium">Complete profile information</div>
            </li>

            <li className="text-sm mb-2 flex justify-start items-center cursor-pointer" onClick={() => {}}>
              <div className="flex justify-center items-center mr-2 w-5 h-5 aspect-square bg-docsigna-orange rounded-full">
                <img src={tickImg} className={`h-2 ${officeBilingInfoCompleted ? "" : "opacity-30"}`} alt="" />
              </div>
              <div className="w-full font-medium">Enter billing information</div>
            </li>
            <li className="text-sm mb-2 flex justify-start items-center cursor-pointer" onClick={() => {}}>
              <div className="flex justify-center items-center mr-2 w-5 h-5 aspect-square bg-docsigna-orange rounded-full">
                <img src={tickImg} className={`h-2 ${legalTermAccepted ? "" : "opacity-30"}`} alt="" />
              </div>
              <div className="w-full font-medium">Agree to legal terms</div>
            </li>
            <li className="text-sm mb-2 flex justify-start items-center cursor-pointer" onClick={() => {}}>
              <div className="flex justify-center items-center mr-2 w-5 h-5 aspect-square bg-docsigna-orange rounded-full">
                <img src={tickImg} className={`h-2 ${assignedPcp ? "" : "opacity-30"}`} alt="" />
              </div>
              <div className="w-full font-medium">Assign PCP to team</div>
            </li>
          </ul>
        </>
      </div>

      <div className="w-full h-fit p-0.5 border rounded-bl rounded-br border-docnote-pink-dark">
        <span
          className={`block w-full h-fit bg-white py-3 px-4 font-bold text-docsigna-blue-light cursor-pointer rounded-lg rounded-t-none ${
            trigerPublished ? "" : "opacity-50"
          }`}
          onClick={() => trigerPublished && handlePublishClick()}>
          {!published ? "Publish Office" : "Unpublish Office"}
        </span>
      </div>
      <ConfirmationDialog
        open={confirmationOpen}
        description={`${
          !published
            ? "Please confirm you would like to publish this office. This action will make it possible for patients to find your office and send requests."
            : "Are you sure you want to unpublish your office? Patients will no longer be able to find your office in the search box."
        }`}
        title={""}
        successButtonText={"Confirm"}
        handleSuccess={() => {
          handleConfirmationBox("yes");
        }}
        failureButtonText={"Cancel"}
        handleFailure={() => {
          handleConfirmationBox("Cancel");
        }}
      />
    </div>
  );
};
export default OfficeOnboardingtaskList;
