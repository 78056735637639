import React, { useState, useEffect, useContext } from "react";
import NumberFormat from "react-number-format";
import { createUpdateRequestObject, formatPhoneNumber, validateEmail } from "../../../utils";
import { Phone_Fax_Regex, colors } from "../../../utils/constants";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import DatePicker from "../../DatePicker/DatePicker";
import TextInput from "../../TextInput/TextInput";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { useParams } from "react-router-dom";
import { getOfficeRequest } from "../../../redux/slices/OfficeRequestSlice";
import { updateRequest } from "../../../redux/slices/OnBoardingSlice";
import { ToastVariants } from "../../../models";

interface formDataType {
  id: string;
  value: string | boolean;
  title: string;
  placeholder: string;
  required: boolean;
  errorMessage: string;
  valid: boolean;
  type: "textInput" | "ohip" | "birthday" | "checkbox";
}

const formDataInitialState: formDataType[] = [
  {
    id: "patientFirstName",
    value: "",
    title: "First Name",
    placeholder: "First Name",
    required: true,
    errorMessage: "Please provide first name",
    valid: false,
    type: "textInput",
  },

  {
    id: "patientLastName",
    value: "",
    title: "Last Name",
    placeholder: "Last Name",
    required: true,
    errorMessage: "Please provide last name",
    valid: false,
    type: "textInput",
  },

  {
    id: "Email",
    value: "",
    title: "Email",
    placeholder: "Email Address",
    required: true,
    errorMessage: "Please provide valid email address",
    valid: false,
    type: "textInput",
  },
  {
    id: "Phone",
    value: "",
    title: "Phone",
    placeholder: "Enter Phone number",
    required: true,
    errorMessage: "Please enter valid phone number",
    valid: false,
    type: "textInput",
  },
  {
    id: "dob",
    value: "",
    title: "Date of Birth",
    placeholder: "MM/DD/YYYY",
    required: true,
    errorMessage: "Please enter valid date of birth",
    valid: false,
    type: "birthday",
  },
  {
    id: "ohip",
    value: "",
    title: "OHIP Number (Optional)",
    placeholder: "Enter OHIP Number",
    required: false,
    errorMessage: "Please enter OHIP number",
    valid: false,
    type: "ohip",
  },
  {
    id: "patientVerified",
    value: false,
    title: "This patient has been verified.",
    placeholder: "",
    required: true,
    errorMessage: "Please verify the patient.",
    valid: true,
    type: "checkbox",
  },
];

const VerifyPatient = ({
  onClickCancel,
  fromOffice,
  setIsLoading,
  isLoading,
}: {
  onClickCancel: () => void;
  fromOffice?: boolean;
  setIsLoading: (val: boolean) => void;
  isLoading?: boolean;
}) => {
  const [formData, setFormData] = useState(formDataInitialState);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const toast = useContext(ToastContext);
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const { verificationError } = useSelector((state: RootState) => state.patient);
  const dispatch = useDispatch<AppDispatch>();
  const { id, requestId } = useParams();
  const handleChange = (id: string, value: string | boolean) => {
    const tempFormData = [...formData];
    tempFormData.forEach(input => {
      if (input.id === id) {
        if (id === "Phone") {
          input.value = formatPhoneNumber(value as string);
          input.valid = Phone_Fax_Regex.test(formatPhoneNumber(value as string));
        } else {
          input.value = value;
          if (id === "Email") {
            input.valid = validateEmail(value as string);
          } else if (id === "ohip") {
            input.valid = (value as string).trim() !== "" ? (value as string).length === 10 : true;
          } else {
            input.valid = typeof value === "string" ? value.length > 0 : true;
          }
        }
      }
    });
    setFormData(tempFormData);
  };
  const validateForm = () => {
    return formData.every(val => val.valid);
  };

  const renderInput = (inp: formDataType) => {
    switch (inp.type) {
      case "textInput":
        return (
          <TextInput
            key={inp.id}
            value={inp.value as string}
            onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
            placeholder={inp.placeholder}
            errorMsg={inp.errorMessage}
            isError={!inp.valid && errorInitiated}
            label={inp.title}
            isRequired={inp.required}
            onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
            isDarkBg={true}
          />
        );
      case "ohip":
        return (
          <div key={inp.id}>
            <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-0.5 mt-0 pt-0">{inp.title}</label>
            <NumberFormat
              value={inp.value as string}
              isNumericString
              format={"#### ### ###"}
              placeholder="Enter OHIP Number"
              displayType="input"
              className="general-ohip"
              onValueChange={values => handleChange(inp.id, values.value)}
            />
            {!inp.valid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMessage}</p> : null}
          </div>
        );
      case "birthday":
        return (
          <DatePicker
            key={inp.id}
            label={inp.title}
            isRequired={inp.required}
            name={inp.id}
            placeholder={inp.placeholder}
            value={inp.value as string}
            isError={!inp.valid && errorInitiated}
            onChange={val => handleChange(inp.id, val)}
            errorMsg={inp.errorMessage}
            isDarkBg={true}
          />
        );

      case "checkbox":
        return (
          <>
            <Checkbox
              key={inp.id}
              id={"patientVerified"}
              label={inp.title}
              checked={inp.value as boolean}
              name={inp.id}
              onChange={e =>
                fromOffice
                  ? handleChange(inp.id, e.currentTarget.checked)
                  : !requestData?.patientVerified
                  ? handleChange(inp.id, e.currentTarget.checked)
                  : null
              }
              disabled={requestData?.patientVerified && !fromOffice ? true : false}
              isDarkBg={true}
            />
            {!inp.valid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMessage}</p> : null}
          </>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (requestData && !fromOffice) {
      let newFormData = [...formData];
      formData.forEach((data, ind) => {
        switch (data.id) {
          case "patientFirstName":
            newFormData = [...newFormData.slice(0, ind), { ...data, value: requestData.firstName, valid: true }, ...newFormData.slice(ind + 1)];
            break;
          case "patientLastName":
            newFormData = [...newFormData.slice(0, ind), { ...data, value: requestData.lastName, valid: true }, ...newFormData.slice(ind + 1)];
            break;
          case "Email":
            newFormData = [...newFormData.slice(0, ind), { ...data, value: requestData.email, valid: true }, ...newFormData.slice(ind + 1)];
            break;
          case "Phone":
            newFormData = [
              ...newFormData.slice(0, ind),
              { ...data, value: requestData.phone ? (requestData.phone as string) : "", valid: true },
              ...newFormData.slice(ind + 1),
            ];
            break;
          case "dob":
            newFormData = [
              ...newFormData.slice(0, ind),
              { ...data, value: requestData.dateOfBirth ? (requestData.dateOfBirth as string) : "" },
              ...newFormData.slice(ind + 1),
            ];
            break;
          case "ohip":
            newFormData = [
              ...newFormData.slice(0, ind),
              { ...data, value: requestData.ohipNumber ? (requestData.ohipNumber as string) : "", valid: true },
              ...newFormData.slice(ind + 1),
            ];
            break;
          case "patientVerified":
            newFormData = [...newFormData.slice(0, ind), { ...data, value: requestData.patientVerified }, ...newFormData.slice(ind + 1)];
            break;
        }
      });
      setFormData(newFormData);
    }
  }, [requestData]);

  const handleClickSave = async () => {
    setErrorInitiated(true);
    const firstName = formData.find(item => item.id === "patientFirstName");
    const lastName = formData.find(item => item.id === "patientLastName");
    const email = formData.find(item => item.id === "Email");
    const phone = formData.find(item => item.id === "Phone");
    const dateOfBirth = formData.find(item => item.id === "dob");
    const ohipNumber = formData.find(item => item.id === "ohip");
    const patientVerified = formData.find(item => item.id === "patientVerified");

    if (validateForm()) {
      if (fromOffice) {
        toast?.openToast();
        onClickCancel();
      } else {
        if (id && requestId && firstName && lastName && email && phone && dateOfBirth && ohipNumber && patientVerified && requestData?.requestType) {
          try {
            setIsLoading(true);
            const oldRequestData = createUpdateRequestObject(requestData);
            const resp = await dispatch(
              updateRequest({
                request: {
                  ...oldRequestData,
                  requestId,
                  officeId: id,
                  requestType: requestData?.requestType,
                  firstName: firstName.value as string,
                  lastName: lastName.value as string,
                  email: email.value as string,
                  phone: phone.value as string,
                  dateOfBirth: dateOfBirth.value as string,
                  ohipNumber: ohipNumber.value as string,
                  patientVerified: patientVerified.value as boolean,
                },
                requestId: requestData.requestId,
                officeId: requestData.officeId,
              })
            );
            setIsLoading(false);
            if (resp !== null) {
              dispatch(
                getOfficeRequest({
                  requestId: requestData.requestId,
                  officeId: requestData.officeId,
                })
              );
              onClickCancel();
              toast?.openToast("Patient details updated successfully!", 2000, ToastVariants.Success);
            } else {
              toast?.openToast("The update of patient details failed!");
            }
          } catch (_) {
            setIsLoading(false);
            toast?.openToast("Something went wrong please try again!", 2000, ToastVariants.Error);
          }
        }
      }
    }
  };
  return (
    <>
      {verificationError ? <p className="text-sm px-8 py-5 text-red-400 text-center">An error has occurred, please try again</p> : null}
      <div className="flex justify-between gap-5 px-8">
        {formData.map(input =>
          input.id === "patientFirstName" || input.id === "patientLastName" ? (
            <div key={input.id} className="py-2">
              {renderInput(input)}
            </div>
          ) : null
        )}
      </div>

      {formData.map(input =>
        input.id === "patientFirstName" || input.id === "patientLastName" ? null : (
          <div key={input.id} className="px-8 py-2">
            {renderInput(input)}
          </div>
        )
      )}

      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-0 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
            disabled={isLoading}
          />
          <div>
            <Button
              onClickFunc={() => {
                handleClickSave();
              }}
              disabled={(!validateForm() && errorInitiated) || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyPatient;
