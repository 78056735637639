import { headersType } from "../../models";

export const headersInitialState: headersType[] = [
  {
    title: "Fee Name",
    extraClasses: "w-full lg:w-240 pl-0 truncate",
    sortKey: "name",
    isClickable: true,
  },
  {
    title: "Description",
    extraClasses: "w-full hidden lg:block truncate",
    sortKey: "description",
    isClickable: false,
  },
  {
    title: "Price (per unit)",
    extraClasses: "w-160 hidden lg:block",
    sortKey: "price",
    isClickable: true,
  },
  {
    title: "Enable",
    extraClasses: "w-160 hidden lg:block",
    sortKey: "",
    isClickable: false,
  },
  {
    title: "",
    extraClasses: "w-40",
    sortKey: "",
    isClickable: false,
  },
];

export const headersDocTemplates: headersType[] = [
  {
    title: "Select All",
    extraClasses: "w-full hidden lg:flex lg:w-140 pl-0 ml-0",
    sortKey: "selectAll",
    isClickable: false,
    isCheckbox: true,
  },
  {
    title: "Fee Name",
    extraClasses: "w-full lg:w-240 pl-0 break-all",
    sortKey: "name",
    isClickable: true,
  },
  {
    title: "Description",
    extraClasses: "w-full hidden lg:block break-all",
    sortKey: "description",
    isClickable: false,
  },
  {
    title: "Price (per unit)",
    extraClasses: "w-160 hidden lg:block",
    sortKey: "price",
    isClickable: true,
  },
  {
    title: "",
    extraClasses: "w-40",
    sortKey: "",
    isClickable: false,
  },
];
