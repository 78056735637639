import * as React from "react";
import TablePagination from "@mui/material/TablePagination";

interface PaginationType {
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  rowsPerPage: number;
  page: number;
  count: number;
  colSpan?: number;
  rowsPerPageOptions?: number[];
}

const Pagination = ({ count, onPageChange, onRowsPerPageChange, page, rowsPerPage, colSpan = 10, rowsPerPageOptions }: PaginationType) => {
  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      colSpan={colSpan}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};

export default Pagination;
