import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { logger } from "../utils/logger";
import { authService } from "./auth.service";
import { GuestToken } from "./guest.service";

// Raw API calls will return an ApiResponse to services, and then services can throw
// ApiError to caller if anything goes wrong with service-specific codes and details
export interface ApiResponse {
  success: boolean; // Call was successful
  status?: number; // HTTP response code
  data?: any; // The returned response
}

// Just the ops we support for now
/* eslint-disable no-unused-vars */
export enum ApiPatchOp {
  Replace = "replace",
  Remove = "remove",
}
/* eslint-enable no-unused-vars */

export interface ApiPatch {
  op: ApiPatchOp;
  path: string;
  value?: any;
}

interface TokenRefreshResponse {
  accessToken: string;
}

const log = logger.getLogger("ApiService");
const bodyFormatter = (data: any) => JSON.stringify(data);

let abortController:any = null;
export class ApiService {
  private API_ENDPOINT?: string;
  private UPLOAD_ENDPOINT?: string;

  // Must be initialized before first usage
  init(accessToken?: string, refreshToken: string | null = null) {
    this.API_ENDPOINT = process.env.REACT_APP_API_URL;
    this.UPLOAD_ENDPOINT = process.env.REACT_APP_UPLOAD_URL;

    if (!accessToken) return;

    // Restore auth state
    authService.authToken = { accessToken, refreshToken, emailVerificationRequired: false, passwordChangeRequired: false };
  }

  private axiosConfig(guestToken?: GuestToken, allowCancelRequest?:boolean): AxiosRequestConfig {
    const headers: any = {
      "Content-Type": "application/json",
    };

    // Add token to header if exists, and not if we're using a guest token
    const token = authService.authToken?.accessToken;
    if (guestToken) {
      headers["X-DS-Guest-Token"] = guestToken.guestToken;
    } else if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    if(allowCancelRequest) {
      abortController = new AbortController();
    }
    return {
      headers,
      signal: allowCancelRequest && abortController ? abortController.signal : undefined,
    };
  }

  private axiosPatchConfig(guestToken?: GuestToken): AxiosRequestConfig {
    const headers: any = {
      "Content-Type": "application/json-patch+json",
    };

    // Add token to header if exists
    const token = authService.authToken?.accessToken;
    if (guestToken) {
      headers["X-DS-Guest-Token"] = guestToken.guestToken;
    } else if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    return {
      headers,
    };
  }

  async get(path: string, allowTokenRefresh = true, guestToken?: GuestToken): Promise<ApiResponse> {
    if (!this.API_ENDPOINT) throw new Error("ApiService => init must be called before first usage");

    log.debug(`GET => ${path}`);

    let response: AxiosResponse | undefined,
      success = false;

    try {
      response = await axios.get(this.API_ENDPOINT + path, this.axiosConfig(guestToken));
      success = response !== undefined;
    } catch (e: any) {
      // On 401, refresh token and if successful try again
      if (e.response?.status === 401 && allowTokenRefresh) {
        const refreshed = await this._refreshToken();
        if (refreshed) {
          log.debug("Token refreshed, trying again...");
          return await this.get(path, false);
        }
      }

      response = e.response;
      this._handleError(path, e);
    }

    const status = response?.status;
    const data = response?.data;
    log.trace(`GET response => ${JSON.stringify(data)}`);
    return { success, status, data };
  }

  // Ignore 401 is primarily for our confirm credentials functionality
  async post(path: string, body = {}, ignore401 = false, allowTokenRefresh = true, guestToken?: GuestToken, allowCancelRequest?:boolean): Promise<ApiResponse> {
    if (!this.API_ENDPOINT) throw new Error("ApiService => init must be called before first usage");
    log.debug({ msg: `POST => ${path}`, data: body, ds: bodyFormatter });

    let response: AxiosResponse | undefined,
      success = false;

    try {
      // Not sending auth headers on a token refresh
      response = await axios.post(this.API_ENDPOINT + path, body, this.axiosConfig(guestToken));
      success = response !== undefined;
    } catch (e: any) {
      // On 401, refresh token and if successful try again
      if (e.response?.status === 401 && !ignore401 && allowTokenRefresh) {
        const refreshed = await this._refreshToken();
        if (refreshed) {
          log.debug("Token refreshed, trying again...");
          return await this.post(path, body, ignore401, false);
        }
      }

      response = e.response;
      this._handleError(path, e, ignore401);
    }

    const status = response?.status;
    const data = response?.data;
    log.trace(`POST response => ${JSON.stringify(data)}`);
    return { success, status, data };
  }

  async put(path: string, body = {}, allowTokenRefresh = true): Promise<ApiResponse> {
    if (!this.API_ENDPOINT) throw new Error("ApiService => init must be called before first usage");

    log.debug({ msg: `PUT => ${path}`, data: body, ds: bodyFormatter });

    let response: AxiosResponse | undefined,
      success = false;

    try {
      response = await axios.put(this.API_ENDPOINT + path, body, this.axiosConfig());
      success = response !== undefined;
    } catch (e: any) {
      // On 401, refresh token and if successful try again
      if (e.response?.status === 401 && allowTokenRefresh) {
        const refreshed = await this._refreshToken();
        if (refreshed) {
          log.debug("Token refreshed, trying again...");
          return await this.put(path, body, false);
        }
      }

      response = e.response;
      this._handleError(path, e);
    }

    const status = response?.status;
    const data = response?.data;
    log.trace(`PUT response => ${JSON.stringify(data)}`);
    return { success, status, data };
  }

  async delete(path: string, allowTokenRefresh = true): Promise<ApiResponse> {
    if (!this.API_ENDPOINT) throw new Error("ApiService => init must be called before first usage");

    log.debug(`DELETE => ${path}`);

    let response: AxiosResponse | undefined,
      success = false;

    try {
      response = await axios.delete(this.API_ENDPOINT + path, this.axiosConfig());
      success = response !== undefined;
    } catch (e: any) {
      // On 401, refresh token and if successful try again
      if (e.response?.status === 401 && allowTokenRefresh) {
        const refreshed = await this._refreshToken();
        if (refreshed) {
          log.debug("Token refreshed, trying again...");
          return await this.delete(path, false);
        }
      }

      response = e.response;
      this._handleError(path, e);
    }

    const status = response?.status;
    const data = response?.data;
    log.trace(`DELETE response => ${JSON.stringify(data)}`);
    return { success, status, data };
  }

  async patch(path: string, operations: ApiPatch[], allowTokenRefresh = true, guestToken?: GuestToken): Promise<ApiResponse> {
    if (!this.API_ENDPOINT) throw new Error("ApiService => init must be called before first usage");

    log.debug({ msg: `PATCH => ${path}`, data: operations, ds: bodyFormatter });

    let response: AxiosResponse | undefined,
      success = false;

    try {
      response = await axios.patch(this.API_ENDPOINT + path, operations, this.axiosPatchConfig(guestToken));
      success = response !== undefined;
    } catch (e: any) {
      // On 401, refresh token and if successful try again
      if (e.response?.status === 401 && allowTokenRefresh) {
        const refreshed = await this._refreshToken();
        if (refreshed) {
          log.debug("Token refreshed, trying again...");
          return await this.patch(path, operations, false);
        }
      }

      response = e.response;
      this._handleError(path, e);
    }

    const status = response?.status;
    const data = response?.data;
    log.trace(`PATCH response => ${JSON.stringify(data)}`);
    return { success, status, data };
  }

  async upload(path: string, formData: FormData, callback?: any, allowTokenRefresh = true, allowCancelRequest?:boolean): Promise<ApiResponse> {
    if (!this.API_ENDPOINT) throw new Error("ApiService => init must be called before first usage");

    log.debug(`UPLOAD => ${path}`);

    let response: AxiosResponse | undefined,
      success = false;

    const tickerConfig = {
      onUploadProgress: function (progressEvent: any) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (callback && percentCompleted) {
          callback(percentCompleted);
        }
      },
    };

    // Override content type
    const config = this.axiosConfig(undefined, allowCancelRequest);
    config.headers!["Content-Type"] = "multipart/form-data";

    const newConfig = { ...config, ...tickerConfig };

    try {
      response = await axios.post(this.UPLOAD_ENDPOINT + path, formData, newConfig);
      success = response !== undefined;
    } catch (e: any) {
      // On 401, refresh token and if successful try again
      if (e.response?.status === 401 && allowTokenRefresh) {
        const refreshed = await this._refreshToken();
        if (refreshed) {
          log.debug("Token refreshed, trying again...");
          return await this.upload(path, formData, callback, false);
        }
      }

      response = e.response;
      this._handleError(path, e);
    }

    const status = response?.status;
    const data = response?.data;
    log.trace(`UPLOAD response => ${JSON.stringify(data)}`);
    return { success, status, data };
  }

  private _handleError(path: string, error: AxiosError, ignore401 = false) {
    // Forcing logout on a 401
    if (error.response?.status === 401 && !ignore401) {
      //log.warn(`Received 401 on '${path}', forcing logout.`);
      authService.signOut();
      return;
    }

    log.warn(`API error (${path}): ${error}`);
  }

  // Return true if calling method should try again
  private async _refreshToken(): Promise<boolean> {
    // If user isn't authenticated then we don't refresh
    if (!authService.isAuthenticated()) {
      return false;
    }

    const refreshToken = authService.authToken?.refreshToken;
    if (!refreshToken) {
      log.warn("_refreshToken => No refresh token available");
      return false;
    }

    try {
      log.debug("Refreshing access token...");
      const response = await axios.post(this.API_ENDPOINT + "/api/v1/auth/token", { refreshToken: refreshToken }, {});
      const data = response.data as TokenRefreshResponse;
      if (data.accessToken) {
        authService.tokenRefreshed(data.accessToken);
        log.debug("Access token refreshed");
        return true;
      }
      log.warn(`Unable to renew access token, received ${response}`);
    } catch (e: any) {
      log.warn(`Unable to renew access token, received ${e}`);
    }

    return false;
  }

  async stopApiRequest () {
    if(abortController) {
      abortController.abort();
    }  
    abortController = null;
  }  
}

export const apiService = new ApiService();
