export type DsFile = {
  fileId: string;
  originalFilename: string;
  contentType?: string;
  size: number;
  url?: string;
  urlExpires?: string; // TODO: can this be a Date?
};

export enum RequestFileTypes {
  Invoice = 'invoice',
  Document = 'document',
  Receipt = 'receipt',
}
