import { UpdateRequest } from "../models/update-request.model";
import { Request } from "../models/request.model";
import { apiService } from "./api.service";
import { ApiPage, emptyApiPage } from "../models/api-page.model";

export class RequestService {
  private static BASE_PATH = "/api/v1/requests";

  // Returns saved request with ID included, or null on failure
  static async addRequest(request: UpdateRequest): Promise<Request | null> {
    const response = await apiService.post(`${this.BASE_PATH}`, request);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async getRequest(requestId: string): Promise<Request | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${requestId}`);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async deleteRequest(requestId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/${requestId}`);
    if (!response.success) return false;

    return true;
  }

  static async searchRequest(term: string, page = 0, size = 10, sort = "requestNumber,asc"): Promise<ApiPage<Request>> {
    const response = await apiService.post(`${this.BASE_PATH}/search?page=${page}&size=${size}&sort=${sort}`, {
      term
    });
    if (!response.success) return emptyApiPage<Request>();

    return response.data as ApiPage<Request>;
  }
}
