import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { formatOhipNumber } from "../../../utils";

const PatientDetails = () => {
  const { patientRequestData } = useSelector((state: RootState) => state.user);

  return (
    <>
      <p className="text-md mb-2">
        <span className="font-medium">OHIP Number:</span> {patientRequestData?.ohipNumber ? formatOhipNumber(patientRequestData?.ohipNumber) : "-"}
      </p>
      <p className="text-md mb-2">
        <span className="font-medium">Date of Birth:</span> {moment(patientRequestData?.dateOfBirth).format("ll")}
      </p>
      <p className="text-md mb-2">
        <span className="font-medium">Email Address:</span> <span className="text-base" style={{wordWrap:"break-word"}}>{patientRequestData?.email}</span>
      </p>
      <p className="text-md">
        <span className="font-medium">Phone #:</span> {patientRequestData?.phone}
      </p>
    </>
  );
};

export default PatientDetails;
