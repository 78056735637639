import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiErrorCode } from "../../models/api-error.model";
import { ApiPage } from "../../models/api-page.model";
import { Office } from "../../models/office.model";
import { OfficeService } from "../../services/office.service";
import { RequestMessage } from "../../models/request-message.model";
import { UpdateFee } from "../../models/update-fee.model";
import { Request } from "../../models/request.model";
import { logger } from "../../utils/logger";

const log = logger.getLogger('RequestErrors');
interface OfficeDataForRender extends Office {
  tooltipVisible: boolean;
}

type officeList = {
  sortedBy: string;
  data: ApiPage<OfficeDataForRender> | null;
};

interface sortObj {
  start: number;
  end: number;
  pageNumber: number;
  rowsPerPage: number;
  sortedBy: string;
}

export interface OfficeStateType {
  error: boolean;
  errorType: ApiErrorCode;
  loading: boolean;
  apiSucess: boolean;
  officeList: officeList;
  sort: sortObj;
  currentOffice: Office | null;
  activeTabIndex: number;
  openPaymentSidebar: boolean;
  internalMessage: RequestMessage[] | null;
  patientMessage: RequestMessage[] | null;
  requestFee: Request | null;
}

type listOfficeResponse = { data: ApiPage<Office>; sortType: string; page: number; size: number };

interface ListOfficeType {
  searchTerm: string;
  page: number;
  size: number;
  sortType: string;
}

interface getOfficeInternalMessageType {
  officeId: string;
  requestId: string;
}
interface getOfficeRequestPatientMessageType {
  officeId: string;
  requestId: string;
}

interface addOfficeRequestMessageType {
  officeId: string;
  requestId: string;
  message: string;
  internal: boolean;
  senderId?: string;
  senderName?: string;
}

interface updateOfficeRequestFeesTypes {
  officeId: string;
  requestId: string;
  fees: UpdateFee[];
}

const initialState: OfficeStateType = {
  loading: false,
  error: false,
  errorType: ApiErrorCode.UNKNOWN,
  apiSucess: false,
  officeList: { data: null, sortedBy: "" },
  sort: {
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "name,desc",
  },
  currentOffice: null,
  activeTabIndex: 0,
  openPaymentSidebar: false,
  internalMessage: null,
  patientMessage: null,
  requestFee: null,
};

export const listOfficeData = createAsyncThunk(
  "requestApi/list",
  async ({ searchTerm, page, sortType, size }: ListOfficeType, { rejectWithValue }) => {
    try {
      const res = await OfficeService.lookupOffice(searchTerm, page, size, sortType.length > 0 ? sortType : undefined);
      return { data: res, sortType: sortType, page, size };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getOfficeRequestInternalMessages = createAsyncThunk(
  "requestApi/getOfficeRequestInternalMessages",
  async ({ officeId, requestId }: getOfficeInternalMessageType, { rejectWithValue }) => {
    try {
      const res = OfficeService.getOfficeRequestInternalMessages(officeId, requestId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getOfficeRequestPatientMessages = createAsyncThunk(
  "requestApi/getOfficeRequestPatientMessages",
  async ({ officeId, requestId }: getOfficeRequestPatientMessageType, { rejectWithValue }) => {
    try {
      const res = OfficeService.getOfficeRequestPatientMessages(officeId, requestId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const setOfficeRequestMessageRead = createAsyncThunk(
  "requestApi/setOfficeRequestMessageRead",
  async (
    { officeId, requestId, messageId, read }: { officeId: string; requestId: string; messageId: string; read: boolean },
    { rejectWithValue }
  ) => {
    try {
      const res = OfficeService.setOfficeRequestMessageRead(officeId, requestId, messageId, read);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const updateOfficeRequestFees = createAsyncThunk(
  "requestApi/updateOfficeRequestFees",
  async ({ officeId, requestId, fees }: updateOfficeRequestFeesTypes, { rejectWithValue }) => {
    try {
      const res = OfficeService.updateOfficeRequestFees(officeId, requestId, fees);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const addOfficeRequestMessage = createAsyncThunk(
  "requestApi/addOfficeRequestMessage",
  async ({ officeId, requestId, message, internal, senderId, senderName }: addOfficeRequestMessageType, { rejectWithValue }) => {
    try {
      const res = OfficeService.addOfficeRequestMessage(officeId, requestId, message, internal, senderId, senderName);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const RequestSlice = createSlice({
  name: "requestApi",
  initialState,
  reducers: {
    listOffices: state => {
      state.error = false;
      state.errorType = ApiErrorCode.UNKNOWN;
      state.loading = false;
      state.apiSucess = false;
    },

    resetState: state => {
      state.officeList.data = null;
    },
    setActiveIndex: (state, action: PayloadAction<number>) => {
      state.activeTabIndex = action.payload;
    },
    setOpenPaymentSidebar: (state, action: PayloadAction<boolean>) => {
      state.openPaymentSidebar = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(listOfficeData.pending, state => {
        if (state.officeList.data === null) {
          state.loading = true;
        }
        state.error = false;
      })
      .addCase(listOfficeData.fulfilled, (state, action: PayloadAction<listOfficeResponse>) => {
        state.loading = false;
        state.error = false;
        state.officeList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        const { sortType } = action.payload;
        state.sort.pageNumber = action.payload.page;
        state.officeList.sortedBy = sortType;
      })
      .addCase(listOfficeData.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getOfficeRequestInternalMessages.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOfficeRequestInternalMessages.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getOfficeRequestInternalMessages.fulfilled, (state, action: PayloadAction<RequestMessage[]>) => {
        state.loading = false;
        state.error = false;
        state.internalMessage = action.payload;
      })
      .addCase(getOfficeRequestPatientMessages.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOfficeRequestPatientMessages.fulfilled, (state, action: PayloadAction<RequestMessage[]>) => {
        state.loading = false;
        state.error = false;
        state.patientMessage = action.payload;
      })
      .addCase(getOfficeRequestPatientMessages.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateOfficeRequestFees.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateOfficeRequestFees.fulfilled, (state, action: PayloadAction<Request | null>) => {
        state.loading = false;
        state.error = false;
        state.requestFee = action.payload;
      })
      .addCase(updateOfficeRequestFees.rejected, state => {
        state.loading = false;
        state.error = true;
      })

      .addCase(addOfficeRequestMessage.pending, state => {
        state.error = false;
        state.apiSucess = false;
        state.loading = true
      })
      .addCase(addOfficeRequestMessage.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload !== null) {
          state.apiSucess = true;
        } else {
          state.apiSucess = false;
        }
        state.loading = false;
        state.error = false;
      })
      .addCase(addOfficeRequestMessage.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.loading = false;
        state.errorType = action.payload;
      });
  },
});

export const { listOffices, resetState, setActiveIndex, setOpenPaymentSidebar } = RequestSlice.actions;

export default RequestSlice.reducer;
