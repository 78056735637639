/* eslint-disable @typescript-eslint/no-var-requires */
import CashFlowReport from "./CashFlowReport";
import FinancesReport from "./FinancesReport";
import Download from "../../assets/images/download.png";
import moment from "moment";
import { saveAs } from "file-saver";
import { Office } from "../../models/office.model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getOfficeFinanceReportCsv } from "../../redux/slices/FinanceSlice";
import { logger } from "../../utils/logger";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { CircularProgress } from "@mui/material";
import { FinanceReportKeys } from "../../utils/constants";
import { FinanceBilledTo } from "../../models/finance.model";
import FinancesReportPDF from "./FinancesReportPDF";
import CashFlowReportPDF from "./CashFlowReportPDF";

const html2pdf = require("html2pdf.js");
const JSZip = require("jszip");
const log = logger.getLogger("ReportExportError");

const ReportingTab = ({
  selectedMonth,
  selectedOffice,
  monthName,
}: {
  monthOptions: any;
  changeReportTabMonth: any;
  selectedMonth: string;
  selectedOffice: Office | null;
  monthName: any;
}) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const { financeReportTabData } = useSelector((state: RootState) => state.finance);
  let reportMonth = moment().subtract(1, "month").format("MMMM YYYY");
  if (selectedMonth) {
    reportMonth = selectedMonth;
  }

  const downloadInvoiceAsPdf = async () => {
    try {
      setLoading(true);
      const splitMonth = reportMonth.split(" ");
      const month = monthName.indexOf(splitMonth[0]);
      const year = splitMonth[1];
      const firstDay = moment([year, month]).startOf("month").format("YYYY-MM-DD");
      const lastDay = moment([year, month]).endOf("month").add(1, "day").format("YYYY-MM-DD");
      let finalData = "";
      if (selectedOffice?.officeId) {
        const response: any = await dispatch(
          getOfficeFinanceReportCsv({
            officeId: selectedOffice?.officeId,
            startDate: new Date(firstDay),
            endDate: new Date(lastDay),
          })
        );
        if (response && response?.payload?.toString().split(/\n/).length) {
          const data = response?.payload?.toString().split(/\n/);
          const headers = data[0];
          const csvRows = [];
          csvRows.push(headers);
          data.slice(1).map((item: string) => {
            csvRows.push(item);
            return true;
          });
          finalData = csvRows.join("\n");
        }
      }
      const element = document.getElementById("finance-report-container");
      const fileMonth = `${moment([year, month]).format("MMMM")}${moment([year, month]).format("YYYY")}`;
      const officeName = selectedOffice?.name ? selectedOffice?.name?.split(" ").join("") : "";
      const pdfFilename = `${officeName}Summary${fileMonth}`;
      const csvFilename = `${officeName}AccountingCSV${fileMonth}.csv`;
      const zipName = `${officeName}MonthlyReport${fileMonth}.zip`;
      element?.classList.remove("hidden");
      const opt = {
        margin: [0, 0, 0, 0],
        filename: pdfFilename,
        html2canvas: { dpi: 75, scale: 2, letterRendering: true },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        jsPDF: { orientation: "portrait", unit: "in", format: "a4", compressPDF: true },
        image: { type: "jpeg", quality: 1 },
      };
      const zip = new JSZip();
      zip.file(`${pdfFilename}.pdf`, html2pdf().set(opt).from(element).outputPdf("arraybuffer"));
      if (finalData) {
        zip.file(`${csvFilename}`, new Blob([finalData], { type: "text/csv" }));
      }
      setLoading(false);
      return zip.generateAsync({ type: "blob" }).then((blob: any) => {
        saveAs(blob, `${zipName}`);
      });
    } catch (err) {
      setLoading(false);
      toast?.openToast("Download report failed. Try again!");
      log.warn(JSON.stringify(err));
    }
  };

  useEffect(() => {
    if (financeReportTabData) {
      const reportData: any = financeReportTabData;
      const length = FinanceReportKeys.filter((item: string) => reportData[item] !== undefined && reportData[item] > 0).length;
      const length1 = financeReportTabData?.billedTo?.filter((item: FinanceBilledTo) => item.amount > 0).length;
      if (!length && !length1) {
        setShowDownloadButton(false);
      } else {
        setShowDownloadButton(true);
      }
    }
  }, [financeReportTabData]);

  return (
    <div className="relative">
      <div className="mb-5 flex items-center">
        {loading ? (
          <CircularProgress size={22} className="ml-5" />
        ) : showDownloadButton ? (
          <img src={Download} alt="Download Icon" className="w-6 h-6 cursor-pointer" onClick={downloadInvoiceAsPdf} />
        ) : null}
      </div>
      <div className="grid lg:grid-cols-2 gap-8 mb-10">
        <FinancesReport />
        <CashFlowReport />
      </div>
      <div className="opacity-0">
        <div id="finance-report-container" className="lg:grid-cols-1 hidden">
          <FinancesReportPDF isPdf={true} selectedMonth={reportMonth} />
          <CashFlowReportPDF isPdf={true} selectedMonth={reportMonth} />
        </div>
      </div>
    </div>
  );
};

export default ReportingTab;
