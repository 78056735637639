import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropArea, ProtectedComponent, Tooltip, ConfirmationDialog, UserSideBar, FinalDocuments, Button } from "..";
import { FileTypes, ToastVariants } from "../../models";
import { AppDispatch, RootState } from "../../redux/store";
import { AuthPermission, authService, UserState } from "../../services/auth.service";
import { getFileSize, onFileError } from "../../utils";
import { colors } from "../../utils/constants";
import {
  addOfficeRequestFiles,
  clearRequestFiles,
  deleteOfficeRequestFile,
  getOfficeRequest,
  getOfficeRequestFiles,
  requestFilesType,
  sendRequestFileToPatient,
  sendRequestFileToThirdParty,
  setRequestData,
  toggleToolip,
} from "../../redux/slices/OfficeRequestSlice";
import moment from "moment";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { saveAs } from "file-saver";
import { CircularProgress, Modal } from "@mui/material";
import { clearUploadedFiles, uploadedInvoice } from "../../redux/slices/FileSlice";
import { Checkbox } from "../../components";
import close from "../../assets/images/close.svg";
import { RequestState, RequestType } from "../../models/request.model";
import { apiService } from "../../services/api.service";
import { ServiceBilledTo } from "../../models/service.model";
import { getOfficeRequestTimeline } from "../../redux/slices/UserSlice";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { OfficeService } from "../../services/office.service";

const RequestOverViewFileUpload = ({
  requestId,
  officeId,
  readOnly = false,
}: {
  requestId: string | undefined;
  officeId: string | undefined;
  readOnly?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const allowedFiles = [FileTypes.JPEG, FileTypes.PNG, FileTypes.PDF];
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [dropAreaError, setDropAreaError] = useState<string>("");
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [authorized] = useState(authService.currentUserPermissions.includes(AuthPermission.Request));
  const addedFiles: string[] = [];
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [selectedFilesForMerge, setSelectedFilesForMerge] = useState<requestFilesType[]>([]);
  const [selectedFinalFilesForMerge, setSelectedFinalFilesForMerge] = useState<requestFilesType[]>([]);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openSendFinalDoc, setOpenSendFinalDoc] = useState(false);
  const [openPaymentNotDone, setOpenPaymentNotDone] = useState(false);
  const { requestData, sentToPatientLoading } = useSelector((state: RootState) => state.officeRequest);
  const showThirdPaty = requestData && (requestData.recipientContact || requestData.recipientName) ? true : false;
  const [sentToPatientError, setSentToPatientError] = useState(false);
  const [sentToThirdPartyError, setSentToThirdPartyError] = useState(false);
  const requestCompletedOrCancelled = requestData?.state === RequestState.Complete || requestData?.state === RequestState.Cancelled;
  const serviceBilledTo = requestData?.serviceBilledToOverride ? requestData?.serviceBilledToOverride : requestData?.service?.serviceBilledTo;
  const isThirdParty = requestData?.requestType === RequestType.ThirdPartyOffice;
  const showFinalDocCheckBox =
    serviceBilledTo === ServiceBilledTo.Patient && requestData?.paymentComplete === false && RequestType.DocnoteDebit !== requestData?.requestType;
  const files = useSelector((state: RootState) => state.officeRequest.requestFiles);
  const { loading, invoiceCreate } = useSelector((state: RootState) => state.file);
  const mergedDocuments = files.filter(f => f.isFinal);
  const [modalopen, setModalopen] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [finalDocumentSend, setFinalDocumentSend] = useState(false);
  const handleConfirmationBox = (valueSelected: "yes" | "no") => {
    if (valueSelected === "no") {
      setConfirmationOpen(false);
    }
    if (valueSelected === "yes") {
      if (selectedFileId && officeId && requestId) {
        dispatch(deleteOfficeRequestFile({ fileId: selectedFileId, officeId: officeId, requestId: requestId }));
        setConfirmationOpen(false);
      }
    }
  };

  const handleDropArea = (fileIds: string[]) => {
    const filesToAdd: string[] = [];
    fileIds.forEach(id => {
      if (!addedFiles.includes(id)) {
        filesToAdd.push(id);
        addedFiles.push(id);
        if (officeId && requestId) dispatch(addOfficeRequestFiles({ fileIds: [id], officeId: officeId, requestId: requestId }));
      }
    });
    dispatch(clearUploadedFiles());
  };

  const openFile = (fileId: string, url?: string) => {
    if (url) {
      // window.open(url, "_blank");
      setFileUrl(url);
      return;
    }
    if (fileId) {
      const fileToOpen = files.find((f: { fileId: string }) => f.fileId === fileId);
      // window.open(fileToOpen?.url, "_blank");
      setFileUrl(fileToOpen?.url || "");
    }
  };

  const handleTooltip = (open: boolean, fileId?: string) => {
    dispatch(toggleToolip({ visible: open, fileId: fileId }));

    if (open && fileId) {
      setSelectedFileId(fileId);
    }
  };

  const fileToolTipOptions = authorized && !readOnly ? ["View File", "Delete File"] : ["View File"];
  const handleFileTooltip = (type: string, fileId: string, url?: string) => {
    if (type === fileToolTipOptions[0]) {
      openFile(fileId, url);
      openImageInModal();
    }
    if (type === fileToolTipOptions[1]) {
      setConfirmationOpen(true);
    }
  };

  const handleDownloadFile = async (url: string, fileName: string) => {
    const blob = await fetch(url).then(r => r.blob());
    saveAs(blob, fileName);
  };

  const getTooltipOptions = (data: requestFilesType) => {
    if (authorized) {
      let options = ["View Document", "Download Document"];
      if (readOnly) {
        return options;
      }
      let sliceNo = 2;
      if (!data.sentPatient && !data.sentThirdParty) {
        options = [...options, "Delete Document"];
        sliceNo = 3;
      }
      if (requestData?.patient?.userState === UserState.Active) {
        options = [...options, `${data.sentPatient ? "Resend" : "Send"} to Patient`];
      }

      if (isThirdParty || requestData?.requestType === RequestType.PrescriptionRefill) {
        options = [...options.slice(0, sliceNo), ...options.slice(sliceNo + 1)];
      }
      if (showThirdPaty || requestData?.requestType === RequestType.PrescriptionRefill) {
        options = [
          ...options,
          `${data.sentThirdParty ? "Resend" : "Send"} to ${requestData?.requestType === RequestType.PrescriptionRefill ? "Pharmacy" : "Third Party"}`,
        ];
      }
      return options;
    } else {
      return ["View Document"];
    }
  };

  const sendToPatient = async () => {
    if (officeId && requestId) {
      if (mergedDocuments?.length > 1) {
        const requestFilePromises = selectedFinalFilesForMerge.map(async fileId => {
          await sendRequestAndGetFilesToP(fileId.requestFileId, officeId, requestId);
        });
        await Promise.all(requestFilePromises);
      } else {
        const defaultFileId = mergedDocuments[0].requestFileId;
        await sendRequestAndGetFilesToP(defaultFileId, officeId, requestId);
      }
    }
  };

  const sendRequestAndGetFilesToP = async (fileId: string, officeId: string, requestId: string) => {
    const action = await dispatch(
      sendRequestFileToPatient({
        officeId: officeId,
        requestId: requestId,
        requestFileId: fileId,
      })
    );
    if (action.payload !== null) {
      toast?.openToast("Successfully sent to patient");
      dispatch(getOfficeRequestTimeline({ officeId: officeId, requestId: requestId, page: 0, size: 5 }));
      dispatch(getOfficeRequestFiles({ officeId: officeId, requestId: requestId }));
    } else {
      setSentToThirdPartyError(true);
      setTimeout(() => {
        setSentToThirdPartyError(false);
      }, 3000);
    }
  };

  const sendRequestAndGetFilesToTP = async (fileId: string, officeId: string, requestId: string) => {
    const action = await dispatch(
      sendRequestFileToThirdParty({
        officeId: officeId,
        requestId: requestId,
        requestFileId: fileId,
      })
    );
    if (action.payload !== null) {
      toast?.openToast("Successfully sent to 3rd party");
      await dispatch(getOfficeRequest({ officeId, requestId }));
      await dispatch(getOfficeRequestFiles({ officeId: officeId, requestId: requestId }));
    } else {
      setSentToPatientError(true);
      setTimeout(() => {
        setSentToPatientError(false);
      }, 3000);
    }
  };

  const sendToThirdParty = async () => {
    if (!requestData?.recipientName || !requestData?.recipientContact) {
      toast?.openToast("Please enter recipient contact information to send this document", 2000, ToastVariants.Error);
      return;
    }
    if (officeId && requestId) {
      if (mergedDocuments?.length > 1) {
        const requestFilePromises = selectedFinalFilesForMerge.map(async fileId => {
          await sendRequestAndGetFilesToTP(fileId.requestFileId, officeId, requestId);
        });
        await Promise.all(requestFilePromises);
      } else {
        const defaultFileId = mergedDocuments[0].requestFileId;
        await sendRequestAndGetFilesToTP(defaultFileId, officeId, requestId);
      }
    }
  };

  const handleDocumentTooltip = (type: string, fileId: string, url?: string) => {
    switch (type) {
      case "View Document":
        openFile(fileId, url);
        openImageInModal();
        break;
      case "Delete Document":
        setConfirmationOpen(true);
        break;
      case "Download Document":
        const fileToDownload = mergedDocuments.find(v => v.requestFileId === fileId);
        if (fileToDownload?.url && fileToDownload.originalFilename) handleDownloadFile(fileToDownload.url, fileToDownload.originalFilename);
        break;
      case "Send to Patient":
      case "Resend to Patient":
        if (requestData?.state === RequestState.PendingVerification || requestData?.patient?.userState === UserState.Invited) {
          showUnverifiedToast();
          return;
        }
        if (officeId && requestId && fileId) {
          sendRequestAndGetFilesToP(fileId, officeId, requestId);
        }
        break;
      case "Send to Third Party":
      case "Resend to Third Party":
      case "Send to Pharmacy":
      case "Resend to Pharmacy":
        if (
          (requestData?.state === RequestState.PendingVerification || requestData?.patient?.userState === UserState.Invited) &&
          requestData?.requestType !== RequestType.ThirdPartyOffice
        ) {
          showUnverifiedToast();
          return;
        }
        if (!requestData?.recipientName || !requestData?.recipientContact) {
          toast?.openToast("Please enter recipient contact information to send this document", 2000, ToastVariants.Error);
          return;
        }
        if (officeId && requestId && fileId) {
          sendRequestAndGetFilesToTP(fileId, officeId, requestId);
        }
        break;
      default:
        toast?.openToast();
        break;
    }
  };

  const handleFinalFilesCheckbox = (value: boolean, requestFileId: string) => {
    const fileToMerge = mergedDocuments.find(f => f.requestFileId === requestFileId);
    if (fileToMerge && value) {
      if (!selectedFinalFilesForMerge.includes(fileToMerge)) {
        setSelectedFinalFilesForMerge([...selectedFinalFilesForMerge, fileToMerge]);
      }
    }
    if (!value) {
      const temp = [...selectedFinalFilesForMerge];
      const index = temp.findIndex(f => f.requestFileId === requestFileId);
      setSelectedFinalFilesForMerge([...selectedFinalFilesForMerge.slice(0, index), ...selectedFinalFilesForMerge.slice(index + 1)]);
    }
  };
  const handleFilesCheckbox = (value: boolean, requestFileId: string) => {
    const fileToMerge = files.find(f => f.requestFileId === requestFileId);
    if (fileToMerge && value) {
      if (!selectedFilesForMerge.includes(fileToMerge)) {
        setSelectedFilesForMerge([...selectedFilesForMerge, fileToMerge]);
      }
    }
    if (!value) {
      const temp = [...selectedFilesForMerge];
      const index = temp.findIndex(f => f.requestFileId === requestFileId);
      setSelectedFilesForMerge([...selectedFilesForMerge.slice(0, index), ...selectedFilesForMerge.slice(index + 1)]);
    }
  };

  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
    setOpenSendFinalDoc(false);
    setOpenPaymentNotDone(false);
  };

  const sendFinalDoc = async (fullfill: boolean) => {
    if (officeId && requestId && requestData) {
      try {
        const resp = await OfficeService.updateOfficeRequestFulfillOnPayment(requestData.officeId, requestData.requestId, fullfill);
        if (resp !== null) {
          dispatch(setRequestData(resp));
        } else {
          toast?.openToast("Document send failed!");
        }
      } catch (_) {
        toast?.openToast("Something went wrong!", 2000, ToastVariants.Error);
      }
    }
  };

  const handleConfirmationSuccess = () => {
    if (openSendFinalDoc) {
      sendFinalDoc(true);
    }
    if (openPaymentNotDone) {
      sendToPatient();
      setSelectedFinalFilesForMerge([]);
    }
    setOpenPaymentNotDone(false);
    setOpenSendFinalDoc(false);
  };

  const handleSelectAll = (value: boolean) => {
    if (value) {
      setSelectedFilesForMerge([...files.filter(f => !f.isFinal)]);
    } else {
      setSelectedFilesForMerge([]);
    }
  };

  const handleSelectAllFinalDoc = (value: boolean) => {
    if (value) {
      setSelectedFinalFilesForMerge([...mergedDocuments]);
    } else {
      setSelectedFinalFilesForMerge([]);
    }
  };

  useEffect(() => {
    if (requestData?.requestFileIds?.length && officeId && requestId) {
      dispatch(getOfficeRequestFiles({ officeId: officeId, requestId: requestId }));
      dispatch(uploadedInvoice(false));
    } else {
      dispatch(clearRequestFiles());
    }
    setFinalDocumentSend(requestData?.fulfillOnPayment ? requestData?.fulfillOnPayment : false);
  }, [requestData]);

  useEffect(() => {
    setSelectedFilesForMerge([]);
  }, [files.length]);

  // useEffect(() => {
  //   uploadedFiles.map(file => {
  //     if (!addedFiles.includes(file.fileId)) {
  //       addedFiles.push(file.fileId);
  //       if (officeId && requestId) dispatch(addOfficeRequestFiles({ fileIds: [file.fileId], officeId: officeId, requestId: requestId }));
  //     }
  //     return false;
  //   });
  //   if (uploadedFiles.length) dispatch(clearUploadedFiles());
  // }, [uploadedFiles.length, requestData?.state === RequestState.Complete, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearUploadedFiles());
    };
  }, []);

  const openImageInModal = () => {
    setModalopen(true);
  };

  const handleFinalDocumnetSend = () => {
    if (finalDocumentSend) {
      setFinalDocumentSend(false);
      sendFinalDoc(false);
    } else {
      setOpenSendFinalDoc(true);
    }
  };

  const showUnverifiedToast = () => {
    let msg = "Request must be verified before sending documents";
    if (requestData?.patient?.userState === UserState.Invited && requestData?.requestType !== RequestType.ThirdPartyOffice)
      msg = "Patient must be verified before sending documents to patient";
    toast?.openToast(msg, 2000, ToastVariants.Error);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <p className="text-base font-semibold">Files</p>

        {readOnly ? null : <p style={{ color: colors.Red }}>{dropAreaError}</p>}
        {readOnly ? null : (
          <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
            <DropArea
              allowedFileTypes={allowedFiles}
              maxFileSize={50 * 1024 * 1024}
              maxNumOfFiles={10}
              onError={err => setDropAreaError(onFileError(err))}
              onFilesChange={handleDropArea}
              // onProgress={setUploadProgress}
              hideImageInPreview
              hidePreviewAfterUpload
              filePreviewVisible={true}>
              {(<span className="text-sm text-docsigna-blue-light font-medium cursor-pointer">{"+ Add File"}</span>) as unknown as Element}
            </DropArea>
          </ProtectedComponent>
        )}
      </div>
      <ConfirmationDialog
        open={confirmationOpen}
        description={`This will permanently delete this file, proceed?`}
        title={""}
        successButtonText={"Yes"}
        handleSuccess={() => {
          handleConfirmationBox("yes");
        }}
        failureButtonText={"No"}
        handleFailure={() => {
          handleConfirmationBox("no");
        }}
      />
      <div className={`${!files.length && readOnly ? "" : "p-6 w-full border border-docsigna-pink rounded-lg mb-6"}`}>
        {readOnly ? null : (
          <ProtectedComponent requiredPermission={[AuthPermission.Request]}>
            <DropArea
              allowedFileTypes={allowedFiles}
              maxFileSize={50 * 1024 * 1024}
              maxNumOfFiles={10}
              onError={err => setDropAreaError(onFileError(err))}
              onFilesChange={handleDropArea}
              // onProgress={setUploadProgress}
              hideImageInPreview
              hidePreviewAfterUpload
              dropAreaText="files"
              filePreviewVisible={true}
            />
          </ProtectedComponent>
        )}
        <div className={`block md:flex justify-between items-center flex-wrap w-full ${files.length > 0 ? "border-b" : ""} mt-5`}>
          {files.length > 0 ? (
            <>
              <div className="w-full lg:w-1/2 p-3 pl-0 pr-0 lg:pr-3 pb-0 lg:pb-3 text-xs text-slate-500 font-medium uppercase flex items-center">
                {readOnly ? null : (
                  <>
                    <Checkbox
                      checked={files.filter(f => !f.isFinal).length ? selectedFilesForMerge.length === files.filter(f => !f.isFinal).length : false}
                      onChange={e => {
                        handleSelectAll(e.currentTarget.checked);
                      }}
                      label=""
                    />
                  </>
                )}
                File name
              </div>
              <div className="flex justify-between items-center w-full lg:w-1/2">
                <div className="inline-block w-full p-3 pl-0 lg:pl-3 text-xs text-slate-500 font-medium uppercase">Date</div>
                <div className="inline-block w-full p-3  text-xs text-slate-500 font-medium uppercase">Size</div>
                <div className="inline-block w-auto p-3 pr-0 lg:pr-3 text-xs text-slate-500 font-medium uppercase relative">
                  <span className="inline-block w-4">&nbsp;</span>
                </div>
              </div>
            </>
          ) : readOnly ? (
            "-"
          ) : null}
        </div>
        {/* {uploadProgress !== 100 ? (
          <div className="flex justify-between items-center border-b w-full">
            <div className="w-full">
              <LinearProgress value={uploadProgress} variant="determinate" />
            </div>
          </div>
        ) : null} */}
        {invoiceCreate === true && loading ? (
          <div className="px-[50%] mt-2">
            <CircularProgress />
          </div>
        ) : null}
        {files
          .filter(f => !f.isFinal)
          .map(file => (
            <div className="block md:flex justify-between items-center flex-wrap w-full border-b" key={file.requestFileId}>
              <div className="w-full lg:w-1/2 p-3 pl-0 pr-0 lg:pr-3 pb-0 lg:pb-3 flex items-center">
                {readOnly ? null : (
                  <>
                    <Checkbox
                      checked={selectedFilesForMerge.map(v => v.requestFileId).includes(file.requestFileId)}
                      onChange={e => handleFilesCheckbox(e.currentTarget.checked, file.requestFileId)}
                      label=""
                    />
                  </>
                )}
                <span
                  className="text-base text-docsigna-blue-dark cursor-pointer break-all"
                  onClick={() => {
                    openFile(file.fileId, file.url);
                    openImageInModal();
                  }}>
                  {file.originalFilename}
                </span>
              </div>
              <div className="flex justify-between items-center w-full lg:w-1/2">
                <div className="inline-block w-full p-3 pl-0 lg:pl-3  text-sm">{moment(file.createdDate).format("ll")}</div>
                <div className="inline-block w-full p-3 ">{getFileSize(file.size)}</div>
                <div className="inline-block w-auto p-3 pr-0 lg:pr-3 relative">
                  <Tooltip
                    open={file.tooltipVisible}
                    onSelect={type => handleFileTooltip(type, file.fileId, file.url)}
                    options={fileToolTipOptions}
                    imageComponent={<MoreHorizIcon htmlColor={`${colors.Orange}`} className="cursor-pointer" />}
                    handleOpenTooltip={() => {
                      handleTooltip(true, file.requestFileId);
                    }}
                    handleCloseTooltip={() => {
                      handleTooltip(false);
                    }}
                    additionalComponents={
                      authorized
                        ? [
                            <div className="block" key={"1"} style={{ cursor: "pointer" }}>
                              <p
                                className="block text-base px-4 py-3 border-b border-gray-200"
                                onClick={() => {
                                  if (file.url) handleDownloadFile(file.url, file.originalFilename);
                                  handleTooltip(false);
                                }}>
                                Download File
                              </p>
                            </div>,
                          ]
                        : []
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        {
          <span>
            <div className="flex justify-between items-center w-full mt-4">
              <div className="w-full py-3">
                <p className="text-base font-semibold ">Final Documents</p>
              </div>
              <div className="flex justify-end items-center w-full">
                {readOnly ? null : (
                  <div className="pb-0 text-right">
                    <Button onClickFunc={() => (selectedFilesForMerge.length === 0 ? null : setSideBarOpen(true))} text="Create Final" />
                  </div>
                )}
              </div>
            </div>
            {readOnly ? null : (
              <p className="text-sm mt-2">
                To create a final document to send to patients and/or third parties, select the documents and tap Create Final. (When multiple docs
                are selected they will be merged into a single file).{" "}
              </p>
            )}

            {sentToPatientError || sentToThirdPartyError ? (
              <p className="text-sm mt-4 text-red-500">Unable to send file at this time, please try again.</p>
            ) : null}
            {mergedDocuments.length !== 0 ? (
              <>
                <div className="block md:flex justify-between items-center flex-wrap w-full border-b mt-5">
                  <div className="w-full lg:w-1/2 p-3 pl-0 pr-0 lg:pr-3 pb-0 lg:pb-3 text-xs text-slate-500 font-medium uppercase flex items-center">
                    {readOnly ? null : (
                      <div className="flex justify-start items-center cursor-pointer">
                        <Checkbox
                          checked={mergedDocuments.length ? selectedFinalFilesForMerge.length === mergedDocuments.length : false}
                          onChange={e => {
                            handleSelectAllFinalDoc(e.currentTarget.checked);
                          }}
                          label=""
                        />
                        <label className="cursor-pointer text-base ml-2"></label>
                      </div>
                    )}
                    File name
                  </div>
                  <div className="flex justify-between items-center w-full lg:w-1/2">
                    <div className="inline-block w-full p-3 pl-0 lg:pl-3 text-xs font-medium uppercase">Date</div>
                    <div className="inline-block w-full p-3 text-xs font-medium uppercase">Size</div>
                    <div className="inline-block w-full p-3 text-xs font-medium uppercase">Sent to</div>
                    <div className="inline-block w-auto p-3 pr-0 lg:pr-3 text-xs font-medium uppercase relative">
                      <span className="inline-block w-4">&nbsp;</span>
                    </div>
                  </div>
                </div>
              </>
            ) : readOnly ? (
              "-"
            ) : (
              <div className="flex justify-between items-center w-full">
                <div className="w-full py-3">
                  <p className="text-base text-docsinga-blue-dark font-light">No final documents created</p>
                </div>
              </div>
            )}
            {mergedDocuments.map(doc => (
              <div className="block md:flex justify-between items-center flex-wrap w-full border-b" key={doc.requestFileId}>
                <div className="w-full lg:w-1/2 p-3 pl-0 pr-0 lg:pr-3 pb-0 lg:pb-3 flex items-center">
                  {readOnly ? null : (
                    <div className="flex justify-start items-center cursor-pointer">
                      <Checkbox
                        checked={selectedFinalFilesForMerge.map(v => v.requestFileId).includes(doc.requestFileId)}
                        onChange={e => handleFinalFilesCheckbox(e.currentTarget.checked, doc.requestFileId)}
                        label=""
                      />
                      <label className="cursor-pointer text-base ml-2"></label>
                    </div>
                  )}
                  <span
                    className="text-base break-all text-docsigna-blue-light cursor-pointer"
                    onClick={() => {
                      openFile(doc.fileId, doc.url);
                      openImageInModal();
                    }}>
                    {doc.originalFilename}
                  </span>
                </div>
                <div className="flex justify-between items-center w-full lg:w-1/2">
                  <div className="inline-block w-full p-2 pl-0 lg:pl-3 text-sm">{moment(doc.createdDate).format("ll")}</div>
                  <div className="inline-block w-full p-2">{getFileSize(doc.size)}</div>
                  <div className="inline-block w-full p-2">
                    {doc.sentPatient && doc.sentThirdParty
                      ? "Patient, Third Party"
                      : doc.sentPatient
                      ? "Patient"
                      : doc.sentThirdParty
                      ? "Third Party"
                      : "-"}
                  </div>
                  <div className="inline-block w-auto p-2 pr-0 lg:pr-3 relative">
                    <span className="text-base text-docsigna-blue-dark">
                      <Tooltip
                        open={doc.tooltipVisible}
                        onSelect={type => handleDocumentTooltip(type, doc.requestFileId, doc.url)}
                        options={getTooltipOptions(doc)} //{doc.sentThirdParty || doc.sentPatient ? documentTooltipOptionsWhenTrue : documentTooltipOptions}
                        imageComponent={<MoreHorizIcon htmlColor={`${colors.Orange}`} className="cursor-pointer" />}
                        handleOpenTooltip={() => {
                          handleTooltip(true, doc.requestFileId);
                        }}
                        handleCloseTooltip={() => {
                          handleTooltip(false);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex flex-col mt-3 w-full md:flex-row md:justify-between md:items-center ">
              {readOnly ? (
                <div />
              ) : (
                <>
                  {showFinalDocCheckBox ? (
                    <div className="mr-4 mb-4 md:mb-0">
                      {RequestType.PrescriptionRefill !== requestData?.requestType ? (
                        <Checkbox
                          checked={finalDocumentSend}
                          onChange={e => {
                            handleFinalDocumnetSend();
                          }}
                          label="Send all final documents to patient and 3rd party (if provided) upon payment"
                        />
                      ) : (
                        <label>Final documents will automatically be sent to the 3rd party upon payment by patient.</label>
                      )}
                    </div>
                  ) : (
                    <div />
                  )}
                </>
              )}
              {!readOnly &&
              mergedDocuments.length &&
              !requestCompletedOrCancelled &&
              requestData?.fulfillOnPayment !== true &&
              !(requestData?.requestType === RequestType.PrescriptionRefill && showFinalDocCheckBox) ? (
                <Button
                  width="fit"
                  AdditionalClassNames={`${
                    finalDocumentSend ||
                    (requestData?.state === RequestState.PendingVerification && requestData?.requestType !== RequestType.ThirdPartyOffice)
                      ? "opacity-30"
                      : ""
                  }`}
                  text={
                    !isThirdParty
                      ? `Send To ${RequestType.PrescriptionRefill !== requestData?.requestType ? "Patient" : "Pharmacy"}`
                      : "Send to 3rd Party"
                  }
                  varient={sentToPatientLoading ? "loading" : "Primary"}
                  hover={finalDocumentSend ? false : true}
                  onClickFunc={() => {
                    if (
                      (requestData?.state === RequestState.PendingVerification || requestData?.patient?.userState === UserState.Invited) &&
                      requestData?.requestType !== RequestType.ThirdPartyOffice
                    ) {
                      showUnverifiedToast();
                      return;
                    }
                    if (mergedDocuments.length > 1 && selectedFinalFilesForMerge.length === 0) {
                      setOpenPrivacyPolicy(true);
                    } else {
                      if (showFinalDocCheckBox) {
                        setOpenPaymentNotDone(true);
                      } else {
                        !isThirdParty && RequestType.PrescriptionRefill !== requestData?.requestType ? sendToPatient() : sendToThirdParty();
                        setSelectedFinalFilesForMerge([]);
                      }
                    }
                  }}
                  disabled={selectedFinalFilesForMerge?.length === 0}
                />
              ) : null}
            </div>
          </span>
        }
      </div>
      <UserSideBar title="Create Final Document" onClickOutside={() => null} visible={sideBarOpen}>
        <FinalDocuments
          files={selectedFilesForMerge}
          officeId={officeId || ""}
          requestId={requestId || ""}
          closeForm={allowCancelRequest => {
            if (allowCancelRequest) {
              apiService.stopApiRequest();
            }
            setSideBarOpen(false);
            setSelectedFilesForMerge([]);
          }}
        />
      </UserSideBar>
      <Modal open={modalopen} onClose={() => setModalopen(false)}>
        <div className="modal relative z-10">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl">
                  <div className="bg-white p-4 xl:p-6">
                    <span className="modal-close absolute top-2 right-2 w-7 h-7 bg-white rounded-full">
                      <img src={close} alt="" className="w-full h-full cursor-pointer" onClick={() => setModalopen(false)} />
                    </span>
                    <embed src={fileUrl} className="w-full" style={{ height: "80vh" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationDialog
        maxWidth="lg"
        successButtonText={openSendFinalDoc || openPaymentNotDone ? "Confirm" : ""}
        failureButtonText={openSendFinalDoc || openPaymentNotDone ? "Cancel" : "Close"}
        open={openPrivacyPolicy || openSendFinalDoc || openPaymentNotDone}
        description={
          openPrivacyPolicy
            ? `Please click checkbox of documents you wish to send to Patient and then click "Send to ${
                !isThirdParty ? "Patient" : "Third Party"
              }" again.`
            : openPaymentNotDone
            ? "Payment has not been completed for this request.  Do you still want to send this document?"
            : "All documents in this section will be immediately sent to the patient about completion of payment."
        }
        title=""
        handleSuccess={() => {
          handleConfirmationSuccess();
        }}
        handleFailure={handleClosePrivacyPolicy}
      />
    </>
  );
};

export default RequestOverViewFileUpload;
