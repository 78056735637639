import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiPage } from "../../models/api-page.model";
import { Fee } from "../../models/fee.model";
import { FeeService } from "../../services/fee.service";
import { setPreviousSelectedOfficeId } from "./OfficeSlice";
import { logger } from "../../utils/logger";

const log = logger.getLogger('FeeErrors');

interface ListFeesType {
  officeId?: string;
  searchTerm?: string;
  page: number;
  size: number;
  sortType: string;
  fullPageLoaderShown?: boolean;
}

interface InitialStateType {
  loading: boolean;
  error: boolean;
  success: boolean;
  feeList: ApiPage<Fee> | null;
  fullPageLoaderShown: boolean;
  sort: {
    start: number;
    end: number;
    pageNumber: number;
    rowsPerPage: number;
    sortedBy: string;
  };
}

const initialState: InitialStateType = {
  loading: false,
  error: false,
  success: false,
  feeList: null,
  fullPageLoaderShown: false,
  sort: {
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "name,asc",
  },
};

export const FeeSlice = createSlice({
  name: "fileApi",
  initialState,
  reducers: {
    resetStateFeeSlice: () => initialState,
    clearFeeList: state => {
      state.feeList = null;
      state.fullPageLoaderShown = false;
    },
    resetFeeTableSort: state => {
      state.sort = {
        start: 0,
        end: 10,
        pageNumber: 0,
        rowsPerPage: 10,
        sortedBy: "name,asc",
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        getAllSystemFees.pending,
        (state, action: PayloadAction<any, any, { arg: ListFeesType; requestId: string; requestStatus: "pending" }>) => {
          if(!state.feeList) state.loading = true;
          state.error = false;
          if (state.feeList === null && action.meta.arg.fullPageLoaderShown) {
            state.fullPageLoaderShown = true;
          }
        }
      )
      .addCase(getAllSystemFees.rejected, state => {
        state.error = true;
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(getAllSystemFees.fulfilled, (state, action: PayloadAction<ApiPage<Fee>>) => {
        state.error = false;
        state.loading = false;
        state.fullPageLoaderShown = false;
        state.feeList = action.payload;
      })
      .addCase(
        getOfficeFees.pending,
        (state, action: PayloadAction<any, any, { arg: ListFeesType; requestId: string; requestStatus: "pending" }>) => {
          if(!state.feeList) state.loading = true;
          state.error = false;
          if (state.feeList === null && action.meta.arg.fullPageLoaderShown) {
            state.fullPageLoaderShown = true;
          }
        }
      )
      .addCase(getOfficeFees.rejected, state => {
        state.error = true;
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(getOfficeFees.fulfilled, (state, action: PayloadAction<ApiPage<Fee> | null>) => {
        state.error = false;
        state.loading = false;
        state.fullPageLoaderShown = false;
        state.feeList = action.payload;
      })
      .addCase(searchSystemFees.pending, state => {
        state.loading = false;
        state.error = false;
      })
      .addCase(searchSystemFees.rejected, state => {
        state.error = true;
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(searchSystemFees.fulfilled, (state, action: PayloadAction<ApiPage<Fee>>) => {
        state.error = false;
        state.loading = false;
        state.fullPageLoaderShown = false;
        state.feeList = action.payload;
      })
      .addCase(searchOfficeFees.pending, state => {
        state.loading = false;
        state.error = false;
      })
      .addCase(searchOfficeFees.rejected, state => {
        state.error = true;
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(searchOfficeFees.fulfilled, (state, action: PayloadAction<ApiPage<Fee>>) => {
        state.error = false;
        state.loading = false;
        state.fullPageLoaderShown = false;
        state.feeList = action.payload;
      })
      .addCase(addSystemFee.pending, state => {
        state.error = false;
      })
      .addCase(addSystemFee.rejected, state => {
        state.error = true;
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(addSystemFee.fulfilled, (state, action: PayloadAction<Fee | null>) => {
        if (action.payload !== null) {
          state.error = false;
        }
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(updateSystemFee.pending, state => {
        state.error = false;
      })
      .addCase(updateSystemFee.rejected, state => {
        state.error = true;
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(updateSystemFee.fulfilled, (state, action: PayloadAction<Fee | null>) => {
        if (action.payload !== null) {
          state.error = false;
        }
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(addOfficeFee.pending, state => {
        state.error = false;
      })
      .addCase(addOfficeFee.rejected, state => {
        state.error = true;
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(addOfficeFee.fulfilled, (state, action: PayloadAction<Fee | null>) => {
        if (action.payload !== null) {
          state.error = false;
        }
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(updateOfficeFee.pending, state => {
        state.error = false;
      })
      .addCase(updateOfficeFee.rejected, state => {
        state.error = true;
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(updateOfficeFee.fulfilled, (state, action: PayloadAction<Fee | null>) => {
        if (action.payload !== null) {
          state.error = false;
        }
        state.loading = false;
        state.fullPageLoaderShown = false;
      })
      .addCase(setTableState.fulfilled, (state, action: PayloadAction<ListFeesType>) => {
        const { sortType, page, size } = action.payload;
        const obj = {
          pageNumber: page,
          rowsPerPage: size,
          sortedBy: sortType,
        };
        state.sort = { ...state.sort, ...obj };
      });
  },
});

export const setTableState = createAsyncThunk(
  "ServiceApi/sortData",
  async ({ page, sortType, size }: ListFeesType, { rejectWithValue }) => {
    try {
      return { sortType, page, size };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getAllSystemFees = createAsyncThunk("FeeSlice/getAllSystemFees", async ({ page, sortType, size }: ListFeesType, { rejectWithValue, dispatch }) => {
  try {
    const res = await FeeService.getAllSystemFees(page, size, sortType);
    dispatch(setTableState({ sortType, page, size }));
    return res;
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});

export const getOfficeFees = createAsyncThunk(
  "FeeSlice/getOfficeFees",
  async ({ officeId, page, sortType, size }: ListFeesType, { rejectWithValue, dispatch }) => {
    try {
      if (officeId) {
        dispatch(setPreviousSelectedOfficeId(officeId));
        const res = await FeeService.getOfficeFees(officeId, page, size, sortType);
        dispatch(setTableState({ sortType, page, size }));
        return res;
      } else {
        return null;
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchSystemFees = createAsyncThunk(
  "FeeSlice/searchSystemFees",
  async ({ page, sortType, size, searchTerm }: ListFeesType, { rejectWithValue, dispatch }) => {
    try {
      if (searchTerm) {
        const res = await FeeService.searchSystemFees(searchTerm, page, size, sortType);
        dispatch(setTableState({ sortType, page, size }));
        return res;
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchOfficeFees = createAsyncThunk(
  "FeeSlice/searchOfficeFees",
  async ({ page, sortType, size, searchTerm, officeId }: ListFeesType, { rejectWithValue, dispatch }) => {
    try {
      if (searchTerm && officeId) {
        const res = await FeeService.searchOfficeFees(officeId, searchTerm, page, size, sortType);
        dispatch(setTableState({ sortType, page, size }));
        return res;
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const addSystemFee = createAsyncThunk("FeeSlice/addSystemFee", async (fee: Fee, { rejectWithValue }) => {
  try {
    const res = await FeeService.addSystemFee(fee);
    return res;
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});
export const updateSystemFee = createAsyncThunk("FeeSlice/updateSystemFee", async (fee: Fee, { rejectWithValue }) => {
  try {
    const res = await FeeService.updateSystemFee(fee);
    return res;
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});

export const addOfficeFee = createAsyncThunk(
  "FeeSlice/addOfficeFee",
  async ({ fee, officeId }: { fee: Fee; officeId: string }, { rejectWithValue }) => {
    try {
      const res = await FeeService.addOfficeFee(officeId, fee);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const updateOfficeFee = createAsyncThunk(
  "FeeSlice/updateOfficeFee",
  async ({ fee, officeId }: { fee: Fee; officeId: string }, { rejectWithValue }) => {
    try {
      const res = await FeeService.updateOfficeFee(officeId, fee);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

// Action creators are generated for each case reducer function
export const { resetStateFeeSlice, resetFeeTableSort, clearFeeList } = FeeSlice.actions;

export default FeeSlice.reducer;
