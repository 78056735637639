import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addOfficeRequestTimelineEvent } from "../../../redux/slices/UserSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import {
  addOfficeRequestFiles,
  getOfficeRequest,
  getOfficeRequestInvoice,
  setOfficeRequestPaid,
  setOfficeRequestState,
  undoOfficePaymentStatus,
} from "../../../redux/slices/OfficeRequestSlice";
import { RequestPaymentMethod, RequestState } from "../../../models/request.model";
import { ServiceBilledTo } from "../../../models/service.model";
import { uploadFileNew } from "../../../redux/slices/FileSlice";
import { ToastVariants } from "../../../models";
import { DsFile, RequestFileTypes } from "../../../models/ds-file.model";
import moment from "moment";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const html2pdf = require("html2pdf.js");

const MarkAsPaid = ({
  onClickCancel,
  setIsLoading,
  isLoading,
  overridePrice = 0,
}: {
  onClickCancel: (priceOverride?: boolean) => void;
  setIsLoading: (val: boolean) => void;
  isLoading?: boolean;
  overridePrice?: number;
}) => {
  const { id, requestId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state?.user);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const toast = useContext(ToastContext);
  const [message, setMessage] = useState("");
  const [apiError, setApiError] = useState("");

  const billedTo = requestData?.serviceBilledToOverride ? requestData?.serviceBilledToOverride : requestData?.service?.serviceBilledTo;
  const showPaidAndSubmit = billedTo === ServiceBilledTo.ThirdParty;

  const handleChange = (value: string) => {
    setMessage(value);
  };

  const uploadNewFile = async (file: File) => {
    if (file) {
      const response = await dispatch(
        uploadFileNew({
          file: file,
          callback: undefined,
        })
      );
      const fileId = (response?.payload as DsFile)?.fileId;
      if (fileId && requestData) {
        await dispatch(
          addOfficeRequestFiles({
            officeId: requestData?.officeId,
            requestId: requestData.requestId,
            fileIds: [fileId],
            requestFileType: RequestFileTypes.Invoice,
          })
        );
      }
    }
  };

  const createInvoice = () => {
    try {
      const filename = "PriceOverrideInvoice" + requestData?.requestNumber + "-" + moment().format("DD-MMM-YYYY-h-mm-ss");
      const element1 = document.getElementById("price-override-invoice-container-pdf");
      element1?.classList.remove("hidden");

      const opt = {
        margin: [5, 0, 5, 0],
        filename: filename,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 4, dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: {
          unit: "mm",
          format: "a4",
          compress: true,
          orientation: "portrait",
        },
        pagebreak: { mode: "avoid-all" },
      };
      return html2pdf()
        .set(opt)
        .from(element1)
        .outputPdf("arraybuffer")
        .then(async (blob: Blob) => {
          element1?.classList.remove("hidden");
          element1?.classList.remove("min-w-full");

          const file = new File([blob], filename + ".pdf", {
            type: "application/pdf",
          });
          await uploadNewFile(file);
          return true;
        });
    } catch (_) {
      toast?.openToast("Failed to create invoice", 2000, ToastVariants.Error);
    }
    return false;
  };

  const downloadInvoiceAsPdf = async () => {
    try {
      const element1 = document.getElementById("invoice-patient-container-pdf");
      element1?.classList.remove("hidden");
      const fileName = `Request ${requestData?.requestNumber} Receipt`;
      const opt = {
        margin: [5, 0, 5, 0],
        filename: fileName,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 4, dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: {
          unit: "mm",
          format: "a4",
          compress: true,
          orientation: "portrait",
        },
        pagebreak: { mode: "avoid-all" },
      };
      const blob: Blob = await html2pdf().set(opt).from(element1).outputPdf("arraybuffer");
      element1?.classList.add("hidden");
      element1?.classList.remove("min-w-full");
      const file = new File([blob], fileName + ".pdf", {
        type: "application/pdf",
      });
      const response = await dispatch(
        uploadFileNew({
          file: file,
          callback: undefined,
        })
      );
      if (requestData) {
        await dispatch(
          addOfficeRequestFiles({
            officeId: requestData?.officeId,
            requestId: requestData.requestId,
            fileIds: [(response.payload as DsFile).fileId],
            requestFileType: RequestFileTypes.Receipt,
          })
        );
        // const requestObj: any = requestRes.payload;
        // if (requestObj?.requestFileIds?.length) {
        //   const requestFileId = requestObj.requestFileIds[requestObj.requestFileIds.length - 1];
        //   await dispatch(
        //     sendRequestFileToPatient({
        //       officeId: requestData?.officeId,
        //       requestId: requestData.requestId,
        //       requestFileId: requestFileId,
        //     })
        //   );
        // }
      }
      element1?.classList.add("hidden");
      toast?.openToast("Receipt created successfully!", 2000, ToastVariants.Success);
      return true;
    } catch (_) {
      return true;
    }
  };

  const handleSubmit = async () => {
    try {
      setApiError("");
      if (id && requestId) {
        setIsLoading(true);
        if (billedTo === ServiceBilledTo.Patient) {
          await downloadInvoiceAsPdf();
        }
        await dispatch(
          setOfficeRequestPaid({
            officeId: id,
            requestId,
            paymentMethod: RequestPaymentMethod.External,
            paymentDetails: message.trim(),
            overridePrice,
          })
        ).then(async action => {
          if (action.payload !== null) {
            await dispatch(
              addOfficeRequestTimelineEvent({
                event: `${currentUser?.firstName + " " + currentUser?.lastName} marked as paid`,
                officeId: id,
                requestId,
              })
            );
            if (billedTo === ServiceBilledTo.Patient && !requestData?.fulfillOnPayment) {
              await dispatch(setOfficeRequestState({ officeId: id, requestId, state: RequestState.InProgress }));
            }
            if (showPaidAndSubmit) {
              await dispatch(setOfficeRequestState({ officeId: id, requestId, state: RequestState.Complete }));
            }
            if (overridePrice) {
              await dispatch(getOfficeRequestInvoice({ officeId: id, requestId }));
              await createInvoice();
              await dispatch(undoOfficePaymentStatus({ officeId: id, requestId, status: true }));
            }
            await dispatch(getOfficeRequest({ officeId: id, requestId }));
            setIsLoading(false);
            toast?.openToast(`Marked As Paid!`);
            onClickCancel(overridePrice ? true : false);
          }
        });
      }
    } catch (err) {
      setApiError("Something went wrong please try again!");
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="px-8 py-2">
        <label className="text-sm text-docsigna-blue-dark font-medium block w-full mt-3 mb-2">
          {"Enter any details relevant to this payment below:"}
        </label>
        <TextInput
          value={message}
          disabled={loading}
          onChangeFunc={e => handleChange(e.currentTarget.value)}
          placeholder={"eg. cheque or transaction #"}
          isRequired={false}
          isTextArea={true}
          showCounter={true}
          counterMessage={"250"}
          extraInputClass="h-48"
          onBlur={e => handleChange(e.currentTarget.value.trim())}
          isDarkBg={true}
        />
        {apiError ? <span className="text-red-500">{apiError}</span> : null}
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onClickCancel()}
            disabled={isLoading}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button
              onClickFunc={() => {
                handleSubmit();
              }}
              varient={isLoading ? "loading" : "Primary"}
              disabled={isLoading}
              AdditionalClassNames="pointer px-5"
              text="Confirm"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarkAsPaid;
