import React from "react";
import nextArrow from "../../assets/images/next.svg";
import tick from "../../assets/images/tick.svg";

interface TaskCardType {
  title: string;
  onClick: () => void;
  completed: boolean;
}

const TaskCard = ({ completed, onClick, title }: TaskCardType) => {
  return (
    <div
      onClick={() => onClick()}
      className="flex cursor-pointer justify-between items-center w-full px-5 py-2 mb-3 border border-slate-200 rounded-lg"
    >
      <div className="flex items-center">
        <div className="flex justify-center items-center mr-3 w-10 h-10 bg-purple-100 border-4 border-purple-50 rounded-full">
          <img src={tick} className={`h-2.5 ${completed ? "opacity-20" : ""}`} alt="" />
        </div>
        <p className="text-md">{title}</p>
      </div>
      <div className="">
        <img src={nextArrow} className="h-4" alt="" />
      </div>
    </div>
  );
};

export default TaskCard;
