import { Link } from "react-router-dom";
import Logo from "../../assets/images/DocnoteLogo.svg";
import { Button } from "../../components";

const HomePageHeader = () => {
  return (
    <div className={`patients__header absolute flex w-full bg-transparent`}>
      <div className="container">
        <div className="pt-6 flex flex-wrap md:flex-nowrap gap-2 md:gap-0">
          <div className="w-1/2">
            <Link to={"/"}>
              <img src={Logo} alt="Logo" className="w-52" />
            </Link>
          </div>
          <div className="flex w-full md:w-1/2 justify-end gap-5 text-right items-center">
            <Link to={"/create-account"} className="text-docsinga-blue-dark font-medium cursor-pointer leading-none">
              Create an account
            </Link>
            <Link to={"/login"}>
              <Button text="Login" onClickFunc={() => null} width="fit" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageHeader;
