import { SelectOption } from "../../../components/Select/SelectNew";
import { generateTimeList } from "../../../utils";

export enum FormFieldType {
  TextInput = "textInput",
  Select = 'select',
  SelectNew = 'selectNew',
  Radio = 'radio',
  Places = "places",
  SectionHeader = "sectionHeader",
  Timings = "Timings",
  HST = 'hst',
  Number = 'number',
  WSIB = 'wsibNumber'
}

export interface formDataType {
  id: string;
  value: string | boolean;
  title: string;
  placeholder: string;
  required: boolean;
  errorMessage: string;
  valid: boolean;
  type: FormFieldType;
  options?: string[];
  newOptions?: SelectOption[];
  visible?: boolean;
  child?: formDataType;
}

export const hoursList = generateTimeList(0, 0, 23, 30);

const specialityData = [
  {
    label:'Dermatology',
    value:'dermatology'
  },
  {
    label:'OBGYN',
    value:'obgyn'
  },
  {
    label:'Pediatrics',
    value:'pediatrics'
  },
  {
    label:'Psychiatry',
    value:'psychiatry'
  },
  {
    label:'General Surgery',
    value:'generalSurgery'
  },
  {
    label:'Orthopedic Surgery',
    value:'orthopedicSurgery'
  },
  {
    label:'Plastic Surgery',
    value:'plasticSurgery'
  },
  {
    label:'Urology',
    value:'urology'
  },
  {
    label:'Gastroenterology',
    value:'gastroenterology'
  },
  {
    label:'Cardiology',
    value:'cardiology'
  },
  {
    label:'Rheumatology',
    value:'rheumatology'
  },
  {
    label:'Physiatry',
    value:'physiatry'
  },
  {
    label:'Other',
    value:'other'
  },
]

const emrOptions = [
  {
    label: 'Oscar',
    value: 'oscar',
  },  
  {
    label: 'QHR Accuro',
    value: 'qhrAccuro',
  },  
  {
    label: 'Telus Practice Solution',
    value: 'telusPracticeSolution',
  },  
  {
    label: 'Telus CHR',
    value: 'telusCHR',
  },  
  {
    label: 'Cerner',
    value: 'cerner',
  },  
  {
    label: 'Other',
    value: 'other',
  },  
]

export const formDataInitialState: formDataType[] = [
  {
    id: "officeInfo",
    value: "",
    title: "Office Information",
    placeholder: "",
    required: false,
    errorMessage: "",
    valid: true,
    type: FormFieldType.SectionHeader,
  },
  {
    id: "officeName",
    value: "",
    title: "Office Name",
    placeholder: "Enter Office name",
    required: true,
    errorMessage: "Please provide office name",
    valid: false,
    type: FormFieldType.TextInput,
  },
  {
    id: "officeAddress",
    value: "",
    title: "Office Address",
    placeholder: "Enter Office Address",
    required: true,
    errorMessage: "Please provide valid office address",
    valid: false,
    type: FormFieldType.Places,
  },
  {
    id: "street2",
    value: "",
    title: "Unit Number",
    placeholder: "Enter unit number",
    required: false,
    errorMessage: "Please provide valid unit number",
    valid: true,
    type: FormFieldType.TextInput,
  },
  {
    id: "officePhone",
    value: "",
    title: "Phone Number",
    placeholder: "Enter phone number",
    required: true,
    errorMessage: "Please provide phone number",
    valid: false,
    type: FormFieldType.TextInput,
  },
  {
    id: "officeFax",
    value: "",
    title: "Fax Number",
    placeholder: "Enter fax number",
    required: true,
    errorMessage: "Please provide fax number",
    valid: false,
    type: FormFieldType.TextInput,
  },
  {
    id: "contactInfo",
    value: "",
    title: "Contact Information",
    placeholder: "",
    required: false,
    errorMessage: "",
    valid: true,
    type: FormFieldType.SectionHeader,
  },
  {
    id: "primaryContact",
    value: "",
    title: "Primary Contact",
    placeholder: "Enter contact name",
    required: true,
    errorMessage: "Please provide primary contact name",
    valid: false,
    type: FormFieldType.TextInput,
  },
  {
    id: "primaryContactEmail",
    value: "",
    title: "Primary Contact Email",
    placeholder: "Enter email",
    required: true,
    errorMessage: "Please provide primary contact email",
    valid: false,
    type: FormFieldType.TextInput,
  },
  {
    id: "additionalOfficeInformation",
    value: "",
    title: "Additional Office Information",
    placeholder: "",
    required: false,
    errorMessage: "",
    valid: true,
    type: FormFieldType.SectionHeader,
  },
  {
    id: "yearsInPractice",
    value: "",
    title: "Years In Practice",
    placeholder: "Enter years",
    required: true,
    errorMessage: "Please provide years",
    valid: false,
    type: FormFieldType.TextInput,
  },
  {
    id: "emr",
    value: "",
    title: "What is your EMR solution",
    placeholder: "Select EMR Solution",
    required: true,
    errorMessage: "Please provide emr solution",
    valid: false,
    type: FormFieldType.SelectNew,
    newOptions: emrOptions
  },
  {
    id: "geoArea",
    value: "",
    title: "Geographic Area",
    placeholder: "Select geographic area",
    required: true,
    errorMessage: "Please provide geographic area",
    valid: false,
    type: FormFieldType.SelectNew,
    newOptions: [
      {
        label: 'Urban',
        value: 'urban'
      },  
      {
        label: 'Rural',
        value: 'rural'
      },  
    ]
  },
  {
    id: "staffSize",
    value: "",
    title: "What is the current size of your staff",
    placeholder: "Select size of staff",
    required: true,
    errorMessage: "Please provide staff size",
    valid: false,
    type: FormFieldType.SelectNew,
    newOptions: [
      {
        label: '1-5',
        value: '1'
      },  
      {
        label: '6-10',
        value: '6'
      },  
      {
        label: '10+',
        value: '10'
      },  
    ]
  },
  {
    id: "familyPhysician",
    value: "",
    title: "Are you a family physician?",
    placeholder: "",
    required: true,
    errorMessage: "Please provide family physician",
    valid: false,
    type: FormFieldType.Radio,
    options: [
      'Yes',
      'No',
    ]
  },
  {
    id: "specialty",
    value: "",
    title: "What is your speciality",
    placeholder: "Select your speciality",
    required: false,
    errorMessage: "Please provide speciality",
    valid: true,
    type: FormFieldType.SelectNew,
    newOptions: specialityData,
    visible: false
  },
  {
    id: "inPhysicianGroups",
    value: "",
    title: "Are you in any physician groups",
    placeholder: "",
    required: false,
    errorMessage: "Please provide physician group",
    valid: true,
    type: FormFieldType.Radio,
    options: [
      'Yes',
      'No',
    ],
    visible: false
  },
  {
    id: "physicianGroups",
    value: "",
    title: "Are you in any physician group option",
    placeholder: "",
    required: false,
    errorMessage: "Please provide physician group option",
    valid: true,
    type: FormFieldType.Radio,
    options: [
      'FHO',
      'FHT',
      'FHG',
      'FHN',
      'CHC',
    ],
    visible: false
  },
  {
    id: "patientRosterSize",
    value: "",
    title: "Approximate patient roster size",
    placeholder: "Select patient roster size",
    required: false,
    errorMessage: "Please patient roster size",
    valid: true,
    type: FormFieldType.SelectNew,
    newOptions: [
      {
        label: 'Less than 1000',
        value: '1000'
      },   
      {
        label: '1001 - 1500',
        value: '1001'
      },   
      {
        label: '1501 - 2000',
        value: '1501'
      },   
      {
        label: '2001 - 2500',
        value: '2001'
      },   
      {
        label: '2501+',
        value: '2501'
      },   
    ],
    visible: false
  },
  {
    id: "acceptingPatients",
    value: "",
    title: "Are you accepting new patients?",
    placeholder: "",
    required: false,
    errorMessage: "Please provide value",
    valid: true,
    type: FormFieldType.Radio,
    options: [
      'Yes',
      'No',
    ],
    visible: false
  }
];

export const officeWeekDayTimings = [
  {
    id: "officeHourMondayOpen",
    title: "Monday",
    valid: false,
    key1: 'monOpen',
    open: '',
    key2: 'monClose',
    close: '',
    key3: 'monClosed',
    closed: false,
  },
  {
    id: "officeHourTuesdayOpen",
    title: "Tuesday",
    valid: false,
    key1: 'tueOpen',
    open: '',
    key2: 'tueClose',
    close: '',
    key3: 'tueClosed',
    closed: false,
  },
  {
    id: "officeHourWednesdayOpen",
    title: "Wednesday",
    valid: false,
    key1: 'wedOpen',
    open: '',
    key2: 'wedClose',
    close: '',
    key3: 'wedClosed',
    closed: false,
  },
  {
    id: "officeHourThursdayOpen",
    title: "Thursday",
    valid: false,
    key1: 'thuOpen',
    open: '',
    key2: 'thuClose',
    close: '',
    key3: 'thuClosed',
    closed: false,
  },
  {
    id: "officeHourFridayOpen",
    title: "Friday",
    valid: false,
    key1: 'friOpen',
    open: '',
    key2: 'friClose',
    close: '',
    key3: 'friClosed',
    closed: false,
  },
  {
    id: "officeHourSaturdayOpen",
    title: "Saturday",
    valid: false,
    key1: 'satOpen',
    open: '',
    key2: 'satClose',
    close: '',
    key3: 'satClosed',
    closed: false,
  },
  {
    id: "officeHourSundayOpen",
    title: "Sunday",
    valid: false,
    key1: 'sunOpen',
    open: '',
    key2: 'sunClose',
    close: '',
    key3: 'sunClosed',
    closed: false,
  },
]
