import React, { useEffect, useState } from "react";
import Button from "../../Button/Button";
import { TextInput } from "../..";
import { addOfficeRequestFinalDocuments, requestFilesType } from "../../../redux/slices/OfficeRequestSlice";
import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle, DropResult } from "react-beautiful-dnd";
import { mergePDF } from "../../../utils";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { DsFile } from "../../../models/ds-file.model";
import { FileTypes } from "../../../models";
import { uploadFileForMerge } from "../../../redux/slices/FileSlice";
import { colors } from "../../../utils/constants";

interface FinalDocumentsType {
  files: requestFilesType[];
  requestId: string;
  officeId: string;
  closeForm: (val?: boolean) => void;
}
let clickedCloseForm = false;

const FinalDocuments = ({ files, officeId, requestId, closeForm }: FinalDocumentsType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [fileNameValidation, setfileNameValidation] = useState(true);
  const [fileName, setFileName] = useState("Final Document");
  const [mergingPending, setMergingPending] = useState(false);
  const [fileError, setFileError] = useState("");
  const [items, setItems] = useState<FinalDocumentsType["files"]>(files);
  const dispatch = useDispatch<AppDispatch>();
  const validateForm = () => {
    if (fileNameValidation) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    clickedCloseForm = false;
  }, []);

  useEffect(() => {
    if (fileName.length) handleChangeFileName(fileName);
  }, [fileName]);

  const saveFinalFiles = () => {
    mergeFiles();
  };

  const reorder = (list: FinalDocumentsType["files"], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const tempItems = reorder(items, result.source.index, result.destination.index);
    setItems(tempItems);
  };
  const grid = 8;
  const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle) =>
    ({
      ...draggableStyle,
    } as unknown as React.CSSProperties);
  const getListStyle = () => ({
    padding: grid,
    width: "auto",
  });

  const mergeFiles = async () => {
    setMergingPending(true);
    const data = await mergePDF(items.map(f => f.url || ""));

    if (data[0]?.errorType) {
      const errFileName = items.find(f => f.url === data[0]?.url)?.originalFilename;
      if (data[0]?.errorType === "encrypted") {
        setFileError(
          `The document ${errFileName} is secured and cannot be used to create the final document. Please try again using an unsecured version.`
        );
      } else {
        setFileError(`Something went wrong with ${errFileName}.!`);
      }

      setMergingPending(false);
    } else {
      const file = new File([data], `${fileName.replace(".pdf", "")}.pdf`, { type: FileTypes.PDF });
      if (!clickedCloseForm) {
        dispatch(
          uploadFileForMerge({
            file: file,
          })
        ).then(action => {
          setMergingPending(false);
          if (officeId && requestId && action?.payload && (action.payload as DsFile)?.fileId) {
            dispatch(addOfficeRequestFinalDocuments({ officeId: officeId, requestId: requestId, fileIds: [(action.payload as DsFile).fileId] }));
            closeForm();
          }
        });
      }
    }
  };

  const handleChangeFileName = (e: string) => {
    setFileName(e);
    if (e.trim().length > 0) {
      setfileNameValidation(false);
    } else {
      setfileNameValidation(true);
    }
  };

  return (
    <>
      <p className="text-base px-8 mt-2 py-3 font-medium">You can create multiple final docs. Name your file below.</p>

      <div className="px-8 ">
        <TextInput
          onChangeFunc={e => handleChangeFileName(e.currentTarget.value)}
          value={fileName}
          autoFocus
          label="Document Name"
          errorMsg={"Please enter file name"}
          isError={errorInitiated && fileNameValidation}
          isRequired
          placeholder="Add file name"
          onBlur={e => handleChangeFileName(e.currentTarget.value.trim())}
          isDarkBg={true}
        />
        <p className="py-4">Reorder the files by dragging the handles. Files will be merged in the order they appear below...</p>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
                {items.map((item, index: number) => (
                  <Draggable key={item.requestFileId} draggableId={item.requestFileId} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                        <div className="px-8 py-0.5 flex justify-between items-center relative" key={item.fileId}>
                          <span className="text-base text-docsigna-blue-dark">{item.originalFilename}</span>
                          <div className="absolute left-0 top-[5px]">
                            <span className="block w-5 h-0.5 bg-gray-500 mt-1"></span>
                            <span className="block w-5 h-0.5 bg-gray-500 mt-1"></span>
                            <span className="block w-5 h-0.5 bg-gray-500 mt-1"></span>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        {fileError ? (
          <p className="px-8 py-5" style={{ color: colors.Red }}>
            {fileError}
          </p>
        ) : null}
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => {
              clickedCloseForm = true;
              closeForm(true);
            }}
            disabled={mergingPending}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div onClick={() => setErrorInitiated(true)}>
            <Button
              onClickFunc={() => {
                if (validateForm()) saveFinalFiles();
              }}
              disabled={!validateForm() && errorInitiated}
              varient={mergingPending ? "loading" : "Primary"}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FinalDocuments;
