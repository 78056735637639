/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, PasswordErrorList, TextInput } from "../../components";
import { AuthContext } from "../../contexts/AuthContext/AuthContext";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { resetPassword } from "../../redux/slices/CurrentUserSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { LOCAL_STORAGE_RESET_PASSWORD_EMAIL } from "../../utils/constants";
import { validatePassword } from "../../utils";

const ResetPassword = () => {
  const passwordInitData = [
    {
      id: "password",
      label: "New Password",
      placeholder: "Enter New Password",
      required: true,
      errorMesage: "Please provide password",
      value: "",
      isValid: false,
    },
    {
      id: "confirmPassword",
      label: "Confirm Password",
      placeholder: "Confirm password",
      required: true,
      errorMesage: "Password and confirm password are not same",
      value: "",
      isValid: false,
    },
  ];

  const validationsInitData = [
    {
      id: "lowerCase",
      isValid: false,
      msg: "Password must contain a lower case letter",
    },
    {
      id: "upperCase",
      isValid: false,
      msg: "Password must contain an upper case letter",
    },
    {
      id: "specialCharacter",
      isValid: false,
      msg: "Password must contain a special character",
    },
    { id: "cotainNumber", isValid: false, msg: "Password must contain a number" },
    {
      id: "eightCharacters",
      isValid: false,
      msg: "Password must contain a atleast  8 characters",
    },
    { id: "passwordMatch", isValid: false, msg: "Password must match" },
  ];

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const toast = useContext(ToastContext);
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string | null>(null);
  const [passwordData, setPasswordData] = useState(passwordInitData);
  const [validations, setValidations] = useState(validationsInitData);
  const [touchedInput, setTouchedInput] = useState(false);
  const [code, setCode] = useState<null | string>(null);
  const [apiError, setApiError] = useState(false);
  const { loading } = useSelector((state: RootState) => state.currentUser);

  const keyUpHandler = (e: any) => {
    if (e.key === "Enter" && e.target.name === "confirmPassword") {
      if (validations.every(it => it.isValid)) {
        handleContinue();
      }
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.currentTarget.value;
    if (e.currentTarget.name === "confirmPassword") {
      const isValid = passwordData[0].value === value;

      const validationErrors = [...validations];
      validationErrors[5] = { ...validationErrors[5], isValid };
      setValidations(validationErrors);
    } else {
      const newValidations = validations.map(item => {
        const isValid = validatePassword(item.id, value);

        return { ...item, isValid };
      });

      setValidations(newValidations);
    }

    setPasswordData([
      ...passwordData.slice(0, index),
      {
        ...passwordData[index],
        value,
      },
      ...passwordData.slice(index + 1),
    ]);
  };
  const handleSignIn = async () => {
    const signIn = await auth?.signin({ email: email || "", password: passwordData[0].value });
    if (signIn) {
      navigate("/");
      toast?.openToast("Your password has been updated.");
    }
  };

  const handleContinue = () => {
    setApiError(false);
    setTouchedInput(false);
    if (code && email) {
      dispatch(resetPassword({ code, email, password: passwordData[0].value })).then(action => {
        if (action.payload) {
          localStorage.removeItem(LOCAL_STORAGE_RESET_PASSWORD_EMAIL);
          handleSignIn();
        } else {
          setApiError(true);
        }
      });
    }
  };

  useEffect(() => {
    if (passwordData[0].value.length > 0 || passwordData[1].value.length > 0) {
      setTouchedInput(true);
    } else {
      setTouchedInput(false);
    }
  }, [passwordData]);

  useEffect(() => {
    setEmail(localStorage.getItem(LOCAL_STORAGE_RESET_PASSWORD_EMAIL));
    setCode(searchParams.get("code"));
    if (auth?.isAuthenticated) auth.signout();
  }, []);

  return (
    <main>
      <div className="flex justify-between w-full">
        <div className="flex justify-center w-full h-screen items-center">
          <div className="w-80">
            <h3 className="heading text-center">Reset Password</h3>
            {apiError ? (
              <p className="text-red-700" style={{ color: "red" }}>
                Unable to reset password at this time, please try again.
              </p>
            ) : null}
            <div className="py-5">
              {passwordData.map((item, index) => {
                return (
                  <div className="py-2" key={item.id}>
                    <TextInput
                      isRequired={item.required}
                      label={item.label}
                      value={item.value}
                      placeholder={item.placeholder}
                      name={item.id}
                      isPassword
                      onFocus={() => {}}
                      onChangeFunc={e => handlePasswordChange(e, index)}
                      onKeyUp={e => keyUpHandler(e)}
                    />
                  </div>
                );
              })}
              <div>
                {touchedInput
                  ? validations.map(item => {
                      return (
                        <div key={item.id} className="my-1 ml-2">
                          <PasswordErrorList isValid={item.isValid} message={item.msg} />
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="py-2 mt-5">
                <Button
                  varient={loading ? "loading" : "Primary"}
                  onClickFunc={() => (!loading ? handleContinue() : null)}
                  text={"Continue"}
                  disabled={!validations.every(it => it.isValid)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
