import { useEffect, useState } from "react";
import TextInput from "../TextInput/TextInput";
import { useDebouncedCallback } from "use-debounce";

const SearchComponent = ({
  placeholder,
  value,
  extraInputClass,
  handleFinalSearch,
}: {
  placeholder?: string;
  value: string;
  extraInputClass?: string;
  handleFinalSearch: (val: string) => void;
}) => {
  const [text, setText] = useState(value);

  const debounced = useDebouncedCallback(val => {
    handleFinalSearch(val);
  }, 500);

  const handleSearch = (val: string) => {
    setText(val);
    if (val.trim().length > 2) debounced(val);
    if (text.trim().length > 2 && val.trim().length <= 2) {
      debounced("");
    }
  };

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <TextInput
      placeholder={placeholder ?? ""}
      extraInputClass={extraInputClass ?? ""}
      isSearchInput={true}
      onChangeFunc={e => {
        handleSearch(e.currentTarget.value);
      }}
      value={text}
    />
  );
};

export default SearchComponent;
