import React, { useState, useContext, useEffect } from "react";
import { formatPhoneNumber, validateEmail, validatePostalCode } from "../../../utils";
import { ContactType, Phone_Fax_Regex, ProvinceList } from "../../../utils/constants";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { ContactService } from "../../../services/contact.service";
import { ToastVariants } from "../../../models";
import { Contact } from "../../../models/contact-model";
import { capitalize } from "lodash";

enum ContactPreference {
  Email = "email",
  Fax = "fax",
}
interface formDataType {
  id: string;
  value: string;
  title: string;
  placeholder: string;
  required: boolean;
  errorMessage: string;
  valid: boolean;
  options?: { text: string; value: string }[];
  type: "textInput" | "select";
}
const formDataInitialState: formDataType[] = [
  {
    id: "company",
    value: "",
    title: "Company",
    placeholder: "Enter company name",
    required: true,
    errorMessage: "Please provide company name",
    valid: false,
    type: "textInput",
  },
  {
    id: "contactType",
    value: "",
    title: "Contact Type",
    placeholder: "Select contact type",
    required: true,
    errorMessage: "Please provide contact type",
    valid: false,
    options: [
      {
        text: ContactType.InsuranceCompany,
        value: ContactType.InsuranceCompany,
      },
      {
        text: ContactType.Lawyer,
        value: ContactType.Lawyer,
      },
      {
        text: ContactType.Pharmacy,
        value: ContactType.Pharmacy,
      },
      {
        text: ContactType.Other,
        value: ContactType.Other,
      },
    ],
    type: "select",
  },
  {
    id: "firstName",
    value: "",
    title: "First Name",
    placeholder: "Enter first name",
    required: false,
    errorMessage: "Please provide name",
    valid: true,
    type: "textInput",
  },
  {
    id: "lastName",
    value: "",
    title: "Last Name",
    placeholder: "Enter last name",
    required: false,
    errorMessage: "Please provide name",
    valid: true,
    type: "textInput",
  },
  {
    id: "street1",
    value: "",
    title: "Address 1",
    placeholder: "Enter address",
    required: false,
    errorMessage: "Please provide address",
    valid: true,
    type: "textInput",
  },
  {
    id: "street2",
    value: "",
    title: "Address 2",
    placeholder: "Enter address",
    required: false,
    errorMessage: "Please provide address",
    valid: true,
    type: "textInput",
  },
  {
    id: "city",
    value: "",
    title: "City",
    placeholder: "Enter city",
    required: false,
    errorMessage: "Please provide address",
    valid: true,
    type: "textInput",
  },
  {
    id: "province",
    value: "",
    title: "Province",
    placeholder: "Select province",
    required: false,
    errorMessage: "Please provide province",
    valid: true,
    type: "select",
    options: ProvinceList,
  },
  {
    id: "postalCode",
    value: "",
    title: "Postal Code",
    placeholder: "Enter postal code",
    required: false,
    errorMessage: "Please provide postal code",
    valid: true,
    type: "textInput",
  },
  {
    id: "phoneNumber",
    value: "",
    title: "Phone Number",
    placeholder: "Enter phone number",
    required: false,
    errorMessage: "Please provide phone number",
    valid: true,
    type: "textInput",
  },
  {
    id: "email",
    value: "",
    title: "Email",
    placeholder: "Enter email address",
    required: false,
    errorMessage: "Please provide valid email address",
    valid: true,
    type: "textInput",
  },
  {
    id: "fax",
    value: "",
    title: "Fax",
    placeholder: "Enter fax number",
    required: false,
    errorMessage: "Please enter valid fax number",
    valid: true,
    type: "textInput",
  },
  {
    id: "contactPreference",
    value: "",
    title: "Select Preferred Contact Method:",
    placeholder: "Select Contact Method",
    required: true,
    errorMessage: "Please select contact method",
    valid: false,
    options: [
      {
        text: capitalize(ContactPreference.Email),
        value: ContactPreference.Email,
      },
      {
        text: capitalize(ContactPreference.Fax),
        value: ContactPreference.Fax,
      },
    ],
    type: "select",
  },
];

const AddContact = ({
  onClickCancel,
  isEditable = false,
  selectedContact,
  officeId,
}: {
  onClickCancel: (val?: boolean) => void;
  isEditable?: boolean;
  selectedContact: Contact | undefined;
  officeId: string | undefined;
}) => {
  const [formData, setFormData] = useState(formDataInitialState);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [isContactMethodAdded, setIsContactMethodAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useContext(ToastContext);
  const preferredContactMethod = formData[formData.length - 1].value;

  const validateFieldAndValue = (val: string, id: string, required: boolean) => {
    let value = val;
    let valid = false;
    switch (id) {
      case "phoneNumber":
      case "fax":
        value = formatPhoneNumber(value);
        valid = value ? Phone_Fax_Regex.test(formatPhoneNumber(value as string)) : true;
        break;
      case "email":
        valid = value ? validateEmail(value) : true;
        break;
      case "postalCode":
        valid = value ? validatePostalCode(value) : true;
        break;
      default:
        if (!value && !required) {
          valid = true;
        } else {
          valid = value?.trim().length > 0 ? true : false;
        }
        break;
    }
    return { value, valid };
  };

  const handleChange = (index: number, id: string, value: string) => {
    const item = formData.find(item => item.id === id);
    if (item) {
      const { value: newVal, valid } = validateFieldAndValue(value, id, item.required);
      setFormData([...formData.slice(0, index), { ...item, value: newVal, valid }, ...formData.slice(index + 1)]);
    }
  };

  const validateForm = () => {
    return formData.every(val => val.valid);
  };

  const handleClickSave = async () => {
    const contactData: any = { officeId: "" };
    formData.forEach(item => {
      const trimmedItem = item.value.trim();
      contactData[item.id] = trimmedItem.length ? trimmedItem : undefined;
    });
    let showContactMethodError = false;
    if (
      (preferredContactMethod === ContactPreference.Email && !contactData?.email) ||
      (preferredContactMethod === ContactPreference.Fax && !contactData?.fax)
    ) {
      showContactMethodError = true;
    }
    if (!validateForm() || !(contactData?.email || contactData?.fax) || showContactMethodError) {
      setErrorInitiated(true);
      setIsContactMethodAdded(!(contactData?.email || contactData?.fax) || showContactMethodError);
      return;
    }
    setIsContactMethodAdded(false);
    setErrorInitiated(false);
    setLoading(true);
    let resp = null;
    if (officeId) {
      contactData.officeId = officeId;
      if (selectedContact) {
        resp = await ContactService.updateContact(contactData, officeId, selectedContact.contactId);
      } else {
        resp = await ContactService.addContact(contactData, officeId);
      }
      if (resp) {
        toast?.openToast(`Contact ${selectedContact ? "updated" : "added"} successfully!`, 2000, ToastVariants.Success);
        onClickCancel(true);
      } else {
        toast?.openToast("Something went wrong!", 2000, ToastVariants.Error);
      }
    } else {
      onClickCancel(false);
    }
    setLoading(false);
  };

  const renderInput = (inp: formDataType, index: number) => {
    switch (inp.type) {
      case "textInput":
        return (
          <TextInput
            key={inp.id}
            value={inp.value}
            onChangeFunc={e => handleChange(index, inp.id, e.currentTarget.value)}
            placeholder={inp.placeholder}
            errorMsg={inp.errorMessage}
            isError={!inp.valid && errorInitiated}
            label={inp.title}
            isRequired={inp.required}
            readonly={!isEditable}
            //onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
            isDarkBg={true}
          />
        );
      case "select":
        return (
          <>
            <label>
              {inp.title}
              {inp.required ? <span className="text-red-500">*</span> : null}
            </label>
            <select
              className="general-select w-full bg-transparent"
              value={inp.value}
              onChange={e => handleChange(index, inp.id, e.currentTarget.value)}>
              <option>{inp.placeholder}</option>
              {inp?.options?.map(item => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                );
              })}
            </select>
            {!inp.valid && errorInitiated ? <p className="text-red-500">{inp.errorMessage}</p> : <p></p>}
          </>
        );
      default:
        return null;
    }
  };

  const populateContactFormData = (contact?: Contact): formDataType[] => {
    let newFormData = formDataInitialState;
    if (!contact) return newFormData;
    newFormData = newFormData.map(item => {
      const newItem = { ...item };
      const val = contact[item.id as keyof Contact]?.toString() ?? "";
      const { value, valid } = validateFieldAndValue(val, item.id, item.required);
      newItem.value = value;
      newItem.valid = valid;
      return newItem;
    });
    console;
    return newFormData;
  };

  useEffect(() => {
    if (selectedContact && isEditable) {
      const newData = populateContactFormData(selectedContact);
      setFormData(newData);
    }
  }, [selectedContact, isEditable]);

  return (
    <>
      <p className="text-sm px-8">Please provide the below contact details.</p>
      {formData.map((input, index) => (
        <div key={input.id} className="px-8 py-2">
          {input.id === "email" ? (
            <div className="flex flex-col gap-1 mb-3">
              <p>
                Provide at least one method of contact below:<span className="text-red-400">*</span>
              </p>
              {isContactMethodAdded ? (
                <p className="text-red-500">
                  {preferredContactMethod ? "Please provide preferred contact method" : "Please provide contact method either email or fax"}
                </p>
              ) : null}
            </div>
          ) : null}
          {renderInput(input, index)}
        </div>
      ))}

      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
            disabled={loading}
          />
          <div>
            <Button
              onClickFunc={() => {
                handleClickSave();
              }}
              disabled={!validateForm() && errorInitiated && loading}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
              varient={loading ? "loading" : "Primary"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContact;
