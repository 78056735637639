import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Comment, TextInput } from "../../../components";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { getOffice } from "../../../redux/slices/OfficeSlice";
import { addOfficeRequestMessage, getOfficeRequestPatientMessages, setOfficeRequestMessageRead } from "../../../redux/slices/RequestSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { UserType } from "../../../services/user.service";

const OfficeCommunication = ({ isCompleted, scrollHeight = false }: { isCompleted: boolean; scrollHeight: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const { patientRequestData } = useSelector((state: RootState) => state.user);
  const { patientMessage, loading } = useSelector((state: RootState) => state.request);
  const { currentOffice } = useSelector((state: RootState) => state.office);
  const { patientRequestId } = useParams();
  const [communicationMsg, setCommunicationMsg] = useState("");
  const sendPatientCommentMessage = () => {
    if (communicationMsg.trim() !== "" && currentOffice?.officeId && patientRequestId && currentUser) {
      // TODO : Use the API call made for adding messages by patient.
      dispatch(
        // ↓ Replace this function.
        addOfficeRequestMessage({
          officeId: currentOffice?.officeId,
          requestId: patientRequestId,
          message: communicationMsg,
          internal: false,
          senderId: currentUser?.userId,
        })
      ).then(() => {
        setCommunicationMsg("");
        patientRequestData?.officeId && patientRequestData?.requestId
          ? dispatch(getOfficeRequestPatientMessages({ officeId: patientRequestData?.officeId, requestId: patientRequestData?.requestId }))
          : null;
      });
    }
  };

  useEffect(() => {
    const handleScroll = async () => {
      if (scrollHeight) {
        const patientUnReadMessage = patientMessage?.filter(f => f.unread === true);

        const setReadPromises: any = patientUnReadMessage?.forEach(async e => {
          if (patientRequestData?.officeId && patientRequestData?.requestId)
            await dispatch(
              setOfficeRequestMessageRead({
                officeId: patientRequestData?.officeId,
                requestId: patientRequestData?.requestId,
                messageId: e.requestMessageId,
                read: true,
              })
            );
        });
        if (setReadPromises) await Promise.all(setReadPromises);
        if (patientRequestData?.officeId && patientRequestData?.requestId) {
          await dispatch(getOffice({ officeId: patientRequestData?.officeId }));
          await dispatch(getOfficeRequestPatientMessages({ officeId: patientRequestData?.officeId, requestId: patientRequestData?.requestId }));
        }
      }
    };
    handleScroll();
  }, [scrollHeight]);

  return (
    <>
      {isCompleted ? (
        <>
          {patientMessage && patientMessage?.length > 0
            ? patientMessage?.map(cmt => (
                <Comment
                  onMentionClick={() => {}}
                  time={cmt.createdDate}
                  from={
                    currentUser?.userId !== cmt.sender?.userId
                      ? currentOffice?.name || ""
                      : `${cmt?.senderName ? cmt?.senderName : cmt?.sender?.firstName + " " + cmt?.sender?.lastName}`
                  }
                  key={cmt.requestMessageId}
                  text={cmt.message}
                  disableMentions
                  comment={cmt}
                />
              ))
            : "-"}
        </>
      ) : (
        patientMessage?.map(cmt => (
          <Comment
            onMentionClick={() => {}}
            time={cmt.createdDate}
            from={
              cmt?.sender?.userType === UserType.SystemAdmin
                ? currentOffice?.name || ""
                : `${cmt?.sender ? cmt?.sender?.firstName + " " + cmt?.sender?.lastName : cmt?.senderName}`
            }
            key={cmt.requestMessageId}
            text={cmt.message}
            disableMentions
            comment={cmt}
          />
        ))
      )}

      {isCompleted ? null : (
        <div className={`tabs inline-block pt-3 mt-2 w-full  ${patientMessage?.length && "border-t border-slate-200"}`}>
          <div className="tab-nav flex mb-1">
            <span onClick={() => toast?.openToast()} className="text-md text-center font-medium p-2 -mb-px mr-8 cursor-pointer">
              Message Doctor&apos;s Office
            </span>
          </div>
          <div className="tab-content text-right">
            <TextInput
              isTextArea
              value={communicationMsg}
              onChangeFunc={e => setCommunicationMsg(e.currentTarget.value)}
              placeholder="Add a message to the doctor’s office"
              extraInputClass="mb-4 h-20 resize-none"
              onBlur={e => setCommunicationMsg(e.currentTarget.value.trim())}
            />
            <Button text="Send" disabled={loading} width="fit" onClickFunc={() => sendPatientCommentMessage()} AdditionalClassNames="px-8 py-1 " />
          </div>
        </div>
      )}
    </>
  );
};

export default OfficeCommunication;
