import { useContext, useEffect, useState } from "react";
import { Button, ConfirmationDialog, PasswordErrorList, RenderName, TextInput } from "../../components";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { formatPhoneNumber, passwordValidationList, validateEmail, validatePassword } from "../../utils";
import { Phone_Fax_Regex } from "../../utils/constants";
import { updatePatientUserProfile } from "../../redux/slices/CurrentUserSlice";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../../models";
import { authService } from "../../services/auth.service";
import { UserUpdate } from "../../models/user-update.model";

const PatientAccountSettings = () => {
  const toast = useContext(ToastContext);
  const [editAccountSetting, setEditAccountSetting] = useState(false);
  const [editChangePassword, setEditChangePassword] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmAlert, setShowConfirmAlert] = useState(false);
  const [pwdValidations, setPwdValidations] = useState(passwordValidationList);

  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.currentUser.currentUser);

  const validEmail = validateEmail(email);
  const validPhone = Phone_Fax_Regex.test(phone);
  const validPasswordState = !!currentPassword?.trim() && !!password?.trim() && !!confirmPassword?.trim() && pwdValidations.every(val => val.isValid);

  const validateForm = () => {
    return validEmail && validPhone;
  };

  const handleChange = (val: string, id: string) => {
    let value = val;
    switch (id) {
      case "phone":
        value = formatPhoneNumber(value as string);
        setPhone(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handlePasswordChange = (e: string, id: string) => {
    let value = "";
    value = e;
    switch (id) {
      case "currentPassword":
        setCurrentPassword(value);
        break;
      case "confirmPassword":
        const isValid = password === value;
        const validationErrors = [...pwdValidations];
        validationErrors[5] = { ...validationErrors[5], isValid };
        setPwdValidations(validationErrors);
        setConfirmPassword(value);
        break;
      case "password":
        const newValidations = pwdValidations.map(item => {
          const isValid = validatePassword(item.id, value);
          return { ...item, isValid };
        });
        setPwdValidations(newValidations);
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const renderConfirmMessage = () => {
    return (
      <div className="flex flex-col gap-4">
        <h2 className="font-bold">Your email is being updated to {email}.</h2>
        <p>Once confirmed, you will use your updated email to login to Docnote. Please confirm if you wish to proceed.</p>
      </div>
    );
  };

  const handleUpdate = async () => {
    if (user?.userId && user?.offices?.length && user?.offices[0]?.officeId) {
      const updatedUser: UserUpdate = {
        userId: user?.userId,
        officeId: user?.offices[0].officeId,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone,
        dateOfBirth: user?.dateOfBirth,
        ohipNumber: user?.ohipNumber,
      };
      if (user?.email !== email) {
        updatedUser.email = email;
      }
      if (user?.phone !== phone) {
        updatedUser.phone = phone;
      }
      setLoading(true);
      const resp = await dispatch(updatePatientUserProfile({ userId: user?.userId, body: updatedUser, officeId: user?.offices[0].officeId }));
      if (!resp.payload) {
        toast?.openToast("Unable to update account settings", 2000, ToastVariants.Error);
      }
      toast?.openToast("Account settings updated successfully!", 2000, ToastVariants.Success);
    }
    setLoading(false);
    setEditAccountSetting(false);
  };

  const handleSave = () => {
    setErrorState(false);
    if (!validateForm()) {
      setErrorState(true);
      return;
    }
    if (email === user?.email && phone === user?.phone) {
      setLoading(false);
      setEditAccountSetting(false);
      return;
    }
    if (email !== user?.email) {
      setShowConfirmAlert(true);
      return;
    }
    handleUpdate();
  };

  const cancelChangePassword = () => {
    setEditChangePassword(false);
    setLoading(false);
    setPassword("");
    setCurrentPassword("");
    setConfirmPassword("");
  };

  const handleChangePassword = async () => {
    setLoading(true);
    if (user?.email) {
      const res = await authService.changePassword(currentPassword, password);
      if (!res) {
        toast?.openToast("The current password you entered is incorrect. Please try again.", 2000, ToastVariants.Error);
        setLoading(false);
        return;
      }
    }
    cancelChangePassword();
  };

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setPhone(user?.phone ?? "");
    }
  }, [user]);

  return (
    <main>
      <div className="container mx-auto p-8 relative min-h-screen">
        <div className="bg-slate-200 p-5 rounded-3xl">
          <div className="flex flex-wrap justify-between items-center mb-5">
            <h2 className="heading uppercase font-semibold">Account settings</h2>
            {!editAccountSetting ? <Button text="Edit" onClickFunc={() => setEditAccountSetting(true)} AdditionalClassNames="!w-28" /> : null}
          </div>
          {!editAccountSetting ? (
            <div className="flex flex-col gap-4">
              <RenderName title="Email" subTitle={`${user?.email}`} type="input-list" />
              <RenderName title="Mobile Number" subTitle={user?.phone ?? "-"} type="input-list" />
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex gap-5">
                <div className="md:w-1/2 w-full">
                  <TextInput
                    label={"Email Address"}
                    placeholder={"Enter email address"}
                    isRequired={true}
                    value={email}
                    onChangeFunc={e => handleChange(e.target.value, "email")}
                    isError={errorState && !validEmail}
                    errorMsg={"Please enter a valid email address"}
                  />
                </div>
                <div className="md:w-1/2 w-full" />
              </div>
              <div className="flex gap-5">
                <div className="md:w-1/2 w-full">
                  <TextInput
                    label={"Mobile Number"}
                    placeholder={"Enter mobile number"}
                    isRequired={true}
                    value={phone}
                    onChangeFunc={e => handleChange(e.target.value, "phone")}
                    isError={errorState && !validPhone}
                    errorMsg={"Please enter a valid mobile number"}
                  />
                </div>
                <div className="md:w-1/2 w-full" />
              </div>
              <div className="flex">
                <Button
                  text="Save"
                  onClickFunc={handleSave}
                  AdditionalClassNames="!w-28"
                  disabled={(!validateForm() && errorState) || loading}
                  varient={loading ? "loading" : "Primary"}
                />
              </div>
            </div>
          )}
        </div>
        <div className="bg-slate-200 p-5 rounded-3xl mt-10">
          <div className="flex flex-wrap justify-between items-center mb-5">
            <h2 className="heading uppercase font-semibold">Update Password</h2>
            {!editChangePassword ? (
              <Button text="Change Password" onClickFunc={() => setEditChangePassword(true)} AdditionalClassNames="!w-48" />
            ) : null}
          </div>
          {!editChangePassword ? (
            <div className="flex flex-col gap-4">
              <RenderName title="Password" subTitle={`****************`} type="input-list" />
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex gap-5">
                <div className="md:w-1/2 w-full">
                  <TextInput
                    label={"Current password"}
                    placeholder={"Enter current password"}
                    value={currentPassword}
                    isPassword
                    onChangeFunc={e => handlePasswordChange(e.target.value, "currentPassword")}
                  />
                </div>
                <div className="md:w-1/2 w-full" />
              </div>
              <div className="flex gap-5">
                <div className="md:w-1/2 w-full">
                  <TextInput
                    label={"New password"}
                    placeholder={"Enter new password"}
                    isPassword
                    value={password}
                    onChangeFunc={e => handlePasswordChange(e.target.value, "password")}
                  />
                </div>
                <div className="md:w-1/2 w-full">
                  <TextInput
                    label={"Confirm password"}
                    placeholder={"Enter confirm password"}
                    isPassword
                    value={confirmPassword}
                    onChangeFunc={e => handlePasswordChange(e.target.value, "confirmPassword")}
                  />
                </div>
              </div>
              {password?.trim().length ? (
                <div className="flex gap-5">
                  <div className="md:w-1/2 w-full">
                    {pwdValidations.map(item => {
                      return (
                        <div key={item.id} className="my-1 ml-1">
                          <PasswordErrorList isValid={item.isValid} message={item.msg} />
                        </div>
                      );
                    })}
                  </div>
                  <div className="md:w-1/2 w-full"></div>
                </div>
              ) : null}
              <div className="flex gap-5 w-full">
                <Button varient="Secondary" text="Cancel" onClickFunc={cancelChangePassword} disabled={loading} />
                <Button
                  text="Change password"
                  onClickFunc={handleChangePassword}
                  disabled={!validPasswordState || loading}
                  varient={loading ? "loading" : "Primary"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ConfirmationDialog
        open={confirmAlert}
        description={renderConfirmMessage()}
        handleFailure={() => setShowConfirmAlert(false)}
        handleSuccess={() => {
          setShowConfirmAlert(false);
          handleUpdate();
        }}
        successButtonText="Confirm"
        failureButtonText="Cancel"
        //hideActionButtons={false}
      />
    </main>
  );
};

export default PatientAccountSettings;
