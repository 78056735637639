import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Button from "../Button/Button";
import Checkbox from "../Checkbox/Checkbox";

const ViewFee = ({ onCancelClick, feeId }: { onCancelClick: () => void; feeId: string }) => {
  const fees = useSelector((state: RootState) => state.fees.feeList?.content);
  const feeToBeShown = fees?.find(f => f.feeId === feeId);
  const [enableFeeCheckBox] = useState<boolean>(feeToBeShown?.enabled ? feeToBeShown?.enabled : false);

  // onCancelClick;
  return (
    <div className="py-2">
      <div className="px-8 py-2">
        <p className="font-semibold text-md">Fee Details</p>
      </div>
      <div className="px-8 py-2">
        <p className="font-semibold text-xs">Fee Name</p>
        {feeToBeShown?.name}
      </div>
      <div className="px-8 py-2">
        <p className="font-semibold text-xs">{"Fee Price (per unit)"}</p>
        <p>${(feeToBeShown?.price || 0) / 100}</p>
      </div>
      <div className="px-8 py-2">
        <p className="font-semibold text-xs">HST Applicable</p>
        <p>{feeToBeShown?.addTax ? "Yes" : "No"}</p>
      </div>
      <div className="px-8 py-2">
        {feeToBeShown?.description ? <p className="font-semibold text-xs">Fee Description</p> : null}
        <p>{feeToBeShown?.description}</p>
      </div>
      <div className="px-8 py-2">
        <Checkbox checked={enableFeeCheckBox} label="Enable fee" isDarkBg={true} />
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => {
              onCancelClick();
            }}
            AdditionalClassNames="pointer px-5"
            text="Close"
            width="fit"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewFee;
