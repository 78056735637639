import React from "react";
import Button from "../Button/Button";
import { Office } from "../../models/office.model";

const ViewOffice = ({ onCancelClick, office }: { onCancelClick: () => void; office: Office }) => {
  // onCancelClick;
  return (
    <div className="py-2">
      <div className="px-8 py-2">
        <p className="font-medium text-sm">Office Name</p>
        {office?.name}
      </div>
      <div className="px-8 py-2">
        <p className="font-medium text-sm">Office Address</p>
        {office?.street}, {office?.city}, {office?.state}, {office?.country}, {office?.zip}
      </div>
      <div className="px-8 py-2">
        <p className="font-medium text-sm">Primary Contact Name</p>
        <a
          className="text-docsigna-blue-light hover:underline-offset-2 hover:underline"
          href={`mailto: ${office.contactEmail}`}
          onClick={e => e.stopPropagation()}>
          {office.contactName}
        </a>
      </div>
      <div className="px-8 py-2">
        <p className="font-medium text-sm">Office Phone</p>
        {office.phone ?? "-"}
      </div>
      <div className="px-8 py-2">
        <p className="font-medium text-sm">Office Fax</p>
        {office.fax ?? "-"}
      </div>
      <div className="px-8 py-2">
        <p className="font-medium text-sm">Users</p>
        {office.numUsers}
      </div>
      <div className="px-8 py-2">
        <p className="font-medium text-sm">Status</p>
        <span className="capitalize">{office.officeState}</span>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => {
              onCancelClick();
            }}
            AdditionalClassNames="pointer px-5"
            text="Close"
            width="fit"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewOffice;
