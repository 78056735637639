/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {
  BreadCrumbs,
  Button,
  Checkbox,
  ConfirmationDialog,
  DatePicker,
  DropArea,
  Select,
  SelectWithSearch,
  TextInput,
  UserSideBar,
} from "../../components";
import { FileTypes, ToastVariants } from "../../models";
import { formatCurrency, formatPhoneNumber, onFileError, priceReview, RequestTypeTitle, validateEmail, validatePhoneOrFaxNumber } from "../../utils";
import { AmountReplaceRegex, DiscountValueRegex, Phone_Fax_Regex, SESSION_STORAGE_NEW_REQUEST, colors } from "../../utils/constants";
import useHomeRoute from "../../hooks/useHomeRoute";
import moment from "moment";
import { AuthPermission, UserState, authService } from "../../services/auth.service";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, AppDispatch } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { getOffice, getOfficeCareProviders, searchOfficePatients, getOfficeUsersWithPermission } from "../../redux/slices/OfficeSlice";
import { searchServiceData } from "../../redux/slices/ServiceSlice";
import { User } from "../../models/user.model";
import AddEditFee from "../../components/forms/AddEditFee/AddEditFee";
import { addRequest } from "../../redux/slices/OnBoardingSlice";
import { clearRequestData } from "../../redux/slices/OfficeRequestSlice";
import { Service, ServiceBilledTo, ServiceOwner } from "../../models/service.model";
import { listOfficeRequests } from "../../redux/slices/OfficeRequestSlice";
import { RequestDiscountType, RequestType as NewRequestType } from "../../models/request.model";
import { InputsTypes, inputsInitialState, serviceBilled } from "./CreateRequestData";
import { clearUploadedFiles } from "../../redux/slices/FileSlice";
import { searchOfficeUser } from "../../redux/slices/UserSlice";
import { UserType } from "../../services/user.service";
import { Contact, ContactMethodType } from "../../models/contact-model";
import { ContactService } from "../../services/contact.service";
import { InvoiceDetails, PriceDetails } from "../../models/price-details.model";
import { OfficeService } from "../../services/office.service";
import { UpdateRequest } from "../../models/update-request.model";
import { UpdateFee } from "../../models/update-fee.model";
import { useDebouncedCallback } from "use-debounce";
import { OfficeState } from "../../models/office.model";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";

let isEmailChange = false;
const windowObj = window as any;

const CreateRequestScreen = () => {
  const { id } = useParams();
  const { homeLink } = useHomeRoute();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useContext(ToastContext);
  const [inputs, setInputs] = useState([...inputsInitialState]);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [showPatientInviteDialog, setShowPatientInviteDialog] = useState(false);
  const currentPermissions = authService.currentUserPermissions;
  const { currentOffice } = useSelector((state: RootState) => state.office);
  const { loading: fileLoading } = useSelector((state: RootState) => state.file);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const breadCrumbItems = [
    { key: `/offices`, title: `Offices` },
    { key: `/offices/${currentOffice?.officeId}/requests`, title: currentOffice?.name },
    { key: `/offices/${currentOffice?.officeId}/requests`, title: `Requests` },
    { key: "#", title: `Create Request` },
  ];
  const { requestData, loading } = useSelector((state: RootState) => state.officeRequest);
  const { userWithPermission, patientList } = useSelector((state: RootState) => state.office);
  const { pending } = useSelector((state: RootState) => state.onBoarding);
  const [formType, setFormType] = useState<NewRequestType | undefined>(undefined);
  const allServices = useSelector((state: RootState) => state.service.serviceList.data?.content);
  const [selectedPatient, setSelectedPatient] = useState<any>();
  const [services, setServices] = useState(allServices);
  const [staff, setStaff] = useState<{ label: string; value: string }[]>([]);
  const [addSideBarOpen, setAddSideBarOpen] = useState<boolean>(false);
  const [officeStaff, setOfficeStaff] = useState<string>("");
  const [careProvider, setCareProvider] = useState<string>("");
  const [addRequestError, setAddRequestError] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<Service | undefined>(undefined);
  const [serviceNotesValue, setServiceNotesValue] = useState<string>("");
  const [serviceNotesError, setServiceNotesError] = useState<boolean>(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string | undefined>(undefined);
  const [discountType, setDiscountType] = useState(RequestDiscountType.Percentage);
  const [discountValue, setDiscountValue] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountError, setDiscountError] = useState(false);
  const [selectsearch, setselectSearch] = useState("");
  const [selectPatientsearch, setselectPatientSearch] = useState("");
  const [searchedUserData, setSearchedUserData] = useState<User | null>(null);
  const [officeContacts, setOfficeContacts] = useState<Contact[]>([]);
  const [selectedContactsearch, setSelectContactSearch] = useState("");
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>(undefined);
  const [selectedContactMethod, setContactMethod] = useState<ContactMethodType>(ContactMethodType.Email);
  const [priceDetails, setPriceDetails] = useState<PriceDetails | undefined>(undefined);
  const [additionalFees, setAdditionalFees] = useState<UpdateFee[] | undefined>(undefined);
  const [dropAreaError, setDropAreaError] = useState<string>("");
  const patientOptions = patientList?.data?.content;
  const billedto = inputs.find(f => f.id === "billedto")?.value as string;
  let billedToSent: ServiceBilledTo;
  switch (formType) {
    case NewRequestType.QuickFormOffice:
    case NewRequestType.PrescriptionRefill:
      billedToSent = ServiceBilledTo.Patient;
      break;
    case NewRequestType.DocnoteDebit:
      billedToSent = ServiceBilledTo.DocnoteDebit;
      break;
    default:
      billedToSent = billedto as ServiceBilledTo;
      break;
  }

  const {
    servicePrice,
    dscDesc,
    dscValue,
    blockFeeDiscountDesc,
    blockFeeDiscountAmount,
    additionalFeeTotal,
    subTotal,
    dsFee,
    dsFeeDesc,
    hstValue,
    hstPercentage,
    hstDesc,
    total,
  } = priceReview(priceDetails);

  const firstName = (inputs.find(f => f.id === "firstName")?.value as string).trim();
  const birthday = inputs.find(f => f.id === "dateOfBirth")?.value as string;
  const details = (inputs.find(f => f.id === "details")?.value as string).trim();
  const recipientField =
    formType === NewRequestType.WSIBOffice || formType === NewRequestType.ServiceCanadaOffice
      ? "recipientName"
      : formType === NewRequestType.ThirdPartyOffice
      ? "recipientNameNew"
      : formType === NewRequestType.PrescriptionRefill
      ? "pharmacyName"
      : "recipientName";
  const recipientContactField =
    formType === NewRequestType.WSIBOffice || formType === NewRequestType.ServiceCanadaOffice
      ? "emailOrFax"
      : formType === NewRequestType.ThirdPartyOffice
      ? "emailOrFaxNew"
      : formType === NewRequestType.PrescriptionRefill
      ? "pharmacyEmailOrFax"
      : "emailOrFax";
  const recipientName = (inputs.find(f => f.id === recipientField)?.value as string).trim();
  const childs = inputs.map(f => {
    if (f.child) {
      return f.child[0];
    } else {
      return null;
    }
  });
  const files = inputs.find(f => f.id === "files")?.value as any;
  const newFiles: string[] = files && files?.length ? files.split(",") : [];
  const lastName = (childs.find(f => f?.id === "lastName")?.value as string).trim();
  const ohipNumber = childs.find(f => f?.id === "ohipNumber")?.value as string;
  const email = (inputs.find(f => f?.id === "email")?.value as string).trim();
  const phoneNumber = (childs.find(f => f?.id === "phone")?.value as string).trim();
  const recipientContact = childs.find(f => f?.id === recipientContactField)?.value as string;
  const typeOfService = inputs.find(f => f.id === "typeOfService")?.value as string;
  const servicePriceOverride = childs.find(f => f?.id === "servicePrice")?.value as string;
  const serviceNotes = inputs.find(f => f.id === "note")?.value as string;
  let finalServiceBilled: any = [];
  switch (formType) {
    case NewRequestType.ThirdPartyOffice:
      finalServiceBilled = serviceBilled.filter((item: any) => item.id === ServiceBilledTo.ThirdParty);
      break;
    case NewRequestType.WSIBOffice:
      finalServiceBilled = serviceBilled.filter((item: any) => item.id === ServiceBilledTo.WSIB);
      break;
    case NewRequestType.ServiceCanadaOffice:
      finalServiceBilled = serviceBilled.filter((item: any) => item.id === ServiceBilledTo.ServiceCanada);
      break;
    default:
      finalServiceBilled = serviceBilled;
  }

  const loadPatients = (id: string, term = "", page = 0, size = 100) => {
    dispatch(searchOfficePatients({ officeId: id, page: page, size: size, sort: "", term: term, userState: UserState.Active }));
  };

  const patientDebounced = useDebouncedCallback(val => {
    if (id) loadPatients(id, val);
  }, 400);

  useEffect(() => {
    const updated = [...inputs];
    let email = true;
    let dob = true;
    let pharmacy = false;
    let file = false;
    let hideAdditionalCheck = false;
    let billedToValue = "";
    let showBilledTo = false;
    switch (formType) {
      case NewRequestType.QuickFormOffice:
      case NewRequestType.PrescriptionRefill:
      case NewRequestType.DocnoteDebit:
        {
          dob = false;
          file = true;
          let billToDD = false;
          if (formType === NewRequestType.PrescriptionRefill) {
            pharmacy = true;
          }
          if (formType === NewRequestType.DocnoteDebit) {
            file = false;
            billToDD = true;
          }
          const newServices = allServices?.filter(f =>
            billToDD
              ? f.serviceBilledTo === ServiceBilledTo.DocnoteDebit
              : f.serviceBilledTo === ServiceBilledTo.Patient || f.serviceBilledTo === undefined
          );
          setServices(newServices);
        }
        break;
      case NewRequestType.ThirdPartyOffice:
        {
          const newServices = allServices?.filter(f => f.serviceBilledTo === ServiceBilledTo.ThirdParty || f.serviceBilledTo === undefined);
          setServices(newServices);
          email = true;
          hideAdditionalCheck = true;
          billedToValue = ServiceBilledTo.ThirdParty;
        }
        break;
      case NewRequestType.WSIBOffice:
        {
          const newServices = allServices?.filter(f => f.serviceBilledTo === ServiceBilledTo.WSIB || f.serviceBilledTo === undefined);
          setServices(newServices);
          email = true;
          billedToValue = ServiceBilledTo.WSIB;
        }
        break;
      case NewRequestType.ServiceCanadaOffice:
        {
          const newServices = allServices?.filter(f => f.serviceBilledTo === ServiceBilledTo.ServiceCanada || f.serviceBilledTo === undefined);
          setServices(newServices);
          email = true;
          billedToValue = ServiceBilledTo.ServiceCanada;
        }
        break;
      case NewRequestType.StandardOffice:
        {
          showBilledTo = true;
          const newServices = allServices?.filter(f => f.serviceBilledTo !== ServiceBilledTo.DocnoteDebit || f.serviceBilledTo === undefined);
          setServices(newServices);
        }
        break;
      default:
        setServices(allServices);
        break;
    }
    updated.forEach(item => {
      switch (item.id) {
        case "email":
          item.visible = email;
          if (!email) item.isValid = true;

          item.child?.forEach(child => {
            if (child.id === "phone") {
              child.visible = email;
            }
          });
          break;
        case "dateOfBirth":
          item.visible = dob;
          item.child?.forEach(child => {
            if (child.id === "ohipNumber") {
              child.visible = dob;
            }
          });
          break;
        case "recipientSection":
          item.visible = hideAdditionalCheck;
          break;
        case "recipientName":
          item.visible = false;
          item.value = "";
          item.isValid = false;
          item.child?.forEach(child => {
            if (child.id === "emailOrFax") {
              child.visible = false;
              child.value = "";
              child.isValid = false;
            }
          });
          break;
        case "recipientNameNew":
          item.visible = hideAdditionalCheck;
          item.value = "";
          item.isValid = false;
          item.child?.forEach(child => {
            if (child.id === "emailOrFaxNew") {
              child.visible = hideAdditionalCheck;
              child.value = "";
              child.isValid = false;
            }
          });
          break;
        case "pharmacySection":
          item.visible = pharmacy;
          break;
        case "pharmacyName":
          item.visible = pharmacy;
          item.value = "";
          item.isValid = false;
          item.child?.forEach(child => {
            if (child.id === "pharmacyEmailOrFax") {
              child.visible = pharmacy;
              child.value = "";
              child.isValid = false;
            }
          });
          break;
        case "addtionalCopyCheck":
          item.visible = email && dob && !hideAdditionalCheck;
          item.value = false;
          break;
        case "files":
          item.required = file;
          item.isValid = file && item.value ? true : !file;
          break;
        case "billedto":
          item.visible = showBilledTo;
          item.isValid = billedToValue ? true : !((email && dob) || !email);
          item.value = billedToValue;
          break;
        default:
          break;
      }
    });
    setInputs(updated);
  }, [formType, allServices]);

  useEffect(() => {
    if (selectedPatient) {
      if (selectedPatient?.userId) {
        setSelectedPatientId(selectedPatient?.userId);
      }
      const keys = Object.keys(selectedPatient);
      const updated = [...inputs];
      const newInputs = updated.map(item => {
        const newItem = { ...item };
        if (keys.includes(newItem.id)) newItem.value = selectedPatient[newItem.id];

        if (newItem.id === "firstName") {
          if (selectedPatient[newItem.id].length) {
            newItem.isValid = true;
          }
        }
        if (newItem.id === "email") {
          if (selectedPatient[newItem.id].length) {
            newItem.isValid = validateEmail(newItem.value as string);
          }
        }
        if (newItem.child && newItem.child?.length) {
          let newItemChild = [...newItem.child];
          newItemChild = newItemChild.map(child => {
            const newChild = { ...child };
            if (keys.includes(newChild.id)) newChild.value = selectedPatient[newChild.id];
            switch (newChild.id) {
              case "lastName":
                if (selectedPatient[newChild.id]?.length) {
                  newChild.isValid = true;
                }
                break;
              case "ohipNumber":
                if (selectedPatient[newChild.id]?.length) {
                  newChild.isValid = true;
                }
                break;
              case "phone":
                if (selectedPatient[newChild.id]?.length) {
                  newChild.value = formatPhoneNumber(newChild.value as string);
                  newChild.isValid = Phone_Fax_Regex.test(newChild.value);
                }
                break;
              default:
                break;
            }
            return newChild;
          });
          newItem.child = [...newItemChild];
        }
        return newItem;
      });

      setInputs(newInputs);
    }
  }, [selectedPatient]);

  useEffect(() => {
    localStorage.removeItem("additionalFee");
    dispatch(clearRequestData());
    if (id) {
      loadPatients(id);
      dispatch(listOfficeRequests({ officeId: id, page: 0, size: 100, sort: "createdDate,desc", assignedTo: "" })).then(() => {});
      dispatch(
        searchServiceData({
          officeId: id,
          page: 0,
          sort: "name",
          size: 200,
          enabledOnly: true,
          serviceOwner: ServiceOwner.Office,
        })
      );
    }
    return () => {
      dispatch(clearUploadedFiles());
    };
  }, []);

  useEffect(() => {
    if (id) {
      const officeId = id;
      dispatch(getOfficeUsersWithPermission({ officeId, permission: AuthPermission.Request, page: 0, sort: "", size: 100 }));
      dispatch(getOfficeCareProviders({ officeId }));
    }
  }, [id]);

  useEffect(() => {
    if (currentOffice === null && id) {
      dispatch(getOffice({ officeId: id }));
    }
    if (currentOffice?.officeId && currentOffice?.officeState !== OfficeState.Published) {
      navigate(`/offices/${currentOffice?.officeId}/requests`);
    }
  }, [currentOffice]);

  useEffect(() => {
    let newOptions = userWithPermission?.data?.content
      .filter(item => item.userState === "active")
      ?.map((user: User) => {
        let hasRequestPermission = false;
        if (user.permissions.includes(AuthPermission.Request)) {
          hasRequestPermission = true;
        }

        // then set has perm to true
        return { label: `${user.firstName} ${user.lastName}`, value: user.userId, requestPermission: hasRequestPermission };
      });
    newOptions = newOptions ? newOptions : [];
    setStaff(newOptions);
  }, [userWithPermission]);

  useEffect(() => {
    const pcpData = currentOffice?.primaryCareProviders;
    if (staff && pcpData && staff.length && pcpData.length) {
      const filteredStaff = pcpData.map(item => {
        const obj = staff.find(item1 => item.userState === UserState.Active && item.userId === item1.value);
        return obj;
      });
      if (filteredStaff && filteredStaff.length) {
        const currentstaff = staff.find(s => s.value === currentUser?.userId);
        if (currentstaff && currentstaff.value) {
          setOfficeStaff(currentstaff.value);
        } else {
          if (staff.length === 2 && filteredStaff.length === 1) {
            const stf = filteredStaff[0] && filteredStaff[0]["value"];
            if (stf) {
              const toBeSelect = staff.find(it => it.value !== stf);
              if (toBeSelect && toBeSelect.value) {
                setOfficeStaff(toBeSelect.value);
              }
            }
          } else if (filteredStaff.length === 1) {
            if (filteredStaff[0] && filteredStaff[0]["value"]) {
              setOfficeStaff(filteredStaff[0]["value"]);
            }
          }
        }
      }
    }
  }, [staff, currentOffice?.primaryCareProviders]);

  useEffect(() => {
    const pcpData = currentOffice?.primaryCareProviders;
    if (pcpData?.length) {
      const pcpList = pcpData?.filter(item => item.userState === UserState.Active);
      if (pcpList?.length === 1) {
        setCareProvider(pcpList[0].userId);
      }
    }
  }, [currentOffice?.primaryCareProviders]);

  useEffect(() => {
    if (!currentPermissions.includes(AuthPermission.Request)) {
      navigate(homeLink);
    }
  }, [currentPermissions]);

  const handleBlur = (id: string) => {
    const updated = [...inputs];
    setInputs(updated);
  };

  const handleFormType = async (val: NewRequestType) => {
    setFormType(val);
    setSelectedContact(undefined);
    setContactMethod(ContactMethodType.Email);
    handleChange("typeOfService", "");
  };

  const handleChange = (id: string, _value: string | boolean) => {
    if (id === "total") return;
    let tempInput = [...inputs];
    const inputIndex = tempInput.findIndex(a => a.id === id);
    let value = _value;
    let valid = value !== "";
    switch (id) {
      case "phone":
        value = formatPhoneNumber(value as string);
        valid = Phone_Fax_Regex.test(value);
        break;
      case "ohipNumber":
        if ((value as string).trim().length > 0) {
          valid = (value as string).length ? (value as string).length === 10 : true;
        } else {
          valid = true;
        }
        break;
      case "email":
        isEmailChange = true;
        valid = validateEmail((value as string).trim());
        break;
      case "note":
        if ((value as string).length > 50) {
          setServiceNotesError(true);
        } else {
          setServiceNotesError(false);
          setServiceNotesValue(value as string);
        }
        break;
      case "typeOfService":
        setDiscountAmount(0);
        setDiscountValue("");
        const billedToIs = services?.find(ele => ele.serviceId === value);
        setSelectedService(billedToIs);
        if (tempInput[inputIndex] && tempInput[inputIndex].child) {
          const newItem = { ...tempInput[inputIndex] };
          if (newItem.child && newItem.child?.length) {
            let newChild = [...newItem.child];
            const newChildItem = { ...newChild[0] };
            newChild = [
              ...newChild.slice(0, 0),
              {
                ...newChildItem,
                value: formatCurrency((services?.find(v => v.serviceId === value)?.price! / 100).toString() || "", "blur"),
                isValid: formatCurrency((services?.find(v => v.serviceId === value)?.price! / 100).toString() || "", "blur").length > 0,
              },
              ...newChild.slice(1),
            ];
            tempInput = [...tempInput.slice(0, inputIndex), { ...newItem, child: [...newChild] }, ...tempInput.slice(inputIndex + 1)];
          }
        }
        const billToIndex = tempInput.findIndex(itm => itm.id === "billedto");
        if (billToIndex !== -1 && billedToIs?.serviceBilledTo) {
          const newItem = { ...tempInput[billToIndex] };
          tempInput = [
            ...tempInput.slice(0, billToIndex),
            { ...newItem, value: billedToIs.serviceBilledTo, isValid: true },
            ...tempInput.slice(billToIndex + 1),
          ];
        }
        break;
      case "addtionalCopyCheck":
        const toChange = ["consentForExtraCopy", "emailOrFax", "recipientName"];
        if (value === true) {
          tempInput.forEach(val => {
            if (val.id === toChange[2]) {
              val.visible = true;
              val.child![0].visible = true;
            }
            if (val.id === toChange[0]) {
              val.visible = true;
              val.isValid = false;
            }
          });
        } else {
          setSelectedContact(undefined);
          tempInput.forEach(val => {
            if (val.id === toChange[2]) {
              val.visible = false;
              val.child![0].visible = false;
              val.value = "";
              val.child![0].value = "";
              val.child![0].isValid = false;
              val.isValid = false;
            }
            if (val.id === toChange[0]) {
              val.visible = false;
              val.value = false;
              val.isValid = false;
            }
          });
        }
        break;
      case "consentForExtraCopy":
        valid = value as boolean;
        tempInput[inputIndex].isValid = value as boolean;
        break;
      case "emailOrFax":
      case "emailOrFaxNew":
      case "pharmacyEmailOrFax":
        if (validateEmail((value as string).trim())) {
          valid = true;
        } else if (validatePhoneOrFaxNumber(formatPhoneNumber(value as string))) {
          value = formatPhoneNumber(value as string);
          valid = true;
        } else {
          valid = false;
        }
        break;
      case "servicePrice":
        value = formatCurrency(value as string, "");
        valid = value !== "";
        break;
      default:
        break;
    }
    if (inputIndex === -1) {
      let newInputs = [...tempInput];
      tempInput.forEach((val, i, arr) => {
        const newItem = { ...val };
        if (newItem.child && newItem.child[0].id === id) {
          let newChilds = [...newItem.child];
          newChilds = [...newChilds.slice(0, 0), { ...newChilds[0], value: value, isValid: valid }, ...newChilds.slice(1)];
          newInputs = [...newInputs.slice(0, i), { ...newInputs[i], child: [...newChilds] }, ...newInputs.slice(i + 1)];
        }
      });
      setInputs(newInputs);
      return;
    }

    setInputs([
      ...tempInput.slice(0, inputIndex),
      {
        ...tempInput[inputIndex],
        value: value,
        isValid: valid,
      },
      ...tempInput.slice(inputIndex + 1),
    ]);
  };

  const validateForm = () => {
    if (serviceNotesError) {
      return false;
    }
    return (
      !!formType &&
      inputs
        .map(v => {
          if (v.visible === false) return true;
          if (v.required === false) return true;
          if (v.child) {
            return [v.child[0].isValid, v.isValid].every(v => v);
          }
          return v.isValid;
        })
        .every(v => v)
    );
  };

  const renderInput = (inp: InputsTypes) => {
    if (inp.visible === false) return null;
    switch (inp.type) {
      case "textInput":
        return (
          <>
            <TextInput
              onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
              disabled={inp.id === "recipientName" || inp.id === "emailOrFax" ? false : inp.id === "servicePrice"}
              placeholder={inp.placeholder}
              value={typeof inp.value === "string" ? inp.value : ""}
              errorMsg={inp.errorMesage}
              isRequired={inp.required}
              label={inp.title}
              key={inp.id}
              isError={!inp.isValid && errorInitiated}
              extraInputClass={"mb-2"}
              onBlur={e => {
                handleBlur(inp.id);
                handleChange(inp.id, e.target.value.trim());
              }}
            />
          </>
        );
      case "typeOfService":
        return (
          <div key={inp.id} className="select-service">
            <SelectWithSearch
              value={inp.value as string}
              onSearcSelect={newalue => {
                setselectSearch(newalue);
              }}
              onSelect={newValue => {
                handleChange(inp.id, newValue.serviceId);
              }}
              options={services?.filter(o => o.name.toLowerCase().includes(selectsearch.toLowerCase())) || []}
              label={inp.title}
              required={inp.required}
              placeholder={"Select Service"}
              isSearchBox={true}
              searchValue={selectsearch}
              renderOption={option => {
                return option.name;
              }}
              renderValue={option => {
                return services?.find(o => o.serviceId === option)?.name;
              }}
              onSearchChange={(e, newValue) => {
                setselectSearch(newValue);
              }}
            />
            {!inp.isValid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMesage}</p> : null}
          </div>
        );
      case "billedto":
        return (
          <div key={inp.id} className="block sm:flex justify-between items-center">
            <label className="text-sm text-docsigna-blue-dark font-medium block w-half mb-1">
              {inp.title}
              {inp.required ? <span className="text-red-500">*</span> : null}
            </label>{" "}
            <div className="w-full sm:w-48">
              <select
                onChange={e => handleChange(inp.id, e.currentTarget.value)}
                value={inp.value as string}
                className="general-select w-full"
                disabled={
                  formType
                    ? [NewRequestType.ThirdPartyOffice, NewRequestType.WSIBOffice, NewRequestType.ServiceCanadaOffice].includes(formType)
                    : false
                }>
                {formType === NewRequestType.StandardOffice || !formType ? <option hidden>Select billed to</option> : null}
                {finalServiceBilled?.map((ser: any) => {
                  return (
                    <option value={ser.id} key={ser.id}>
                      {ser.name}
                    </option>
                  );
                })}
              </select>
              <div>{!inp.isValid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMesage}</p> : null}</div>
            </div>
          </div>
        );
      case "SectionTitle":
        return (
          <div>
            <p key={inp.id} className="text-base mb-1 mt-3">
              <span className="block text-base font-bold">{inp.title}</span>
            </p>
            {inp.subTitle ? <p className="text-base mb-2">{inp.subTitle}</p> : null}
          </div>
        );
      case "DropArea":
        return formType !== NewRequestType.DocnoteDebit ? (
          <>
            <p className="text-base mb-2">
              <span className="block text-base  font-bold">
                {formType === NewRequestType.StandardOffice ||
                formType === NewRequestType.ThirdPartyOffice ||
                formType === NewRequestType.WSIBOffice ||
                formType === NewRequestType.ServiceCanadaOffice
                  ? "Attachments"
                  : formType === NewRequestType.PrescriptionRefill
                  ? "Prescription Renewal Document"
                  : "Final Document(s)"}
                {formType === NewRequestType.QuickFormOffice || formType === NewRequestType.PrescriptionRefill ? (
                  <span className="text-red-500">*</span>
                ) : null}
              </span>{" "}
              <p>You can attach up to 10 JPEG, PDF or PNG files</p>
            </p>
            <DropArea
              allowedFileTypes={[FileTypes.JPEG, FileTypes.PNG, FileTypes.PDF]}
              onFilesChange={files => handleChange(inp.id, files.toString())}
              onError={err => setDropAreaError(onFileError(err))}
              maxFileSize={50 * 1024 * 1024}
              maxNumOfFiles={10}
              filePreviewVisible={true}
            />
            <p className="text-red-500">{!inp.isValid && errorInitiated ? inp.errorMesage : null}</p>
            {dropAreaError ? <p className="text-red-500">{dropAreaError}</p> : null}
          </>
        ) : null;
      case "textArea":
        return (
          <>
            <TextInput
              onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
              placeholder={inp.placeholder}
              value={typeof inp.value === "string" ? inp.value : ""}
              errorMsg={inp.errorMesage}
              isRequired={inp.required}
              label={inp.title}
              key={inp.id}
              isTextArea
              isError={!inp.isValid && errorInitiated}
              extraInputClass="resize-none"
            />
          </>
        );
      case "serviceNotes":
        return (
          <TextInput
            onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
            placeholder={inp.placeholder}
            value={serviceNotesValue}
            errorMsg={inp.errorMesage}
            isRequired={inp.required}
            label={inp.title}
            key={inp.id}
            isTextArea
            isError={errorInitiated && serviceNotesError}
            showCounter={true}
            counterMessage={"50"}
            extraInputClass="resize-none"
          />
        );
      case "checkbox":
        return (
          <>
            {inp.id === "addtionalCopyCheck" ? (
              <p className="text-base mb-2">
                <span className="block text-base  font-bold mb-1">
                  Recipient{" "}
                  {formType && [NewRequestType.WSIBOffice, NewRequestType.ServiceCanadaOffice, NewRequestType.StandardOffice].includes(formType)
                    ? "(Optional)"
                    : ""}
                </span>
              </p>
            ) : null}
            <Checkbox
              label={inp.title}
              checked={typeof inp.value === "boolean" ? inp.value : false}
              key={inp.id}
              onChange={e => handleChange(inp.id, e.currentTarget.checked)}
            />
            {!inp.isValid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMesage}</p> : null}
          </>
        );
      case "dateOfBirth":
        return (
          <div className="create-request-screen mb-1">
            <DatePicker
              label={inp.title}
              isRequired={inp.required}
              name={inp.id}
              placeholder={inp.placeholder!}
              value={inp.value as string}
              onChange={val => handleChange(inp.id, val)}
              errorMsg={inp.errorMesage}
              isError={!inp.isValid && errorInitiated}
            />
          </div>
        );
      case "ohip":
        return (
          <div>
            <label className="text-sm text-docsigna-blue-dark font-medium block w-full">
              {inp.title}
              {inp.required ? <span className="text-red-500">*</span> : null}
            </label>
            <NumberFormat
              value={inp.value as string}
              isNumericString
              format={"#### ### ###"}
              placeholder="Enter OHIP Number"
              displayType="input"
              className="general-ohip"
              onValueChange={values => {
                handleChange(inp.id, values.value);
              }}
            />
            {!inp.isValid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMesage}</p> : null}
          </div>
        );
      case "select":
        return (
          <>
            <Select
              required
              placeHolder={inp.placeholder!}
              title={inp.title}
              onSelect={e => handleChange(inp.id, e.currentTarget.value)}
              options={["Option 1", "Option 2", "Option 3"]}
            />
            {!inp.isValid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMesage}</p> : null}
          </>
        );
      default:
        break;
    }
  };

  const handleAddAdditionalSideBar = () => {
    setAddSideBarOpen(true);
  };

  const handleStaffChange = (e: string) => {
    setOfficeStaff(e);
  };

  const handleCareProvider = (e: string) => {
    let val = e;
    if (e === "Select Care Provider") {
      val = "";
    }
    setCareProvider(val);
  };

  const saveRequest = async () => {
    if (currentOffice?.officeId) {
      let userData = null;
      if (isEmailChange && email && selectedPatient?.email !== email) {
        const res: any = await dispatch(
          searchOfficeUser({
            officeId: currentOffice.officeId,
            term: email,
            sortType: "lastName,asc",
            page: 0,
            size: 200,
            userType: UserType.Patient,
          })
        );
        if (res?.payload && res?.payload?.data && res?.payload?.data?.content?.length) {
          userData = res?.payload?.data?.content[0];
          if (userData?.userState === UserState.Active) {
            setSearchedUserData(userData);
          } else {
            addReq(false, userData?.userId);
          }
          return;
        } else {
          if (
            formType &&
            ![NewRequestType.DocnoteDebit, NewRequestType.ThirdPartyOffice, NewRequestType.WSIBOffice, NewRequestType.ServiceCanadaOffice].includes(
              formType
            )
          ) {
            setShowPatientInviteDialog(true);
            return;
          }
        }
      }
      addReq();
    }
  };

  const addReq = (useSearchedData = false, userId?: string) => {
    if (currentOffice?.officeId) {
      const requestFormType = formType === NewRequestType.QuickFormOffice;
      let patientId;
      if (!useSearchedData && userId) {
        patientId = userId;
      } else {
        patientId =
          useSearchedData && searchedUserData?.userId
            ? searchedUserData?.userId
            : selectedPatientId && isEmailChange && email !== selectedPatient?.email
            ? undefined
            : selectedPatientId;
      }
      if (formType) {
        let finalFormType = formType;
        switch (formType) {
          case NewRequestType.ServiceCanadaOffice:
          case NewRequestType.WSIBOffice:
            finalFormType = NewRequestType.ThirdPartyOffice;
            break;
          default:
            break;
        }
        const requestBody = {
          requestType: finalFormType,
          firstName: useSearchedData && searchedUserData?.firstName ? searchedUserData?.firstName : firstName,
          lastName: useSearchedData && searchedUserData?.lastName ? searchedUserData?.lastName : lastName,
          dateOfBirth: useSearchedData && searchedUserData?.dateOfBirth ? searchedUserData?.dateOfBirth : birthday === "" ? undefined : birthday,
          ohipNumber: useSearchedData && searchedUserData?.ohipNumber ? searchedUserData?.ohipNumber : ohipNumber === "" ? undefined : ohipNumber,
          phone: useSearchedData && searchedUserData?.phone ? searchedUserData?.phone : phoneNumber === "" ? undefined : phoneNumber,
          email: email?.trim() ? email.trim() : undefined,
          details: details === "" ? undefined : details,
          fileIds: requestFormType ? undefined : newFiles.length ? newFiles : undefined,
          recipientName: recipientName === "" ? undefined : recipientName.trim(),
          recipientContact: recipientContact === "" ? undefined : recipientContact.trim(),
          officeId: currentOffice?.officeId,
          patientId: patientId,
          fulfillOnPayment: requestFormType ? true : undefined,
          assignedToUserId: officeStaff ? officeStaff : undefined,
          primaryCareProviderUserId: careProvider ? careProvider : undefined,
          serviceId: typeOfService as string,
          serviceBilledToOverride: billedToSent,
          servicePriceOverride: parseFloat(servicePriceOverride.replace("$", "").replaceAll(",", "")) * 100,
          serviceNotes: serviceNotes && serviceNotes.length ? serviceNotes.trim() : undefined,
          finalFileIds: requestFormType ? (newFiles.length ? newFiles : []) : undefined,
          patientVerified: true,
          fees: additionalFees,
          discountAmount: discountAmount,
          discountType: discountType,
        };
        if (windowObj && windowObj?.userGuiding && windowObj?.userGuiding?.launchSurvey) {
          windowObj?.userGuiding?.launchSurvey(3152);
        }
        dispatch(addRequest({ request: requestBody })).then(action => {
          if (action.payload !== null) {
            const stringifyId = JSON.stringify(action?.payload);
            const parseId = JSON.parse(stringifyId);
            dispatch(clearUploadedFiles());
            if (!useSearchedData && userId) {
              sessionStorage.setItem(SESSION_STORAGE_NEW_REQUEST, parseId?.requestId);
            }
            navigate(`/offices/${currentOffice?.officeId}/requests/${parseId?.requestId}`);
          } else {
            setAddRequestError(true);
          }
          setSearchedUserData(null);
        });
      }
    }
  };

  const updateRecipients = (contact: Contact, val: ContactMethodType) => {
    let fieldName = "recipientName";
    switch (formType) {
      case NewRequestType.ThirdPartyOffice:
        fieldName = "recipientNameNew";
        break;
      case NewRequestType.PrescriptionRefill:
        fieldName = "pharmacyName";
        break;
      default:
        break;
    }
    const index = inputs.findIndex(inp => inp.id === fieldName);
    if (index !== -1) {
      const oldInp = inputs[index];
      const child = oldInp.child;
      if (child) {
        child[0].value = val === ContactMethodType.Email ? contact.email ?? "" : contact.fax ?? "";
        child[0].isValid = (val === ContactMethodType.Email && contact.email) || (val === ContactMethodType.Fax && contact.fax) ? true : false;
      }
      const newItem = { ...oldInp, value: `${contact?.company ?? ""}`, isValid: true, child };
      setInputs([...inputs.slice(0, index), { ...newItem }, ...inputs.slice(index + 1)]);
    }
  };

  const handleContactMethod = (val: ContactMethodType) => {
    setContactMethod(val);
    if (selectedContact) {
      updateRecipients(selectedContact, val);
    }
  };

  const onOptionSelect = (patientData: any) => {
    isEmailChange = false;
    setSelectedPatient(patientData);
    setselectPatientSearch("");
  };

  const debounced = useDebouncedCallback(value => {
    setDiscountAmount(value);
  }, 750);

  const onContactOptionSelect = (contact: Contact) => {
    setSelectedContact(contact);
    setSelectContactSearch("");
    const newContactMethod =
      contact?.contactPreference && contact?.contactPreference !== "Phone"
        ? (contact?.contactPreference as ContactMethodType)
        : selectedContactMethod;
    setContactMethod(newContactMethod);
    updateRecipients(contact, newContactMethod);
  };

  const handleDiscount = (type: "$" | "%" | "input", value: string | boolean) => {
    switch (type) {
      case "$":
      case "%":
        setDiscountType(type === "$" ? RequestDiscountType.Amount : RequestDiscountType.Percentage);
        setDiscountAmount(0);
        setDiscountValue("");
        setDiscountError(false);
        break;
      case "input":
        switch (discountType) {
          case RequestDiscountType.Amount:
            const amount = formatCurrency(value as string, "");
            setDiscountValue(amount);
            const amnt = +amount.replace(AmountReplaceRegex, "") * 100;
            if (selectedService?.price && amnt > selectedService?.price) {
              setDiscountError(true);
            } else {
              setDiscountError(false);
            }
            debounced(parseFloat(amnt.toFixed(2)));
            break;
          case RequestDiscountType.Percentage:
            const discount = +parseInt((value as string).replace(DiscountValueRegex, "")).toFixed(2) || 0;
            if (discount <= 100) {
              setDiscountValue(discount ? (value as string).replace(DiscountValueRegex, "") : "");
              debounced(discount);
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleDiscountBlur = () => {
    if (discountAmount && discountType === RequestDiscountType.Amount) {
      setDiscountValue(formatCurrency((discountAmount / 100)?.toString(), "blur"));
    }
  };

  const getUserData = () => {
    return (
      <div>
        <h2 className="font-bold">This patient has an existing account with the below details:</h2>
        <div className="grid grid-cols-1 gap-2 mt-3 mb-3">
          <div>
            <span className="font-semibold mr-2">Name:</span>
            {searchedUserData?.firstName} {searchedUserData?.lastName}
          </div>
          <div>
            <span className="font-semibold mr-2">OHIP Number:</span>
            {searchedUserData?.ohipNumber ? searchedUserData?.ohipNumber : "-"}
          </div>
          <div>
            <span className="font-semibold mr-2">Date of Birth:</span>
            {searchedUserData?.dateOfBirth ? moment(searchedUserData?.dateOfBirth).format("ll") : "-"}
          </div>
          <div>
            <span className="font-semibold mr-2">Email:</span>
            <a
              href={`mailto:${searchedUserData?.email}`}
              className="cursor-pointer hover:underline text-docsigna-blue-dark"
              onClick={e => e.stopPropagation()}>
              {searchedUserData?.email}
            </a>
          </div>
          <div>
            <span className="font-semibold mr-2">Phone #:</span>
            {searchedUserData?.phone ? searchedUserData?.phone : "-"}
          </div>
        </div>
        <h2 className="">Would you like to proceed using their account? Please click No to continue as a guest request.</h2>
        <div className="flex justify-end">
          <div className="w-40 flex justify-between mt-5">
            <Button onClickFunc={() => setSearchedUserData(null)} text="No" varient={"Secondary"} AdditionalClassNames="" />
            <Button onClickFunc={() => addReq(true)} text="Proceed" varient={"Primary"} AdditionalClassNames="border" />
          </div>
        </div>
      </div>
    );
  };

  const fetchContacts = async (id: string) => {
    const resp = await ContactService.searchContacts(id, 0, 200);
    if (resp && resp?.content?.length) {
      setOfficeContacts(resp?.content);
    }
  };

  const handleUserInvite = async () => {
    addReq();
    setShowPatientInviteDialog(false);
  };

  useEffect(() => {
    const getPrice = async () => {
      if (id && formType && selectedService && billedToSent && servicePriceOverride) {
        let finalFormType = formType;
        switch (formType) {
          case NewRequestType.ServiceCanadaOffice:
          case NewRequestType.WSIBOffice:
            finalFormType = NewRequestType.ThirdPartyOffice;
            break;
          default:
            break;
        }
        const body: UpdateRequest = {
          officeId: id,
          firstName: firstName,
          lastName: lastName,
          requestType: finalFormType,
          serviceId: selectedService?.serviceId,
          patientId: selectedPatientId,
          serviceBilledToOverride: billedToSent,
          servicePriceOverride: selectedService?.price ?? 0,
          discountType: discountAmount > 0 ? discountType : undefined,
          discountAmount: discountAmount > 0 ? discountAmount : undefined,
          fees: additionalFees,
        };
        const resp = await OfficeService.getPriceReview(id, body);
        if (resp) {
          setPriceDetails(resp);
          if (resp?.totalAmount > 0 && resp?.totalAmount / 100 < 1) {
            toast?.openToast("Minimum transaction amount is $1", 2000, ToastVariants.Error);
          }
        } else setPriceDetails(undefined);
      }
    };
    getPrice();
  }, [id, formType, selectedPatientId, discountType, discountAmount, selectedService, billedToSent, servicePriceOverride, additionalFees]);

  useEffect(() => {
    if (id) fetchContacts(id);
  }, [id]);

  return (
    <div className="container mx-auto p-8">
      <BreadCrumbs items={breadCrumbItems} />
      <div className="w-full max-w-2xl">
        <h2 className="font-bold text-2xl mb-2 mt-5">Create New Request</h2>
        <p className="text-base font-medium mb-5">{"To create a new request, search by patient name or manually add patient details below."}</p>
        <div className="py-2">
          <select onChange={e => handleFormType(e.target.value as NewRequestType)} value={formType} className="general-select w-full">
            <option value="">
              Select request type <span className="text-red-500">*</span>
            </option>
            <option value={NewRequestType.QuickFormOffice} key={NewRequestType.QuickFormOffice}>
              {RequestTypeTitle.QuickForm}
            </option>
            <option value={NewRequestType.StandardOffice} key={NewRequestType.StandardOffice}>
              {RequestTypeTitle.NewRequest}
            </option>
            <option value={NewRequestType.ThirdPartyOffice} key={NewRequestType.ThirdPartyOffice}>
              {RequestTypeTitle.ThirdParty}
            </option>
            <option value={NewRequestType.PrescriptionRefill} key={NewRequestType.PrescriptionRefill}>
              {RequestTypeTitle.PrescriptionRefill}
            </option>
            <option value={NewRequestType.DocnoteDebit} key={NewRequestType.DocnoteDebit}>
              {RequestTypeTitle.DocnoteDebit}
            </option>
            <option value={NewRequestType.WSIBOffice} key={NewRequestType.WSIBOffice}>
              {RequestTypeTitle.WSIB}
            </option>
            <option value={NewRequestType.ServiceCanadaOffice} key={NewRequestType.ServiceCanadaOffice}>
              {RequestTypeTitle.ServiceCanada}
            </option>
          </select>
          <div>{!formType && errorInitiated ? <p style={{ color: colors.Red }}>{"Please select request type"}</p> : null}</div>
        </div>
        <div className="py-2 relative ">
          <SelectWithSearch
            value={selectedPatient?.firstName ? `${selectedPatient?.firstName} ${selectedPatient?.lastName}` : ""}
            onSearcSelect={newalue => {
              setselectPatientSearch(newalue);
            }}
            onSelect={newValue => {
              onOptionSelect(newValue);
            }}
            options={patientOptions ?? []}
            placeholder={"Select Patient"}
            isSearchBox={true}
            searchValue={selectPatientsearch}
            renderOption={option => {
              return (
                <div>
                  {option.firstName} {option.lastName}{" "}
                  <div className="text-sm">Date of Birth: {moment(option.dateOfBirth).format("MMM DD, YYYY")}</div>
                </div>
              );
            }}
            renderValue={option => {
              const renderVal = patientOptions?.find(o => `${o?.firstName} ${o?.lastName}` === option);
              return renderVal ? `${renderVal?.firstName} ${renderVal?.lastName}` : "";
            }}
            onSearchChange={async (e, newValue) => {
              setselectPatientSearch(newValue);
              if (newValue.trim().length >= 3) {
                patientDebounced(newValue);
              } else if (selectPatientsearch.length > 2) {
                patientDebounced("");
              }
            }}
          />
        </div>
        <div className="mb-3">
          <button
            className="text-docsigna-blue-light font-medium"
            onClick={() => {
              setSelectedPatient("");
            }}>
            Manually enter patient information
          </button>
        </div>

        {inputs.map(inp => {
          if (inp.child && inp.child?.length > 0) {
            return (
              <>
                {inp.visible && (inp.id === "recipientName" || inp.id === "recipientNameNew" || inp.id === "pharmacyName") ? (
                  <>
                    <SelectWithSearch
                      value={selectedContact?.company ? `${selectedContact?.company}` : ""}
                      onSearcSelect={newalue => {
                        setSelectContactSearch(newalue);
                      }}
                      onSelect={newValue => {
                        onContactOptionSelect(newValue);
                      }}
                      options={officeContacts?.filter(o => `${o?.company?.toLowerCase()}`.includes(selectedContactsearch.toLowerCase())) || []}
                      placeholder={"Select Company"}
                      isSearchBox={true}
                      searchValue={selectedContactsearch}
                      renderOption={option => {
                        return <div>{option.company}</div>;
                      }}
                      renderValue={option => {
                        const renderVal = officeContacts?.find(o => `${o?.company}` === option);
                        return renderVal ? `${renderVal?.company}` : "";
                      }}
                      onSearchChange={(e, newValue) => {
                        setSelectContactSearch(newValue);
                      }}
                    />
                    {selectedContact ? (
                      <>
                        <p className="mt-2">Select method for sending final document:</p>
                        <div className="w-full sm:w-1/3 flex">
                          <label className="block w-auto sm:w-full mb-1 mr-4 sm:mr-0 relative">
                            <input
                              type={"radio"}
                              checked={selectedContactMethod === ContactMethodType.Email}
                              value={ContactMethodType.Email}
                              onChange={e => handleContactMethod(e.currentTarget.value as ContactMethodType)}
                              className="general-radio bg-transparent"
                            />
                            <span className="text-base ml-2">Email</span>
                          </label>
                          <label className="block w-auto sm:w-full mb-1 relative">
                            <input
                              type={"radio"}
                              checked={selectedContactMethod === ContactMethodType.Fax}
                              value={ContactMethodType.Fax}
                              onChange={e => handleContactMethod(e.currentTarget.value as ContactMethodType)}
                              className="general-radio bg-transparent"
                            />
                            <span className="text-base ml-2">Fax</span>
                          </label>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
                <div className="py-0.5 block sm:flex gap-6" key={inp.id}>
                  <div key={inp.id} className="w-full sm:w-1/2 ">
                    {renderInput(inp)}
                  </div>
                  {inp.child.map(ch => {
                    return (
                      <div key={ch.id} className="w-full sm:w-1/2">
                        {renderInput(ch)}
                      </div>
                    );
                  })}
                </div>
              </>
            );
          }
          return (
            <div key={inp.id} className={`${inp.visible ? "py-2" : ""} ${inp.width === "half" ? "w-full sm:w-1/2" : ""}`}>
              {renderInput(inp)}
            </div>
          );
        })}
        <div className="py-2">
          <span className="font-medium">Discount</span>
          <div className="block sm:flex flex-row mt-3">
            <div className="w-full sm:w-1/2">
              <TextInput
                onChangeFunc={e => handleDiscount("input", e.currentTarget.value)}
                value={discountValue}
                placeholder="Enter discount"
                onBlur={() => handleDiscountBlur()}
              />
            </div>
            <div className="w-full sm:w-1/2 flex flx-row sm:px-8 pt-2 sm:pt-0 items-center">
              <label className="block w-auto sm:w-full mb-1 mr-4 sm:mr-0 relative">
                <input
                  type={"radio"}
                  checked={discountType === RequestDiscountType.Percentage}
                  onChange={e => handleDiscount("%", e.currentTarget.checked)}
                  className="general-radio bg-transparent"
                />
                <span className="text-base ml-2">{"%"}</span>
              </label>
              <label className="block w-auto sm:w-full mb-1 relative">
                <input
                  type={"radio"}
                  checked={discountType === RequestDiscountType.Amount}
                  onChange={e => handleDiscount("$", e.currentTarget.checked)}
                  className="general-radio bg-transparent"
                />
                <span className="text-base ml-2">{"$"}</span>
              </label>
            </div>
          </div>
          {discountError ? <span className="text-red-500">Invalid discount amount</span> : null}
        </div>
        <div className="font-semibold py-2">Fees</div>
        <div className="py-2 pb-7">
          <div className="mb-2">
            {priceDetails?.fees?.length
              ? priceDetails?.fees.map((ele: InvoiceDetails, index: number) => {
                  return (
                    <div key={index} className="w-full flex justify-between py-2 border-b">
                      <div className="text-md font-medium">{ele.description}</div>
                      <div className="text-md font-medium">{formatCurrency((ele.amount / 100).toString(), "blue")}</div>
                    </div>
                  );
                })
              : null}
          </div>
          <button onClick={handleAddAdditionalSideBar} className="text-sm text-docsigna-blue-light font-medium">
            {priceDetails?.fees?.length ? "Add/Edit Fee" : `Add New Fee`}
          </button>
        </div>
        <div className="font-semibold py-2">Service Cost</div>
        <div className="mb-3">
          <div className="w-full flex justify-between py-2 border-b">
            <div className="text-md font-medium">Service</div>
            <div className="text-md font-medium">{formatCurrency(servicePrice.toString(), "blur")}</div>
          </div>
          {dscValue > 0 ? (
            <div className="w-full flex justify-between py-2 border-b">
              <div className="text-md font-bold">{dscDesc}</div>
              <div className="text-md font-bold">{formatCurrency(dscValue.toString(), "blur")}</div>
            </div>
          ) : null}
          {blockFeeDiscountAmount > 0 ? (
            <div className="w-full flex justify-between py-2 border-b">
              <div className="text-md font-bold">Block Fee: {blockFeeDiscountDesc}</div>
              <div className="text-md font-bold">{formatCurrency(blockFeeDiscountAmount.toString(), "blur")}</div>
            </div>
          ) : null}
          <div className="w-full flex justify-between py-2 border-b">
            <div className="text-md font-medium">Additional Fees</div>
            <div className="text-md font-medium">{formatCurrency(additionalFeeTotal.toString(), "blur")}</div>
          </div>
          <div className="w-full flex justify-between py-2 border-b">
            <div className="text-md font-medium">Subtotal</div>
            <div className="text-md font-medium">{formatCurrency(subTotal.toString(), "blur")}</div>
          </div>
          {dsFee > 0 ? (
            <div className="w-full flex justify-between py-2">
              <div className="text-md font-medium">{dsFeeDesc}</div>
              <div className="text-md font-medium">{formatCurrency(dsFee.toString(), "blur")}</div>
            </div>
          ) : null}
          {hstValue > 0 ? (
            <div className="w-full flex justify-between py-2 border-b">
              <div className="text-md font-medium">{hstDesc ? `${hstDesc} (${hstPercentage ? `${hstPercentage}%` : ""})` : "HST"}</div>
              <div className="text-md font-medium">{formatCurrency(hstValue.toString(), "blur")}</div>
            </div>
          ) : null}
          <div className="w-full flex justify-between py-2">
            <div className="text-md font-semibold">Total</div>
            <div className="text-md font-medium">{formatCurrency(total.toString(), "blur")}</div>
          </div>
        </div>
        {formType === NewRequestType.StandardOffice ||
        formType === NewRequestType.ThirdPartyOffice ||
        formType === NewRequestType.WSIBOffice ||
        formType === NewRequestType.ServiceCanadaOffice ? (
          <div className="py-2 mb-5">
            <p className="text-base mb-2">
              <span className="block text-base  font-bold">Assign Request</span>
            </p>
            <p className="text-base mb-2">Assign this request to internal staff and primary care provider.</p>

            <div className="block sm:flex items-center py-2">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <label className="text-sm font-medium block w-full mb-1">Staff</label>
                <select onChange={e => handleStaffChange(e.currentTarget.value)} value={officeStaff} className="general-select w-full">
                  {staff.length === 1 ? null : <option hidden>Select Staff</option>}
                  {staff.map((staf: any) => {
                    return (
                      <option value={staf.value} key={staf.value}>
                        {staf.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2 mt-2 sm:mt-0">
                <label className="text-sm font-medium  block w-full mb-1">Primary Care Provider</label>
                <select onChange={e => handleCareProvider(e.currentTarget.value)} value={careProvider} className="general-select w-full">
                  {currentOffice?.primaryCareProviders?.length === 1 ? null : <option>Select Care Provider</option>}
                  {currentOffice?.primaryCareProviders
                    ?.filter(care => care.userState === "active")
                    .map(care => {
                      return (
                        <option value={care.userId} key={care.userId}>
                          {`${care.firstName} ${care.lastName}`}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
        ) : null}
        {addRequestError ? (
          <p style={{ color: colors.Red }} className="text-base mb-5">
            {"Unable to create request at this time, please try again."}
          </p>
        ) : null}
        <div
          className="w-full sm:w-1/4 mt-5"
          onClick={() => {
            setErrorInitiated(true);
          }}>
          <Button
            onClickFunc={() => {
              if (validateForm() && !discountError) saveRequest();
            }}
            disabled={((!validateForm() || discountError) && errorInitiated) || fileLoading || (total > 0 && total < 1)}
            text="Submit Request"
            varient={pending || loading ? "loading" : "Primary"}
          />
        </div>
      </div>
      <UserSideBar
        onClickOutside={() => {
          setAddSideBarOpen(false);
        }}
        title="Add Fees"
        visible={addSideBarOpen}>
        <AddEditFee
          onCancelClick={(feeData?: UpdateFee[]) => {
            setAddSideBarOpen(false);
            setAdditionalFees(feeData);
          }}
          officeId={id ? id : null}
          requestId={requestData ? requestData.requestId : null}
          addedFee={additionalFees}
        />
      </UserSideBar>
      {searchedUserData?.userState === UserState.Active ? (
        <ConfirmationDialog
          maxWidth="sm"
          successButtonText=""
          failureButtonText=""
          open={true}
          hideActionButtons={false}
          description={getUserData()}
          handleSuccess={() => null}
          handleFailure={() => {
            setSearchedUserData(null);
          }}
          showCrossIcon={false}
        />
      ) : null}
      {showPatientInviteDialog ? (
        <ConfirmationDialog
          open={true}
          title=""
          loading={loading}
          description={`This patient does not have a Docnote account. They will be sent an invite to join the platform so they can view this request.`}
          successButtonText="Continue"
          failureButtonText="Cancel"
          handleSuccess={() => handleUserInvite()}
          handleFailure={() => setShowPatientInviteDialog(false)}
        />
      ) : null}
    </div>
  );
};

export default CreateRequestScreen;
