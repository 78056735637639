export enum ContactMethodType {
    Email = "email",
    Fax = "fax"
};
export type Contact = {
    contactId: string,
    officeId: string,
    firstName: string,
    lastName: string,
    company: string,
    street1: string,
    street2: string,
    city: string,
    province: string,
    postalCode: string,
    phoneNumber: string,
    email: string,
    fax: string,
    contactPreference: string
}

export type CreateContact = {
    officeId: string, 
    firstName: string,
    lastName: string, 
    company: string, 
    street1?: string, 
    street2?: string, 
    city?: string, 
    province?: string, 
    postalCode?: string, 
    phoneNumber?: string, 
    email?: string, 
    fax?: string,
    contactPreference?: string 
}

export type Address = {
    street1?: string, 
    street2?: string, 
    city?: string, 
    province?: string, 
    postalCode?: string, 
}