import { useEffect, useState } from "react";
import { Button, TextInput } from "../../components";
import { colors } from "../../utils/constants";
import { loadStripe } from "@stripe/stripe-js";
import { createPaymentIntent } from "../../redux/slices/PaymentSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";

const SubscriptionOptions = [
  {
    id: "1",
    name: "Single",
    price: 10,
    type: "monthly",
  },
  {
    id: "2",
    name: "Couple",
    price: 15,
    type: "monthly",
  },
  {
    id: "3",
    name: "Family",
    price: 28,
    type: "monthly",
  },
  {
    id: "4",
    name: "Senior",
    price: 8,
    type: "monthly",
  },
  {
    id: "5",
    name: "Senior Couple",
    price: 12,
    type: "monthly",
  },
];

const PatientSubscriptionForm = ({ handleSubmitPayment }: { handleSubmitPayment: (val: boolean) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const [text, setText] = useState("");

  const { paymentIntent, cancelled, error } = useSelector((state: RootState) => state.payment);

  const handleChangeSubscription = (id: string) => {
    setSelectedSubscription(id);
  };

  const stripePromise = process.env.REACT_APP_STRAPI_PUBLISHABLE_KEY ? loadStripe(process.env.REACT_APP_STRAPI_PUBLISHABLE_KEY) : null;

  useEffect(() => {
    dispatch(createPaymentIntent({ requestId: "70ac06e7-d833-4e24-859f-5215b816544a" }));
  }, []);

  return (
    <div className="px-8 border-t">
      <div className="flex">
        <div className={`w-1/2 relative text-center overflow-hidden pb-12`}>
          <span className="status-number" />
          <span className="status-title">Select Subscription</span>
        </div>
        <div className={`w-1/2 relative text-center overflow-hidden pb-12 ${activeStep !== 2 ? "incomplete-step" : ""}`}>
          <span className="status-number" />
          <span className="status-title">Payment</span>
        </div>
      </div>
      {activeStep === 1 ? (
        <>
          <div className="mt-2">
            <p>
              The following subscription options are offered by your doctor’s office. Please select your preferred option and click Proceed To Payment
              to subscribe.
            </p>
            <div className="flex flex-col gap-4 my-4">
              {SubscriptionOptions.map(item => {
                return (
                  <div key={item.id}>
                    <label>
                      <input
                        type={"radio"}
                        checked={selectedSubscription === item.id}
                        onChange={e => handleChangeSubscription(item.id)}
                        className="general-radio bg-transparent"
                      />
                      <span className="text-base ml-2">{`${item.name} ($${item.price}/month)`}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            <p>
              Please enter the names and birthdates of the additional patients that will be using this subscription (only registered patients of this
              doctor that live at the same address can be included):
            </p>
            <TextInput
              isTextArea
              value={text}
              onChangeFunc={e => setText(e.target.value)}
              placeholder={""}
              isError={false}
              errorMsg={""}
              label={""}
              isRequired={false}
              onBlur={e => null}
              isDarkBg={false}
              extraInputClass="mt-5 resize-none bg-white h-32"
            />
          </div>
          <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light border-t">
            <div className="px-8 py-5 flex justify-end">
              <Button
                onClickFunc={() => handleSubmitPayment(false)}
                AdditionalClassNames="pointer px-5 mr-3"
                text="Cancel"
                width="fit"
                varient="Secondary"
              />
              <Button
                onClickFunc={() => setActiveStep(2)}
                disabled={!selectedSubscription}
                AdditionalClassNames="pointer px-5"
                text="Proceed To Payment"
                width="fit"
              />
            </div>
          </div>
        </>
      ) : null}
      {activeStep === 2 ? (
        <>
          <div className="mt-2">
            <p>
              This subscription will be charged monthly to the below credit card. You can change or edit your subscription and payment methods at any
              time.
            </p>
            <div className="flex flex-col gap-4 my-4">
              <div className="grid grid-cols-2">
                <p>Subscription:</p>
                <p>Couple</p>
              </div>
              <div className="grid grid-cols-2">
                <p>Monthly Fee:</p>
                <p>$15.00</p>
              </div>
              <div className="grid grid-cols-2">
                <p>HST:</p>
                <p>$15.00</p>
              </div>
              <div className="grid grid-cols-2">
                <p className="font-bold">Total Monthly Fee:</p>
                <p>$15.00</p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="w-full ">{cancelled ? <p>Payment has been cancelled.</p> : null}</div>
              <div className="w-full">{error ? <p style={{ color: colors.Red }}>An error occurred, please try again.</p> : null}</div>
              <div className="w-full">
                {paymentIntent !== null && !cancelled ? (
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret: paymentIntent.clientSecret,
                      appearance: {
                        theme: "stripe",
                        variables: {
                          spacingGridRow: "0",
                          spacingGridColumn: "0",
                          spacingUnit: "0",
                          borderRadius: "0",
                        },
                        rules: {
                          ".Grid": {
                            marginBottom: "0px",
                          },
                          ".Label": {
                            fontSize: "0px",
                            lineHeight: "0px",
                            marginBottom: "0px",
                          },
                          ".p-CardNumberInput-input": {
                            borderTop: "1px",
                            borderTopLeftRadius: "8px",
                          },
                          "#Field-numberInput": {
                            backgroundColor: "red",
                          },
                          ".Input": {
                            color: colors.DarkBlue,
                            fontSize: "16px",
                            fontWeight: "500",
                            margin: "10px 0 0 0",
                            backgroundColor: "#fff",
                            outline: "none",
                            boxShadow: "none",
                            borderColor: colors.DarkBlue,
                          },
                          ".Input:focus": {
                            outline: "none",
                            boxShadow: "none",
                          },
                          ".Error": {
                            color: "red",
                          },
                          "p-BillingAddressForm": {
                            marginTop: "20px",
                          },
                        },
                      },
                    }}>
                    <p className="py-2 text-semibold">
                      <b>Credit Card Information</b>
                    </p>
                    <PaymentElement className="stripe-payment-element" id={"Payment-Form-Stripe"} />
                  </Elements>
                ) : null}
              </div>
            </div>
          </div>
          <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light border-t">
            <div className="px-8 py-5 flex justify-end">
              <Button
                onClickFunc={() => handleSubmitPayment(false)}
                AdditionalClassNames="pointer px-5 mr-3"
                text="Cancel"
                width="fit"
                varient="Secondary"
              />
              <div>
                <Button
                  onClickFunc={() => handleSubmitPayment(true)}
                  disabled={!selectedSubscription}
                  AdditionalClassNames="pointer px-5"
                  text="Pay Now"
                  width="fit"
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PatientSubscriptionForm;
