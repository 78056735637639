import { headersType } from "../../models";

export const headers: headersType[] = [
  {
    title: "Company",
    extraClasses: "w-full lg:w-200 pl-0 truncate",
    sortKey: "company",
    isClickable: true,
  },
  {
    title: "Name",
    extraClasses: "w-full lg:w-200 hidden lg:block truncate",
    sortKey: "firstName",
    isClickable: true,
  },
  {
    title: "Address",
    extraClasses: "w-full hidden lg:block truncate",
    sortKey: "street1",
    isClickable: true,
  },
  {
    title: "Email",
    extraClasses: "w-full hidden lg:block truncate",
    sortKey: "email",
    isClickable: true,
  },
  {
    title: "Fax",
    extraClasses: "w-full lg:w-200 hidden lg:block truncate",
    sortKey: "fax",
    isClickable: true,
  },
  {
    title: "",
    extraClasses: "w-40 ",
    sortKey: "",
    isClickable: false,
  },
];

export const contactsSampleData = [{
    id: "1",
    name: "Carol Beer",
    company: "Silverbirch Studios",
    address: "25 The West Mall, Toronto, ON, M9C 1B8, CA",
    email: "carolbeer13@gmail.com",
    fax: "555-555-5555"
},
{
    id: "2",
    name: "Kevin Birch",
    company: "Silverbirch Studios",
    address: "25 The West Mall, Toronto, ON, M9C 1B8, CA",
    email: "carolbeer13@gmail.com",
    fax: "555-555-5555"
},
{
    id: "3",
    name: "Nitin singh",
    company: "Silverbirch Studios",
    address: "25 The West Mall, Toronto, ON, M9C 1B8, CA",
    email: "carolbeer13@gmail.com",
    fax: "555-555-5555"
},
{
    id: "4",
    name: "Antonio Duran",
    company: "Silverbirch Studios",
    address: "25 The West Mall, Toronto, ON, M9C 1B8, CA",
    email: "carolbeer13@gmail.com",
    fax: "555-555-5555"
}]