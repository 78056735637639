import { useContext, useEffect, useState } from "react";
import { Checkbox, ConfirmationDialog, RenderName, TableHeader, TableRow } from "../../components";
import { ToastVariants, headersType } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import { editBlockFee, getOfficeBlockFees } from "../../redux/slices/BlockFeeSlice";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../redux/store";
import { BlockFee } from "../../models/block-fee.model";
import { AuthPermission, authService } from "../../services/auth.service";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { BlockFeeService } from "../../services/blockFee.service";

export const headers: headersType[] = [
  {
    title: "Plan Name",
    extraClasses: "w-full pl-0 break-all",
    sortKey: "planName",
    isClickable: false,
  },
  {
    title: "Payment Frequency",
    extraClasses: "w-full hidden lg:block",
    sortKey: "frequency",
    isClickable: false,
  },
  {
    title: "Rate",
    extraClasses: "w-120 hidden lg:block",
    sortKey: "rate",
    isClickable: false,
  },
  {
    title: "# of Users",
    extraClasses: "w-120 hidden lg:block",
    sortKey: "numUsers",
    isClickable: false,
  },
  {
    title: "Enable",
    extraClasses: "w-120 hidden lg:block",
    sortKey: "status",
    isClickable: false,
  },
];

const Plans = ({ setEditPlan }: { setEditPlan: (plan: BlockFee) => void }) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const permissions = authService?.currentUserPermissions;
  const { id } = useParams();
  const [showTooltip, setShowTooltip] = useState(-1);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<BlockFee | null>(null);
  const [extraClasses] = useState<string[]>(headers.map(head => head.extraClasses));
  const { plans } = useSelector((state: RootState) => state.blockFee);

  const handleChange = (val: boolean, plan: BlockFee) => {
    dispatch(
      editBlockFee({
        officeId: plan?.officeId,
        blockFeeId: plan?.blockFeeId,
        name: plan?.name,
        price: plan?.price,
        subscriptionType: plan?.subscriptionType,
        enabled: val,
      })
    );
  };

  const handleTooltipClick = (type: string, id: string) => {
    const plan = plans.find((item: BlockFee) => item.blockFeeId === id);
    switch (type) {
      case "Edit plan":
        plan ? setEditPlan(plan) : null;
        break;
      case "Remove Plan":
        if (plan?.numUsers) {
          toast?.openToast("Plan cannot be deleted when patients are attached.");
        } else {
          if (plan) {
            setShowDeleteConfirmationDialog(true);
            setSelectedPlan(plan);
          }
        }
        break;
      default:
        toast?.openToast();
        break;
    }
  };

  const removePlan = async () => {
    if (selectedPlan && id) {
      const resp = await BlockFeeService.removeBlockFee(id, selectedPlan?.blockFeeId);
      if (resp) {
        setSelectedPlan(null);
        setShowDeleteConfirmationDialog(false);
        await dispatch(getOfficeBlockFees({ officeId: id }));
        toast?.openToast("Plan removed successfully!", 2000, ToastVariants.Success);
      } else {
        toast?.openToast("Removal of the plan failed", 2000, ToastVariants.Error);
      }
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getOfficeBlockFees({ officeId: id }));
    }
  }, [dispatch, id]);

  return (
    <div className="w-full relative">
      <div className="flex justify-between items-center border-b border-docsigna-blue-light">
        {headers.map((head, index) => (
          <TableHeader
            title={head.title}
            index={index}
            key={index}
            sortIcon={null}
            handleSort={() => null}
            extraClassesHeader={head.extraClasses}
            isClickable={head.isClickable}
          />
        ))}
        <div className="w-40 p-5 py-3">
          <span className="text-base text-docsigna-purple-dark inline-block w-5"></span>
        </div>
      </div>
      {plans?.map((rowData, index) => {
        const sortedData = {
          name: <RenderName extraClasses="capitalize" title={`${rowData.name}`} subTitle={""} />,
          frequency: <RenderName extraClasses="capitalize" title={rowData.subscriptionType} subTitle={""} />,
          rate: `$${rowData.price}`,
          numUsers: rowData?.numUsers ?? 0,
          status: (
            <div key={index} className={`${extraClasses}`}>
              <Checkbox label="" checked={rowData.enabled} onChange={e => handleChange(e.target.checked, rowData)} />
            </div>
          ),
        };
        return (
          <TableRow
            data={Object.values(sortedData)}
            key={index}
            id={rowData.blockFeeId}
            tooltipVisible={showTooltip === index && permissions?.includes(AuthPermission.Office)}
            extraClassesRow={extraClasses}
            handleOpenTooltip={() => setShowTooltip(index)}
            handleCloseTooltip={() => setShowTooltip(-1)}
            tooltipOptions={["Edit plan", "Remove Plan"]}
            handleTooltipClick={handleTooltipClick}
            onRowClick={() => null}
          />
        );
      })}
      {showDeleteConfirmationDialog ? (
        <ConfirmationDialog
          open={true}
          description={`Are you sure you want to remove this plan?  This action cannot be undone.`}
          title={""}
          successButtonText={"Remove"}
          handleSuccess={removePlan}
          failureButtonText={"Cancel"}
          handleFailure={() => {
            setShowDeleteConfirmationDialog(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default Plans;
