import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiError, ApiErrorCode } from "../../models/api-error.model";
import { ApiPage } from "../../models/api-page.model";
import { CreateOffice } from "../../models/create-office.model";
import { Office, OfficeState } from "../../models/office.model";
import { UpdateOfficeBilling } from "../../models/update-office-billing.model";
import { UpdateOffice } from "../../models/update-office.model";
import { UserInfo } from "../../models/user-info.model";
import { User } from "../../models/user.model";
import { AuthPermission } from "../../services/auth.service";
import { OfficeService } from "../../services/office.service";
import { InviteUserType } from "./UserSlice";
import { logger } from "../../utils/logger";
import { AllOfficeObj } from "../../utils/constants";
import { Service } from "../../models/service.model";

const log = logger.getLogger('OfficeErrors');

interface OfficeDataForRender extends Office {
  tooltipVisible: boolean;
}

type officeList<T> = {
  sortedBy?: string;
  data: ApiPage<T> | null;
  page?: number;
  size?: number;
  sortType?: string;
};

export interface sortObj {
  start?: number;
  end?: number;
  pageNumber: number;
  rowsPerPage: number;
  sortedBy: string;
  searchTerm?: string,
  totalElements?: number
}

export interface OfficeStateType {
  error: boolean;
  errorType: ApiErrorCode;
  loading: boolean;
  apiSucess: boolean;
  officeList: officeList<OfficeDataForRender>;
  sort: sortObj;
  currentOffice: Office | null;
  pageLoading: boolean;
  userWithPermission: officeList<User> | null;
  loadingOffice: boolean;
  primaryCareProvider: UserInfo[] | null;
  resendInviteStatus: "Unable to resend invitation" | "User invitation sent" | null;
  inviteUserApiSuccess: boolean;
  patientList: PatientsList;
  previousSelectedOfficeId: string;
  selectedOffice: Office | null;
  subscribers: User[],
  subscribersSortObj: sortObj,
  uninsuredServices: Service[],

}
export interface PatientDataForRender extends User {
  tooltipVisible: boolean;
}
type listOfficeResponse = { data: ApiPage<Office>; sortType: string; page: number; size: number };
type listPatientUsersResponse = { data: ApiPage<User>; sortType: string; term?: string };
type PatientsList = {
  sortedBy: string;
  data: ApiPage<PatientDataForRender> | null;
};

interface ListOfficeType {
  searchTerm?: string;
  page: number;
  size: number;
  sortType: string;
}

interface UserWithPermissionType {
  officeId: string;
  permission: AuthPermission;
  page: number;
  size: number;
  sort: string;
}

const initialState: OfficeStateType = {
  loading: false,
  error: false,
  errorType: ApiErrorCode.UNKNOWN,
  apiSucess: false,
  officeList: { data: null, sortedBy: "" },
  sort: {
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "name,asc",
  },
  currentOffice: null,
  userWithPermission: null,
  pageLoading: false,
  loadingOffice: false,
  primaryCareProvider: null,
  resendInviteStatus: null,
  inviteUserApiSuccess: false,
  patientList: { data: null, sortedBy: "" },
  previousSelectedOfficeId: "",
  selectedOffice: AllOfficeObj,
  subscribers: [],
  subscribersSortObj: {
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "lastName,desc",
    totalElements: 0,
  },
  uninsuredServices: []
};

export const addOffice = createAsyncThunk(
  "officeApi/add",
  async ({ name, street, city, state, zip, country, phone, fax, contactName, contactEmail }: CreateOffice, { rejectWithValue }) => {
    try {
      const res = await OfficeService.addOffice({ name, street, city, state, zip, country, phone, fax, contactName, contactEmail });
      return res;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);
export const updateOffice = createAsyncThunk(
  "officeApi/update",
  async (data: UpdateOffice, { rejectWithValue }) => {
    try {
      const res = await OfficeService.updateOffice(data);
      return res;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);
export const updateOfficeBilling = createAsyncThunk(
  "officeApi/updateOfficeBilling",
  async (
    {
      officeId,
      billingName,
      billingStreet,
      billingCity,
      billingState,
      billingZip,
      billingCountry,
      taxEnabled,
      taxNumber,
      wsibNumber,
      bankAccount,
      bankInstitution,
      bankTransit,
      billingStreet2: street2
    }: UpdateOfficeBilling,
    { rejectWithValue }
  ) => {
    try {
      const res = await OfficeService.updateOfficeBilling({
        officeId,
        billingName,
        billingStreet,
        billingCity,
        billingState,
        billingZip,
        billingCountry,
        taxEnabled,
        taxNumber,
        wsibNumber,
        bankAccount,
        bankInstitution,
        bankTransit,
        billingStreet2: street2
      });
      return res;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);
export const setOfficeAcceptedTerms = createAsyncThunk(
  "ServiceApi/setOfficeAcceptedTerms",
  async ({ officeId, acceptedTerms }: { officeId: string; acceptedTerms: boolean }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.setOfficeAcceptedTerms(officeId, acceptedTerms);
      return res;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);
export const setOfficePublished = createAsyncThunk(
  "ServiceApi/setOfficePublished",
  async ({ officeId, published }: { officeId: string; published: OfficeState }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.setOfficePublished(officeId, published);
      return res;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const listOfficeData = createAsyncThunk("officeApi/list", async ({ page, sortType, size }: ListOfficeType, { rejectWithValue, dispatch }) => {
  try {
    const res = await OfficeService.getAllOffices(page, size, sortType.length > 0 ? sortType : undefined);
    dispatch(setOfficeTableState({ sortType: sortType, page, size }));
    return { data: res, sortType: sortType, page, size };
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});

export const listOfficeDataForSelectList = createAsyncThunk(
  "officeApi/listForSelect ",
  async ({ page, sortType, size }: ListOfficeType, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getAllOffices(page, size, sortType.length > 0 ? sortType : undefined);
      return { data: res, sortType: sortType, page, size };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchOffice = createAsyncThunk(
  "officeApi/search",
  async ({ searchTerm, page, sortType, size }: ListOfficeType, { rejectWithValue, dispatch }) => {
    try {
      const term = searchTerm === undefined ? "" : searchTerm;
      const res = await OfficeService.searchOffice(term, page, size, sortType);
      dispatch(setOfficeTableState({ sortType, page, size }));
      return { data: res, sortType: sortType, page, size };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getOffice = createAsyncThunk("officeApi/getOffice", async ({ officeId }: { officeId: string }, { rejectWithValue }) => {
  try {
    const res = await OfficeService.getOffice(officeId);
    return res;
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});

// TODO: Do not use generic names for specific functions. This functions is specific to officeApi
export const setOfficeTableState = createAsyncThunk("officeApi/sortData", async ({ page, sortType, size }: ListOfficeType, { rejectWithValue }) => {
  try {
    return { sortType, page, size };
  } catch (e) {
    log.warn(JSON.stringify(e));
    return rejectWithValue(null);
  }
});

export const getOfficeUsersWithPermission = createAsyncThunk(
  "officeApi/getOfficeUsersWithPermissions",
  async ({ officeId, permission, page, sort, size }: UserWithPermissionType, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeUsersWithPermission(officeId, permission, page, size, sort);
      return { data: res, sortType: sort, page, size };
    } catch (e) {
      log.warn(JSON.stringify(e));
      rejectWithValue(null);
      return null;
    }
  }
);

export const getOfficeCareProviders = createAsyncThunk(
  "officeApi/getOfficeCareProviders",
  async ({ officeId }: { officeId: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeCareProviders(officeId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const inviteOfficeUser = createAsyncThunk(
  "officeApi/inviteOfficeUser",
  async (
    { firstName, lastName, email, selectedPermissions, primaryCareProvider, officeId, userType, phone, jobTitleId }: InviteUserType,
    { rejectWithValue }
  ) => {
    try {
      if (officeId) {
        const res = await OfficeService.inviteOfficeUser(
          firstName,
          lastName,
          email,
          jobTitleId,
          userType,
          selectedPermissions,
          primaryCareProvider,
          officeId,
          phone
        );
        return res;
      } else {
        return null;
      }
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        log.warn(JSON.stringify(e));
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const cancelOfficeUserInvitation = createAsyncThunk(
  "userApi/cancelOfficeUserInvitation",
  async ({ officeId, userId }: { officeId: string; userId: string }, { rejectWithValue }) => {
    try {
      const response = await OfficeService.cancelOfficeUserInvitation(officeId, userId);
      return response;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const cancelOfficePatientInvitation = createAsyncThunk(
  "userApi/cancelOfficePatientInvitation",
  async ({ officeId, userId }: { officeId: string; userId: string }, { rejectWithValue }) => {
    try {
      const response = await OfficeService.cancelOfficePatientInvitation(officeId, userId);
      return response;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const resendOfficeUserInvitation = createAsyncThunk(
  "userApi/resendOfficeUserInvitation",
  async ({ officeId, userId }: { officeId: string; userId: string }, { rejectWithValue }) => {
    try {
      const response = await OfficeService.resendOfficeUserInvitation(officeId, userId);
      return response;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const resendOfficePatientInvitation = createAsyncThunk(
  "userApi/resendOfficePatientInvitation",
  async ({ officeId, userId }: { officeId: string; userId: string }, { rejectWithValue }) => {
    try {
      const response = await OfficeService.resendOfficePatientInvitation(officeId, userId);
      return response;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getOfficePatients = createAsyncThunk(
  "userApi/getOfficePatients",
  async ({ officeId, page, size, sort }: { officeId: string; page: number; size: number; sort: string; }, { rejectWithValue, dispatch }) => {
    try {
      if (officeId) {
        dispatch(setPreviousSelectedOfficeId(officeId));
        const response = await OfficeService.getOfficePatients(officeId, page, size, sort);
        return { data: response, sortType: sort };
      } else {
        return rejectWithValue(null);
      }
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchOfficePatients = createAsyncThunk(
  "userApi/searchOfficePatients",
  async ({ officeId, page, size, sort, term, userState }: { officeId: string; term: string; page: number; size: number; sort: string; userState?:string }, { rejectWithValue }) => {
    try {
      const response = await OfficeService.searchOfficePatients(officeId, term, page, size, sort, userState);
      return { data: response, sortType: sort };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchBlockFeeSubscribers = createAsyncThunk(
  "userApi/searchBlockFeeSubscribers",
  async ({ officeId, page, size, sort, term }: { officeId: string; term?: string; page: number; size: number; sort: string; }, { rejectWithValue }) => {
    try {
      const response = await OfficeService.searchOfficePatients(officeId, term, page, size, sort, undefined, true);
      return { data: response, sortType: sort, term };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const enableOfficeService = createAsyncThunk(
  "officeApi/enableService",
  async ({ state, officeId, serviceId }: {state:boolean; officeId: string; serviceId:string;}, { rejectWithValue }) => {
    try {
      const res = await OfficeService.setOfficeServiceEnabled(officeId, serviceId, state);
      return res;
    } catch (e) {
      if ((e as ApiError).code === ApiErrorCode.EXISTS) {
        return rejectWithValue(ApiErrorCode.EXISTS);
      } else {
        return rejectWithValue(ApiErrorCode.UNKNOWN);
      }
    }
  }
);

export const submitSupportForm = createAsyncThunk(
  "officeApi/submitSupportForm",
  async ({ officeId, message, file }: {message:string; officeId: string; file?:File;}, { rejectWithValue }) => {
    try {
      const res = await OfficeService.submitSupportForm(officeId, message, file ?? undefined);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const invitePatient = createAsyncThunk(
  "officeApi/invitePatient",
  async ({ officeId, email, note }: {officeId:string; email:string; note:string;}, _) => {
    try {
      await OfficeService.invitePatient(officeId, email, note);
      return 'success';
    } catch (e:any) {
      log.warn(JSON.stringify(e));
      if(e?.code === 4) {
        return 'duplicate';
      } else {
        return 'fail';
      }
    }
  }
);

export const acceptPatientInvitation = createAsyncThunk(
  "officeApi/acceptPatientInvitation",
  async ({ officeId, email, phone, token, firstName, lastName, dateOfBirth, ohipNumber, password }: {officeId:string; email:string; phone:string; token:string; firstName: string; lastName:string;dateOfBirth:string;ohipNumber?:string; password:string}, {rejectWithValue}) => {
    try {
      const res = await OfficeService.acceptPatientInvitation(officeId, token, email, phone, password, firstName, lastName, dateOfBirth, ohipNumber)
      return res;
    } catch (e:any) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const getOfficeUninsuredServices = createAsyncThunk(
  "officeApi/getOfficeUninsuredServices",
  async ({ officeId, page, size, sort }: {officeId:string; page?: number; size?: number; sort?: string; }, {rejectWithValue}) => {
    try {
      const res = await OfficeService.getOfficeUninsuredServices(officeId, page, size, sort)
      return res.content;
    } catch (e:any) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const OfficeSlice = createSlice({
  name: "officeApi",
  initialState,
  reducers: {
    setPreviousSelectedOfficeId: (state, action) => {
      state.previousSelectedOfficeId = action.payload;
    },
    listOffices: state => {
      state.error = false;
      state.errorType = ApiErrorCode.UNKNOWN;
      state.loading = false;
      state.apiSucess = false;
    },
    openTooltipOffice: (state, action: PayloadAction<number>) => {
      if (state.officeList.data)
        state.officeList.data.content = state.officeList.data?.content.map((d, i) => ({ ...d, tooltipVisible: i === action.payload }));
    },
    closeTooltipOffice: state => {
      if (state.officeList.data) state.officeList.data.content = state.officeList.data?.content.map(d => ({ ...d, tooltipVisible: false }));
    },
    resetStateOfficeSlice: () => initialState,
    resetInviteUserApiSuccess: state => {
      state.inviteUserApiSuccess = false;
    },
    clearPatientsList: state => {
      state.patientList = { data: null, sortedBy: "" };
    },
    setSelectedOffice: (state,action) => {
      state.selectedOffice = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(addOffice.pending, state => {
        state.error = false;
        state.apiSucess = false;
      })
      .addCase(addOffice.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload !== null) {
          state.apiSucess = true;
        } else {
          state.apiSucess = false;
        }
        state.loading = false;
        state.error = false;
      })
      .addCase(addOffice.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.errorType = action.payload;
      })
      .addCase(listOfficeData.pending, state => {
        if (state.officeList.data === null) {
          state.loading = true;
          state.pageLoading = true;
        }
        state.error = false;
      })
      .addCase(listOfficeData.fulfilled, (state, action: PayloadAction<listOfficeResponse>) => {
        state.loading = false;
        state.error = false;
        state.officeList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        const { sortType } = action.payload;
        state.sort.pageNumber = action.payload.page;
        state.officeList.sortedBy = sortType;
        state.pageLoading = false;
      })
      .addCase(listOfficeData.rejected, state => {
        state.error = true;
        state.loading = false;
        state.pageLoading = false;
      })
      .addCase(listOfficeDataForSelectList.pending, state => {
        if (state.officeList.data === null) {
          state.loading = true;
        }
        state.error = false;
      })
      .addCase(listOfficeDataForSelectList.fulfilled, (state, action: PayloadAction<listOfficeResponse>) => {
        state.loading = false;
        state.error = false;
        state.officeList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        const { sortType } = action.payload;
        state.sort.pageNumber = action.payload.page;
        state.officeList.sortedBy = sortType;
      })
      .addCase(listOfficeDataForSelectList.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(searchOffice.pending, state => {
        state.error = false;
      })
      .addCase(searchOffice.fulfilled, (state, action: PayloadAction<listOfficeResponse>) => {
        state.error = false;
        state.officeList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        const { sortType } = action.payload;
        state.officeList.sortedBy = sortType;
        state.sort.pageNumber = action.payload.page;
      })
      .addCase(searchOffice.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(setOfficeTableState.fulfilled, (state, action: PayloadAction<ListOfficeType>) => {
        state.loading = false;
        state.error = false;
        const { sortType, page, size } = action.payload;
        const obj = {
          pageNumber: page,
          rowsPerPage: size,
          sortedBy: sortType,
        };
        state.sort = { ...state.sort, ...obj };
      })
      .addCase(getOffice.fulfilled, (state, action: PayloadAction<Office | null>) => {
        state.loading = false;
        state.error = false;
        state.loadingOffice = false;
        state.currentOffice = action.payload;
      })
      .addCase(getOffice.rejected, state => {
        state.error = true;
        state.loading = false;
        state.loadingOffice = false;
      })
      .addCase(getOffice.pending, state => {
        state.loading = true;
        state.loadingOffice = true;
        state.error = false;
      })

      .addCase(getOfficeUsersWithPermission.fulfilled, (state, action: PayloadAction<officeList<User> | null>) => {
        state.loading = false;
        state.error = false;
        state.userWithPermission = action.payload;
      })
      .addCase(getOfficeUsersWithPermission.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getOfficeUsersWithPermission.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOfficeCareProviders.fulfilled, (state, action: PayloadAction<UserInfo[] | null>) => {
        state.loading = false;
        state.error = false;
        state.primaryCareProvider = action.payload;
      })
      .addCase(getOfficeCareProviders.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getOfficeCareProviders.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(inviteOfficeUser.fulfilled, state => {
        state.loading = false;
        state.error = false;
        state.inviteUserApiSuccess = true;
      })
      .addCase(inviteOfficeUser.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.loading = false;
        state.errorType = action.payload;
      })
      .addCase(inviteOfficeUser.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(cancelOfficeUserInvitation.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(cancelOfficeUserInvitation.fulfilled, state => {
        state.loading = false;
        state.error = false;
      })
      .addCase(cancelOfficeUserInvitation.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(searchOfficePatients.pending, state => {
        state.error = false;
      })
      .addCase(searchOfficePatients.fulfilled, (state, action: PayloadAction<listPatientUsersResponse>) => {
        state.error = false;
        state.patientList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        state.patientList.sortedBy = action.payload.sortType;
        state.loading = false;
      })
      .addCase(searchOfficePatients.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(searchBlockFeeSubscribers.pending, state => {
        state.error = false;
      })
      .addCase(searchBlockFeeSubscribers.fulfilled, (state, action: PayloadAction<listPatientUsersResponse>) => {
        state.error = false;
        const {data, sortType, term} = action.payload;
        state.subscribers = data.content;
        state.subscribersSortObj = {
          ...state.subscribersSortObj,
          sortedBy: sortType,
          searchTerm: term,
          pageNumber: data.number,
          rowsPerPage: data.size,
          totalElements: data.totalElements
        };
      })
      .addCase(searchBlockFeeSubscribers.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getOfficePatients.pending, state => {
        if (state.patientList.data === null) {
          state.loading = true;
          state.pageLoading = true;
        }
        state.error = false;
      })
      .addCase(getOfficePatients.fulfilled, (state, action: PayloadAction<listPatientUsersResponse>) => {
        state.loading = false;
        state.error = false;
        state.patientList.data = { ...action.payload.data, content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })) };
        state.patientList.sortedBy = action.payload.sortType;
        state.pageLoading = false;
      })
      .addCase(getOfficePatients.rejected, state => {
        state.error = true;
        state.loading = false;
        state.pageLoading = false;
      })
      .addCase(updateOffice.pending, state => {
        state.loading = true;
      })
      .addCase(updateOffice.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateOffice.rejected, state => {
        state.loading = false;
      })
      .addCase(getOfficeUninsuredServices.pending, state => {
        state.loading = true;
      })
      .addCase(getOfficeUninsuredServices.fulfilled, (state, action: PayloadAction<Service[]>) => {
        state.loading = false;
        state.uninsuredServices = action.payload;
      })
      .addCase(getOfficeUninsuredServices.rejected, state => {
        state.loading = false;
      })
  },
});

export const {
  listOffices,
  closeTooltipOffice,
  openTooltipOffice,
  resetStateOfficeSlice,
  resetInviteUserApiSuccess,
  setPreviousSelectedOfficeId,
  clearPatientsList,
  setSelectedOffice
} = OfficeSlice.actions;

export default OfficeSlice.reducer;
