const ReportFooter = () => {
  return (
    <div className="absolute bottom-0 w-full">
      <p className="text-xs text-center text-docsigna-purple-dark">481 London Road, Unit 109, Sarnia, ON N7T 4X3 HST # 703533687 RT 0001</p>
      <p className=" text-xs text-center text-docsigna-purple-dark mb-5">
        This service has been provided by Bluewater Promotions Inc., operating as Docnote
      </p>
    </div>
  );
};

export default ReportFooter;
