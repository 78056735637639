import React from "react";

interface CheckboxPropType {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isDarkBg?: boolean;
}

const Checkbox = ({ label, checked = false, disabled = false, onChange = undefined, name = "", id = "", isDarkBg = false }: CheckboxPropType) => {
  return (
    <div className="flex justify-start items-center cursor-pointer">
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        name={name}
        id={id}
        disabled={disabled}
        style={disabled ? { cursor: "not-allowed" } : {}}
        className={`cursor-pointer rounded shadow-none border-docsigna-blue-dark ${isDarkBg ? "bg-docsigna-pink-light" : ""}`}
      />
      <label htmlFor={id} className="cursor-pointer text-base ml-2" style={disabled ? { cursor: "not-allowed" } : {}}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
