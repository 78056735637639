import { ApiError, ApiErrorCode } from "../models/api-error.model";
import { ApiPage, emptyApiPage } from "../models/api-page.model";
import { CreateOffice } from "../models/create-office.model";
import { Document } from "../models/document.model";
import { DsFile, RequestFileTypes } from "../models/ds-file.model";
import { AccountReceivablesTotals, Finance, FinanceRequest, Payee, TopOverdueAccounts } from "../models/finance.model";
import { InvitationResponse } from "../models/invitation-response.model";
import { Office, OfficeState } from "../models/office.model";
import { PriceDetails } from "../models/price-details.model";
import { Receipt } from "../models/receipt.model";
import { RequestCounts } from "../models/request-counts.model";
import { RequestFile } from "../models/request-file.model";
import { RequestMessage } from "../models/request-message.model";
import { RequestPatientUpdate } from "../models/request-patient-update.model";
import { ArchiveState, Request, RequestPaymentMethod, RequestState } from "../models/request.model";
import { Service } from "../models/service.model";
import { Timeline } from "../models/timeline.model";
import { UpdateFee } from "../models/update-fee.model";
import { UpdateOfficeBilling } from "../models/update-office-billing.model";
import { UpdateOffice } from "../models/update-office.model";
import { UpdateRequest } from "../models/update-request.model";
import { UserInfo } from "../models/user-info.model";
import { UserUpdate } from "../models/user-update.model";
import { User } from "../models/user.model";
import { ApiPatchOp, apiService } from "./api.service";
import { AuthPermission } from "./auth.service";
import { UserType } from "./user.service";

export class OfficeService {
  private static BASE_PATH = "/api/v1/offices";

  static async getAllOffices(page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Office>> {
    const response = await apiService.get(`${this.BASE_PATH}?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<Office>();

    return response.data as ApiPage<Office>;
  }

  static async searchOffice(term: string, page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Office>> {
    const response = await apiService.post(`${this.BASE_PATH}/search?page=${page}&size=${size}&sort=${sort}`, {
      term: term,
    });
    if (!response.success) return emptyApiPage<Office>();

    return response.data as ApiPage<Office>;
  }

  static async lookupOffice(term: string, page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Office>> {
    const response = await apiService.post(`${this.BASE_PATH}/lookup?page=${page}&size=${size}&sort=${sort}`, {
      term: term,
    });
    if (!response.success) return emptyApiPage<Office>();

    return response.data as ApiPage<Office>;
  }

  static async getOffice(officeId: string): Promise<Office | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}`);
    if (!response.success) return null;

    return response.data as Office;
  }

  // Returns saved office with ID included, or null on failure
  static async addOffice(office: CreateOffice): Promise<Office | null> {
    const response = await apiService.post(`${this.BASE_PATH}`, office);
    if (!response.success) return null;

    return response.data as Office;
  }

  static async updateOffice(office: UpdateOffice): Promise<Office | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${office.officeId}`, office);
    if (!response.success) return null;

    return response.data as Office;
  }

  static async updateOfficeBilling(office: UpdateOfficeBilling): Promise<Office | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${office.officeId}/billing`, office);
    if (!response.success) return null;

    return response.data as Office;
  }

  static async deleteOffice(officeId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/${officeId}`);
    if (!response.success) return false;

    return true;
  }

  static async addOfficeService(officeId: string, service: Service): Promise<Service | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/services`, service);
    if (!response.success) return null;

    return response.data as Service;
  }

  static async updateOfficeService(officeId: string, service: Service): Promise<Service | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${officeId}/services/${service.serviceId}`, service);
    if (!response.success) return null;

    return response.data as Service;
  }

  static async setOfficeServiceEnabled(officeId: string, serviceId: string, enabled: boolean): Promise<Service | null> {
    const body = [
      {
        op: ApiPatchOp.Replace,
        path: "/enabled",
        value: enabled,
      },
    ];
    if (!enabled) {
      body.push({
        op: ApiPatchOp.Replace,
        path: "/featured",
        value: false,
      });
      body.push({
        op: ApiPatchOp.Replace,
        path: "/uninsured",
        value: false,
      });
    }
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/services/${serviceId}`, body);
    if (!response.success) return null;

    return response.data as Service;
  }

  static async duplicateServiceToOffice(officeId: string, serviceId: string): Promise<Service | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/services/${serviceId}/duplicate`, {});
    if (!response.success) return null;

    return response.data as Service;
  }

  static async setOfficeAcceptedTerms(officeId: string, acceptedTerms: boolean): Promise<Office | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/acceptedTerms",
        value: acceptedTerms,
      },
    ]);
    if (!response.success) return null;

    return response.data as Office;
  }

  static async setOfficePublished(officeId: string, published: OfficeState): Promise<Office | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/officeState",
        value: { name: published },
      },
    ]);
    if (!response.success) return null;

    return response.data as Office;
  }

  static async getOfficeServices(officeId: string, page = 0, size = 20, sort = "name,asc"): Promise<ApiPage<Service>> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/services?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<Service>();

    return response.data as ApiPage<Service>;
  }

  static async getOfficeService(officeId: string, serviceId: string): Promise<Service | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/services/${serviceId}`);
    if (!response.success) return null;

    return response.data as Service;
  }

  static async searchOfficeServices(
    officeId: string,
    term?: string,
    enabledOnly = false,
    serviceOwner?: string,
    page = 0,
    size = 20,
    sort = "name,asc",
    hasBlockFeeDiscount?: boolean
  ): Promise<ApiPage<Service>> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/services/search?page=${page}&size=${size}&sort=${sort}`, {
      term: term,
      enabledOnly: enabledOnly,
      serviceOwner: serviceOwner,
      hasBlockFeeDiscount,
      featured: true,
    });
    if (!response.success) return emptyApiPage<Service>();

    return response.data as ApiPage<Service>;
  }

  static async getOfficeUsers(officeId: string, page = 0, size = 20, sort = "lastName,asc"): Promise<ApiPage<User>> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/users?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<User>();

    return response.data as ApiPage<User>;
  }

  static async searchOfficeUsers(
    officeId: string,
    term: string,
    page = 0,
    size = 20,
    sort = "lastName,asc",
    userState?: string,
    userType?: UserType
  ): Promise<ApiPage<User>> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/users/search?page=${page}&size=${size}&sort=${sort}`, {
      term: term ? term : undefined,
      userState,
      userType: userType ?? undefined,
    });
    if (!response.success) return emptyApiPage<User>();

    return response.data as ApiPage<User>;
  }

  static async getOfficeUsersWithPermission(
    officeId: string,
    permission: AuthPermission,
    page = 0,
    size = 100,
    sort = "lastName,asc"
  ): Promise<ApiPage<User>> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/users/search?page=${page}&size=${size}&sort=${sort}`, {
      permission: permission,
    });
    if (!response.success) return emptyApiPage<User>();

    return response.data as ApiPage<User>;
  }

  static async getOfficeUsersWithType(officeId: string, type: UserType, page = 0, size = 100, sort = "lastName,asc"): Promise<ApiPage<User>> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/users/search?page=${page}&size=${size}&sort=${sort}`, {
      userType: type,
    });
    if (!response.success) return emptyApiPage<User>();

    return response.data as ApiPage<User>;
  }

  static async getOfficeUser(officeId: string, userId: string): Promise<User | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/users/${userId}`);
    if (!response.success) return null;

    return response.data as User;
  }

  static async getOfficePatients(officeId: string, page = 0, size = 20, sort = "lastName,asc"): Promise<ApiPage<User>> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/patients?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<User>();

    return response.data as ApiPage<User>;
  }

  static async searchOfficePatients(
    officeId: string,
    term?: string,
    page = 0,
    size = 20,
    sort = "lastName,asc",
    userState?: string,
    hasBlockFee?: boolean
  ): Promise<ApiPage<User>> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/patients/search?page=${page}&size=${size}&sort=${sort}`, {
      term: term ? term : undefined,
      userState: userState ? userState : undefined,
      hasBlockFee,
    });
    if (!response.success) return emptyApiPage<User>();

    return response.data as ApiPage<User>;
  }

  static async getOfficeCareProviders(officeId: string): Promise<UserInfo[]> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/providers`);
    if (!response.success) return [];

    return response.data as UserInfo[];
  }

  static async getOfficeRequestCounts(officeId: string, assignedTo?: string, archiveState = ArchiveState.Any): Promise<RequestCounts | null> {
    let endpoint = `${this.BASE_PATH}/${officeId}/requests/status-counts?archiveState=${archiveState}`;
    if (assignedTo) {
      endpoint += `&assignedTo=${assignedTo}`;
    }
    const response = await apiService.get(endpoint);
    if (!response.success) return null;

    return response.data as RequestCounts;
  }

  static async getOfficeRequests(officeId: string, assignedTo?: string, page = 0, size = 20, sort = "createdDate,desc"): Promise<ApiPage<Request>> {
    let endpoint = `${this.BASE_PATH}/${officeId}/requests?page=${page}&size=${size}&sort=${sort}`;
    if (assignedTo) {
      endpoint += `&assignedTo=${assignedTo}`;
    }

    const response = await apiService.get(endpoint);
    if (!response.success) return emptyApiPage<Request>();

    return response.data as ApiPage<Request>;
  }

  static async getOfficeRequest(officeId: string, requestId: string): Promise<Request | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/requests/${requestId}`);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async searchOfficeRequests(
    officeId: string,
    term: string,
    assignedTo?: string,
    state?: RequestState,
    urgent?: boolean,
    archiveState = ArchiveState.Any,
    page = 0,
    size = 20,
    sort = "createdDate,desc"
  ): Promise<ApiPage<Request>> {
    const body = {
      term: term,
      assignedTo: assignedTo,
      state: state,
      urgent: urgent,
      archiveState: archiveState,
    };
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/search?page=${page}&size=${size}&sort=${sort}`, body);
    if (!response.success) return emptyApiPage<Request>();

    return response.data as ApiPage<Request>;
  }

  static async getUrgentOfficeRequests(
    officeId: string,
    assignedTo?: string,
    page = 0,
    size = 20,
    sort = "createdDate,desc"
  ): Promise<ApiPage<Request>> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/search?page=${page}&size=${size}&sort=${sort}`, {
      urgent: true,
      assignedTo: assignedTo,
    });
    if (!response.success) return emptyApiPage<Request>();

    return response.data as ApiPage<Request>;
  }

  static async getOfficeRequestsByState(
    officeId: string,
    state: RequestState,
    assignedTo?: string,
    page = 0,
    size = 20,
    sort = "createdDate,desc"
  ): Promise<ApiPage<Request>> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/search?page=${page}&size=${size}&sort=${sort}`, {
      state: state,
      assignedTo: assignedTo,
    });
    if (!response.success) return emptyApiPage<Request>();

    return response.data as ApiPage<Request>;
  }

  static async updateOfficeRequestPatient(officeId: string, patientUpdate: RequestPatientUpdate): Promise<Request | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${officeId}/requests/${patientUpdate.requestId}`, patientUpdate);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async setOfficeRequestUrgent(officeId: string, requestId: string, urgent: boolean): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/urgent",
        value: urgent,
      },
    ]);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async setOfficeRequestState(officeId: string, requestId: string, state: RequestState): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/requestState",
        value: {
          name: state,
        },
      },
    ]);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async setOfficeRequestPaid(
    officeId: string,
    requestId: string,
    paymentMethod: RequestPaymentMethod,
    paymentDate = new Date(),
    paymentDetails?: string | undefined,
    overridePrice?: number
  ): Promise<Request | null> {
    const body = [
      {
        op: ApiPatchOp.Replace,
        path: "/paymentComplete",
        value: overridePrice ? false : true,
      },
      {
        op: ApiPatchOp.Replace,
        path: "/paymentMethod",
        value: {
          name: paymentMethod,
        },
      },
      {
        op: ApiPatchOp.Replace,
        path: "/paymentDate",
        value: paymentDate.toISOString(),
      },
    ];
    if (paymentDetails?.trim()?.length) {
      body.push({
        op: ApiPatchOp.Replace,
        path: "/paymentDetails",
        value: paymentDetails,
      });
    }
    if (overridePrice && overridePrice > 0) {
      body.push({
        op: ApiPatchOp.Replace,
        path: "/servicePriceOverride",
        value: overridePrice.toString(),
      });
    }
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, body);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async setOfficeRequestSubmitted(officeId: string, requestId: string, status: boolean): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/submitted",
        value: status,
      },
    ]);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async undoOfficePaymentStatus(officeId: string, requestId: string, status: boolean): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/paymentComplete",
        value: status,
      },
    ]);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async getOfficeRequestFiles(officeId: string, requestId: string): Promise<RequestFile[]> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/requests/${requestId}/files`);
    if (!response.success) return [];

    return response.data as RequestFile[];
  }

  // The `fileIds` will be from `DsFile` model after initial upload(s)
  static async addOfficeRequestFiles(
    officeId: string,
    requestId: string,
    fileIds: string[],
    requestFileType?: RequestFileTypes
  ): Promise<Request | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/files`, {
      fileIds: fileIds,
      requestFileType: requestFileType ?? RequestFileTypes.Document,
    });
    if (!response.success) return null;

    return response.data as Request;
  }

  static async addOfficeRequestFinalDocuments(officeId: string, requestId: string, fileIds: string[]): Promise<Request | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/final-files`, {
      fileIds: fileIds,
      requestFileType: RequestFileTypes.Document,
    });
    if (!response.success) return null;

    return response.data as Request;
  }

  static async deleteOfficeRequestFile(officeId: string, requestId: string, requestFileId: string): Promise<Request | null> {
    const response = await apiService.delete(`${this.BASE_PATH}/${officeId}/requests/${requestId}/files/${requestFileId}`);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async getOfficeServiceSampleFile(officeId: string, serviceId: string): Promise<DsFile | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/services/${serviceId}/sample-file`);
    if (!response.success) return null;

    return response.data as DsFile;
  }

  // Requires *either* sender name or sender ID
  static async addOfficeRequestMessage(
    officeId: string,
    requestId: string,
    message: string,
    internal: boolean,
    senderId?: string,
    senderName?: string
  ): Promise<RequestMessage | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/messages`, {
      requestId: requestId,
      senderId: senderId,
      senderName: senderName,
      message: message,
      internal: internal,
    });
    if (!response.success) return null;

    return response.data as RequestMessage;
  }

  static async getOfficeRequestInternalMessages(officeId: string, requestId: string): Promise<RequestMessage[]> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/requests/${requestId}/messages/internal`);
    if (!response.success) return [];

    return response.data as RequestMessage[];
  }

  static async getOfficeRequestPatientMessages(officeId: string, requestId: string): Promise<RequestMessage[]> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/requests/${requestId}/messages/external`);
    if (!response.success) return [];

    return response.data as RequestMessage[];
  }

  static async setOfficeRequestMessageRead(officeId: string, requestId: string, messageId: string, read: boolean): Promise<RequestMessage | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}/messages/${messageId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/unread",
        value: !read,
      },
    ]);
    if (!response.success) return null;

    return response.data as RequestMessage;
  }

  static async updateOfficeRequestFees(officeId: string, requestId: string, fees: UpdateFee[]): Promise<Request | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${officeId}/requests/${requestId}/fees`, fees);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async sendRequestFileToPatient(officeId: string, requestId: string, requestFileId: string): Promise<RequestFile | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/files/${requestFileId}/send-patient`, {});
    if (!response.success) return null;

    return response.data as RequestFile;
  }

  static async sendRequestFileToThirdParty(officeId: string, requestId: string, requestFileId: string): Promise<RequestFile | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/files/${requestFileId}/send-recipient`, {});
    if (!response.success) return null;

    return response.data as RequestFile;
  }

  static async getOfficeRequestPriceDetails(officeId: string, requestId: string): Promise<PriceDetails | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/requests/${requestId}/price-details`);
    if (!response.success) return null;

    return response.data as PriceDetails;
  }

  static async createOfficeRequestInvoice(officeId: string, requestId: string, notes: string): Promise<PriceDetails | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/invoice`, {
      notes: notes,
    });
    if (!response.success) return null;

    return response.data as PriceDetails;
  }

  static async inviteOfficeUser(
    firstName: string,
    lastName: string,
    email: string,
    jobTitleId: string,
    userType: UserType = UserType.SystemAdmin,
    permissions: AuthPermission[],
    primaryCareProvider: boolean,
    officeId: string,
    phone?: string
  ): Promise<InvitationResponse | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/users/invite`, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      userType: userType,
      permissions: permissions,
      primaryCareProvider: primaryCareProvider,
      officeId: officeId,
      phone: phone,
      jobTitleId,
    });

    if (!response.success) {
      throw new ApiError(response.status === 409 ? ApiErrorCode.EXISTS : ApiErrorCode.UNKNOWN, JSON.stringify(response.data));
    }

    return response.data as InvitationResponse;
  }

  static async cancelOfficeUserInvitation(officeId: string, userId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/${officeId}/users/invite/${userId}`);
    if (!response.success) return false;

    return true;
  }

  static async cancelOfficePatientInvitation(officeId: string, userId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/${officeId}/patients/invite/${userId}`);
    if (!response.success) return false;

    return true;
  }

  static async resendOfficeUserInvitation(officeId: string, userId: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/users/invite-resend`, {
      userId: userId,
    });
    if (!response.success) return false;

    return true;
  }

  static async resendOfficePatientInvitation(officeId: string, userId: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/users/invite-patient-resend`, {
      userId: userId,
    });
    if (!response.success) return false;

    return true;
  }

  static async updateOfficeUser(officeId: string, user: UserUpdate): Promise<User | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${officeId}/users/${user.userId}`, user);
    if (!response.success) return null;

    return response.data as User;
  }

  static async getOfficeRequestTimeline(officeId: string, requestId: string, page = 0, size = 20): Promise<ApiPage<Timeline>> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/requests/${requestId}/timeline?page=${page}&size=${size}`);
    if (!response.success) return emptyApiPage<Timeline>();

    return response.data as ApiPage<Timeline>;
  }

  static async addOfficeRequestTimelineEvent(event: string, officeId: string, requestId: string): Promise<Timeline | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/timeline`, {
      event: event,
    });
    if (!response.success) return null;

    return response.data as Timeline;
  }

  static async getOfficeFinanceReport(officeId: string, startDate: Date, endDate?: Date): Promise<Finance | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/finance/search`, {
      startDate: startDate.toISOString(),
      endDate: endDate?.toISOString(),
    });
    if (!response.success) return null;

    return response.data as Finance;
  }

  static async getOfficeFinanceReportCsv(officeId: string, startDate: Date, endDate?: Date): Promise<string | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/finance/requests/csv`, {
      startDate: startDate.toISOString(),
      endDate: endDate?.toISOString(),
    });
    if (!response.success) return null;
    return response.data as string;
  }

  static async getOfficeFinanceRequests(
    officeId: string,
    startDate: Date,
    endDate: Date,
    page = 0,
    size = 10,
    sort = "request.lastName"
  ): Promise<ApiPage<FinanceRequest>> {
    const body = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/finance/requests?page=${page}&size=${size}&sort=${sort}`, body);
    if (!response.success) return emptyApiPage<FinanceRequest>();

    return response.data as ApiPage<FinanceRequest>;
  }

  static async sendOfficeRequestTextMessage(officeId: string, requestId: string, message: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/send-sms`, {
      message: message,
    });
    if (!response.success) return false;

    return true;
  }

  static async declineOfficeRequest(officeId: string, requestId: string, reason: string): Promise<Request | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/decline`, {
      reason: reason,
    });
    if (!response.success) return null;

    return response.data as Request;
  }

  static async submitSupportForm(officeId: string, message: string, file?: File): Promise<boolean> {
    const formData = new FormData();
    formData.append("message", message);
    if (file) {
      formData.append("file", file);
    }
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/support`, formData);
    if (!response.success) return false;

    return true;
  }

  static async invitePatient(officeId: string, email: string, note: string): Promise<InvitationResponse | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/users/invite-patient`, {
      officeId,
      email,
      note,
    });
    if (!response.success) {
      throw new ApiError(response.status === 409 ? ApiErrorCode.EXISTS : ApiErrorCode.UNKNOWN, JSON.stringify(response.data));
    }

    return response.data as InvitationResponse;
  }

  static async acceptPatientInvitation(
    officeId: string,
    token: string,
    email: string,
    phone: string,
    password: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    ohipNumber?: string
  ): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/users/invite-patient-accept`, {
      token,
      email,
      phone,
      password,
      firstName,
      lastName,
      dateOfBirth,
      ohipNumber,
    });
    if (!response.success) return false;

    return true;
  }

  static async sendPaymentReminder(officeId: string, requestId: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/send-payment-reminder`, {});
    if (!response.success) return false;

    return true;
  }

  static async sendInviteReminder(officeId: string, requestId: string): Promise<boolean> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/send-invite-reminder`, {});
    if (!response.success) return false;

    return true;
  }

  static async getPriceReview(officeId: string, requestBody: UpdateRequest): Promise<PriceDetails | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/price-preview`, requestBody);
    if (!response.success) return null;

    return response.data;
  }

  static async updateRequest(request: UpdateRequest, requestId: string, officeId: string): Promise<Request | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, request);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async updateRequestNotes(details: string, requestId: string, officeId: string): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/details",
        value: details,
      },
    ]);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async updateRequestRecipients(name: string, contact: string, requestId: string, officeId: string): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/recipientName",
        value: name,
      },
      {
        op: ApiPatchOp.Replace,
        path: "/recipientContact",
        value: contact,
      },
    ]);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async updateRequestAssignee(
    assignedToUserId: string,
    primaryCareProviderUserId: string,
    requestId: string,
    officeId: string
  ): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/assignedTo",
        value: {
          userId: assignedToUserId,
        },
      },
      {
        op: ApiPatchOp.Replace,
        path: "/primaryCareProvider",
        value: {
          userId: primaryCareProviderUserId,
        },
      },
    ]);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async updateOfficeRequestFulfillOnPayment(officeId: string, requestId: string, fulfill: boolean): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/fulfillOnPayment",
        value: fulfill,
      },
    ]);
    if (!response.success) return null;

    return response.data as Request;
  }

  static async getOfficeAccountReceivablesTotals(officeId: string): Promise<AccountReceivablesTotals | undefined> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/account-receivables-totals`);
    if (!response.success) return undefined;

    return response.data as AccountReceivablesTotals;
  }

  static async getOfficeAccountReceivablesPayee(officeId: string): Promise<Payee[]> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/account-receivables-payee`);
    if (!response.success) return [];

    return response.data as Payee[];
  }

  static async getOfficeAccountReceivablesTopOverdueAccounts(officeId: string): Promise<TopOverdueAccounts[]> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/top-overdue-accounts`);
    if (!response.success) return [];

    return response.data as TopOverdueAccounts[];
  }

  static async getOfficeAccountReceivables(officeId: string, page = 0, size = 10, sort = "requestNumber,desc"): Promise<ApiPage<TopOverdueAccounts>> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/account-receivables?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<TopOverdueAccounts>();

    return response.data as ApiPage<TopOverdueAccounts>;
  }

  static async setOfficeTemporarilyClosed(officeId: string, closed: boolean, reason?: string): Promise<Office | null> {
    const body = [];

    if (closed && reason?.length) {
      body.push({
        op: ApiPatchOp.Replace,
        path: "/tempClosedReason",
        value: reason.toString(),
      });
    }

    body.push({
      op: ApiPatchOp.Replace,
      path: "/tempClosed",
      value: closed,
    });

    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}`, body);
    if (!response.success) return null;

    return response.data as Office;
  }

  static async refundRequest(officeId: string, requestId: string, requestReason: string, requestReasonOther?: string): Promise<Request | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/requests/${requestId}/refund-request`, {
      requestReason,
      requestReasonOther,
    });
    if (!response.success) return null;

    return response.data;
  }

  static async approveRefundRequest(officeId: string, requestId: string, refundRequestId: string): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}/refund-request/${refundRequestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/refundRequestState",
        value: {
          name: "approved",
        },
      },
    ]);
    if (!response.success) return null;

    return response.data;
  }

  static async rejectRefundRequest(officeId: string, requestId: string, refundRequestId: string, reason: string): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}/refund-request/${refundRequestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/refundRequestState",
        value: {
          name: "rejected",
        },
      },
      {
        op: ApiPatchOp.Replace,
        path: "/rejectedReason",
        value: reason,
      },
    ]);
    if (!response.success) return null;

    return response.data;
  }

  static async getOfficeUninsuredServices(officeId: string, page = 0, size = 200, sort = "name,asc"): Promise<ApiPage<Service>> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/services/uninsured?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<Service>();

    return response.data as ApiPage<Service>;
  }

  static async getPatientReceipts(officeId: string, userId: string, page = 0, size = 10, sort = "name,asc"): Promise<ApiPage<Receipt>> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/patients/${userId}/receipts?page=${page}&size=${size}`);
    if (!response.success) return emptyApiPage<Receipt>();

    return response.data as ApiPage<Receipt>;
  }

  static async getPatientDocuments(officeId: string, userId: string, page = 0, size = 10, sort = "createdDate,desc"): Promise<ApiPage<Document>> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/patients/${userId}/documents?page=${page}&size=${size}&sort=${sort}`);
    if (!response.success) return emptyApiPage<Document>();

    return response.data as ApiPage<Document>;
  }

  static async setOfficeRefundReason(officeId: string, requestId: string, reason: string): Promise<Request | null> {
    const response = await apiService.patch(`${this.BASE_PATH}/${officeId}/requests/${requestId}`, [
      {
        op: ApiPatchOp.Replace,
        path: "/refundReason",
        value: reason,
      },
    ]);
    if (!response.success) return null;

    return response.data as Request;
  }
}
