import { headersType } from "../../models";

export const headers: headersType[] = [
  {
    title: "Patient name",
    extraClasses: "w-full lg:w-[13.5%] pl-0 truncate",
    sortKey: "request.lastName",
    isClickable: true,
  },
  {
    title: "Request #",
    extraClasses: "w-full lg:w-[13.5%] hidden lg:block",
    sortKey: "request.requestNumber",
    isClickable: true,
  },
  {
    title: "Service name",
    extraClasses: "w-full lg:w-[14%] hidden lg:block pl-0 break-all",
    sortKey: "request.service.name",
    isClickable: true,
  },
  {
    title: "Date Submitted",
    extraClasses: "w-full lg:w-[16%] hidden lg:block",
    sortKey: "request.submittedDate",
    isClickable: false,
  },
  {
    title: "Total",
    extraClasses: "w-full lg:w-[10%] hidden lg:block",
    sortKey: "totalAmount",
    isClickable: true,
  },
  {
    title: "HST",
    extraClasses: "w-full lg:w-[9.5%] hidden lg:block",
    sortKey: "totalTax",
    isClickable: true,
  },
  {
    title: "Pay. Status",
    extraClasses: "w-full lg:w-[12%] hidden lg:block truncate",
    sortKey: "request.paymentComplete",
    isClickable: true,
  },
  {
    title: "Pay. Method",
    extraClasses: "w-full lg:w-[11%] hidden lg:block truncate pl-0",
    sortKey: "request.paymentMethod",
    isClickable: false,
  },
];

