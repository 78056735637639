import { Slide } from "@mui/material";
import React, { useContext } from "react";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../../models";

interface ToastType {
  message?: string;
}

const Toast = ({ message = "" }: ToastType) => {
  const context = useContext(ToastContext);

  const ToastElement = () => {
    if (context?.variant === ToastVariants.Error) {
      return (
        <div
          style={{ zIndex: 999, backgroundColor: "#F15C36" }}
          className="text-base z-50 text-white font-semibold p-5 py-2.5 fixed top-8 right-2 w-80 rounded-md">
          {message === "" ? context.toastMsg : message}
        </div>
      );
    }
    if (context?.variant === ToastVariants.Warn) {
      return (
        <div
          style={{ zIndex: 999, backgroundColor: "#C2A1CB" }}
          className="text-base z-50 text-white font-semibold p-5 py-2.5 fixed top-8 right-2 w-80 rounded-md">
          {message === "" ? context.toastMsg : message}
        </div>
      );
    }
    if (context?.variant === ToastVariants.Success) {
      return (
        <div
          style={{ zIndex: 999, backgroundColor: "#DAE25F" }}
          className="text-base z-50 font-semibold p-5 py-2.5 fixed top-8 right-2 w-80 rounded-md text-docsigna-purple">
          {message === "" ? context.toastMsg : message}
        </div>
      );
    }
    return null;
  };

  // if (!context?.isVisible) return null;
  return (
    <div className="fixed top-0 right-0 z-[100000]">
      <Slide in={context?.isVisible} direction="left" mountOnEnter unmountOnExit>
        <div>
          <ToastElement />
        </div>
      </Slide>
    </div>
  );
};

export default Toast;
