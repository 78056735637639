import React, { useState, useEffect, useContext } from "react";
import { colors } from "../../../utils/constants";
import Button from "../../Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { getOfficeUsersWithPermission, getOffice, getOfficeCareProviders } from "../../../redux/slices/OfficeSlice";
import { AuthPermission } from "../../../services/auth.service";
import { useParams } from "react-router-dom";
import { User } from "../../../models/user.model";
import { addOfficeRequestTimelineEvent } from "../../../redux/slices/UserSlice";
import { setRequestData } from "../../../redux/slices/OfficeRequestSlice";
import { OfficeService } from "../../../services/office.service";
import { ToastVariants } from "../../../models";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";

interface optionType {
  label: string;
  value: string;
}

interface formDataType {
  id: string;
  value: string;
  label: string;
  title: string;
  placeholder: string;
  required: boolean;
  errorMessage: string;
  valid: boolean;
  options: optionType[];
}

const formDataInitialState: formDataType[] = [
  {
    id: "staff",
    value: "",
    label: "",
    title: "Staff",
    placeholder: "Select Staff",
    required: true,
    errorMessage: "Please select a staff member",
    valid: false,
    options: [],
  },
  {
    id: "pcp",
    value: "",
    label: "",
    title: " Primary Care Provider",
    placeholder: "Select Primary Care Provider",
    required: true,
    errorMessage: "Please select a primary care provider.",
    valid: false,
    options: [],
  },
];

const AssignRequest = ({
  onClickCancel,
  setIsLoading,
  isLoading,
}: {
  onClickCancel: () => void;
  setIsLoading: (val: boolean) => void;
  isLoading?: boolean;
}) => {
  const toast = useContext(ToastContext);
  const { id, requestId } = useParams();
  const [formData, setFormData] = useState(formDataInitialState);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const [serverError, setServerError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const { userWithPermission } = useSelector((state: RootState) => state.office);
  const { assignRequestError } = useSelector((state: RootState) => state.officeRequest);
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const { primaryCareProvider } = useSelector((state: RootState) => state.office);
  const { currentOffice } = useSelector((state: RootState) => state.office);

  useEffect(() => {
    if (id) {
      const officeId = id;
      dispatch(
        getOfficeUsersWithPermission({
          officeId,
          permission: AuthPermission.Request,
          page: 0,
          sort: "",
          size: 100,
        })
      );

      dispatch(getOfficeCareProviders({ officeId }));
    }
  }, [id]);

  useEffect(() => {
    let newOptions = userWithPermission?.data?.content
      .filter(item => item.userState === "active")
      ?.map((user: User) => {
        return { label: `${user.firstName} ${user.lastName}`, value: user.userId };
      });

    newOptions = newOptions ? newOptions : [];

    setFormData(formData =>
      formData.map(data => {
        if (data.id === "staff" && newOptions) {
          return { ...data, options: [...newOptions] };
        }
        return data;
      })
    );
  }, [userWithPermission]);

  useEffect(() => {
    if (currentOffice === null && id) {
      dispatch(getOffice({ officeId: id }));
    }
  }, [currentOffice, id]);

  useEffect(() => {
    const pcp = primaryCareProvider
      ?.filter(pcp => pcp.userState === "active")
      ?.map(pcp => {
        return { label: `${pcp.firstName} ${pcp.lastName}`, value: pcp.userId };
      });

    const newPCP = pcp ? (pcp?.length > 0 ? pcp : []) : [];

    setFormData(forms => {
      return forms.map(data => {
        if (data.id === "pcp") {
          return { ...data, options: newPCP, isValid: true };
        }
        return data;
      });
    });
  }, [primaryCareProvider]);

  useEffect(() => {
    formData.forEach(data => {
      if (data.id === "staff" && requestData?.assignedTo) {
        handleChange("staff", requestData.assignedTo.userId);
      }

      if (data.id === "pcp" && requestData?.primaryCareProvider) {
        handleChange("pcp", requestData.primaryCareProvider.userId);
      }
    });
  }, [requestData]);

  const handleOnSubmit = async () => {
    const userId = formData.find(d => d.id === "staff") as formDataType;
    const pcpUserId = formData.find(d => d.id === "pcp") as formDataType;
    if (id && requestId && requestData) {
      setIsLoading(true);
      try {
        const resp = await OfficeService.updateRequestAssignee(userId.value, pcpUserId.value, requestId, id);
        setIsLoading(false);
        if (resp !== null) {
          onClickCancel();
          dispatch(setRequestData(resp));
          await dispatch(
            addOfficeRequestTimelineEvent({
              event: `${currentUser?.firstName + " " + currentUser?.lastName} assigned request to ${userId.label}`,
              officeId: id,
              requestId: requestId,
            })
          );
          await dispatch(
            addOfficeRequestTimelineEvent({
              event: `${currentUser?.firstName + " " + currentUser?.lastName} assigned Dr. ${pcpUserId.label.split(" ")[1]} as primary care provider`,
              officeId: id,
              requestId: requestId,
            })
          );
          toast?.openToast("Assignees updated successfully!", 2000, ToastVariants.Success);
        } else {
          toast?.openToast("The update for assignees failed!");
        }
      } catch (_) {
        setIsLoading(false);
        setServerError(true);
        toast?.openToast("Something went wrong please try again!", 2000, ToastVariants.Error);
      }
    }
  };

  const handleChange = (id: string, value: string) => {
    setFormData(tempFormData => {
      return tempFormData.map(input => {
        if (input.id === id) {
          const staffName = input.options?.filter(f => f.value === value);
          return { ...input, value, valid: true, label: staffName[0]?.label };
        }

        return { ...input };
      });
    });
  };
  const validateForm = () => {
    return formData.every(val => val.valid);
  };

  return (
    <>
      {assignRequestError || serverError ? <p className="text-red-400 text-center mt-3">An error has occurred, please try again</p> : null}
      <p className="text-sm px-8 pb-5 font-medium">Assign this request to internal staff and primary care provider</p>
      {formData.map(inp => (
        <div key={inp.id} className="px-8 py-2">
          <label className="text-sm text-docsigna-dark-blue font-medium block w-full mb-0.5">
            {inp.title}
            {inp.required ? <span className="text-red-500">*</span> : null}
          </label>

          <select onChange={e => handleChange(inp.id, e.target.value)} value={inp.value} className="general-select w-full bg-transparent">
            {inp.value === "" ? (
              <option disabled value={""}>
                {inp.placeholder}
              </option>
            ) : null}
            {inp.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {!inp.valid && errorInitiated ? <p style={{ color: colors.Red }}>{inp.errorMessage}</p> : null}
        </div>
      ))}
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onClickCancel()}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
            disabled={isLoading}
          />
          <div>
            <Button
              onClickFunc={() => {
                setErrorInitiated(true);
                if (validateForm()) handleOnSubmit();
              }}
              disabled={(!validateForm() && errorInitiated) || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Save"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignRequest;
