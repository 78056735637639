import { Observable, Subject } from "rxjs";
import jwtDecode from "jwt-decode";
import { ApiError, ApiErrorCode } from "../models/api-error.model";
import { ApiResponse } from "./api.service";
import { apiService } from "./api.service";
import { logger } from "../utils/logger";
import { User } from "../models/user.model";
import { UserUpdate } from "../models/user-update.model";

/* eslint-disable no-unused-vars */
export enum AuthPermission {
  Admin = "admin",
  Finance = "finance", // View/Edit Finance
  Signing = "signing", // Sign Forms
  Office = "office", // Edit Office
  Request = "request", // Edit Requests
  Report = "report", // View Reports
  Service = "service", // Edit Services
  User = "user", // Edit Users
  Legal = "legal",
}
/* eslint-enable no-unused-vars */
export interface AuthToken {
  accessToken: string;
  refreshToken: string | null;
  emailVerificationRequired: boolean;
  passwordChangeRequired: boolean;
}

export interface AccessTokenState {
  sub: string; // Holds the user ID
  exp: number;
}

export interface CurrentUser {
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export enum UserStates {
  All = "All Users",
  Active = "Active Users",
  Invited = "Invited Users",
  Disabled = "Disabled Users",
}

export enum PatientStates {
  All = "All Patients",
  Active = "Active Patients",
  Invited = "Invited Patients",
  Disabled = "Disabled Patients",
}

export enum UserState {
  Active = "active",
  Invited = "invited",
  Disabled = "disabled",
}

const log = logger.getLogger("AuthService");

class AuthService {
  private BASE_PATH = "/api/v1/auth";

  private _authStateChange = new Subject<boolean>();
  authStateChange$: Observable<boolean> = this._authStateChange.asObservable();

  private _accessTokenRefreshed = new Subject<string>();
  accessTokenRefreshed$: Observable<string> = this._accessTokenRefreshed.asObservable();

  private _authToken: AuthToken | null = null;
  get authToken(): AuthToken | null {
    return this._authToken;
  }
  set authToken(authToken: AuthToken | null) {
    if (!authToken || !authToken?.accessToken) {
      this.signOut();
      return;
    }

    this._authToken = authToken;
    this._authStateChange.next(true);
  }

  get currentUserId(): string | null {
    if (!this._authToken?.accessToken) return null;

    const decoded: AccessTokenState = jwtDecode(this._authToken.accessToken);
    return decoded.sub;
  }

  get currentUserPermissions(): AuthPermission[] {
    if (!this._authToken?.accessToken) return [];

    const decoded: any = jwtDecode(this._authToken.accessToken);
    const cognitoGroups: string[] = decoded["cognito:groups"];
    if (!cognitoGroups) return [];

    return cognitoGroups.flatMap(val => {
      switch (val) {
        case "admin":
          return AuthPermission.Admin;
        case "signing":
          return AuthPermission.Signing;
        case "user":
          return AuthPermission.User;
        case "office":
          return AuthPermission.Office;
        case "finance":
          return AuthPermission.Finance;
        case "report":
          return AuthPermission.Report;
        case "service":
          return AuthPermission.Service;
        case "request":
          return AuthPermission.Request;

        default:
          return [];
      }
    });
  }

  /** Confirm credentials, used for functionality requiring authentication (i.e. email change) */
  async confirmCredentials(email: string, password: string): Promise<boolean> {
    const response = await apiService.post(
      this.BASE_PATH + "/authenticate",
      {
        email: email,
        password: password,
      },
      true // Ignoring 401 responses
    );
    if (!response.success || response.status !== 200) {
      return false;
    }

    if (response.data?.emailVerificationRequired) {
      return false;
    }

    if (!response.data?.accessToken) {
      return false;
    }

    return true;
  }

  async signIn(email: string, password: string): Promise<AuthToken> {
    const response = await apiService.post(this.BASE_PATH + "/authenticate", {
      email: email,
      password: password,
    });
    if (!response.success || response.status !== 200) {
      throw new ApiError(response.status === 401 ? ApiErrorCode.AUTH_BAD_CREDENTIALS : ApiErrorCode.UNKNOWN, response.data);
    }

    if (!response.data?.emailVerificationRequired && !response.data?.accessToken) {
      log.warn(`signIn => No access token received: ${response}`);
      throw new ApiError(ApiErrorCode.UNKNOWN, response.data);
    }

    if (response.data?.emailVerificationRequired) {
      return {
        accessToken: "",
        refreshToken: null,
        emailVerificationRequired: true,
        passwordChangeRequired: false,
      };
    } else {
      this._authToken = {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        emailVerificationRequired: response.data.emailVerificationRequired,
        passwordChangeRequired: response.data.passwordChangeRequired,
      };

      // Let listeners know about the state change
      this._authStateChange.next(true);

      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        emailVerificationRequired: response.data.emailVerificationRequired,
        passwordChangeRequired: response.data.passwordChangeRequired,
      };
    }
  }

  signOut() {
    this._authToken = null;

    // TODO: Call sign out endpoint

    // Let listeners know about the state change
    this._authStateChange.next(false);
  }

  // We are authenticated if valid access token exists
  isAuthenticated(): boolean {
    if (this._authToken?.accessToken && this._authToken?.accessToken?.length > 0) return true;
    return false;
  }

  async verifyUser(email: string, code: string): Promise<boolean> {
    const response: ApiResponse = await apiService.post(this.BASE_PATH + "/verify-user", {
      email: email,
      code: code,
    });
    if (!response.success) return false;

    return true;
  }

  async authenticateMfa(email: string, code: string): Promise<AuthToken> {
    const response: ApiResponse = await apiService.post(this.BASE_PATH + "/authenticate-mfa", {
      email: email,
      code: code,
    });

    if (!response.success || response.status !== 200) {
      throw new ApiError(response.status === 401 ? ApiErrorCode.AUTH_BAD_CREDENTIALS : ApiErrorCode.UNKNOWN, response.data);
    }

    if (!response.data?.accessToken) {
      log.warn(`signIn => No access token received: ${response}`);
      throw new ApiError(ApiErrorCode.UNKNOWN, response.data);
    }
    this._authToken = {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      emailVerificationRequired: false,
      passwordChangeRequired: false,
    };
    // Let listeners know about the state change
    this._authStateChange.next(true);
    return {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      emailVerificationRequired: false,
      passwordChangeRequired: false,
    };
  }

  // Called by API service on a token refresh
  tokenRefreshed(token: string) {
    if (!this._authToken) return;
    this._authToken.accessToken = token;
    this._accessTokenRefreshed.next(token);
  }

  // TODO: Implement
  // async forceLogout(): Promise<string | null> {
  //   const response: ApiResponse = await apiService.get("/authtest/401");
  //   if (!response.success) return null;

  //   return response.data as string;
  // }

  async resendConfirmationCode(email: string): Promise<boolean> {
    const response: ApiResponse = await apiService.post(this.BASE_PATH + "/resend-code", {
      email: email,
    });
    if (!response.success) return false;

    return true;
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    const response: ApiResponse = await apiService.post(this.BASE_PATH + "/change-password", {
      oldPassword,
      newPassword,
    });
    if (!response.success) return false;

    return true;
  }

  async forgotPassword(email: string): Promise<boolean> {
    const response: ApiResponse = await apiService.post(this.BASE_PATH + "/forgot-password", {
      email: email,
    });
    if (!response.success) return false;

    return true;
  }

  async resetPassword(email: string, code: string, password: string): Promise<boolean> {
    const response: ApiResponse = await apiService.post(this.BASE_PATH + "/reset-password", {
      email: email,
      code: code,
      password: password,
    });
    if (!response.success) return false;

    return true;
  }

  async updatePatientUserProfile(userId: string, officeId: string, body: UserUpdate): Promise<User | null> {
    const response = await apiService.put(`/api/v1/offices/${officeId}/users/${userId}/profile`, body);
    if (!response.success) return null;

    return response.data as User;
  }

  async getUserLastAccessDate(userId: string): Promise<string | undefined> {
    const response = await apiService.get(`/api/v1/auth/users/${userId}`);
    if (!response.success) return undefined;

    return response.data?.lastTokenRefresh ?? undefined;
  }
}

export const authService = new AuthService();
