import { ChangeEvent, useContext, useState } from "react";
import { Button, TextInput } from "../../components";
import { FileTypes, ToastVariants } from "../../models";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DeleteOutline } from "@mui/icons-material";
import { colors } from "../../utils/constants";
import { ToastContext } from "../../contexts/ToastContext/ToastContext";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { createForms } from "../../redux/slices/FormSlice";
import { uploadSingleFile } from "../../redux/slices/FileSlice";
import { LinearProgress } from "@mui/material";

const allowedFiles = [FileTypes.PDF];

const FormUploadLayout = ({ handleCancel }: { handleCancel: (val?: boolean) => void }) => {
  const toast = useContext(ToastContext);
  const dispatch = useDispatch<AppDispatch>();
  const [filesProgress, setFilesProgress] = useState<number>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [canUpload, setCanUpload] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].type && allowedFiles.includes(e.target.files[0].type as FileTypes)) {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
      } else {
        toast?.openToast("Invalid file format", 2000, ToastVariants.Warn);
      }
    }
  };

  const handleUploadFile = async () => {
    if (file) {
      try {
        setUploading(true);
        const res = await dispatch(
          uploadSingleFile({
            file,
            callback: progress => {
              setFilesProgress(progress);
            },
          })
        );
        setUploading(false);
        if (res.payload) {
          const url: string | undefined = res.payload.toString();
          setFileId(url);
          setCanUpload(true);
        }
      } catch (_) {
        setUploading(false);
      }
    }
  };

  const finalFormSubmit = async () => {
    if (fileName?.trim().length <= 0) {
      toast?.openToast("Please provide file name", 2000, ToastVariants.Error);
      return;
    }
    if (fileId && fileName) {
      setUploading(true);
      const res = await dispatch(
        createForms({
          fileId,
          name: fileName,
        })
      );
      if (res.payload) toast?.openToast("Form template uploaded successfully.");
      else toast?.openToast("Form template upload failed.");
    }
    setUploading(false);
    cancel(true);
  };

  const cancel = (val?: boolean) => {
    setFile(undefined);
    setFileName("");
    setFileId(undefined);
    setFilesProgress(0);
    setCanUpload(false);
    handleCancel(val);
  };

  return (
    <div className="mx-auto p-4 w-full">
      {!canUpload ? (
        <p className="text-base mb-3 text-docsigna-blue-dark text-center">Upload form template in PDF format</p>
      ) : (
        <p className="text-base mb-3 text-docsigna-blue-dark text-center">
          Confirm or change below file name <br />
          before proceeding with upload
        </p>
      )}
      {!canUpload ? (
        <>
          <div className="text-center bg-transparent border border-docsigna-blue-dark rounded my-5 relative">
            <input
              type="file"
              className="text-base rounded-md border-gray-300 focus:border-docsigna-purple w-full h-full py-6 px-4 opacity-0 relative z-10"
              onChange={handleFileChange}
            />
            <div className="w-full h-full py-6 px-4 absolute top-0 flex items-center justify-center">
              <AttachFileIcon className="rotate-[45deg]" />
              <p className="ml-1 mt-1">Upload here</p>
            </div>
          </div>
          <div className="px-2 w-full">
            {filesProgress !== 100 && uploading ? <LinearProgress variant={"determinate"} value={filesProgress || 0} /> : null}
          </div>
        </>
      ) : (
        <div className="">
          <TextInput value={fileName} onChangeFunc={e => setFileName(e.target.value)} isDarkBg={true} />
        </div>
      )}
      {!canUpload && file ? (
        <div className="text-center my-5 relative flex items-center justify-between">
          <div className="flex items-center">
            <p>{file.name}</p>
          </div>
          {!uploading ? (
            <div className="cursor-pointer" onClick={() => setFile(undefined)}>
              <DeleteOutline htmlColor={colors.Orange} />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="flex justify-end gap-4 absolute right-[34px] bottom-[38px]">
        <Button disabled={uploading} onClickFunc={() => cancel()} text={"Cancel"} varient="Secondary" />
        {!canUpload ? <Button disabled={uploading} onClickFunc={handleUploadFile} text="Confirm" /> : null}
        {canUpload ? <Button disabled={uploading} onClickFunc={finalFormSubmit} text="Upload" /> : null}
      </div>
    </div>
  );
};

export default FormUploadLayout;
