import React, { useState, useEffect, useContext } from "react";
import { TextInput, GooglePlaceSearchComponent, Button, Checkbox } from "../../../components";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import checqueImg from "../../../assets/images/CHEQUE.png";
import iconsclose from "../../../assets/images/iconsclose.png";
import questionmark from "../../../assets/images/questionmark.png";
import { numberOnly, officeHstNonvalidation, officeHstvalidation } from "../../../utils";
import { Dialog } from "@mui/material";
import { UpdateOfficeBilling } from "../../../models/update-office-billing.model";
import { getOffice, updateOfficeBilling } from "../../../redux/slices/OfficeSlice";
import { formDataInitialState } from "./BillingInfoData";
import { formDataType, FormFieldType } from "./OfficeProfileData";
import { AuthPermission } from "../../../services/auth.service";

const STREET_KEY = "route";
const CITY_KEY = ["locality", "sublocality_level_1"];
const STATE_KEY = "administrative_area_level_1";
const ZIP_KEY = ["zip_code", "postal_code"];
const COUNTRY_KEY = "country";
const STREET_NUMBER_KEY = "street_number";
const UNIT = "subpremise";

interface GoogleAddressCompType {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GoogleAPIResponseType {
  address_components: GoogleAddressCompType[];
}

const getKeyValueOfAddress = (data: GoogleAPIResponseType | null, key: string | string[], longValue?: boolean) => {
  if (data === null) return "";

  if (Array.isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      const k = key[i];
      const addressComp: GoogleAddressCompType[] = data["address_components"];
      const keyArr = addressComp?.find(addrCom => addrCom.types.includes(k));
      if (keyArr !== undefined) {
        return keyArr[longValue ? "long_name" : "short_name"];
      }
    }
  } else {
    const addressComp: GoogleAddressCompType[] = data["address_components"];
    const keyArr = addressComp?.find(addrCom => addrCom.types.includes(key));
    return keyArr ? keyArr[longValue ? "long_name" : "short_name"] : "";
  }

  return "";
};

const validateAddress = (value: GoogleAddressCompType[]) => {
  const city = getKeyValueOfAddress({ address_components: value }, CITY_KEY);
  let streetNumber = getKeyValueOfAddress({ address_components: value }, STREET_NUMBER_KEY);
  if (typeof streetNumber !== "string") {
    streetNumber = "";
  }
  const street = getKeyValueOfAddress({ address_components: value }, STREET_KEY, true);
  const unit = getKeyValueOfAddress({ address_components: value }, UNIT);
  const state = getKeyValueOfAddress({ address_components: value }, STATE_KEY);
  const zip = getKeyValueOfAddress({ address_components: value }, ZIP_KEY[0])
    ? getKeyValueOfAddress({ address_components: value }, ZIP_KEY[0])
    : getKeyValueOfAddress({ address_components: value }, ZIP_KEY[1]);
  const country = getKeyValueOfAddress({ address_components: value }, COUNTRY_KEY);
  const addressObj = { city, state, street, zip, country, streetNumber, unit };
  if (!city || !street || !state || !zip || !country || !streetNumber) {
    return null;
  }

  return addressObj;
};

const BillingInfoTab = () => {
  const dispatch: AppDispatch = useDispatch();

  const [errorInitiated, setErrorInitiated] = useState(false);
  const [formData, setFormData] = useState(formDataInitialState);
  const toast = useContext(ToastContext);
  const { currentOffice, loading } = useSelector((state: RootState) => state.office);
  const [hstApplicable, setHstApplicable] = useState(currentOffice?.taxEnabled ? currentOffice?.taxEnabled : false);
  const [open, setOpen] = React.useState(false);
  const [addressValidate, setAddressValidate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const billingInfoChecked =
    currentOffice?.name === currentOffice?.billingName &&
    `${currentOffice?.billingStreet}, ${currentOffice?.billingCity}, ${currentOffice?.billingState}, ${currentOffice?.billingZip}, ${currentOffice?.billingCountry}` ===
      `${currentOffice?.street}, ${currentOffice?.city}, ${currentOffice?.state}, ${currentOffice?.zip}, ${currentOffice?.country}`;
  const [billingInfo, setBillingInfo] = useState(billingInfoChecked);
  const [billingInfoClicked, setBillingInfoClicked] = useState(false);

  const initialAddressData = [
    { short_name: `${currentOffice?.billingStreet}`, long_name: `${currentOffice?.billingStreet}`, types: ["street_number"] },
    { short_name: `${currentOffice?.billingStreet}`, long_name: `${currentOffice?.billingStreet}`, types: ["route"] },
    {
      short_name: `${currentOffice?.billingCity}`,
      long_name: `${currentOffice?.billingCity}`,
      types: ["sublocality_level_1", "sublocality", "political"],
    },
    { short_name: `${currentOffice?.billingCity}`, long_name: `${currentOffice?.billingCity}`, types: ["locality", "political"] },
    {
      short_name: `${currentOffice?.billingState}`,
      long_name: `${currentOffice?.billingState}`,
      types: ["administrative_area_level_2", "political"],
    },
    {
      short_name: `${currentOffice?.billingState}`,
      long_name: `${currentOffice?.billingState}`,
      types: ["administrative_area_level_1", "political"],
    },
    { short_name: `${currentOffice?.billingCountry}`, long_name: `${currentOffice?.billingCountry}`, types: ["country", "political"] },
    { short_name: `${currentOffice?.billingZip}`, long_name: `${currentOffice?.billingZip}`, types: ["postal_code"] },
  ];
  const [addressData, setAddressData] = useState({ address_components: initialAddressData || [] });

  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const permissions = currentUser?.permissions;

  const isAuthorizedToEdit = Array.isArray(permissions) && permissions.includes(AuthPermission.Office);

  useEffect(() => {
    if (currentOffice !== null) {
      setCanEdit(!currentOffice?.bankInstitution ?? false);
      const {
        billingStreet,
        billingCity,
        billingState,
        billingZip,
        billingCountry,
        billingStreet2,
        billingName,
        taxNumber,
        bankInstitution,
        bankTransit,
        bankAccount,
        wsibNumber,
        taxEnabled,
      } = currentOffice;

      const address = billingStreet ? `${billingStreet}, ${billingCity}, ${billingState}, ${billingZip}, ${billingCountry}` : "";
      const oldFormData = formDataInitialState;
      const updatedFormData = oldFormData.map(data => {
        let value = "";
        let valid = false;
        let required = data.required;
        switch (data.id) {
          case "officeAddress":
            value = address;
            valid = address.trim().length !== 0;
            break;
          case "companyName":
            value = billingName ?? "";
            valid = !!value;
            break;
          case "hstNumber":
            value = taxNumber ?? "";
            valid = !!value;
            required = !!taxEnabled;
            break;
          case "bankCode":
            value = bankInstitution ?? "";
            valid = !!value;
            break;
          case "transitNumber":
            value = bankTransit ?? "";
            valid = !!value;
            break;
          case "accountNumber":
            value = bankAccount ?? "";
            valid = !!value;
            break;
          case "wsibNumber":
            value = wsibNumber ?? "";
            valid = !!value;
            break;
          case "billingStreet2":
            value = billingStreet2 ?? ""; // Preserve the existing value if no matching case
            valid = true;
            break;
          default:
            value = data.value as string; // Preserve the existing value if no matching case
            valid = data.required || value.length ? !!value : true;
            break;
        }

        return { ...data, value, valid, required };
      });
      setFormData(updatedFormData);
    }
  }, [currentOffice]);

  useEffect(() => {
    if (billingInfoClicked) {
      if (billingInfo === true && currentOffice) {
        handleChange(
          "officeAddress",
          `${currentOffice.street}, ${currentOffice.city}, ${currentOffice.state}, ${currentOffice.zip}, ${currentOffice?.country}`
        );
        handleChange("companyName", currentOffice.name);
        setErrorInitiated(false);
        setAddressValidate(false);
      } else {
        handleChange("officeAddress", "");
        handleChange("companyName", "");
      }
    }
  }, [billingInfo, billingInfoClicked]);

  const handleChange = (id: string, value: string | boolean) => {
    const nonRequiredFlds = ["wsibNumber", "hstNumber"];
    const tempFormData = [...formData];

    const setInputValueAndValidity = (input: formDataType, value: string | boolean, isValid: boolean) => {
      input.value = value;
      input.valid = isValid;
    };

    tempFormData.forEach(input => {
      if (input.id === id) {
        switch (id) {
          case "street2":
            setInputValueAndValidity(input, numberOnly(value as string).toString(), !!numberOnly(value as string));
            break;
          case "bankCode":
            setInputValueAndValidity(input, value, (value as string).length === 3);
            break;
          case "hstNumber":
            if (hstApplicable) {
              if ((value as string).length <= 15) {
                setInputValueAndValidity(input, value, officeHstNonvalidation(value as string));
              } else if ((value as string).length > 15 && (value as string).length <= 17 && (value as string).includes(" ")) {
                setInputValueAndValidity(input, value, officeHstvalidation(value as string));
              }
            } else {
              setInputValueAndValidity(input, "", true);
            }
            break;
          case "accountNumber":
          case "wsibNumber":
            setInputValueAndValidity(input, value, (value as string).length <= 20);
            break;
          case "transitNumber":
            setInputValueAndValidity(input, value, (value as string).length <= 10);
            break;
          default:
            input.value = value;
            if (!nonRequiredFlds.includes(id)) {
              input.valid = typeof value === "string" ? value.length > 0 : value === true;
            }
            break;
        }
      }
    });

    setFormData(tempFormData);
  };

  const validateForm = () => {
    return formData.every(val => (val.required ? val.valid : true));
  };

  const onPlaceSelect = (places: any) => {
    let addressValue = "";
    places?.address_components?.forEach((data: any) => {
      addressValue += `${data.long_name},`;
    });
    const valid = !!validateAddress(places?.address_components);
    const tempFormData = [...formData];
    const index = tempFormData.findIndex(item => item.id === "officeAddress");
    const item = tempFormData.find(item => item.id === "officeAddress");
    if (item && index !== -1) {
      setFormData([
        ...tempFormData.slice(0, index),
        {
          ...item,
          value: addressValue,
          valid: valid,
        },
        ...tempFormData.slice(index + 1),
      ]);
    }
    setAddressData({ address_components: places?.address_components });
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value?.trim() === "" || e.target.value === null) {
      const tempFormData = [...formData];
      const index = tempFormData.findIndex(item => item.id === "officeAddress");
      const item = tempFormData.find(item => item.id === "officeAddress");
      if (item && index !== -1) {
        setFormData([
          ...tempFormData.slice(0, index),
          {
            ...item,
            value: "",
            valid: false,
          },
          ...tempFormData.slice(index + 1),
        ]);
      }
    }
  };

  const handlehstApplicable = (e: boolean) => {
    setHstApplicable(e);
    let tempFormData = [...formData];
    const index = tempFormData.findIndex(item => item.id === "hstNumber");
    const item = tempFormData.find(item => item.id === "hstNumber");
    if (index !== -1 && item) {
      tempFormData = [...tempFormData.slice(0, index), { ...item, valid: !e, required: e, value: "" }, ...tempFormData.slice(index + 1)];
    }
    setFormData(tempFormData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAddOfficeData = () => {
    return ["companyName", "hstNumber", "wsibNumber", "accountNumber", "bankCode", "transitNumber", "billingStreet2"]
      .map(key => {
        const foundVal = formData?.find(el => el.id === key)?.value as string;
        return {
          [key]: foundVal ? foundVal : "",
        };
      })
      .reduce((acc, el) => {
        return { ...acc, ...el };
      }, {});
  };

  const updateOfficeBillingData = (dataToSend: UpdateOfficeBilling) => {
    if (currentOffice) {
      dispatch(updateOfficeBilling(dataToSend)).then(async act => {
        if (act.payload !== null && currentOffice.officeId) {
          await dispatch(getOffice({ officeId: currentOffice.officeId }));
          toast?.openToast("Updated Successfully!");
          setCanEdit(false);
        }
      });
    }
  };

  const saveBillingInfo = () => {
    //toast?.openToast();
    if (currentOffice?.officeId) {
      const { companyName, hstNumber, wsibNumber, accountNumber, bankCode, transitNumber, billingStreet2 } = getAddOfficeData();
      if (!billingInfo) {
        const addressValidation = validateAddress(addressData.address_components);
        if (addressValidation === null) {
          setAddressValidate(true);
          return;
        }
        const streetName = `${(addressValidation.streetNumber + " " + addressValidation.street)
          .replace(` ${currentOffice?.street}`, "")
          .substring(0, 98)}${addressValidation?.unit ? ` #${addressValidation?.unit}` : ""}`;
        const dataToSend: UpdateOfficeBilling = {
          officeId: currentOffice?.officeId,
          billingName: companyName,
          billingStreet: streetName,
          billingCity: addressValidation.city,
          billingState: addressValidation.state,
          billingZip: addressValidation.zip,
          billingCountry: addressValidation.country,
          taxEnabled: hstApplicable,
          taxNumber: hstNumber.length ? hstNumber : undefined,
          wsibNumber: wsibNumber,
          bankAccount: accountNumber,
          bankInstitution: bankCode,
          bankTransit: transitNumber,
          billingStreet2,
        };
        setErrorInitiated(false);
        updateOfficeBillingData(dataToSend);
      } else {
        const dataToSend: UpdateOfficeBilling = {
          officeId: currentOffice?.officeId,
          billingName: currentOffice?.name,
          billingStreet: currentOffice?.street,
          billingCity: currentOffice?.city,
          billingState: currentOffice?.state,
          billingZip: currentOffice?.zip,
          billingCountry: currentOffice?.country,
          taxEnabled: hstApplicable,
          taxNumber: hstNumber.length ? hstNumber : undefined,
          wsibNumber: wsibNumber,
          bankAccount: accountNumber,
          bankInstitution: bankCode,
          bankTransit: transitNumber,
          billingStreet2,
        };
        setErrorInitiated(false);
        updateOfficeBillingData(dataToSend);
      }
    }
  };

  const renderInput = (inp: formDataType, isReadOnly: boolean) => {
    switch (inp.type) {
      case FormFieldType.TextInput:
        return (
          <div className="col-span-2 md:col-span-1">
            {isReadOnly ? (
              <div className="w-full">
                <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">{inp.title}</label>
                <h2 className="text-lg font-medium text-docsigna-purple">{inp.value}</h2>
              </div>
            ) : (
              <TextInput
                key={inp.id}
                value={inp.value as string}
                onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
                placeholder={inp.placeholder}
                errorMsg={inp.errorMessage}
                isError={!inp.valid && errorInitiated}
                label={inp.title}
                isRequired={inp.required}
                disabled={inp.id === "companyName" && billingInfo ? true : false}
                onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
              />
            )}
          </div>
        );
      case FormFieldType.WSIB:
        return (
          <div className="col-span-2 md:col-span-1">
            {isReadOnly ? (
              <div className="w-full">
                <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">{inp.title}</label>
                <h2 className="text-lg font-medium text-docsigna-purple">{inp.value}</h2>
              </div>
            ) : (
              <TextInput
                key={inp.id}
                value={inp.value as string}
                onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
                placeholder={inp.placeholder}
                errorMsg={inp.errorMessage}
                isError={!inp.valid && errorInitiated}
                label={inp.title}
                isRequired={inp.required}
                onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
              />
            )}
          </div>
        );
      case FormFieldType.HST:
        return (
          <div className="col-span-2 md:col-span-1">
            {isReadOnly ? (
              hstApplicable ? (
                <div className="w-full">
                  <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">{inp.title}</label>
                  <h2 className="text-lg font-medium text-docsigna-purple">{inp.value}</h2>
                </div>
              ) : null
            ) : (
              <>
                <Checkbox
                  label={"HST applicable on services and fees for this office"}
                  checked={hstApplicable}
                  onChange={e => {
                    handlehstApplicable(e.currentTarget.checked);
                  }}
                />
                <div className=" mt-2">
                  {hstApplicable ? (
                    <TextInput
                      key={inp.id}
                      value={inp.value as string}
                      onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
                      placeholder={inp.placeholder}
                      errorMsg={inp.errorMessage}
                      isError={!inp.valid && errorInitiated}
                      label={inp.title}
                      isRequired={hstApplicable}
                      onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        );

      case FormFieldType.Places:
        return !billingInfo ? (
          <div className="col-span-2 md:col-span-1">
            {isReadOnly ? (
              <div className="w-full">
                <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">{inp.title}</label>
                <h2 className="text-lg font-medium text-docsigna-purple">{inp.value}</h2>
              </div>
            ) : (
              <GooglePlaceSearchComponent
                //TODO: make address object while calling the update api
                label={inp.title}
                onPlaceSelected={addr => {
                  onPlaceSelect(addr);
                }}
                onChange={onAddressChange}
                defaultValue={!billingInfoClicked ? (inp.value as string) : ""}
                isRequired={inp.required}
                errorMsg={inp.errorMessage}
                isError={(!inp.valid && errorInitiated) || addressValidate}
                placeholder={inp.placeholder}
                disabled={inp.id === "companyName" && billingInfo ? true : false}
              />
            )}
          </div>
        ) : (
          <div className="col-span-2 md:col-span-1">
            {isReadOnly ? (
              <div className="w-full">
                <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">{inp.title}</label>
                <h2 className="text-lg font-medium text-docsigna-purple">{inp.value}</h2>
              </div>
            ) : (
              <TextInput
                key={inp.id}
                value={inp.value as string}
                onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
                placeholder={inp.placeholder}
                errorMsg={inp.errorMessage}
                isError={!inp.valid && errorInitiated}
                label={inp.title}
                isRequired={inp.required}
                disabled={billingInfo}
                onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
              />
            )}
          </div>
        );

      case FormFieldType.Number:
        return (
          <div className="col-span-2 md:col-span-1">
            {isReadOnly ? (
              <div className="w-full">
                <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">{inp.title}</label>
                <h2 className="text-lg font-medium text-docsigna-purple">{inp.value}</h2>
              </div>
            ) : (
              <>
                <div className="flex">
                  <label className="text-sm text-gray-700 block w-full mb-0.5">
                    {inp.title}
                    {inp.required ? <span className="text-red-500">*</span> : null}
                  </label>
                  {inp.id !== "wsibNumber" ? (
                    <div
                      className="flex justify-center items-center mr-2 mb-1 w-4 h-4 aspect-square bg-docsigna-orange rounded-full"
                      onClick={() => setOpen(true)}>
                      <img src={questionmark} className={`h-3 `} alt="" />
                    </div>
                  ) : null}
                </div>

                <TextInput
                  key={inp.id}
                  value={inp.value as string}
                  onChangeFunc={e => handleChange(inp.id, e.currentTarget.value)}
                  placeholder={inp.placeholder}
                  errorMsg={inp.errorMessage}
                  isError={!inp.valid && errorInitiated}
                  onBlur={e => handleChange(inp.id, e.currentTarget.value.trim())}
                />
              </>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="relative md:flex gap-5">
      <div>
        <div className="flex border-b border-docsigna-purple border-opacity-40 py-1 my-2 col-span-2">
          <h2 className="uppercase text-lg font-bold">billing Information</h2>
        </div>
        {canEdit ? (
          <div className="py-2 font-medium">
            <Checkbox
              id="billing-info"
              checked={billingInfo}
              onChange={e => {
                setBillingInfo(e.currentTarget.checked);
                setBillingInfoClicked(true);
              }}
              label="Billing contact info is the same as office profile"
            />
          </div>
        ) : null}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
          {formData.map(input => {
            return (
              <>
                {renderInput(input, !canEdit)}
                {input.id !== "officeAddress" && input.id !== "billingStreet2" ? <div className="col-span-2 md:col-span-1"></div> : null}
              </>
            );
          })}
        </div>
      </div>
      <div className="py-2">
        {isAuthorizedToEdit ? (
          canEdit ? (
            <Button
              AdditionalClassNames="profile-btn"
              width="fit"
              onClickFunc={() => {
                setErrorInitiated(true);
                if (validateForm()) saveBillingInfo();
              }}
              disabled={(!validateForm() && errorInitiated) || loading}
              varient={loading ? "loading" : "Primary"}
              text="Save"
            />
          ) : (
            <Button
              AdditionalClassNames="profile-btn"
              width="fit"
              onClickFunc={() => {
                setCanEdit(true);
              }}
              text="Edit"
            />
          )
        ) : null}
      </div>
      <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
        <div className="relative fit-content">
          {" "}
          <img src={checqueImg} alt="" />
          <span className="modal-close absolute top-2 right-1 w-7 h-7" onClick={handleClose}>
            <img src={iconsclose} alt="" className="w-5 h-5 cursor-pointer" />
          </span>
        </div>
      </Dialog>
    </div>
  );
};

export default BillingInfoTab;
