import React from "react";
import { formatDate } from "../../utils";
import { RequestMessage } from "../../models/request-message.model";

interface CommentPropType {
  from: string;
  text: string;
  time?: string;
  badgeText?: string;
  onMentionClick?: (id: string) => void;
  disableMentions?: boolean;
  staff?: any;
  showReadAction?: boolean;
  comment: RequestMessage;
  readAction?: (id: string, read: boolean) => void;
}

const Mention = ({ name }: { id: string; name: string; onMentionClick: CommentPropType["onMentionClick"] }) => {
  return <span className="text-sm text-docsigna-purple-dark">@{name}</span>;
};

const Comment = ({ from, text, badgeText, time, onMentionClick = () => {}, staff }: CommentPropType) => {
  const regex = /\[##(.*?)|@(.*?)##]/g;
  const result = text
    .split(regex)
    .map(item => item && item.trim())
    .filter(item => item && item.split("-").length === 1);

  const newMention = staff?.map((element: any) => {
    return element.display;
  });

  const temp = result?.length
    ? result.map(t => {
        if (newMention?.includes(t as string)) {
          return [" ", <Mention key={t.toString()} id={t.toString()} name={t.toString()} onMentionClick={onMentionClick} />];
        }
        return " " + t;
      })
    : text;
  return (
    <div>
      <p className="text-xs font-medium text-gray-600 mb-2">
        <span className="text-docsigna-blue-light">
          {from} {time ? `sent on ${formatDate(time, "PP, h:mm aaa")}` : null}
        </span>
        {badgeText?.length ? (
          <span className="inline-block text-xs text-docsigna-blue-light font-medium py-1 px-2 md:ml-2 bg-docsigna-purple-light rounded-full">
            {badgeText}
          </span>
        ) : null}
      </p>
      <p className="text-sm mb-5">{temp}</p>
    </div>
  );
};

export default Comment;
