import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import PaymentDetailSummaryBox from "./PaymentDetailSummaryBox";
import { ServiceBilledTo } from "../../../models/service.model";
import { useParams } from "react-router-dom";
import { RequestState, RequestType } from "../../../models/request.model";
import { formatCurrency, priceReview } from "../../../utils";
import moment from "moment";

const PaymentSummaryBox = ({
  showPaymentDetails,
  isPatient = false,
  setShowRefundOption,
}: {
  showPaymentDetails?: boolean;
  isPatient?: boolean;
  setShowRefundOption?: (val: boolean) => void;
}) => {
  const { patientRequestId } = useParams();
  const { patientRequestData } = useSelector((state: RootState) => state.user);
  const { RequestData } = useSelector((state: RootState) => state.onBoarding);
  const { invoiceObject } = useSelector((state: RootState) => state.officeRequest);

  const isCancelled =
    isPatient &&
    patientRequestData?.service?.serviceBilledTo &&
    [ServiceBilledTo.Patient, ServiceBilledTo.DocnoteDebit].includes(patientRequestData?.service?.serviceBilledTo) &&
    patientRequestData?.state === RequestState.Cancelled;
  const showPaymentStatusGuest =
    (RequestData?.serviceBilledToOverride
      ? RequestData?.serviceBilledToOverride === ServiceBilledTo.Patient
      : RequestData?.service?.serviceBilledTo === ServiceBilledTo.Patient) || RequestData?.requestType === RequestType.DocnoteDebit;
  const showPaymentStatusPatient =
    (patientRequestData?.serviceBilledToOverride
      ? patientRequestData?.serviceBilledToOverride === ServiceBilledTo.Patient
      : patientRequestData?.service?.serviceBilledTo === ServiceBilledTo.Patient) || RequestData?.requestType === RequestType.DocnoteDebit;
  const patientRefunded = patientRequestData?.refunded && patientRequestData?.state !== RequestState.PendingPayment;
  const showPayButton =
    patientRefunded ||
    RequestData?.refunded ||
    (patientRequestData && patientRequestData?.paymentComplete) ||
    (RequestData && RequestData.paymentComplete)
      ? false
      : true;
  const billedToPatient = patientRequestData?.serviceBilledToOverride
    ? patientRequestData?.serviceBilledToOverride === ServiceBilledTo.Patient
    : patientRequestData?.service?.serviceBilledTo === ServiceBilledTo.Patient;
  const { total } = priceReview(invoiceObject ?? undefined);

  return (
    <>
      <div className="w-full md:max-w-xs">
        <div className="w-full bg-docsigna-pink-light border border-docsigna-pink rounded-lg mb-3">
          <div className="w-full ">
            {showPaymentDetails ? (
              <>
                {isPatient ? (
                  <PaymentDetailSummaryBox
                    officeId={patientRequestData ? patientRequestData?.officeId : ""}
                    requestId={patientRequestId ? patientRequestId : ""}
                    discountType={patientRequestData?.discountType}
                    showPayButton={showPayButton}
                    cancelled={isCancelled}
                  />
                ) : (
                  <PaymentDetailSummaryBox isGuest={true} discountType={RequestData?.discountType} showPayButton={showPayButton} />
                )}
              </>
            ) : (
              <div className="w-full md:max-w-xs">
                <div className="w-full bg-docsigna-pink-light mb-3">
                  <div className="w-full py-3 px-4">
                    <p className="text-md mb-2">
                      <span className="font-semibold">Payment Details</span>
                    </p>
                    {showPaymentStatusPatient || showPaymentStatusGuest ? (
                      <p className="text-sm ">
                        <span className="font-semibold">Not available yet</span>
                      </p>
                    ) : (
                      <p className="text-sm ">
                        <span className="font-semibold">No payment due</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {showPaymentDetails && isPatient && billedToPatient && total > 0 ? (
              patientRefunded || patientRequestData?.refunded || patientRequestData?.refundRequest ? null : (patientRequestData &&
                  patientRequestData?.paymentComplete &&
                  moment().diff(patientRequestData?.paymentDate, "days") < 30) ||
                (RequestData && RequestData.paymentComplete && moment().diff(RequestData?.paymentDate, "days") < 30) ? (
                <div className="px-4 pb-3 flex justify-end">
                  <p
                    className="text-md text-docsigna-orange cursor-pointer"
                    onClick={() => (setShowRefundOption ? setShowRefundOption(true) : undefined)}>
                    Request Refund
                  </p>
                </div>
              ) : null
            ) : null}
            {patientRequestData?.refunded ? (
              <div className="px-4 pb-3 flex justify-between">
                <h2 className="text-red-600 font-bold">Payment Refunded</h2>
                <h2 className="text-red-600 font-bold">-{formatCurrency(total ? total.toString() : "0", "blur")}</h2>
              </div>
            ) : null}
          </div>
          {showPaymentDetails && !isPatient ? (
            patientRefunded || RequestData?.refunded ? null : (patientRequestData && patientRequestData?.paymentComplete) ||
              (RequestData && RequestData.paymentComplete) ? (
              <div className="w-full bg-white rounded-lg rounded-t-none py-3 px-4">
                <p className="text-md">
                  <span className="text-md font-small font-bold">Payment Complete</span>
                </p>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PaymentSummaryBox;
