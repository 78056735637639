import { ApiPage, emptyApiPage } from "../models/api-page.model";
import { Contact, CreateContact } from "../models/contact-model";
import { apiService } from "./api.service";

export class ContactService {
  private static BASE_PATH = "/api/v1/offices";

  static async getContact(contactId: string, officeId: string): Promise<Contact | null> {
    const response = await apiService.get(`${this.BASE_PATH}/${officeId}/contacts/${contactId}`);
    if (!response.success) return null;

    return response.data as Contact;
  }

  static async searchContacts(officeId:string, page = 0, size = 10, sort = "firstName,asc", term?:string): Promise<ApiPage<Contact>> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/contacts/search?page=${page}&size=${size}&sort=${sort}`, {
        searchTerm: term,
    });
    if (!response.success) return emptyApiPage<Contact>();

    return response.data as ApiPage<Contact>;
  }

  // Returns saved office with ID included, or null on failure
  static async addContact(contact: CreateContact, officeId: string): Promise<Contact | null> {
    const response = await apiService.post(`${this.BASE_PATH}/${officeId}/contacts`, contact);
    if (!response.success) return null;

    return response.data as Contact;
  }

  static async updateContact(contact: CreateContact, officeId:string, contactId: string): Promise<Contact | null> {
    const response = await apiService.put(`${this.BASE_PATH}/${officeId}/contacts/${contactId}`, contact);
    if (!response.success) return null;

    return response.data as Contact;
  }


  static async deleteContact(officeId: string, contactId: string): Promise<boolean> {
    const response = await apiService.delete(`${this.BASE_PATH}/${officeId}/contacts/${contactId}`);
    if (!response.success) return false;

    return true;
  }
}
