import React from "react";
import { colors } from "../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
interface TextInputPropType {
  onChangeFunc: (e: React.ChangeEvent<any>) => void;
  value: string;
  placeholder?: string;
  isRequired?: boolean;
  errorMsg?: string;
  isPassword?: boolean;
  name?: string;
  label?: string;
  isError?: boolean;
  autoFocus?: boolean;
  maxLenght?: number | undefined;
  isFocused?: boolean;
  onFocus?: (e: React.FocusEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => void;
  isTextArea?: boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  extraInputClass?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  disabled?: boolean;
  showCounter?: boolean;
  counterMessage?: string;
  isNumber?: boolean;
  readonly?: boolean;
  isSearchInput?: boolean;
  isDarkBg?: boolean;
}
const TextInput = ({
  onChangeFunc,
  value = "",
  label = "",
  placeholder = "",
  isRequired = false,
  errorMsg = "",
  isPassword = false,
  name = "",
  isError = false,
  autoFocus = false,
  isFocused = false,
  maxLenght,
  onFocus = () => {},
  isTextArea = false,
  onBlur = () => {},
  onKeyUp = () => {},
  extraInputClass = "",
  onClick = () => {},
  disabled = false,
  showCounter = false,
  counterMessage = "",
  isNumber = false,
  readonly,
  isSearchInput = false,
  isDarkBg = false,
}: TextInputPropType) => {
  return (
    <>
      {label ? (
        <label className="text-sm text-docsigna-blue-dark font-medium block w-full max-w-full mb-1">
          {label}
          {isRequired ? <span className="text-red-500">*</span> : null}
        </label>
      ) : null}
      <div className="block w-auto max-w-full relative">
        {isTextArea ? (
          <textarea
            name={name}
            ref={textInput => (isFocused ? textInput?.focus() : null)}
            autoFocus={autoFocus}
            className={`text-base text-docsigna-blue-dark font-medium block w-full rounded-md shadow-none border border-docsigna-blue-dark placeholder-gray-400 ${extraInputClass} ${
              isDarkBg ? "bg-docsigna-pink-light" : ""
            }`}
            placeholder={placeholder}
            onChange={onChangeFunc}
            value={value}
            maxLength={maxLenght}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            disabled={disabled}
            readOnly={readonly}
          />
        ) : (
          <input
            name={name}
            ref={textInput => {
              isFocused && textInput?.focus();
            }}
            autoFocus={autoFocus}
            type={isPassword ? "password" : isNumber ? "number" : "text"}
            className={`block w-full text-docsigna-blue-dark font-medium leading-none rounded-md shadow-none border border-docsigna-blue-dark p-2.5 ${extraInputClass} ${
              isSearchInput ? "pl-8" : ""
            } ${isDarkBg ? "bg-docsigna-pink-light" : ""}`}
            placeholder={placeholder}
            onChange={onChangeFunc}
            value={value}
            maxLength={maxLenght}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={e => onClick(e)}
            onKeyUp={onKeyUp}
            disabled={disabled}
            readOnly={readonly}
          />
        )}
        {showCounter ? <p className="block text-sm text-gray-500 text-right">{`${value.length} / ${counterMessage}`}</p> : null}
        {isError ? <p style={{ color: colors.Red }}>{errorMsg}</p> : <p></p>}
        {isSearchInput ? (
          <div className="absolute top-0 bottom-0 left-3 flex items-center">
            <SearchIcon htmlColor={colors.CyanBlue} style={{ fontSize: "18px", marginTop: "2px" }} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TextInput;
