import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ApiErrorCode } from "../../models/api-error.model";
import { ArchiveState, Request, RequestPaymentMethod, RequestState } from "../../models/request.model";
import { ApiPage } from "../../models/api-page.model";
import { OfficeService } from "../../services/office.service";
import { RequestCounts } from "../../models/request-counts.model";
import { RequestFile } from "../../models/request-file.model";
import { logger } from "../../utils/logger";
import { PriceDetails } from "../../models/price-details.model";
import { RequestFileTypes } from "../../models/ds-file.model";

const log = logger.getLogger("OfficeRequestErrors");
interface OfficeRequestDataForRender extends Request {
  tooltipVisible: boolean;
}

type OfficeRequestList = {
  sortedBy: string;
  data: ApiPage<OfficeRequestDataForRender> | null;
};

type listOfficeRequestResponse = { data: ApiPage<Request>; sortType: string; page: number; size: number };
// type requestDetailsResponse = {data:ApiPage<Request>;}

interface sortObj {
  start: number;
  end: number;
  pageNumber: number;
  rowsPerPage: number;
  sortedBy: string;
}

export interface requestFilesType extends RequestFile {
  tooltipVisible: boolean;
}
export interface OfficeRequestStateType {
  error: boolean;
  errorType: ApiErrorCode;
  loading: boolean;
  sentToPatientLoading: boolean;
  pending: boolean;
  apiSuccess: boolean;
  officeRequestList: OfficeRequestList;
  sort: sortObj;
  requestData: Request | null;
  requestSaved: boolean;
  invoiceObject: PriceDetails | null;
  requestFiles: requestFilesType[];
  requestFile: requestFilesType | null;
  addedFiles: string[];
  pageLoading: boolean;
  requestCounts: RequestCounts | null;
  assignRequestError: boolean;
  paymentSucceded: boolean;
}

export interface ListOfficeRequestsType {
  officeId: string;
  page: number;
  sort: string;
  size: number;
  assignedTo?: string;
}

export interface SearchOfficeRequestsType {
  searchTerm: string;
  officeId: string;
  page: number;
  sort: string;
  size: number;
  assignedTo?: string;
  state?: RequestState;
  urgent?: boolean;
  archiveState?: ArchiveState;
}

const initialState: OfficeRequestStateType = {
  loading: false,
  pending: false,
  sentToPatientLoading: false,
  error: false,
  errorType: ApiErrorCode.UNKNOWN,
  apiSuccess: false,
  officeRequestList: { data: null, sortedBy: "" },
  requestData: null,
  requestSaved: false,
  sort: {
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "lastName,desc",
  },
  requestFiles: [],
  requestFile: null,
  invoiceObject: null,
  addedFiles: [],
  pageLoading: false,
  assignRequestError: false,
  requestCounts: null,
  paymentSucceded: false,
};

export const sendRequestFileToPatient = createAsyncThunk(
  "officeRequest/sendToPatient",
  async ({ officeId, requestId, requestFileId }: { officeId: string; requestId: string; requestFileId: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.sendRequestFileToPatient(officeId, requestId, requestFileId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const sendRequestFileToThirdParty = createAsyncThunk(
  "officeRequest/sendToThirdParty",
  async ({ officeId, requestId, requestFileId }: { officeId: string; requestId: string; requestFileId: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.sendRequestFileToThirdParty(officeId, requestId, requestFileId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);
export const getOfficeRequestInvoice = createAsyncThunk(
  "officeRequest/getOfficeRequestInvoice",
  async ({ officeId, requestId }: { officeId: string; requestId: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeRequestPriceDetails(officeId, requestId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const createOfficeRequestInvoice = createAsyncThunk(
  "officeRequest/createOfficeRequestInvoice",
  async ({ officeId, requestId, notes }: { officeId: string; requestId: string; notes: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.createOfficeRequestInvoice(officeId, requestId, notes);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const getOfficeRequestFiles = createAsyncThunk(
  "requestApi/getFiles",
  async ({ officeId, requestId }: { officeId: string; requestId: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeRequestFiles(officeId, requestId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const deleteOfficeRequestFile = createAsyncThunk(
  "requestApi/deleteFile",
  async ({ officeId, requestId, fileId }: { officeId: string; requestId: string; fileId: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.deleteOfficeRequestFile(officeId, requestId, fileId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
  }
);

export const getUrgentOfficeRequests = createAsyncThunk(
  "officeRequest/getUrgentOfficeRequests",
  async (
    { officeId, page, size, sort, assignedTo }: { officeId: string; page: number; size: number; sort: string; assignedTo?: string },
    { rejectWithValue }
  ) => {
    try {
      const res = OfficeService.getUrgentOfficeRequests(officeId, assignedTo, page, size, sort.length > 0 ? sort : undefined);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      rejectWithValue(e);
    }
  }
);

export const getOfficeRequestsByState = createAsyncThunk(
  "officeRequest/getRequestsByState",
  async (
    {
      officeId,
      state,
      page,
      size,
      sort,
      assignedTo,
    }: { officeId: string; state: RequestState; page: number; size: number; sort: string; assignedTo?: string },
    { rejectWithValue }
  ) => {
    try {
      const res = OfficeService.getOfficeRequestsByState(officeId, state, assignedTo, page, size, sort);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      rejectWithValue(e);
    }
  }
);

export const getOfficeRequestCounts = createAsyncThunk(
  "officeRequest/getRequestCount",
  async ({ officeId, assignedTo, archiveState }: { officeId: string; assignedTo?: string; archiveState?: ArchiveState }, { rejectWithValue }) => {
    try {
      const res = OfficeService.getOfficeRequestCounts(officeId, assignedTo, archiveState);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      rejectWithValue(e);
      return null;
    }
  }
);

export const listOfficeRequests = createAsyncThunk(
  "officeRequest/list",
  async ({ officeId, page, sort, size, assignedTo }: ListOfficeRequestsType, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeRequests(officeId, assignedTo, page, size, sort.length > 0 ? sort : undefined);
      return { data: res, sortType: sort, page, size };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const searchOfficeRequests = createAsyncThunk(
  "officeRequest/search",
  async ({ searchTerm, officeId, page, sort, size, assignedTo, state, urgent, archiveState }: SearchOfficeRequestsType, { rejectWithValue }) => {
    try {
      const res = await OfficeService.searchOfficeRequests(
        officeId,
        searchTerm,
        assignedTo,
        state ?? undefined,
        urgent,
        archiveState,
        page,
        size,
        sort.length > 0 ? sort : undefined
      );
      return { data: res, sortType: sort, page, size };
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getOfficeRequest = createAsyncThunk(
  "officeRequest/get",
  async ({ officeId, requestId }: { officeId: string; requestId: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeRequest(officeId, requestId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const addOfficeRequestFiles = createAsyncThunk(
  "officeRequest/addRequestFile",
  async ({ officeId, requestId, fileIds, requestFileType }: { officeId: string; requestId: string; fileIds: string[]; requestFileType?: RequestFileTypes }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.addOfficeRequestFiles(officeId, requestId, fileIds, requestFileType);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      rejectWithValue(e);
      return null;
    }
  }
);

export const setOfficeRequestUrgent = createAsyncThunk(
  "officeRequest/setOfficeRequestUrgent",
  async ({ officeId, requestId, urgent }: { officeId: string; requestId: string; urgent: boolean }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.setOfficeRequestUrgent(officeId, requestId, urgent);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      rejectWithValue(e);
      return null;
    }
  }
);

export const setOfficeRequestState = createAsyncThunk(
  "officeRequest/setOfficeRequestState",
  async ({ officeId, requestId, state }: { officeId: string; requestId: string; state: RequestState }, { rejectWithValue }) => {
    try {
      const res = OfficeService.setOfficeRequestState(officeId, requestId, state);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      rejectWithValue(e);
      return null;
    }
  }
);

export const declineOfficeRequest = createAsyncThunk(
  "userApi/declineOfficeRequest",
  async ({ officeId, requestId, reason }: { officeId: string; requestId: string; reason: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.declineOfficeRequest(officeId, requestId, reason);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const addOfficeRequestFinalDocuments = createAsyncThunk(
  "officeRequest/addOfficeRequestFinalDocuments",
  async ({ officeId, requestId, fileIds }: { officeId: string; requestId: string; fileIds: string[] }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.addOfficeRequestFinalDocuments(officeId, requestId, fileIds);
      return res;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const setOfficeRequestPaid = createAsyncThunk(
  "officeRequest/setOfficeRequestPaid",
  async (
    {
      officeId,
      requestId,
      paymentMethod,
      paymentDetails,
      overridePrice
    }: { officeId: string; requestId: string; paymentMethod: RequestPaymentMethod; paymentDetails?: string; overridePrice?: number },
    { rejectWithValue }
  ) => {
    try {
      const res = OfficeService.setOfficeRequestPaid(officeId, requestId, paymentMethod, new Date(), paymentDetails, overridePrice);
      return res;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const setOfficeRequestSubmitted = createAsyncThunk(
  "officeRequest/setOfficeRequestSubmitted",
  async ({ officeId, requestId, status }: { officeId: string; requestId: string; status: boolean }, { rejectWithValue }) => {
    try {
      const res = OfficeService.setOfficeRequestSubmitted(officeId, requestId, status);
      return res;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const undoOfficePaymentStatus = createAsyncThunk(
  "officeRequest/undoOfficePaymentStatus",
  async ({ officeId, requestId, status }: { officeId: string; requestId: string; status: boolean }, { rejectWithValue }) => {
    try {
      const res = OfficeService.undoOfficePaymentStatus(officeId, requestId, status);
      return res;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const approveRejectRefundRequest = createAsyncThunk(
  "officeRequest/approveRejectRefundRequest",
  async ({ officeId, requestId, refundRequestId, action, reason }: { officeId: string; requestId: string; refundRequestId: string; action: boolean; reason?: string }, { rejectWithValue }) => {
    try {
      let res;
      if (action) {
        res = OfficeService.approveRefundRequest(officeId, requestId, refundRequestId);
      } else {
        res = OfficeService.rejectRefundRequest(officeId, requestId, refundRequestId, reason ?? '');
      }  
      return res;
    } catch (error) {
      log.warn(JSON.stringify(error));
      return rejectWithValue(error);
    }
  }
);

export const setOfficeRefundReason = createAsyncThunk(
  "userApi/setOfficeRefundReason",
  async ({ officeId, requestId, reason }: { officeId: string; requestId: string; reason: string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.setOfficeRefundReason(officeId, requestId, reason);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const OfficeRequestSlice = createSlice({
  name: "officeRequest",
  initialState,
  reducers: {
    setPaymentSucceded: (state, action) => {
      state.paymentSucceded = action.payload;
    },
    clearInvoiceObject: state => {
      state.invoiceObject = null;
    },
    setRequestSaved: (state, action) => {
      state.requestSaved = action.payload;
    },
    clearOfficeRequestList: state => {
      state.officeRequestList = { data: null, sortedBy: "" };
    },
    listRequests: state => {
      state.error = false;
      state.errorType = ApiErrorCode.UNKNOWN;
      state.loading = false;
      state.apiSuccess = false;
    },
    setRequestData: (state, action) => {
      state.requestData = action.payload;
    },
    clearRequestData: state => {
      state.requestData = null;
      state.requestFiles = [];
    },
    clearRequestFiles: state => {
      state.requestFiles = [];
    },
    toggleToolip: (state, action: PayloadAction<{ visible: boolean; fileId?: string }>) => {
      if (action.payload.visible === false) {
        state.requestFiles = state.requestFiles.map(req => ({ ...req, tooltipVisible: false }));
      } else {
        state.requestFiles = state.requestFiles.map(req => ({ ...req, tooltipVisible: req.requestFileId === action.payload.fileId }));
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(listOfficeRequests.pending, state => {
        if (state.officeRequestList.data === null) {
          state.loading = true;
          state.pageLoading = true;
        }
        state.error = true;
      })
      .addCase(listOfficeRequests.fulfilled, (state, action: PayloadAction<listOfficeRequestResponse>) => {
        state.loading = false;
        state.error = false;
        state.officeRequestList.data = {
          ...action.payload.data,
          content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })),
        };
        const { sortType } = action.payload;
        state.sort.pageNumber = action.payload.page;
        state.officeRequestList.sortedBy = sortType;
        state.pageLoading = false;
      })
      .addCase(listOfficeRequests.rejected, state => {
        state.error = true;
        state.loading = false;
        state.pageLoading = false;
      })
      .addCase(searchOfficeRequests.pending, state => {
        if (state.officeRequestList.data === null) {
          state.loading = true;
        }
        state.error = false;
      })
      .addCase(searchOfficeRequests.fulfilled, (state, action: PayloadAction<listOfficeRequestResponse>) => {
        state.loading = false;
        state.error = false;
        state.officeRequestList.data = {
          ...action.payload.data,
          content: action.payload.data.content.map(data => ({ ...data, tooltipVisible: false })),
        };
        const { sortType } = action.payload;
        state.sort.pageNumber = action.payload.page;
        state.officeRequestList.sortedBy = sortType;
      })
      .addCase(searchOfficeRequests.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getOfficeRequest.pending, state => {
        if (state.officeRequestList.data === null) {
          state.loading = true;
        }
        state.error = false;
      })
      .addCase(getOfficeRequest.fulfilled, (state, action: PayloadAction<Request | null>) => {
        state.loading = false;
        state.error = false;
        state.requestData = action.payload;
      })
      .addCase(getOfficeRequest.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getOfficeRequestCounts.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOfficeRequestCounts.fulfilled, (state, action: PayloadAction<RequestCounts | null>) => {
        state.loading = false;
        state.error = false;
        state.requestCounts = action.payload;
      })
      .addCase(getOfficeRequestCounts.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getOfficeRequestsByState.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getOfficeRequestsByState.fulfilled, (state, action: PayloadAction<ApiPage<Request> | undefined>) => {
        state.loading = false;
        if (action.payload) {
          state.error = false;
          state.officeRequestList.data = { ...action.payload, content: action.payload.content.map(d => ({ ...d, tooltipVisible: false })) };
          //  action.payload.content.map(d => ({ ...d, tooltipVisible: false }));
        }
      })
      .addCase(getOfficeRequestsByState.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getUrgentOfficeRequests.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUrgentOfficeRequests.fulfilled, (state, action: PayloadAction<ApiPage<Request> | undefined>) => {
        state.loading = false;
        if (action.payload) {
          state.error = false;
          state.officeRequestList.data = { ...action.payload, content: action.payload.content.map(d => ({ ...d, tooltipVisible: false })) };
          //  action.payload.content.map(d => ({ ...d, tooltipVisible: false }));
        }
      })
      .addCase(getUrgentOfficeRequests.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getOfficeRequestFiles.fulfilled, (state, action: PayloadAction<RequestFile[]>) => {
        if (action.payload.length) {
          const dataForState = action.payload.map(d => ({ ...d, tooltipVisible: false }));
          state.requestFiles = dataForState;
          state.loading = false;
        } else {
          state.loading = false;
          state.error = true;
        }
      })
      .addCase(getOfficeRequestFiles.pending, state => {
        state.loading = true;
      })
      .addCase(getOfficeRequestFiles.rejected, state => {
        state.requestFiles = [];
        state.loading = false;
      })

      .addCase(getOfficeRequestInvoice.fulfilled, (state, action: PayloadAction<PriceDetails | null>) => {
        if (action.payload !== null) {
          state.invoiceObject = action.payload;
          state.pending = false;
        }
      })
      .addCase(getOfficeRequestInvoice.pending, state => {
        state.pending = true;
      })
      .addCase(getOfficeRequestInvoice.rejected, state => {
        state.requestFiles = [];
        state.pending = false;
      })
      .addCase(createOfficeRequestInvoice.fulfilled, (state, action: PayloadAction<PriceDetails | null>) => {
        if (action.payload !== null) state.invoiceObject = action.payload;
      })
      .addCase(createOfficeRequestInvoice.pending, state => {
        state.loading = true;
      })
      .addCase(createOfficeRequestInvoice.rejected, state => {
        state.requestFiles = [];
        state.loading = false;
      })

      .addCase(addOfficeRequestFiles.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.requestData = action.payload;
      })
      .addCase(addOfficeRequestFiles.pending, state => {
        state.loading = true;
      })
      .addCase(addOfficeRequestFiles.rejected, state => {
        state.requestFiles = [];
        state.loading = false;
      })
      .addCase(deleteOfficeRequestFile.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.requestData = action.payload;
        state.loading = false;
      })
      .addCase(deleteOfficeRequestFile.pending, state => {
        state.loading = true;
      })
      .addCase(deleteOfficeRequestFile.rejected, state => {
        state.requestFiles = [];
        state.loading = false;
      })
      .addCase(setOfficeRequestUrgent.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.requestData = action.payload;
        state.loading = false;
      })
      .addCase(setOfficeRequestUrgent.pending, state => {
        state.loading = true;
      })
      .addCase(setOfficeRequestUrgent.rejected, state => {
        state.requestFiles = [];
        state.loading = false;
      })
      .addCase(setOfficeRequestState.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.requestData = action.payload;
        state.loading = false;
      })
      .addCase(setOfficeRequestState.pending, state => {
        state.loading = true;
      })
      .addCase(setOfficeRequestState.rejected, state => {
        state.requestFiles = [];
        state.loading = false;
      })
      .addCase(declineOfficeRequest.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.requestData = action.payload;
        state.loading = false;
      })
      .addCase(declineOfficeRequest.pending, state => {
        state.loading = true;
      })
      .addCase(declineOfficeRequest.rejected, state => {
        state.loading = false;
      })
      .addCase(addOfficeRequestFinalDocuments.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.requestData = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(addOfficeRequestFinalDocuments.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addOfficeRequestFinalDocuments.rejected, state => {
        state.loading = false;
        state.error = true;
      })

      .addCase(setOfficeRequestPaid.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.requestData = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(setOfficeRequestPaid.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setOfficeRequestPaid.rejected, state => {
        state.loading = false;
        state.error = true;
      })

      .addCase(setOfficeRequestSubmitted.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.requestData = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(setOfficeRequestSubmitted.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(setOfficeRequestSubmitted.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(sendRequestFileToPatient.fulfilled, (state, action: PayloadAction<RequestFile | null>) => {
        if (action.payload !== null) state.requestFile = { ...action.payload, tooltipVisible: false };
        state.sentToPatientLoading = false;
        state.error = false;
      })
      .addCase(sendRequestFileToPatient.pending, state => {
        state.sentToPatientLoading = true;
        state.error = false;
      })
      .addCase(sendRequestFileToPatient.rejected, state => {
        state.sentToPatientLoading = false;
        state.error = true;
      })
      .addCase(approveRejectRefundRequest.fulfilled, (state, action: PayloadAction<Request | null>) => {
        if (action.payload !== null) state.requestData = action.payload;
      });
  },
});

export const {
  listRequests,
  clearRequestData,
  toggleToolip,
  clearRequestFiles,
  setPaymentSucceded,
  setRequestSaved,
  clearOfficeRequestList,
  clearInvoiceObject,
  setRequestData,
} = OfficeRequestSlice.actions;
export default OfficeRequestSlice.reducer;
