import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { AppDispatch, RootState } from "../../../redux/store";
import { UserType } from "../../../services/user.service";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import Select from "../../Select/Select";
import TextInput from "../../TextInput/TextInput";
import { AuthPermission } from "../../../services/auth.service";
import { Office } from "../../../models/office.model";
import { UserUpdate } from "../../../models/user-update.model";
import { useDispatch } from "react-redux";
import { updatePatientUserProfile } from "../../../redux/slices/CurrentUserSlice";
import { ToastVariants } from "../../../models";
import SelectNew from "../../Select/SelectNew";
import { getJobTitles } from "../../../redux/slices/UserSlice";
interface EditFormDataType {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  errorMsg: string;
  isError: boolean;
  required: boolean;
  type: "text" | "select" | "phone" | "select-new";
  options?: string[];
}

const roleOptions = [
  {
    title: "System Superuser",
    key: UserType.SystemAdmin,
  },
  {
    title: "Office Superuser",
    key: UserType.OfficeAdmin,
  },
  {
    title: "Accounting",
    key: UserType.Accounting,
  },
  {
    title: "User",
    key: UserType.User,
  },
];

let checkBoxOptions = [
  {
    title: "Edit Office",
    key: AuthPermission.Office,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Users",
    key: AuthPermission.User,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Services",
    key: AuthPermission.Service,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Sign Forms",
    key: AuthPermission.Signing,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "View Reports",
    key: AuthPermission.Report,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "View/Edit Finance",
    key: AuthPermission.Finance,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Edit Requests",
    key: AuthPermission.Request,
    checked: false,
    dependents: [],
    disabled: false,
  },
  {
    title: "Legal",
    key: AuthPermission.Legal,
    checked: false,
    dependents: [],
    disabled: false,
  },
];

const EditProfile = ({ onCancelClick }: { onCancelClick: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.currentUser.currentUser);
  const { jobTitles } = useSelector((state: RootState) => state.user);

  const getIsPCP = (offices: Office[] | undefined, userId: string) => {
    if (!offices) return false;
    for (let i = 0; i < offices.length; i++) {
      const selectedOffice = offices[i];
      if (selectedOffice.primaryCareProviders?.some(pcp => pcp.userId === userId)) {
        return true;
      }
    }
    return false;
  };

  const [primaryCareProvider, setPrimaryCareProvider] = useState<boolean>(getIsPCP(user?.offices, user?.userId || ""));
  const [formData, setFormData] = useState<EditFormDataType[]>([]);
  const [formDataOld, setFormDataOld] = useState<EditFormDataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const toast = useContext(ToastContext);

  const isEdited = JSON.stringify(formData) === JSON.stringify(formDataOld);

  const permission = user?.permissions;
  if (permission) {
    checkBoxOptions = checkBoxOptions?.map(p => {
      return { ...p, checked: permission?.includes(p.key) };
    });
  }

  const handleChange = (id: string, value: string) => {
    const temp = [...formData];
    const updated = temp.map(v => {
      const newItem = { ...v };
      if (newItem.id === id) {
        newItem.value = value;
        newItem.isError = value === "";
      }
      return newItem;
    });
    setFormData([...updated]);
  };

  const validateForm = () => {
    return formData.every(v => {
      return !v.isError;
    });
  };

  const handleUpdate = async () => {
    const officeId = user?.offices?.[0]?.officeId;
    if (officeId && user?.userId) {
      setLoading(true);
      const body: UserUpdate = {
        officeId: officeId,
        userId: user.userId,
        firstName: formData[0].value,
        lastName: formData[1].value,
        email: user.email,
        phone: user.phone,
      };
      if (user?.userType !== UserType.SystemAdmin) {
        body.jobTitleId = formData[3].value;
      }
      const res = await dispatch(updatePatientUserProfile({ body, officeId, userId: user.userId }));
      if (res.payload) {
        toast?.openToast("Profile updated successfully!", 2000, ToastVariants.Success);
        onCancelClick();
      } else {
        toast?.openToast("Profile updated failed", 2000, ToastVariants.Error);
      }
      setLoading(false);
    }
  };

  const renderInputFields = () => {
    const items = formData.map(form => {
      const commonProps = {
        onChangeFunc: (e: any) => handleChange(form.id, e.currentTarget.value),
        value: form.value,
        placeholder: form.placeholder,
        errorMsg: form.errorMsg,
        isRequired: form.required,
        isError: form.isError && errorInitiated,
        label: form.label,
        isDarkBg: true,
        key: form.id,
      };
      switch (form.type) {
        case "text":
        case "phone":
          return (
            <div className="py-2" key={form.id}>
              <TextInput {...commonProps} onBlur={form.type === "text" ? e => handleChange(form.id, e.currentTarget.value.trim()) : undefined} />
            </div>
          );
        case "select":
          return (
            <div className="py-2" key={form.id}>
              <Select
                onSelect={e => handleChange(form.id, e.currentTarget.value)}
                options={form.options!}
                required={form.required}
                title={form.label}
                placeHolder={form.placeholder}
                disabled={true}
                isDarkBg={true}
                //   defaultValue={form.value}
              />
            </div>
          );
        case "select-new":
          return (
            <div className="py-2">
              <SelectNew
                title={form.label}
                required={true}
                value={commonProps.value}
                placeHolder={form.placeholder}
                onSelect={e => handleChange(form.id, e.target.value)}
                options={jobTitles.map(item => {
                  return { label: item.name, value: item.jobTitleId };
                })}
                disabled={true}
                errorMsg={commonProps.isError ? commonProps.errorMsg : ""}
                isDarkBg
              />
            </div>
          );
        default:
          return null;
      }
    });

    return items;
  };

  useEffect(() => {
    if (user) {
      const officeName = user?.offices?.length ? user?.offices[0].name : "";
      const formDataInit: EditFormDataType[] = [
        {
          id: "firstName",
          label: "First Name",
          placeholder: "Enter first name",
          value: user?.firstName || "",
          errorMsg: "Please enter first name.",
          isError: false,
          required: true,
          type: "text",
        },
        {
          id: "lastName",
          label: "Last Name",
          placeholder: "Enter last name",
          value: user?.lastName || "",
          errorMsg: "Please enter last name.",
          isError: false,
          required: true,
          type: "text",
        },
        {
          id: "office",
          label: "Office",
          placeholder: officeName || "Select Office",
          value: officeName,
          errorMsg: "Please select an office.",
          isError: false,
          required: false,
          type: "select",
          options: [],
        },
      ];
      if (user?.userType !== UserType.SystemAdmin) {
        formDataInit.push({
          id: "jobTitle",
          label: "Job Title",
          placeholder: "Select job title",
          value: user?.jobTitle?.jobTitleId ?? "",
          errorMsg: "Please select job title",
          isError: !user?.jobTitle,
          required: true,
          type: "select-new",
          options: [],
        });
      }
      setFormDataOld([...formDataInit]);
      setFormData([...formDataInit]);
    }
    dispatch(getJobTitles());
    setPrimaryCareProvider(getIsPCP(user?.offices, user?.userId || ""));
  }, [user]);

  return (
    <>
      <div className="px-8">
        {renderInputFields()}
        <div className="py-2">
          <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-1">Role</label>
          <Checkbox
            onChange={() => {}}
            label={roleOptions.find(v => v.key === user?.userType)?.title || ""}
            checked={true}
            disabled={true}
            isDarkBg={true}
          />
        </div>
        <div className="py-2">
          <label className="text-sm text-docsigna-blue-dark font-medium block w-full mb-1">Permissions</label>
          {checkBoxOptions?.map(v => {
            return <Checkbox label={v.title} checked={v.checked} onChange={() => {}} key={v.key} disabled={true} isDarkBg={true} />;
          })}
          <Checkbox label="Primary Care Provider" checked={primaryCareProvider} onChange={() => null} disabled={true} isDarkBg={true} />
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => {
              onCancelClick();
            }}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div onClick={() => setErrorInitiated(true)}>
            <Button
              onClickFunc={() => {
                if (validateForm()) handleUpdate();
              }}
              AdditionalClassNames="pointer px-5"
              disabled={(!validateForm() && errorInitiated) || isEdited}
              text="Save"
              width="fit"
              varient={loading ? "loading" : "Primary"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
