import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logger } from "../../utils/logger";
import { BlockFeeService } from "../../services/blockFee.service";
import { BlockFee } from "../../models/block-fee.model";

const log = logger.getLogger('BlockFeeErrors');

export interface BlockFeeStateType {
  error: boolean;
  loading: boolean;
  plans: BlockFee[];
  frequencies: string[];
}

const initialState: BlockFeeStateType = {
  loading: false,
  error: false,
  plans: [],
  frequencies: []
};

export const getOfficeBlockFees = createAsyncThunk("authApi/getOfficeBlockFees", async ({officeId}:{officeId:string}, { rejectWithValue }) => {
    try {
      const data = await BlockFeeService.getOfficeBlockFees(officeId)
      return data;
    } catch (err) {
      log.warn(JSON.stringify(err));
      return rejectWithValue(err);
    }
});

export const createBlockFee = createAsyncThunk("authApi/createBlockFee", async ({officeId, name, price, subscriptionType, enabled=false}:{officeId:string, name:string, price:number, subscriptionType:string, enabled:boolean}, { rejectWithValue }) => {
  try {
    const data = await BlockFeeService.createOfficeBlockFee(officeId, name, price, subscriptionType, enabled)
    return data;
  } catch (err) {
    log.warn(JSON.stringify(err));
    return rejectWithValue(err)
  }
});

export const editBlockFee = createAsyncThunk("authApi/editBlockFee", async ({officeId, blockFeeId, name, price, subscriptionType, enabled=false}:{officeId:string, blockFeeId:string, name:string, price:number, subscriptionType:string, enabled:boolean}, { rejectWithValue }) => {
  try {
    const data = await BlockFeeService.updateOfficeBlockFee(blockFeeId, officeId, name, subscriptionType, price, enabled)
    return data;
  } catch (err) {
    log.warn(JSON.stringify(err));
    return rejectWithValue(err)
  }
});

export const getSubscriptionTypes = createAsyncThunk("authApi/getSubscriptionTypes", async (_, { rejectWithValue }) => {
  try {
    const data = await BlockFeeService.getSubscriptionTypes()
    return data;
  } catch (err) {
    log.warn(JSON.stringify(err));
    return rejectWithValue(err);
  }
});


export const BlockFeeSlice = createSlice({
  name: "blockFeeApi",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createBlockFee.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(createBlockFee.fulfilled, (state, action: PayloadAction<BlockFee | number>) => {
        state.error = false;
        state.loading = false;
        if(typeof action.payload === 'object'){
          state.plans = [...state.plans, action.payload]
        }
      })
      .addCase(createBlockFee.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(editBlockFee.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getOfficeBlockFees.pending, state => {
        state.error = false;
      })
      .addCase(getOfficeBlockFees.fulfilled, (state, action: PayloadAction<BlockFee[] | []>) => {
        state.plans = action.payload
        state.loading = false;
        state.error = false;
      })
      .addCase(getOfficeBlockFees.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getSubscriptionTypes.pending, state => {
        state.error = false;
      })
      .addCase(getSubscriptionTypes.fulfilled, (state, action: PayloadAction<string[] | []>) => {
        state.frequencies = action.payload
        state.loading = false;
        state.error = false;
      })
      .addCase(getSubscriptionTypes.rejected, (state, action: PayloadAction<any>) => {
        state.error = true;
        state.loading = false;
      })
      .addCase(editBlockFee.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(editBlockFee.fulfilled, (state, action: PayloadAction<BlockFee | number>) => {
        state.error = false;
        state.loading = false;
        if(typeof action.payload === 'object'){
          const newItem = action.payload
          const index = state.plans.findIndex(item => item.blockFeeId === newItem?.blockFeeId)
          if(index!==-1) {
            state.plans = [...state.plans.slice(0, index), {...action.payload}, ...state.plans.slice(index+1)]
          }
        }
      });
  },
});

export default BlockFeeSlice.reducer;
