import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { logger } from "../../utils/logger";
import { AccountReceivablesTotals, Finance, FinanceRequest, Payee, TopOverdueAccounts } from "../../models/finance.model";
import { FinanceService } from "../../services/finance.service";
import { OfficeService } from "../../services/office.service";
import { ApiPage } from "../../models/api-page.model";

const log = logger.getLogger('FinanceErrors');
export interface FinanceSliceState {
  loading: boolean;
  officeFinanceRequests: FinanceRequest[];
  officeFinanceReport: Finance | null;
  financeRequests: FinanceRequest[];
  financeReport: Finance | null;
  financeReportTabData: Finance | null;
  error: boolean;
  activeTab: number;
  activeOfficeTab: number;
  sort: {
    start: number;
    end: number;
    pageNumber: number;
    rowsPerPage: number;
    sortedBy: string;
    totalElements: number;
  };
  officeSort: {
    start: number;
    end: number;
    pageNumber: number;
    rowsPerPage: number;
    sortedBy: string;
    totalElements: number;
  };
  accountReceivablesTotals: AccountReceivablesTotals | undefined;
  accountReceivablesPayee: Payee[];
  topOverdueAccounts: TopOverdueAccounts[];
  accountReceivables: TopOverdueAccounts[];
  arSort: {
    last: boolean;
    pageNumber: number;
  }
}

const initialState: FinanceSliceState = {
  loading: false,
  officeFinanceRequests: [],
  officeFinanceReport: null,
  financeRequests: [],
  financeReport: null,
  financeReportTabData: null,
  activeTab: 0,
  activeOfficeTab: 0,
  sort: {
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "request.lastName",
    totalElements: 0,
  },
  officeSort: {
    start: 0,
    end: 10,
    pageNumber: 0,
    rowsPerPage: 10,
    sortedBy: "request.lastName",
    totalElements: 0,
  },
  accountReceivablesTotals:undefined,
  accountReceivablesPayee: [],
  topOverdueAccounts: [],
  accountReceivables: [],
  arSort: {
    last: false,
    pageNumber: 0
  },
  error: false,
};

//Office level api's
export const getOfficeFinanceRequests = createAsyncThunk(
  "financeSliceApi/getOfficeFinanceRequests",
  async ({ officeId, startDate, endDate, page=0, size=10, sort = "request.lastName" }: { officeId: string; startDate:Date, endDate:Date; page?:number; size?:number; sort?:string }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeFinanceRequests(officeId, startDate, endDate, page, size, sort);
      return {res, sort};
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e);
    }
});

export const getOfficeFinanceReport = createAsyncThunk(
"financeSliceApi/getOfficeFinanceReport",
  async ({ officeId, startDate, endDate }: { officeId: string; startDate: Date; endDate?: Date }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeFinanceReport(officeId, startDate, endDate);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
});

//DS level api's
export const getFinanceRequests = createAsyncThunk(
    "financeSliceApi/getFinanceRequests",
    async ({ officeId, startDate, endDate, page=0, size=10, sort = "request.lastName" }: { officeId?: string; startDate:Date, endDate:Date; page?:number; size?:number; sort?:string }, { rejectWithValue }) => {
        try {
          const res = await FinanceService.getFinanceRequests(startDate, endDate, page, size, sort, officeId);
          return {res, sort};
        } catch (e) {
          log.warn(JSON.stringify(e));
          return rejectWithValue(e);
        }
    }
);

export const getFinanceReport = createAsyncThunk(
  "financeSliceApi/getFinanceReport",
  async ({ officeId, startDate, endDate }: { officeId?: string; startDate: Date; endDate?: Date }, { rejectWithValue }) => {
    try {
      const res = await FinanceService.getFinanceReport(startDate, endDate, officeId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getFinanceReportTabData = createAsyncThunk(
  "financeSliceApi/getFinanceReportTabData",
  async ({ officeId = '', startDate, endDate }: { officeId?: string; startDate: Date; endDate?: Date }, { rejectWithValue }) => {
    try {
      const res = await FinanceService.getFinanceReport(startDate, endDate, officeId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  } 
);

export const getOfficeFinanceReportCsv = createAsyncThunk(
  "financeSliceApi/getOfficeFinanceReportCsv",
  async ({ officeId, startDate, endDate }: { officeId: string; startDate: Date; endDate?: Date }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeFinanceReportCsv(officeId, startDate, endDate);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return null;
    }
  } 
);

export const setActiveFinanceTab = createAsyncThunk(
  "financeSliceApi/setActiveFinanceTab",
  async ({ index }: { index: number }, { rejectWithValue }) => {
    try {
      return index;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const setActiveOfficeFinanceTab = createAsyncThunk(
  "financeSliceApi/setActiveOfficeFinanceTab",
  async ({ index }: { index: number }, { rejectWithValue }) => {
    try {
      return index;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(null);
    }
  }
);

export const getOfficeAccountReceivablesTotals = createAsyncThunk(
  "financeSliceApi/getOfficeAccountReceivablesTotals",
  async ({ officeId}: { officeId: string; }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeAccountReceivablesTotals(officeId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e)
    }
  } 
);

export const getOfficeAccountReceivablesPayee = createAsyncThunk(
  "financeSliceApi/getOfficeAccountReceivablesPayee",
  async ({ officeId}: { officeId: string; }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeAccountReceivablesPayee(officeId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e)
    }
  } 
);

export const getOfficeAccountReceivablesTopOverdueAccounts = createAsyncThunk(
  "financeSliceApi/getOfficeAccountReceivablesTopOverdueAccounts",
  async ({ officeId}: { officeId: string; }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeAccountReceivablesTopOverdueAccounts(officeId);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e)
    }
  } 
);

export const getOfficeAccountReceivables = createAsyncThunk(
  "financeSliceApi/getOfficeAccountReceivables",
  async ({ officeId, page, sortBy}: { officeId: string; page: number, sortBy?: string; }, { rejectWithValue }) => {
    try {
      const res = await OfficeService.getOfficeAccountReceivables(officeId, page, 200, sortBy);
      return res;
    } catch (e) {
      log.warn(JSON.stringify(e));
      return rejectWithValue(e)
    }
  } 
);

export const FinanceSlice = createSlice({
  name: "financeSliceApi",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOfficeFinanceRequests.pending, state => {
        state.error = false;
      })
      .addCase(getOfficeFinanceRequests.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        const {res, sort} = action.payload;
        state.officeFinanceRequests = res?.content;
        state.officeSort = {
          ...state.sort, 
          pageNumber: res.number,
          rowsPerPage: res.size,
          sortedBy: sort,
          totalElements: res.totalElements
        }
      })
      .addCase(getOfficeFinanceRequests.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getOfficeFinanceReport.pending, state => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getOfficeFinanceReport.fulfilled, (state, action: PayloadAction<Finance | null>) => {
        state.loading = false;
        if (action.payload !== null) {
          state.error = false;
          state.officeFinanceReport = action.payload;
        }
      })
      .addCase(getOfficeFinanceReport.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getFinanceRequests.pending, state => {
        state.error = false;
      })
      .addCase(getFinanceRequests.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        const {res, sort} = action.payload;
        state.financeRequests = res?.content;
        state.sort = {
          ...state.sort, 
          pageNumber: res.number,
          rowsPerPage: res.size,
          sortedBy: sort,
          totalElements: res.totalElements
        }
      })
      .addCase(getFinanceRequests.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getFinanceReport.pending, state => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getFinanceReport.fulfilled, (state, action: PayloadAction<Finance | null>) => {
        state.loading = false;
        if (action.payload !== null) {
          state.error = false;
          state.financeReport = action.payload;
        }
      })
      .addCase(getFinanceReport.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(getFinanceReportTabData.pending, state => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getFinanceReportTabData.fulfilled, (state, action: PayloadAction<Finance | null>) => {
        state.loading = false;
        if (action.payload !== null) {
          state.error = false;
          state.financeReportTabData = action.payload;
        }
      })
      .addCase(getFinanceReportTabData.rejected, state => {
        state.error = true;
        state.loading = false;
      })
      .addCase(setActiveFinanceTab.fulfilled, (state, action: PayloadAction<number>) => {
        state.activeTab = action.payload;
      })
      .addCase(setActiveOfficeFinanceTab.fulfilled, (state, action: PayloadAction<number>) => {
        state.activeOfficeTab = action.payload;
      })
      .addCase(getOfficeAccountReceivablesTotals.fulfilled, (state, action: PayloadAction<AccountReceivablesTotals | undefined>) => {
        state.accountReceivablesTotals = action.payload;
      })
      .addCase(getOfficeAccountReceivablesPayee.fulfilled, (state, action: PayloadAction<Payee[]>) => {
        state.accountReceivablesPayee = action.payload;
      })
      .addCase(getOfficeAccountReceivablesTopOverdueAccounts.fulfilled, (state, action: PayloadAction<TopOverdueAccounts[]>) => {
        state.topOverdueAccounts = action.payload;
      })
      .addCase(getOfficeAccountReceivables.fulfilled, (state, action: PayloadAction<ApiPage<TopOverdueAccounts>>) => {
        if(action.payload.number === 0)
          state.accountReceivables = [...action.payload.content];
        else 
          state.accountReceivables = [...state.accountReceivables, ...action.payload.content];
        state.arSort = {
          last: action.payload.last,
          pageNumber: action.payload.number
        }
      });
  },
});

export default FinanceSlice.reducer;
