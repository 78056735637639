import { formDataType, FormFieldType } from "./OfficeProfileData";

export const formDataInitialState: formDataType[] = [
  {
    id: "companyName",
    value: "",
    title: "Company Name",
    placeholder: "Please provide company name",
    required: true,
    errorMessage: "Please provide company name",
    valid: false,
    type: FormFieldType.TextInput,
  },
  {
    id: "officeAddress",
    value: "",
    title: "Office Address",
    placeholder: "Please provide address",
    required: true,
    errorMessage: "Please provide valid office address",
    valid: false,
    type: FormFieldType.Places,
  },
  {
    id: "billingStreet2",
    value: "",
    title: "Unit Number",
    placeholder: "Enter unit number",
    required: false,
    errorMessage: "Please provide valid unit number",
    valid: true,
    type: FormFieldType.TextInput,
  },
  {
    id: "hstNumber",
    value: "",
    title: "HST #",
    placeholder: "Please provide HST number",
    required: false,
    errorMessage: "Please provide a valid HST #",
    valid: true,
    type: FormFieldType.HST,
  },
  {
    id: "bankCode",
    value: "",
    title: "Bank Code",
    placeholder: "Please provide bank code",
    required: true,
    errorMessage: "Please provide bank code",
    valid: false,
    type: FormFieldType.Number,
  },
  {
    id: "transitNumber",
    value: "",
    title: "Transit Number",
    placeholder: "Please provide transit number",
    required: true,
    errorMessage: "Please provide transit number",
    valid: false,
    type: FormFieldType.Number
  },
  {
    id: "accountNumber",
    value: "",
    title: "Account Number",
    placeholder: "Please provide account number",
    required: true,
    errorMessage: "Please provide account number",
    valid: false,
    type: FormFieldType.Number
  },

  {
    id: "wsibNumber",
    value: "",
    title: "WSIB Billing Number",
    placeholder: "Please provide WSIB billing number",
    required: true,
    errorMessage: "Please provide valid WSIB billing number",
    valid: false,
    type: FormFieldType.Number
  },
];
