import { createContext, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Subscription } from "rxjs";
import useResetStore from "../../hooks/useResetStore";
import { getUser, resetState, setCurrentUserNull } from "../../redux/slices/CurrentUserSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { authService, AuthToken } from "../../services/auth.service";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../utils/constants";

type SignInFunction = ({ email, password }: { email: string; password: string }) => Promise<AuthToken | null>;
export interface AuthContextType {
  isAuthenticated: boolean;
  errorMessage: string;
  signin: SignInFunction;
  signout: () => void;
  error: boolean;
  pending: boolean;
  userType: string;
  isAlreadyLoggedIn: () => void;
  authenticateMFA: (email: string, code: string) => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [pending, setpending] = useState(false);
  const [userType, setUserType] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const user = useSelector((state: RootState) => state.currentUser);
  const dispatch = useDispatch<AppDispatch>();
  const [tokens, setTokens] = useState<{ accessToken: null | string; refreshToken: null | string }>({ accessToken: null, refreshToken: null });
  const resetStore = useResetStore();

  const signin = async ({ email, password }: { email: string; password: string }) => {
    setpending(true);
    setError(false);
    try {
      const response = await authService.signIn(email, password);
      if (response?.emailVerificationRequired) {
        return response;
      }
      if (response.accessToken && response.refreshToken) {
        dispatch(resetState());
        await dispatch(getUser());
        setTokens({ accessToken: response.accessToken, refreshToken: response.refreshToken });
      }
      return response;
    } catch (err) {
      setError(true);
      setErrorMessage("Incorrect email or password");
      setpending(false);
      return null;
    }
  };

  const authenticateMFA = async (email: string, code: string) => {
    setpending(true);
    setError(false);
    try {
      const response = await authService.authenticateMfa(email, code);
      if (response.accessToken && response.refreshToken) {
        dispatch(resetState());
        await dispatch(getUser());
        setTokens({ accessToken: response.accessToken, refreshToken: response.refreshToken });
      }
      return true;
    } catch (err) {
      return false;
    }
  };

  const signout: () => void = async () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.clear();
    setTokens({ accessToken: null, refreshToken: null });
    setAuthenticated(false);
    setError(false);
    setUserType("");
    resetStore();
    dispatch(setCurrentUserNull());
    const interval = setInterval(() => {
      if (localStorage.getItem(ACCESS_TOKEN) === null) {
        authService.signOut();
        localStorage.removeItem("redirectPath");
        clearInterval(interval);
      }
    }, 100);
  };

  const isAlreadyLoggedIn = () => {
    setAuthenticated(authService.isAuthenticated());
    if (authService.isAuthenticated()) {
      dispatch(getUser());
    }
  };

  const value = {
    isAuthenticated,
    errorMessage,
    signin,
    signout,
    error,
    pending,
    userType,
    isAlreadyLoggedIn,
    authenticateMFA,
  };

  useEffect(() => {
    const subscribeRefreshedAccessToken: Subscription = authService.accessTokenRefreshed$.subscribe(token => {
      if (token) {
        setTokens({ accessToken: token, refreshToken: tokens.refreshToken });
      }
    });

    const authSubscription: Subscription = authService.authStateChange$.subscribe(status => {
      if (!status) {
        if (localStorage.getItem(ACCESS_TOKEN)) {
          signout();
        }
      }
    });

    return () => {
      subscribeRefreshedAccessToken.unsubscribe();
      authSubscription.unsubscribe();
    };
  }, [tokens.refreshToken]);

  useEffect(() => {
    if (user.error) {
      setAuthenticated(false);
      setError(true);
      setErrorMessage("Sign in currently unavailable, please try again");
      setpending(false);
    }
    if (user.currentUser !== undefined && user.currentUser !== null && !user.error && tokens.accessToken && tokens.refreshToken) {
      setAuthenticated(true);
      setErrorMessage("");
      setError(false);
      setpending(false);
      if (tokens.accessToken && tokens.refreshToken) {
        localStorage.setItem(ACCESS_TOKEN, tokens.accessToken ? tokens.accessToken : "");
        localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken ? tokens.refreshToken : "");
      }
      setUserType(user?.currentUser?.userType ?? "");
    }
  }, [tokens.accessToken, tokens.refreshToken, user]);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem(ACCESS_TOKEN);
    const storedRefreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (storedAccessToken && storedRefreshToken) {
      setTokens({
        accessToken: storedAccessToken,
        refreshToken: storedRefreshToken,
      });
      isAlreadyLoggedIn();
    }
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
