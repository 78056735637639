import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux/store";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import { ToastContext } from "../../../contexts/ToastContext/ToastContext";
import { ToastVariants } from "../../../models";
import { setRequestData } from "../../../redux/slices/OfficeRequestSlice";
import { OfficeService } from "../../../services/office.service";

const EditNotes = ({
  onClickCancel,
  setIsLoading,
  isLoading,
}: {
  onClickCancel: () => void;
  setIsLoading: (val: boolean) => void;
  isLoading?: boolean;
}) => {
  const { id, requestId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state?.user);
  const toast = useContext(ToastContext);
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const [message, setMessage] = useState(requestData?.details ?? "");
  const [messageError, setMessageError] = useState(true);
  const [errorInitiated, setErrorInitiated] = useState(false);
  const handleChange = (value: string) => {
    if ((value as string).length < 1) {
      setMessageError(true);
    } else {
      setMessageError(false);
    }
    setMessage(value as string);
  };

  const handleSubmit = async () => {
    if (id && requestId && requestData && message && !messageError) {
      setIsLoading(true);
      try {
        const resp = await OfficeService.updateRequestNotes(message.trim(), requestData.requestId, requestData.officeId);
        setIsLoading(false);
        if (resp !== null) {
          onClickCancel();
          dispatch(setRequestData(resp));
          toast?.openToast("Notes updates successfully!", 2000, ToastVariants.Success);
        } else {
          toast?.openToast("Notes update failed!");
        }
      } catch (_) {
        setIsLoading(false);
        toast?.openToast("Something went wrong please try again!", 2000, ToastVariants.Error);
      }
    }
  };
  return (
    <>
      <div className="px-8 py-2">
        <label className="text-sm text-docsigna-blue-dark font-medium block w-full mt-3 mb-2">
          {"Please provide any information relevant to this request"}
          <span className="text-red-500">*</span>
        </label>
        <TextInput
          value={message}
          disabled={loading}
          onChangeFunc={e => handleChange(e.currentTarget.value)}
          placeholder={"Enter notes here"}
          errorMsg={"Notes is required"}
          isError={errorInitiated && messageError}
          isRequired={false}
          isTextArea
          onBlur={e => handleChange(e.currentTarget.value.trim())}
          isDarkBg={true}
        />
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => onClickCancel()}
            disabled={isLoading}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button
              onClickFunc={() => {
                setErrorInitiated(true);
                handleSubmit();
              }}
              varient={loading ? "loading" : "Primary"}
              disabled={(errorInitiated && messageError) || isLoading}
              AdditionalClassNames="pointer px-5"
              text="Update"
              width="fit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNotes;
