import { useEffect } from "react";

const ExternalRedirect = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null; // Return null or a loader, as the user is being redirected
};

export default ExternalRedirect;
