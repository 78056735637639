// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AuthPermission, authService } from "../../services/auth.service";

interface ProtectedComponentType {
  requiredPermission: AuthPermission[];
  children: JSX.Element;
}

const ProtectedComponent = ({ requiredPermission, children }: ProtectedComponentType) => {
  const userPermissions = authService.currentUserPermissions;
  if (requiredPermission.length === 0) return children;
  const permissions = requiredPermission.map(permission => {
    return userPermissions.includes(permission);
  });
  return permissions.includes(false) ? null : children;
};

export default ProtectedComponent;
