import { headersType } from "../../models";

export const headers: headersType[] = [
  {
    title: "",
    extraClasses: "w-18 pr-0 hidden lg:block pl-0",
    sortKey: "urgent",
    isClickable: true,
  },
  {
    title: "Patient Name",
    extraClasses: "w-full pl-0 md:pl-5 truncate",
    sortKey: "lastName",
    isClickable: true,
  },
  {
    title: "Request #",
    extraClasses: "w-200 hidden lg:block truncate",
    sortKey: "requestNumber",
    isClickable: true,
  },
  {
    title: "Service",
    extraClasses: "w-full hidden lg:block truncate",
    sortKey: "service.name",
    isClickable: true,
  },
  {
    title: "Bill To",
    extraClasses: "w-160 hidden lg:block truncate",
    sortKey: "serviceBilledToOverride.name",
    isClickable: true,
  },
  {
    title: "Status",
    extraClasses: "w-full hidden lg:block truncate",
    sortKey: "requestState.name",
    isClickable: true,
  },

  {
    title: "Updated",
    extraClasses: "w-200 hidden lg:block truncate",
    sortKey: "updatedDate",
    isClickable: true,
  },
  {
    title: "Comments",
    extraClasses: "w-180 hidden lg:block truncate",
    sortKey: "",
    isClickable: false,
  },
];
