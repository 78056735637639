import { useEffect, useState } from "react";
import { Button } from "../../components";
import { colors } from "../../utils/constants";
import { loadStripe } from "@stripe/stripe-js";
import { createPaymentIntent } from "../../redux/slices/PaymentSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";

const UpdatePaymentMethod = ({ handleUpdatePaymentMethod }: { handleUpdatePaymentMethod: (val: boolean) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isReady, setFormReady] = useState(false);

  const { paymentIntent, cancelled, error } = useSelector((state: RootState) => state.payment);

  const stripePromise = process.env.REACT_APP_STRAPI_PUBLISHABLE_KEY ? loadStripe(process.env.REACT_APP_STRAPI_PUBLISHABLE_KEY) : null;

  useEffect(() => {
    dispatch(createPaymentIntent({ requestId: "70ac06e7-d833-4e24-859f-5215b816544a" }));
  }, []);

  return (
    <div className="px-8 border-t">
      <div className="flex flex-col">
        <div className="w-full ">{cancelled ? <p>Payment has been cancelled.</p> : null}</div>
        <div className="w-full">{error ? <p style={{ color: colors.Red }}>An error occurred, please try again.</p> : null}</div>
        <div className="w-full">
          {paymentIntent !== null && !cancelled ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: paymentIntent.clientSecret,
                appearance: {
                  theme: "stripe",
                  variables: {
                    spacingGridRow: "0",
                    spacingGridColumn: "0",
                    spacingUnit: "0",
                    borderRadius: "0",
                  },
                  rules: {
                    ".Grid": {
                      marginBottom: "0px",
                    },
                    ".Label": {
                      fontSize: "0px",
                      lineHeight: "0px",
                      marginBottom: "0px",
                    },
                    ".p-CardNumberInput-input": {
                      borderTop: "1px",
                      borderTopLeftRadius: "8px",
                    },
                    "#Field-numberInput": {
                      backgroundColor: "red",
                    },
                    ".Input": {
                      color: colors.DarkBlue,
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "10px 0 0 0",
                      backgroundColor: "#fff",
                      outline: "none",
                      boxShadow: "none",
                      borderColor: colors.DarkBlue,
                    },
                    ".Input:focus": {
                      outline: "none",
                      boxShadow: "none",
                    },
                    ".Error": {
                      color: "red",
                    },
                    "p-BillingAddressForm": {
                      marginTop: "20px",
                    },
                  },
                },
              }}>
              <p className="py-2 text-semibold">
                <b>Credit Card Information</b>
              </p>
              <PaymentElement
                className="stripe-payment-element"
                id={"Payment-Form-Stripe"}
                onChange={(e: any) => {
                  setFormReady(e.complete);
                }}
              />
            </Elements>
          ) : null}
        </div>
      </div>
      <div className="fixed bottom-0 right-0 w-11/12 max-w-120 z-30 bg-docsigna-pink-light border-t">
        <div className="px-8 py-5 flex justify-end">
          <Button
            onClickFunc={() => handleUpdatePaymentMethod(false)}
            AdditionalClassNames="pointer px-5 mr-3"
            text="Cancel"
            width="fit"
            varient="Secondary"
          />
          <div>
            <Button
              onClickFunc={() => handleUpdatePaymentMethod(true)}
              AdditionalClassNames="pointer px-5"
              text="Update Payment"
              width="fit"
              disabled={!isReady}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePaymentMethod;
