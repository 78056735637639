import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ServiceBilledTo } from "../../../models/service.model";
import InvoicePdf from "./InvoicePdf";

const PriceOverrideInvoiceForm = () => {
  const { requestData } = useSelector((state: RootState) => state.officeRequest);
  const billedTo = requestData?.serviceBilledToOverride ? requestData?.serviceBilledToOverride : requestData?.service?.serviceBilledTo;
  const [notes] = useState("");
  const [recipientContact] = useState(requestData?.recipientContact || "");
  const [recipientName] = useState(requestData?.recipientName || "");
  const [serviceCanadaAdd] = useState(`PO Box 2020 Station Main
Chatham ON
N7M 6B2`);
  const [wsibAdd] = useState(`Workplace Safety and Insurance Board
200 Front St. West
15th Floor
Toronto ON M5V 3J1`);

  return (
    <div id="price-override-invoice-container-pdf" className="flex-col hidden">
      <InvoicePdf
        notes={notes}
        recipientContact={recipientContact}
        recipientName={recipientName}
        address={billedTo === ServiceBilledTo.WSIB ? wsibAdd : serviceCanadaAdd}
      />
    </div>
  );
};

export default PriceOverrideInvoiceForm;
